import React, {useState} from 'react'
import ReactTable from 'react-table'
import cn from 'classnames'

import {ASCENDING, DESCENDING} from '../../../model/sort_directions.js'

import Modal from '../../widgets/Modal.js'
import TextLink from '../../widgets/TextLink.js'
import ErrorBody from '../../ErrorBody'
import ClearableSearchInput from '../../widgets/ClearableSearchInput.js'
import SortingColumnHeaderCell from '../../patent_family_list/SortingColumnHeaderCell.js'
import EditableTextLink from './EditableTextLink.js'
import {TrashIcon, PlusIcon} from '../../widgets/IconSet.js'
import ScrollableList from '../../widgets/ScrollableList.js'

import {is_valid_tag_name} from '../../../utils/report_management_utils.js'
import {filter_on_search_string} from '../utils/sort_and_filter_utils.js'
import {is_array_non_empty_non_null} from '../../../utils/utils.js'

import s from './TagManagementModal.module.scss'

const MIN_ROWS = 5

const TagManagementModal = ({on_hide, tags, error_managing_tags, handle_create_new_tag,
                              handle_rename_existing_tag, handle_select_tag_to_delete}) => {

  const title = 'Manage tags'
  const [search_input, set_search_input] = useState('')
  const [sorted, set_sorted] = useState([{id: 'name', desc: false}])
  const [tag_to_rename_id, set_tag_to_rename_id] = useState(null)

  const tags_filtered = filter_on_search_string(tags, search_input, 'name')
  const no_rows_message = is_array_non_empty_non_null(tags) ? 'No search matches' : 'You have not yet created any tags'

  function rename_tag(tag, name) {
    set_tag_to_rename_id(null)
    handle_rename_existing_tag(tag, name)
  }

  function render_delete_button(tag) {
    return (
      <TextLink
        onClick={() => handle_select_tag_to_delete(tag)}
        title='Delete'
        no_decoration
      >
        <TrashIcon/>
      </TextLink>
    )
  }

  function render_column_header(field) {
    // Make sure the field has id, name, and not_sortable properties (SortingColumnHeaderCell expects these).
    const field_with_id_and_name = {
      ...field,
      id:           field.name,
      name:         field.label,
      not_sortable: !field.sortable
    }

    const selected_sort_field_id     = sorted.length > 0 ? sorted[0].id : undefined
    const selected_sort_direction_id = sorted.length > 0 ? (sorted[0].desc ? DESCENDING : ASCENDING) : undefined

    return (
      <SortingColumnHeaderCell
        field={field_with_id_and_name}
        selected_sort_field_id={selected_sort_field_id}
        selected_sort_direction_id={selected_sort_direction_id}
        on_change_sort_field_id_and_sort_direction_id={(sort_field_id, sort_direction_id) => {
          const sort_obj = {
            id: sort_field_id,
            desc: sort_direction_id === DESCENDING
          }
          set_sorted([sort_obj])
        }}
      />
    )
  }

  function render_editable_tag(tag) {
    return (
      <EditableTextLink
        link_text={tag.name}
        on_confirm={(name) => rename_tag(tag, name)}
        on_cancel={() => set_tag_to_rename_id(null)}
        on_edit={() => set_tag_to_rename_id(tag.tag_id)}
        is_edit={tag_to_rename_id && tag_to_rename_id === tag.tag_id}
        is_valid={(name) => is_valid_tag_name(name, tags)}
        is_clickable={false}
        is_editable={true}
      />
    )
  }

  function get_columns() {
    const fields = [
      {label: 'Name',    name: 'name',          cell_render: render_editable_tag,  sortable: true},
      {label: 'Reports', name: 'report_count',                                     sortable: true,   max_width: 105},
      {                  name: 'delete',        cell_render: render_delete_button, sortable: false,  max_width: 40}
    ]

    return fields.map((field) => {
      return {
        Header: render_column_header(field),
        headerClassName: 'no-sort-mark',
        accessor: field.name,
        id: field.id,
        maxWidth: field.max_width,
        minWidth: field.min_width,
        resizable: field.resizable,
        ...(field.cell_render) && { Cell: (row) => field.cell_render(row.original)}
      }
    })
  }

  const columns = get_columns()

  return (
    <Modal
      on_hide={on_hide}
      title={title}
      close_label='Done'
      size='sm'
    >
      {error_managing_tags &&
        <ErrorBody
          error={error_managing_tags.error}
          context={error_managing_tags.action}
        />
      }
      <TextLink
        onClick={handle_create_new_tag}
        no_decoration
        className={s.create_new_link}
      >
        <span className= 'me-1'><PlusIcon /></span><span className={s.create_new_link_label}>New</span>
      </TextLink>
      <ClearableSearchInput
        containerClassName={cn('mt-3')}
        value={search_input}
        placeholder={'Search tags'}
        auto_focus={true}
        handle_change={set_search_input}
      />
      <ScrollableList className='mt-2'>
        <ReactTable
          filterable={false}
          sortable={false}
          columns={columns}
          data={tags_filtered}
          pageSize={tags_filtered ? tags_filtered.length : MIN_ROWS}
          minRows={MIN_ROWS}
          className={cn('border-0', '-striped', 'mt-3')}
          noDataText={no_rows_message}
          resizable={false}
          showPagination={false}
          sorted={sorted}
        />
      </ScrollableList>
    </Modal>
  )
}

export default TagManagementModal