import {
  PIE_ID,
  LINE_ID,
  SCATTER_ID,
  BUBBLE_ID,
  BAR_ID,
  HEATMAP_ID,
  TREEMAP_ID,
  TABLE_ID,
  DISPUTES_TABLE_ID,
  CHOROPLETH_MAP_ID,
  GROWTH_TREEMAP_ID,
  COLUMN_CLUSTER_ID,
  COLUMN_STACK_ID,
  GROWTH_TABLE_ID,
  AREA_ID,
  CAGR_TABLE_ID,
  BAR_CLUSTER_ID,
  BAR_STACK_ID,
  EXTENDED_TABLE_ID,
  GROWTH_SCATTER_ID,
  CAGR_SCATTER_ID
} from './view_ids.js'

import PieChart from '../components/spec_visualisations/PieChart.js'
import ColumnChart from '../components/spec_visualisations/ColumnChart.js'
import LineChart from '../components/spec_visualisations/LineChart.js'
import ScatterChart from '../components/spec_visualisations/ScatterChart.js'
import LineAreaChart from '../components/spec_visualisations/LineAreaChart.js'
import Heatmap from '../components/spec_visualisations/Heatmap.js'
import ChoroplethMap from '../components/spec_visualisations/ChoroplethMap.js'
import SimpleTreemap from '../components/spec_visualisations/SimpleTreemap.js'
import GrowthTreemap from '../components/spec_visualisations/GrowthTreemap.js'
import TabularView from '../components/spec_visualisations/TabularView.js'
import ExtendedTabularView from '../components/spec_visualisations/ExtendedTabularView.js'
import GrowthTabularView from '../components/spec_visualisations/GrowthTabularView.js'
import CagrTabularView from '../components/spec_visualisations/CagrTabularView.js'
import DisputesContainer from '../components/disputes/DisputesContainer.js'
import BubbleChart from '../components/spec_visualisations/BubbleChart.js'
import ColumnClusteredChart from '../components/spec_visualisations/ColumnClusteredChart.js'
import BarClusteredChart from '../components/spec_visualisations/BarClusteredChart.js'
import BarStackedChart from '../components/spec_visualisations/BarStackedChart.js'
import {
  AreaViewIcon,
  BarsClusterViewIcon,
  BarsStackViewIcon,
  BubbleViewIcon,
  ColumnsClusterViewIcon,
  ColumnsStackViewIcon,
  HeatmapViewIcon,
  LineViewIcon,
  MapViewIcon,
  PieViewIcon,
  ScatterViewDotsIcon,
  TabularViewIcon,
  TreemapViewIcon
} from '../components/widgets/IconSet.js'
import { get_as_map } from '../utils/utils.js'
import GrowthScatterChart from '../components/spec_visualisations/GrowthScatterChart.js'
import CagrScatterChart from '../components/spec_visualisations/CagrScatterChart.js'

export const DEFAULT_1D_NON_TIMESERIES_VIEWS                  = [BAR_STACK_ID, COLUMN_STACK_ID, PIE_ID, TREEMAP_ID, TABLE_ID]
export const DEFAULT_1D_NON_TIMESERIES_AVG_VIEWS              = [BAR_STACK_ID, COLUMN_STACK_ID, TABLE_ID]
export const DEFAULT_1D_HISTOGRAM_VIEWS                       = [COLUMN_STACK_ID, TABLE_ID]
export const DEFAULT_2D_TIMESERIES_VIEWS                      = [LINE_ID, AREA_ID, COLUMN_STACK_ID, BUBBLE_ID, HEATMAP_ID, TABLE_ID]
export const DEFAULT_2D_NON_TIMESERIES_VIEWS                  = [BAR_STACK_ID, COLUMN_STACK_ID, HEATMAP_ID, TABLE_ID]
export const DEFAULT_2D_NON_TIMESERIES_GROUPED_VIEWS          = [BAR_STACK_ID, COLUMN_STACK_ID, AREA_ID, BUBBLE_ID, HEATMAP_ID, TABLE_ID] // timeseries-like, but no line chart
export const DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS= [COLUMN_STACK_ID, LINE_ID, AREA_ID, BUBBLE_ID, HEATMAP_ID, TABLE_ID] // line chart is not really correct, but included anyway
export const DEFAULT_2D_HISTOGRAM_VIEWS                       = [COLUMN_STACK_ID, COLUMN_CLUSTER_ID, HEATMAP_ID, TABLE_ID]
export const DEFAULT_GROWTH_VIEWS                             = [GROWTH_TREEMAP_ID, GROWTH_SCATTER_ID, GROWTH_TABLE_ID]

export const DEFAULT_1D_NON_TIMESERIES_VIEW                      = DEFAULT_1D_NON_TIMESERIES_VIEWS[0]
export const DEFAULT_1D_NON_TIMESERIES_AVG_VIEW                  = DEFAULT_1D_NON_TIMESERIES_AVG_VIEWS[0]
export const DEFAULT_1D_NON_TIMESERIES_GROUPED_VIEW              = DEFAULT_1D_NON_TIMESERIES_VIEWS[0]
export const DEFAULT_1D_HISTOGRAM_VIEW                           = DEFAULT_1D_HISTOGRAM_VIEWS[0]
export const DEFAULT_2D_TIMESERIES_VIEW                          = DEFAULT_2D_TIMESERIES_VIEWS[0]
export const DEFAULT_2D_NON_TIMESERIES_VIEW                      = DEFAULT_2D_NON_TIMESERIES_VIEWS[2]
export const DEFAULT_2D_NON_TIMESERIES_GROUPED_VIEW              = DEFAULT_2D_NON_TIMESERIES_GROUPED_VIEWS[0]
export const DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW    = DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS[0]
export const DEFAULT_2D_HISTOGRAM_VIEW                           = DEFAULT_2D_HISTOGRAM_VIEWS[0]
export const DEFAULT_GROWTH_VIEW                                 = DEFAULT_GROWTH_VIEWS[0]

export const ID_TO_VIEW = {
  [PIE_ID]: {
    view_id: PIE_ID,
    name: 'Pie',
    ViewComponent: PieChart
  },
  [LINE_ID]: {
    view_id: LINE_ID,
    name: 'Line',
    ViewComponent: LineChart
  },
  [SCATTER_ID]: {
    view_id: SCATTER_ID,
    name: 'Scatter',
    ViewComponent: ScatterChart
  },
  [AREA_ID]: {
    view_id: AREA_ID,
    name: 'Area',
    ViewComponent: LineAreaChart
  },
  [BUBBLE_ID]: {
    view_id: BUBBLE_ID,
    name: 'Bubble',
    ViewComponent: BubbleChart
  },
  [COLUMN_CLUSTER_ID]: {
    view_id: COLUMN_CLUSTER_ID,
    name: 'Column',
    ViewComponent: ColumnClusteredChart
  },
  [COLUMN_STACK_ID]: {
    view_id: COLUMN_STACK_ID,
    name: 'Column',
    ViewComponent: ColumnChart
  },
  [BAR_CLUSTER_ID]: {
    view_id: BAR_CLUSTER_ID,
    name: 'Bar',
    ViewComponent: BarClusteredChart
  },
  [BAR_STACK_ID]: {
    view_id: BAR_STACK_ID,
    name: 'Bar',
    ViewComponent: BarStackedChart
  },
  [BAR_ID]: {
    view_id: BAR_ID,
    name: 'Bar',
    ViewComponent: null
  },
  [HEATMAP_ID]: {
    view_id: HEATMAP_ID,
    name: 'Heatmap',
    ViewComponent: Heatmap
  },
  [TREEMAP_ID]: {
    view_id: TREEMAP_ID,
    name: 'Treemap',
    ViewComponent: SimpleTreemap
  },
  [GROWTH_TREEMAP_ID]: {
    view_id: GROWTH_TREEMAP_ID,
    name: 'Treemap',
    ViewComponent: GrowthTreemap
  },
  [TABLE_ID]: {
    view_id: TABLE_ID,
    name: 'Table',
    ViewComponent: TabularView
  },
  [EXTENDED_TABLE_ID]: {
    view_id: EXTENDED_TABLE_ID,
    name: 'Table',
    ViewComponent: ExtendedTabularView
  },
  [GROWTH_TABLE_ID]: {
    view_id: GROWTH_TABLE_ID,
    name: 'Table',
    ViewComponent: GrowthTabularView
  },
  [GROWTH_SCATTER_ID]: {
    view_id: GROWTH_SCATTER_ID,
    name: 'Scatter',
    ViewComponent: GrowthScatterChart
  },
  [DISPUTES_TABLE_ID]: {
    view_id: DISPUTES_TABLE_ID,
    name: 'Table',
    ViewComponent: DisputesContainer
  },
  [CHOROPLETH_MAP_ID]: {
    view_id: CHOROPLETH_MAP_ID,
    name: 'Map',
    ViewComponent: ChoroplethMap
  },
  [CAGR_TABLE_ID]: {
    view_id: CAGR_TABLE_ID,
    name: 'Table',
    ViewComponent: CagrTabularView
  },
  [CAGR_SCATTER_ID]: {
    view_id: CAGR_SCATTER_ID,
    name: 'Scatter',
    ViewComponent: CagrScatterChart
  }
}

const VIEW_ICON = [
  { view_id: PIE_ID,               icon: PieViewIcon            },
  { view_id: LINE_ID,              icon: LineViewIcon           },
  { view_id: SCATTER_ID,           icon: ScatterViewDotsIcon    },
  { view_id: AREA_ID,              icon: AreaViewIcon           },
  { view_id: BUBBLE_ID,            icon: BubbleViewIcon         },
  { view_id: COLUMN_CLUSTER_ID,    icon: ColumnsClusterViewIcon },
  { view_id: COLUMN_STACK_ID,      icon: ColumnsStackViewIcon   },
  { view_id: BAR_CLUSTER_ID,       icon: BarsClusterViewIcon    },
  { view_id: BAR_STACK_ID,         icon: BarsStackViewIcon      },
  { view_id: HEATMAP_ID,           icon: HeatmapViewIcon        },
  { view_id: TREEMAP_ID,           icon: TreemapViewIcon        },
  { view_id: TABLE_ID,             icon: TabularViewIcon        },
  { view_id: CHOROPLETH_MAP_ID,    icon: MapViewIcon            },
  { view_id: GROWTH_TABLE_ID,      icon: TabularViewIcon        },
  { view_id: GROWTH_TREEMAP_ID,    icon: TreemapViewIcon        },
  { view_id: GROWTH_SCATTER_ID,    icon: ScatterViewDotsIcon    },
  { view_id: CAGR_TABLE_ID,        icon: TabularViewIcon        },
  { view_id: CAGR_SCATTER_ID,      icon: ScatterViewDotsIcon    }
]

export const VIEW_ID_TO_VIEW_ICON = get_as_map(VIEW_ICON, 'view_id')
