import React from 'react'
import axios from 'axios'
import _ from 'underscore'

import { HYPERSCRIPTS_BASE_URL } from '../constants/urls.js'
import { add_source_err_to_target_err, JSON_POST_HEADER } from './axios_utils.js'
import {
  has_cipher_product,
  has_hs_google_valuation,
  has_trendspotter,
  is_poweruser,
  can_select_nd_version,
  has_reassignment_export,
  has_benchmarking_builder,
} from './user_permissions.js'
import { get_as_map, is_array_non_empty_non_null, to_local_date } from './utils.js'
import { PRODUCT_ND} from '../constants/cipher_product_ids.js'
import { PDF_FILE_FORMAT, ZIP_FILE_FORMAT } from './download_utils.js'
import { fetch_data_for_exec_summary_report} from './report_reader_utils.js'
import { NO_FILTER_ID, ID_TO_TIME_RANGE_FIELD} from '../model/time_range_filter_fields'

import {
  BenchmarkMetricsSetupParams,
  FilingStrategySetupParams,
  GeoComparisonSetupParams,
  NdReportSetupParams,
  OverlapSetupParams,
  PublicationsSetupParams,
  SPIFSetupParams,
  TrendspotterSetupParams
} from '../components/hyperscripts/HyperscriptSetupParams.js'
import BenchmarkingReportSetupParams from '../components/hyperscripts/BenchmarkingReportSetupParams.js'
import {
  APRIORI_CUTOFF_PARAM_NAME,
  BENCHMARK_METRICS_ID,
  BENCHMARKING_REPORT_ID,
  CITATIONS_ID,
  EXECUTIVE_SUMMARY_ID,
  FILING_STRATEGY_ID,
  FILING_STRATEGY_PARAM_NAME,
  FILING_TRENDS_ID,
  GEO_COMPARISON_ID,
  GEO_TIMELINE_ID,
  GEO_WATERFALL_ID,
  GOOGLE_VALUATION_ID,
  HYPERSCRIPT,
  LITIGATIONS_DEFENDANT_ID,
  LITIGATIONS_PLAINTIFF_ID,
  ALERT_SETUP_ID,
  ND_REPORT_ID,
  ORGANISATION_RENAME_ID,
  OVERLAP_ID,
  OVERLAP_PARAM_NAME,
  PUBLICATIONS_ID,
  PVIX_ID,
  QUALITY_ID,
  REASSIGNMENT_ID,
  REPORT_ZIP_ID,
  SPIF_ID,
  STATUS_FILTERS_PARAM_NAME,
  TECHNOLOGY_RENAME_ID,
  TREND_SPOTTER_ID,
  VERSION_PARAM_NAME
} from '../model/hyperscripts.js'
import { SELECTED_PORTFOLIOS } from '../model/deref.js'
import {
  BenchmarkingReportDesc,
  BenchmarkMetricsDesc,
  CitationsDesc,
  DefendantLitigationsDesc,
  ExecutiveSummaryDesc,
  FilingStrategyDesc,
  FilingTrendsDesc,
  GeoComparisonDesc,
  GeoTimelineDesc,
  GeoWaterfallDesc,
  GoogleValuationDesc,
  AlertSetupDesc,
  NDDesc,
  OrganisationRenameDesc,
  OverlapDesc,
  PlaintiffLitigationsDesc,
  PublicationsDesc,
  PVIXDesc,
  QualityDesc,
  ReassignmentDesc,
  ReportZIPDesc,
  SPIFDesc,
  TechnologyRenameDesc,
  TrendSpotterDesc
} from '../components/hyperscripts/HyperscriptDescriptions.js'
import { build_benchmarking_tables } from './benchmarking_report_builder_utils.js'
import { is_nd_report_type } from './report_utils.js'
import { can_view_dispute_data, has_pvix_scores } from './data_availability_utils.js'
import AlertSetupParams from '../components/hyperscripts/AlertSetupParams.js'
import { get_reason_alert_cannot_be_built_from_input, find_first_alert_date } from './alert_report_utils.js'
import {
  ALERT_FREQUENCY_ID_WEEKLY,
  ALERT_TYPE_ID_NEW_FAMILIES,
  NOTHING_TO_ALERT_DESCRIPTION
} from '../constants/alert_reports.js'
import { DEFAULT_ALERT_FILTERS } from '../model/alert_reports.js'
import { overlap_unavailable_reason } from './overlap_utils.js'

const ALERT_SETUP_HS = {
  ...HYPERSCRIPT,
  id: ALERT_SETUP_ID,
  name: 'Create an alert',
  permissions_endpoint: ({report_input}) => !!report_input,
  setup_params: [
    {id: 'alert_id', default_value: null},
    {id: 'alert_name', default_value: null, label: 'Alert name'},
    {id: 'frequency', default_value: ALERT_FREQUENCY_ID_WEEKLY, label: 'Frequency'},
    {id: 'alert_type', default_value: ALERT_TYPE_ID_NEW_FAMILIES, label: 'Alert type'},
    {id: 'empty_alerts', default_value: false, label: NOTHING_TO_ALERT_DESCRIPTION, is_checkbox: true},
    {id: 'start_date', default_value: find_first_alert_date(ALERT_FREQUENCY_ID_WEEKLY), label: 'First alert date'},
    {id: 'filters', default_value: DEFAULT_ALERT_FILTERS, label: 'Alert filters'}
  ],
  option_disabled: (({report_input, report_type, created_by_user, data_creation_date, is_incomplete, params_id: alert_id}) => {
    const reason = get_reason_alert_cannot_be_built_from_input({report_input, report_type, created_by_user, data_creation_date, is_incomplete, alert_id})
    return reason ? {reason} : null
  }),
  setup_params_component: AlertSetupParams,
  description: AlertSetupDesc
}

const LITIGATIONS_DEFENDANT_HS = {
  ...HYPERSCRIPT,
  id: LITIGATIONS_DEFENDANT_ID,
  name: 'Defendant litigations',
  endpoint: fetch_hyperscript_report_fn(LITIGATIONS_DEFENDANT_ID),
  filename_suffix: LITIGATIONS_DEFENDANT_ID,
  permissions_endpoint: (({has_missing_assignee_links, user}) => !has_missing_assignee_links && can_view_dispute_data({user})),
  description: DefendantLitigationsDesc,
  help_article: '28989647770259-Litigations-Defendant-Litigations-Export-Report'
}
const LITIGATIONS_PLAINTIFF_HS = {
  ...HYPERSCRIPT,
  id: LITIGATIONS_PLAINTIFF_ID,
  name: 'Plaintiff litigations',
  endpoint: fetch_hyperscript_report_fn(LITIGATIONS_PLAINTIFF_ID),
  filename_suffix: LITIGATIONS_PLAINTIFF_ID,
  permissions_endpoint: (({has_missing_assignee_links, user}) => !has_missing_assignee_links && can_view_dispute_data({user})),
  description: PlaintiffLitigationsDesc,
  help_article: '28989693514899-Litigations-Plaintiff-Litigations-Export-Report'
}
const QUALITY_HS = {
  ...HYPERSCRIPT,
  id: QUALITY_ID,
  name: 'Cipher quality',
  endpoint: fetch_hyperscript_report_fn(QUALITY_ID),
  filename_suffix: QUALITY_ID,
  description: QualityDesc,
  help_article: '28993150710419-Quality-Export-Report'
}
const FILING_TRENDS_HS = {
  ...HYPERSCRIPT,
  id: FILING_TRENDS_ID,
  name: 'Filing trends',
  endpoint: fetch_hyperscript_report_fn(FILING_TRENDS_ID),
  filename_suffix: FILING_TRENDS_ID,
  description: FilingTrendsDesc,
  help_article: '28994187492755-Filing-Trends-Report'
}
const GEO_WATERFALL_HS = {
  ...HYPERSCRIPT,
  id: GEO_WATERFALL_ID,
  name: 'Geographic waterfall',
  endpoint: fetch_hyperscript_report_fn(GEO_WATERFALL_ID),
  filename_suffix: 'geographic_waterfall',
  description: GeoWaterfallDesc,
  help_article: '29140856476051-Geographic-Waterfall-report'
}
const GEO_COMPARISON_HS = {
  ...HYPERSCRIPT,
  id: GEO_COMPARISON_ID,
  name: 'Geographic comparison',
  endpoint: fetch_hyperscript_report_fn(GEO_COMPARISON_ID),
  filename_suffix: 'geographic_comparison',
  description: GeoComparisonDesc,
  setup_params: [{id: STATUS_FILTERS_PARAM_NAME, default_value: 'granted'}],
  setup_params_component: GeoComparisonSetupParams
}
const CITATIONS_HS = {
  ...HYPERSCRIPT,
  id: CITATIONS_ID,
  name: 'Citations',
  endpoint: fetch_hyperscript_report_fn(CITATIONS_ID),
  filename_suffix: CITATIONS_ID,
  description: CitationsDesc,
  help_article: '28993645655571-Citations-Export-Report'
}
const REASSIGNMENT_HS = {
  ...HYPERSCRIPT,
  id: REASSIGNMENT_ID,
  name: 'Reassignment',
  endpoint: fetch_hyperscript_report_fn(REASSIGNMENT_ID),
  filename_suffix: REASSIGNMENT_ID,
  permissions_endpoint: is_reassignment_enabled,
  description: ReassignmentDesc,
  help_article: '29143369777043-Reassignment-Export-report'
}
const ND_REPORT_HS =  {
  ...HYPERSCRIPT,
  id: ND_REPORT_ID,
  name: (<span><sup>n</sup>/<sub>d</sub> report</span>),
  endpoint: fetch_hyperscript_report_fn(ND_REPORT_ID),
  filename_suffix: ND_REPORT_ID,
  get_filename_suffix: ({user, params}) => {
    if (!can_select_nd_version(user)) return ND_REPORT_ID

    const {version} = params || {}

    return `${ND_REPORT_ID}_v${version}`
  },
  permissions_endpoint: is_nd_enabled,
  setup_params: [
    {id: 'expiry_cutoff', default_value: false},
    {id: VERSION_PARAM_NAME, default_value: 1}
  ],
  setup_params_component: NdReportSetupParams,
  description: NDDesc,
  help_article: '29145595328019-Cross-Licensing-the-N-D-taxonomy-of-Classifiers'
}
const PUBLICATIONS_HS = {
  ...HYPERSCRIPT,
  id: PUBLICATIONS_ID,
  name: 'Publications',
  endpoint: fetch_hyperscript_report_fn(PUBLICATIONS_ID),
  filename_suffix: PUBLICATIONS_ID,
  setup_params: [
    {id: 'technologies_together', default_value: false, label: 'All technologies in one sheet'},
    {id: 'country_column', default_value: false, label: 'Include territories column'},
    {id: 'assignee_column', default_value: false, label: 'Include assignees column'},
    {id: 'grants_only', default_value: false, label: 'Show grants only'},
    {id: 'active_only', default_value: false, label: 'Show active families only'},
    {id: 'scores', default_value: false, label: 'Include scores', check_if_available: ({report_has_scores}) => { return report_has_scores }}
  ],
  setup_params_component: PublicationsSetupParams,
  description: PublicationsDesc
}
const EXECUTIVE_SUMMARY_HS = {
  ...HYPERSCRIPT,
  id: EXECUTIVE_SUMMARY_ID,
  name: 'Executive Summary',
  endpoint: fetch_data_for_exec_summary_report,
  filename_suffix: EXECUTIVE_SUMMARY_ID,
  file_format: PDF_FILE_FORMAT,
  description: ExecutiveSummaryDesc,
  help_article: '28993750369939-Executive-Summary'
}
const TREND_SPOTTER_HS = {
  ...HYPERSCRIPT,
  id: TREND_SPOTTER_ID,
  name: 'Trend Spotter',
  endpoint: fetch_hyperscript_report_fn(TREND_SPOTTER_ID),
  permissions_endpoint: ({user}) => has_trendspotter(user),
  setup_params:[
    {id: APRIORI_CUTOFF_PARAM_NAME, default_value: 1, label: 'Trend recency cutoff years'}
  ],
  setup_params_component: TrendspotterSetupParams,
  file_format: null,
  description: TrendSpotterDesc
}
const PVIX_HS = {
  ...HYPERSCRIPT,
  id: PVIX_ID,
  name: 'PVIX Scores',
  endpoint: fetch_hyperscript_report_fn(PVIX_ID),
  permissions_endpoint: ({user, ref_data}) => {
    const { schema_version } = ref_data || {}
    return has_pvix_scores({schema_version, user})
  },
  filename_suffix: PVIX_ID,
  description: PVIXDesc,
  help_article: '28992181594003-PVIX-Scores-report'
}
const SPIF_HS = {
  ...HYPERSCRIPT,
  id: SPIF_ID,
  name: 'SPIF',
  endpoint: fetch_hyperscript_report_fn(SPIF_ID),
  filename_suffix: SPIF_ID,
  setup_params: [
    {id: 'include_port_tech', default_value: false, label: 'Include organisation and technology'},
    {id: 'include_comment', default_value: false, label: 'Include SPIF processing comments'}
  ],
  setup_params_component: SPIFSetupParams,
  description: SPIFDesc,
  help_article: '29145480620179-SPIF-Export-Report',
}
const BENCHMARK_METRICS_HS = {
  ...HYPERSCRIPT,
  id: BENCHMARK_METRICS_ID,
  name: 'Benchmark metrics',
  endpoint: fetch_hyperscript_report_fn(BENCHMARK_METRICS_ID),
  filename_suffix: BENCHMARK_METRICS_ID,
  permissions_endpoint: ({user}) => is_poweruser(user),
  setup_params: [
    {id: 'claims', default_value: false, label: 'Include claims data (slower)?'}
  ],
  setup_params_component: BenchmarkMetricsSetupParams,
  description: BenchmarkMetricsDesc
}
const BENCHMARKING_REPORT_HS = {
  ...HYPERSCRIPT,
  id: BENCHMARKING_REPORT_ID,
  name: 'Benchmarking report',
  endpoint: build_benchmarking_tables,
  filename_suffix: BENCHMARKING_REPORT_ID,
  permissions_endpoint: ({user, created_by_user, is_set_theory_report}) => created_by_user && is_set_theory_report && has_benchmarking_builder(user),
  option_disabled: ({has_benchmarking_org_lists}) => !has_benchmarking_org_lists,
  setup_params: [
    {id: 'tech_areas', default_value: [], label: 'Technology groupings'},
    {id: 'company_lists', default_value: [], label: 'Company lists'},
    {id: 'is_overwrite', default_value: false, label: 'Overwrite', is_checkbox: true}
  ],
  is_start_enabled: ({params, benchmarking_params}) => {
    const has_benchmarking_tables_already = _.some(benchmarking_params, param_array => is_array_non_empty_non_null(param_array))
    return is_array_non_empty_non_null(params['tech_areas']) && (!has_benchmarking_tables_already || (params['is_overwrite'] === true))
  },
  setup_params_component: BenchmarkingReportSetupParams,
  description: BenchmarkingReportDesc
}
const FILING_STRATEGY_HS = {
  ...HYPERSCRIPT,
  id: FILING_STRATEGY_ID,
  name: 'Strategic filing analysis',
  endpoint: fetch_hyperscript_report_fn(FILING_STRATEGY_ID),
  filename_suffix: FILING_STRATEGY_ID,
  setup_params: [
    {id: FILING_STRATEGY_PARAM_NAME, default_value: null }
  ],
  setup_params_component: FilingStrategySetupParams,
  is_start_enabled: ({params, deref_data}) => {
    const selections = deref_data || {}

    const selected_portfolios = selections[SELECTED_PORTFOLIOS] || []

    return ((params['ref_portfolio'] != null) && (selected_portfolios.length >= 2 ))
  },
  description: FilingStrategyDesc
}
const TECHNOLOGY_RENAME_HS = {
  ...HYPERSCRIPT,
  id: TECHNOLOGY_RENAME_ID,
  name: 'Technology rename',
  filename_suffix: TECHNOLOGY_RENAME_ID,
  permissions_endpoint: ({user}) => is_poweruser(user),
  endpoint: fetch_hyperscript_report_fn(TECHNOLOGY_RENAME_ID),
  description: TechnologyRenameDesc
}
const ORGANISATION_RENAME_HS = {
  ...HYPERSCRIPT,
  id: ORGANISATION_RENAME_ID,
  name: 'Organisation rename',
  filename_suffix: ORGANISATION_RENAME_ID,
  permissions_endpoint: ({user}) => is_poweruser(user),
  endpoint: fetch_hyperscript_report_fn(ORGANISATION_RENAME_ID),
  description: OrganisationRenameDesc
}
const OVERLAP_HS = {
  ...HYPERSCRIPT,
  id: OVERLAP_ID,
  name: 'Overlap',
  filename_suffix: OVERLAP_ID,
  permissions_endpoint: ({user}) => is_poweruser(user),
  endpoint: fetch_hyperscript_report_fn(OVERLAP_ID),
  setup_params: [
    {id: OVERLAP_PARAM_NAME, default_value: false}
  ],
  is_start_enabled: ({params, deref_data, report_technology_partitioning}) => {
    const is_org_selected = params[OVERLAP_PARAM_NAME]
    return !overlap_unavailable_reason({deref_data, is_org_selected, technology_partitioning: report_technology_partitioning})
  },
  setup_params_component: OverlapSetupParams,
  description: OverlapDesc
}

const GOOGLE_VALUATION_HS = {
  ...HYPERSCRIPT,
  id: GOOGLE_VALUATION_ID,
  name: 'Google Valuation Report',
  filename_suffix: GOOGLE_VALUATION_ID,
  permissions_endpoint: ({user}) => has_hs_google_valuation(user),
  endpoint: fetch_hyperscript_report_fn(GOOGLE_VALUATION_ID),
  description: GoogleValuationDesc
}

const GEO_TIMELINE_HS = {
  ...HYPERSCRIPT,
  id: GEO_TIMELINE_ID,
  name: 'Application timeline',
  filename_suffix: GEO_TIMELINE_ID,
  endpoint: fetch_hyperscript_report_fn(GEO_TIMELINE_ID),
  description: GeoTimelineDesc
}

const REPORT_ZIP_HS = {
  ...HYPERSCRIPT,
  id: REPORT_ZIP_ID,
  name: 'Zipped report data',
  filename_suffix: 'data',
  permissions_endpoint: ({user}) => is_poweruser(user),
  file_format: ZIP_FILE_FORMAT,
  endpoint: fetch_hyperscript_report_fn(REPORT_ZIP_ID),
  description: ReportZIPDesc
}

export const HYPERSCRIPTS = [
  LITIGATIONS_DEFENDANT_HS, LITIGATIONS_PLAINTIFF_HS, QUALITY_HS, FILING_TRENDS_HS,
  GEO_WATERFALL_HS, GEO_COMPARISON_HS, CITATIONS_HS, REASSIGNMENT_HS, ND_REPORT_HS,
  PUBLICATIONS_HS, PVIX_HS, BENCHMARK_METRICS_HS, BENCHMARKING_REPORT_HS, FILING_STRATEGY_HS,
  TECHNOLOGY_RENAME_HS, ORGANISATION_RENAME_HS, OVERLAP_HS, EXECUTIVE_SUMMARY_HS, TREND_SPOTTER_HS,
  SPIF_HS, GOOGLE_VALUATION_HS, GEO_TIMELINE_HS, REPORT_ZIP_HS, ALERT_SETUP_HS
]

export const HYPERSCRIPT_BY_ID = get_as_map(HYPERSCRIPTS, 'id')

function build_hs_param_payload(selections, external_report_id, user_id) {
  const {
    selected_portfolio_ids = [],
    selected_tech_ids = [],
    selected_geo_ids = [],
    selected_timerange = {}
  } = selections

  let time_filter_data = null
  if (selected_timerange.field_id !== NO_FILTER_ID) {
    time_filter_data = {
      time_range: selected_timerange.timerange,
      field: ID_TO_TIME_RANGE_FIELD[selected_timerange.field_id]
    }
  }

  return {
    portfolio: selected_portfolio_ids,
    technology: selected_tech_ids,
    territory: selected_geo_ids,
    time_filter: time_filter_data,
    external_id: external_report_id,
    user_id: user_id
  }
}

function fetch_hyperscript_report_fn(hs_id) {
  return (args) => {
    const {user, internal_report_id, external_report_id, selections, setup_params} = args
    const body_payload = build_hs_param_payload(selections, external_report_id, user['user_id'])

    // Special case of N/D
    let override_params = null
    if (hs_id === ND_REPORT_ID){
      const { expiry_cutoff } = setup_params
      override_params = expiry_cutoff ? { expiry_cutoff: to_local_date(expiry_cutoff) } : null
    }

    return axios.post(`${HYPERSCRIPTS_BASE_URL}/${hs_id}/${internal_report_id}`, body_payload, {
      responseType: 'arraybuffer',
      params: override_params ? override_params : setup_params
    })
      .then(response => response.data)
      .catch(err => {
        throw add_source_err_to_target_err(
          err, new Error(),`Error calling ${hs_id} hyperscript: `
        )
      })
  }
}

export function fetch_trainingset_analysis(training_set_id, pos_pfam_ids, neg_pfam_ids) {
  return axios.post(
    `${HYPERSCRIPTS_BASE_URL}/trainingset_analysis/${training_set_id}`,
    { positive: pos_pfam_ids, negative: neg_pfam_ids },
    { headers: JSON_POST_HEADER, responseType: 'arraybuffer' }
  )
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(),`Error calling trainingset_analysis hyperscript: `)
    })
}

function is_nd_enabled({user, report_type}) {
  if (!user || !report_type) return false
  return has_cipher_product(user, PRODUCT_ND) && is_nd_report_type(report_type)
}

function is_reassignment_enabled({user}) {
  if (!user) return false
  return is_poweruser(user) || has_reassignment_export(user) //until poweruser role can be updated with has_reassignment_export
}

export function build_params(hyperscript) {
  const {setup_params} = hyperscript
  if (!setup_params) return null

  let params = {}

  setup_params.forEach(item => {
    const {id, default_value} = item
    params = {...params, [id]: default_value}
  })

  return params
}

export function arraybuffer_to_string(buf) {
  return String.fromCharCode.apply(null, new Uint8Array(buf))
}