import React from 'react'
import cn from 'classnames'

import TextLink from '../widgets/TextLink.js'
import { Tag } from './family_tag_utils'
import { AddCircleIcon } from '../widgets/IconSet'

import Label from '../widgets/Label.js'

interface FamilyTagLinkProps {
  className: string,
  tag: Tag,
  on_click_handler: Function
}

export const FamilyTagLink = ({className, tag, on_click_handler}: FamilyTagLinkProps) => {

  const total_tag_group_families = tag.values.reduce((total, tag_value) => (total + tag_value.families_count), 0)
  const any_families: boolean = total_tag_group_families > 0
  const title = tag.name || ''

  function render(): JSX.Element {
    return (
      <div className={cn(className)}>
        {title &&
          <div className='d-flex'>
            <Label className='my-auto'>{title}</Label>
            {/*@ts-expect-error*/}
            <TextLink
              className='ms-2 d-flex'
              onClick={() => on_click_handler(tag)}
              title={'Add tag group to the basket (' + total_tag_group_families +')' }
              disable={!any_families}
              no_decoration
            >
              <AddCircleIcon />
            </TextLink>
          </div>
        }
        <div className='d-flex flex-wrap'>
          {tag.values.map((tag_value, i) => {
            return (
              <span key={i}>
                {/*@ts-expect-error*/}
                <TextLink
                  className='me-3'
                  onClick={() => on_click_handler(tag, tag_value)}
                  disable={tag_value.families_count === 0}
                  title={tag_value.families_count + ' families'}
                >
                  {tag_value.value + ' (' + tag_value.families_count + ')'}
                </TextLink>
              </span>
            )
          })}
        </div>
      </div>
    )
  }

  return render()
}


