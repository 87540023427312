import React from 'react'
import { DropdownItem } from 'reactstrap'
import cn from 'classnames'

import BaseDropdown from './BaseDropdown.js'

/**
 * @param {} className
 * @param {} values An array of values
 * @param {} selected_value
 * @param {} on_change_value
 * @param {} right If true, aligns the dropdown to the right.
 * @param {} buttonClassName
 * @param {} menuClassName
 */
const ValueDropdownSelector = ({
  className,
  values,
  selected_value,
  on_change_value,
  right,
  buttonClassName,
  menuClassName,
  disabled,
  format_value_fn
}) => {
  return (
    <BaseDropdown
      className={cn(className)}
      label={format_value_fn ? format_value_fn(selected_value) : selected_value}
      right={right}
      buttonClassName={buttonClassName}
      menuClassName={menuClassName}
      disabled={disabled}
    >
      {values.map((value, idx) =>
        <DropdownItem
          key={idx}
          active={value === selected_value}
          onClick={on_change_value.bind(null, value)}
        >
          <span>{format_value_fn ? format_value_fn(value) : value}</span>
        </DropdownItem>
      )}
    </BaseDropdown>
  )
}

export default ValueDropdownSelector