import React from 'react'
import cn from 'classnames'

import { display_score } from '../utils/training_set_utils.js'
import { get_score_colour } from '../utils/score_utils.js'

const ClassifierScoreDisplay = ({score, className}) => {

  const score_color = get_score_colour(score)

  if (score == null) {
    return null
  }

  return (
    <span
      className={cn('fs-unmask', className)}
      style={score_color != null ? { backgroundColor: score_color, color: '#ffffff' } : null}
    >
      {display_score(score)}
    </span>
  )
}

export default ClassifierScoreDisplay