import React from 'react'
import cn from 'classnames'

import { CheckIcon, ExclamationMarkIcon, QuestionMarkIcon } from '../widgets/IconSet.js'

import s from './MatchSymbols.module.scss'

export const MatchFoundIcon = ({disabled}) => {
  return <span className={cn(s.icon, {[s.__found]: !disabled} )}><CheckIcon /></span>
}

export const MatchNotFoundIcon = ({disabled}) => {
  return <span className={cn(s.icon, {[s.__not_found]: !disabled})}><ExclamationMarkIcon /></span>
}

export const MatchAmbiguousIcon = ({disabled}) => {
  return <span className={cn(s.icon, {[s.__ambiguous]: !disabled})}><QuestionMarkIcon /></span>
}