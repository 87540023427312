import React, { useState } from 'react'

import { PrimaryButton } from '../../widgets/Button.js'
import Modal from '../../widgets/Modal.js'

import ReportNameInput from '../../ReportNameInput.js'

import {
  is_valid_report_name, normalise_name,
  remove_not_allowed_chars_from_text
} from '../../../utils/name_utils.js'

const RenameReportModal = ({report_name, rename_report_handler, on_close}) => {

  const [new_report_name, set_new_report_name] = useState(report_name)

  function on_change_report_name_input(value) {
    set_new_report_name(remove_not_allowed_chars_from_text(value))
  }

  function save_report_name() {
    const normalised_report_name = normalise_name(new_report_name)

    rename_report_handler(normalised_report_name)
    on_close()
  }

  function on_key_up(e) {
    switch (e.keyCode) {
      case 13:
        e.stopPropagation()
        e.preventDefault()
        if (can_update()) {
          save_report_name()
        }
        break
      case 27:
        e.preventDefault()
        on_close()
        break
      default:
        return
    }
  }

  function can_update() {
    return is_valid_report_name(new_report_name) && new_report_name !== report_name
  }

  function primary_button() {
    return (
      <PrimaryButton
        onClick={() => save_report_name()}
        disabled={!can_update()}
      >
        Save
      </PrimaryButton>
    )
  }

  return (
    <Modal
      on_hide={on_close}
      primary_button={primary_button()}
      title='Rename report'
      close_label='Cancel'
      size='sm'
    >
      <ReportNameInput
        report_name={new_report_name}
        on_change={on_change_report_name_input}
        on_key_up={on_key_up}
      />
    </Modal>
  )

}

export default RenameReportModal