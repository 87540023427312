import React from 'react'

import ContainerFullWidth from '../ContainerFullWidth.js'
import { PdfViewer } from '../widgets/PdfViewer.js'
import { track_visit_event } from '../../utils/tracking_utils.js'

const ReportSchemaPdfView = () => {
  //the S3 bucket is s3://pdf-static.cipher.ai/pdf/report_schema.pdf

  document.title = 'Cipher: Report Schema'
  track_visit_event('page="report_schema"')

  return (
    <ContainerFullWidth className='px-0 w-100 h-100'>
      <PdfViewer
        src='https://static.cipher.ai/pdf/report_schema.pdf'
        title='Cipher report schema'
        className='w-100 h-100'
      />
    </ContainerFullWidth>
  )
}

export default ReportSchemaPdfView