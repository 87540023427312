import React from 'react'
import cn from 'classnames'
import { DropdownItem } from 'reactstrap'

import BaseDropdown from '../widgets/BaseDropdown.js'
import { get_report_region_grouping_by_id } from '../../utils/regions_utils.js'
import { REPORT_REGION_GROUPING_OPTIONS } from '../../constants/regions.js'

import s from './RegionGroupingControls.module.scss'

const RegionGroupingControls = ({spec, report_region_grouping, on_change_report_region_grouping, className}) => {

  const {show_region_grouping_selector} = spec

  if (!show_region_grouping_selector) {
    return null
  }

  const selected_region_grouping_label = get_report_region_grouping_by_id(report_region_grouping).label

  return (
    <div
      className={cn('d-inline-flex', 'align-items-center', className)}
    >
      <span
        className='me-2'
      >
          Group by
      </span>

      <span
        className='me-2'
      >
        <BaseDropdown
          label={selected_region_grouping_label}
          menuClassName={s.dropdown_menu}
        >
          {REPORT_REGION_GROUPING_OPTIONS.map((item, i) => (
            <DropdownItem
              key={i}
              onClick={() => on_change_report_region_grouping(item.id)}
              active={report_region_grouping === item.id}
            >
              {item.label}
            </DropdownItem>
          ))}
        </BaseDropdown>
      </span>
    </div>
  )
}

export default RegionGroupingControls