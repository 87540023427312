import React, { useEffect, useState } from 'react'
import _ from 'underscore'

import { withUser } from '../../UserContext.js'
import AdminPageWrapper from '../AdminPageWrapper.js'
import UserGroupSearch from '../UserGroupSearch.js'
import CopySet from './CopySet.js'
import RemoveSet from './RemoveSet.js'
import AddSet from './AddSet.js'
import { USER_CUSTOM_CHART_SETS } from '../../../model/user_settings.js'
import { save_user_setting_by_id } from '../../../utils/user_settings_utils.js'
import { save_group_setting_by_id } from '../../../utils/user_group_settings_utils.js'
import { send_error_to_sentry } from '../../../utils/sentry_utils.js'
import { ACTION_ADD, ACTION_COPY, ACTION_REMOVE, AVAILABLE_ACTIONS } from './chart_sets_setup_utils.js'
import { fetch_all_active_keycloak_groups } from '../../../utils/user_group_utils.js'
import { NavTab, NavWrapper } from '../../widgets/NavTab.js'

const ChartSetsSetup = () => {

  const [groups, set_groups] = useState(null)

  const [is_fetching_groups, set_is_fetching_groups] = useState(true)
  const [selected_group, set_selected_group] = useState(null)
  const [selected_group_chart_sets, set_selected_group_chart_sets] = useState(null)
  const [selected_user, set_selected_user] = useState(null)
  const [selected_user_chart_sets, set_selected_user_chart_sets] = useState(null)
  const [selected_action, set_selected_action] = useState(null)

  useEffect(() => {

    fetch_all_active_keycloak_groups()
      .catch(error => {
        set_is_fetching_groups(false)
        // No point in notifying user, but log error in sentry.
        send_error_to_sentry(error, {})
        throw error
      })
      .then(group_data => {
        const groups = _.sortBy(group_data, g => (g.name || '').toLowerCase())
        set_groups(groups)
        set_is_fetching_groups(false)
      })
  }, [])

  function on_get_group_sets(settings={}) {
    const group_sets = settings['group_custom_chart_sets'] || []
    set_selected_group_chart_sets(group_sets)
  }

  function on_get_user_sets(settings={}) {
    const user_sets = settings[USER_CUSTOM_CHART_SETS] || []
    set_selected_user_chart_sets(user_sets)
  }

  function save_updated_sets(updated_sets) {
    return (selected_user_or_group_type === 'group') ? on_save_group_settings(updated_sets) : on_save_user_settings(updated_sets)
  }

  function on_save_user_settings(updated_sets) {
    const {id} = selected_user || {}

    save_user_setting_by_id(id, USER_CUSTOM_CHART_SETS, updated_sets)
      .catch(error => {
        send_error_to_sentry(error, {})
        throw error
      })
      .then(() => {
        set_selected_user_chart_sets(updated_sets)
      })
  }

  function on_save_group_settings(updated_sets) {
    const {id} = selected_group || {}
    save_group_setting_by_id(id, 'group_custom_chart_sets', updated_sets)
       .catch(error => {
         send_error_to_sentry(error, {})
         throw error
       })
       .then(() => {
         set_selected_group_chart_sets(updated_sets)
       })
  }

  function on_change_action(action) {
    set_selected_action(action)
  }

  const current_sets = selected_user_chart_sets || set_selected_group_chart_sets || []
  const selected_user_or_group = selected_user || selected_group
  const selected_user_or_group_type =  !(selected_user || selected_group) ? null : selected_user ? 'user' : 'group'

  return (
    <AdminPageWrapper page_name='Chart sets'>
      <label className='mt-3'>
        Select an entire group or an individual user to update chart sets:
      </label>

      <UserGroupSearch
        show_spinner={is_fetching_groups}
        groups={groups}
        selected_group={selected_group}
        selected_group_chart_sets={selected_group ? selected_group_chart_sets || [] : null}
        selected_user={selected_user}
        selected_user_chart_sets={selected_user ? selected_user_chart_sets || [] : null}
        on_select_group_handler={set_selected_group}
        on_get_group_settings_handler={on_get_group_sets}
        on_select_user_handler={set_selected_user}
        on_get_user_settings_handler={on_get_user_sets}

        select_user_label='Update group chart sets or select user'
      />


        <NavWrapper className='mt-3'>
          {AVAILABLE_ACTIONS.map(action => {
            const {id, name} = action
            return (
              <NavTab key={id} className='me-4' disabled={!(selected_group || selected_user)} is_active={selected_action === id} on_click={() => on_change_action(id)}>
                {name}
              </NavTab>
            )
          })}
        </NavWrapper>

      <div className='py-4 px-2'>
        {selected_action === ACTION_COPY &&
          <CopySet
            groups={groups}
            selected_target_user_or_group={selected_user_or_group}
            current_sets={current_sets}
            on_save_handler={save_updated_sets}
          />
        }

        {selected_action === ACTION_ADD &&
          <AddSet
            selected_target_user_or_group={selected_user_or_group}
            current_sets={current_sets}
            on_save_handler={save_updated_sets}
          />
        }

        {selected_action === ACTION_REMOVE &&
          <RemoveSet
            selected_target_user_or_group={selected_user_or_group}
            current_sets={current_sets}
            on_save_handler={save_updated_sets}
          />
        }
      </div>

    </AdminPageWrapper>
  )
}

export default withUser(ChartSetsSetup)