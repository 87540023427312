import React, { useState, useEffect } from 'react'
import {withRouter} from 'react-router-dom'
import axios from 'axios'

import { get_domain_prefix } from '../../utils/utils.js'
import { Input } from 'reactstrap'
import { PrimaryButton } from '../widgets/Button.js'
import {SOURCES, FAMILY } from '../../constants/paths.js'
import { is_cipher_family_id_valid } from '../../utils/family_view_utils.js'
import { withUser } from '../UserContext.js'
import { is_cipher_engineering } from '../../utils/user_permissions.js'
import PageNotFound from '../PageNotFound.js'
import Spinner from '../widgets/Spinner.js'
import ErrorBody from '../ErrorBody.js'
import AdminPageWrapper from '../admin/AdminPageWrapper.js'

const FamilySourcesView = ({history, location, user}) => {
  document.title = 'Cipher: Family Sources'

  const [show_spinner, set_show_spinner] = useState(true)
  const [family_id, set_family_id] = useState(null)
  const [family_data, set_family_data] = useState(null)

  const [fetch_error, set_fetch_error] = useState(null)

  const {pathname, search} = location

  const cipher_family_id_from_url = get_family_id_from_url(pathname)
  const family_id_value = family_id == null ? cipher_family_id_from_url : family_id

  function get_family_id_from_url(pathname) {
    const pathname_items = pathname.split('/')

    return pathname_items.length === 4 ? pathname_items[2] : null
  }

  useEffect(() => {
    if (!cipher_family_id_from_url || cipher_family_id_from_url === '') {
      set_show_spinner(false)
      return
    }

    set_show_spinner(true)
    const src = `${get_domain_prefix()}/api/family_html/${cipher_family_id_from_url}${search ? search : ''}`

    axios.get(src)
      .then(response => {
        const { data } = response
        set_family_data(data)
        set_show_spinner(false)
      })
      .catch(e => {
        set_fetch_error(e)
        set_show_spinner(false)
      })
  }, [cipher_family_id_from_url, search])

  function do_update_url(family_id_value) {
    history.push(`${FAMILY}/${family_id_value}/${SOURCES}`)
    window.location.reload()
  }

  function on_change_from_family_id_input(e) {
    set_family_id(e.target.value)
  }

  function on_key_down_from_family_id_input(e) {
    if (e.keyCode === 13) {
      e.preventDefault()
      if (is_cipher_family_id_valid(family_id)) {
        do_update_url(family_id)
      }
    }
  }

  if (!is_cipher_engineering(user)) {
    return (
      <PageNotFound location={location} />
    )
  }

  return (
    <AdminPageWrapper page_name={family_id_value || ''} admin_page_name='Family sources' admin_page_path={`${FAMILY}/${family_id_value}/${SOURCES}`}>

      <label>Family ID</label>
      <div className='d-flex'>
        <Input
          value={family_id_value || ''}
          onChange={on_change_from_family_id_input}
          onKeyDown={on_key_down_from_family_id_input}
          autoComplete='off'
          placeholder='Enter family ID'
        />
        <PrimaryButton
          onClick={() => {do_update_url(family_id_value)}}
          disabled={!is_cipher_family_id_valid(family_id_value)}
          className='ms-1'
        >
          Find
        </PrimaryButton>
      </div>

      {show_spinner &&
        <div>
          <Spinner />
        </div>
      }

      {!show_spinner && fetch_error &&
        <ErrorBody
          error={fetch_error}
          context={'fetching family data'}
        />
      }

      {!show_spinner && family_data &&
        <div className='position-relative h-100 py-3'>
          <div
            dangerouslySetInnerHTML={{__html: family_data}}
          />
        </div>
      }
    </AdminPageWrapper>
  )
}

export default withUser(withRouter(FamilySourcesView))