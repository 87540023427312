import React from 'react'
import cn from 'classnames'

import { ASCENDING, DESCENDING } from '../../model/sort_directions.js'
import {
  ColumnSortedAscIcon,
  ColumnSortedDescIcon,
  ColumnUnsortedIcon,
} from '../widgets/TableColumnHeader.js'

import s from './SortingColumnHeaderCell.module.scss'

/**
 * Custom column header cell for ReactTable,
 * including up/down buttons for sorting.
 * @param {} field Object with 'id' and 'name' properties.
 * @param {} selected_sort_field_id
 * @param {} selected_sort_direction_id
 * @param {} on_change_sort_field_id_and_sort_direction_id
 * @param {} sort_disabled
 * @returns {}
 */
const SortingColumnHeaderCell = (
  {
    field,
    column_name_to_display,
    selected_sort_field_id,
    selected_sort_direction_id,
    on_change_sort_field_id_and_sort_direction_id,
    sort_disabled,

    className
}) => {
  const {id, sortable, name, sub_label } = field || {}

  const no_sort = ((sortable === false) || sort_disabled)

  const is_sorted_by   = (selected_sort_field_id === id)
  const is_sorted_desc = is_sorted_by && (selected_sort_direction_id === DESCENDING)
  const is_sorted_asc  = is_sorted_by && (selected_sort_direction_id === ASCENDING)

  function toggle() {
    const direction = is_sorted_by && selected_sort_direction_id === DESCENDING ? ASCENDING : DESCENDING
    on_change_sort_field_id_and_sort_direction_id(id, direction)
  }

  return (
    <div
      className={cn('d-flex', s.header_wrapper, {[s.header_wrapper__no_sort]: no_sort}, className)}
    >
      <div
        className={cn('text-start', s.label, {[s.label__sortable]: !no_sort})}
        onClick={no_sort ? null : toggle}
      >
        {column_name_to_display || name}
        {sub_label &&
          <span className='ms-2'>({sub_label})</span>
        }
      </div>

      {!no_sort &&
        <>
          {!is_sorted_by &&
            <ColumnUnsortedIcon on_click={() => {on_change_sort_field_id_and_sort_direction_id(id, ASCENDING)}} className={s.sort_icon} />
          }

          {is_sorted_desc &&
            <ColumnSortedDescIcon on_click={() => {on_change_sort_field_id_and_sort_direction_id(id, ASCENDING)}} className={s.sort_icon} />
          }

          {is_sorted_asc &&
            <ColumnSortedAscIcon on_click={() => {on_change_sort_field_id_and_sort_direction_id(id, DESCENDING)}} className={s.sort_icon} />
          }
        </>
      }
    </div>
  )
}

export default SortingColumnHeaderCell