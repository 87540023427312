import React from 'react'
import { withRouter } from 'react-router-dom'
import { DropdownItem } from 'reactstrap'

import AdminPageWrapper from './AdminPageWrapper.js'
import { MONITOR } from '../../constants/paths.js'
import { ID_TO_MONITOR_TOOL, MONITOR_TOOLS } from '../../model/monitor_tools.js'
import BaseDropdown from '../widgets/BaseDropdown.js'

const MonitorPageWrapper = ({history, monitor_page, report_id, children}) => {
  const {name: current_page_name = ''} = ID_TO_MONITOR_TOOL[monitor_page || ''] || {}

  function go_to_monitoring_tool(tool_id) {
    if (tool_id !== monitor_page) {
      history.push(ID_TO_MONITOR_TOOL[tool_id].get_path(report_id))
    }
  }

  const breadcrumbs_tools = (
    <BaseDropdown
      label={current_page_name !== '' ? current_page_name : 'Monitoring tools'}
      right
    >
      {MONITOR_TOOLS.map((tool, i) => {
        return (
          <DropdownItem key={i} onClick={() => go_to_monitoring_tool(tool.id)} active={tool.id === monitor_page}>
            {tool.name}
          </DropdownItem>
        )
      })}
    </BaseDropdown>
  )

  return (
    <AdminPageWrapper
      page_name={current_page_name}
      admin_page_name='Monitor'
      admin_page_path={MONITOR}

      breadcrumbs_tools={breadcrumbs_tools}
    >
      {children}
    </AdminPageWrapper>
  )
}

export default withRouter(MonitorPageWrapper)