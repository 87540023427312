import React from 'react'
import cn from 'classnames'

import s from './DashboardTileInner.module.scss'

export const DashboardTileInner = ({children, className}) => {
  return (
    <div className={cn('d-flex align-items-start flex-column p-2', s.panel, className)}>
      {children}
    </div>
  )
}