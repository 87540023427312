import React from 'react'
import { DropdownItem } from 'reactstrap'
import _ from 'underscore'
import cn from 'classnames'

import BaseDropdown from './BaseDropdown.js'
import { CheckboxAndLabel } from './CheckboxAndLabel.js'
import { add_or_remove_item_from_array } from '../../utils/utils.js'

const MultiItemDropdownSelector = ({
  className,
  rowClassName,
  items,
  selected_item_ids,
  label,
  labelClassName,
  on_change_item_ids,
  right,
  buttonClassName,
  menuClassName,
  disabled
}) => {

  return (
    <BaseDropdown
      className={cn(className)}
      label={label}
      labelClassName={labelClassName}
      right={right}
      buttonClassName={buttonClassName}
      menuClassName={menuClassName}
      disabled={disabled}
    >
      {items.map((item, idx) =>
        <DropdownItem
          className={cn('d-flex', rowClassName)}
          key={idx}
          toggle={false} // on click, keep the dropdown open
          onClick={() => on_change_item_ids(add_or_remove_item_from_array(selected_item_ids, item.id))}
          disabled={item.disabled}
        >
          <CheckboxAndLabel
            is_checked={_.contains(selected_item_ids, item.id)}
            className='me-1'
            label={item.name}
          />
        </DropdownItem>
      )}
    </BaseDropdown>
  )
}

export default MultiItemDropdownSelector