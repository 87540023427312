import React, {useEffect, useState} from 'react'

import { LoadSelectionModal, SaveSelectionModal, UserChartSetsList } from './SelectionMenu.js'
import DatasetGroup from './DatasetGroup.js'
import { SELECTED_GROUP } from '../../model/spec_groups/selected.js'
import { DISPLAY_ALL } from '../../utils/spec_group_utils.js'

import { EXCEL_FILE_EXT, POWERPOINT_FILE_EXT } from '../../utils/download_utils.js'
import DownloadAllChartsModal from './DownloadAllChartsModal.js'
import { scroll_window_to_top } from '../../utils/viewer_utils.js'
import {
  get_selected_items_for_display, SELECTED_ITEMS_CIPHER_ORDER, SELECTED_ITEMS_SELECTION_ORDER,
} from '../../utils/main_items_selection_utils.js'

const SelectedView = (
  {
    display_mode,
    zoomed_dataset_id,
    is_group_collapsed,
    toggle_group,

    show_all_datasets_refresh,
    enable_all_dataset_refresh,
    all_datasets_refresh_handler,

    user_chart_sets,
    default_chart_selection,
    on_save_user_chart_set,
    on_remove_user_chart_set,
    on_select_user_chart_set,
    on_clear_selected_datasets,
    change_default_chart_selection_handler,

    selected_items,
    spec_id_to_fetch_obj,
    data_creation_date,
    ref_data,
    deref_data,
    show_charts_in_thumbnails,
    minimal_selections,
    selections,
    report_title,
    internal_report_id,
    report_series_sort,

    selected_charts_display_order_preference,
    change_selected_charts_display_order_preference,

    on_datasets_deselect,
    on_datasets_reshuffle,
    on_dataset_zoom,

    on_fetch_data_for_datasets,
    is_bulk_download_enabled,

    on_next_item,
    on_prev_item,

    className
  }) => {

  const [is_save_as_chart_set, set_is_save_as_chart_set] = useState(false)
  const [is_load_chart_set, set_is_load_chart_set] = useState(false)
  const [selected_download_format, set_selected_download_format] = useState(null)
  const [planned_bulk_download, set_planned_bulk_download] = useState(null)

  useEffect(() => {
    scroll_window_to_top()
  }, [])

  useEffect(() => {
    if (planned_bulk_download != null) {
        set_selected_download_format(planned_bulk_download)
    }

    set_planned_bulk_download(null)
  }, [planned_bulk_download])

  const selected_items_to_display = get_selected_items_for_display(selected_items, selected_charts_display_order_preference)

  const selected_items_ids = selected_items_to_display.map(item => item.spec_id)
  const no_items = selected_items_to_display.length === 0

  const group_props = {
    display_mode: display_mode || DISPLAY_ALL,
    zoomed_dataset_id,
    is_group_collapsed,
    toggle_group,
    show_all_datasets_refresh,
    enable_all_dataset_refresh,
    all_datasets_refresh_handler,
    show_selected_icon: false,
    spec_id_to_fetch_obj,
    ref_data,
    deref_data,
    internal_report_id,
    report_series_sort,
    minimal_selections,
    selections,
    data_creation_date,
    selected_items: selected_items_to_display,
    show_charts_in_thumbnails,
    selected_charts_display_order_preference,

    on_datasets_deselect,
    on_datasets_reshuffle,
    on_dataset_zoom,

    on_prev_item,
    on_next_item
  }

  function on_change_selected_charts_order_preference() {
    const updated_order = selected_charts_display_order_preference === SELECTED_ITEMS_SELECTION_ORDER ? SELECTED_ITEMS_CIPHER_ORDER : SELECTED_ITEMS_SELECTION_ORDER
    change_selected_charts_display_order_preference(updated_order)
  }

  function open_download_modal(selected_download_format) {
    const unfetched_datasets = selected_items_ids.filter(item_id => spec_id_to_fetch_obj[item_id] == null)

    if (unfetched_datasets.length > 0) {
      on_fetch_data_for_datasets(unfetched_datasets)
      return set_planned_bulk_download(selected_download_format)
    }

    return set_selected_download_format(selected_download_format)
  }

  return (
    <div className={className}>
      {no_items &&
        <div>
          <div>
            <div className='mb-3'>You have not selected any charts yet.</div>
            <div>Load a saved selection of charts:</div>
            <div className='d-flex ms-3 mt-2'>
              <UserChartSetsList
                user_chart_sets={user_chart_sets}
                on_select={on_select_user_chart_set}
              />
            </div>
          </div>
        </div>
      }

      {!no_items &&
        <DatasetGroup
          group={{...SELECTED_GROUP, children: selected_items_to_display}}
          {...group_props}
          is_bulk_download_enabled={is_bulk_download_enabled}

          on_export_selected_charts_as_xls={() => open_download_modal(EXCEL_FILE_EXT)}
          on_export_selected_charts_as_ppt={() => open_download_modal(POWERPOINT_FILE_EXT)}
          on_change_selected_charts_display_order_preference={on_change_selected_charts_order_preference}
          on_save_charts_as_set={() => set_is_save_as_chart_set(true)}
          on_load_chart_set={() => set_is_load_chart_set(true)}
          on_clear_selected_charts={on_clear_selected_datasets}
        />
      }

      {selected_download_format &&
        <DownloadAllChartsModal
          on_close={() => set_selected_download_format(null)}
          download_as={selected_download_format}
          report_title={report_title}
          data_creation_date={data_creation_date}
          selections={selections}
          items={selected_items_to_display}
          spec_id_to_fetch_obj={spec_id_to_fetch_obj}
          ref_data={ref_data}
          deref_data={deref_data}
          report_series_sort={report_series_sort}
        />
      }

      {is_save_as_chart_set &&
        <SaveSelectionModal
          user_chart_sets={user_chart_sets}
          on_confirm={on_save_user_chart_set}
          on_hide={() => set_is_save_as_chart_set(false)}
        />
      }

      {is_load_chart_set &&
        <LoadSelectionModal
          user_chart_sets={user_chart_sets}
          default_chart_selection={default_chart_selection}
          on_remove_user_chart_set={on_remove_user_chart_set}
          on_select_user_chart_set={(i) => on_select_user_chart_set(i, true)}
          on_change_default_chart_selection={change_default_chart_selection_handler}

          on_hide={() => set_is_load_chart_set(false)}
        />
      }

    </div>
  )
}

export default SelectedView