import React from 'react'
import { withRouter } from 'react-router-dom'

import { DashboardSmallTile } from './DashboardTile.js'
import { TileHelpLink } from './TileHelpLink.js'
import { get_new_report_help_url } from '../../utils/help_utils.js'
import { DashboardTileInner } from './DashboardTileInner.js'
import { DashboardTileDesc } from './DashboardTileDesc.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'
import { new_report_from_existing } from '../../utils/report_builder_utils.js'

const CustomBuilderTile = ({history, user_has_classifiers}) => {

  function on_click_from_custom_new_report_btn() {
    track_report_builder_event('action="select_builder" type="normal" context="dashboard"')
    new_report_from_existing(null, history)
  }

  return (
    <DashboardSmallTile title='Report builder' hint={<TileHelpLink help_url={get_new_report_help_url()} />} on_click={on_click_from_custom_new_report_btn}>
      <DashboardTileInner>
        <DashboardTileDesc>
          <span>
          {user_has_classifiers &&
            'Create a new classifier or clustered report of selected organisations, custom portfolios and/or patent upload'
          }
          {!user_has_classifiers &&
            'Create a new report of selected organisations, custom portfolios and/or patent upload'
          }
          </span>
          <span> with report builder</span>
        </DashboardTileDesc>
      </DashboardTileInner>
    </DashboardSmallTile>
  )
}

export default withRouter(CustomBuilderTile)