import React from 'react'

import { get_as_map } from '../utils/utils.js'

import {
  PRODUCT_ABB,
  PRODUCT_AMATDEMO,
  PRODUCT_AMAZON,
  PRODUCT_APPLE,
  PRODUCT_ARISTOCRAT,
  PRODUCT_ARM,
  PRODUCT_ASM,
  PRODUCT_AUTOMOTIVE,
  PRODUCT_BAE,
  PRODUCT_BAEAIR,
  PRODUCT_BAEAIRNEW,
  PRODUCT_BAEELECTRICAL,
  PRODUCT_BAELAND,
  PRODUCT_BAEMARITIME,
  PRODUCT_BAESUSTAINABILITY,
  PRODUCT_BANKOFAMERICA,
  PRODUCT_BAT,
  PRODUCT_BIOPLASTICS,
  PRODUCT_BIOTECH4,
  PRODUCT_BIOTRONIK,
  PRODUCT_BLOCK,
  PRODUCT_BOREALIS,
  PRODUCT_BOSTONSCIENTIFIC,
  PRODUCT_CAMBRIDGE,
  PRODUCT_CENTRICA,
  PRODUCT_CONTINENTAL,
  PRODUCT_CORBION,
  PRODUCT_CORNING,
  PRODUCT_CROWDSTRIKE,
  PRODUCT_CSDENTAL,
  PRODUCT_DAIMLER,
  PRODUCT_DROPBOX,
  PRODUCT_ELANCO,
  PRODUCT_ELECTROLUX,
  PRODUCT_FACEBOOK,
  PRODUCT_FERRARI,
  PRODUCT_FMCG,
  PRODUCT_FINANCIALSERVICES,
  PRODUCT_FINTECH,
  PRODUCT_FIRMENICH,
  PRODUCT_GEA,
  PRODUCT_GM,
  PRODUCT_GOOGLE,
  PRODUCT_GPA,
  PRODUCT_HALLIBURTON,
  PRODUCT_IA,
  PRODUCT_INFINEON,
  PRODUCT_INSTAGRAM,
  PRODUCT_INTEL,
  PRODUCT_INTERDIGITAL,
  PRODUCT_JTI,
  PRODUCT_KYNDRYL,
  PRODUCT_MERCEDESBENZ2013,
  PRODUCT_MESSENGER,
  PRODUCT_METAREALITYLABS,
  PRODUCT_MINTEL,
  PRODUCT_MINTEL_DEMO,
  PRODUCT_MOTIONAL,
  PRODUCT_ND,
  PRODUCT_NISSAN,
  PRODUCT_NOKIA,
  PRODUCT_NVIDIA,
  PRODUCT_NVMDEMO,
  PRODUCT_OIN,
  PRODUCT_REDHAT,
  PRODUCT_REDHAT2020,
  PRODUCT_RENISHAW,
  PRODUCT_RICHARDSONOLIVER,
  PRODUCT_ROLLSROYCE,
  PRODUCT_RPX,
  PRODUCT_SAAB,
  PRODUCT_SABIC,
  PRODUCT_SANOFI,
  PRODUCT_SAP,
  PRODUCT_SCHOTT,
  PRODUCT_SEAGATE,
  PRODUCT_SERVICENOW,
  PRODUCT_SKOLTECH,
  PRODUCT_SUSTAINABILITY,
  PRODUCT_SWIFT,
  PRODUCT_SYNOPSYS,
  PRODUCT_TATELYLE,
  PRODUCT_TCH,
  PRODUCT_TECHINSIGHTS,
  PRODUCT_TECHINSIGHTS_DEMO,
  PRODUCT_THERMOFISHER,
  PRODUCT_TOPSOE,
  PRODUCT_TTI,
  PRODUCT_TTIDEMO,
  PRODUCT_TUSIMPLE,
  PRODUCT_UNILEVER,
  PRODUCT_UTC,
  PRODUCT_VMWARE,
  PRODUCT_WHATSAPP,
  PRODUCT_WIGGIN,
  PRODUCT_WORKDAY,
  PRODUCT_XPERI,
  PRODUCT_LYFT,
} from '../constants/cipher_product_ids.js'


/**
 * This list provides names and ordering for product taxonomies.
 *
 * Optional attribute 'bespoke_name' can include JSX content for displaying complex names or non-standard names (see Classifiers.js)
 */
export const TAXONOMY_PRODUCTS = [
  { id: PRODUCT_ABB,                  },
  { id: PRODUCT_AMATDEMO,             },
  { id: PRODUCT_AMAZON,               },
  { id: PRODUCT_APPLE,                },
  { id: PRODUCT_ARISTOCRAT,           },
  { id: PRODUCT_ARM,                  },
  { id: PRODUCT_ASM,                  },
  { id: PRODUCT_AUTOMOTIVE,           },
  // BAE products should not be re-ordered from the following
  // (electrical, land, maritime, air new, air, classifiers)
  { id: PRODUCT_BAEELECTRICAL,        bespoke_name: 'BAE | Electrical Systems'},
  { id: PRODUCT_BAELAND,              bespoke_name: 'BAE | Land'              },
  { id: PRODUCT_BAEMARITIME,          bespoke_name: 'BAE | Maritime'          },
  { id: PRODUCT_BAESUSTAINABILITY,    bespoke_name: 'BAE | Sustainability'    },
  { id: PRODUCT_BAEAIRNEW,            bespoke_name: 'BAE | Air New'           },
  { id: PRODUCT_BAEAIR,               bespoke_name: 'BAE | AIR'               },
  { id: PRODUCT_BAE },
  // end BAE products
  { id: PRODUCT_BANKOFAMERICA,        },
  { id: PRODUCT_BAT,                  },
  { id: PRODUCT_BIOPLASTICS,          bespoke_name: 'Bioplastics taxonomy'},
  { id: PRODUCT_BIOTECH4,             },
  { id: PRODUCT_BIOTRONIK,            },
  { id: PRODUCT_BLOCK,                bespoke_name: 'Block taxonomy'},
  { id: PRODUCT_BOREALIS,             },
  { id: PRODUCT_BOSTONSCIENTIFIC,     },
  { id: PRODUCT_CAMBRIDGE,            bespoke_name: 'Cambridge University taxonomy'},
  { id: PRODUCT_CENTRICA,             },
  { id: PRODUCT_CONTINENTAL,          },
  { id: PRODUCT_CORBION,              },
  { id: PRODUCT_CORNING,              },
  { id: PRODUCT_CROWDSTRIKE           },
  { id: PRODUCT_CSDENTAL,             bespoke_name: 'CS Dental'},
  { id: PRODUCT_DROPBOX,              },
  { id: PRODUCT_ELANCO,               },
  { id: PRODUCT_ELECTROLUX,           },
  { id: PRODUCT_FERRARI,              },
  { id: PRODUCT_FINANCIALSERVICES,    },
  { id: PRODUCT_FINTECH,              },
  { id: PRODUCT_FIRMENICH,            },
  { id: PRODUCT_FMCG,                 bespoke_name: 'FMCG classifiers'},
  { id: PRODUCT_GEA,                  },
  { id: PRODUCT_GM,                   },
  { id: PRODUCT_GOOGLE,               },
  { id: PRODUCT_GPA,                  },
  { id: PRODUCT_HALLIBURTON,          },
  { id: PRODUCT_IA,                   },
  { id: PRODUCT_INFINEON,             },
  { id: PRODUCT_INSTAGRAM,            },
  { id: PRODUCT_INTEL,                },
  { id: PRODUCT_INTERDIGITAL,         },
  { id: PRODUCT_JTI,                  },
  { id: PRODUCT_KYNDRYL,              },
  { id: PRODUCT_LYFT,                 },
  { id: PRODUCT_DAIMLER,              },
  { id: PRODUCT_MERCEDESBENZ2013,     },
  { id: PRODUCT_METAREALITYLABS,      bespoke_name: 'Meta Reality Labs Taxonomy'},
  { id: PRODUCT_FACEBOOK,             },
  { id: PRODUCT_MESSENGER,            bespoke_name: 'Messenger taxonomy'},
  { id: PRODUCT_MINTEL,               },
  { id: PRODUCT_MINTEL_DEMO,          },
  { id: PRODUCT_MOTIONAL,             },
  { id: PRODUCT_ND,                   bespoke_name: (<span><sup>n</sup>/<sub>d</sub> classifiers</span>) },
  { id: PRODUCT_NISSAN,               },
  { id: PRODUCT_NOKIA,                },
  { id: PRODUCT_NVIDIA,               },
  { id: PRODUCT_NVMDEMO,              bespoke_name: 'Demo taxonomy'},
  { id: PRODUCT_OIN,                  },
  { id: PRODUCT_REDHAT,               },
  { id: PRODUCT_REDHAT2020,           },
  { id: PRODUCT_RENISHAW,             },
  { id: PRODUCT_RICHARDSONOLIVER,     },
  { id: PRODUCT_ROLLSROYCE,           },
  { id: PRODUCT_RPX,                  },
  { id: PRODUCT_SAAB,                 },
  { id: PRODUCT_SABIC,                },
  { id: PRODUCT_SANOFI,               },
  { id: PRODUCT_SAP,                  },
  { id: PRODUCT_SCHOTT,               },
  { id: PRODUCT_SEAGATE,              },
  { id: PRODUCT_SERVICENOW,           },
  { id: PRODUCT_SKOLTECH,             },
  { id: PRODUCT_SUSTAINABILITY,       },
  { id: PRODUCT_SWIFT,                },
  { id: PRODUCT_SYNOPSYS,             },
  { id: PRODUCT_TATELYLE,             },
  { id: PRODUCT_TCH,                  },
  { id: PRODUCT_TECHINSIGHTS,         },
  { id: PRODUCT_TECHINSIGHTS_DEMO,    },
  { id: PRODUCT_THERMOFISHER,         },
  { id: PRODUCT_TOPSOE,               },
  { id: PRODUCT_TTI,                  bespoke_name: 'TTI taxonomy'},
  { id: PRODUCT_TTIDEMO,              },
  { id: PRODUCT_TUSIMPLE,             bespoke_name: 'TuSimple taxonomy'},
  { id: PRODUCT_UNILEVER,             },
  { id: PRODUCT_UTC,                  },
  { id: PRODUCT_VMWARE,               },
  { id: PRODUCT_WHATSAPP,             },
  { id: PRODUCT_WIGGIN,               },
  { id: PRODUCT_WORKDAY,              },
  { id: PRODUCT_XPERI,                },
]

export const ID_TO_TAXONOMY_PRODUCT = get_as_map(TAXONOMY_PRODUCTS, 'id')