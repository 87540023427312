import React from 'react'

import { Label } from './FamilyDetailsLabel.js'
import { parse_territories } from '../../utils/family_view_utils.js'

export const FamilyTerritories = ({family}) => {
  const { pendingTerritories, grantedTerritories, expiredTerritories, firstFilingCountry } = family

  const pending_parsed = parse_territories(pendingTerritories)
  const granted_parsed = parse_territories(grantedTerritories)
  const expired_parsed = parse_territories(expiredTerritories)

  const has_pending_territories = pending_parsed != null
  const has_granted_territories = granted_parsed != null
  const has_expired_territories = expired_parsed != null

  return (
    <div>
      <div className={has_pending_territories ? '' : 'd-lg-flex'}>
        <Label inline={!has_pending_territories} disabled={!has_pending_territories}>Pending territories</Label>
        <div>{pending_parsed || 'None'}</div>
      </div>
      <div className={has_granted_territories ? '' : 'd-lg-flex'}>
        <Label inline={!has_granted_territories} disabled={!has_granted_territories}>Granted territories</Label>
        <div>{granted_parsed || 'None'}</div>
      </div>
      <div className={has_expired_territories ? '' : 'd-lg-flex'}>
        <Label inline={!has_expired_territories} disabled={!has_expired_territories}>Expired territories</Label>
        <div>{expired_parsed || 'None'}</div>
      </div>
      {firstFilingCountry && (['DD', 'SU', 'YU'].indexOf(firstFilingCountry) === -1) &&
        <>
          <Label>First filing country</Label>
          <div>{firstFilingCountry}</div>
        </>
      }
    </div>
  )
}