import _ from 'underscore'
import {
  DECK_TOP_OWNERS_IN_LANDSCAPE_ID,
  DECK_ACTIVE_FAMILIES_BY_PORTFOLIO_ID,
  DECK_PATFAMS_WITH_GRANTS_BY_REGION_TARGET_ORG_ID,
  DECK_PATFAMS_WITH_GRANTS_BY_REGION_SELECTED_ORGS_ID,
  DECK_ACTIVE_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
  DECK_ACTIVE_FAMILIES_BY_TECH_BY_STATUS_ID,
  DECK_AVERAGE_COST_BY_PORTFOLIO_ID,
  DECK_AVG_PRIORITY_YEAR_BY_ORG_ID,
  DECK_CAGR_FAMILY_FILINGS_BY_PORTFOLIO_ID,
  DECK_CAGR_FAMILY_FILINGS_BY_TECH_ID,
  DECK_FAMILIES_BY_PORTFOLIO_BY_PUBLICATION_YEAR_ID,
  DECK_FAMILIES_BY_TECH_BY_PUBLICATION_YEAR_ID,
  DECK_FAMILY_FILINGS_BY_LANDSCAPE_PORTFOLIO_WITHIN_TIMEFRAME_ID,
  DECK_FAMILY_FILINGS_BY_LIST_PORTFOLIO_WITHIN_TIMEFRAME_ID,
  DECK_PATFAMS_WITH_GRANTS_BY_REGION_ALL_TECHS_ID,
  DECK_PATFAMS_WITH_GRANTS_BY_REGION_SELECTED_TECHS_ID,
  DECK_PENDING_FAMILIES_PERCENT_BY_PORTFOLIO_ID,
  DECK_PVIX_MEAN_BY_PORTFOLIO_ID,
  ID_TO_DECK_SPEC,
  DECK_PVIX_DISTRIBUTION_LIST_ORGS_ID,
  DECK_PVIX_DISTRIBUTION_TARGET_ORG_ID,
  DECK_COST_DISTRIBUTION_LIST_ORGS_ID,
  DECK_COST_DISTRIBUTION_TARGET_ORG_ID,
  DECK_PRIO_YEAR_DISTRIBUTION_TARGET_ORG_ID,
  DECK_PRIO_YEAR_DISTRIBUTION_LIST_ORGS_ID
} from '../../../model/deck_specs.js'
import { BAR_STACK_ID, COLUMN_STACK_ID, EXTENDED_TABLE_ID, LINE_ID } from '../../../model/view_ids.js'
import { STATUS_PENDING_ID } from '../../../model/statuses.js'
import {
  DECK_BACKGROUND_ORG_COLUMN_COLOR,
  DECK_DEFAULT_COLUMN_COLOR,
  DECK_LIGHTER_DEFAULT_COLUMN_COLOR,
  DECK_MISC_SPOTLIGHT_COLOR,
  DECK_SPOTLIGHTED_ORG_COLUMN_COLOR,
  DECK_TARGET_ORG_COLUMN_COLOUR,
  PS_RED
} from '../../../constants/colours.js'
import { get_as_map, is_array_non_empty_non_null, pluralise_text, sum } from '../../../utils/utils.js'
import { lighten_color_by_percentage } from '../../../utils/color_utils.js'
import {
  get_dominant_colour_from_spotlighted_techs,
  get_target_org_display_name,
} from '../../../utils/report_deck_utils.js'
import {
  DeckBarsPosNegSpotlightIcon,
  DeckBarsSpotlightIcon,
  DeckColsPosNegSpotlightIcon, DeckColumnsBoldIcon,
  DeckColumnsLightIcon,
  DeckColumnsStackIcon, DeckLandscapeBarsIcon,
  DeckLineIcon
} from '../../widgets/IconSet.js'
import { is_company_list_target_list } from '../../../utils/deck_spec_utils.js'

export const TECH_SECTION_ID = 'techs'
export const BENCHMARKING_SECTION_ID = 'benchmarking'

export const TECH_SCREENING_SECTION_MODEL = {id: TECH_SECTION_ID, title: 'Tech screening', short_title: 'Tech', pages: []}
export const BENCHMARKING_SECTION_MODEL = {id: BENCHMARKING_SECTION_ID, title: 'Benchmarking', short_title: 'Org', pages: []}

const SECTIONS = [TECH_SCREENING_SECTION_MODEL, BENCHMARKING_SECTION_MODEL]
export const SECTION_BY_ID = get_as_map(SECTIONS, 'id')

export const SIZE_PAGE_ID = 'size'
export const SIZE_LANDSCAPE_PAGE_ID = 'size_landscape'
export const ACTIVITY_PAGE_ID = 'activity'
export const GEOGRAPHY_PAGE_ID = 'geography'
export const QUALITY_COST_PAGE_ID = 'quality_cost'
export const PIPELINE_AGE_PAGE_ID = 'pipeline_age'

export const PVIX_DISTRIBUTION_PAGE_ID = 'pvix_dist'
export const COST_DISTRIBUTION_PAGE_ID = 'cost_dist'
export const PRIO_YEAR_DISTRIBUTION_PAGE_ID = 'date_dist'

export const PORTFOLIO_SIZE_TITLE = 'Portfolio size'
export const PORTFOLIO_SIZE_SHORT_TITLE = 'Size'
export const LANDSCAPE = 'Landscape'
export const ACTIVITY_TITLE = 'Activity'
export const GEOGRAPHY_TITLE = 'Geography'
export const QUALITY_TITLE = 'Quality'
export const COST_TITLE = 'Cost'
export const PIPELINE_TITLE = 'Pipeline'
export const AGE_TITLE = 'Age'

function get_closest_matching_portfolio(name_to_match, portfolios) {
  // prefer exact matches as sometimes we will have both 'Hitachi' and 'Hitachi Astemo Corp', for eg
  const exact_match = _.find(portfolios, portfolio => portfolio.name === name_to_match)
  return exact_match || _.find(portfolios, portfolio => portfolio.name.startsWith(name_to_match))
}

function get_custom_series_labels_for_landscape({plotly_data, ref_data, selections})  {
  const {all_portfolios=[], portfolio_to_company_list={}} = ref_data || {}
  const {selected_org_lists=[]} = selections || {}

  const {y=[]} = (plotly_data || [])[0] || {}

  const annotations = y.map((y_item, i) => {
    const portfolio = get_closest_matching_portfolio(y_item, all_portfolios)
    const {id, name} = portfolio || {}
    const org_list = portfolio_to_company_list[id]
    const {id: list_id} = org_list || {}

    if (!org_list || (selected_org_lists.indexOf(list_id) === -1)) {
      return null
    }

    return {
      y: y_item,
      x: sum(plotly_data.map(item => {
        const {x} = item
        return x[i]
      })),
      text: name,
      xanchor: 'left',
      yanchor: 'center',
      showarrow: false,
      font: {
        size: 10
      }
  }})

  return annotations.filter(x => x != null)
}

function get_spotlighted_technologies_description({spec, spotlighted_tech_areas, tech_areas}) {
  const spotlighted_techs = (tech_areas || []).filter(tech => (spotlighted_tech_areas || []).indexOf(tech.id) !== -1)
  if (!is_array_non_empty_non_null(spotlighted_techs) && !spec.spotlighted_tech_areas_only) {
    return 'All technologies'
  } else if (spotlighted_techs.length === 1) {
    return spotlighted_techs[0].name
  }
  return 'All highlighted technologies'
}

function get_values_total({chart_click, data}) {
  const {data: chart_data = []} = data || {}
  const {item} = chart_click || {}
  const {items=[]} = item || {}

  const clicked_portfolio_id = (items[0] || {}).id

  const portfolios = chart_data[0] || []
  const values = chart_data[2] || []

  const clicked_portfolio_values = []

  for (let i = 0; i < portfolios.length; i++) {
    if (portfolios[i] === clicked_portfolio_id) {
      clicked_portfolio_values.push(values[i] || 0)
    }
  }

  return clicked_portfolio_values.length > 0 ? sum(clicked_portfolio_values) : 0
}

export const DECK_ACTIVE_FAMILIES_BY_TECH_BY_STATUS_CHART = {
  header: PORTFOLIO_SIZE_TITLE,
  get_title: ({granted_only}) => {return `${(granted_only === true) ? 'Granted' : 'Active'} Families by Technology`},
  get_description: ({granted_only}) => (granted_only ? 'Currently granted patent families in each technology area.' : 'Currently active patent families (granted and pending) in each technology area across the landscape.'),
  spec_id: DECK_ACTIVE_FAMILIES_BY_TECH_BY_STATUS_ID,
  view_id: COLUMN_STACK_ID,
  view_icon: DeckColumnsStackIcon,
  get_series_colour: ({x_item: tech_area, y_item: status, selections}) => {
    const {spotlighted_tech_areas=[]} = selections
    const {id: tech_area_id, color: tech_area_colour} = tech_area || {}
    const {id: status_id} = status
    if (spotlighted_tech_areas.indexOf(tech_area_id) > -1) {
      return (status_id === STATUS_PENDING_ID) ? lighten_color_by_percentage(tech_area_colour, 60) : tech_area_colour
    }
    return status.color
  },
  get_series_clickthrough_value: get_values_total,
  is_family_count: true
}

export const DECK_TOP_OWNERS_IN_LANDSCAPE_CHART = {
  header: PORTFOLIO_SIZE_TITLE,
  get_title: ({granted_only, spotlighted_tech_areas, tech_areas}) => {
    const spec = ID_TO_DECK_SPEC[DECK_TOP_OWNERS_IN_LANDSCAPE_ID]
    const technologies = get_spotlighted_technologies_description({spec, spotlighted_tech_areas, tech_areas})
    return `Top ${(granted_only === true) ? 'Granted' : 'Active'} Patent Family Owners: ${technologies}`
  },
  get_accent_colour: get_dominant_colour_from_spotlighted_techs,
  get_description: ({granted_only, spotlighted_tech_areas}) => {
    const spotlighted_techs_count = spotlighted_tech_areas ? spotlighted_tech_areas.length : 0
    return `Table showing the top 20 owners of ${granted_only ? 'granted' : 'active'} families in the landscape across ${is_array_non_empty_non_null(spotlighted_tech_areas) ? `the highlighted technology ${pluralise_text(spotlighted_techs_count, 'area')}` : 'all technology areas in the report'}.`
  },
  spec_id: DECK_TOP_OWNERS_IN_LANDSCAPE_ID,
  view_id: EXTENDED_TABLE_ID,
  is_family_count: true
}

export const DECK_FAMILIES_BY_TECH_BY_PUBLICATION_YEAR_CHART ={
  header: ACTIVITY_TITLE,
  get_title: ({granted_only}) => {return `Patenting Activity by Publications: Families ${(granted_only === true) ? 'granted' : 'published'} by year`},
  get_description: ({granted_only}) => `Number of patent families ${granted_only ? 'granted' : 'published'} each year by ${granted_only ? 'grant' : 'publication'} year and technology area.`,
  spec_id: DECK_FAMILIES_BY_TECH_BY_PUBLICATION_YEAR_ID,
  view_id: LINE_ID,
  view_icon: DeckLineIcon,
  update_key_dims: ({key_dims, selections}) => {
    const {spotlighted_tech_areas=[]} = selections
    const [years, techs] = key_dims

    const updated_techs = techs.map(item => {
      const {id: tech_area_id, color: tech_area_colour} = item
      return {...item, color: (spotlighted_tech_areas.indexOf(tech_area_id) > -1) ? tech_area_colour : DECK_LIGHTER_DEFAULT_COLUMN_COLOR}
    })
    return [years, updated_techs]
  },

  is_family_count: true
}

export const DECK_CAGR_FAMILY_FILINGS_BY_TECH_CHART = {
  header: ACTIVITY_TITLE,
  get_title: ({ granted_only, timerange }) => {return `Patenting Activity CAGR${timerange ? ` ${timerange[0]} - ${timerange[1]-1}` : ''}: ${(granted_only === true) ? 'Granted' : 'Published'} patent families`},
  get_description: ({granted_only}) => `Compound Annual Growth Rate (CAGR) in number of ${granted_only ? 'grants' : 'publications'} in each technology area over the specified time period. For accuracy this excludes technologies with fewer than 10  ${granted_only ? 'grants' : 'publications'} at either the start or end of the time covered.`,
  spec_id: DECK_CAGR_FAMILY_FILINGS_BY_TECH_ID,
  view_id: COLUMN_STACK_ID,
  view_icon: DeckColsPosNegSpotlightIcon,
  update_key_dims: ({key_dims, selections}) => {
    const {spotlighted_tech_areas=[]} = selections
    const [techs, years] = key_dims

    const updated_techs = techs.map(item => {
      const {id: tech_area_id, color: tech_area_colour} = item
      return {...item, color: (spotlighted_tech_areas.indexOf(tech_area_id) > -1) ? tech_area_colour : DECK_DEFAULT_COLUMN_COLOR}
    })
    return [updated_techs, years]
  },
}

export const DECK_PATFAMS_WITH_GRANTS_BY_REGION_ALL_TECHS_CHART = {
  header: GEOGRAPHY_TITLE,
  title: 'Families with Grants in each Region: All technologies',
  description: 'Number of patent families with at least one current grant in each respective region across all technology areas in the report. ' +
    'The percentage shown is the percentage of all families in the report that have received at least one grant in the respective region.',
  spec_id: DECK_PATFAMS_WITH_GRANTS_BY_REGION_ALL_TECHS_ID,
  view_id: COLUMN_STACK_ID,
  view_icon: DeckColumnsLightIcon,
  is_family_count: true,
}

export const DECK_PATFAMS_WITH_GRANTS_BY_REGION_SELECTED_TECHS_CHART = {
  header: GEOGRAPHY_TITLE,
  get_title: ({spotlighted_tech_areas, tech_areas}) => {
    const spec = ID_TO_DECK_SPEC[DECK_PATFAMS_WITH_GRANTS_BY_REGION_SELECTED_TECHS_ID]
    const technologies = get_spotlighted_technologies_description({spec, spotlighted_tech_areas, tech_areas})
    return `Families with Grants in each Region: ${technologies}`
  },
  get_description: ({spotlighted_tech_areas}) => {
    const spotlighted_techs_count = spotlighted_tech_areas ? spotlighted_tech_areas.length : 0
    return `Number of patent families with at least one current grant in each respective region in the highlighted technology ${pluralise_text(spotlighted_techs_count, 'area')}. ` +
    `The percentage shown is the percentage of all families in ${pluralise_text(spotlighted_techs_count, 'that technology area', 'those technology areas')} that have received at least one grant in the respective region.`
  },
  get_accent_colour: get_dominant_colour_from_spotlighted_techs,
  update_key_dims: ({key_dims, selections}) => {
    const {spotlighted_tech_areas=[]} = selections
    const [regions] = key_dims
    const bar_color = spotlighted_tech_areas ? get_dominant_colour_from_spotlighted_techs({spotlighted_tech_areas}) : DECK_MISC_SPOTLIGHT_COLOR
    const updated_regions = regions.map(region => ({...region, color: bar_color}))
    return [updated_regions]
  },
  spec_id: DECK_PATFAMS_WITH_GRANTS_BY_REGION_SELECTED_TECHS_ID,
  view_id: COLUMN_STACK_ID,
  view_icon: DeckColumnsBoldIcon,
  is_family_count: true,
  no_data_message: 'To show data in this chart, one or more technology areas should be highlighted. Highlighting controls can be found on hover in the \'Technology areas\' filter.'
}

export const DECK_ACTIVE_FAMILIES_BY_TECH_BY_PORTFOLIO_CHART = {
  header: `${PORTFOLIO_SIZE_TITLE} (${LANDSCAPE})`,
  get_title: ({granted_only}) => {return `Top 50: ${(granted_only === true) ? 'Granted' : 'Active'} families`},
  get_description: ({granted_only}) => `Currently ${granted_only ? 'granted' : 'active (granted or pending) '} patent families by organisation and technology.`,
  spec_id: DECK_ACTIVE_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
  view_id: BAR_STACK_ID,
  view_icon: DeckLandscapeBarsIcon,
  rollup_thresholds: [50, ],
  no_rollups: true,
  get_series_colour: ({x_item: portfolio, y_item: tech, ref_data, selections={}}) => {
    const {id: portfolio_id} = portfolio || {}
    const {portfolio_to_company_list} = ref_data || {}
    const {spotlighted_orgs, selected_org_lists} = selections
    const company_list = portfolio_to_company_list[portfolio_id]
    const { id: company_list_id } = company_list || {}
    const is_in_list = company_list && ((selected_org_lists || []).indexOf(company_list_id) > -1)
    const is_spotlighted = (spotlighted_orgs || []).indexOf(portfolio_id) > -1
    const is_target_org = is_in_list && is_company_list_target_list(company_list)
    return is_in_list ? (is_target_org || is_spotlighted ? tech.color :  lighten_color_by_percentage(tech.color, 70)) : DECK_BACKGROUND_ORG_COLUMN_COLOR
  },
  get_series_clickthrough_value: get_values_total,
  is_family_count: true,
  show_custom_series_labels: true,
  get_custom_series_labels: get_custom_series_labels_for_landscape,
}

export const DECK_FAMILY_FILINGS_BY_LIST_PORTFOLIO_WITHIN_TIMEFRAME_CHART = {
  header: PORTFOLIO_SIZE_TITLE,
  get_title: ({granted_only, timerange}) => `Families ${granted_only ? 'granted' : 'published'} ${timerange ? `${timerange[0]} - ${timerange[1] - 1}` : 'in recent years'} (company lists)`,
  get_description: ({granted_only}) => `Families ${granted_only ? 'granted' : 'published'} by year in the selected time period, by organisation.`,
  spec_id: DECK_FAMILY_FILINGS_BY_LIST_PORTFOLIO_WITHIN_TIMEFRAME_ID,
  view_id: BAR_STACK_ID,
  view_icon: DeckBarsSpotlightIcon,
  no_rollups: true,
  is_family_count: true
}

export const DECK_FAMILY_FILINGS_BY_LANDSCAPE_PORTFOLIO_WITHIN_TIMEFRAME_CHART = {
  header: `${PORTFOLIO_SIZE_TITLE} (${LANDSCAPE})`,
  get_title: ({granted_only, timerange}) => `Top Innovators: Families ${granted_only ? 'granted' : 'published'}${timerange ? ` ${timerange[0]} - ${timerange[1] - 1}` : ''}`,
  get_description: ({granted_only}) => `Families ${granted_only ? 'granted' : 'published'} by year, by organisation across all technology areas in the report.`,
  spec_id: DECK_FAMILY_FILINGS_BY_LANDSCAPE_PORTFOLIO_WITHIN_TIMEFRAME_ID,
  view_id: BAR_STACK_ID,
  view_icon: DeckLandscapeBarsIcon,
  rollup_thresholds: [50, ],
  no_rollups: true,
  is_family_count: true,
  update_key_dims: ({key_dims, ref_data, selections}) => {
    const [portfolios] = key_dims || []
    const {portfolio_to_company_list} = ref_data || {}
    const {spotlighted_orgs, selected_org_lists} = selections
    const updated_portfolios = portfolios.map(item => {
      const {id: portfolio_id} = item
      const list = (portfolio_to_company_list || {})[portfolio_id]
      const {id: company_list_id} = list || {}
      const is_in_list = list && ((selected_org_lists || []).indexOf(company_list_id) > -1)
      const is_spotlighted = (spotlighted_orgs || []).indexOf(portfolio_id) > -1
      const is_target_org = is_in_list && is_company_list_target_list(list)

      const color = !is_in_list ? DECK_BACKGROUND_ORG_COLUMN_COLOR :
        (is_target_org ? PS_RED :
            (is_spotlighted ? DECK_SPOTLIGHTED_ORG_COLUMN_COLOR : DECK_DEFAULT_COLUMN_COLOR)
        )

      return {...item, color}
    })
    return [updated_portfolios]
  },
  show_custom_series_labels: true,
  get_custom_series_labels: get_custom_series_labels_for_landscape
}

export const DECK_ACTIVE_FAMILIES_BY_PORTFOLIO_CHART = {
  header: PORTFOLIO_SIZE_TITLE,
  get_title: ({granted_only}) => {return `All ${(granted_only === true) ? 'granted' : 'active'} families (company lists)`},
  get_description: ({granted_only}) => `Currently ${granted_only ? 'granted' : 'active (granted or pending)'} patent families by organisation.`,
  spec_id: DECK_ACTIVE_FAMILIES_BY_PORTFOLIO_ID,
  view_id: BAR_STACK_ID,
  view_icon: DeckBarsSpotlightIcon,
  is_family_count: true
}

export const DECK_CAGR_FAMILY_FILINGS_BY_PORTFOLIO_CHART = {
  header: ACTIVITY_TITLE,
  get_title: ({granted_only, timerange}) => {return `Patenting Activity CAGR${timerange ? ` ${timerange[0]} - ${timerange[1] - 1}` : ''} (company lists): ${(granted_only === true) ? 'Granted' : 'Published'} patent families`},
  get_description: ({granted_only}) => `Compound Annual Growth Rate (CAGR) in number of ${granted_only ? 'grants' : 'publications'} from each organisation over the specified time period. For accuracy this excludes organisations with fewer than 10  ${granted_only ? 'grants' : 'publications'} at either the start or end of the time covered.`,
  spec_id: DECK_CAGR_FAMILY_FILINGS_BY_PORTFOLIO_ID,
  view_id: BAR_STACK_ID,
  view_icon: DeckBarsPosNegSpotlightIcon,
}

export const DECK_FAMILIES_BY_PORTFOLIO_BY_PUBLICATION_YEAR_CHART = {
  header: ACTIVITY_TITLE,
  get_title: ({granted_only}) => {return `Patenting Activity by Publications (company lists): Families ${(granted_only === true) ? 'granted' : 'published'} by year`},
  get_description: ({granted_only}) => `Number of patent families ${granted_only ? 'granted' : 'published'} each year by ${granted_only ? 'grant' : 'publication'} year and organisation.`,
  spec_id: DECK_FAMILIES_BY_PORTFOLIO_BY_PUBLICATION_YEAR_ID,
  view_id: LINE_ID,
  update_key_dims: ({key_dims}) => {
    const [years, portfolios] = key_dims
    const updated_portfolios = portfolios.map(item => item.color === DECK_DEFAULT_COLUMN_COLOR ? {...item, color: DECK_LIGHTER_DEFAULT_COLUMN_COLOR} : item)
    return [years, updated_portfolios]
  },
  view_icon: DeckLineIcon,
  is_family_count: true
}

export const DECK_PATFAMS_WITH_GRANTS_BY_REGION_TARGET_ORG_CHART = {
  header: GEOGRAPHY_TITLE,
  get_title: ({org_lists}) => {
    const my_org_name = get_target_org_display_name(org_lists)
    return `Families with Grants in each Region: ${my_org_name}`
  },
  description: 'Number of patent families owned by my organisation with at least one current grant in the respective region.',
  spec_id: DECK_PATFAMS_WITH_GRANTS_BY_REGION_TARGET_ORG_ID,
  view_id: COLUMN_STACK_ID,
  view_icon: DeckColumnsLightIcon,
  is_family_count: true
}

export const DECK_PATFAMS_WITH_GRANTS_BY_REGION_SELECTED_ORGS_CHART = {
  header: GEOGRAPHY_TITLE,
  title: 'Families with Grants in each Region (company lists)',
  description: 'Number of patent families owned by selected list organisations with at least one current grant in the respective region.',
  spec_id: DECK_PATFAMS_WITH_GRANTS_BY_REGION_SELECTED_ORGS_ID,
  view_id: COLUMN_STACK_ID,
  view_icon: DeckColumnsBoldIcon,
  is_family_count: true
}

export const DECK_PVIX_MEAN_BY_PORTFOLIO_CHART = {
  header: QUALITY_TITLE,
  get_title: ({granted_only}) => {return `Mean PVIX ${(granted_only === true) ? 'Granted' : 'Active'} Families (company lists)`},
  description: 'This chart looks at average PVIX in order to enable a direct comparison of portfolio quality between organisations. ' +
    'PVIX score is calculated on a per-family basis, and is derived from a Market and Reputation score. ' +
    'The Market score takes into account national GDP figures for each territory in which that family has one or more currently-active issued patents. ' +
    'The Reputation score takes into account the number of forward citations that the family has received.',
  more_info_url: 'https://support.cipher.ai/en/articles/4351395-pvix-scores-report#methodology',
  spec_id: DECK_PVIX_MEAN_BY_PORTFOLIO_ID,
  view_id: BAR_STACK_ID,
  view_icon: DeckBarsSpotlightIcon,
  clickthrough_detail_page: PVIX_DISTRIBUTION_PAGE_ID
}

export const DECK_PVIX_DISTRIBUTION_BY_PORTFOLIO_TARGET_ORG_CHART = {
  header: QUALITY_TITLE,
  get_title: ({org_lists}) => {
    const my_org_name = get_target_org_display_name(org_lists)
    return `PVIX distribution: ${my_org_name}`
  },
  update_key_dims: ({key_dims}) => {
    const [counts] = key_dims
    const updated_counts = counts.map(item => ({...item, color: DECK_TARGET_ORG_COLUMN_COLOUR}))
    return [updated_counts]
  },
  description: 'Distribution of PVIX score, showing the number of patent families in each score category in the selected technologies for this organisation.',
  more_info_url:  'https://support.cipher.ai/en/articles/4351395-pvix-scores-report#methodology',
  spec_id: DECK_PVIX_DISTRIBUTION_TARGET_ORG_ID,
  view_id: COLUMN_STACK_ID,
  is_family_count: true
}

export const DECK_PVIX_DISTRIBUTION_BY_PORTFOLIO_SELECTED_ORGS_CHART = {
  header: QUALITY_TITLE,
  get_title: ({clickthrough_item}) => {
    if (!clickthrough_item || clickthrough_item.is_target_org) {
      return 'PVIX distribution: Company list orgs'
    }
    const {items} = (clickthrough_item)
    return `PVIX distribution: ${items.map(item => item.name).join(', ')}`
  },
  update_key_dims: ({key_dims, clickthrough_item, selections}) => {
    const [counts] = key_dims
    if (!clickthrough_item) {
      return [counts.map(item => ({...item, color: DECK_DEFAULT_COLUMN_COLOR}))]
    }
    const ids = (clickthrough_item.items || []).map(item => item.id)
    const color = (is_array_non_empty_non_null(ids) && _.all(ids, id => _.contains(selections.spotlighted_orgs, id))) ? DECK_SPOTLIGHTED_ORG_COLUMN_COLOR : DECK_DEFAULT_COLUMN_COLOR
    const updated_counts = counts.map(item => ({...item, color}))
    return [updated_counts]
  },
  get_description: ({clickthrough_item}) => {
    if (clickthrough_item && !clickthrough_item.is_target_org) {
      return 'Distribution of PVIX score, showing the number of patent families in each score category in the selected technologies for this organisation.'
    }
    return 'Distribution of PVIX score, showing the number of patent families in each score category in the selected technologies for all list organisations in the report.'
  },
  more_info_url:  'https://support.cipher.ai/en/articles/4351395-pvix-scores-report#methodology',
  spec_id: DECK_PVIX_DISTRIBUTION_LIST_ORGS_ID,
  view_id: COLUMN_STACK_ID,
  is_family_count: true
}

export const DECK_COST_DISTRIBUTION_BY_PORTFOLIO_TARGET_ORG_CHART = {
  header: COST_TITLE,
  get_title: ({org_lists}) => {
    const my_org_name = get_target_org_display_name(org_lists)
    return `Cost distribution: ${my_org_name}`
  },
  update_key_dims: ({key_dims}) => {
    const [counts] = key_dims
    const updated_counts = counts.map(item => ({...item, color: DECK_TARGET_ORG_COLUMN_COLOUR}))
    return [updated_counts]
  },
  description: 'Distribution of overall patent family cost. This is calculated from priority year until today, and only applies for families with an age of 10 years or older to ensure an accurate representation of cost per family.',
  spec_id: DECK_COST_DISTRIBUTION_TARGET_ORG_ID,
  view_id: COLUMN_STACK_ID,
  is_family_count: true
}

export const DECK_COST_DISTRIBUTION_BY_PORTFOLIO_SELECTED_ORGS_CHART = {
  header: COST_TITLE,
  get_title: ({clickthrough_item}) => {
    if (!clickthrough_item || clickthrough_item.is_target_org) {
      return 'Cost distribution: Company list orgs'
    }
    const {items} = (clickthrough_item)
    return `Cost distribution: ${items.map(item => item.name).join(', ')}`
  },
  update_key_dims: ({key_dims, clickthrough_item, selections}) => {
    const [counts] = key_dims
    if (!clickthrough_item) {
      return [counts.map(item => ({...item, color: DECK_DEFAULT_COLUMN_COLOR}))]
    }
    const ids = (clickthrough_item.items || []).map(item => item.id)
    const color = (is_array_non_empty_non_null(ids) && _.all(ids, id => _.contains(selections.spotlighted_orgs, id))) ? DECK_SPOTLIGHTED_ORG_COLUMN_COLOR : DECK_DEFAULT_COLUMN_COLOR
    const updated_counts = counts.map(item => ({...item, color}))
    return [updated_counts]
  },
  get_description: ({clickthrough_item}) => {
    if (clickthrough_item && !clickthrough_item.is_target_org) {
      return 'Distribution of overall patent family cost. This is calculated from priority year until today, and only applies for families with an age of 10 years or older to ensure an accurate representation of cost per family.'
    }
    return 'Distribution of overall patent family cost for all list organisations. This is calculated from priority year until today, and only applies for families with an age of 10 years or older to ensure an accurate representation of cost per family.'
  },
  spec_id: DECK_COST_DISTRIBUTION_LIST_ORGS_ID,
  view_id: COLUMN_STACK_ID,
  is_family_count: true
}

export const DECK_PRIO_YEAR_DISTRIBUTION_BY_PORTFOLIO_TARGET_ORG_CHART = {
  header: AGE_TITLE,
  get_title: ({org_lists}) => {
    const my_org_name = get_target_org_display_name(org_lists)
    return `Priority year distribution: ${my_org_name}`
  },
  update_key_dims: ({key_dims}) => {
    const [counts] = key_dims
    const updated_counts = counts.map(item => ({...item, color: DECK_TARGET_ORG_COLUMN_COLOUR}))
    return [updated_counts]
  },
  description: 'Number of families by priority year for this organisation.',
  spec_id: DECK_PRIO_YEAR_DISTRIBUTION_TARGET_ORG_ID,
  view_id: COLUMN_STACK_ID,
  is_family_count: true
}

export const DECK_PRIO_YEAR_DISTRIBUTION_BY_PORTFOLIO_SELECTED_ORGS_CHART = {
  header: AGE_TITLE,
  get_title: ({clickthrough_item}) => {
    if (!clickthrough_item || clickthrough_item.is_target_org) {
      return 'Priority year distribution: Company list orgs'
    }
    const {items} = (clickthrough_item)
    return `Priority year distribution: ${items.map(item => item.name).join(', ')}`
  },
  update_key_dims: ({key_dims, clickthrough_item, selections}) => {
    const [counts] = key_dims
    if (!clickthrough_item) {
      return [counts.map(item => ({...item, color: DECK_DEFAULT_COLUMN_COLOR}))]
    }
    const ids = (clickthrough_item.items || []).map(item => item.id)
    const color = (is_array_non_empty_non_null(ids) && _.all(ids, id => _.contains(selections.spotlighted_orgs, id))) ? DECK_SPOTLIGHTED_ORG_COLUMN_COLOR : DECK_DEFAULT_COLUMN_COLOR
    const updated_counts = counts.map(item => ({...item, color}))
    return [updated_counts]
  },
  get_description: ({clickthrough_item}) => `Number of families by priority year${clickthrough_item ? ' for this organisation.' : ' for all list organisations.'}`,
  spec_id: DECK_PRIO_YEAR_DISTRIBUTION_LIST_ORGS_ID,
  view_id: COLUMN_STACK_ID,
  is_family_count: true
}

export const DECK_AVERAGE_COST_BY_PORTFOLIO_CHART = {
  header: COST_TITLE,
  get_title: ({granted_only}) => {return `Mean ${(granted_only === true) ? 'Granted' : 'Active'} Family Cost (company lists)`},
  description: 'Overall average cost per patent family, split by organisation. This is calculated from priority year until today, and only applies for families with an age of 10 years or older to ensure an accurate representation of the average cost per family.',
  spec_id: DECK_AVERAGE_COST_BY_PORTFOLIO_ID,
  view_id: BAR_STACK_ID,
  view_icon: DeckBarsSpotlightIcon,
  clickthrough_detail_page: COST_DISTRIBUTION_PAGE_ID
}

export const DECK_PENDING_FAMILIES_PERCENT_BY_PORTFOLIO_CHART = {
  header: PIPELINE_TITLE,
  title: 'Percent of Active Families Pending (company lists)',
  description: 'Families at pending (application) stage as a percentage of all active families owned by each organisation.',
  spec_id: DECK_PENDING_FAMILIES_PERCENT_BY_PORTFOLIO_ID,
  view_id: BAR_STACK_ID,
  view_icon: DeckBarsSpotlightIcon,
}

export const DECK_AVG_PRIORITY_YEAR_BY_ORG_CHART = {
  header: AGE_TITLE,
  get_title: ({granted_only}) => {return `Mean Priority Year: ${(granted_only === true) ? 'Granted' : 'Active'} families by year (company lists)`},
  get_description: ({granted_only}) => `Average priority year for each organisation, calculated across all their ${granted_only ? 'granted' : 'active'} families.`,
  spec_id: DECK_AVG_PRIORITY_YEAR_BY_ORG_ID,
  view_id: BAR_STACK_ID,
  view_icon: DeckBarsSpotlightIcon,
  clickthrough_detail_page: PRIO_YEAR_DISTRIBUTION_PAGE_ID
}

export const DECK_CHARTS = [
  DECK_ACTIVE_FAMILIES_BY_TECH_BY_STATUS_CHART,
  DECK_TOP_OWNERS_IN_LANDSCAPE_CHART,
  DECK_FAMILIES_BY_TECH_BY_PUBLICATION_YEAR_CHART,
  DECK_CAGR_FAMILY_FILINGS_BY_TECH_CHART,
  DECK_PATFAMS_WITH_GRANTS_BY_REGION_ALL_TECHS_CHART,
  DECK_PATFAMS_WITH_GRANTS_BY_REGION_SELECTED_TECHS_CHART,
  DECK_ACTIVE_FAMILIES_BY_TECH_BY_PORTFOLIO_CHART,
  DECK_ACTIVE_FAMILIES_BY_PORTFOLIO_CHART,
  DECK_CAGR_FAMILY_FILINGS_BY_PORTFOLIO_CHART,
  DECK_FAMILIES_BY_PORTFOLIO_BY_PUBLICATION_YEAR_CHART,
  DECK_PATFAMS_WITH_GRANTS_BY_REGION_TARGET_ORG_CHART,
  DECK_PATFAMS_WITH_GRANTS_BY_REGION_SELECTED_ORGS_CHART,
  DECK_PVIX_MEAN_BY_PORTFOLIO_CHART,
  DECK_AVERAGE_COST_BY_PORTFOLIO_CHART,
  DECK_PENDING_FAMILIES_PERCENT_BY_PORTFOLIO_CHART,
  DECK_AVG_PRIORITY_YEAR_BY_ORG_CHART
]

export const SPEC_ID_TO_DECK_CHARTS = get_as_map(DECK_CHARTS, 'spec_id')