import React, { useState, useEffect, useReducer, useRef, useCallback, useContext } from 'react'
import { withRouter } from 'react-router'
import {Redirect} from 'react-router-dom'
import cn from 'classnames'
import _ from 'underscore'
import {RouteWithTracing} from '../RouteWithTracing'

import { withUser } from '../UserContext.js'
import ViewerHeader from '../header/ViewerHeader.js'
import DatasetGroupsView from './DatasetGroupsView.js'
import ReportControlsBar from './ReportControlsBar.js'
import ExportsView from './ExportsView.js'
import ReportFamiliesView from './ReportFamiliesView.js'
import BlockingNotificationModal from '../BlockingNotificationModal.js'

import { has_classifiers_edit, has_report_series_sort } from '../../utils/user_permissions.js'
import {
  fetch_user_settings,
  get_default_chart_selection,
  get_report_region_grouping,
  should_report_show_ungrouped_families
} from '../../utils/user_settings_utils.js'

import {
  get_custom_chart_sets,
  get_default_custom_set,
  get_default_selected_items,
  get_generic_main_items,
  get_only_saved_items,
  get_selected_items,
  SELECTED_ITEMS_CIPHER_ORDER
} from '../../utils/main_items_selection_utils.js'
import {
  create_new_user_state,
  get_selections,
  get_selections_minimal,
  with_spotlighted_portfolios,
  with_spotlighted_techs,
  with_main_items,
  with_selected_geo_ids,
  with_selected_portfolio_ids,
  with_selected_tech_ids,
  with_selected_timerange
} from '../../model/user_state.js'
import { get_deref_data } from '../../utils/deref_data_utils.js'
import {
  get_default_report_series_sort,
  is_classifier_landscape_report_type,
  is_set_theory_report_type
} from '../../utils/report_utils.js'
import { get_available_groups, get_spec_refs } from '../../utils/spec_group_utils.js'
import { SELECTED_GEOS, SELECTED_PORTFOLIOS, SELECTED_TECHS } from '../../model/deref.js'
import ContainerFullWidth from '../ContainerFullWidth.js'
import { set_all_is_fetching, set_all_to_fetch, set_data, set_error } from '../../model/report_reader_fetch_state.js'
import { fetch_item_data } from '../../utils/report_reader_utils.js'
import DatasetView from './DatasetView.js'
import {
  COMPACT_VIEW_THRESHOLD,
  is_all_families_path,
  is_dataset_bulk_fetch_enabled,
  is_export_path,
  is_selected_path,
  is_small_report,
  is_zoomed_dataset_path,
  is_zoomed_group_path,
  is_large_report,
  generate_subset_id,
  is_any_path
} from '../../utils/viewer_utils.js'
import {
  add_main_item_as_selected,
  get_main_item,
  reset_deselected_items,
  with_main_item_data_orientation,
  with_main_item_defendant_type_ids,
  with_main_item_deselected_geo_ids,
  with_main_item_deselected_portfolio_ids,
  with_main_item_deselected_tech_ids,
  with_main_item_dispute_type,
  with_main_item_spotlighted_portfolios,
  with_main_item_spotlighted_techs,
  with_main_item_next_agglom_item_visibility,
  with_main_item_outcome_ids,
  with_main_item_plaintiff_type_ids,
  with_main_item_region_grouping,
  with_main_item_rollup_thresholds,
  with_main_item_start_date_range,
  with_main_item_timerange,
  with_main_item_view_id,
  with_main_item_data_should_include_all_zeros,
  with_main_item_histogram_range
} from '../../model/main_items.js'
import { save_user_state_to_history } from '../../utils/report_history_utils.js'
import { track_report_viewer_event } from '../../utils/tracking_utils.js'
import ErrorModal from '../ErrorModal.js'
import Modal from '../widgets/Modal.js'
import { PrimaryButton } from '../widgets/Button.js'
import Spinner from '../widgets/Spinner.js'
import WithHighlightPhrasesContextMenu from '../classifiers_editor/components/WithHighlightPhrasesContextMenu.js'

import {
  USER_SHOW_UNGROUPED_FAMILIES,
  USER_CHARTS_IN_THUMBNAILS,
  USER_CUSTOM_CHART_SETS,
  USER_REGION_GROUPING,
  USER_DEFAULT_CHART_SELECTION,
  USER_STARTING_PAGE,
  USER_INCLUDE_UNRELATED_TECHS,
  USER_DEFAULT_UTT_VERSION,
  NOTIFY_BEFORE_REPORT_EXPIRY,
  USER_LANGUAGE_PREFERENCE, USER_SAVE_KNN_SEARCHES
} from '../../model/user_settings.js'
import { SELECTED_GROUP_ID } from '../../model/spec_groups/spec_group_ids.js'
import { SELECTED_GROUP } from '../../model/spec_groups/selected.js'
import { SPEC_ID_TO_GROUP } from '../../model/spec_groups.js'
import { DEFAULT_PRESET } from '../../model/chart_sets.js'
import { DEBOUNCE_PERIOD } from '../../constants/constants.js'
import { ALL_FAMILIES_ID, get_new_spec_id } from '../../model/spec_ids.js'
import SelectedView from './SelectedView.js'
import UserSettingsModal from './UserSettingsModal.js'
import { register_time_monitor, unregister_time_monitor } from '../../utils/browser_activity_utils.js'
import { LOCATION_EVALUATION_REPORT, save_training_activity } from '../../utils/training_activity_utils.js'
import { get_report_classifier_task_details } from '../../utils/choreo_utils.js'
import { get_ts_counts } from '../classifiers_editor/utils/count_utils.js'
import { get_and_rebuild_classifier, get_updated_eval_landscape_input } from '../classifiers_editor/utils/evaluate_utils.js'
import IncompleteReportWarning from '../report_info/IncompleteReportWarning.js'
import { ID_TO_SPEC } from '../../model/specs.js'
import ReportInitialFetchDisplay from './ReportInitialFetchDisplay.js'
import { CIPHER_DEFAULT, CUSTOM_SET } from '../../constants/default_chart_selection.js'
import {
  DATASET_SUBPATH,
  EXPORT_SUBPATH,
  FAMILIES_SUBPATH,
  GROUP_SUBPATH,
  SELECTED_SUBPATH,
  SUBSET_SUBPATH
} from '../../constants/viewer_paths.js'
import { get_datasets_filtered_by_tags, update_selected_spec_tags } from '../../utils/spec_tags_utils.js'
import { get_normalised_name } from '../../utils/name_utils.js'
import { UserSettingsContext, UserSettingsDispatchContext } from '../UserSettingsContext.js'
import RenameReportModal from '../report_management/components/RenameReportModal.js'
import ReportDetailsModal from '../report_info/ReportDetailsModal.js'
import { new_eval_report, new_report_from_existing } from '../../utils/report_builder_utils.js'

import s from './Viewer.module.scss'

function fetch_data_reducer(state, data) {
  return {...state, ...data}
}

function has_truncated_results(choreo_status, initial_data) {
  if (initial_data && initial_data.is_truncated) {
    return initial_data.is_truncated === true
  }
  if (!choreo_status) return false
  const {is_truncated} = get_report_classifier_task_details(choreo_status)
  return (is_truncated === true)
}

const Viewer = (
  {
    user, match, history,

    is_initial_fetch,
    fetch_initial_error,
    initial_data,
    choreo_status,
    eval_classifier_data,

    rename_report,
    save_report,
    tag_or_untag_report,
    create_new_tag_and_add_to_report,
    set_label,
    on_change_phrases_to_highlight,

  }) => {

  const viewer_ref = useRef(false)

  const { user_settings, user_group_settings } = useContext(UserSettingsContext)
  const { save_user_settings_handler, reset_user_settings_handler } = useContext(UserSettingsDispatchContext)

  const {
    eval_training_set_path_to_ui,
    external_report_id,
    report_input,
    initial_report_metadata,
    available_tags,
    ref_data,
    families_count,
    timerange_field_id_to_default_extent,
    has_complete_disputes,
    report_has_scores
  } = initial_data || {}

  const { total_families_count } = families_count || {}

  const  {
    internal_report_id,
    title: report_title,
    report_type,
    created_by_user,
    created_at,
    user_state: db_user_state,
    is_saved,
    tag_ids,
    eval_training_set_id,
    notify_when_complete
  } = initial_report_metadata || {}

  const { schema_version } = ref_data || {}

  const has_missing_assignee_links = !has_complete_disputes // temporarily setting old constant, until this logic is untangled

  const { latest_built_classifier_version, training_set_info, training_set_patfams, id_to_tsfam, phrases_to_highlight, no_highlighting, id_to_tsfam_pending, id_to_tsfam_error } = eval_classifier_data || {}
  const { name: eval_training_set_title, description: eval_training_set_description} = training_set_info || {}

  const [user_state, set_user_state] = useState(null)
  const [base_groups, set_base_groups] = useState([])

  const [is_user_settings_modal_open, set_is_user_settings_modal_open] = useState(false)
  const [is_zoomed_group_collapsed, set_is_zoomed_group_collapsed] = useState(false)
  const [is_clear_all_selected_modal_open, set_is_clear_all_selected_modal_open] = useState(false)
  const [is_reset_report_modal_open, set_is_reset_report_modal_open] = useState(false)
  const [is_rerunning_eval_report, set_is_rerunning_eval_report] = useState(false)
  const [is_selected_only, set_is_selected_only] = useState(false)
  const [selected_dataset_tags, set_selected_dataset_tags] = useState(null)
  const [global_filters_updated, set_global_filters_updated] = useState(false)
  const [global_selections_before_update, set_global_selections_before_update] = useState(null)
  const [spec_id_to_fetch_obj, fetch_data_dispatcher] = useReducer(fetch_data_reducer, {})
  const [is_fetch_all_datasets, set_is_fetch_all_datasets] = useState(false)
  const [is_fetch_new_datasets, set_is_fetch_new_datasets] = useState(false)

  const [show_results_truncated_warning, set_show_results_truncated_warning] = useState(false)
  const [show_details_modal, set_show_details_modal] = useState(false)
  const [show_rename_modal, set_show_rename_modal] = useState(false)
  const [is_family_tagging_mode_on, set_is_family_tagging_mode_on] = useState(false)
  const [family_tagging_search_phrase, set_family_tagging_search_phrase] = useState(null)

  const [error_updating_user_state, set_error_updating_user_state] = useState(null)
  const [error_updating_user_settings, set_error_updating_user_settings] = useState(null)
  const [error_rerunning_eval_report, set_error_rerunning_eval_report] = useState(null)

  const is_eval_report = !!eval_training_set_id

  useEffect(() => {
    // After component has rendered for the first time, set flag to "true"
    viewer_ref.current = true

    return () => {
      // After component has been removed, set flag to "false"
      viewer_ref.current = false
    }
  }, [])

  useEffect(() => {
    if (eval_training_set_id && (training_set_patfams != null)) {
      // Register time monitor for classifier work

      const { owner_user_uuid } = training_set_info

      register_time_monitor(LOCATION_EVALUATION_REPORT, (seconds_elapsed) => {
        const counts = get_ts_counts(training_set_patfams)

        save_training_activity({
          classifier_id: eval_training_set_id,
          build_version: latest_built_classifier_version,
          owner_id: owner_user_uuid,
          seconds_elapsed,
          counts
        })
      })
    }

    return () => {
      unregister_time_monitor()
    }
  }, [
    // Since this is a Functional (hook) component,
    // we need to unbind/rebind the handler whenever training_set_patfams changes
    // (otherwise it uses a stale training_set_patfams reference).
    training_set_patfams, eval_training_set_id,
    latest_built_classifier_version, training_set_info
  ])

  useEffect(() => {
    if (!choreo_status || is_initial_fetch) return
    set_show_results_truncated_warning(has_truncated_results(choreo_status))
  }, [choreo_status, is_initial_fetch])

  useEffect(() => {
    if (is_initial_fetch || (fetch_initial_error != null)) return
    const new_user_state = db_user_state ? db_user_state : create_new_user_state()

    const selected_items = get_selected_items({
      user_state: new_user_state,
      user_settings,
      group_settings: user_group_settings,
      is_eval_report,
      has_missing_assignee_links,
      ref_data,
      report_has_scores,
      schema_version,
      user
    })

    set_user_state(with_main_items(new_user_state, selected_items))

    const groups = get_available_groups({selected_items, report_has_scores, has_missing_assignee_links, schema_version, user})

    //check if can skip fetching datasets
    if (is_all_families_path(history.location.pathname) || is_export_path(history.location.pathname)) {
      set_base_groups(groups)
      return
    }

    const direct_to_selected = user_settings && user_settings[USER_STARTING_PAGE] && (user_settings[USER_STARTING_PAGE] === SELECTED_SUBPATH) && !is_any_path(history.location.pathname)
    const group_id = is_selected_path(history.location.pathname) || direct_to_selected ? SELECTED_GROUP_ID : is_zoomed_group_path(history.location.pathname)

    //check if zoomed view
    const zoomed_spec_id = is_zoomed_dataset_path(history.location.pathname)
    const spec_ids_to_fetch = !is_dataset_bulk_fetch_enabled(total_families_count, get_should_show_charts_in_thumbnails(new_user_state)) ? [] : get_spec_ids_for_bulk_fetch(groups, group_id, selected_items)

    if (zoomed_spec_id && ID_TO_SPEC[zoomed_spec_id]) {
      spec_ids_to_fetch.push(zoomed_spec_id)
    }

    const spec_id_to_fetch_obj =  set_all_to_fetch({}, _.uniq(spec_ids_to_fetch))
    fetch_data_dispatcher(spec_id_to_fetch_obj)
    set_is_fetch_all_datasets(true)
    set_base_groups(groups)

    if (!is_selected_path(history.location.pathname) && direct_to_selected) {
      navigate_to_selected()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_initial_fetch, fetch_initial_error, db_user_state, has_missing_assignee_links, ref_data, report_type, report_has_scores, history])

  function get_spec_ids_for_bulk_fetch(groups, group_id, selected_items) {
    let spec_ids = []
    if (group_id != null) {
      const group = (group_id === SELECTED_GROUP_ID) ?
        {...SELECTED_GROUP, children: get_spec_refs(get_only_saved_items(selected_items))} :
        groups.filter(item => item.id === group_id)[0]
      const {children} = group || {}
      const filtered_children = (children || []).filter(item => !item.is_deprecated)
      spec_ids = filtered_children.map(item => item.spec_id)
    } else {
      groups.forEach(group => {
        const {children} = group
        const filtered_children = children.filter(item => !item.is_deprecated)
        const limit = (filtered_children.length - 1 === COMPACT_VIEW_THRESHOLD) ? filtered_children.length : COMPACT_VIEW_THRESHOLD
        filtered_children.slice(0, limit).forEach(item => spec_ids.push(item.spec_id))
      })
    }

    return spec_ids
  }

  useEffect(() => {
    if (is_fetch_all_datasets || is_fetch_new_datasets) {
      const minimal_selections = get_selections_minimal(user_state, ref_data, user_settings)

      const spec_ids = is_fetch_all_datasets ? Object.keys(spec_id_to_fetch_obj) : Object.keys(spec_id_to_fetch_obj).filter(id => spec_id_to_fetch_obj[id].to_fetch)

      const new_spec_id_to_fetch_obj = set_all_is_fetching({}, spec_ids)
      fetch_data_dispatcher(new_spec_id_to_fetch_obj)

      set_is_fetch_all_datasets(false)
      set_is_fetch_new_datasets(false)

      spec_ids.forEach(spec_id => {
        //temporary fix to keep the list view in dataset selection
        if (spec_id === ALL_FAMILIES_ID) return
        const main_items = get_selected_items({
          user_state,
          ref_data,
          report_has_scores,
          is_eval_report,
          has_missing_assignee_links,
          schema_version,
          user
        })
        const main_item = get_main_item(main_items, spec_id)

        const item = main_item || {spec_id}

        fetch_item_data(internal_report_id, item, minimal_selections, created_at)
          .then(data => {
            // SUCCESS
            if (viewer_ref.current) {
              const new_spec_id_to_fetch_obj = set_data({}, item.spec_id, data)
              fetch_data_dispatcher(new_spec_id_to_fetch_obj)
            }
          })
          .catch(err => {
            // FAIL
            if (viewer_ref.current) {
              const new_spec_id_to_fetch_obj = set_error({}, item.spec_id, err)
              fetch_data_dispatcher(new_spec_id_to_fetch_obj)
            }
          })
      })


    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_fetch_all_datasets, is_fetch_new_datasets, user_state, ref_data, user_settings, created_at, internal_report_id, spec_id_to_fetch_obj, has_missing_assignee_links, report_has_scores, report_type])

  function get_dataset_path(group_id, spec_id) {
    return `${match.url}/${GROUP_SUBPATH}/${group_id}/${DATASET_SUBPATH}/${spec_id}`
  }

  function navigate_to_dataset(group_id, spec_id) {
    track_report_viewer_event(`obj="dataset" dataset="${spec_id}" action="viewed"`)
    history.push(get_dataset_path(group_id, spec_id))
  }

  function do_refresh_datasets() {
    track_report_viewer_event('obj="datasets" action="refresh"')
    set_is_fetch_all_datasets(true)
    set_global_selections_before_update(null)
    set_global_filters_updated(false)
  }

  function on_fetch_data_for_datasets(ids_to_fetch) {
    if (!ids_to_fetch || (ids_to_fetch.length === 0)) return
    const new_spec_id_to_fetch_obj = set_all_to_fetch({}, _.uniq(ids_to_fetch))
    fetch_data_dispatcher(new_spec_id_to_fetch_obj)
    set_is_fetch_new_datasets(true)
  }

  function save_user_state_to_db(user_state, external_report_id_param) {
    // Write to db (async)
    save_user_state_to_history(external_report_id_param || external_report_id, user_state)
      .catch(error => {
        set_error_updating_user_state(error)
      })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const save_user_state_to_db_debounced = useCallback(
    _.debounce((user_state, external_report_id) => save_user_state_to_db(user_state, external_report_id), DEBOUNCE_PERIOD),
    [])

  function on_datasets_select(spec_ids) {
    track_report_viewer_event(`obj="dataset" dataset="${spec_ids.join(',')}" action="add"`)
    const main_items = get_selected_items({user_state, ref_data, report_has_scores, is_eval_report, has_missing_assignee_links, schema_version, user})
    let new_main_items = [...main_items]

    spec_ids.forEach(spec_id => {
      new_main_items = add_main_item_as_selected(new_main_items, spec_id)
    })

    update_main_items_in_state_and_db(new_main_items)
  }

  function on_datasets_deselect(spec_ids) {
    track_report_viewer_event(`obj="dataset" dataset="${spec_ids.join(',')}" action="remove"`)
    const main_items = get_selected_items({user_state, ref_data, report_has_scores, is_eval_report, has_missing_assignee_links, schema_version, user})
    const new_main_items = main_items.map(item => {return spec_ids.indexOf(item.spec_id) === -1 ?  item : {...item, is_temp: true}})
    const new_user_state = with_main_items(user_state, new_main_items)
    if ( get_only_saved_items(new_main_items).length === 0) {
      set_is_selected_only(false)
      if (is_selected_view) {
        navigate_to_selected()
      }
    }
    set_user_state(new_user_state)
    save_user_state_to_db(new_user_state)
  }

  function on_clear_selected_datasets() {
    track_report_viewer_event(`obj="dataset" action="bulk_remove"`)
    const main_items = get_selected_items({user_state, ref_data, report_has_scores, is_eval_report, has_missing_assignee_links, schema_version, user})
    update_main_items_in_state_and_db(main_items.map(item => {return {...item, is_temp: true}}))
    set_is_selected_only(false)
    if (is_selected_view) {
      //in case the current path points to a specific dataset that is no in selection anymore
      navigate_to_selected()
    }
  }

  function on_datasets_reshuffle(ordered_main_items) {
    track_report_viewer_event(`obj="dataset" action="order"`)
    update_main_items_in_state_and_db(ordered_main_items)
  }

  function update_main_items_in_state_and_db(new_main_items) {
    const new_user_state = with_main_items(user_state, new_main_items)
    set_user_state(new_user_state)
    save_user_state_to_db(new_user_state)
  }

  function change_report_sort(sort) {
    track_report_viewer_event(`action="series_sort_change" sort="${sort}"`)
    const new_user_state = {...user_state}
    new_user_state['report_series_sort'] = sort
    set_user_state(new_user_state) // show state immediately
    save_user_state_to_db(new_user_state)    // save to db
    set_is_fetch_all_datasets(true)
  }

  function change_report_thumbnails(show_charts) {
    track_report_viewer_event(`action="change_report_thumbnails" show_charts="${show_charts}"`)
    const new_user_state = {...user_state}
    new_user_state['report_charts_in_thumbnails'] = show_charts
    set_user_state(new_user_state) // show state immediately
    if (show_charts) {
      fetch_data_for_thumbnails(available_groups)
    }

    save_user_state_to_db(new_user_state)
  }

  function change_report_should_show_ungrouped_families() {
    const is_show = should_report_show_ungrouped_families(user_state, user_settings)
    track_report_viewer_event(`action="show_report_ungrouped_families" show="${!is_show}" `)

    const new_user_state = {...user_state}
    new_user_state['show_ungrouped_families'] = !is_show
    set_user_state(new_user_state)
    save_user_state_to_db(new_user_state)
    set_global_filters_updated(true)

    const spec_id = is_zoomed_dataset_path(history.location.pathname)

    if (spec_id) {
      on_fetch_data_for_datasets([spec_id])
    }

    if (is_all_families_view) {
      update_families_view_url()
    }
  }

  function change_selected_charts_display_order_preference(chart_order) {
    track_report_viewer_event(`action="change_selected_charts_display_order" chart_order="${chart_order}"`)
    const new_user_state = {...user_state}
    new_user_state['selected_charts_display_order'] = chart_order
    set_user_state(new_user_state)
    save_user_state_to_db(new_user_state)
  }

  function fetch_data_for_thumbnails(groups, selected_group_id) {
    const group_id = selected_group_id || is_zoomed_group_path(history.location.pathname)
    const spec_ids_for_bulk_fetch = get_spec_ids_for_bulk_fetch(groups, group_id, selected_items)
    const ids_to_fetch = spec_ids_for_bulk_fetch.filter(id => spec_id_to_fetch_obj[id] == null)
    on_fetch_data_for_datasets(ids_to_fetch)
  }

  function rerun_eval_report () {
    if (is_rerunning_eval_report) {
      return
    }

    set_is_rerunning_eval_report(true)

    track_report_viewer_event('obj="evaluation_report" action="report_rerun"')

    const { owner_user_uuid } = training_set_info

    // Capture activity
    save_training_activity({
      classifier_id: eval_training_set_id,
      build_version: latest_built_classifier_version,
      owner_id: owner_user_uuid,
      num_classifier_builds:  1,
      num_eval_report_builds: 1
    })

    // Rebuild classifier
    get_and_rebuild_classifier(eval_training_set_id)
      .then(() => {
        if (is_classifier_landscape_report_type(report_type)) {
          // Landscape
          const updated_report_input = get_updated_eval_landscape_input(
            {
              report_input,
              report_name: report_title,
              training_set_id: eval_training_set_id,
              training_set_name: eval_training_set_title,
              training_set_description: eval_training_set_description
            })
          return new_eval_report(updated_report_input, report_title, eval_training_set_id, history)
        }

        // Normal
        return new_report_from_existing(external_report_id, history, report_type, true)
      })
      .catch(err => {
        set_is_rerunning_eval_report(false)
        set_error_rerunning_eval_report(err)
      })
  }

  function no_data_in_report(selections) {
    const selected_portfolios = selections[SELECTED_PORTFOLIOS] || []
    const selected_techs = selections[SELECTED_TECHS] || []
    const selected_geos = selections[SELECTED_GEOS] || []

    return (selected_portfolios.length === 0) || (selected_techs.length === 0) || (selected_geos.length === 0)
  }

  function get_report_series_sort(user_state) {
    const { report_series_sort } = user_state || {}

    if (!has_report_series_sort(user) && !report_series_sort) {
      return get_default_report_series_sort().id || 'size'
    }

    return (report_series_sort !== undefined) ? report_series_sort : has_report_series_sort(user)
  }

  function get_should_show_charts_in_thumbnails() {
    return false

    //temporarily commenting out, in case we need to keep this logic

    /*const { report_charts_in_thumbnails } = user_state || {}

    if (BROWSER_IS_IE_11) return false

    if (report_charts_in_thumbnails != null) {
      return report_charts_in_thumbnails
    }

    if (!is_small_report(total_families_count)) {
      return false
    }

    return has_charts_in_thumbnails(user_settings)*/
  }

  function get_selected_charts_display_order_preference(user_state) {
    const { selected_charts_display_order } = user_state || {}

    return selected_charts_display_order || SELECTED_ITEMS_CIPHER_ORDER
  }

  function on_dataset_zoom(group_id, spec_id) {
    const not_pre_fetched = (spec_id_to_fetch_obj[spec_id] == null)

    if (global_filters_updated || not_pre_fetched) {
      on_fetch_data_for_datasets([spec_id])
    }

    navigate_to_dataset(group_id, spec_id)
  }

  function on_group_zoom(group, selected_spec_id) {
    const {id: group_id, children} = group
    const fetched_items_ids = Object.keys(spec_id_to_fetch_obj)
    const group_items_ids = children.map(item => item.spec_id)
    const zoomed_dataset_id = selected_spec_id || group_items_ids[0]

    //if report isn't large, pre-fetch the whole group, otherwise fetch only the displayed dataset
    const selected_group_items_ids = !is_dataset_bulk_fetch_enabled(total_families_count, get_should_show_charts_in_thumbnails(user_state)) ? [zoomed_dataset_id] : group_items_ids

    const ids_to_fetch = _.difference(selected_group_items_ids, fetched_items_ids)
    if (global_filters_updated) {
      ids_to_fetch.push(zoomed_dataset_id)
    }

    if (ids_to_fetch.length > 0) {
      on_fetch_data_for_datasets(_.uniq(ids_to_fetch))
    }
    navigate_to_dataset(group_id, zoomed_dataset_id)
  }

  function get_main_items_and_include_selected(selected_spec_id) {
    const main_items = get_selected_items({user_state, ref_data, report_has_scores, is_eval_report, has_missing_assignee_links, schema_version, user})
    const selected_main_item = get_main_item(main_items, selected_spec_id)
    const new_main_items = selected_main_item ? [...main_items] : [...main_items, { spec_id: selected_spec_id, is_temp: true }]
    return new_main_items
  }

  function update_user_state_with_main_items(main_items) {
    const new_user_state = with_main_items(user_state, main_items)
    set_user_state(new_user_state)
    save_user_state_to_db(new_user_state)
  }

  function change_dataset_view(spec_id, view_id) {
    track_report_viewer_event(`obj="dataset" dataset="${spec_id}" action="select_view" view="${view_id}" `)
    const main_items = get_main_items_and_include_selected(spec_id)
    const updated_main_items = with_main_item_view_id(main_items, spec_id, view_id)
    update_user_state_with_main_items(updated_main_items)
  }

  function change_dataset_dispute_type(spec_id, selected_dispute_type) {
    track_report_viewer_event(`obj="dataset" dataset="${spec_id}" action="select_dispute_type" dispute_type="${selected_dispute_type}"`)
    const main_items = get_main_items_and_include_selected(spec_id)
    const updated_main_items = with_main_item_dispute_type(main_items, spec_id, selected_dispute_type)
    update_user_state_with_main_items(updated_main_items)
  }

  function change_dataset_region_grouping(spec_id, region_grouping) {
    track_report_viewer_event(`obj="dataset" dataset="${spec_id}" action="select_region_grouping" region_type="${region_grouping}"`)
    const main_items = get_main_items_and_include_selected(spec_id)
    const updated_main_items = with_main_item_region_grouping(main_items, spec_id, region_grouping)
    update_user_state_with_main_items(updated_main_items)
    on_fetch_data_for_datasets([spec_id])
  }

  function change_dataset_data_orientation(spec_id, data_orientation) {
    track_report_viewer_event(`obj="dataset" dataset="${spec_id}" action="change_data_orientation" data_orientation="${data_orientation}"`)
    const main_items = get_main_items_and_include_selected(spec_id)
    const updated_main_items = with_main_item_data_orientation(main_items, spec_id, data_orientation)
    update_user_state_with_main_items(updated_main_items)
  }

  function change_data_include_all_zeros(spec_id, should_include_all_zeros) {
    track_report_viewer_event(`obj="dataset" dataset="${spec_id}" action="change_data_include_all_zeros" should_include_all_zeros="${should_include_all_zeros}"`)
    const main_items = get_main_items_and_include_selected(spec_id)
    const updated_main_items = with_main_item_data_should_include_all_zeros(main_items, spec_id, should_include_all_zeros)
    update_user_state_with_main_items(updated_main_items)
  }

  function change_dataset_rollup_thresholds(spec_id, rollup_thresholds, deselected_items_to_reset_key_name) {
    track_report_viewer_event(`obj="dataset" dataset="${spec_id}" action="change_rollup" rollup_value="${JSON.stringify(rollup_thresholds)}"`)
    const main_items = get_main_items_and_include_selected(spec_id)
    let updated_main_items = with_main_item_rollup_thresholds(main_items, spec_id, rollup_thresholds)
    if (deselected_items_to_reset_key_name) {
      updated_main_items = reset_deselected_items(updated_main_items, spec_id, deselected_items_to_reset_key_name)
    }

    update_user_state_with_main_items(updated_main_items)
  }

  function change_dataset_next_agglom_item_visibility(spec_id, next_agglom) {
    track_report_viewer_event(`obj="dataset" dataset="${spec_id}" action="change_next_agglom_item_visibility" next_agglom="${JSON.stringify(next_agglom)}"`)
    const main_items = get_main_items_and_include_selected(spec_id)
    const updated_main_items = with_main_item_next_agglom_item_visibility(main_items, spec_id, next_agglom)
    update_user_state_with_main_items(updated_main_items)
  }

  function change_dataset_timerange(spec_id, timerange) {
    track_report_viewer_event(`obj="dataset" dataset="${spec_id}" action="change_timerange" timerange_value="${timerange}"`, true)
    const main_items = get_main_items_and_include_selected(spec_id)
    const updated_main_items = with_main_item_timerange(main_items, spec_id, timerange)
    const new_user_state = with_main_items(user_state, updated_main_items)
    set_user_state(new_user_state)
    save_user_state_to_db_debounced(new_user_state, external_report_id)
  }

  function change_dataset_histogram_range(spec_id, histogram_range) {
    track_report_viewer_event(`obj="dataset" dataset="${spec_id}" action="change_histogram_range" histogram_rang="${histogram_range}"`, true)
    const main_items = get_main_items_and_include_selected(spec_id)
    const updated_main_items = with_main_item_histogram_range(main_items, spec_id, histogram_range)
    const new_user_state = with_main_items(user_state, updated_main_items)
    set_user_state(new_user_state)
    save_user_state_to_db_debounced(new_user_state, external_report_id)
  }

  function change_dataset_portfolios_selection(spec_id, deselected_portfolio_ids, next_agglom) {
    const main_items = get_main_items_and_include_selected(spec_id)
    let updated_main_items = with_main_item_deselected_portfolio_ids(main_items, spec_id, deselected_portfolio_ids)
    if (next_agglom) {
      updated_main_items = with_main_item_next_agglom_item_visibility(updated_main_items, spec_id, next_agglom)
    }
    update_user_state_with_main_items(updated_main_items)
  }

  function change_dataset_techs_selection(spec_id, deselected_tech_ids, next_agglom) {
    const main_items = get_main_items_and_include_selected(spec_id)
    let updated_main_items = with_main_item_deselected_tech_ids(main_items, spec_id, deselected_tech_ids)
    if (next_agglom) {
      updated_main_items = with_main_item_next_agglom_item_visibility(updated_main_items, spec_id, next_agglom)
    }
    update_user_state_with_main_items(updated_main_items)
  }

  function change_dataset_geos_selection(spec_id, deselected_geo_ids, next_agglom) {
    const main_items = get_main_items_and_include_selected(spec_id)
    let updated_main_items = with_main_item_deselected_geo_ids(main_items, spec_id, deselected_geo_ids)
    if (next_agglom) {
      updated_main_items = with_main_item_next_agglom_item_visibility(updated_main_items, spec_id, next_agglom)
    }
    update_user_state_with_main_items(updated_main_items)
  }

  function change_dataset_plaintiff_type_ids(spec_id, plaintiff_type_ids) {
    track_report_viewer_event(`obj="dataset" dataset="${spec_id}" action="change_plaintiff_type_ids"`, true)
    const main_items = get_main_items_and_include_selected(spec_id)
    const updated_main_items = with_main_item_plaintiff_type_ids(main_items, spec_id, plaintiff_type_ids)
    update_user_state_with_main_items(updated_main_items)
  }

  function change_dataset_defendant_type_ids(spec_id, defendant_type_ids) {
    track_report_viewer_event(`obj="dataset" dataset="${spec_id}" action="change_defendant_type_ids"`, true)
    const main_items = get_main_items_and_include_selected(spec_id)
    const updated_main_items = with_main_item_defendant_type_ids(main_items, spec_id, defendant_type_ids)
    update_user_state_with_main_items(updated_main_items)
  }

  function change_dataset_outcome_ids(spec_id, outcome_ids) {
    track_report_viewer_event(`obj="dataset" dataset="${spec_id}" action="change_outcome_ids"`, true)
    const main_items = get_main_items_and_include_selected(spec_id)
    const updated_main_items = with_main_item_outcome_ids(main_items, spec_id, outcome_ids)
    update_user_state_with_main_items(updated_main_items)
  }

  function change_dataset_start_date_range(spec_id, start_date_range) {
    track_report_viewer_event(`obj="dataset" dataset="${spec_id}" action="change_start_date_range"`, true)
    const main_items = get_main_items_and_include_selected(spec_id)
    const updated_main_items = with_main_item_start_date_range(main_items, spec_id, start_date_range)
    update_user_state_with_main_items(updated_main_items)
  }

  function change_dataset_spotlighted_portfolios(spec_id, spotlighted_portfolios) {
    track_report_viewer_event(`obj="dataset" dataset="${spec_id}" action="change_spotlighted_portfolios"`, true)
    const main_items = get_main_items_and_include_selected(spec_id)
    const updated_main_items = with_main_item_spotlighted_portfolios(main_items, spec_id, spotlighted_portfolios)
    update_user_state_with_main_items(updated_main_items)
  }

  function change_dataset_spotlighted_techs(spec_id, spotlighted_techs) {
    track_report_viewer_event(`obj="dataset" dataset="${spec_id}" action="change_spotlighted_techs"`, true)
    const main_items = get_main_items_and_include_selected(spec_id)
    const updated_main_items = with_main_item_spotlighted_techs(main_items, spec_id, spotlighted_techs)
    update_user_state_with_main_items(updated_main_items)
  }

  function change_region_grouping(grouping) {
    track_report_viewer_event(`obj="user_settings" action="change_user_regions" grouping="${grouping}"`)
    save_user_setting_by_key_and_value(USER_REGION_GROUPING, grouping, {fetch_all_datasets: true})
  }

  function change_should_show_ungrouped_families(should_show_ungrouped_families) {
    track_report_viewer_event(`obj="user_settings" action="show_ungrouped_families" show="${should_show_ungrouped_families}"`)

    const { show_ungrouped_families: report_show_ungrouped_families } = user_state || {}
    const fetch_all_datasets = (report_show_ungrouped_families == null)

    save_user_setting_by_key_and_value(USER_SHOW_UNGROUPED_FAMILIES, should_show_ungrouped_families, {fetch_all_datasets})

    if (is_all_families_view && fetch_all_datasets) {
      update_families_view_url()
    }
  }

  function change_default_chart_selection(default_chart_selection) {
    track_report_viewer_event(`obj="user_settings" action="change_default_chart_selection" chart_selection="${default_chart_selection}"`)

    if (default_chart_selection === CIPHER_DEFAULT) {
      on_select_user_chart_set(DEFAULT_PRESET)
    }

    if (default_chart_selection == null) {
      on_clear_selected_datasets()
    }

    if (default_chart_selection && default_chart_selection.startsWith(CUSTOM_SET)) {

      const custom_set = get_default_custom_set({default_chart_selection, user_settings, group_settings: user_group_settings})

      on_select_user_chart_set(custom_set)
    }

    save_user_setting_by_key_and_value(USER_DEFAULT_CHART_SELECTION, default_chart_selection)
  }

  function change_default_utt_version(default_utt_version) {
    track_report_viewer_event(`obj="user_settings" action="change_default_utt_version" utt_version="${default_utt_version}"`)

    save_user_setting_by_key_and_value(USER_DEFAULT_UTT_VERSION, default_utt_version)
  }

  function change_thumbnails(show_charts) {
    track_report_viewer_event(`obj="user_settings" action="change_user_thumbnails" show_charts="${show_charts}"`)

    save_user_settings_handler(USER_CHARTS_IN_THUMBNAILS, show_charts)
      .then(() => {
        if (show_charts && (!is_small_report(total_families_count) || global_filters_updated)) {
          fetch_data_for_thumbnails(available_groups)
        }
      })
  }

  function change_starting_page(starting_page) {
    track_report_viewer_event(`obj="user_settings" action="change_user_starting_page" starting_page="${starting_page}"`)

    save_user_setting_by_key_and_value(USER_STARTING_PAGE, starting_page)
  }

  function change_should_include_unrelated_techs(include_unrelated) {
    track_report_viewer_event(`obj="user_settings" action="change_should_include_unrelated_techs" include_unrelated="${include_unrelated}"`)

    save_user_setting_by_key_and_value(USER_INCLUDE_UNRELATED_TECHS, include_unrelated)
  }

  function toggle_notify_before_report_expiry(should_notify) {
    track_report_viewer_event(`obj="user_settings" action="change_${NOTIFY_BEFORE_REPORT_EXPIRY}" should_notify="${should_notify}"`)

    save_user_setting_by_key_and_value(NOTIFY_BEFORE_REPORT_EXPIRY, should_notify)
  }

  function change_language_preference(language_preference) {
    track_report_viewer_event(`obj="user_settings" action="change_${USER_LANGUAGE_PREFERENCE}" language="${language_preference}"`)

    save_user_setting_by_key_and_value(USER_LANGUAGE_PREFERENCE, language_preference)
  }

  function change_save_knn_searches(save_knn_searches) {
    track_report_viewer_event(`obj="user_settings" action="change_${USER_SAVE_KNN_SEARCHES}" save_knn_searches="${save_knn_searches}"`)

    save_user_setting_by_key_and_value(USER_SAVE_KNN_SEARCHES, save_knn_searches)
  }

  function reset_user_settings() {
    track_report_viewer_event(`obj="user_settings" action="reset_user_settings"`)
    reset_user_settings_handler()
      .then(() => {
        set_is_fetch_all_datasets(true)
      })
  }

  function save_user_setting_by_key_and_value(key, value, params) {
    const {fetch_all_datasets} = params || {}

    save_user_settings_handler(key, value)
      .catch(err => {
        set_error_updating_user_settings(err)
        throw err
      })
      .then(() => {
        if (fetch_all_datasets) {
          set_is_fetch_all_datasets(true)
        }
      })
  }

  function change_show_selected_only({should_show_selected_only, group_id}) {
    track_report_viewer_event(`obj="datasets" show="${should_show_selected_only ? 'selected' : 'all'}"`)

    if (should_show_selected_only) {
      fetch_data_for_thumbnails(available_groups, SELECTED_GROUP_ID)
    }

    if (!should_show_selected_only && group_id) {
      fetch_data_for_thumbnails(base_groups.filter(group => group.id === group_id), group_id)
    }
    set_is_selected_only(should_show_selected_only)
  }

  function filter_datasets_by_tags(selected_tag_id) {
    const updated_tags =  update_selected_spec_tags(selected_dataset_tags || [], selected_tag_id)
    set_selected_dataset_tags(updated_tags)
  }

  function clear_selected_dataset_tags() {
    set_selected_dataset_tags(null)
  }

  function on_save_user_chart_set(user_chart_set_name) {

    function update_sets(user_sets, items, user_chart_set_name) {
      const set_to_update = user_sets.filter(set => get_normalised_name(set.name) === user_chart_set_name)[0]

      const should_update = set_to_update != null
      track_report_viewer_event(`obj="user_chart_set" action="${should_update ? 'update' : 'add'}"`)

      if (!should_update) return [...user_sets, {name: user_chart_set_name, items}]

      return  user_sets.map(set => {
        const {name} = set
        if (get_normalised_name(name) === user_chart_set_name) return {name: user_chart_set_name, items}
        return set
      })
    }

    fetch_user_settings()
      .then(user_settings_response => {

        const user_sets = user_settings_response[USER_CUSTOM_CHART_SETS] || []
        const main_items = get_selected_items({user_state, ref_data, report_has_scores, is_eval_report, has_missing_assignee_links, schema_version, user})
        const saved_main_items = get_only_saved_items(main_items)
        const generic_main_items = get_generic_main_items(saved_main_items)
        const updated_user_sets =  update_sets(user_sets, generic_main_items, get_normalised_name(user_chart_set_name))

        return save_user_settings_handler(USER_CUSTOM_CHART_SETS, updated_user_sets)
      })
      .catch(err => {
        set_error_updating_user_settings(err)
        throw err
      })
  }

  function on_remove_user_chart_set(user_set) {
    track_report_viewer_event(`obj="user_chart_set" action="delete"`)
    const { name='' } = user_set || {}
    const user_sets = user_settings[USER_CUSTOM_CHART_SETS] || []
    const updated_user_sets = user_sets.filter(item => item.name !== name)

    save_user_setting_by_key_and_value(USER_CUSTOM_CHART_SETS, updated_user_sets)
  }

  function on_select_user_chart_set(set) {
    track_report_viewer_event(`obj="user_chart_set" action="select"`)
    const {items, id} = set || {}

    let selected_items = items

    if (id === DEFAULT_PRESET.id) {
      selected_items = get_default_selected_items({ref_data, is_eval_report, report_has_scores})
    }

    if (!selected_items) return

    const spec_ids = selected_items.map(item => item.spec_id)
    const new_main_items = selected_items.map(item => {
      return {...item, is_temp: false}
    })

    const new_user_state = with_main_items(user_state, new_main_items)
    set_user_state(new_user_state)
    on_fetch_data_for_datasets(spec_ids)
    save_user_state_to_db(new_user_state)

    const spec_id = is_zoomed_dataset_path(history.location.pathname)

    if (spec_id) {
      const selected_spec_id = spec_ids.indexOf(spec_id) !== -1 ? spec_id : spec_ids[0]
      on_group_zoom(SELECTED_GROUP, selected_spec_id)
    }
  }

  function set_selected_portfolio_ids(selected_portfolio_ids) {
    if (global_selections_before_update == null) {
      set_global_selections_before_update(get_deref_data(ref_data, user_state, selections))
    }
    const new_user_state = with_selected_portfolio_ids(user_state, selected_portfolio_ids)
    on_global_filters_update(new_user_state, selected_portfolio_ids.length > 0, false)
  }

  function set_selected_tech_ids(selected_tech_ids) {
    if (global_selections_before_update == null) {
      set_global_selections_before_update(get_deref_data(ref_data, user_state, selections))
    }
    const new_user_state = with_selected_tech_ids(user_state, selected_tech_ids)
    on_global_filters_update(new_user_state, selected_tech_ids.length > 0, false)
  }

  function set_selected_geo_ids(selected_geo_ids) {
    if (global_selections_before_update == null) {
      set_global_selections_before_update(get_deref_data(ref_data, user_state, selections))
    }
    const new_user_state = with_selected_geo_ids(user_state, selected_geo_ids)
    on_global_filters_update(new_user_state, selected_geo_ids.length > 0, false)
  }

  function set_selected_timerange(selected_timerange) {
    if (global_selections_before_update == null) {
      set_global_selections_before_update(get_deref_data(ref_data, user_state, selections))
    }
    const new_user_state = with_selected_timerange(user_state, selected_timerange)
    on_global_filters_update(new_user_state, true, true)
  }

  function on_global_filters_update(updated_user_state, any_filter_items_selected, update_state_with_delay) {
    set_user_state(updated_user_state)

    update_state_with_delay ?
      save_user_state_to_db_debounced(updated_user_state, external_report_id) :
      save_user_state_to_db(updated_user_state)

    set_global_filters_updated(true)

    const spec_id = is_zoomed_dataset_path(history.location.pathname)

    if (spec_id && any_filter_items_selected) {
      on_fetch_data_for_datasets([spec_id])
    }

    if (is_all_families_view) {
      update_families_view_url()
    }
  }

  function set_spotlighted_portfolios(spotlighted_portfolios) {
    const new_user_state = with_spotlighted_portfolios(user_state, spotlighted_portfolios)
    set_user_state(new_user_state)
    save_user_state_to_db(new_user_state)
  }

  function set_spotlighted_techs(spotlighted_techs) {
    const new_user_state = with_spotlighted_techs(user_state, spotlighted_techs)
    set_user_state(new_user_state)
    save_user_state_to_db(new_user_state)
  }

  function reset_report() {
    track_report_viewer_event('obj="report" action="hard_reset"')

    let new_user_state = with_main_items(user_state, [])

    new_user_state = {...new_user_state, selections: {}}
    delete new_user_state.show_ungrouped_families
    const spec_id = is_zoomed_dataset_path(history.location.pathname)
    if (spec_id) {
      on_fetch_data_for_datasets([spec_id])
    }

    set_global_filters_updated(false)
    set_user_state(new_user_state)
    save_user_state_to_db(new_user_state)
    set_is_selected_only(false)
    set_selected_dataset_tags(null)
  }

  function toggle_user_settings_modal() {
    const should_open = !is_user_settings_modal_open
    track_report_viewer_event('obj="user_settings_modal" action="show"')
    set_is_user_settings_modal_open(should_open)
  }

  function back_to_report() {
    if (is_dataset_bulk_fetch_enabled(total_families_count, get_should_show_charts_in_thumbnails(user_state))) {
      const spec_ids_for_bulk_fetch = get_spec_ids_for_bulk_fetch(available_groups)
      const ids_to_fetch = spec_ids_for_bulk_fetch.filter(id => spec_id_to_fetch_obj[id] == null)
      on_fetch_data_for_datasets(ids_to_fetch)
    }

    track_report_viewer_event('obj="report_overview" action="navigate"')
    history.push(match.url)
  }

  function navigate_to_exports() {
    track_report_viewer_event('obj="exports" action="navigate"')
    history.push(`${match.url}/${EXPORT_SUBPATH}`)
  }

  function navigate_to_families_view() {
    track_report_viewer_event('obj="families_list" action="navigate"')
    update_families_view_url()
  }

  function update_families_view_url() {
    history.push(`${match.url}/${FAMILIES_SUBPATH}/${SUBSET_SUBPATH}/${generate_subset_id()}`)
  }

  function navigate_to_selected() {
    track_report_viewer_event('obj="selected_view" action="navigate"')
    if (is_dataset_bulk_fetch_enabled(total_families_count, get_should_show_charts_in_thumbnails(user_state))) {
      const spec_ids_for_bulk_fetch = get_spec_ids_for_bulk_fetch([], SELECTED_GROUP_ID, selected_items)
      const ids_to_fetch = spec_ids_for_bulk_fetch.filter(id => spec_id_to_fetch_obj[id] == null)
      on_fetch_data_for_datasets(ids_to_fetch)
    }

    history.push(`${match.url}/${SELECTED_SUBPATH}`)
  }

  function get_chart_sets() {
    if (is_initial_fetch) return []
    return get_custom_chart_sets(user_settings, user_group_settings)
  }

  function filter_groups() {
    const groups = base_groups || []

    if (!is_selected_only && !selected_dataset_tags ) {
      return groups
    }

    const available_groups = []

    const selected_items_ids = get_only_saved_items(selected_items).map(item => item.spec_id)

    groups.forEach(group => {
      const { children } = group
      const items_filtered_by_tags = get_datasets_filtered_by_tags(children, selected_dataset_tags)

      const selected_group_children = (is_selected_only) ? items_filtered_by_tags.filter(item => selected_items_ids.indexOf(item.spec_id) > -1) : items_filtered_by_tags

      if (selected_group_children.length > 0) {
        available_groups.push({...group, children: selected_group_children})
      }
    })

    if (show_charts_in_thumbnails) {
      fetch_data_for_thumbnails(available_groups)
    }

    return available_groups
  }

  function on_toggle_family_tagging_mode() {
    set_is_family_tagging_mode_on(!is_family_tagging_mode_on)
  }

  function on_update_family_tagging_search_phrase(text) {
    set_family_tagging_search_phrase(text)
  }

  const { portfolios, portfolios_count_incl_rollups, techs, geos, continent_id_to_geo_ids, data_creation_date } = ref_data || {}

  const selections = get_selections(user_state, ref_data, user_settings)
  const deref_data = get_deref_data(ref_data, user_state, selections) || {}

  const minimal_selections = get_selections_minimal(user_state, ref_data, user_settings)

  const report_series_sort = get_report_series_sort(user_state)
  const show_charts_in_thumbnails = get_should_show_charts_in_thumbnails(user_state)
  const selected_charts_display_order_preference = get_selected_charts_display_order_preference(user_state)
  const report_region_grouping = get_report_region_grouping(user_settings)
  const {
    selected_portfolio_ids,
    selected_tech_ids,
    selected_geo_ids,
    selected_timerange,
    spotlighted_portfolios,
    spotlighted_techs
  } = selections || {}

  const selected_items = get_selected_items({user_state, ref_data, report_has_scores, is_eval_report, has_missing_assignee_links, schema_version, user})

  const available_groups = filter_groups()

  const no_data = no_data_in_report(deref_data)

  const is_incomplete = has_truncated_results(choreo_status, initial_data)

  const user_chart_sets = get_chart_sets()

  const show_ungrouped_families = should_report_show_ungrouped_families(user_state, user_settings)

  const is_grid_view = !!match.isExact
  const is_all_families_view = is_all_families_path(history.location.pathname)
  const is_export_view = is_export_path(history.location.pathname)
  const is_selected_view = is_selected_path(history.location.pathname)

  const user_charts_props = {
    user_chart_sets,
    default_chart_selection: get_default_chart_selection(user_settings),
    on_save_user_chart_set,
    on_remove_user_chart_set,
    on_select_user_chart_set,
    on_clear_selected_datasets: () => {set_is_clear_all_selected_modal_open(true)},
    change_default_chart_selection_handler: change_default_chart_selection
  }

  const report_state_props = {
    user_settings,
    selected_items,
    has_missing_assignee_links,
    report_type,
    report_input,
    report_has_scores,
    spec_id_to_fetch_obj: spec_id_to_fetch_obj || {},
    data_creation_date,
    ref_data,
    deref_data,
    deref_data_for_thumbnails: global_selections_before_update || deref_data,
    internal_report_id,
    external_report_id,
    report_series_sort,
    show_charts_in_thumbnails,
    minimal_selections,
    selections,
    report_title,
    report_region_grouping,
    is_family_tagging_mode_on,
    family_tagging_search_phrase: family_tagging_search_phrase || '',
    selected_charts_display_order_preference,

    change_selected_charts_display_order_preference,
    on_datasets_reshuffle,
    on_datasets_select,
    on_datasets_deselect,
    on_dataset_zoom,
    on_group_zoom,
    on_toggle_family_tagging_mode,
    on_update_family_tagging_search_phrase
  }

  const is_set_theory_report = is_set_theory_report_type(report_type)

  const user_has_classifier_builder_access = has_classifiers_edit(user)

  return (
    <>
      <div className='sticky-top'>
        <ViewerHeader
          is_initial_fetch={is_initial_fetch}
          fetch_initial_error={fetch_initial_error}
          internal_report_id={internal_report_id}
          external_report_id={external_report_id}
          report_input={report_input}
          report_title={report_title}
          report_type={report_type}
          data_creation_date={data_creation_date}

          is_saved={is_saved}
          is_incomplete={is_incomplete}
          created_by_user={created_by_user}
          report_series_sort={report_series_sort}
          show_charts_in_thumbnails={show_charts_in_thumbnails}
          chart_in_thumbnails_allowed={!is_large_report(total_families_count)}
          selections={selections}
          selected_items={selected_items}
          available_tags={available_tags || []}
          tag_ids={tag_ids}

          eval_classifier_title={eval_training_set_title}
          eval_classifier_path_to_ui={eval_training_set_path_to_ui}
          eval_classifier_id={eval_training_set_id}

          save_report_handler={save_report}
          rerun_eval_report={rerun_eval_report}
          change_report_sort_handler={change_report_sort}
          show_user_settings_handler={toggle_user_settings_modal}
          change_report_thumbnails_handler={change_report_thumbnails}
          rename_report_handler={() => set_show_rename_modal(true)}
          tag_report_handler={tag_or_untag_report}
          create_new_tag_handler={create_new_tag_and_add_to_report}
          reset_report_handler={() => set_is_reset_report_modal_open(true)}
          user_settings={user_settings || {}}
          user_state={user_state}
          no_data={no_data}
        />

        {!is_initial_fetch && !fetch_initial_error &&
          <ReportControlsBar
            families_count={families_count}
            created_at={created_at}
            eval_classifier_title={eval_training_set_title}
            eval_classifier_path_to_ui={eval_training_set_path_to_ui}
            eval_classifier_id={eval_training_set_id}

            report_title={report_title}
            is_saved={is_saved}
            is_incomplete={is_incomplete}
            created_by_user={created_by_user}
            user_state={user_state}
            user_settings={user_settings}
            rename_report_handler={() => set_show_rename_modal(true)}
            show_report_details_handler={() => set_show_details_modal(true)}

            portfolios={portfolios}
            portfolios_count_incl_rollups={portfolios_count_incl_rollups}
            techs={techs}
            geos={geos}
            timerange_field_id_to_default_extent={timerange_field_id_to_default_extent}
            continent_id_to_geo_ids={continent_id_to_geo_ids}
            selected_portfolio_ids={selected_portfolio_ids}
            selected_tech_ids={selected_tech_ids}
            selected_geo_ids={selected_geo_ids}
            selected_timerange={selected_timerange}
            set_selected_portfolio_ids={set_selected_portfolio_ids}
            set_selected_tech_ids={set_selected_tech_ids}
            set_selected_geo_ids={set_selected_geo_ids}
            set_selected_timerange={set_selected_timerange}
            spotlighted_portfolios={spotlighted_portfolios}
            spotlighted_techs={spotlighted_techs}
            set_spotlighted_portfolios={set_spotlighted_portfolios}
            set_spotlighted_techs={set_spotlighted_techs}
            fetch_initial_error={fetch_initial_error}
            report_has_scores={report_has_scores}
            on_back_to_report={back_to_report}
            navigate_to_exports={navigate_to_exports}
            navigate_to_families_view={navigate_to_families_view}
            navigate_to_selected={navigate_to_selected}
            enable_back_to_report_link={!is_grid_view}
            enable_all_families_link={!is_all_families_view}
            enable_exports_link={!is_export_view}
            enable_selected_link={!is_selected_view}
            show_ungrouped_families={show_ungrouped_families}
            toggle_show_ungrouped_families={change_report_should_show_ungrouped_families}
          />
        }
      </div>

      <ContainerFullWidth className={cn('w-100', s.report_content_block)}>
        {(is_initial_fetch || fetch_initial_error) &&
          <ReportInitialFetchDisplay
            show_banner_with_spinner={is_initial_fetch && !choreo_status}
            show_choreo_progress={is_initial_fetch && choreo_status}

            created_by_user={created_by_user}
            is_eval_report={is_eval_report}
            internal_report_id={internal_report_id}
            external_report_id={external_report_id}
            report_title={report_title}
            created_at={created_at}
            notify_when_complete={notify_when_complete}
            choreo_status={choreo_status}
            rename_report_handler={() => set_show_rename_modal(!show_rename_modal)}
            show_details_handler={() => set_show_details_modal(!show_details_modal)}

            fetch_initial_error={fetch_initial_error}

            className='pe-2'
          />
        }

        {!is_initial_fetch &&
          <>

            {!fetch_initial_error &&
              <WithHighlightPhrasesContextMenu
                disable_context_menu={!user_has_classifier_builder_access}
                phrases_to_highlight={phrases_to_highlight}
                on_change_phrases_to_highlight={on_change_phrases_to_highlight}
                className='position-relative w-100 h-100'
              >
                <RouteWithTracing
                  path={match.url}
                  exact
                  render={() => (
                    <DatasetGroupsView
                      groups={available_groups}
                      show_selected_only={is_selected_only}
                      on_selected_only_toggle={change_show_selected_only}
                      selected_dataset_tags={selected_dataset_tags || []}
                      on_filter_datasets_by_tags={filter_datasets_by_tags}
                      on_clear_selected_dataset_tags={clear_selected_dataset_tags}

                      show_all_datasets_refresh={global_filters_updated && show_charts_in_thumbnails}
                      enable_all_dataset_refresh={global_filters_updated}
                      all_datasets_refresh_handler={do_refresh_datasets}

                      {...report_state_props}

                      className='pe-2 h-100 overflow-auto'
                    />
                  )}
                />

                <RouteWithTracing
                  path={`${match.url}/${FAMILIES_SUBPATH}/${SUBSET_SUBPATH}/:subset_id`}
                  render={({match: inner_match}) => {
                    const {subset_id} = inner_match.params
                    return (
                      <ReportFamiliesView
                        base_path={inner_match.url}
                        subset_id={subset_id}
                        selections={selections}
                        minimal_selections={minimal_selections}
                        internal_report_id={internal_report_id}
                        report_title={report_title}
                        data_creation_date={data_creation_date}
                        report_input={report_input}
                        report_has_scores={report_has_scores}
                        ref_data={ref_data}
                        on_back_to_report={back_to_report}
                        user_settings={user_settings || {}}

                        // Eval classifier stuff
                        training_set={training_set_patfams}
                        id_to_tsfam={id_to_tsfam}
                        id_to_tsfam_pending={id_to_tsfam_pending}
                        id_to_tsfam_error={id_to_tsfam_error}
                        phrases_to_highlight={phrases_to_highlight}
                        no_highlighting={no_highlighting}
                        eval_training_set_id={eval_training_set_id}
                        set_label={set_label}
                        eval_classifier_data={eval_classifier_data}
                        // end of Eval classifier stuff

                        //family tagging
                        is_family_tagging_mode_on={is_family_tagging_mode_on}
                        family_tagging_search_phrase={family_tagging_search_phrase || ''}
                        on_toggle_family_tagging_mode={on_toggle_family_tagging_mode}
                        on_update_family_tagging_search_phrase={on_update_family_tagging_search_phrase}
                        //end of family tagging

                        className='pe-2'
                      />
                    )}}
                />

                <RouteWithTracing
                  path={`${match.url}/${EXPORT_SUBPATH}/:selected_export_id?/:params_id?`}
                  exact
                  render={({ match: inner_match }) => {
                    const {selected_export_id, params_id} = inner_match.params || {}

                    return (
                      <ExportsView
                        no_data={no_data}
                        is_incomplete={is_incomplete}
                        created_by_user={created_by_user}
                        is_set_theory_report={is_set_theory_report}
                        selected_export_id={selected_export_id}
                        params_id={params_id}
                        report_input={report_input}
                        {...report_state_props}

                        className='pe-2'
                      />
                   )}}
                />

                <RouteWithTracing
                  path={`${match.url}/${SELECTED_SUBPATH}`}
                  exact
                  render={() => {
                    return (
                      <SelectedView
                        user_settings={user_settings}
                        {...user_charts_props}
                        {...report_state_props}
                        selected_items={get_only_saved_items(selected_items)}
                        on_fetch_data_for_datasets={on_fetch_data_for_datasets}
                        is_bulk_download_enabled={!is_large_report(total_families_count)}
                        className='pe-2'
                      />
                    )
                  }}
                />

                <RouteWithTracing
                  path={`${match.url}/${GROUP_SUBPATH}/:group_id/${DATASET_SUBPATH}/:spec_id`}
                  render={({ match: inner_match }) => {

                    const {group_id, spec_id} = inner_match.params

                    const is_selected_group_view = (group_id === SELECTED_GROUP_ID)

                    if (is_selected_group_view && (user_state != null)) {
                      //the url points to the selected group but the dataset might not be in there so let's redirect to the assigned group

                      const selected_items_ids = selected_items.map(item => item.spec_id)

                      if (selected_items_ids.indexOf(spec_id) === -1) {
                        const canonical_group = SPEC_ID_TO_GROUP[spec_id]
                        const {id: canonical_group_id} = canonical_group || {}

                        return <Redirect to={get_dataset_path(canonical_group_id, spec_id)} />
                      }
                    }

                    return (
                      <DatasetView
                        zoomed_group_id={group_id}
                        zoomed_dataset_id={spec_id}
                        groups={available_groups}
                        is_group_collapsed={is_zoomed_group_collapsed}
                        toggle_group={() => set_is_zoomed_group_collapsed(!is_zoomed_group_collapsed)}
                        base_path={inner_match.url}
                        on_change_dataset_view={change_dataset_view}
                        on_change_dataset_region_grouping={change_dataset_region_grouping}
                        on_change_data_orientation={change_dataset_data_orientation}
                        on_change_data_include_all_zeros={change_data_include_all_zeros}
                        on_change_dataset_dispute_type={change_dataset_dispute_type}
                        on_change_next_agglom_item_visibility={change_dataset_next_agglom_item_visibility}
                        on_change_dataset_timerange={change_dataset_timerange}
                        on_change_dataset_histogram_range={change_dataset_histogram_range}
                        on_change_dataset_rollup_thresholds={change_dataset_rollup_thresholds}
                        on_change_dataset_portfolios_selection={change_dataset_portfolios_selection}
                        on_change_dataset_techs_selection={change_dataset_techs_selection}
                        on_change_dataset_geo_selection={change_dataset_geos_selection}
                        on_change_dataset_plaintiff_type_ids={change_dataset_plaintiff_type_ids}
                        on_change_dataset_defendant_type_ids={change_dataset_defendant_type_ids}
                        on_change_dataset_outcome_ids={change_dataset_outcome_ids}
                        on_change_dataset_start_date_range={change_dataset_start_date_range}
                        on_change_dataset_spotlighted_portfolios={change_dataset_spotlighted_portfolios}
                        on_change_dataset_spotlighted_techs={change_dataset_spotlighted_techs}
                        on_change_report_portfolios_selection={set_selected_portfolio_ids}
                        on_change_report_techs_selection={set_selected_tech_ids}
                        on_change_report_geo_selection={set_selected_geo_ids}
                        on_back_to_report={is_selected_group_view ? navigate_to_selected : back_to_report}

                        continent_id_to_geo_ids={continent_id_to_geo_ids}
                        timerange_field_id_to_default_extent={timerange_field_id_to_default_extent}
                        selected_timerange={selected_timerange}
                        show_selected_only={is_selected_only}
                        on_selected_only_toggle={change_show_selected_only}
                        selected_dataset_tags={selected_dataset_tags || []}
                        on_filter_datasets_by_tags={filter_datasets_by_tags}

                        // Eval classifier stuff
                        training_set={training_set_patfams}
                        id_to_tsfam={id_to_tsfam}
                        id_to_tsfam_pending={id_to_tsfam_pending}
                        id_to_tsfam_error={id_to_tsfam_error}
                        phrases_to_highlight={phrases_to_highlight}
                        no_highlighting={no_highlighting}
                        eval_training_set_id={eval_training_set_id}
                        set_label={set_label}
                        eval_classifier_data={eval_classifier_data}
                        // end of Eval classifier stuff

                        show_all_datasets_refresh={global_filters_updated && show_charts_in_thumbnails}
                        enable_all_dataset_refresh={global_filters_updated}
                        all_datasets_refresh_handler={do_refresh_datasets}

                        {...user_charts_props}
                        {...report_state_props}

                        is_bulk_download_enabled={!is_large_report(total_families_count)}
                      />
                    )
                  }}
                />

                <RouteWithTracing
                  path={`${match.url}/${FAMILIES_SUBPATH}`}
                  exact
                  render={({ match: inner_match }) => {
                    return <Redirect to={`${inner_match.url}/${SUBSET_SUBPATH}/${generate_subset_id()}`} />
                  }}
                />

                <RouteWithTracing
                  path={`${match.url}/:old_spec_id`}
                  render={({ match: inner_match }) => {
                    const new_spec_id = get_new_spec_id(inner_match.params.old_spec_id)
                    const group = SPEC_ID_TO_GROUP[new_spec_id]
                    const {id: group_id} = group || {}
                    if (new_spec_id !== '' && group_id) {
                      return <Redirect to={get_dataset_path(group_id, new_spec_id)} />
                    }
                  }}
                />
              </WithHighlightPhrasesContextMenu>
            }
          </>
        }
      </ContainerFullWidth>

      {is_clear_all_selected_modal_open &&
        <Modal
          is_open={true}
          on_hide={() => set_is_clear_all_selected_modal_open(false)}
          primary_button={
            <PrimaryButton onClick={() => {
              on_clear_selected_datasets()
              set_is_clear_all_selected_modal_open(false)
            }}>
              Confirm
            </PrimaryButton>
          }
        >
          Are you sure you want to clear the current selection?
        </Modal>
      }

      {is_reset_report_modal_open &&
        <Modal
          is_open={true}
          on_hide={() => set_is_reset_report_modal_open(false)}
          primary_button={
            <PrimaryButton onClick={() => {
              reset_report()
              set_is_reset_report_modal_open(false)
            }}>
              Confirm
            </PrimaryButton>
          }
          size='sm'
        >
          Are you sure you want to restore report to default settings?
        </Modal>
      }

      {show_rename_modal &&
        <RenameReportModal
          on_close={() => set_show_rename_modal(false)}
          report_name={report_title}
          rename_report_handler={rename_report}
        />
      }

      <ReportDetailsModal
        is_open={show_details_modal}
        on_close={() => set_show_details_modal(false)}
        report_input={report_input}

        user_state={user_state}
        user_settings={user_settings}

        external_report_id={external_report_id}
        internal_report_id={internal_report_id}
        report_title={report_title}
        created_at={created_at}
        is_saved={is_saved}
        is_eval_report={is_eval_report}
        is_incomplete={is_incomplete}
        families_count={families_count}
      />

      {is_user_settings_modal_open &&
        <UserSettingsModal
          on_hide={toggle_user_settings_modal}
          user_settings={user_settings}
          user_chart_sets={user_chart_sets}
          change_region_grouping_handler={change_region_grouping}
          change_thumbnails_handler={change_thumbnails}
          change_should_show_ungrouped_families_handler={change_should_show_ungrouped_families}
          change_starting_page_handler={change_starting_page}
          change_default_chart_selection_handler={change_default_chart_selection}
          change_default_utt_version_handler={change_default_utt_version}
          change_should_include_unrelated_techs_handler={change_should_include_unrelated_techs}
          toggle_notify_before_report_expiry_handler={toggle_notify_before_report_expiry}
          change_language_preference_handler={change_language_preference}
          change_save_knn_searches_handler={change_save_knn_searches}
          reset_settings_handler={reset_user_settings}
        />
      }

      {show_results_truncated_warning &&
        <Modal
          is_open={true}
          on_hide={() => set_show_results_truncated_warning(false)}
        >
          <IncompleteReportWarning />
        </Modal>
      }

      {error_updating_user_state &&
        <ErrorModal
          on_hide={() => set_error_updating_user_state(null)}
          error={error_updating_user_state}
          context={'updating user state in db'}
        />
      }

      {error_rerunning_eval_report &&
        <ErrorModal
          on_hide={() => set_error_rerunning_eval_report(null)}
          error={error_rerunning_eval_report}
          context={'re-building classifier and re-running evaluation report'}
        />
      }

      {is_rerunning_eval_report &&
        <BlockingNotificationModal
          title={'Re-run report'}
        >
          <div className={cn('d-flex', 'align-items-center')}>
            <Spinner className={cn('me-2')}/>
            Rebuilding classifier (this may take a few minutes)
          </div>
        </BlockingNotificationModal>
      }

      {error_updating_user_settings &&
        <ErrorModal
          on_hide={() => set_error_updating_user_settings(null)}
          error={error_updating_user_settings}
          context={'updating user settings'}
        />
      }

    </>
  )
}

export default withUser(withRouter(Viewer))