import React from 'react'
import cn from 'classnames'

import Spinner from '../../widgets/Spinner.js'
import InlineError from '../../widgets/InlineError.js'

import s from './ClassifierStatusMessage.module.scss'

const ClassifierStatusMessage = ({
  className,
  is_saving,
  is_error,
  short
}) => {

  return (
    
    <div
      className={cn('d-flex', 'align-items-center', s.block, className)}
    >
      {is_saving &&
        <Spinner
          size={'sm'}
          className={cn('me-2')}
        />
      }

      {is_error &&
        <div className={cn('d-flex', 'align-items-center')}>
          <InlineError
            context={'saving label'}
          />
          {!short &&
            <span className={cn(s.error_saving_message, 'ms-1')}>failed</span>
          }
        </div>
      }
    </div>
  )
}

export default ClassifierStatusMessage