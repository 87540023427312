import React from 'react'
import cn from 'classnames'

import s from './DefaultPageContainer.module.scss'

const DefaultPageContainer = ({ dark, children, className }) => {
  return (
    <div className={cn(s.page_container, {[s.page_dark_bg]: dark}, className)}>
      {children}
    </div>
  )
}

export default DefaultPageContainer