import React from 'react'

import { Heading } from './FamilyDetailsLabel.js'

import { Highlighter } from '../widgets/Highlighter.js'

export const FamilyAbstract = (
  {
    family,
    no_highlighting,
    highlight_prefix_only,
    search_words,
    search_colours,

    className
  }) => {
  const { abstract, patFamId } = family

  return (
    <div key={`abstract_${patFamId}`} className={className}>
      <Heading>Family abstract</Heading>
      <div>
        {abstract != null &&
          <Highlighter
            no_highlighting={no_highlighting}
            highlight_prefix_only={highlight_prefix_only}
            search_words={search_words}
            search_colours={search_colours}
            text_to_highlight={abstract}
          />
        }
        {abstract == null &&
          '(none)'
        }
      </div>
    </div>
  )
}