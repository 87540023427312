import React from "react"
import cn from 'classnames'

import { FamilyTagLink } from './FamilyTagLink'
import { Tag } from './family_tag_utils'

import s from './FamilyTagsSection.module.scss'

interface FamilyTagSetProps {
    title: string,
    tags: Array<Tag>,
    on_tag_select_handler: Function
}

export const FamilyTagsSection = ({title, tags, on_tag_select_handler}: FamilyTagSetProps) => {

  function render(): JSX.Element {
    return (
      <div className={cn('mt-2 pb-1', s.section_wrapper)}>
        <h4>{title}</h4>
        {tags.map((tag: Tag, index) => {
          return (
          <div className='mb-2' key={index}>
            <FamilyTagLink
              className={''}
              tag={tag}
              on_click_handler={on_tag_select_handler}
            />
          </div>
        )})}
      </div>
    )}
  return render()
}
