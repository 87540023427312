import React, { useState } from 'react'

import { PrimaryButton } from './widgets/Button.js'
import Modal from './widgets/Modal.js'

import {
  normalise_name,
  remove_not_allowed_chars_from_text
} from '../utils/name_utils.js'
import NameInput from './NameInput.js'

const RenameModal = ({modal_title, heading, name, invalid_name_message, rename_handler, is_name_valid_handler, on_close, inputClassName}) => {

  const [new_name, set_new_name] = useState(name)

  function on_change_name_input(value) {
    set_new_name(remove_not_allowed_chars_from_text(value))
  }

  function save_name() {
    const normalised_name = normalise_name(new_name)

    rename_handler(normalised_name)
    on_close()
  }

  function on_key_up(e) {
    switch (e.keyCode) {
      case 13:
        e.stopPropagation()
        e.preventDefault()
        if (can_update()) {
          save_name()
        }
        break
      case 27:
        e.preventDefault()
        on_close()
        break
      default:
        return
    }
  }

  function can_update() {
    return is_name_valid_handler(new_name) && new_name !== name
  }

  function get_primary_button() {
    return (
      <PrimaryButton
        onClick={() => save_name()}
        disabled={!can_update()}
      >
        Save
      </PrimaryButton>
    )
  }

  return (
    <Modal
      on_hide={on_close}
      primary_button={get_primary_button()}
      title={modal_title || 'New name'}
      close_label='Cancel'
      size='sm'
    >
      <NameInput
        name={new_name}
        heading={heading || 'Name'}
        invalid_name_message={invalid_name_message}
        on_change={on_change_name_input}
        on_key_up={on_key_up}
        is_name_valid_handler={is_name_valid_handler}
        className={inputClassName}
      />
    </Modal>
  )

}

export default RenameModal