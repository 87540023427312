import React, { useState } from 'react'
import _ from 'underscore'
import { DropdownItem } from 'reactstrap'

import { CN, ID_TO_CONTINENT, IP5_COUNTRIES, UPC_COUNTRIES, US } from '../../constants/countries.js'
import CheckboxGroupHeader from './CheckboxGroupHeader.js'
import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'
import { track_report_viewer_event } from '../../utils/tracking_utils.js'
import GeoCheckboxTree from './GeoCheckboxTree.js'
import ScrollableList from '../widgets/ScrollableList.js'
import TextLink from '../widgets/TextLink.js'
import { has_us_centric_territories } from '../../utils/user_permissions.js'
import { withUser } from '../UserContext.js'

import cs from '../cipher_styles.module.scss'
import s from './TerritorySelector.module.scss'

function is_fully_covered(selected_ids, available_ids) {
  return available_ids.every(id => _.contains(selected_ids, id))
}

const TerritorySelector = (
  {
    user,
    selector_id,
    geos,
    selected_geo_ids,
    continent_id_to_geo_ids,
    set_selected_geo_ids,
    show_ungrouped_families,
    toggle_show_ungrouped_families,
    report_has_ungrouped_families
  }) => {

  const all_geo_ids = (geos || []).map(geo => geo.id)

  const [search_phrase, set_search_phrase] = useState(null)

  function handle_change_search_phrase(search_phrase) {
    track_report_viewer_event(`obj="dropdown_items" dropdown="${selector_id}" action="search"`, true)
    set_search_phrase( search_phrase)
  }

  function select_all() {
    const new_selected_geo_ids = geos.map(geo => geo.id)
    set_selected_geo_ids(new_selected_geo_ids)
  }

  function deselect_all() {
    set_selected_geo_ids([])
  }

  function on_toggle_selected_geo_id(geo_id) {
    const is_selected = _.contains(selected_geo_ids, geo_id)

    track_report_viewer_event(`obj="dropdown_items" dropdown="${selector_id}" action="${is_selected ? 'deselect' : 'select'}" geo="${geo_id}"`)

    const new_selected_geo_ids = is_selected ?
          selected_geo_ids.filter(id => id !== geo_id) // remove
          : [...selected_geo_ids, geo_id]              // add

    set_selected_geo_ids(new_selected_geo_ids)
  }


  function on_toggle_select_all_continent_id(continent_id) {
    const continent_geo_ids = continent_id_to_geo_ids[continent_id]

    const fully_covered = is_fully_covered(selected_geo_ids, continent_geo_ids)

    track_report_viewer_event(`obj="dropdown_items" dropdown="${selector_id}" action="${fully_covered ? 'deselect' : 'select'}" continent="${continent_id}"`)

    const new_selected_geo_ids =  fully_covered ?
          selected_geo_ids.filter(id => !_.contains(continent_geo_ids, id)) // remove continent geos
          : [...selected_geo_ids, ...continent_geo_ids]                     // add continent geos

    set_selected_geo_ids(_.unique(new_selected_geo_ids))
  }


  function update_looked_up_items_selection(selected_geo_ids) {
    track_report_viewer_event(`obj="dropdown_items" dropdown="${selector_id}" action="${selected_geo_ids.length > 0 ? 'select' : "deselect"}" option="found"`)
    set_selected_geo_ids(selected_geo_ids)
  }

  function select_all_except_china() {
    return update_looked_up_items_selection(all_geo_ids.filter(geo => geo !== CN))
  }

  function select_ip5() {
    return update_looked_up_items_selection(all_geo_ids.filter(geo => IP5_COUNTRIES.indexOf(geo) > -1))
  }

  function select_upc() {
    return update_looked_up_items_selection(all_geo_ids.filter(geo => UPC_COUNTRIES.indexOf(geo) > -1))
  }

  if (!geos) {
    return null
  }

  const num_selectable_items = geos.length
  const search_phrase_lower_case = (search_phrase || '').toLowerCase()

  const continent_ids = _.keys(continent_id_to_geo_ids)
  continent_ids.sort((id_a, id_b) => {
    return ID_TO_CONTINENT[id_a].name >= ID_TO_CONTINENT[id_b].name ? 1 : -1 // sort alphabetically (in place)
  })

  // filter geos by search_phrase
  const filtered_geos = !search_phrase ? geos : geos.filter(geo => {
    return geo.name.toLowerCase().indexOf(search_phrase_lower_case) !== -1
      || geo.country_code.toLowerCase().indexOf(search_phrase_lower_case) !== -1
  })
  const is_filtered = (geos && filtered_geos) ? geos.length !== filtered_geos.length : false
  const num_filtered_results = filtered_geos ? filtered_geos.length : 0


  const has_US = all_geo_ids.indexOf(US) > -1
  const has_CN = all_geo_ids.indexOf(CN) > -1
  const has_IP5 = _.intersection(all_geo_ids, IP5_COUNTRIES).length > 0
  const has_UPC = _.intersection(all_geo_ids, UPC_COUNTRIES).length > 0

  const should_display_shortcuts = has_IP5 || has_US || has_UPC

  return (
    <div className='pb-2'>
      <CheckboxGroupHeader
        no_scroll={true}
        id_key={'country_code'}
        page_items={filtered_geos}
        filtered_items={filtered_geos}
        all_items={geos}
        selected_item_ids={selected_geo_ids}
        is_filtered={is_filtered}
        num_filtered_results={num_filtered_results}
        num_selectable_items={num_selectable_items}
        select_all={select_all}
        deselect_all={deselect_all}
        search_phrase={search_phrase || ''}
        set_selected_item_ids={update_looked_up_items_selection}
        handle_change_search_phrase={handle_change_search_phrase}
        no_options_dropdown={!report_has_ungrouped_families}
        extra_dropdown_option={(
          <DropdownItem
            tag='div'
            toggle={false}
          >
            <CheckboxAndLabel
              label='Include ungrouped families'
              is_checked={show_ungrouped_families}
              on_click={toggle_show_ungrouped_families}
            />
          </DropdownItem>
        )}
      />

      <ScrollableList>
        <GeoCheckboxTree
          continent_id_to_geo_ids={continent_id_to_geo_ids}
          selected_geo_ids={selected_geo_ids}
          on_toggle_select_all_continent_id={(continent_id) => on_toggle_select_all_continent_id(continent_id)}
          on_toggle_selected_geo_id={(geo_id) => on_toggle_selected_geo_id(geo_id)}
          search_phrase={search_phrase || ''}
        />
      </ScrollableList>

      {should_display_shortcuts &&
        <div className='mt-2'>
          <span className={cs.font_size_small}>Use the shortcuts below to filter list of territories:</span>
          <div className='d-flex mt-2'>
            {has_IP5 &&
              <TextLink onClick={select_ip5} title='Select IP5 countries'>IP5</TextLink>
            }

            {has_US &&
              <TextLink className={s.select_shortcut} onClick={() => update_looked_up_items_selection([US])} title='Select US only'>US only</TextLink>
            }

            {has_CN &&
              <TextLink className={s.select_shortcut} onClick={() => update_looked_up_items_selection([CN])} title='Select US only'>CN only</TextLink>
            }

            {has_UPC &&
              <TextLink className={s.select_shortcut} onClick={select_upc} title='Select UPC countries'>UPC</TextLink>
            }

            {has_CN && has_us_centric_territories(user) &&
              <TextLink className={s.select_shortcut} onClick={select_all_except_china} title='Select all except China'>all except China</TextLink>
            }
          </div>
        </div>
      }
    </div>
  )
}

export default withUser(TerritorySelector)