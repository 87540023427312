import React, { useEffect } from 'react'
import _ from 'underscore'
import cn from 'classnames'

import { get_as_map, get_domain_prefix, startsWith } from '../../utils/utils.js'
import { useToggle } from '../../hooks/general_hooks.js'
import { Heading } from './FamilyDetailsLabel.js'
import TextLink from '../widgets/TextLink.js'
import Spinner from '../widgets/Spinner.js'
import { do_common_parse } from '../../utils/family_view_utils.js'
import { scroll_to_top } from '../../utils/scroll_utils.js'
import { Highlighter } from '../widgets/Highlighter.js'
import { convert_ifi_image_pkey_to_ucid, get_ifi_image_url } from '../../utils/patent_utils.js'
import { ok_to_proceed_with_shortcuts } from '../../utils/keyboard_shortcuts/keyboard_utils.js'
import { NavigationAnchor } from './FamilyDetailsNavigation.js'

import s from './PublicationXMLPartView.module.scss'

const PublicationXMLPartView = (
  {
    show_spinner,
    context,
    content,
    images,
    block_ref,
    toggle_view_key,

    no_highlighting,
    highlight_prefix_only,
    search_phrases,
    search_colours,

    error,
  }) => {

  const images_by_filename = (images) ? get_as_map(images, 'filename') : null

  const [show_more, toggle_show_more] = useToggle(false)

  useEffect(() => {
    document.addEventListener('keyup', on_keyup)

    return () => {
      document.removeEventListener('keyup', on_keyup)
    }
  })

  function get_heading() {
    return context === 'abstract' ? 'Abstract' : 'Description'
  }

  function on_keyup(event) {
    const { keyCode } = event

    const is_ok_to_proceed_with_shortcut = ok_to_proceed_with_shortcuts(event, true /* allow shortcuts to work in modal */)
    if (!is_ok_to_proceed_with_shortcut) {
      return
    }

    if (keyCode === toggle_view_key) {
      toggle_show_more()
      return
    }

    return
  }

  function hide_content() {
    scroll_to_top(block_ref)
    toggle_show_more()
  }

  function format_content_item(item) {
    const {elements=[]} = item

    if (!item || !elements || elements.length === 0) return ''

    const content = elements.map(item => {
      if (item.name === 'img') {
        const { file } = item.attributes || {}
        const image = (images_by_filename) ? images_by_filename[file] : null

        if (!image) return ''

        const { pkey, media, filename } = image
        const ucid = convert_ifi_image_pkey_to_ucid(pkey)
        if (image && media && startsWith(media, 'image')) {
          const url = get_domain_prefix() + get_ifi_image_url(filename, ucid)
          return `<img src=${url} alt=${file}/>`
        }
        else {
          return ''
        }
      }

      if (item.elements) {
        const is_p = item.name === 'p'
        return `${is_p? '<p>': ''}${format_content_item(item)}${is_p? '</p>': ''}`
      }
      return do_common_parse(item)
    })

    return content.join('')
  }

  function get_content_to_display() {
    const content_to_display = content.map((item,i) => {

      const formatted_item = format_content_item(item)

      if (!formatted_item || formatted_item === '') return null

      return (
        <div className='mb-3' key={i}>
          <Highlighter
            no_highlighting={no_highlighting}
            highlight_prefix_only={highlight_prefix_only}
            search_words={search_phrases}
            search_colours={search_colours}
            text_to_highlight={formatted_item}
            input_is_raw_html={true}
          />
        </div>
      )
    })

    if (_.every(content_to_display, (item) => item == null)) return null

    return content_to_display
  }

  const has_content = (content != null) && (content.length > 0)

  const content_to_display = has_content ? get_content_to_display() : null

  return (

    <div className={cn('position-relative mb-3', s.block)}>
      <NavigationAnchor ref={block_ref} />
      <div className='d-flex'>
        <Heading>{get_heading()}</Heading>
        {has_content && content_to_display && <TextLink className='ms-2 fs-unmask' onClick={toggle_show_more}>{show_more ? 'hide' : 'show'}</TextLink>}
      </div>

      {show_more && !show_spinner && has_content &&
        <div className={s.description_wrapper__visible}>
          <div>{content_to_display}</div>
          <TextLink onClick={hide_content}>hide {context}</TextLink>
        </div>
      }

      {show_spinner &&
        <Spinner/>
      }

      {!show_spinner && (!has_content || !content_to_display) &&
        <div>(none)</div>
      }

      {error &&
        <div>There was an error fetching {context}</div>
      }
    </div>
  )
}

export default PublicationXMLPartView