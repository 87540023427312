import React from 'react'
import { withRouter, matchPath } from 'react-router'
import cn from 'classnames'
import { RouteWithTracing } from '../RouteWithTracing'

import { ChevronRightIcon } from '../widgets/IconSet.js'
import { InfoPopover } from '../widgets/Tooltip.js'
import SpecHelpLink from './SpecHelpLink.js'
import ItemHeaderBackButton from './ItemHeaderBackButton.js'
import {
  SPEC_GROUP_TO_BORDER_CLASSNAME,
  SPEC_GROUP_TO_CLASSNAME,
  SPEC_GROUP_TO_MARKER_CLASSNAME, SPEC_GROUP_TO_THUMBNAIL_CLASSNAME
} from './dataset_groups_styles.js'

import s from './ItemHeader.module.scss'

const ItemHeader = (
  {
    className,
    children,
    history,

    on_close,

    group_id,
    group_name,
    spec_name,
    short_spec_name,
    item,
    level_1_path,

    level_2_label,
    level_2_path,

    level_3_label,
    level_3_path,

    refresh,

    return_btn_ref
}) => {
  const path = window.location.pathname

  const level_1_exact_match = level_1_path == null ? null : matchPath(path, { path: level_1_path, exact: true })

  const { description, get_description, help_article } = item || {}
  const description_to_display = get_description ? get_description({item}) : description

  return (
    <div className={cn('px-2 d-flex align-items-center flex-nowrap justify-content-between', SPEC_GROUP_TO_CLASSNAME[group_id], s.header, className)}>
      <ItemHeaderBackButton
        return_btn_ref={return_btn_ref}
        on_refresh={refresh}
        on_close={on_close}
        level_1_path={level_1_path}
        level_2_path={level_2_path}
        level_3_path={level_3_path}

        className={cn(s.back_btn, SPEC_GROUP_TO_THUMBNAIL_CLASSNAME[group_id])}
      />

      <span className={cn('ms-2 d-flex align-items-center order-1 order-lg-2 w-100 flex-nowrap', s.level_items)}>
        {/* Level 1 */}
        {level_1_path &&
          <RouteWithTracing
            path={level_1_path}
            render={({ match }) => {
              return (
                <>
                  <a
                    className={cn(
                      'd-flex',
                      s.breadcrumb_link,
                      { [s.breadcrumb_link__selected]: match.isExact }
                    )}
                    title={`${group_name ? `${group_name}: `: ''}${spec_name}`}
                    href={level_1_path}
                    onClick={(event) => {
                      event.preventDefault()
                      history.push(level_1_path)
                    }}
                  >
                    {group_name &&
                      <div className='d-flex me-1'><div className={cn('me-1', s.marker, SPEC_GROUP_TO_MARKER_CLASSNAME[group_id])}/><div className={s.label__group}>{group_name}</div></div>
                    }
                    <span
                      className={cn(s.label__limit_width)}
                    >
                      {level_1_exact_match && <span>{spec_name}</span>}
                      {!level_1_exact_match && <span>{short_spec_name}</span>}
                    </span>
                  </a>
                  {match.isExact && description_to_display &&
                    <InfoPopover
                     interactive={true}
                     buttonClassName='ms-1'
                     arrow={false}
                     popupClassName={cn(SPEC_GROUP_TO_BORDER_CLASSNAME[group_id], s.info_popup)}
                    >
                      <div className={s.description}>
                        <span>{description_to_display}</span>
                        <SpecHelpLink help_article={help_article} className='ms-1'/>
                      </div>
                    </InfoPopover>
                  }
                </>
              )
            }}
          />
        }

        {/* Level 2 */}
        {level_2_path &&
          <RouteWithTracing
            path={level_2_path}
            render={({ match }) => (
              <span>
                <span className='ms-1'>
                  <ChevronRightIcon />
                </span>
                <a
                  className={cn(
                    'ms-1',
                    'd-inline-flex',
                    s.breadcrumb_link,
                    {
                      [s.breadcrumb_link__selected]: match.isExact
                    }
                  )}
                  href={level_2_path}
                  title={level_2_label}
                  onClick={(event) => {
                    event.preventDefault()
                    if (match.isExact) {
                      return
                    }
                    history.push(level_2_path)
                  }}
                >
                  <span
                    className={cn(s.label__limit_width)}
                  >
                    <span>{level_2_label}</span>
                  </span>
                </a>
              </span>
            )}
          />
        }

        {/* Level 3 */}
        {level_3_path &&
          <RouteWithTracing
            path={level_3_path}
            render={({ match }) => (
              <span>
                <span className='ms-1'>
                  <ChevronRightIcon />
                </span>
                <a
                  className={cn(
                    'ms-1',
                    s.breadcrumb_link,
                    { [s.breadcrumb_link__selected]: match.isExact }
                  )}
                  href={level_3_path}
                  title={level_3_label}
                  onClick={(event) => {
                    event.preventDefault()
                    if (match.isExact) {
                      return
                    }
                    history.push(level_3_path)
                  }}
                >
                  <span>{level_3_label}</span>
                </a>
              </span>
            )}
          />
        }

      </span>

      {/* Extra stuff */}

      <div className='my-auto order-3'>
        {children}
      </div>

    </div>
  )
}

export default withRouter(ItemHeader)