import React from 'react'
import ClearableSearchInput from '../widgets/ClearableSearchInput.js'

const OrgSearchInput = ({ placeholder, on_change_handler, on_refresh_handler, value, autofocus, show_spinner, show_refresh, inputClassName }) => {
  const should_autofocus = (autofocus != null) ? autofocus : false

  function on_key_down(e) {
    if (e.which === 13 && (value !== '') && on_refresh_handler) {
      on_refresh_handler()
    }
  }

  return (
    <ClearableSearchInput
      placeholder={(placeholder != null) ? placeholder : 'Enter an organisation'}
      handle_change={on_change_handler}
      handle_refresh={on_refresh_handler}
      value={value || ''}
      handle_key_down={on_key_down}
      auto_focus={should_autofocus}
      containerClassName='flex-grow-1 my-auto'
      show_spinner={show_spinner}
      show_clear={!show_spinner}
      show_refresh={show_refresh && !show_spinner}
      inputClassName={inputClassName}
    />
  )
}

export default OrgSearchInput
