import React from 'react'
import { DropdownItem } from 'reactstrap'

import { get_dispute_type_by_id } from '../../model/user_state.js'
import BaseDropdown from '../widgets/BaseDropdown.js'

import s from './DisputeTypeFilterControl.module.scss'

const DisputeTypeFilterControl = ({spec, available_dispute_types, selected_dispute_type, on_change_selected_dispute_type, className}) => {

  const {filter_by_dispute_type} = spec

  if (!filter_by_dispute_type) {
    return null
  }

  const selected_region_grouping_label = get_dispute_type_by_id(selected_dispute_type).label

  return (
    <BaseDropdown
      className={className}
      buttonClassName={s.dropdown_toggle}
      labelClassName={s.dropdown_toggle_label}
      label={selected_region_grouping_label}
      menuClassName={s.dropdown_menu}
    >
      {available_dispute_types.map((item, i) => (
        <DropdownItem
          className={'d-flex'}
          key={i}
          onClick={() => on_change_selected_dispute_type(item.id)}
          active={item.id === selected_dispute_type}
        >
          {item.label}
        </DropdownItem>
      ))}
    </BaseDropdown>
  )
}

export default DisputeTypeFilterControl