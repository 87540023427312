import React, { useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import _ from 'underscore'

import Spinner from '../widgets/Spinner.js'
import {
  get_classifier_for_report_input,
  get_group_classifiers_ids,
  get_group_indirectly_selected_classifiers,
  get_group_selected_classifiers,
  get_group_heading, get_classifier_groups_to_ids,
} from '../../utils/classifier_group_utils.js'
import Modal from '../widgets/Modal.js'
import ClassifierGroupsSelector from './wizard/ClassifierGroupsSelector.js'
import ClassifierGroupDisplay from './wizard/ClassifierGroupDisplay.js'
import {
  get_all_available_taxonomy_ids,
  get_classifiers_group_names,
  is_utt_among_classifiers
} from './wizard/builder_wizard_utils.js'
import { UTT_GROUP_ID } from '../../constants/utt.js'

import s from './TechnologiesDisplay.module.scss'

const TechnologiesDisplay = (
  {
    is_fetching,
    classifier_groups,
    utt_group,

    selected_classifier_group_id,
    selected_classifiers,
    classifiers_landscape_limit,
    utt_landscape_limit,

    on_select_classifier_group,
    on_select_classifiers,

    is_subscription_display
  }) => {

  const classifier_groups_ids = get_all_available_taxonomy_ids(classifier_groups)
  const all_available_group_ids = useMemo(() => (utt_group ? [UTT_GROUP_ID] : classifier_groups_ids) , [utt_group, classifier_groups_ids])

  const is_single_group_view = all_available_group_ids.length === 1

  const taxonomies_in_basket = get_classifiers_group_names(selected_classifiers || [])

  const [show_limit_alert, set_show_limit_alert] = useState(false)

  useEffect(() => {
    if (selected_classifier_group_id == null) {
      on_select_classifier_group(all_available_group_ids[0])
    }
  }, [all_available_group_ids, selected_classifier_group_id, on_select_classifier_group])

  function on_group_select(classifier_group_id) {
    on_select_classifier_group(classifier_group_id === selected_classifier_group_id ? null : classifier_group_id)
  }

  function get_classifier_group_by_id(classifier_group_id) {
    return classifier_group_id === UTT_GROUP_ID ? utt_group : classifier_groups_by_ids[classifier_group_id]
  }

  function get_new_classifiers_selection(classifiers_group_selection) {
    const selected_classifier_ids = (selected_classifiers || []).map(s => s.classifier_id)
    return classifiers_group_selection.filter(item => selected_classifier_ids.indexOf(item.classifier_id) === -1).map((classifier) => get_classifier_for_report_input({...classifier, classifier_group_id: selected_classifier_group_id}))
  }

  function update_classifiers_selection(classifiers_group_selection) {
    const group_classifiers_ids = get_group_classifiers_ids(get_classifier_group_by_id(selected_classifier_group_id))

    const unselected_group_classifiers_ids = _.difference(group_classifiers_ids, classifiers_group_selection.map(item => item.classifier_id))
    const selected_classifiers_filtered = (selected_classifiers || []).filter(item => unselected_group_classifiers_ids.indexOf(item.classifier_id) === -1)

    const __classifier_ids = []

    const updated_selected_classifiers = [...selected_classifiers_filtered, ...get_new_classifiers_selection(classifiers_group_selection)].reduce((acc, item) => {
      //remove duplicates, preserve order

      const {classifier_id} = item || {}

      if (__classifier_ids.indexOf(classifier_id) === -1) {
        acc.push(item)
        __classifier_ids.push(classifier_id)
      }
      return acc
    }, [])

    on_select_classifiers(classifiers_landscape_limit ? updated_selected_classifiers.slice(0, classifiers_landscape_limit) : updated_selected_classifiers)
  }

  function update_utt_classes_selection(classifiers_group_selection) {
    //only one class allowed
    const selected = get_new_classifiers_selection(classifiers_group_selection)
    on_select_classifiers(selected)
  }

  function get_selected_classifiers_by_group_id(classifier_group_id) {
    return get_group_selected_classifiers(get_classifier_group_by_id(classifier_group_id), selected_classifiers)
  }

  function get_indirectly_selected_classifiers_by_group_id(classifier_group_id) {
    return get_group_indirectly_selected_classifiers(get_classifier_group_by_id(classifier_group_id), selected_classifiers)
  }

  function check_if_selection_permitted(selected_classifiers) {
    if (is_utt_group_selected) {
      return true
    }

    return classifiers_landscape_limit ? (selected_classifiers || []).length < classifiers_landscape_limit : true
  }

  const classifier_groups_by_ids = get_classifier_groups_to_ids(classifier_groups)

  const classifier_group_id = is_single_group_view ? all_available_group_ids[0] : selected_classifier_group_id
  const is_group_selected = classifier_group_id != null
  const is_utt_group_selected = classifier_group_id === UTT_GROUP_ID

  const classifier_groups__clean = (classifier_groups || []).filter(group => group && group.children && group.children.length > 0)

  const is_selection_permitted = check_if_selection_permitted(selected_classifiers)

  return (
    <div className={cn('w-100 mt-1 pb-3', s.block)}>
      {is_fetching &&
        <div>
          <Spinner/>
        </div>
      }

      {!is_fetching && (classifiers_landscape_limit != null) &&
        <div>Select up to {classifiers_landscape_limit} classifiers.</div>
      }

      {!is_fetching && (utt_landscape_limit != null) &&
        <div>Select one UTT technology.</div>
      }

      {!is_fetching && (all_available_group_ids.length > 0) &&
        <div className='d-flex mt-3'>
          {!is_single_group_view &&
            <div className={cn('py-2 pe-4', s.groups_wrapper)}>
              {classifier_groups__clean.map((classifier_group, i) => {
                const { name, id } = classifier_group || {}

                const is_selected = (id === selected_classifier_group_id)

                const is_in_basket = taxonomies_in_basket.indexOf(name) !== -1

                const title = get_group_heading(classifier_group)

                return (
                  <ClassifierGroupsSelector
                    key={i}
                    name={title}
                    is_selected={is_selected}
                    is_in_basket={is_in_basket}
                    on_select_handler={() => on_group_select(id)}
                    is_anything_selected={is_group_selected}

                    is_disabled={is_utt_among_classifiers(selected_classifiers || [])}
                  />
                )
              })}

              {utt_group &&
                <ClassifierGroupsSelector
                  name={utt_group.name}
                  is_selected={is_utt_group_selected}
                  is_in_basket={is_utt_among_classifiers(selected_classifiers || [])}
                  on_select_handler={() => on_group_select(UTT_GROUP_ID)}
                  is_anything_selected={is_group_selected}
                  is_disabled={(selected_classifiers || []).length > 0 && !is_utt_among_classifiers(selected_classifiers || [])}
                />
              }
            </div>
          }

          {is_group_selected &&
            <ClassifierGroupDisplay
              key={classifier_group_id}
              classifiers_group={is_utt_group_selected ? utt_group : classifier_groups_by_ids[classifier_group_id]}
              selected_classifiers={get_selected_classifiers_by_group_id(classifier_group_id)}
              selected_indirectly={get_indirectly_selected_classifiers_by_group_id(classifier_group_id)}
              update_selection_handler={is_utt_group_selected && utt_landscape_limit? update_utt_classes_selection : update_classifiers_selection}
              prohibit_parent_level_selection={is_subscription_display || (is_utt_group_selected && utt_landscape_limit)}
              is_selection_permitted={is_selection_permitted}
              unpermitted_selection_handler={() => set_show_limit_alert(true)}
              is_single_choice={is_utt_group_selected && utt_landscape_limit}
            />
          }
        </div>
      }

      {show_limit_alert &&
        <Modal on_hide={() => set_show_limit_alert(false)} title={''}>
          Sorry, you have already reached the maximum allowed number for selected classifiers.
        </Modal>
      }
    </div>
  )
}

export default TechnologiesDisplay