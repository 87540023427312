import React from 'react'
import cn from 'classnames'

import { format_integer_with_comma } from '../../utils/utils.js'
import { ChevronLeftIcon, ChevronRightIcon } from './IconSet.js'
import { TertiaryButton } from './Button.js'

import s from './ForwardBackSelector.module.scss'

const ForwardBackSelector = ({
  className,
  buttonClassName,
  no_label,
  label,
  current_idx,
  min_idx_incl, // inclusive
  max_idx_excl, // exclusive
  on_next,
  on_prev
}) => {
  return (
    <span
      className={cn('d-flex', 'align-items-center', className)}
    >
      <TertiaryButton
        className={buttonClassName}
        disabled={current_idx <= min_idx_incl}
        onClick={() => on_prev()}
        icon_only={true}
      >
        <ChevronLeftIcon className={cn(s.icon)}/>
      </TertiaryButton>

      {!no_label &&
        <div className={cn('ms-1 d-flex flex-nowrap', s.label_text)} key={current_idx}>
          <div>{label || <span>Page</span>}&nbsp;<span>{current_idx + 1}</span></div>
          {max_idx_excl > 0 &&
            <div>
              &nbsp;of&nbsp;
              <span>{format_integer_with_comma(max_idx_excl)}</span>
            </div>
          }
        </div>
      }

      <TertiaryButton
        className={cn('ms-1', buttonClassName)}
        disabled={current_idx >= (max_idx_excl - 1)}
        onClick={() => on_next()}
        icon_only={true}
      >
        <ChevronRightIcon className={cn(s.icon)}/>
      </TertiaryButton>
    </span>
  )
}

export default ForwardBackSelector