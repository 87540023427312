import React from 'react'
import cn from 'classnames'

import { POSITIVE, NEGATIVE, IGNORE, UNKNOWN, TEST_POSITIVE, TEST_NEGATIVE } from '../constants/labels.js'

import {
  TrashIcon,
  PositiveLabelControlIcon,
  NegativeLabelControlIcon,
  PositiveTestLabelControlIcon,
  NegativeTestLabelControlIcon,
  IgnoreLabelControlIcon
} from '../../widgets/IconSet.js'

import { withUser } from '../../UserContext.js'

import s from './ClassifierLabelControl.module.scss'
import { get_static_classname } from '../../../utils/tracking_utils.js'


/**
 * Renders buttons (pos / neg / ignore) for adding/updating patents to a Classifier.
 * @param {} className
 * @param {} set_label single arg function: set_label(label)
 * @param {} selected_label (optional)
 */
const ClassifierLabelControl = ({
                                  user,
                                  className,
                                  disable,
                                  set_label,
                                  selected_label
                                }) => {

  const is_positive      = selected_label === POSITIVE
  const is_negative      = selected_label === NEGATIVE
  const is_test_positive = selected_label === TEST_POSITIVE
  const is_test_negative = selected_label === TEST_NEGATIVE
  const is_ignore        = selected_label === IGNORE

  const is_labelled = ((selected_label != null) && (selected_label !== UNKNOWN))

  return (
    <div className={cn('d-flex align-items-center h-100', className)}>

      <div
        title={'Add to Training Set as a "positive"'}
        className={cn(
          get_static_classname('btn_classifier_label_pos'),
          s.button_container,
          s.label_as_positive,
          {
            [s.button_container__selected]: is_positive,
            [s.button_container__disabled]: disable
          }
        )}
        onClick={(event) => {
          event.stopPropagation()
          set_label(POSITIVE)
        }}
      >
        <span
          className={cn(
            s.label_icon,
            {
              [s.label_icon__disabled]: disable,
              [s.label_as_positive__selected]: is_positive
            }
          )}
        >
          <PositiveLabelControlIcon/>
        </span>
      </div>

      <div
        title={'Add to Training Set as a "negative"'}
        className={cn(
          get_static_classname('btn_classifier_label_neg'),
          'ms-1',
          s.button_container,
          s.label_as_negative,
          {
            [s.button_container__selected]: is_negative,
            [s.button_container__disabled]: disable
          }
        )}
        onClick={(event) => {
          event.stopPropagation()
          set_label(NEGATIVE)
        }}
      >
        <span
          className={cn(
            s.label_icon,
            {
              [s.label_icon__disabled]: disable,
              [s.label_as_negative__selected]: is_negative
            }
          )}
        >
          <NegativeLabelControlIcon/>
        </span>
      </div>

      <div
        title={'Mark as "ignore"'}
        className={cn(
          get_static_classname('btn_classifier_label_ign'),
          'ms-1',
          s.button_container,
          s.label_as_ignore,
          {
            [s.button_container__selected]: is_ignore,
            [s.button_container__disabled]: disable
          }
        )}
        onClick={(event) => {
          event.stopPropagation()
          set_label(IGNORE)
        }}
      >
        <span
          className={cn(
            s.label_icon,
            {
              [s.label_icon__disabled]: disable,
              [s.label_as_ignore__selected]: is_ignore
            }
          )}
        >
          <IgnoreLabelControlIcon/>
        </span>
      </div>
      <div className={cn('ms-1', s.left_separator)}/>
      <div className={cn('d-flex align-items-center h-100 ps-1')}>
        <div
          title={'Add to Test Set as a "positive"'}
          className={cn(
            get_static_classname('btn_classifier_label_pos_test'),
            s.button_container,
            s.label_as_test_positive,
            {
              [s.button_container__selected]: is_test_positive,
              [s.button_container__disabled]: disable
            }
          )}
          onClick={(event) => {
            event.stopPropagation()
            set_label(TEST_POSITIVE)
          }}
        >
          <span
            className={cn(
              s.label_icon,
              {
                [s.label_icon__disabled]: disable,
                [s.label_as_test_positive__selected]: is_test_positive
              }
            )}
          >
            <PositiveTestLabelControlIcon/>
          </span>
        </div>

        <div
          title={'Add to Test Set as a "negative"'}
          className={cn(
            get_static_classname('btn_classifier_label_neg_test'),
            'ms-1',
            s.button_container,
            s.label_as_test_negative,
            {
              [s.button_container__selected]: is_test_negative,
              [s.button_container__disabled]: disable
            }
          )}
          onClick={(event) => {
            event.stopPropagation()
            set_label(TEST_NEGATIVE)
          }}
        >
          <span
            className={cn(
              s.label_icon,
              {
                [s.label_icon__disabled]: disable,
                [s.label_as_test_negative__selected]: is_test_negative
              }
            )}
          >
            <NegativeTestLabelControlIcon/>
          </span>
        </div>

      </div>
      <div className={cn('ms-1', s.left_separator)}/>

      <div className={cn('d-flex align-items-center h-100 ps-1')}>
        <div
          title={'Remove label'}
          className={cn(
            get_static_classname('btn_classifier_label_remove'),
            s.button_container,
            s.remove_label_icon,
            {
              [s.button_container__disabled]: !is_labelled || disable
            }
          )}
          onClick={(event) => {
            event.stopPropagation()
            set_label(UNKNOWN)
          }}
        >
          <span
            className={cn(
              s.label_icon,
              {[s.label_icon__disabled]: !is_labelled || disable}
            )}
          >
            <TrashIcon/>
          </span>
        </div>
      </div>
    </div>

  )
}

export default withUser(ClassifierLabelControl)