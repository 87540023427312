import React from 'react'
import cn from 'classnames'

import s from './Logo.module.scss'

const Logo = ({ id, link_to, small_logo, title, className }) => {
  return (
    <a
      id={id}
      className={cn(
        'ms-3 d-flex',
        s.logo,
        { [s.logo_mark_only]: small_logo },
        className
      )}
      href={link_to || '/'}
      title={title || 'Home page'}
    >
      <span className='d-none'>Home page</span>
    </a>
  )
}

export const CipherLogo = ({ link_to, small_logo, title, className }) => {
  return (
    <Logo
      id='cipher_logo'
      className={cn(s.logo_cipher, className)}
      link_to={link_to}
      small_logo={small_logo}
      title={title}
    />
  )
}

export const TechDiscoveryLogo = ({small_logo, className}) => {
  return (
    <Logo
      id='techdiscovery_logo'
      className={cn(s.logo_techdiscovery, className)}
      title='TechDiscovery home page'
      small_logo={small_logo}
    />
  )
}
