import React, {Component} from 'react'
import {withRouter} from 'react-router'
import qs from 'query-string'

import ContainerFullWidth from './ContainerFullWidth.js'
import ErrorBody from './ErrorBody.js'
import Spinner from './widgets/Spinner.js'
import TextLink from './widgets/TextLink.js'

import { save_user_setting } from '../utils/user_settings_utils.js'
import { get_as_map } from '../utils/utils.js'
import { track_user_event } from '../utils/tracking_utils.js'

import { NOTIFY_BEFORE_REPORT_EXPIRY } from '../model/user_settings.js'


const NOTIFICATION_TYPES = [
  { id: 'report_expiry',
    description: 'notifications when Classification reports you created are due to expire',
    user_setting: NOTIFY_BEFORE_REPORT_EXPIRY,
    default: true }
]

const NOTIFICATION_TYPES_BY_ID = get_as_map(NOTIFICATION_TYPES, 'id')


class Notifications extends Component {
  constructor(props) {
    super(props)
    this.state = {
      updating_notification_settings: true,
      error_updating_notification_settings: null
    }
  }

  componentDidMount() {
    const {location} = this.props
    const query_params = qs.parse(location.search)
    const {notification_id, opt_out} = query_params

    this.update_notification_preferences(notification_id, opt_out)
  }

  update_notification_preferences(notification_id, is_opt_out) {
    this.save_preference(notification_id, is_opt_out)
      .catch(err => {
        this.setState({
          updating_notification_settings: true,
          error_updating_notification_settings: err
        })
      })
      .then(() => {
        track_user_event(`obj="notification" type="${notification_id}" action="${is_opt_out ? 'unsubscribe' : 'subscribe'}"`)

        this.setState({
          updating_notification_settings: false,
          notification_id,
          is_opted_out: is_opt_out
        })
      })
  }

  save_preference(notification_id, is_opt_out) {
    const notification = NOTIFICATION_TYPES_BY_ID[notification_id]
    if (!notification) {
      throw Error(`Notification type ${notification_id} not found`)
    }
    return save_user_setting(notification.user_setting, !is_opt_out)
  }

  render() {
    const {updating_notification_settings, notification_id, is_opted_out, error_updating_notification_settings } = this.state

    if (error_updating_notification_settings) {
      return (
        <ContainerFullWidth>
          <ErrorBody
            error={error_updating_notification_settings}
            context={'updating notification preferences'}
          />
        </ContainerFullWidth>
      )
    }

    return (
      <ContainerFullWidth>
        { updating_notification_settings &&
          <Spinner/>
        }
        { !updating_notification_settings && notification_id &&
          <div className='mt-3'>
            You have successfully {is_opted_out ? 'unsubscribed from' : 'subscribed to'} {NOTIFICATION_TYPES_BY_ID[notification_id].description}.
            <div className='mt-2'>
              <TextLink onClick={this.update_notification_preferences.bind(this, notification_id, !is_opted_out)}>{is_opted_out ? 'Resubscribe' : 'Unsubscribe'}</TextLink>
            </div>
          </div>
        }
      </ContainerFullWidth>
      )
  }
}

export default withRouter(Notifications)