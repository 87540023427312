import React, { useEffect, useState } from 'react'
import _ from 'underscore'

import { fetch_all_active_keycloak_groups } from '../../utils/user_group_utils.js'
import { send_error_to_sentry } from '../../utils/sentry_utils.js'
import UserGroupSearch from './UserGroupSearch.js'
import {
  delete_user_settings_by_id,
  save_user_setting_by_id
} from '../../utils/user_settings_utils.js'
import {
  NOTIFY_BEFORE_REPORT_EXPIRY,
  SETTINGS_ENABLED_TO_RESET,
  USER_CHARTS_IN_THUMBNAILS,
  USER_DEFAULT_CHART_SELECTION,
  USER_DEFAULT_UTT_VERSION,
  USER_INCLUDE_UNRELATED_TECHS, USER_LANGUAGE_PREFERENCE,
  USER_REGION_GROUPING,
  USER_SHOW_UNGROUPED_FAMILIES,
  USER_STARTING_PAGE
} from '../../model/user_settings.js'
import UserSettingsDisplay from '../UserSettingsDisplay.js'
import { get_default_chart_selection } from '../../utils/user_settings_utils.js'
import { get_custom_chart_sets } from '../../utils/main_items_selection_utils.js'
import AdminPageWrapper from './AdminPageWrapper.js'

const UserSettingsManagement = () => {
  const [groups, set_groups] = useState(null)

  const [is_fetching_groups, set_is_fetching_groups] = useState(true)
  const [selected_group, set_selected_group] = useState(null)
  const [selected_user, set_selected_user] = useState(null)
  const [selected_group_settings, set_selected_group_settings] = useState(null)
  const [selected_group_users, set_selected_group_users] = useState(null)
  const [selected_user_settings, set_selected_user_settings] = useState(null)

  useEffect(() => {
    fetch_all_active_keycloak_groups()
      .catch(error => {
        set_is_fetching_groups(false)
        // No point in notifying user, but log error in sentry.
        send_error_to_sentry(error, {})
        throw error
      })
      .then(group_data => {
        const groups = _.sortBy(group_data, g => (g.name || '').toLowerCase())
        set_groups(groups)
        set_is_fetching_groups(false)
      })
  }, [])

  function save_setting(key, value) {
    const {id} = selected_user || {}
    return save_user_setting_by_id(id, key, value)
      .catch(err => {
        throw err
      })
      .then(() => {
        const new_user_settings = {...selected_user_settings, [key]: value}
        set_selected_user_settings(new_user_settings)
      })
  }

  function save_setting_for_all_users(key, value) {
    const save_settings_promises = selected_group_users.map(user => {
      return save_user_setting_by_id(user.id, key, value)
    })

    Promise.all(save_settings_promises)
      .catch(err => {
        throw err
      })
      .then(() => {
        set_selected_group_settings({...selected_group_settings || {}, [key]: value})
      })
  }

  function reset_user_settings() {
    const {id} = selected_user || {}

    return delete_user_settings_by_id(id, SETTINGS_ENABLED_TO_RESET)
      .catch(err => {
        throw err
      })
      .then(() => {
        const new_user_settings = _.omit(selected_user_settings, SETTINGS_ENABLED_TO_RESET)
        set_selected_user_settings(new_user_settings)
      })
  }

  return (
    <AdminPageWrapper page_name='User settings'>
      <label className='mt-3'>
        Select user to update settings
      </label>

      <div className='d-flex'>
        <UserGroupSearch
          show_spinner={is_fetching_groups}
          groups={groups}
          selected_group={selected_group}
          selected_user={selected_user}
          on_select_group_handler={set_selected_group}
          on_select_user_handler={set_selected_user}
          on_get_group_settings_handler={set_selected_group_settings}
          on_get_group_users_handler={set_selected_group_users}
          on_get_user_settings_handler={set_selected_user_settings}

          select_user_label='Update all accounts or select one'
        />
      </div>

      <div className='mt-3'>

      {selected_group && selected_group_users && selected_group_users.length > 0 && !selected_user &&
        <UserSettingsDisplay
          user_chart_sets={get_custom_chart_sets([] , selected_group_settings)}
          change_default_chart_selection_handler={(default_chart_selection) => save_setting_for_all_users(USER_DEFAULT_CHART_SELECTION, default_chart_selection)}
          change_default_utt_version_handler={(default_utt_version) => save_setting_for_all_users(USER_DEFAULT_UTT_VERSION, default_utt_version)}
          change_starting_page_handler={(starting_page) => save_setting_for_all_users(USER_STARTING_PAGE, starting_page)}
          change_region_grouping_handler={(grouping) => save_setting_for_all_users(USER_REGION_GROUPING, grouping)}
          change_thumbnails_handler={(show_charts) => save_setting_for_all_users(USER_CHARTS_IN_THUMBNAILS, show_charts)}
          change_should_show_ungrouped_families_handler={(should_show_ungrouped_families) => save_setting_for_all_users(USER_SHOW_UNGROUPED_FAMILIES, should_show_ungrouped_families)}
          change_should_include_unrelated_techs_handler={(include_unrelated) => save_setting_for_all_users(USER_INCLUDE_UNRELATED_TECHS, include_unrelated)}
          toggle_notify_before_report_expiry_handler={(should_notify) => save_setting_for_all_users(NOTIFY_BEFORE_REPORT_EXPIRY, should_notify)}
          change_language_preference_handler={(language_preference) => save_setting_for_all_users(USER_LANGUAGE_PREFERENCE, language_preference)}
        />
      }

      {selected_user &&
        <UserSettingsDisplay
          user_settings={selected_user_settings}
          user_chart_sets={get_custom_chart_sets(selected_user_settings, selected_group_settings)}
          default_chart_selection={get_default_chart_selection(selected_user_settings)}
          change_default_chart_selection_handler={(default_chart_selection) => save_setting(USER_DEFAULT_CHART_SELECTION, default_chart_selection)}
          change_default_utt_version_handler={(default_utt_version) => save_setting(USER_DEFAULT_UTT_VERSION, default_utt_version)}
          change_starting_page_handler={(starting_page) => save_setting(USER_STARTING_PAGE, starting_page)}
          change_region_grouping_handler={(grouping) => save_setting(USER_REGION_GROUPING, grouping)}
          change_thumbnails_handler={(show_charts) => save_setting(USER_CHARTS_IN_THUMBNAILS, show_charts)}
          change_should_show_ungrouped_families_handler={(should_show_ungrouped_families) => save_setting(USER_SHOW_UNGROUPED_FAMILIES, should_show_ungrouped_families)}
          change_should_include_unrelated_techs_handler={(include_unrelated) => save_setting(USER_INCLUDE_UNRELATED_TECHS, include_unrelated)}
          toggle_notify_before_report_expiry_handler={(should_notify) => save_setting(NOTIFY_BEFORE_REPORT_EXPIRY, should_notify)}
          change_language_preference_handler={(language_preference) => save_setting(USER_LANGUAGE_PREFERENCE, language_preference)}
          reset_settings_handler={reset_user_settings}
        />
      }
      </div>
    </AdminPageWrapper>
  )
}

export default UserSettingsManagement