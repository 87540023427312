import React from 'react'
import cn from 'classnames'

import { FormFeedback as RSFormFeedback } from 'reactstrap'
import { ExclamationMarkIcon } from './IconSet.js'

import s from './FormFeedback.module.scss'

export const FormFeedback = ({valid, validation_text, ...rest}) => {
  return (
    <div className={cn(
      (valid ? 'd-none' : 'd-inline-flex'),
      s.feedback_container
    )}>
      <ExclamationMarkIcon />
      <RSFormFeedback
        valid={valid}
        className={cn(s.feedback_text, {[s.form_feedback__visible]: !valid})}
        {...rest}
      >
        {validation_text}
      </RSFormFeedback>
    </div>
  )
}