import React from 'react'
import _ from 'underscore'
import cn from 'classnames'

import OrgSearchMultipleMatchesDropdown from './OrgSearchMultipleMatchesDropdown.js'
import TextLink from '../widgets/TextLink.js'
import { AddCircleIcon, TrashIcon } from '../widgets/IconSet.js'
import { get_org_id } from '../../utils/organisation_utils.js'
import EditableText from '../widgets/EditableText.js'

import cs from '../cipher_styles.module.scss'
import s from './OrgSearchMultipleMatchesTable.module.scss'

const OrgSearchMultipleMatchesTableRow = (
  {
    is_wizard,
    enable_remove_action,

    name,
    best_match,
    is_ignore,
    is_selected,
    all_match_options,
    can_add,

    on_select,
    on_update_ignore_search_name,
    on_update_best_match,

    on_update_search_name,
    on_validate_new_search_name,
  }) => {

  const select_link_title = !is_selected ? 'Add' : (enable_remove_action) ? 'Remove' : 'Already exists'

  return (
    <tr>
      <td className={cn( {[s.row_is_ignore]: is_ignore && !is_wizard})}>
        <EditableText
          text={name}
          on_confirm={on_update_search_name}
          on_check_if_valid={on_validate_new_search_name}
          textClassName={cs.border_none}
          edit_icon_on_hover={true}
        />
      </td>
      <td className={s.match_options_column}>
        { best_match ?
          <OrgSearchMultipleMatchesDropdown
            search_phrase={name}
            best_match={best_match}
            all_match_options={all_match_options}
            is_selected={is_selected}
            on_update_best_match={on_update_best_match}
            is_ignore={is_ignore}
            on_ignore={() => on_update_ignore_search_name(name, !is_ignore)}
          />
          : <span>No match found</span>
        }
      </td>
      {!is_wizard &&
        <td className={cn('align-middle text-center', is_ignore ? s.row_is_ignore : '')}>
          { best_match &&
            <TextLink
              onClick={() => on_select(best_match, is_selected)}
              disable={is_ignore || (is_selected && !enable_remove_action) || !can_add}
              className={cn({[cs.pointer_events_disabled]: is_selected && !enable_remove_action})}
              title={select_link_title}
              no_decoration
            >
              { is_selected ? <TrashIcon /> : <AddCircleIcon /> }
            </TextLink>
          }
        </td>
      }
    </tr>
  )
}


const OrgSearchMultipleMatchesTable = (
  {
    search_names,
    search_name_to_org_search_results,
    search_name_to_best_match,
    search_names_to_ignore,
    selected_org_ids,
    on_result_check_in,
    on_update_best_match,
    on_update_ignore_search_name,
    on_update_search_name,
    enable_remove_action,
    enable_ignore_action,
    can_add_orgs_handler,

    is_wizard,
  }) => {

  function on_select(org, is_selected) {
    if ((is_selected && !enable_remove_action) || !(check_if_can_add_one(org))) return
    on_result_check_in({org, parents: [], add: !is_selected})
  }

  function check_if_can_add_one(org) {
    if (can_add_orgs_handler) {
      return can_add_orgs_handler({orgs_to_add: [org]})
    }

    return true
  }

  function check_if_search_name_valid(search_name) {
    if (!search_name || search_name.trim() === '') return false

    if (search_names.indexOf(search_name) !== -1) return false

    return true
  }

  return (
    <table className='table'>
      <thead>
        <tr>
          <th className={cs.font_weight_normal}>Search input</th>
          <th className={cs.font_weight_normal}>Match options</th>
          {!is_wizard &&
            <th />
          }
        </tr>
      </thead>
      <tbody>
        { (search_names || []).map((name, i) => {

          const best_match = search_name_to_best_match[name]
          const org_id = get_org_id(best_match)
          const is_selected = selected_org_ids.indexOf(org_id) !== -1
          const is_ignore = _.contains(search_names_to_ignore, name)

          return (
            <OrgSearchMultipleMatchesTableRow
              key={i}
              is_wizard={is_wizard}
              enable_ignore_action={enable_ignore_action}
              enable_remove_action={enable_remove_action}

              name={name}
              best_match={best_match}
              all_match_options={search_name_to_org_search_results[name]}
              is_ignore={is_ignore}
              is_selected={is_selected}
              can_add={check_if_can_add_one(best_match)}

              on_select={on_select}
              on_update_best_match={(better_match) => on_update_best_match(name, better_match)}
              on_update_ignore_search_name={on_update_ignore_search_name}
              on_update_search_name={(new_name) => on_update_search_name(new_name, i)}

              on_validate_new_search_name={check_if_search_name_valid}
            />
          )
        })}
      </tbody>
    </table>
  )
}

export default OrgSearchMultipleMatchesTable