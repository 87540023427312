import React from 'react'
import cn from 'classnames'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { OpenWithIcon } from '../widgets/IconSet.js'

import s from './DragAndDropSortableItem.module.scss'

export function DragAndDropSortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable(
    {
      id: props.id,
      transition: {
        duration: 550, // milliseconds
        easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
      }
    })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const {id, active_id, over_id, is_enabled, className} = props

  const is_active = active_id === id
  const is_over   = over_id === id

  return (
    <div
      ref={setNodeRef}
      className={cn('position-relative', { [s.item__active]: is_active}, {[s.item__over]: is_over && !is_active}, className)}

      style={style}
    >
      {props.children}

      {is_enabled &&
        <button
          {...attributes}
          {...listeners}

          className={s.drag_btn}
        >
          <OpenWithIcon />
        </button>
      }
    </div>
  )
}

