import React, { useEffect, useState } from 'react'
import cn from 'classnames'

import ContainerFullWidth from '../ContainerFullWidth.js'
import { withUser } from '../UserContext.js'
import { is_creator } from '../../utils/user_permissions.js'
import { extract_failed_report_status_from_error } from '../../utils/report_progress_utils.js'
import { CipherLogo } from '../Logo.js'
import OnlineDetector from '../OnlineDetector.js'
import HeaderControls from './HeaderControls.js'
import ShareReportModal from '../report_management/components/ShareReportModal.js'
import SavedReportConfirmationModal from '../report_management/components/SavedReportConfirmationModal.js'
import { CreateNewTagModal } from '../report_management/components/CreateNewTagModal.js'

import { usePrevious } from '../../hooks/general_hooks.js'
import { HAS_VIEWED_SAVED_REPORT_INTRO } from '../../model/user_settings.js'
import { save_user_setting } from '../../utils/user_settings_utils.js'
import { track_report_viewer_event } from '../../utils/tracking_utils.js'
import { is_array_non_empty_non_null } from '../../utils/utils.js'
import HeaderAppsMenu from './HeaderAppsMenu.js'
import CipherSwitch from './CipherSwitch.js'

import s from './Header.module.scss'

const ViewerHeader = (
  {
    user,

    is_fetch_initial,
    fetch_initial_error,

    external_report_id,
    report_input,
    report_title,
    report_type,

    data_creation_date,
    is_saved,
    is_incomplete,
    created_by_user,
    report_series_sort,
    show_charts_in_thumbnails,
    chart_in_thumbnails_allowed,
    selections,
    selected_items,
    available_tags,
    tag_ids,

    eval_classifier_path_to_ui,
    eval_classifier_id,
    rerun_eval_report,

    save_report_handler,
    change_report_sort_handler,
    show_user_settings_handler,
    change_report_thumbnails_handler,
    rename_report_handler,
    tag_report_handler,
    create_new_tag_handler,
    reset_report_handler,
    user_settings,
    user_state,
    no_data
  }) => {

  const user_has_report_tags = is_array_non_empty_non_null(available_tags)

  const [show_share_modal, set_show_share_modal] = useState(false)
  const [is_newly_saved_report_modal_open, set_is_newly_saved_report_modal_open] = useState(false)
  const [is_newly_saved_report_tooltip_open, set_is_newly_saved_report_tooltip_open] = useState(false)
  const [is_entering_new_tag_name, set_is_entering_new_tag_name] = useState(false)
  const [is_editing_report_tags, set_is_editing_report_tags] = useState(false)

  const was_saved = usePrevious(is_saved)

  useEffect(() => {
    if (was_saved === false && is_saved === true) {
      if (!user_settings[HAS_VIEWED_SAVED_REPORT_INTRO]) {
        set_is_newly_saved_report_modal_open(true)
        save_user_setting(HAS_VIEWED_SAVED_REPORT_INTRO, true)
          .catch(err => {
            throw err
          })
      } else {
        set_is_newly_saved_report_tooltip_open(true)
      }
    }
  }, [was_saved, is_saved, user_settings])

  function on_confirm_new_tag_name(name) {
    set_is_entering_new_tag_name(false)
    create_new_tag_handler(name)
  }

  function on_edit_tags_for_newly_saved_report() {
    set_is_newly_saved_report_modal_open(false)
    toggle_edit_report_tags()
  }

  function toggle_edit_report_tags() {
    set_is_newly_saved_report_tooltip_open(false)
    if (!is_entering_new_tag_name) {
      const should_open = !is_editing_report_tags
      if (should_open && !user_has_report_tags) {
        // if the user hasn't created any tags yet, open the input modal so they can create one
        toggle_is_entering_new_tag_name()
        return
      }

      if (should_open) {
        track_report_viewer_event('obj="edit_tags_dropdown" action="show"')
      }

      set_is_editing_report_tags(should_open)
    }
  }

  function toggle_is_entering_new_tag_name() {
    const should_open = !is_entering_new_tag_name

    if (should_open) {
      track_report_viewer_event('obj="new_tag_modal" action="show"')
    }

    set_is_entering_new_tag_name(should_open)
  }

  const is_eval_report = !!eval_classifier_id

  return (
    <ContainerFullWidth className={cn('sticky-top', s.header_container)}>
      <OnlineDetector/>

      <div className={cn('d-flex p-0', s.header_navbar)}>
        <div className='d-flex'>
          <HeaderAppsMenu />
          <CipherLogo className='mt-4 ms-4' />
        </div>

        <div className='ms-auto h-100 d-flex'>
          <CipherSwitch />

          <HeaderControls
            include_report_controls={true}
            is_fetch_initial={is_fetch_initial}
            should_disable={is_fetch_initial || (fetch_initial_error != null)}
            has_report_failed_to_build={fetch_initial_error ? (extract_failed_report_status_from_error(fetch_initial_error) !== null) : false}
            external_report_id={external_report_id}
            report_input={report_input}
            report_title={report_title}
            report_type={report_type}
            data_creation_date={data_creation_date}
            is_incomplete={is_incomplete}
            is_saved={is_saved}
            created_by_user={created_by_user}
            report_series_sort={report_series_sort}
            show_charts_in_thumbnails={show_charts_in_thumbnails}
            chart_in_thumbnails_allowed={chart_in_thumbnails_allowed}
            selections={selections}
            selected_items={selected_items}
            available_tags={available_tags}
            tag_ids={tag_ids}

            is_eval_report={is_eval_report}
            can_edit_classifier={eval_classifier_path_to_ui}
            rerun_eval_report={rerun_eval_report}

            is_editing_report_tags={is_editing_report_tags}
            is_newly_saved_report_tooltip_open={is_newly_saved_report_tooltip_open}
            edit_tag_selections_handler={toggle_edit_report_tags}
            create_new_tag_handler={toggle_is_entering_new_tag_name}
            hide_newly_saved_report_tooltip_handler={() => set_is_newly_saved_report_tooltip_open(false)}

            save_report_handler={save_report_handler}
            change_report_sort_handler={change_report_sort_handler}
            show_user_settings_handler={show_user_settings_handler}
            change_report_thumbnails_handler={change_report_thumbnails_handler}
            tag_report_handler={tag_report_handler}
            rename_report_handler={rename_report_handler}
            share_report_handler={() => set_show_share_modal(true)}
            reset_report_handler={reset_report_handler}
            user_settings={user_settings}
            user_state={user_state}
            no_data={no_data}
          />
        </div>
      </div>

      {/* Share report */}
      <ShareReportModal
        is_open={is_creator(user) && show_share_modal}
        external_report_id={external_report_id}
        report_title={report_title}
        selections={selections}
        main_items={selected_items}
        user_state={user_state}
        on_close={() => set_show_share_modal(false)}
      />

      {/* Show save confirmation message */}
      {is_newly_saved_report_modal_open &&
        <SavedReportConfirmationModal
          on_hide={() => set_is_newly_saved_report_modal_open(false)}
          report_count={1}
          on_edit_tags={on_edit_tags_for_newly_saved_report}
        />
      }

      {/* Create new tag for report */}
      {is_entering_new_tag_name &&
        <CreateNewTagModal
          handle_create_new_tag={on_confirm_new_tag_name}
          existing_tags={available_tags}
          on_close={toggle_is_entering_new_tag_name}
        />
      }
    </ContainerFullWidth>
  )
}

export default withUser(ViewerHeader)
