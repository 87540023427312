import React, { useState } from 'react'
import _ from 'underscore'

import { withUser } from '../UserContext.js'
import {
  has_cipher_product,
  has_orgsets_permissions,
  is_aistemos,
} from '../../utils/user_permissions.js'
import OrgSetsPanel from './OrgSetsPanel.js'
import UserCreatedCompanyLists from './UserCreatedCompanyLists.js'
import { AUTOMOTIVE_ORGSETS, IA_ORGSETS, PRODUCT_BANKOFAMERICA, PRODUCT_JTI } from '../../constants/cipher_product_ids.js'
import { fetch_organisations_by_tags } from '../../utils/organisation_utils.js'
import { fetch_list_companies_for_basket } from '../../utils/company_list_utils.js'
import {
  AUTOMOTIVE_GROUPS,
  AUTOMOTIVE_TAG,
  BOA_GROUPS,
  BOA_TAG,
  CIPHER_GROUPS,
  IA_GROUPS,
  IA_TAG,
  JTI_GROUPS,
  JTI_TAG
} from '../../model/org_sets.js'
import ErrorModal from '../ErrorModal.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'
import Modal from '../widgets/Modal.js'
import Spinner from '../widgets/Spinner.js'
import OrgListAdminLink from './OrgListAdminLink.js'

import s from './OrgSetsDisplay.module.scss'

const OrgSetsDisplay = ({user, add_to_basket_handler, user_company_lists, user_company_list_updated_handler, user_company_list_rename_handler, user_company_list_sharing_handler, invalid_portfolios_in_list_handler}) => {
  const [is_fetching, set_is_fetching] = useState(false)
  const [fetch_error, set_fetch_error] = useState(null)
  const [set_without_results, set_set_without_results] = useState(null)

  function get_tags_query({product_tags, group_tags, set_tags}) {
    const tags = [...product_tags || [], ...group_tags || [], ...set_tags || []]
    const tags_dsl = (tags || []).join(' AND ')
    return tags_dsl
  }

  function fetch_orgs_in_set(group, set, product_tags) {
    if (is_fetching) return

    const { group_tags, label: group_label } = group
    const { tags: set_tags, fetch_endpoint, label: set_label } = set

    const tags_dsl = get_tags_query({product_tags, group_tags, set_tags})

    track_report_builder_event(`action="add_to_basket" obj="org_set" group="${group_label}" set="${set_label}"`)

    set_is_fetching(true)

    const endpoint = fetch_endpoint ? fetch_endpoint : fetch_organisations_by_tags

    endpoint(tags_dsl)
      .then(results => {
        const company_list = set_label // use org set name for company list in report input
        const orgs = (results || []).map(org => ({...org, company_list}))
        if (orgs.length === 0) {
          set_set_without_results(set)
        } else {
          const orgs_sorted = _.sortBy(orgs, 'name')
          add_to_basket_handler(orgs_sorted)
        }
        set_is_fetching(false)
      })
      .catch(error => {
        set_fetch_error(error)
        set_is_fetching(false)
      })
  }

  function fetch_companies_in_user_created_list(company_list_id, list_name, is_shared) {
    set_is_fetching(true)

    track_report_builder_event(`action="add_to_basket" obj="org_list" group="${is_shared ? 'shared' : 'personal'}"`)

    fetch_list_companies_for_basket(company_list_id)
      .then(([companies, missing_orgs]) => {
        const sorted = _.sortBy(companies, 'name')
          .map(company => ({...company, company_list: list_name, company_list_id: company_list_id}))

        add_to_basket_handler(sorted)
        set_is_fetching(false)

        if (!_.isEmpty(missing_orgs) && invalid_portfolios_in_list_handler) {
          invalid_portfolios_in_list_handler(missing_orgs)
        }
      })
      .catch(error => {
        set_fetch_error(error)
        set_is_fetching(false)
      })
  }

  const user_has_company_lists = user_company_lists && !_.isEmpty(user_company_lists)

  return (
    <div>
      {fetch_error &&
        <ErrorModal
          error={fetch_error}
          on_hide={() => set_fetch_error(null)}
          context='fetching organisations in organisation set'
        />
      }

      {set_without_results &&
        <Modal
          is_open={true}
          on_hide={() => set_set_without_results(null)}
          context='fetching organisations in organisation set'
        >
          No organisations found in the &#39;{set_without_results.label}&#39; set
        </Modal>
      }

      <div className={s.message}>
        {is_fetching &&
          <div className='d-flex justify-content-end'>
            <Spinner className='me-1' size='sm' /><div className='my-auto'>fetching organisations</div>
          </div>
        }
      </div>

      {!user_has_company_lists &&
        <div className='mb-2'>
          <OrgListAdminLink label='Create your own organisation lists' />
        </div>
      }

      <div className='d-sm-flex'>

        {user_has_company_lists &&
          <UserCreatedCompanyLists
            company_lists={user_company_lists}
            on_list_select_handler={(company_list_id, list_name, is_shared) => fetch_companies_in_user_created_list(company_list_id, list_name, is_shared)}
            handle_lists_updated={user_company_list_updated_handler}
            user_company_list_rename_handler={user_company_list_rename_handler}
            user_company_list_sharing_handler={user_company_list_sharing_handler}
            className='my-2'
          />
        }

        <OrgSetsPanel
          title='Classification'
          groups={CIPHER_GROUPS}
          on_org_set_select_handler={(group, set) => fetch_orgs_in_set(group, set)}
          className='my-2'
        />

        {has_orgsets_permissions(user, AUTOMOTIVE_ORGSETS) &&
          <OrgSetsPanel
            groups={AUTOMOTIVE_GROUPS}
            title='Automotive'
            on_org_set_select_handler={(group, set) => fetch_orgs_in_set(group, set, [AUTOMOTIVE_TAG]) }
            className='my-2'
          />
        }

        {has_orgsets_permissions(user, IA_ORGSETS) &&
          <OrgSetsPanel
            groups={IA_GROUPS}
            title='Industrial Automation'
            on_org_set_select_handler={(group, set) => fetch_orgs_in_set(group, set, [IA_TAG]) }
            className='my-2'
          />
        }

        {is_aistemos(user) && has_cipher_product(user, PRODUCT_BANKOFAMERICA) &&
          <OrgSetsPanel
            groups={BOA_GROUPS}
            title='Bank of America'
            on_org_set_select_handler={(group, set) => fetch_orgs_in_set(group, set, [BOA_TAG]) }
            className='my-2'
          />
        }

        {is_aistemos(user) && has_cipher_product(user, PRODUCT_JTI) &&
          <OrgSetsPanel
            groups={JTI_GROUPS}
            title='Japan Tobacco International'
            on_org_set_select_handler={(group, set) => fetch_orgs_in_set(group, set, [JTI_TAG]) }
            className='my-2'
          />
        }
      </div>
    </div>
  )
}

export default withUser(OrgSetsDisplay)