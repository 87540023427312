import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'

import { extract_report_state_id } from '../../utils/report_url_utils.js'
import ContainerFullWidth from '../ContainerFullWidth.js'
import Spinner from '../widgets/Spinner.js'
import { get_report_state, scroll_window_to_top } from '../../utils/viewer_utils.js'
import { get_cipher_hostname } from '../../utils/utils.js'
import { REPORT, REPORT_STATE } from '../../constants/paths.js'
import ErrorBody from '../ErrorBody.js'
import { set_custom_url, set_referrer_url, track_visit_event } from '../../utils/tracking_utils.js'
import { is_not_found, is_forbidden } from '../../utils/axios_utils.js'
import TextLink from '../widgets/TextLink.js'

const ReportState = ({history}) => {
  const state_id = extract_report_state_id(window.location.href)

  const [error, set_error] = useState(null)
  const [state_not_found, set_state_not_found] = useState(null)
  const [is_not_authorised, set_is_not_authorised] = useState(null)

  useEffect(() => {
    if (!state_id) return

    get_report_state(state_id)
      .then(external_report_id => {

        const custom_url = `${get_cipher_hostname()}${REPORT_STATE}`
        set_custom_url(custom_url)
        set_referrer_url(custom_url)
        track_visit_event(`page="shared_report"`)

        const { pathname, search } = history.location
        const path_parts = pathname.split(state_id)
        const report_url = `${get_cipher_hostname()}${REPORT}/${external_report_id}${path_parts[1]}${search || ''}`

        window.location.replace(report_url)
      })
      .catch(error => {
        if (is_not_found(error)) {
          set_state_not_found(true)
          return
        } else if (is_forbidden(error)) {
          set_is_not_authorised(true)
          return
        }
        set_error(error)
        throw error
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state_id])

  useEffect(() => {
    scroll_window_to_top()
  }, [])

  const cannot_show_report = state_not_found || is_not_authorised

  return (
    <ContainerFullWidth>
      {!error && !cannot_show_report &&
        <Spinner/>
      }

      {cannot_show_report &&
        <div>
          {state_not_found &&
            <span>Shared report link state not found.</span>
          }
          {is_not_authorised &&
            <span>You do not have access to this report.</span>
          }
          <br/>
          <TextLink element={Link} to={'/'}>Back to Classification</TextLink>
        </div>
      }

      {error &&
        <ErrorBody
          error={error}
          context='fetching report state for shareable link'
        />
      }
    </ContainerFullWidth>
  )
}

export default withRouter(ReportState)