export const STEP_INTRO = 'intro'
export const STEP_PORTFOLIO = 'portfolio'
export const STEP_PORTFOLIO_TEXT_UPLOAD = '1yWXg'
export const STEP_PORTFOLIO_FILE_UPLOAD = 'HWSsS'
export const STEP_PORTFOLIO_BOOLEAN_SEARCH = '146nv'
export const STEP_PORTFOLIO_KNN_SEARCH = 'teas8'
export const STEP_PORTFOLIO_ORG_SEARCH = 'EFEaV'
export const STEP_PORTFOLIO_ORG_LIST = '8kxAw'
export const STEP_TECHNOLOGY = 'technology'
export const STEP_TECHNOLOGY_UTT = 'Wb5Oi'
export const STEP_TECHNOLOGY_BINARY_CLASS = 'q0tk3'
export const STEP_TECHNOLOGY_SPLIT = 'technology_split'
export const STEP_REPORT_INPUT_PARAMS = 'report_input_params'

export const PORTFOLIO_ORG_SEARCH_OPTION = {
  id: STEP_PORTFOLIO_ORG_SEARCH, label: 'Organisation search'
}
export const PORTFOLIO_ORG_LIST_OPTION = {
  id: STEP_PORTFOLIO_ORG_LIST, label: 'Organisation sets'
}
export const PORTFOLIO_TEXT_UPLOAD_OPTION = {
  id: STEP_PORTFOLIO_TEXT_UPLOAD, label: 'Patent IDs'
}
export const PORTFOLIO_FILE_UPLOAD_OPTION = {
  id: STEP_PORTFOLIO_FILE_UPLOAD, label: 'File upload'
}

export const PORTFOLIO_BOOLEAN_SEARCH_OPTION = {
  id: STEP_PORTFOLIO_BOOLEAN_SEARCH, label: 'Boolean search'
}

export const PORTFOLIO_KNN_SEARCH_OPTION = {
  id: STEP_PORTFOLIO_KNN_SEARCH, label: 'Boolean search'
}

export const PORTFOLIO_UPLOAD_OPTIONS = [
  STEP_PORTFOLIO_TEXT_UPLOAD,
  STEP_PORTFOLIO_FILE_UPLOAD
]

export const PORTFOLIO_KEYWORD_SEARCH_OPTIONS = [
  STEP_PORTFOLIO_BOOLEAN_SEARCH,
  STEP_PORTFOLIO_KNN_SEARCH,
]

export const ALL_PORTFOLIO_SEARCH_OPTIONS = [
  ...PORTFOLIO_UPLOAD_OPTIONS,
  ...PORTFOLIO_KEYWORD_SEARCH_OPTIONS,
  STEP_PORTFOLIO_ORG_SEARCH,
  STEP_PORTFOLIO_ORG_LIST
]

export const LANDSCAPE_TECH_TYPE_UTT_OPTION = {
  id: STEP_TECHNOLOGY_UTT, label: 'UTT'
}

export const LANDSCAPE_TECH_TYPE_BINARY_CLASS_OPTION = {
  id: STEP_TECHNOLOGY_BINARY_CLASS, label: 'Classifier'
}

export const ALL_LANDSCAPE_TECH_TYPE_OPTIONS = [
  STEP_TECHNOLOGY_UTT, STEP_TECHNOLOGY_BINARY_CLASS
]

const ACCESSION_DATE_CUT_OFF_30_DAYS_OPTION = 30
const ACCESSION_DATE_CUT_OFF_60_DAYS_OPTION = 60
const ACCESSION_DATE_CUT_OFF_90_DAYS_OPTION = 90

export const ACCESSION_DATE_CUT_OFF_DEFAULT_OPTION = ACCESSION_DATE_CUT_OFF_30_DAYS_OPTION

export const ACCESSION_DATE_CUT_OFF_OPTIONS = [
  ACCESSION_DATE_CUT_OFF_30_DAYS_OPTION,
  ACCESSION_DATE_CUT_OFF_60_DAYS_OPTION,
  ACCESSION_DATE_CUT_OFF_90_DAYS_OPTION
]

