import React from 'react'
import Modal from '../widgets/Modal.js'
import { PrimaryButton } from '../widgets/Button.js'

const OrgUngroupModal = ({org_name, on_hide, on_confirm}) => {
  const footer = (
    <div className='d-flex'>
      <PrimaryButton onClick={() => on_confirm(false)}>Yes, ungroup</PrimaryButton>
      <PrimaryButton outline className='ms-2' onClick={() => on_confirm(true)}>Yes, ungroup and load</PrimaryButton>
    </div>
  )

  return (
    <Modal is_open={true} on_hide={on_hide} footer={footer} title={`Ungrouping ${org_name}`}>
      Are you sure you want to ungroup this organisation?
    </Modal>
  )
}

export default OrgUngroupModal