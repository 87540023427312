import React from 'react'
import cn from 'classnames'
import CheckboxStatic from './CheckboxStatic.js'

import s from './CheckboxAndLabel.module.scss'

export const CheckboxAndLabel = ({is_checked, is_partial, label, post_label, on_click, is_disabled, checkboxClassName, className}) => {
  return (
    <div className={cn('d-flex', className)} onClick={!is_disabled? on_click : null}>
      <CheckboxStatic
        is_checked={is_checked}
        is_partial={is_partial}
        className={cn('me-1', checkboxClassName)}
        is_disabled={is_disabled}
      />
      <span
        className={cn('my-auto ms-1', s.label_container, { [s.label_container__disabled]: is_disabled })}
      >
        <span onClick={!is_disabled ? on_click : null} className={cn('my-auto', {[s.__disabled]: is_disabled})}>
          {label}
        </span>
        {post_label}
      </span>
    </div>
  )
}