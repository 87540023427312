import React from 'react'
import cn from 'classnames'

import ValueDropdownSelector from './widgets/ValueDropdownSelector.js'
import Label from './widgets/Label.js'

import cs from './cipher_styles.module.scss'
import s from './PageSizeControl.module.scss'

/**
 * @param {} className
 * @param {} page_sizes An array of values
 * @param {} selected_page_size
 * @param {} on_change_page_size
 */
const PageSizeControl = ({
  className,
  page_sizes,
  selected_page_size,
  on_change_page_size
}) => {

  return (
    <span className={className}>
      <Label className={cn(cs.font_weight_normal, cs.white_space_nowrap)}>
        Page size
      </Label>
      <ValueDropdownSelector
        className='ms-2'
        values={page_sizes}
        selected_value={selected_page_size}
        on_change_value={on_change_page_size}
        right={true}
        buttonClassName={s.button}
        menuClassName={s.menu}
      />
    </span>
  )
}

export default PageSizeControl