import React from 'react'
import { withRouter } from 'react-router-dom'

import { CLASSIFIER_SUBSCRIPTIONS, REPORT_BASED_SUBSCRIPTIONS, CLASSIFIERS, REPORTS } from '../../constants/paths.js'
import { has_old_weekly_classifier_alerts, is_view_only_user } from '../../utils/user_permissions.js'
import { withUser } from '../UserContext.js'
import { NavTab, NavWrapper } from '../widgets/NavTab.js'

const SubscriptionsNavigation = ({history, current_page, user}) => {

  function on_click(page_name) {
    history.push(page_name)
  }

  return (
    <NavWrapper>
      <NavTab is_active={current_page === REPORTS} on_click={() => on_click(REPORT_BASED_SUBSCRIPTIONS)}>
        Report-based alerts
      </NavTab>
      { has_old_weekly_classifier_alerts(user) && !is_view_only_user(user) &&
        <NavTab className='ms-4' is_active={current_page === CLASSIFIERS} on_click={() => on_click(CLASSIFIER_SUBSCRIPTIONS)}>
          Weekly classifier alerts
        </NavTab>
      }
    </NavWrapper>
  )
}

export default withUser(withRouter(SubscriptionsNavigation))