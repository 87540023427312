import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import { IS_PAGINATABLE } from '../../../utils/classifier_group_utils.js'

import RadioStatic from '../../widgets/RadioStatic.js'
import CheckboxStatic from '../../widgets/CheckboxStatic.js'
import SearchBar from '../../widgets/SearchBar.js'
import IndirectlySelectedHint from '../../widgets/IndirectlySelectedHint.js'

import Classifiers from '../../classifiers/Classifiers.js'

import s from './ClassifierGroupDisplay.module.scss'

const ClassifierGroupDisplay = (
  {
    classifiers_group,
    selected_classifiers,
    selected_indirectly,
    update_selection_handler,
    prohibit_parent_level_selection,
    is_selection_permitted,
    unpermitted_selection_handler,
    is_single_choice,
    post_label,
    hide_user_taxonomy_edit_link,
    className
  }) => {
  const search_input_ref = useRef()

  const [search_phrase, set_search_phrase] = useState(null)

  useEffect(() => {
    search_input_ref.current.focus()
  }, [])

  const CheckIcon = is_single_choice ? RadioStatic : CheckboxStatic

  const {[IS_PAGINATABLE]: is_paginatable } = classifiers_group

  const has_selected_indirectly = (selected_indirectly || []).length > 0

  return (
    <div className={cn('p-2', s.classifiers_group_wrapper, className)}>
      <SearchBar
        placeholder={'Filter by keyword'}
        search_input_ref={search_input_ref}
        search_input={search_phrase || ''}
        on_change_search_input={set_search_phrase}
        no_enter_trigger={true}
        is_search_valid={true}
        autofocus={true}
        textAreaClassName={s.search_input}
      />

      <Classifiers
        classifiers_data={classifiers_group}
        update_selection_handler={update_selection_handler}
        selected_classifiers={selected_classifiers}
        selected_indirectly={selected_indirectly}
        prohibit_parent_level_selection={prohibit_parent_level_selection}
        is_selection_permitted={is_selection_permitted}
        unpermitted_selection_handler={unpermitted_selection_handler}
        search_phrase={search_phrase || ''}
        post_label={post_label}
        check_icon={<CheckIcon is_checked={true} />}
        uncheck_icon={<CheckIcon is_checked={false} is_disabled={!is_selection_permitted} />}
        should_exclude_if_partial_node_clicked={true}
        show_heading={false}
        hide_user_taxonomy_edit_link={hide_user_taxonomy_edit_link}
        className={cn(['p-2', s.classifiers_group_display, {[s.classifiers_group__paginatable]: is_paginatable}])}
      />

      {has_selected_indirectly &&
        <IndirectlySelectedHint className='my-2' />
      }
    </div>
  )
}

export default ClassifierGroupDisplay