import React from 'react'
import { DropdownItem } from 'reactstrap'
import _ from 'underscore'
import cn from 'classnames'

import { CUSTOM_SCORE_RANGE } from '../model/filters.js'
import BaseDropdown from '../../widgets/BaseDropdown.js'
import Label from '../../widgets/Label.js'
import LabelledSlider from '../../LabelledSlider.js'

import cs from '../../cipher_styles.module.scss'
import s from './ScoreFilterControl.module.scss'

const FilterDropdownItem = ({ filter, on_click, is_selected, children }) => {
  const { name, short_name, disabled } = filter || {}
  const name_clean = short_name || name

  return (
    <DropdownItem onClick={on_click} disabled={disabled} toggle={false} active={is_selected}>
      {name_clean}
      {children}
    </DropdownItem>
  )
}

/**
 * @param {} className
 * @param {} filter_groups An array of group objects, where each has 'name' and 'children' properties. Each child item has 'id' and 'name' properties
 * @param {} selected_filter_name
 * @param {} on_change_filter_id
 */
const ScoreFilterControl = (
  {
    className,
    filter_groups,
    selected_filter_id,
    on_change_filter_id,
    custom_score_range,
    on_change_custom_score_range,
    title
  }) => {

  const all_filters = filter_groups.reduce((acc, group) => {
    const { children } = group

    const subfilters = children.reduce((acc, filter) => {
      const { subfilters } = filter
      if (subfilters) {
        return [...acc, ...subfilters]
      }
      return acc
    }, [])

    return [...acc, ...children, ...subfilters]
  }, [])

  const selected_filter = _.find(all_filters, filter => filter.id === selected_filter_id)

  if (!selected_filter) return null

  const { name } = selected_filter

  return (
    <span className={className}>
      <Label className={cs.font_weight_normal}>{title || 'Show'}</Label>

      <BaseDropdown
        className='ms-2'
        label={name}
        right={false}
        buttonClassName={s.button}
        menuClassName={s.menu}
        labelClassName={s.button_label}
      >
        {filter_groups.map(({ name: group_name, children }, i) => {
          // for each group of filter ids, show the filter options and a Divider line
          return (
            <span key={i}>
              { group_name != null &&
                <>
                  {i !== 0 && <DropdownItem divider />}
                  <DropdownItem header >
                    {group_name}
                  </DropdownItem>
                </>
              }
              {children.map((filter, j) => {
                const { id, subfilters = [] } = filter

                if (subfilters.length > 0) {
                  return <div className='d-flex' key={j}>
                    <FilterDropdownItem key={j} filter={filter} is_selected={selected_filter_id === id} on_click={() => on_change_filter_id(id)}/>
                    {subfilters.map((subfilter, k) => {
                      const { id } = subfilter
                      return (
                        <FilterDropdownItem
                          key={`${j}_${k}`}
                          filter={subfilter}
                          is_selected={selected_filter_id === id}
                          on_click={() => on_change_filter_id(id)}
                        />
                      )
                    })}
                  </div>
                }

                return (
                  <FilterDropdownItem
                    key={j}
                    filter={filter}
                    on_click={() => on_change_filter_id(id)}
                    is_selected={selected_filter_id === id}
                  >
                    {(id === CUSTOM_SCORE_RANGE) &&
                      <div className='d-flex align-items-center ms-3'>
                        <LabelledSlider
                          value={custom_score_range}
                          extent={[0,1]}
                          handleChange={(values) => on_change_custom_score_range(values)}
                          step={0.1}
                          hide_label
                          min_distance={0.1}
                        />
                        <div className={cn('ms-3', s.slider_label)}>
                          <span>{custom_score_range[0]}</span>&nbsp;to&nbsp;<span>{custom_score_range[1]}</span>
                        </div>
                      </div>
                    }
                  </FilterDropdownItem>
                )
              })}
            </span>
          )
        })}
      </BaseDropdown>

    </span>
  )
}

export default ScoreFilterControl