import React from 'react'
import _ from 'underscore'
import { UncontrolledDropdown, DropdownMenu, DropdownItem } from 'reactstrap'
import cn from 'classnames'

import ClearableSearchInput from '../../widgets/ClearableSearchInput.js'
import ScrollableList from '../../widgets/ScrollableList.js'
import TextLink from '../../widgets/TextLink.js'
import { PlusIcon, TagsIcon } from '../../widgets/IconSet.js'
import { filter_on_search_string } from '../utils/sort_and_filter_utils.js'
import { InfoPopover } from '../../widgets/Tooltip.js'
import { WRAPPER_ELEMENT_PARENT } from '../../../constants/tooltip.js'
import { CheckboxAndLabel } from '../../widgets/CheckboxAndLabel.js'

import s from './TagSelectorDropdown.module.scss'
import cs from '../../cipher_styles.module.scss'

export const TagSelectorDropdown = (
  {
    toggle_button,
    is_open,
    tags,
    selected_tag_ids,
    partial_tag_ids,
    search_string,
    handle_update_search_string,
    is_showing_selected,
    toggle_is_showing_selected,
    handle_create_new_tag,
    handle_manage_tags,
    handle_select_tag,
    handle_edit_tag_selections,
    is_newly_saved_report_tooltip_open,
    on_tooltip_hide,

    className
  }
) => {

  const tags_to_filter = is_showing_selected ? tags.filter(tag => _.contains(selected_tag_ids, tag.tag_id)) : tags

  const filtered_tags = filter_on_search_string(tags_to_filter, search_string, 'name')
  const show_search_input = tags.length > 3

  function render_tag(tag) {
    const {tag_id, name} = tag || {}

    const is_checked = _.contains((selected_tag_ids || []), tag_id)
    const is_partial = _.contains((partial_tag_ids || []), tag_id)

    return (
      <CheckboxAndLabel
        key={tag_id}
        is_checked={is_checked}
        is_partial={is_partial}
        on_click={() => handle_select_tag(tag, !(is_checked || is_partial))}
        label={name}
      />
    )
  }

  return (
    <UncontrolledDropdown isOpen={is_open} toggle={handle_edit_tag_selections} className={cn('d-flex', className)}>

      <div className='d-flex'>
        {is_newly_saved_report_tooltip_open &&
          <InfoPopover
            no_toggler={true}
            static_trigger={<span></span>}
            visible_by_default={true}
            placement='left'
            is_in_modal={true}
            contentClassName='px-1 py-0'
            interactive={true}
            on_hide={on_tooltip_hide}
            wrapper={WRAPPER_ELEMENT_PARENT}
          >
            <div className={cn('d-flex flex-nowrap', s.tooltip)}><div className='me-1'>Report saved.</div><TextLink onClick={handle_edit_tag_selections}>Add tags?</TextLink></div>
          </InfoPopover>
        }

        { toggle_button }
      </div>
      <DropdownMenu className={cn(s.tag_dropdown_menu, 'w-100')} end>
        <DropdownItem header>Report tags</DropdownItem>
        <div className='px-2 d-flex flex-wrap'>
          {handle_create_new_tag &&
            <TextLink no_decoration onClick={handle_create_new_tag}>
              <PlusIcon/><span className={cn('ms-1', cs.underline_on_hover)}>New</span>
            </TextLink>
          }
          {handle_manage_tags &&
            <TextLink no_decoration className='ms-auto' onClick={handle_manage_tags}>
              <TagsIcon /><span className={cn('ms-1', cs.underline_on_hover)}>Manage</span>
            </TextLink>
          }
        </div>
        {show_search_input &&
          <>
            <ClearableSearchInput
              containerClassName={cn(s.tag_search_input, 'mx-2', 'my-2')}
              value={search_string}
              placeholder={'Search by name...'}
              auto_focus={true}
              handle_change={(search_string => handle_update_search_string(search_string))}
              show_clear={true}
            />
            {toggle_is_showing_selected &&
              <TextLink element='div' className='px-2 mb-1' onClick={toggle_is_showing_selected}>
                {is_showing_selected ? 'Show all' : 'Show selected'}
              </TextLink>
            }
          </>
        }
        <ScrollableList className='px-2'>
          <div className={s.tags_container}>
            {filtered_tags.map((tag) => (
              render_tag(tag)
            ))}
            {filtered_tags && filtered_tags.length === 0 &&
              <span>No {is_showing_selected ? 'selected tags' : 'matches'}</span>
            }
          </div>
        </ScrollableList>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}