import React from 'react'
import cn from 'classnames'

import s from './ChartAndLegendHolder.module.scss'

const ChartAndLegendHolder = ({ chart, legend, is_report_deck }) => {

  if (!legend) {
    // No legend
    return (
      <>
        { is_report_deck &&
          <div className={s.no_legend_spacer} />
        }
        {chart}
      </>
    )
  }

  if (is_report_deck) {
    return (
      <div className='flex-md-nowrap justify-content-center'>
        <div className='d-md-flex justify-content-lg-end'>
          {legend}
        </div>
        <div>
          {chart}
        </div>
      </div>
    )
  }

  return (
    <div className='d-lg-flex flex-lg-nowrap justify-content-center'>
      <div className={cn('d-lg-flex justify-content-lg-end')}>
        {chart}
      </div>
      <div className={cn('d-lg-flex justify-content-lg-start')}>
        {legend}
      </div>
    </div>
  )
}

export default ChartAndLegendHolder