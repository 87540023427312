import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import _ from 'underscore'
import { withRouter } from 'react-router-dom'

import { DashboardSmallTile } from './DashboardTile.js'
import ErrorBody from '../ErrorBody.js'
import Spinner from '../widgets/Spinner.js'
import { fetch_report_history } from '../../utils/report_history_utils.js'
import { get_user_project_history } from '../../utils/project_and_versioning_utils'
import { get_recent_history_help_url } from '../../utils/help_utils.js'
import { TileHelpLink } from './TileHelpLink.js'
import { HISTORY } from '../../constants/paths.js'
import { HistoryListDisplay } from './DashboardDisplays.js'
import { DashboardTileInner } from './DashboardTileInner.js'

import s from './ReportHistoryPanel.module.scss'

const MAX_RECENT_HISTORY = 5
const IGNORE_FAILED_REPORTS = true
const FETCH_REPORT_TAGS = false
const EXCLUDE_EVAL_REPORTS = true

const ReportHistoryPanel = ({history}) => {

  const [is_fetching, set_is_fetching] = useState(true)
  const [recent_history, set_recent_history] = useState(null)
  const [fetching_recent_reports_error, set_fetching_recent_reports_error] = useState(null)

  useEffect(() => {
    Promise.all([
      fetch_report_history(MAX_RECENT_HISTORY, IGNORE_FAILED_REPORTS, FETCH_REPORT_TAGS, EXCLUDE_EVAL_REPORTS),
      get_user_project_history()]
    )
      .then(([reports, projects]) => {
        const items = _.union(reports, projects)
          .sort((a,b) => (a.last_viewed || a.created_at) < (b.last_viewed || b.created_at) ? 1 : -1)
          .slice(0, MAX_RECENT_HISTORY)
        set_recent_history(items)
        set_is_fetching(false)
      })
      .catch(error => {
        set_fetching_recent_reports_error(error)
        set_is_fetching(false)
      })
  }, [])

  const is_new_user = !is_fetching && !fetching_recent_reports_error && recent_history && (recent_history.length === 0)

  return (
    <DashboardSmallTile
      title='Report history'
      on_click={is_new_user ? null : () => history.push(HISTORY)}
      hint={<TileHelpLink help_url={get_recent_history_help_url()}/>}
    >
      {is_fetching &&
        <div className='text-center mt-2'>
          <Spinner className='spinner-pale' />
        </div>
      }
      {fetching_recent_reports_error &&
        <DashboardTileInner className={s.navigation}>
          <ErrorBody
            error={fetching_recent_reports_error}
            context='fetching recent reports'
            show_details_in_modal={true}
          />
        </DashboardTileInner>
      }

      {!is_fetching && !fetching_recent_reports_error &&
        <DashboardTileInner className={s.navigation}>
          {!is_fetching && !fetching_recent_reports_error &&
            <div className={cn(s.wrapper, 'my-3')}>

              <HistoryListDisplay
                history={history}
                list_items={recent_history}
              />

              { is_new_user &&
                <div>You don't have any report history yet</div>
              }
            </div>
          }
        </DashboardTileInner>
      }
    </DashboardSmallTile>
  )
}

export default withRouter(ReportHistoryPanel)