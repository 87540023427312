import React from 'react'
import cn from 'classnames'
import { DropdownToggle } from 'reactstrap'

import DropdownChevron from './DropdownChevron.js'

import s from './SelectDropdownToggleStatic.module.scss'

/**
 * Toggle for a react-strap Dropdown menu.
 * i.e. it is assumed this component will be used in the context of a
 *      react-strap Dropdown or UncontrolledDropdown.
 *      see https://reactstrap.github.io/components/dropdowns/
 * Shows a red "v" (chevron) to the right of the label.
 * Size can be overriden by setting "font-size" in the css (via className).
 * Likewise with background colour.
 * @param {} disabled if set, disables the dropdown
 */
const SelectDropdownToggleStatic = ({ className, labelClassName, chevronClassName, disabled, tag, hide_chevron, children, is_dropdown_open }) => {

  return (
    <DropdownToggle
      tag={tag || 'div'}
      disabled={disabled}
      className={cn(s.block, {[s.__disabled]: disabled}, className)}
    >
      <span className='d-flex justify-content-between my-auto'>
        <span className={cn(s.label, labelClassName)}>{children}</span>
        {!hide_chevron &&
          <DropdownChevron
            is_down_arrow={!is_dropdown_open}
            className={cn('ms-1', chevronClassName)
          } />
        }
      </span>

    </DropdownToggle>
  )
}

export default SelectDropdownToggleStatic