import cn from 'classnames'
import React, {useEffect, useState} from 'react'
import _ from 'underscore'
import {Autocomplete, TextField} from '@mui/material'

import {is_aistemos} from '../../../utils/user_permissions.js'
import Modal from '../../widgets/Modal.js'
import {PrimaryButton} from '../../widgets/Button.js'
import { withUser } from '../../UserContext.js'
import {create_project, get_user_project_history} from '../../../utils/project_and_versioning_utils'
import {pluralise_text} from '../../../utils/utils.js'
import { Project } from '../../project_management/model/project'
import CreateProjectModal from './CreateProjectModal'
import Spinner from '../../widgets/Spinner.js'
import ErrorBody from '../../ErrorBody.js'


export interface AddToProjectModalProps{
  reports_count: number,
  on_hide: Function,
  on_add_to_project: Function,
  is_adding_reports_to_project: boolean,
  history?: any,
  user?: any
}

const AddToProjectModal = (props: AddToProjectModalProps) => {

  const add_to_project_button_label = `Add ${pluralise_text(props.reports_count, 'report')}`
  const new_proj_opt_id = 'new_proj'

  const [user_editable_projects, set_user_editable_projects] = useState<Project[]>([])
  const [is_loading_projects, set_is_loading_projects] = useState<boolean>(false)
  const [selected_project, set_selected_project] = useState<Project|null|undefined>(null)
  const [dropdown_selections, set_dropdown_selections] = useState<Project[]>([])
  const [is_create_project_modal_open, set_is_create_project_modal_open] = useState<boolean>(false)
  const [is_creating_project, set_is_creating_project] = useState<boolean>(false)
  const [new_project_id, set_new_project_id] = useState<string>('')

  const [error_adding_to_project, set_error_adding_to_project] = useState<Error|null>(null)


  const create_new_project_option: Project = {
    name: 'New project',
    project_id: new_proj_opt_id,
    created_at: '',
    permissions: {user: {}, group:{}},
    current_user_permissions: {view: true, edit: true},
    files_count: 0
  }

  function load_user_editable_projects() {
    set_is_loading_projects(true)
    return get_user_project_history()
      .catch(err => {
        set_error_adding_to_project(err)
        set_is_loading_projects(false)
        throw err
      })
      .then(projects => {
        set_user_editable_projects(_.filter(projects, (project: Project) => project.current_user_permissions.edit))
        set_is_loading_projects(false)
      })
  }

  function on_select_option(project: Project|null) {
    if (project && project.project_id === new_proj_opt_id) {
      set_is_create_project_modal_open(true)
    } else {
      set_selected_project(project)
    }
  }

  function on_create_new_project(name: string) {
    set_is_creating_project(true)
    create_project(name)
      .catch(err => {
        set_error_adding_to_project(err)
        set_is_creating_project(false)
        set_is_create_project_modal_open(false)
        throw err
      })
      .then(project_id => {
        load_user_editable_projects()
          .then(() => {
            set_new_project_id(project_id)
            set_is_creating_project(false)
            set_is_create_project_modal_open(false)
          })
      })
  }

  useEffect(() => {
    load_user_editable_projects()
  }, [])

  useEffect(() => {
    set_dropdown_selections([
      ...(is_aistemos(props.user) ? [create_new_project_option] : []),
      ...user_editable_projects
    ])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_editable_projects, props.user])

  useEffect(() => {
    if (new_project_id) {
      const new_proj = _.find(dropdown_selections, s => s.project_id === new_project_id)
      set_selected_project(new_proj)
      set_new_project_id('')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdown_selections])

  function render() : JSX.Element {
    return (
      <React.Fragment>
        {/* @ts-ignore */}
        <Modal
          on_hide={props.on_hide}
          title={`Add ${pluralise_text(props.reports_count, 'report')} to project`}
          close_label='Close'
          size='sm'
          primary_button={
            <PrimaryButton onClick={() => props.on_add_to_project(selected_project)}
                           title={add_to_project_button_label}
                           disabled={!selected_project || props.is_adding_reports_to_project}
            >
              {add_to_project_button_label}
            </PrimaryButton>
          }
          footer={props.is_adding_reports_to_project ? <Spinner/> : null}
        >
          {error_adding_to_project &&
            <ErrorBody
              error={error_adding_to_project}
              context={'adding reports to project'}
            />
          }
          {!error_adding_to_project &&
            <>
              <Autocomplete
                options={dropdown_selections}
                value={selected_project}
                multiple={false}
                freeSolo={false}
                filterSelectedOptions={true}
                onChange={(_event, selection) => on_select_option(selection)}
                disabled={is_loading_projects}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={'Select project'}
                    placeholder={'Project'}
                  />
                )}
                getOptionLabel={(option:Project) => option.name}
              />
              <div className={cn('mt-2')}>
                {is_loading_projects &&
                  <div className='d-flex'>
                    <Spinner size='sm'/>
                    <span className='ms-1'>Fetching project details...</span>
                  </div>
                }
                {!is_loading_projects &&
                  <>
                    {selected_project ? (
                      <div>The {pluralise_text(props.reports_count, 'report')} will be attached to {selected_project.name}</div>
                    ) : (
                      <div>Select a project to attach reports to.</div>
                    )}
                  </>
                }
              </div>
            </>
          }
        </Modal>

        {is_create_project_modal_open &&
          <CreateProjectModal
            on_hide={() => set_is_create_project_modal_open(false)}
            on_create_project={on_create_new_project}
            is_creating_project={is_creating_project}
          />
        }
      </React.Fragment>
    )
  }

  return render()
}

export default withUser(AddToProjectModal)