import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import _ from 'underscore'

import DefaultPageContainer from '../DefaultPageContainer.js'
import {
  RetryIcon,
  NewReportFromParamsIcon,
  ExternalLinkIcon,
  QueryReportDataIcon,
  MailIcon,
  InfoIcon,
  CheckIcon,
  SimpleErrorIcon
} from '../widgets/IconSet.js'
import { withUser } from '../UserContext.js'
import ConfirmModal from '../ConfirmModal.js'
import PageNotFound from '../PageNotFound.js'
import ErrorBody from '../ErrorBody.js'
import TextLink from '../widgets/TextLink.js'
import { MonitorCard } from './MonitorCard.js'
import ReportIdInput from './ReportIdInput.js'
import MonitorErrorDisplay from './MonitorErrorDisplay.js'
import MonitorResponsePanel from './MonitorResponsePanel.js'
import ReportDetailsModal from '../report_info/ReportDetailsModal.js'
import { InfoPopover } from '../widgets/Tooltip.js'

import { useToggle } from '../../hooks/general_hooks.js'
import { extract_internal_or_external_report_id } from '../../utils/report_url_utils.js'
import { fetch_choreo_status } from '../../utils/choreo_utils.js'
import { STATUS_STARTED, STATUS_FAILED, STATUS_QUEUED } from '../../model/report_tasks_and_statuses.js'
import { is_failed_status, is_final_status } from '../../utils/report_progress_utils.js'
import { fetch_report_summary_with_status } from '../../utils/report_reader_utils.js'
import { rerun_report_and_replace_id, new_report_from_existing, report_can_be_built_from_params } from '../../utils/report_builder_utils.js'
import { is_cipher_engineering } from '../../utils/user_permissions.js'
import { limit_long_text_with_ellipsis } from '../../utils/name_utils.js'
import { to_local_datetime, format_string_first_character_capitalised, get_as_map } from '../../utils/utils.js'
import {
  MONITOR,
  REPORT,
  RR_REPORT_INPUT,
  REPORT_READER_QUERY,
  RR_REPORT_BUILD_STATUS,
  RR_DATA_SUMMARY
} from '../../constants/paths.js'
import {
  fetch_report_lists_for_monitor,
  report_id_is_internal_id,
  find_report_by_internal_or_external_id,
  get_most_accurate_report_status,
  get_most_informative_user_name,
  estimate_cc_task_endtime,
  get_validated_input
} from '../../utils/report_monitor_utils.js'
import { get_user_info_by_id } from '../../utils/user_info.js'
import { is_not_found } from '../../utils/axios_utils.js'
import MonitorPageWrapper from './MonitorPageWrapper.js'

import s from './MonitorReport.module.scss'

const REPORT_TITLE_DISPLAY_LENGTH = 90
const FETCH_INTERVAL_TIME = 30 * 1000
const MAX_DISPLAYABLE_INPUT_CHARS = 500000
const SOURCE_FRONTEND_DB = 'Frontend.ReportCreated'

const MonitorReport = ({user, history}) => {
  const report_to_monitor_id = extract_internal_or_external_report_id(window.location.href)
  const [input_report_id, set_input_report_id] = useState(report_to_monitor_id || '')

  const [recently_failed_reports, set_recently_failed_reports] = useState([])
  const [failed_report_owners, set_failed_report_owners] = useState({})
  const [reports_in_progress, set_reports_in_progress] = useState([])

  const [report_owner, set_report_owner] = useState(null)
  const [report_metadata, set_report_metadata] = useState(null)
  const [report_progress, set_report_progress] = useState(null)
  const [report_input, set_report_input] = useState(null)
  const [report_input_is_long, set_report_input_is_long] = useState(false)
  const [report_build_status, set_report_build_status] = useState(null)
  const [report_data_summary, set_report_data_summary] = useState(null)

  const [show_full_metadata, set_show_full_metadata] = useState(false)
  const [show_full_progress, set_show_full_progress] = useState(false)
  const [show_full_input, set_show_full_input] = useState(false)
  const [show_full_build_status, set_show_full_build_status] = useState(false)
  const [show_full_data_summary, set_show_full_data_summary] = useState(false)

  const [is_confirm_rerun, toggle_is_confirm_rerun] = useToggle(false)
  const [show_details_modal, set_show_details_modal] = useState(false)

  const [error_fetching_report_lists, set_error_fetching_report_lists] = useState(null)
  const [error_fetching_report_metadata, set_error_fetching_report_metadata] = useState(null)
  const [error_fetching_report_input, set_error_fetching_report_input] = useState(null)
  const [error_fetching_report_progress, set_error_fetching_report_progress] = useState(null)
  const [error_retrying_failed_report, set_error_retrying_failed_report] = useState(null)
  const [error_fetching_report_build_status, set_error_fetching_report_build_status] = useState(null)
  const [error_fetching_report_data_summary, set_error_fetching_report_data_summary] = useState(null)

  const [polling_time, set_polling_time] = useState(FETCH_INTERVAL_TIME)

  function fetch_and_set_reports_to_display() {
    fetch_report_lists_for_monitor()
      .catch(err => {
        // something is up; best stop polling
        set_polling_time(null)
        set_error_fetching_report_lists(err)
        throw err
      })
      .then(([failed_reports_from_db, running_reports_from_choreo]) => {
        set_recently_failed_reports(failed_reports_from_db)
        set_reports_in_progress(running_reports_from_choreo)
      })
  }

  function monitor_report(report_id) {
    if (!report_id) {
      return
    }

    function fetch_and_set_report_input_and_status(report_id_to_fetch) {
      fetch_choreo_status(report_id_to_fetch)
        .then(progress => {
          set_report_progress(progress)
          set_error_fetching_report_progress(null)
        })
        .catch(error => {
          if (is_not_found(error)) {
            set_report_progress(null)
            set_error_fetching_report_progress(null)
            return
          }
          set_error_fetching_report_progress(error)
          set_report_progress(null)
        })

      get_validated_input(report_id_to_fetch)
        .then(validated_input => {
          const {report_input} = validated_input
          set_report_input(validated_input)
          set_report_input_is_long(JSON.stringify(report_input).length > MAX_DISPLAYABLE_INPUT_CHARS)
          set_error_fetching_report_input(null)
        })
        .catch(error => {
          set_error_fetching_report_input(error)
          set_report_input(null)
        })

      fetch_report_summary_with_status(report_id_to_fetch)
        .then(summary_and_status => {
          const {report_summary, build_status} = summary_and_status
          set_report_data_summary(report_summary)
          set_report_build_status(build_status)
          set_error_fetching_report_data_summary(null)
          set_error_fetching_report_build_status(null)
        })
        .catch(error => {
          if (is_not_found(error)) {
            set_report_data_summary(null)
            set_report_build_status(null)
            set_error_fetching_report_data_summary(null)
            set_error_fetching_report_build_status(null)
            return
          }
          set_error_fetching_report_data_summary(error)
          set_error_fetching_report_build_status(error)
          set_report_build_status(null)
          set_report_data_summary(null)
        })
    }

    set_error_retrying_failed_report(null)
    set_input_report_id(report_id)

    find_report_by_internal_or_external_id(report_id)
      .then(report_metadata => {
        const {internal_report_id} = report_metadata
        set_error_fetching_report_metadata(null)
        set_report_metadata(report_metadata)
        fetch_and_set_report_input_and_status(internal_report_id)
        if (report_metadata && report_metadata.owner_id) {
          get_user_info_by_id([report_metadata.owner_id])
            .then(report_owner_info => set_report_owner(report_owner_info ? report_owner_info[0] : null))
        } else {
          set_report_owner(null)
        }
      })
      .catch(error_fetching_report_metadata => {
        set_report_owner(null)

        if (!report_id_is_internal_id(report_id)) {
          set_error_fetching_report_metadata(error_fetching_report_metadata)
          // without the internal id we have no way to get any further progress info
          set_report_metadata(null)
          set_report_progress(null)
          set_report_input(null)
          set_report_build_status(null)
          set_report_data_summary(null)
          return
        }

        set_report_metadata({
          internal_report_id: report_id,
          title: 'Unknown report'
        })

        fetch_and_set_report_input_and_status(report_id)
      })
  }

  useEffect(() => {
    document.title = 'Classification: Report Monitor'
    fetch_and_set_reports_to_display()

  }, [])

  useEffect(() => {
    if (polling_time) {
      //set interval to refetch
      const report_list_refresh_interval = setInterval(fetch_and_set_reports_to_display.bind(null), polling_time)
      const report_monitor_refresh_interval = setInterval(monitor_report.bind(null, report_to_monitor_id), polling_time)

      // clean up after unmount
      return () => {
        clearInterval(report_list_refresh_interval)
        clearInterval(report_monitor_refresh_interval)
      }
    }
  }, [polling_time, report_to_monitor_id])

  useEffect(() => {
    set_error_fetching_report_metadata(null)
    set_error_fetching_report_input(null)
    set_error_fetching_report_progress(null)
    set_error_retrying_failed_report(null)
    set_error_fetching_report_build_status(null)
    set_error_fetching_report_data_summary(null)

    if (report_to_monitor_id) {
      monitor_report(report_to_monitor_id)
    }
  }, [report_to_monitor_id])

  useEffect(() => {
    if (recently_failed_reports && recently_failed_reports.length) {
      const owner_ids = _.uniq(recently_failed_reports.map(report => report.owner_id))
      get_user_info_by_id(owner_ids)
        .then(users_with_failed_reports => {
          const failed_report_owners_updated = get_as_map(users_with_failed_reports, 'id')
          set_failed_report_owners(failed_report_owners_updated)
        })
    }
  }, [recently_failed_reports])

  if (!is_cipher_engineering(user)) {
    // shouldn't be possible, but I suppose you can't be too careful...
    return (
      <PageNotFound location={`${MONITOR}${REPORT}`} />
    )
  }

  function do_new_report_from_existing() {
    const {report_type, external_report_id} = report_metadata
    new_report_from_existing(external_report_id, history, report_type)
    window.location.reload()
  }

  function do_rerun_failed_report() {
    const {internal_report_id, external_report_id} = report_metadata
    toggle_is_confirm_rerun()
    set_error_retrying_failed_report(null)

    rerun_report_and_replace_id(internal_report_id, external_report_id)
      .catch(err => {
        set_error_retrying_failed_report(err)
        toggle_is_confirm_rerun()
        throw err
      })
      .then(() => {
        // in case the internal id for the old, failed report is being used...
        update_report_to_monitor_id(external_report_id)
        window.location.reload()
      })
  }

  function update_report_to_monitor_id(report_id) {
    history.push(`${MONITOR}${REPORT}/${report_id}`)
  }

  const report_status = get_most_accurate_report_status(report_progress, report_build_status, report_metadata)
  const estimated_cc_task_end_time = estimate_cc_task_endtime(report_input, report_progress)

  const {external_report_id} = report_metadata || {}

  // external_report_id is only part of the report progress response if the user has opted in for a notification
  const user_notified_on_completion = report_progress && report_progress.external_report_id
  const report_has_finished = is_final_status(report_status)
  const report_is_failed_report = is_failed_status(report_status)

  const report_input_source_description = `GET {report reader}/${RR_REPORT_INPUT}/{internal_report_id}`
  const report_progress_source_description = 'GET {choreo}/report/{internal_report_id}/progress'
  const report_build_status_source_description = `GET {report_reader}/${RR_REPORT_BUILD_STATUS}/{internal_report_id}`
  const report_data_summary_source_description = `GET {report_reader}/${RR_DATA_SUMMARY}/{internal_report_id}`

  if (error_fetching_report_lists) {
    return (
      <DefaultPageContainer>
        <ErrorBody
          context='fetching lists of reports to monitor'
          error={error_fetching_report_lists}
        />
      </DefaultPageContainer>
    )
  }

  return (
    <MonitorPageWrapper monitor_page={REPORT} report_id={report_has_finished && !report_is_failed_report ? input_report_id : null}>

      {is_confirm_rerun &&
        <ConfirmModal
          title={'Rerun report and replace id - are you sure?'}
          on_cancel={toggle_is_confirm_rerun}
          on_confirm={() => do_rerun_failed_report()}
          confirm_label='Confirm'
        >
          If rerun, all report links including those in the report creator&#39;s history will point to IDs for the new (retrying/ retried) report.
          { user_notified_on_completion &&
            <div className='text-endbold'>This user has already been notified by email of the status of their report.</div>
          }
        </ConfirmModal>
      }

      <ReportDetailsModal
        is_open={show_details_modal}
        on_close={() => set_show_details_modal(false)}
        external_report_id={report_metadata ? report_metadata.external_report_id : null}
        internal_report_id={report_metadata ? report_metadata.internal_report_id : null}
        report_title={report_metadata ? report_metadata.title : null}
        created_at={report_metadata ? report_metadata.created_at : null}
        is_eval_report={report_metadata && report_metadata.evaluation_classifier_id}
      />

      <MonitorCard title='Report ID (external or internal)'>
        <ReportIdInput
          value={input_report_id}
          on_change={(new_value) => set_input_report_id(new_value)}
          button_text='Find'
          on_button_click={() => update_report_to_monitor_id(input_report_id)}
        />
      </MonitorCard>

      { error_fetching_report_metadata &&
        <MonitorCard title='Error fetching report metadata' source_description={SOURCE_FRONTEND_DB} is_error={true}>
          <MonitorErrorDisplay error={error_fetching_report_metadata} />
        </MonitorCard>
      }

      { error_retrying_failed_report &&
        <MonitorCard title='Error retrying failed report' is_error={true}>
          <MonitorErrorDisplay error={error_retrying_failed_report} />
        </MonitorCard>
      }

      { report_metadata &&
        <div>
          <MonitorCard
            title={limit_long_text_with_ellipsis(report_metadata.title, REPORT_TITLE_DISPLAY_LENGTH)}
            show_info_toggle={<TextLink disable={!report_progress || !report_input} no_decoration onClick={() => set_show_details_modal(true)}><InfoIcon/></TextLink>}
            className={report_is_failed_report ? s.failed_report_info : ''}
          >
            {estimated_cc_task_end_time &&
              <div>
                <span className={s.end_time_info}>should finish by: {estimated_cc_task_end_time}</span>
              </div>
            }
            <div>{report_owner ? get_most_informative_user_name(report_owner) : 'User not known'}</div>
            <div>{to_local_datetime(report_metadata.created_at)}</div>
            <div className='text-endbold'>
              <span>{report_status}</span>
              { user_notified_on_completion &&
                <span className='ms-1' title={`User ${report_has_finished ? 'has received' : 'will receive'} notification email`}>
                  <MailIcon/>
                </span>
              }
            </div>
            <div className={s.monitor_report_icons}>
              <TextLink
                className='me-2'
                onClick={() => toggle_is_confirm_rerun()}
                title={'Rerun'}
                no_decoration
                disable={!report_input || !external_report_id || !report_is_failed_report}
              >
                <RetryIcon/>
              </TextLink>

              <TextLink
                className='me-2'
                onClick={() => do_new_report_from_existing()}
                title={'Copy parameters'}
                no_decoration
                disable={!report_input || !external_report_id || !report_can_be_built_from_params(report_metadata.report_type, report_status)}
              >
                <NewReportFromParamsIcon />
              </TextLink>

              <TextLink
                className='me-2'
                element={Link}
                to={`${REPORT}/${external_report_id}`}
                disable={!external_report_id || report_is_failed_report}
                target='_blank'
                no_decoration
                title={'Open report'}
              >
                <ExternalLinkIcon />
              </TextLink>

              <TextLink
                className='me-2'
                element={Link}
                to={`${MONITOR}${REPORT_READER_QUERY}?report_id=${report_metadata.internal_report_id}`}
                disable={report_is_failed_report || !report_has_finished}
                target='_blank'
                no_decoration
                title={'Query report data'}
              >
                <QueryReportDataIcon />
              </TextLink>
            </div>
          </MonitorCard>

          <MonitorCard
            title='Report metadata'
            source_description={SOURCE_FRONTEND_DB}
            toggle_show_more={set_show_full_metadata}
            is_show_more={show_full_metadata}
          >
            <MonitorResponsePanel>
              {JSON.stringify(report_metadata, null, '  ')}
            </MonitorResponsePanel>
          </MonitorCard>

          { error_fetching_report_progress &&
            <MonitorCard
              title='Error fetching report progress'
              source_description={report_progress_source_description}
              toggle_show_more={set_show_full_progress}
              is_show_more={show_full_progress}
            >
              <MonitorErrorDisplay error={error_fetching_report_progress} />
            </MonitorCard>
          }

          { report_progress &&
            <MonitorCard
              title='Choreo progress'
              source_description={report_progress_source_description}
              toggle_show_more={set_show_full_progress}
              is_show_more={show_full_progress}
              data_to_download={report_progress}
              download_filename={`choreo_progress_${report_metadata.internal_report_id}`}
            >
              <pre className='my-0'>{'{'}</pre>
              { report_progress &&
                _.keys(report_progress).map(key => {
                  switch (key) {
                    case 'status':
                      return <pre key={key} className={`${get_className_by_task_status(report_progress[key])} my-0`}>{`  "${key}": "${report_progress[key]}"`}</pre>
                    case 'tasks':
                      return (
                        <div key={key}>
                          <pre className='my-0'>{'  "tasks": ['}</pre>
                          <div className={s.task_block}>
                            { report_progress[key].map(
                              (task, i) => (
                                <MonitorResponsePanel key={i} className={`${get_className_by_task_status(task.status)} my-0`}>
                                  {JSON.stringify(task, null, '  ') + (i === report_progress[key].length - 1 ? '' : ',')}
                                </MonitorResponsePanel>)
                            )}
                          </div>
                          <MonitorResponsePanel className='my-0'>{'  ]'}</MonitorResponsePanel>
                        </div>
                      )
                    default:
                      return <MonitorResponsePanel key={key} className='my-0'>{`  "${key}": "${report_progress[key]}"`}</MonitorResponsePanel>
                  }
                })
              }
              <pre>{'}'}</pre>
            </MonitorCard>
          }

          { error_fetching_report_input &&
            <MonitorCard
              title='Error fetching report input'
              source_description={report_input_source_description}
              is_error={true}
              toggle_show_more={set_show_full_input}
              is_show_more={show_full_input}
            >
              <MonitorErrorDisplay error={error_fetching_report_input} />
            </MonitorCard>
          }

          { report_input &&
            <MonitorCard
              title='Report input'
              source_description={report_input_source_description}
              toggle_show_more={set_show_full_input}
              toggle_disabled_reason={report_input_is_long ? 'too much data to show' : null}
              is_show_more={!report_input_is_long && show_full_input}
              data_to_download={report_input}
              download_filename={`report_input_${report_metadata.internal_report_id}`}
            >
              <div>{report_input.validation_message.is_valid}</div>
              <div className='d-flex my-0'>
                <div className='w-50'>
                  <div className={s.report_input_label}>
                    Report input from efs
                    {!report_input.translated_input && report_input.validation_message &&
                      <span className='ms-1'>
                        {report_input.validation_message.is_valid &&
                          <span title='report input is valid'><CheckIcon/></span>
                        }
                        {!report_input.validation_message.is_valid &&
                          <InfoPopover
                            toggler={<SimpleErrorIcon/>}
                            placement='right'
                          >
                            <span>{report_input.validation_message.message}</span>
                          </InfoPopover>
                        }
                      </span>
                    }
                  </div>
                  <MonitorResponsePanel className='my-0'>{JSON.stringify(report_input.report_input, null, '  ')}</MonitorResponsePanel>
                </div>
                {report_input.translated_input &&
                  <div className={s.translated_input}>
                    <div className={s.report_input_label}>
                      Translated input
                      <span className='ms-1'>
                        {report_input.validation_message.is_valid &&
                          <span title='report input is valid'><CheckIcon/></span>
                        }
                        {!report_input.validation_message.is_valid &&
                          <InfoPopover
                            toggler={<SimpleErrorIcon/>}
                            placement='right'
                          >
                            <span>{report_input.validation_message.message}</span>
                          </InfoPopover>
                        }
                      </span>
                    </div>
                    <MonitorResponsePanel className='my-0'>{JSON.stringify(report_input.translated_input, null, '  ')}</MonitorResponsePanel>
                  </div>
                }
              </div>
            </MonitorCard>
          }

          { report_build_status &&
            <MonitorCard
              title='Build status'
              source_description={report_build_status_source_description}
              toggle_show_more={report_is_failed_report ? null : set_show_full_build_status}
              is_show_more={report_is_failed_report || show_full_build_status}
            >
              <pre className='my-0'>{'{'}</pre>
              { report_build_status &&
                _.pairs(report_build_status).map((line, i) => {
                  const [key, value] = line
                  return (
                    <MonitorResponsePanel key={i} className={`${(_.contains(['status', 'output'], key) && report_is_failed_report) ? s.status_failed : ''} my-0`}>
                      {`  ${JSON.stringify(key)}: ${JSON.stringify(value)}` + (i === report_build_status.length - 1 ? '' : ',')}
                    </MonitorResponsePanel>
                  )
                })
              }
              <pre className='my-0'>{'}'}</pre>
            </MonitorCard>
          }

          { error_fetching_report_build_status &&
            <MonitorCard
              title='Error fetching report build status'
              source_description={report_build_status_source_description}
              is_error={true}
              toggle_show_more={set_show_full_build_status}
              is_show_more={show_full_build_status}
            >
              <MonitorErrorDisplay error={error_fetching_report_build_status} />
            </MonitorCard>
          }

          { error_fetching_report_data_summary &&
            <MonitorCard
              title='Error fetching report data summary'
              source_description={report_data_summary_source_description}
              is_error={true}
              toggle_show_more={set_show_full_data_summary}
              is_show_more={show_full_data_summary}
            >
              <MonitorErrorDisplay error={error_fetching_report_data_summary} />
            </MonitorCard>
          }

          { report_data_summary &&
            <MonitorCard
              title='Data summary'
              source_description={report_data_summary_source_description}
              toggle_show_more={set_show_full_data_summary}
              is_show_more={show_full_data_summary}
              data_to_download={report_data_summary}
              download_filename={`report_data_summary_${report_metadata.internal_report_id}`}
            >
              <MonitorResponsePanel>{JSON.stringify(report_data_summary, null, '  ')}</MonitorResponsePanel>
            </MonitorCard>
          }
        </div>
      }

      {reports_in_progress &&
        <MonitorCard title='Reports in progress' source_description={'GET {choreo}/report'}>
          {reports_in_progress.length === 0 && <span>None</span>}
          {reports_in_progress.map(report => {
            const {report_id, created_at, report_name, status} = report
            return (
              <div key={report_id}>
                <span className='me-2'>{to_local_datetime(created_at)}</span>
                <TextLink
                  className='me-2'
                  element={Link}
                  to={`${MONITOR}${REPORT}/${report_id}`}
                  title={'Monitor report'}
                >{limit_long_text_with_ellipsis((report_name || 'Unknown report'), REPORT_TITLE_DISPLAY_LENGTH)}</TextLink>
                <span className='me-2'>{format_string_first_character_capitalised(status)}</span>
              </div>)
          })}
        </MonitorCard>
      }

      { recently_failed_reports &&
        <MonitorCard title='Recent report failures' source_description={SOURCE_FRONTEND_DB}>
          {recently_failed_reports.map(report => {
            const {external_report_id, created_at, title, owner_id} = report
            const report_owner = failed_report_owners ? failed_report_owners[owner_id] : null
            const owner_name = report_owner ? get_most_informative_user_name(report_owner) : null
            return (
              <div key={external_report_id}>
                <span className='me-2'>{to_local_datetime(created_at)}</span>
                <TextLink
                  className='me-2'
                  element={Link}
                  to={`${MONITOR}${REPORT}/${external_report_id}`}
                  title={'Investigate report failure'}
                >{limit_long_text_with_ellipsis(title, REPORT_TITLE_DISPLAY_LENGTH)}</TextLink>
                <span className='me-2'>{owner_name}</span>
              </div>)
          })}
        </MonitorCard>
      }
    </MonitorPageWrapper>
  )
}

function get_className_by_task_status(status) {
  switch(status) {
    case STATUS_QUEUED:
      return s.status_waiting
    case STATUS_STARTED:
      return s.status_started
    case STATUS_FAILED:
      return s.status_failed
    default:
      return ''
  }
}

export default withUser(withRouter(MonitorReport))