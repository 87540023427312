import React from 'react'

import ContainerFixedWidth from './ContainerFixedWidth.js'
import { track_visit_event } from '../utils/tracking_utils.js'
import { NOT_FOUND } from '../constants/paths.js'

const PageNotFound = ({location}) => {
  const { pathname } = location || {}
  document.title = 'Classification: Page not found'
  track_visit_event(`page="page-not-found" path="${pathname}"`)

  return (
    <ContainerFixedWidth>
      <div className='my-3'>
        The page you have requested {(pathname !== NOT_FOUND) ? pathname : ''} couldn&#39;t be found.
      </div>
    </ContainerFixedWidth>
  )
}

export default PageNotFound