import React from 'react'

import TextLink from '../widgets/TextLink.js'
import { PropagateUpIcon } from '../widgets/IconSet.js'

const AddFamilyToInput = ({family_id, cipher_family_id, similar_search_input, on_click }) => {
  return (
    <TextLink
      onClick={(e) => {
        e.stopPropagation()
        on_click(cipher_family_id)
      }}
      disable={(similar_search_input || '').indexOf(family_id) > -1}
      title={`Add ${cipher_family_id} to search input`}
      no_decoration
    >
      <PropagateUpIcon />
    </TextLink>
  )
}

export default AddFamilyToInput