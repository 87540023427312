import Keycloak from 'keycloak-js'
import axios from 'axios'

import {
  is_aws_dev_environment,
  is_dev_environment /*is_ln_environment, is_ln_preprod_environment*/
} from './utils.js'

const KC_TOKEN = 'kc_token'
const KC_REFRESH_TOKEN = 'kc_refresh_token'

const DEV_AUTH_URL     = 'https://devlogin.cipher.ai/auth/'
const PROD_LN_AUTH_URL = 'https://login.aws.cipher.ai/auth/'
const DEV_AWS_AUTH_URL = 'https://login-dev.aws.cipher.ai/auth/'

const CUSTOM_AUTH_URL  = process.env['REACT_APP_AUTH_URL']

const REACT_APP_AUTH_REALM     = process.env['REACT_APP_AUTH_REALM']     || 'cipher'
const REACT_APP_AUTH_CLIENT_ID = process.env['REACT_APP_AUTH_CLIENT_ID'] || 'cipher3'

// TODO: use WAM OIDC endpoints (awaiting clientID / secret etc from Evan)
// const TECH_DISCOVERY_AUTH_CLIENT_ID            = process.env['REACT_APP_TECH_DISCOVERY_AUTH_CLIENT_ID']            || '54629e20-ced3-4c57-99f2-33aca1789ddb'
// const TECH_DISCOVERY_AUTH_AUTH_ENDPOINT        = process.env['REACT_APP_TECH_DISCOVERY_AUTH_AUTH_ENDPOINT']        || 'https://login.lexisnexis.com/oauth2/auth'
// const TECH_DISCOVERY_AUTH_TOKEN_ENDPOINT       = process.env['REACT_APP_TECH_DISCOVERY_AUTH_TOKEN_ENDPOINT']       || 'https://login.lexisnexis.com/oauth2/token'
// const TECH_DISCOVERY_AUTH_END_SESSION_ENDPOINT = process.env['REACT_APP_TECH_DISCOVERY_AUTH_END_SESSION_ENDPOINT'] || 'https://login.lexisnexis.com/oauth2/sessions/logout'
// const TECH_DISCOVERY_AUTH_USERINFO_ENDPOINT    = process.env['REACT_APP_TECH_DISCOVERY_AUTH_USERINFO_ENDPOINT']    || 'https://login.lexisnexis.com/userinfo'

// TEMP: for now, use Prod Keycloak (OIDC)
const TECH_DISCOVERY_AUTH_CLIENT_ID            = process.env['REACT_APP_TECH_DISCOVERY_AUTH_CLIENT_ID']            || 'cipher3'
const TECH_DISCOVERY_AUTH_AUTH_ENDPOINT        = process.env['REACT_APP_TECH_DISCOVERY_AUTH_AUTH_ENDPOINT']        || 'https://login.aws.cipher.ai/auth/realms/cipher/protocol/openid-connect/auth'
const TECH_DISCOVERY_AUTH_TOKEN_ENDPOINT       = process.env['REACT_APP_TECH_DISCOVERY_AUTH_TOKEN_ENDPOINT']       || 'https://login.aws.cipher.ai/auth/realms/cipher/protocol/openid-connect/token'
const TECH_DISCOVERY_AUTH_END_SESSION_ENDPOINT = process.env['REACT_APP_TECH_DISCOVERY_AUTH_END_SESSION_ENDPOINT'] || 'https://login.aws.cipher.ai/auth/realms/cipher/protocol/openid-connect/logout'
const TECH_DISCOVERY_AUTH_USERINFO_ENDPOINT    = process.env['REACT_APP_TECH_DISCOVERY_AUTH_USERINFO_ENDPOINT']    || 'https://login.aws.cipher.ai/auth/realms/cipher/protocol/openid-connect/userinfo'

export const REFRESH_JWT_ERROR_MESSAGE = 'refresh jwt failed - maybe max_SSO_session_length or jwt_token have expired'

function get_auth_url() {
  if (CUSTOM_AUTH_URL) {
    return CUSTOM_AUTH_URL
  }

  const in_dev_env = is_dev_environment()
  if (in_dev_env) {
    return DEV_AUTH_URL
  }

  if (is_aws_dev_environment()) {
    // todo: this should replace the above call when we switch to the new dev env
    return DEV_AWS_AUTH_URL
  }

  return PROD_LN_AUTH_URL // login.aws.cipher.ai
}

export function create_keycloak_client(is_tech_discovery) {
  const auth_url = get_auth_url()

  const kc = new Keycloak({

    clientId: (is_tech_discovery ? TECH_DISCOVERY_AUTH_CLIENT_ID: REACT_APP_AUTH_CLIENT_ID),

    // Keycloak config
    realm:    REACT_APP_AUTH_REALM,
    url:      auth_url,

    // TechDiscovery config
    ...(is_tech_discovery ? {
      oidcProvider: {
        authorization_endpoint: TECH_DISCOVERY_AUTH_AUTH_ENDPOINT,
        token_endpoint:         TECH_DISCOVERY_AUTH_TOKEN_ENDPOINT,
        end_session_endpoint:   TECH_DISCOVERY_AUTH_END_SESSION_ENDPOINT,
        userinfo_endpoint:      TECH_DISCOVERY_AUTH_USERINFO_ENDPOINT,
      }
    } : {})

  })

  return kc
}

export function init_keycloak(kc, is_tech_discovery) {
  const { token, refresh_token } = get_from_local_storage()
  return kc.init({
    onLoad: 'check-sso',
    token,
    refreshToken: refresh_token,
    timeSkew: 0,
    checkLoginIframe: false,// disable iframe as it can make local/dev/staging inaccessible on Safari
                            // (as iframe/parent must be on same protocol, i.e. both http or both https).
    promiseType: 'native',

    // Currently WAM configuation only supports explicit redirect uri i.e. http://localhost:3000 (no deep links etc)
    // TODO: chase Evan to add wildcard (i.e. http://localhost:3000* etc)
    // TEMP WORKAROUND FOR NOW: if TechDiscovery, pass in explicit redirectURI without deep path
    ...(is_tech_discovery ? { redirectUri: window.location.origin } : {})
  })
  .then(authenticated => {
    if (!authenticated) {
      return false
    }

    save_to_local_storage(kc)
    return true
  })
}

export function force_refresh_jwt_token(kc) {
  kc.updateToken(-1) // -1 forces refresh
    .catch(on_update_token_error.bind(null, kc))
}

export function on_update_token_error(kc) {
  // Annoyingly keycloak does not expose the response or error, so we can not know exactly what went wrong.
  // There's three main possibilities:
  // a) Refresh token has expired (keycloak returns http 400).
  //    i.e. currently SSO session max is 14 days.
  //    If so, user needs to log in again.
  // b) Refresh token is invalid (keycloak returns http 400)
  //    i.e. perhaps user has logged out (or switched accounts) in another tab,
  //    revoking session associated with current refresh token.
  // c) Some other keycloak error.
  // Whatever the cause, at this point user can not make any calls to our API.
  // So we try to get them logged in again.
  kc.login() // redirects to keycloak for authentication (if cookie is still valid, will immediately redirect back with new refresh token)
}

export function save_to_local_storage(kc) {
  localStorage.setItem(KC_TOKEN, kc.token);
  localStorage.setItem(KC_REFRESH_TOKEN, kc.refreshToken)
}

export function get_from_local_storage() {
  const token         = localStorage.getItem(KC_TOKEN)
  const refresh_token = localStorage.getItem(KC_REFRESH_TOKEN)
  return { token, refresh_token }
}

export function remove_from_local_storage() {
  localStorage.removeItem(KC_REFRESH_TOKEN)
  localStorage.removeItem(KC_TOKEN)
}

export function fetch_wam_oidc_token() {
  const base_url = get_auth_url()
  const idp_alias = 'wam-pdc1c' // TODO: move this to config
  const url = `${base_url}/realms/${REACT_APP_AUTH_REALM}/broker/${idp_alias}/token`
  return axios.get(url)
    .then(response => {
      return response.data
    })
}

export function convert_wam_token_to_cookie(oidc_token) {
  // NOTE:
  // Calling this via ajax will not work in Safari/Firefox, as they block third party cookies.
  // It also suffers from CORS issues, so need to register valid callers with WAM.

  const url = `https://signin.lexisnexis.com/lnaccess/oidc/sso`

  const data = {
    RelayState: 'aci=ciph',
    id_token: oidc_token,
  }

  return axios.post(url, data, {headers: {'content-type': 'application/x-www-form-urlencoded'}} )
}