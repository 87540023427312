import React, { Component } from 'react'
import squarify from 'squarify'
import cn from 'classnames'

import Plot from './CustomPlotLazy.js'

import { BLACK, WHITE } from '../../constants/colours.js'

import {BASE_LAYOUT_PROPS, BASE_OTHER_PROPS, NO_AXIS_PROPS, SCATTER_CHART_TYPE} from './base_plotly_props.js'
import {
  FONT_SIZE,
  get_annotation_text,
  calculate_treemap_height,
  get_label_color_by_background,
  NORMAL_MARGIN,
  THUMBNAIL_MARGIN
} from '../../utils/treemap_utils.js'
import { format_integer_with_comma } from '../../utils/utils.js'
import { get_key_from_spec } from '../../utils/spec_utils.js'
import { THUMBNAIL_MAX_SERIES } from '../../utils/main_items_utils.js'

import s from './BaseTreemap.module.scss'
// NOTE: adds global css in plotly.override.scss

function get_tooltip_text_for_item(item, is_thumbnail, value_formatter) {
  const {tooltip_text, short_name, name, value} = item

  const formatted_value = (value_formatter) ? value_formatter(value) : format_integer_with_comma(value)

  if (!tooltip_text) return `${is_thumbnail ? short_name : name}<br>${formatted_value}`

  return tooltip_text
}

function process_data({items, is_thumbnail, container, value_formatter }) {
  const { width, height } = container

  const shapes = []
  const annotations = []

  const x_trace = []
  const y_trace = []
  const text = []

  const wrapper = {x0: 0, y0: 0, x1: width, y1: height}

  const rectangles = squarify(items, wrapper)

  rectangles.forEach((rectangle, i) => {
    const { x0, x1, y0, y1 } = rectangle
    const item = items[i]

    const {color, name, short_name } = item || {}

    const x = (x0 + x1) / 2
    const y = (y0 + y1) / 2
    const width = x1 - x0
    const height = y1 - y0

    const shape = {
      type: 'rect',
      line: {
        width: 1,
        color: WHITE
      },
      fillcolor: color,
      x0,
      x1,
      y0,
      y1
    }

    shapes.push(shape)

    const annotation_text = get_annotation_text(short_name, width, height)

    const annotation = {
      text: (is_thumbnail ? '' : annotation_text),
      showarrow: false,
      x: x,
      y: y,
      name: name,
      font: {
        size: FONT_SIZE,
        color: get_label_color_by_background(color)
      }
    }

    annotations.push(annotation)

    x_trace.push(x)
    y_trace.push(y)
    text.push(get_tooltip_text_for_item(item, is_thumbnail, value_formatter))
  })

  return {x_trace, y_trace, text, shapes, annotations }
}

function get_index(point_number, point_numbers) {
  // Seems like in certain cases, plotly does not make "point_number" available.
  // So we also need to check "point_numbers" array.
  if (point_number != null) {
    return point_number
  }
  if (point_numbers && point_numbers.length) {
    return point_numbers[0]
  }
  return null
}

let _items = null

class BaseTreemap extends Component {
  constructor(props) {
    super(props)

    this.update_wrapper_dimensions = this.update_wrapper_dimensions.bind(this)

    this.state = {
      container_height: 0,
      container_width: 0
    }
  }

  componentDidMount() {
    this.update_wrapper_dimensions()
    window.addEventListener('resize', this.update_wrapper_dimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.update_wrapper_dimensions)
  }

  componentDidUpdate() {
    this.update_wrapper_dimensions()
  }

  update_wrapper_dimensions() {
    if (!this.treemap_wrapper_ref) {
      return
    }

    const current_height = this.treemap_wrapper_ref.getBoundingClientRect().height
    const current_width = Math.min(this.treemap_wrapper_ref.getBoundingClientRect().width, 1200)
    const { container_width, container_height } = this.state

    if ((current_height !== container_height) || (current_width !== container_width)){
      this.setState({ container_height: current_height, container_width: current_width })
    }
  }

  get_treemap() {
    const { is_thumbnail, item, spec, items, value_formatter, set_selected, set_families_subselections, selections } = this.props
    const { container_width, container_height } = this.state
    if (container_width === 0) {
      //component not mounted yet
      return null
    }

    const treemap_items = is_thumbnail ? items.slice(0, THUMBNAIL_MAX_SERIES) : items

    _items = treemap_items

    const calculated_height = is_thumbnail ? container_height : calculate_treemap_height(container_width)

    const processed_data = process_data({item,  items: treemap_items, spec, is_thumbnail, container: {width: container_width, height: calculated_height}, value_formatter })

    const { x_trace, y_trace, text, shapes, annotations } = processed_data

    const treemap_data = [{
      x: x_trace,
      y: y_trace,
      hovertext: text,
      mode: 'text',
      type: SCATTER_CHART_TYPE,
      hoverinfo: 'text',
      hoverlabel: {
        bgcolor: BLACK // black background for tooltips
      }
    }]

    const layout = {
      ...BASE_LAYOUT_PROPS,
      margin: is_thumbnail ? THUMBNAIL_MARGIN : NORMAL_MARGIN,
      height: calculated_height,
      width: container_width,
      shapes: shapes,
      hovermode: is_thumbnail ? false : 'closest',
      hoverdistance: -1,
      annotations: annotations,
      font: {
        size: FONT_SIZE
      },
      xaxis: NO_AXIS_PROPS,
      yaxis: NO_AXIS_PROPS
    }

    const config = {
      displayModeBar: false
    }

    const {report_region_column} = selections
    const key = (spec) ? get_key_from_spec(spec, report_region_column) : []
    const query_key = key[0]

    return (
      <div className='mx-auto base_treemap_wrapper' style={{width: container_width + 'px'}}>
        <Plot
          {...BASE_OTHER_PROPS}
          config={config}
          data={treemap_data}
          layout={layout}
          onClick={function({points}) {
            if (is_thumbnail) {
              // show the dataset (set_main_item_id), as click does NOT bubble up.
              set_selected()
              return
            }

            if (!set_families_subselections || !points || !points.length) {
              return
            }

            const { pointNumber, pointNumbers, value } = points[0]
            const idx = get_index(pointNumber, pointNumbers)

            const item = _items[idx]

            if (!item) {
              // No item anymore (DOM may have been updated already (i.e. by some network call)).
              // So do nothing.
              return
            }

            set_families_subselections(
              [{ key: query_key, items: [item] }], // subselections
              value                                // value (can be useful occasionally)
            )

          }}
        />
      </div>
    )

  }

  render() {
    const { is_thumbnail } = this.props
    const treemap = this.get_treemap()

    return (
      <div className={cn('d-flex mb-3 overflow-auto', { [s.block__thumbnail]: is_thumbnail })}
           ref={ (treemap_wrapper) => this.treemap_wrapper_ref = treemap_wrapper }
      >
        {treemap}
      </div>
    )
  }
}

export default BaseTreemap