import * as d3 from 'd3'
import _ from 'underscore'

import {
  BLACK,
  WHITE,
  TREEMAP3D_MAX_COLOR,
  TREEMAP3D_MIN_COLOR,
  TREEMAP3D_ZERO_COLOR
} from '../constants/colours.js'
import { KEY_COLUMN_IDXS_2D } from '../model/column_data.js'

const MIN_HEIGHT = 500
const GOLDEN_RATIO = (1 + Math.sqrt(5)) / 2
const INV_GOLDEN_RATIO = 1 / GOLDEN_RATIO
const TREEMAP_TOPBAR_ALLOWANCE = 100

export const FONT_SIZE = 13
const CHAR_SIZE = 9 //approximately how much space is dedicated per character in px
const LINE_HEIGHT = FONT_SIZE + 5 // approx how many px needed to display a line of this font size


export const NORMAL_MARGIN = {
  t: 20, // leave space for tooltips ?
  r: 10,
  b: 20,
  l: 10
}

export const THUMBNAIL_MARGIN = {
  t: 20,
  r: 10,
  b: 60,
  l: 10
}

export function calculate_treemap_height(container_width, min_height) {
  return (calculate_golden_ratio_height(container_width, min_height) - TREEMAP_TOPBAR_ALLOWANCE)
}

export function calculate_golden_ratio_height(container_width, min_height) {
  const gr_height = INV_GOLDEN_RATIO * container_width

  const height_min_cutoff = min_height || MIN_HEIGHT
  return Math.max(gr_height, height_min_cutoff)
}

export function get_label_color_by_background(hex){
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  const rgb = result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    }
    : null

  if (!rgb) return BLACK

  return (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000 > 125 ? BLACK : WHITE
}

export function get_annotation_text(text, width, height) {
  const chars_per_line = Math.floor(width / CHAR_SIZE)
  const displayable_lines = Math.floor(height / LINE_HEIGHT)

  if (text_fits_on_line(text, chars_per_line)) {
    return text
  }

  if (chars_per_line <= 3 || displayable_lines < 1) {
    // we don't have room to display anything readable
    return ''
  }

  if (displayable_lines < 2) {
    // if we only have room for one line, get as much text in there as possible
    return truncate_and_hyphenate(text, chars_per_line)
  }

  return annotation_text_with_line_breaks(text, chars_per_line, displayable_lines)
}

function text_fits_on_line(text, chars_per_line) {
  return text.length <= chars_per_line
}

function annotation_text_with_line_breaks(text, chars_per_line, displayable_lines) {
  const annotation_text_lines = []
  let remaining_text = text

  _.forEach(_.range(displayable_lines), () => {
    if (remaining_text.length) {
      const {next_line, remaining} = next_displayable_line_of_annotation_text(remaining_text, chars_per_line)

      remaining_text = remaining
      annotation_text_lines.push(next_line)
    }
  })

  return annotation_text_lines.join('<br>')
}

function next_displayable_line_of_annotation_text(text, chars_per_line) {
  if (text_fits_on_line(text, chars_per_line)) {
    return {
      next_line: text,
      remaining: ''
    }
  }

  // find index of last space in allowable length of string
  const natural_break = _.lastIndexOf(text.substr(0, chars_per_line + 1), ' ')

  if (natural_break > 3) {
    // break on a space if we can do so without getting a silly short line
    return {
      next_line: text.substr(0, natural_break),
      remaining: text.substr(natural_break + 1)
    }
  }

  return {
    next_line: truncate_and_hyphenate(text, chars_per_line - 1),
    remaining: text.substr(chars_per_line - 1)
  }
}

function truncate_and_hyphenate(text, allowable_chars) {
  // chop and add a "-" if it looks like we're in the middle of a word
  const truncated_text = text.substr(0, allowable_chars)
  return truncated_text + `${truncated_text.substr(-1).match(/^[.,:!?\s]/) ? '' : '-'}`
}

//growth treemap
export const X_COLUMN_IDX = KEY_COLUMN_IDXS_2D[0]
export const Y_COLUMN_IDX = KEY_COLUMN_IDXS_2D[1]
export const LEGEND_WIDTH = 300

const COLOR_SCALE_MIN = -0.5
const COLOR_SCALE_MAX = 0.5
const COLOR_SCALE_STEP = 0.1

export const COLOR_SCHEME = d3.scaleLinear() // NOTE: polylinear change, centred around 0
  .domain([COLOR_SCALE_MIN, 0, COLOR_SCALE_MAX])
  .range([TREEMAP3D_MIN_COLOR, TREEMAP3D_ZERO_COLOR, TREEMAP3D_MAX_COLOR])
  .clamp(true)


export function get_legend_ticks() {
  return range_inclusive(COLOR_SCALE_MIN, COLOR_SCALE_MAX, COLOR_SCALE_STEP)
}

export function get_color(value) {
  return COLOR_SCHEME(value)
}

export function range_inclusive(start, stop, step) {
  // include 'stop' value in result
  return d3.range(start, stop + 0.0000000001, step);
}

function get_plusminus_prefix(d) {
  return d > 0 ? '+' : '';
}

export function format_plusminus(d) {
  return get_plusminus_prefix(d) + d;
}

const FORMAT_PERCENT = d3.format('.2%')

export function format_percent(d) {
  // i.e. 1.234567 -> "123.46%"
  if (d === null || d === undefined) {
    return 'n/a';
  }
  return FORMAT_PERCENT(d);
}

export function format_percent_plusminus(d) {
  if (d == null) {
    return 'n/a';
  }
  return get_plusminus_prefix(d) + FORMAT_PERCENT(d);
}