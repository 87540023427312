import _ from 'underscore'

import { DEFAULT_REPORT_TYPE } from '../../../constants/constants.js'
import { TYPE_PATENT_FAMILIES } from '../../../model/portfolio_basket.js'
import { build_technology_basket_for_single_classifier } from '../../../utils/report_builder_utils.js'
import { build_classifier_sync, get_latest_built_classifier } from '../../../utils/training_set_grpc_utils.js'
import { ID_TO_ENGINE_ITEM } from '../model/classifier_engines.js'
import { CLASSIFIER_SCORE_THRESHOLD_DEFAULT } from '../../../constants/report_input.js'
import { get_negatives_processing, NEGATIVES_PROCESSING_INCLUDE } from '../../../model/negatives_processing.js'
import {
  build_classifier_landscape_report_input,
  build_generic_builder_report_input
} from '../../../utils/report_input_utils.js'
import { is_s3_all_families } from '../../../model/portfolios.js'

export function get_patfam_sample_eval_report_input(
  {
    report_name,
    training_set_id,
    training_set_name,
    training_set_description,
    patfam_ids,
    portfolio_name
  }) {
  // If patfam_ids are specified, create portfolio_basket and portfolio_meta (otherwise leave empty)

  const portfolios = !patfam_ids ? [] : [{
    name: portfolio_name,
    pat_fam_ids: patfam_ids,
    type: TYPE_PATENT_FAMILIES,
    group_by_owner: true
  }]

  return build_generic_builder_report_input(
    {
      report_name,
      report_type: DEFAULT_REPORT_TYPE,
      portfolios,
      classifiers: build_technology_basket_for_single_classifier(training_set_id, training_set_name, training_set_description),
      evaluation_classifier_id: training_set_id,
      negatives_processing: get_negatives_processing({type: NEGATIVES_PROCESSING_INCLUDE}),
      multi_label: false,
      threshold: CLASSIFIER_SCORE_THRESHOLD_DEFAULT
    }
  )
}

export function get_updated_eval_landscape_input({report_input, report_name, training_set_id, training_set_name, training_set_description}) {
  const { technology_partitioning, portfolios, portfolio_roll_up_limit } = report_input
  const { group_by_owner_level, threshold } = technology_partitioning || {}

  const classifiers = build_technology_basket_for_single_classifier(training_set_id, training_set_name, training_set_description)

  // should be false if the original report is an all families landscape
  const has_specified_portfolios = portfolios.filter(portfolio => !is_s3_all_families(portfolio)).length > 0

  // return original input params with title, updated classifier metadata and negatives processing params
  return build_classifier_landscape_report_input({
    report_name,
    ...(has_specified_portfolios ? {portfolios} : {}),
    classifiers,
    group_by_owner_level,
    multi_label: false,
    evaluation_classifier_id: training_set_id,
    portfolio_roll_up_limit,
    threshold
  })
}

export function get_and_rebuild_classifier(training_set_id) {
  // TODO: switch this to use correct build...

  // SYNC BUILD
  // Get latest build
  return get_latest_built_classifier(training_set_id)
    .then(({ latest_built_classifier_metadata }) => {
      const { classifier_engine: metadata_engine_id } = latest_built_classifier_metadata || {}

      // Use engine and default params from latest build
      const engine_id = metadata_engine_id
      const engine = ID_TO_ENGINE_ITEM[engine_id]
      const { get_build_params } = engine
      const params = get_build_params() // default params

      // Rebuild
      return build_classifier_sync(training_set_id, engine_id, params)
    })

  /*
  // ASYNC BUILD
  // Get latest build
  return get_latest_built_classifier(training_set_id)
    .then(({ latest_built_classifier_metadata }) => {
      const { classifier_engine: metadata_engine_id } = latest_built_classifier_metadata || {}

      // Use engine and default params from latest build
      const engine_id = metadata_engine_id
      const engine = ID_TO_ENGINE_ITEM[engine_id]
      const { get_build_params } = engine
      const params = get_build_params() // default params

      // Rebuild
      return create_build_classifier_job(training_set_id, engine_id, params)
      .then(() => poll_build_classifier_job_status_till_not_building(training_set_id))
      .then(() => fetch_latest_build_log_entry(training_set_id, true))
      .then((log_entry) => {
        const { event } = log_entry
        if (event === BUILD_CLASSIFIER_FAILED_ID) {
          const err = Error('Classifier Build Job failed (see service logs for more details)')
          err.log_event = event
          throw err
        }
        return event
      })
    })
    */
}

export function get_owner_to_patfam_ids(ids_A, owners_A, ids_B, owners_B) {
  const owner_to_patfam_ids = {}

  // We use mutation here, as the arrays are potentially large, and an immutable approach can take too much time/memory.

  ids_A.forEach((id, idx) => {
    const owner = owners_A[idx]
    const owner_ids = owner_to_patfam_ids[owner] || []
    owner_ids.push(id)
    owner_to_patfam_ids[owner] = owner_ids
  })

  ids_B.forEach((id, idx) => {
    const owner = owners_B[idx]
    const owner_ids = owner_to_patfam_ids[owner] || []
    owner_ids.push(id)
    owner_to_patfam_ids[owner] = owner_ids
  })

  const result = _.mapObject(owner_to_patfam_ids, ids => _.unique(ids))

  return result
}

export function validate_comparison_tech_response(response) {
  const { data } = response
  return data[1].length === 1
}
