import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import _ from 'underscore'
import { Autocomplete, Switch, TextField } from '@mui/material'

import { withUser } from '../UserContext.js'
import TextLink from '../widgets/TextLink.js'
import { EditIcon, PlusIcon } from '../widgets/IconSet.js'
import ClearableSearchInput from '../widgets/ClearableSearchInput.js'
import {
  Choice,
  fetch_grouped_editable_tags,
  filter_grouped_tags,
  persist_existing_tag,
  persist_new_tag,
  rename_tag_section_name,
  GroupedTags,
  Tag,
  TagValue,
  UUIDPermissions,
  is_valid_name,
  DEFAULT_SECTION_NAME,
  amend_tag_sorting,
  SectionSorting
} from './family_tag_utils'
import { FamilyTagValuesManagement } from './FamilyTagValuesManagement'
import Spinner from '../widgets/Spinner.js'
import TagDetailsModal from './TagDetailsModal'
import EditableTextLink from '../report_management/components/EditableTextLink.js'
import { format_string_only_first_character_capitalised } from '../../utils/utils.js'
import {
  is_element_vertically_onscreen,
  SCROLL_INTO_VIEW_BEHAVIOUR_SMOOTH,
  scroll_to_centre
} from '../../utils/scroll_utils'
import ContainerFullWidth from '../ContainerFullWidth.js'
import Label from '../widgets/Label.js'
import ErrorBody from '../ErrorBody.js'

import cs from '../cipher_styles.module.scss'
import s from './FamilyTagsManagement.module.scss'


interface FamilyTagsManagementProps {
  user: any,
  fetch_tags_func: Function,
  cipher_groups: Array<KeycloakGroup>,
  search_client: any,
  set_search_client: Function,
}

interface KeycloakGroup {
  id: string,
  name: string,
}

function is_iterable(value: any) {
  return Symbol.iterator in Object(value)
}

const FamilyTagsManagement = (
  {
    user,
    fetch_tags_func,
    cipher_groups,
    search_client,
    set_search_client
  }: FamilyTagsManagementProps) => {

  const [grouped_tags, set_grouped_tags] = useState<Array<GroupedTags>>([])
  const [newly_created_or_amended_id, set_newly_created_or_amended_id] = useState<number>(-1)
  const newly_created_or_amended_ref = useRef<HTMLDivElement>(null)
  const [fetch_grouped_tags, set_fetch_grouped_tags] = useState<boolean>(true)
  const [error, set_error] = useState(null)
  const [show_spinner, set_show_spinner] = useState(false)
  const [search_input, set_search_input] = useState('')
  const [show_create_tag, set_show_create_tag] = useState(false)
  const [section_to_rename, set_section_to_rename] = useState<string | null>(null)
  const [selected_section, set_selected_section] = useState<string | null>(null)
  const [selected_tag, set_selected_tag] = useState<Tag | null>(null)
  const [show_amend_tag, set_show_amend_tag] = useState(false)
  const [manual_order_per_section, set_manual_order_per_section] = useState<any>({})

  const fetch_func = fetch_tags_func || fetch_grouped_editable_tags
  const is_admin_mngt = fetch_tags_func != null

  useEffect(() => {
    let did_cancel = false
    if (fetch_grouped_tags) {
      set_show_spinner(true)
      fetch_func()
        .catch((error: React.SetStateAction<null>) => {
          if (!did_cancel) {
            set_error(error)
            set_show_spinner(false)
            set_fetch_grouped_tags(false)
          }
        })
        .then((custom_tags: React.SetStateAction<GroupedTags[]>) => {
          if (!did_cancel && custom_tags) {
            set_error(null)
            set_grouped_tags(custom_tags)
            set_show_spinner(false)
            set_fetch_grouped_tags(false)
          }
        })
    }
    return () => {
      did_cancel = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.user_id, fetch_grouped_tags])


  const existing_sections = grouped_tags.map((group_tag) => (group_tag.tag_section))
  const filtered_tags: Array<GroupedTags> = filter_search()

  useEffect(() => {
    if (grouped_tags && grouped_tags.length > 0 && newly_created_or_amended_id > 0 && newly_created_or_amended_ref.current) {
      if (selected_tag) {
        if (!is_element_vertically_onscreen(newly_created_or_amended_ref.current)) {
          scroll_to_centre(newly_created_or_amended_ref, SCROLL_INTO_VIEW_BEHAVIOUR_SMOOTH)
        }
      }
    }
  })

  useEffect(() => {
    const new_manual_order_per_section: any = manual_order_per_section ? manual_order_per_section : {}
    grouped_tags.forEach((grouped) => {
      const existing = manual_order_per_section && manual_order_per_section[grouped.tag_section]
      new_manual_order_per_section[grouped.tag_section] = existing ? manual_order_per_section[grouped.tag_section] : null
    })
    set_manual_order_per_section(new_manual_order_per_section)
  }, [grouped_tags, manual_order_per_section])

  function on_click_manual_ordering(section: string, new_checkbox_value: boolean) {
    set_show_spinner(true)
    const new_manual_order_per_section: any = {...manual_order_per_section}
    new_manual_order_per_section[section] = new_checkbox_value
    set_manual_order_per_section(new_manual_order_per_section)
    const section_to_sort: GroupedTags = grouped_tags.filter(grouped => grouped.tag_section === section)[0]
    let new_section_tags_ordering: Array<SectionSorting> = []
    new_section_tags_ordering = section_to_sort.tags.map((tag, i) => ({
      tag_id: tag.id,
      tag_name: tag.name,
      is_current: false,
      sort_index: new_checkbox_value ? (tag.sort_index > 0 ? tag.sort_index : (i + 1)) : 0
    }))
    amend_tag_sorting(new_section_tags_ordering)
      .catch(() => {
        set_error(error)
        set_fetch_grouped_tags(false)
        set_show_spinner(false)
      })
      .then(() => {
        set_fetch_grouped_tags(true)
        set_error(null)
      })
  }

  function update_sorting(sorting: Array<SectionSorting>, tag: Tag, new_tag_sort_idx: number) {
    set_show_spinner(true)
    const elem_to_move: SectionSorting = sorting.filter((sorted: any) => (sorted.tag_id === tag.id))[0]
    const new_sorting: Array<SectionSorting> = sorting.filter((sorted: any) => (sorted.tag_id !== tag.id))
    new_sorting.splice(new_tag_sort_idx, 0, elem_to_move)
    const new_section_sorting: Array<SectionSorting> = new_sorting.map((sorted: any, i: number) => ({
      ...sorted,
      sort_index: (i + 1)
    }))
    //persist in the db
    amend_tag_sorting(new_section_sorting)
      .catch(() => {
        set_error(error)
        set_fetch_grouped_tags(false)
        set_show_spinner(false)
      })
      .then(() => {
        set_fetch_grouped_tags(true)
        set_newly_created_or_amended_id(tag.id)
        set_error(null)
      })
  }

  function get_section_sorting(section_tags: Array<Tag>) {
    const needs_resorting = section_tags.filter((tag) => tag.sort_index > section_tags.length).length > 0
    return section_tags.map((tag: Tag, idx: number) => ({
      tag_id: tag.id,
      tag_name: tag.name,
      sort_index: (needs_resorting && !is_admin_mngt)? tag.sort_index: (idx + 1)
    }))
  }

  function is_tag_new_name_valid(section_name: string, new_name: string) {
    const grouped_tag: GroupedTags = grouped_tags.filter((grouped_tag: GroupedTags) => grouped_tag.tag_section === section_name)[0]
    return is_valid_name(new_name, grouped_tag?.tags || [], 'name')
  }

  function amend_tag(selected_tag: Tag, selected_section: string) {
    set_selected_section(selected_section)
    set_selected_tag(selected_tag)
    set_show_amend_tag(true)
    set_newly_created_or_amended_id(-1)
  }

  function create_new_tag(section: string, id: number, name: string, type: Choice, permissions: Array<UUIDPermissions>, labels: Array<TagValue>) {
    const grouped_tag:any = {tag_section: section, tags: [{name: name, type: type, values: labels, id: id}]}
    persist_new_tag(grouped_tag, permissions)
      .then((response: any) => {
        set_show_create_tag(false)
        set_fetch_grouped_tags(true)
        set_newly_created_or_amended_id(response.data.new_tag_id)
      })
      .catch((error: any) => {
        set_show_create_tag(false)
        set_error(error)
        set_newly_created_or_amended_id(-1)
      })
  }

  function save_tag(section: string, tag_id: number, name: string, type: Choice, permissions: Array<UUIDPermissions>, labels: Array<TagValue>) {
    const section_tag: any = {tag_section: section, tags: [{name: name, type: type, values: labels, id: tag_id}]}
    persist_existing_tag(section_tag, permissions)
      .then(() => {
        set_show_amend_tag(false)
        set_fetch_grouped_tags(true)
        set_newly_created_or_amended_id(tag_id)
      })
      .catch((error: any) => {
        set_show_amend_tag(false)
        set_error(error)
        set_newly_created_or_amended_id(-1)
      })
  }

  function rename_section(tag_section: GroupedTags, new_section_name: string) {
    rename_tag_section_name(tag_section, format_string_only_first_character_capitalised(new_section_name))
      .then(() => {
        set_section_to_rename(null)
        const section_to_rename: GroupedTags = grouped_tags.filter((group) => (group.tag_section === tag_section.tag_section))[0]
        const renamed_section_tags = section_to_rename.tags.map(tag => ({...tag, section: new_section_name}))
        const renamed_section: GroupedTags = {tag_section: new_section_name, tags: renamed_section_tags}
        const new_grouped_tags: Array<GroupedTags> = grouped_tags.map((group) => {
          if (group.tag_section === tag_section.tag_section) {
            return renamed_section
          } else {
            return group
          }
        })
        set_grouped_tags(new_grouped_tags)
        set_newly_created_or_amended_id(-1)
      })
      .catch((error: any) => {
        set_section_to_rename(null)
        set_error(error)
        set_newly_created_or_amended_id(-1)
      })
  }

  function filter_search() {
    const search_filtered = !_.isEmpty(search_input) ? filter_grouped_tags(grouped_tags, search_input) : grouped_tags
    return !_.isEmpty(search_client) ? filter_grouped_tags_by_org(search_filtered) : search_filtered
  }

  function filter_grouped_tags_by_org(grouped_tags: Array<GroupedTags>): Array<GroupedTags> {
    const flat_grouped_tags: Array<Tag> = grouped_tags.flatMap((group) => group.tags)
    const filtered: Array<Tag> = flat_grouped_tags.filter((tag) => is_shared_with_org_search(tag))
    const filtered_grouped = grouped_tags.map((group) => ({
      ...group,
      tags: group.tags.filter((tag) => (_.contains(filtered, tag)))
    }))

    return filtered_grouped.filter((group) => !_.isEmpty(group.tags))
  }

  function is_shared_with_org_search(tag: Tag): boolean {
    if (_.isEmpty(search_client)) {
      return true
    }
    const org_ids = (is_iterable(search_client) && search_client?.map((org: any) => org.id)) || [search_client?.id]
    return _.some((tag.orgs || []), org => _.contains(org_ids, org))
  }

  function is_newly_created_or_amended(id: number) {
    return newly_created_or_amended_id === id
  }

  function on_change_search_input(new_value: string) {
    set_search_input(new_value)
    set_newly_created_or_amended_id(-1)
  }

  return (
    <ContainerFullWidth className='my-2'>
      <h3>Family tags management</h3>
      {show_spinner &&
        <div>
          <div>Fetching tags</div>
          <Spinner/>
        </div>
      }

      {error && !show_spinner &&
        <ErrorBody
          error={error}
          context='Fetching, creating or updating family tags'
        />
      }

      {!error && !show_spinner && !show_amend_tag && show_create_tag &&
        <TagDetailsModal
          title={'Create new tag group'}
          on_close={() => set_show_create_tag(false)}
          on_submit={create_new_tag}
          submit_label={'Create'}
          is_valid_tag_name={is_tag_new_name_valid}
          sections={existing_sections}
          selected_section={selected_section}
          set_selected_section={set_selected_section}
          allow_groups_sharing={is_admin_mngt}
        />
      }
      {!error && !show_spinner && !show_create_tag && show_amend_tag &&
        <TagDetailsModal
          title={'Amend tag group'}
          existing_tag={selected_tag}
          on_close={() => set_show_amend_tag(false)}
          on_submit={save_tag}
          submit_label={'Save'}
          is_valid_tag_name={is_tag_new_name_valid}
          sections={existing_sections}
          selected_section={selected_section}
          set_selected_section={set_selected_section}
          allow_groups_sharing={is_admin_mngt}
        />
      }
      {!error && !show_spinner &&
        <div className={'d-flex flex-column justify-content-center'}>
          {grouped_tags.length < 1 &&
            <div className={'d-flex flex-row justify-content-between'}>
              <div>There are currently no tags to edit, please create a new family tag.
              </div>
              {/*// @ts-expect-error*/}
              <TextLink
                onClick={() => {
                  set_show_create_tag(true)
                  set_selected_section(DEFAULT_SECTION_NAME)
                }}
                className={'text-end'}

                no_decoration
              >
                <PlusIcon /><span className={cn('ms-1', cs.underline_on_hover)}>New tag group</span>
              </TextLink>
            </div>
          }
          {grouped_tags.length >= 1 &&
            <div>
              {is_admin_mngt && cipher_groups &&
                <Autocomplete
                  id={'cipher-orgs'}
                  disablePortal
                  multiple
                  filterSelectedOptions
                  defaultValue={search_client}
                  options={cipher_groups}
                  className='mt-2 mb-2'
                  size='small'
                  getOptionLabel={(k_group) => k_group.name}
                  renderInput={(params) => <TextField {...params} label='Search client groups' placeholder='Search by client'/>}
                  onChange={(_, selection) => set_search_client(selection)}
                />
              }
              {/*// @ts-expect-error*/}
              <ClearableSearchInput
                containerClassName={'mt-2 mb-2'}
                value={search_input}
                placeholder={'Search family tags'}
                auto_focus={true}
                handle_change={on_change_search_input}
                show_clear
              />
              <ul className='list-unstyled mt-4'>
                {filtered_tags.map((section: GroupedTags, index) => {
                  const is_editing = section_to_rename && section_to_rename === section.tag_section
                  const section_sorting = get_section_sorting(section.tags)
                  const show_section_sorting = section.tags.length > 1
                  const is_section_sorting_checked = section.tags.filter((tag) => tag.sort_index > 0).length > 0
                  return (
                    <li key={index}>
                      <div className={cn('d-flex justify-content-between', (index === 0 ? s.first_section_header : s.subsequent_section_header))}>
                        <div className={cn(s.section, 'my-auto')}>
                          {is_admin_mngt &&
                            <h4>{section.tag_section}</h4>
                          }
                          {!is_admin_mngt &&
                            <>
                              {!is_editing &&
                                <div className='d-flex'>
                                  <h4>{section.tag_section}</h4>
                                  {/* @ts-expect-error */}
                                  <TextLink
                                    className='align-top'
                                    title='Rename section'
                                    onClick={() => {
                                      set_section_to_rename(section.tag_section)
                                      set_newly_created_or_amended_id(-1)
                                    }}
                                    no_decoration
                                  >
                                    {/* @ts-expect-error */}
                                    <EditIcon className='ms-2'/>
                                  </TextLink>
                                </div>
                              }
                              {is_editing &&
                                /* @ts-expect-error */
                                <EditableTextLink
                                  link_text={section.tag_section}
                                  on_confirm={(name: string) => rename_section(section, name)}
                                  on_cancel={() => set_section_to_rename(null)}
                                  is_edit={is_editing}
                                  is_valid={(name: string) => is_valid_name(name, existing_sections, null)}
                                  is_clickable={true}
                                  is_editable={true}
                                />
                              }
                            </>
                          }
                        </div>
                        {!is_admin_mngt && show_section_sorting &&
                          <div className='d-flex align-self-center'>
                            <Label className=''>
                              Sort tag groups
                            </Label>
                            <Switch
                              className='ms-1'
                              checked={is_section_sorting_checked}
                              onClick={() => on_click_manual_ordering(section.tag_section, !is_section_sorting_checked)}
                            />
                          </div>
                        }
                      </div>
                      {/*// @ts-expect-error*/}
                      <TextLink
                        onClick={() => {
                          set_show_create_tag(true)
                          set_selected_section(section.tag_section)
                        }}
                        no_decoration
                      >
                        <PlusIcon/>
                        <span className={cn('ms-1', cs.underline_on_hover)}>
                            Add a new tag group in this section
                          </span>
                      </TextLink>
                      {section.tags.map((tag: Tag) => {
                        const tag_sorting = section_sorting.map((sorted: any) => ({
                          ...sorted,
                          is_current: (sorted.tag_id === tag.id)
                        }))
                        return (
                          <FamilyTagValuesManagement
                            key={tag.id}
                            tag={tag}
                            className={'py-3 w-100'}
                            notify_parent={() => {
                              set_manual_order_per_section(false)
                              set_fetch_grouped_tags(true)
                              set_newly_created_or_amended_id(tag.id)
                            }}
                            change_tag={() => amend_tag(tag, tag.section)}
                            ref={is_newly_created_or_amended(tag.id) ? newly_created_or_amended_ref : null}
                            sorting={tag_sorting}
                            update_sorting={update_sorting}
                            show_index={show_section_sorting && is_section_sorting_checked}
                          />
                        )
                      })}
                    </li>)
                })}
              </ul>
            </div>
          }
        </div>
      }
    </ContainerFullWidth>
  )
}

export default withUser(FamilyTagsManagement)

