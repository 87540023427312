import React from 'react'

import Modal from '../widgets/Modal.js'
import { PrimaryButton } from '../widgets/Button.js'

const AgglomerationModal = ({org_name, on_hide, on_confirm}) => {
  const primary_button = (
    <PrimaryButton onClick={() => on_confirm()}>Make organisation</PrimaryButton>
  )

  return (
    <Modal is_open={true} on_hide={on_hide} primary_button={primary_button} title={`Turn "${org_name}" into organisation`}>
      Are you sure you want to create organisation from this agglomeration?
    </Modal>
  )
}

export default AgglomerationModal