import React, {Component} from 'react'
import ReactTable from 'react-table'
import _ from 'underscore'
import cn from 'classnames'
import $ from 'jquery'

import { format_integer_with_comma } from '../../utils/utils.js'
import {
  is_element_vertically_onscreen,
  SCROLL_INTO_VIEW_BEHAVIOUR_INSTANT,
  scroll_to_centre
} from '../../utils/scroll_utils.js'

import {
  CIPHER_FAMILY_ID_FIELD_ID,
  OWNERS_FIELD_ID,
  ASSIGNEES_FIELD_ID,
  PATENT_NUMBERS_FIELD_ID,
  TITLE_FIELD_ID,
  ABSTRACT_FIELD_ID,
  STATUS_FIELD_ID,
  PENDING_TERRITORIES_FIELD_ID,
  GRANTED_TERRITORIES_FIELD_ID,
  EXPIRED_TERRITORIES_FIELD_ID,
  PRIORITY_DATE_FIELD_ID,
  GRANTED_DATE_FIELD_ID,
  PUBLICATION_DATE_FIELD_ID,
  EXPIRY_DATE_FIELD_ID,
  CPC_CODES_FIELD_ID,
  INVENTORS_FIELD_ID,
  TOTAL_COST_FIELD_ID,
  TOTAL_COST_PROJECTION_FIELD_ID,
  SCORE_ID,
  BACKWARD_CITATIONS_NUM_ID,
  FORWARD_CITATIONS_NUM_ID,
  CLASSIFIER_LABEL_CONTROLS_ID,
  CLASSIFIER_LABEL_CONTROLS_FIELD,
  TECHNOLOGY_FIELD_ID,
  PORTFOLIO_FIELD_ID,
  PVIX_SCORE_ID,
  SUGGESTION_STRATEGY_FIELD,
  SUGGESTION_STRATEGY_FIELD_ID,
  SUGGESTION_DETAIL_FIELD,
  SUGGESTION_DETAIL_FIELD_ID,
  SELECT_FIELD_ID,
  PAT_FAM_ID_FIELD_ID,
  TAGS_ID,
  SIMILARITY_SCORE_ID,
  CLAIMS_FIELD_ID,
  PRIMARY_CPC_CODE_FIELD_ID,
  FIRST_FILING_COUNTRY_FIELD_ID,
  PRIMARY_PUBLICATION_FIELD_ID,
  SIMILARITY_SEARCH_CONTROLS_FIELD_ID,
  SIMILARITY_SEARCH_CONTROLS_FIELD,
} from '../../model/patent_family_fields.js'
import { display_score, format_score } from '../classifiers_editor/utils/training_set_utils.js'
import CheckboxStatic from '../widgets/CheckboxStatic.js'
import { PatentLink } from '../widgets/PatentLink.js'
import { Highlighter } from '../widgets/Highlighter.js'
import ClassifierLabelControl from '../classifiers_editor/components/ClassifierLabelControl.js'
import ClassifierStatusMessage from '../classifiers_editor/components/ClassifierStatusMessage.js'
import SuggestionsStrategyName from '../classifiers_editor/components/SuggestionsStrategyName.js'
import SuggestionsStrategyDetails from '../classifiers_editor/components/SuggestionsStrategyDetails.js'
import SortingColumnHeaderCell from './SortingColumnHeaderCell.js'
import CipherFamilyLink from '../widgets/CipherFamilyLink'
import CpcWithHover from '../family_view/CpcWithHover.js'
import { FamilyTagsColumn } from '../family_tagging/FamilyTagsColumn'
import { get_is_show_scope } from '../classifiers_editor/utils/scope_modal_utils.js'
import AddFamilyToInput from './AddFamilyToInput.js'
import PatentFamilyClassifierMarker from './PatentFamilyClassifierMarker.js'

import s from './PatentFamiliesTable.module.scss'

function get_width_props(field_id, show_suggestion_strategy) {
  if (_.contains([SUGGESTION_STRATEGY_FIELD_ID], field_id)) {
    return {minWidth: 140}
  }
  if (_.contains([TITLE_FIELD_ID], field_id)) {
    return {minWidth: 300}
  }

  if (_.contains([ABSTRACT_FIELD_ID], field_id)) {
    return {minWidth: 300}
  }

  if (_.contains([CLAIMS_FIELD_ID], field_id)) {
    return {minWidth: 300}
  }

  if (_.contains([CIPHER_FAMILY_ID_FIELD_ID], field_id)) {
    return {width: 170}
  }

  if (_.contains([PRIMARY_CPC_CODE_FIELD_ID, PRIMARY_PUBLICATION_FIELD_ID], field_id)) {
    return {width: 160}
  }

  if (_.contains([PRIORITY_DATE_FIELD_ID, GRANTED_DATE_FIELD_ID, PUBLICATION_DATE_FIELD_ID, EXPIRY_DATE_FIELD_ID], field_id)) {
    return {width: 120}
  }

  if (_.contains([STATUS_FIELD_ID], field_id)) {
    return {maxWidth: 90}
  }

  if (_.contains([TOTAL_COST_FIELD_ID, TOTAL_COST_PROJECTION_FIELD_ID, SCORE_ID], field_id)) {
    return {maxWidth: 90}
  }

  if (_.contains([CLASSIFIER_LABEL_CONTROLS_ID], field_id)) {
    return {width: 8}
  }

  if (_.contains([SELECT_FIELD_ID], field_id)) {
    return {maxWidth: 40}
  }

  if (_.contains([FIRST_FILING_COUNTRY_FIELD_ID, SIMILARITY_SEARCH_CONTROLS_FIELD_ID], field_id)) {
    return {maxWidth: 70}
  }

  if (_.contains([TAGS_ID], field_id)) {
    return {minWidth: 250}
  }

  return {}
}

function get_is_field_right_aligned(field_id) {
  return _.contains([
    TOTAL_COST_FIELD_ID,
    TOTAL_COST_PROJECTION_FIELD_ID,
    SCORE_ID,
    BACKWARD_CITATIONS_NUM_ID,
    FORWARD_CITATIONS_NUM_ID,
    PVIX_SCORE_ID,
    SIMILARITY_SCORE_ID
  ], field_id)
}

function set_labelling_containers_scroll_x(body_el, scroll_x) {
  // We want to absolutely position the labelling controls at the far right of the table,
  // which is the scroll_x position.
  // During scroll "bounce", scroll offset will be beyond the scrollable width of the table.
  // On Safari, absolute positionning beyond the container causes container to expand,
  // giving weird super-wide table columns to the table.
  // So we only apply the positioning when within the container.
  const body_width_full    = body_el.scrollWidth
  const body_width_visible = body_el.offsetWidth
  const max_scroll         = body_width_full - body_width_visible
  if (scroll_x <= max_scroll) {
    $('.' + s.labelling_container).css('right', -scroll_x)
  }
}

// Limit the amount of updates
const set_labelling_containers_scroll_x__throttled = _.throttle(set_labelling_containers_scroll_x, 300)


class PatentFamiliesTable extends Component {
  constructor(props) {
    super(props)
    this.wrapper_ref = React.createRef()
    this.table_header_ref = React.createRef()
    this.table_body_ref = React.createRef()
    this.selected_row_ref = React.createRef()

    this.scroll_x = null

    const {bulk_select, patfams, selected_patfams} = this.props
    const rows = this.to_rows(bulk_select, patfams, selected_patfams)
    this.state = {'rows':rows}
  }

  componentDidMount() {
    this.register_sync_scroll_x()
    this.ensure_selected_row_is_visible()
  }

  componentDidUpdate(prevProps) {
    this.restore_scroll_x()
    this.register_sync_scroll_x()

    const { subidx, bulk_select, patfams, selected_patfams} = this.props

    const new_rows = this.to_rows(bulk_select, patfams, selected_patfams)
    this.setState({'rows': new_rows})

    const prev_subidx = prevProps.subidx
    if (subidx !== prev_subidx) {
      // Selected family has changed, so possibly scroll...
      this.ensure_selected_row_is_visible()
    }
  }

  shouldComponentUpdate(nextProps,newState) {
    const is_show_scope = get_is_show_scope()
    if (is_show_scope) {
      // When scope modal is shown, don't update (with 500 rows this can make the text inputs unusably slow, and the table is not even visible anyways)
      return false
    }

    // Check that stuff has really changed before re-rendering
    // (sometimes some uptree asynchronous callbacks result in a lot of unnecessary re-renders, and jitter).
    const has_changed = !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, newState)
    return has_changed
  }

  register_sync_scroll_x() {
    // We want the table header row to be 'sticky' at the top of the screen,
    // but this means it must be outside the 'overflow: auto' container (no scrolling).
    // So we keep the scroll positions of the table header and body in sync using the below listener.

    // For now, header is not scroll-able by user (overflow: hidden).
    // Body is scrollable, and will set the header position programatically.
    // This avoids Safari jitter problems (when listenners on both header/body scroll),
    // there seemed to be some weird feedback occasionally where
    // header scroll triggers body scroll and so on...

    const header_el = this.table_header_ref.current
    const body_el = this.table_body_ref.current

    if (!body_el) {
      return
    }

    body_el.addEventListener('scroll', () => {
      // "scroll" event on body has just fired.

      if (body_el && header_el) {
        // Save the scroll position
        const scroll_x = body_el.scrollLeft
        this.scroll_x = scroll_x

        // Scroll the header (to same position as body)
        header_el.scrollLeft = scroll_x

        // Reposition labelling controls
        set_labelling_containers_scroll_x__throttled(body_el, scroll_x)
      }
    })
  }

  restore_scroll_x() {
    const body_el = this.table_body_ref.current
    const scroll_x = this.scroll_x

    if (body_el && (scroll_x != null)) {
      body_el.scrollLeft = scroll_x // no need to do header, as scroll event will do this
    }
  }

  to_rows(bulk_select, patfams, selected_patfams) {
    return (bulk_select && !_.isEmpty(patfams)) ? this.add_column_selector(patfams, selected_patfams)
      : (patfams || [])
  }

  get_columns() {
    const {
      fields,
      field_id_to_render_fn = {},

      show_suggestion_strategy,

      selected_sort_field_id,
      selected_sort_direction_id,
      selected_patent_link_option_id,

      on_change_sort_field_id_and_sort_direction_id,

      on_show_detail,

      // Eval classifier stuff
      show_classifier_controls,
      set_label,
      search_phrases,
      search_colours,
      no_highlighting,
      highlight_prefix_only,
      bulk_select,
      eval_training_set_id,   // optional
      eval_classifier_data,   // optional

      on_include_family_id_in_similar_search_input
    } = this.props

    if (!fields || fields.length === 0) return []

    const BULK_TAG_FIELD = { id: SELECT_FIELD_ID, sortable: false, value: false,
      cell_render: () => this.render_selectable_header(this.update_all_rows_selection) }

    const fields_full = [
      ...(show_classifier_controls ? [CLASSIFIER_LABEL_CONTROLS_FIELD] : []),
      ...(bulk_select ? [BULK_TAG_FIELD] : []),
      ...(on_include_family_id_in_similar_search_input ? [SIMILARITY_SEARCH_CONTROLS_FIELD] : []),
      ...fields,
      ...(show_suggestion_strategy ? [SUGGESTION_STRATEGY_FIELD, SUGGESTION_DETAIL_FIELD] : []),
    ]

    const highlighter_props = {
      no_highlighting,
      highlight_prefix_only,
      search_words: search_phrases,
      search_colours,
    }

    return fields_full.map((field) => {
      const { id, cell_render} = field

      const is_right_aligned = get_is_field_right_aligned(id)

      const custom_render_fn = field_id_to_render_fn[id]

      const width_props = get_width_props(id, show_suggestion_strategy)

      return {
        Header: () => { return id === SELECT_FIELD_ID ?
          cell_render() :
          <SortingColumnHeaderCell
            field={field}
            on_change_sort_field_id_and_sort_direction_id={on_change_sort_field_id_and_sort_direction_id}
            selected_sort_field_id={selected_sort_field_id}
            selected_sort_direction_id={selected_sort_direction_id}
          />
        },
        accessor: id,
        sortable: false,
        resizable: [CLASSIFIER_LABEL_CONTROLS_ID, SELECT_FIELD_ID].indexOf(id) === -1,
        className: cn(
          s.table_cell,
          s.table_cell__classifier_controls,
          { 'text-end': is_right_aligned },
          [SELECT_FIELD_ID, SIMILARITY_SEARCH_CONTROLS_FIELD_ID].indexOf(id) > -1 ? 'text-center' : ''
        ),
        headerClassName: cn(s.header_cell, {
          'text-end': is_right_aligned,
          'p-0': id === CLASSIFIER_LABEL_CONTROLS_ID
        }),
        ...width_props,
        Cell: (props) => {
          const {value, index, original: patfam} = props
          if (custom_render_fn) {
            // Custom render function, so use that.
            return custom_render_fn(value)
          }

          if (id === CLASSIFIER_LABEL_CONTROLS_ID) {
            const {is_pending, is_error, user_class} = patfam // these are pseudo-properties, possibly merged in from classifier training_set data structures

            return (
              <>
                <PatentFamilyClassifierMarker
                  label={user_class}
                />

                <div className={cn(
                  s.labelling_container,                                       // by default:       HIDE
                  'show-on-hover-flex',                                        // on hover row:     SHOW
                  { [s.labelling_container__show]: (is_pending || is_error) }, // on pending/error: SHOW
                  'align-items-center',
                )}>
                  <ClassifierStatusMessage
                    className={cn('ms-auto')}
                    is_saving={is_pending}
                    is_error={is_error}
                    short={true}
                  />
                  <ClassifierLabelControl
                    is_saving={is_pending}
                    set_label={set_label.bind(null, null, patfam)}
                    selected_label={user_class}
                  />
                </div>

              </>
            )
          }

          if (id === SUGGESTION_STRATEGY_FIELD_ID) {
            // Suggestion Strategy
            const { strategy_name } = patfam
            return (
              <div className={cn('d-flex')}>
                <SuggestionsStrategyName
                  strategy_id={strategy_name}
                  className={s.suggestions_name}
                />
              </div>
            )
          }

          if (id === SUGGESTION_DETAIL_FIELD_ID) {
            // Suggestion Detail
            const { strategy_name, strategy_details } = patfam
            return (
              <div className={cn('d-flex')}>
                <SuggestionsStrategyDetails
                  details={strategy_details}
                  strategy_id={strategy_name}
                  className={cn('')}
                />
              </div>
            )
          }

          if (id === SIMILARITY_SEARCH_CONTROLS_FIELD_ID) {
            const {on_include_family_id_in_similar_search_input, similar_search_input} = this.props
            const {cipherFamilyId, patFamId} = patfam
            return (
              <AddFamilyToInput
                family_id={patFamId}
                cipher_family_id={cipherFamilyId}
                similar_search_input={similar_search_input}
                on_click={on_include_family_id_in_similar_search_input}
              />
            )
          }

          // Defaults....
          if (value == null) {
            return null
          }

          if (id === CIPHER_FAMILY_ID_FIELD_ID) {
            // Family links
            return (
              <CipherFamilyLink
                family_id={value}
                on_family_id_click={on_show_detail.bind(null, index, value)}
                display_text_as_link={true}
                display_link_icon={true}

                show_similar_families_search={true}

                eval_training_set_id={eval_training_set_id}
                eval_classifier_data={eval_classifier_data}
              />
            )
          }
          if (_.contains([PATENT_NUMBERS_FIELD_ID], id)) {
            // Patent number links
            return value.map((patent_number, i) => (
              <PatentLink
                key={i}
                className={cn('me-1', 'd-inline-block')}
                patent_number={(patent_number || '').trim()}
                patent_link_mode_id={selected_patent_link_option_id}
              />
            ))
          }
          if (id === PRIMARY_PUBLICATION_FIELD_ID) {
            return (
              <PatentLink
                patent_number={(value || '').split('-').join('').trim()}
                patent_link_mode_id={selected_patent_link_option_id}
              />
            )
          }
          if (_.contains([OWNERS_FIELD_ID, ASSIGNEES_FIELD_ID, INVENTORS_FIELD_ID, PORTFOLIO_FIELD_ID, TECHNOLOGY_FIELD_ID], id)) {
            // PIPE-separated
            return (
              <Highlighter
                {...highlighter_props}
                text_to_highlight={value.join(' | ')}
              />
            )
          }
          if (_.contains([TAGS_ID], id)) {
            return (
              <FamilyTagsColumn
                pat_fam_id={patfam.patFamId}
                full_name_tags={value}
              />
            )
          }
          if (_.contains([CPC_CODES_FIELD_ID], id)) {
            return value.map((cpc_code, i) => {
              return (
                <CpcWithHover
                  key={i}
                  cpc_codes={[cpc_code]}
                  highlighter_props={highlighter_props}
                  show_separator={i < value.length - 1}
                  className='me-1'
                />
              )
            })
          }
          if (PRIMARY_CPC_CODE_FIELD_ID === id) {
            return (
              <CpcWithHover
                cpc_codes={[value]}
                highlighter_props={highlighter_props}
              />
            )
          }
          if (_.contains([GRANTED_TERRITORIES_FIELD_ID, PENDING_TERRITORIES_FIELD_ID, EXPIRED_TERRITORIES_FIELD_ID], id)) {
            // COMMA-spearated
            return value.join(', ')
          }
          if (_.contains([TOTAL_COST_FIELD_ID, TOTAL_COST_PROJECTION_FIELD_ID], id)) {
            // format number
            return format_integer_with_comma(value)
          }
          if (_.contains([SCORE_ID], id)) {
            // format score
            return (<span className='fs-unmask'>{display_score(value, ' | ')}</span>)
          }
          if (_.contains([TITLE_FIELD_ID, ABSTRACT_FIELD_ID, CLAIMS_FIELD_ID], id)) {
            return (
              <Highlighter
                {...highlighter_props}
                text_to_highlight={value}
              />
            )
          }
          if (_.contains([PVIX_SCORE_ID, SIMILARITY_SCORE_ID], id)) {
            return format_score(value)
          }
          if (id === SELECT_FIELD_ID) {
            return this.render_selectable_cell(this.update_rows_selection, false, value, index)
          }

          return value // simple value with no formatting
        }
      }
    })
  }

  render_selectable_cell(select_func, partial_value, value, index) {
    return (
      <CheckboxStatic
        is_partial={partial_value}
        is_checked={value}
        is_disabled={false}
        onClick={select_func.bind(this, index)}
      />
    )
  }

  render_selectable_header (select_func){
    const { rows } = this.state
    const selected = rows.filter(row => row[SELECT_FIELD_ID])
    const any_selected = selected.length > 0 && selected.length < rows.length
    const all_selected = selected.length === rows.length && !_.isEmpty(rows)
    return this.render_selectable_cell(select_func, any_selected , all_selected)
  }

  ensure_selected_row_is_visible() {
    const {
      patfams,
      is_fetching,
    } = this.props

    // Ideally we would not use sticky headers, and also would calculate this stuff dynamically.
    // But for now, we pass in explicit values 'page_top' and 'table_top'

    if (is_fetching || !patfams || (patfams.length === 0)) {
      return
    }

    // Get selected row
    const row_el = this.selected_row_ref.current
    if (!row_el) {
      return
    }

    const onscreen = is_element_vertically_onscreen(row_el, this.wrapper_ref.current.offsetTop, this.wrapper_ref.current.offsetTop + this.wrapper_ref.current.offsetHeight)

    if (onscreen) {
      return
    }

    scroll_to_centre(this.selected_row_ref, SCROLL_INTO_VIEW_BEHAVIOUR_INSTANT)
  }

  add_column_selector(data, selected_data) {
    return data.map(row => {
      row[SELECT_FIELD_ID] = _.contains(selected_data, row.patFamId)? true : false
      return row
    })
  }

  update_rows_selection(row_number) {
    const { selected_patfams, set_selected_patfams } = this.props
    const { rows } = this.state
    const new_rows = JSON.parse(JSON.stringify(rows))
    new_rows[row_number][SELECT_FIELD_ID] = !rows[row_number][SELECT_FIELD_ID]
    const new_selected_patfams = this.update_selected_patfams(new_rows[row_number], selected_patfams)
    set_selected_patfams(new_selected_patfams)
    this.setState( {'rows': new_rows})
  }

  update_selected_patfams(selected_row, selected_patfams) {
    const selected_patfam_id = selected_row[PAT_FAM_ID_FIELD_ID]
    let new_selected_patfams = []
    if (selected_row[SELECT_FIELD_ID]) {
      new_selected_patfams = [...selected_patfams, selected_patfam_id]
    } else {
      if (_.contains(selected_patfams, selected_patfam_id)) {
        new_selected_patfams = selected_patfams.filter(pat_fam_id => pat_fam_id !== selected_patfam_id)
      }
    }
    return new_selected_patfams
  }

  update_all_rows_selection() {
    const { set_selected_patfams } = this.props
    const { rows } = this.state
    const all_selected = rows.filter(row => row[SELECT_FIELD_ID]).length === rows.length
    const new_selected_patfams = []
    const new_rows = rows.map(row => {
      const new_row = {...row}
      new_row[SELECT_FIELD_ID] = !all_selected
      if (new_row[SELECT_FIELD_ID]){
        new_selected_patfams.push(row[PAT_FAM_ID_FIELD_ID])
      }
      return new_row
    })
    set_selected_patfams(new_selected_patfams)
    this.setState( {'rows': new_rows})
  }


  render() {
    const {className, headerClassName, patfams, is_fetching, subidx, show_classifier_controls} = this.props
    const { rows } = this.state

    if (is_fetching || !patfams || (patfams.length === 0)) {
      return (
        // return an empty div (we need component to stay rendered, so that it can hold the scroll_x position)
        <div />
      )
    }

    const columns = this.get_columns()
    if (columns.length === 0) {
      return (
        <div />
      )
    }

    return (
      <div className={className} ref={this.wrapper_ref}>
        <ReactTable
          getTrProps={(final_state, row_info) => {
            return {row_info} // pass row details into our custom TrComponent
          }}

          getTdProps={(final_state, row_info, column) => {
            return {row_info, column} // pass row details into our custom TdComponent
          }}

          manual
          filterable={false}
          sortable={false}

          columns={columns}
          data={rows}

          minRows={0}

          TrComponent={({children, className, row_info, ...rest}) => {
            const {index} = row_info || {} // i.e. header doesn't have a row_info object
            const is_selected_row = (index === subidx)

            return (
              <div
                ref={is_selected_row ? this.selected_row_ref : null}
                className={cn(
                  'rt-tr',
                  { [s.table_row__highlight_on_hover]: show_classifier_controls,
                    [s.table_cell__selected]: is_selected_row,
                    'row-selected': is_selected_row
                  },
                  className
                )}
                role='row'
                {...rest}
              >
                {children}
              </div>
            )
          }}

          TdComponent={({className, children, row_info, column, ...rest}) => {
            const { index } = row_info
            const is_selected_row = (index === subidx)

            const is_classifier_marker = (typeof column.Header === 'function') ?
              (column.Header().props['field'] || {})['id'] === CLASSIFIER_LABEL_CONTROLS_ID : false

            return (
              <div
                className={cn(
                  'rt-td',
                  {
                    [s.table_cell__selected]: is_selected_row,
                    'p-0': is_classifier_marker
                  },
                  className
                )}
                role='gridcell'
                {...rest}
              >
                {children}
              </div>
            )
          }}

          TheadComponent={(
            {
              children,
              className,
              style, /* hack to make sure ReactStrap does not add style={min-width} here */   // eslint-disable-line no-unused-vars
              ...rest
            }) => (
            <div
              ref={this.table_header_ref}
              className={cn('rt-thead', s.table_header, headerClassName, className)}
              {...rest}
            >
              {children}
            </div>
          )}

          TbodyComponent={(
            {
              children,
              className,
              style, /* hack to make sure ReactStrap does not add style={min-width} here */   // eslint-disable-line no-unused-vars
              ...rest
            }) => (
            <div
              ref={this.table_body_ref}
              className={cn('rt-tbody', className)}
              {...rest}
            >
              {children}
            </div>
          )}

          className={cn(
            s.table_container,
            'border-0',
            'patent_family_list'
          )}
          showPagination={false}
        />
      </div>
    )
  }
}

export default PatentFamiliesTable