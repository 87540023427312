const IS_SHARED_FIELD_ID = 'is_shared'
export const LIST_NAME_FIELD_ID = 'list_name'
const IS_MISSING_FIELD_ID = 'is_missing'
const IS_SELECTED_FIELD_ID = 'is_selected'
export const DISPLAY_NAME_FIELD_ID = 'name'
export const ORIGINAL_NAME_FIELD_ID = 'original_name'
export const SIZE_ACTIVE_FIELD_ID = 'size_active'
export const SIZE_FIELD_ID = 'size'
export const COMPANY_ID_FIELD_ID = 'company_id'

export const FIELD_IS_SHARED = {id: IS_SHARED_FIELD_ID, accessor: IS_SHARED_FIELD_ID, name: 'Owner', sortable: true}
export const FIELD_LIST_NAME = {id: LIST_NAME_FIELD_ID, accessor: LIST_NAME_FIELD_ID, name: 'Name', sortable: true}
export const FIELD_IS_SELECTED = {id: IS_SELECTED_FIELD_ID, accessor: IS_SELECTED_FIELD_ID, sortable: false}
export const FIELD_IS_MISSING = {id: IS_MISSING_FIELD_ID, accessor: IS_MISSING_FIELD_ID, sortable: false}
export const FIELD_DISPLAY_NAME = {id: DISPLAY_NAME_FIELD_ID, accessor: DISPLAY_NAME_FIELD_ID, name: 'Display name', sortable: true}
export const FIELD_ORIGINAL_NAME = {id: ORIGINAL_NAME_FIELD_ID, accessor: ORIGINAL_NAME_FIELD_ID, name: 'Original name', sortable: true}
export const FIELD_SIZE_ACTIVE = {id: SIZE_ACTIVE_FIELD_ID, accessor: SIZE_ACTIVE_FIELD_ID, name: 'Active size', sortable: true}
export const FIELD_SIZE = {id: SIZE_FIELD_ID, accessor: SIZE_FIELD_ID, name: 'Total size', sortable: true}

export const FIELDS_TO_EXPORT = [ORIGINAL_NAME_FIELD_ID, DISPLAY_NAME_FIELD_ID, SIZE_ACTIVE_FIELD_ID, SIZE_FIELD_ID]