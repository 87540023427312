import React from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import { capitalize } from 'lodash-es'

import { withUser } from '../../UserContext.js'
import EditableReportLink from './EditableReportLink.js'
import { EditReportTagsToggleButton } from './EditReportTagsToggleButton.js'
import { TagSelectorDropdown } from './TagSelectorDropdown.js'
import TagButton from '../../widgets/TagButton.js'

import { is_admin } from '../../../utils/user_permissions.js'
import { report_can_be_saved, alphabetise_tags } from '../utils/sort_and_filter_utils.js'
import { report_has_bespoke_viewer } from '../../../utils/report_url_utils.js'
import { REPORT } from '../../../constants/paths.js'
import TextLink from '../../widgets/TextLink.js'

import s from './ReportNameField.module.scss'

const ReportNameField = ({
  user, report, tags, eval_classifier_name, is_editing_report_tags, is_renaming_report, tracking_context,
  on_edit_report_name, on_confirm_rename_report, on_cancel_edit_report_name, is_showing_selected_tags, toggle_is_showing_selected_tags,
  handle_edit_report_tags, tag_search_input, handle_update_tag_search_string, handle_create_new_tag, handle_manage_tags,
  handle_tag_or_untag, handle_select_tag
}) => {

  const alphabetised_tags = alphabetise_tags(report.tags)
  const has_bespoke_viewer = report_has_bespoke_viewer(report.report_type)

  return (
    <div>
      { eval_classifier_name &&
        <div>{eval_classifier_name}</div>
      }
      <div className='d-flex flex-wrap mb-1'>
        <div className={cn('d-flex', 'flex-wrap', s.editable_report_name)}>
          { has_bespoke_viewer &&
            <span className='me-1'>{capitalize(report.report_type)}:</span>
          }
          <EditableReportLink
            report={report}
            tracking_context={tracking_context}
            user_is_admin={is_admin(user)}
            on_edit={on_edit_report_name}
            on_confirm={on_confirm_rename_report}
            on_cancel={on_cancel_edit_report_name}
            is_edit={is_renaming_report}
          />
        </div>
        <div className={'d-flex ms-auto'}>
          <TagSelectorDropdown
            toggle_button={(
              <EditReportTagsToggleButton
                is_open={is_editing_report_tags}
                disabled={!report_can_be_saved(report)}
              />
              )}
            handle_edit_tag_selections={handle_edit_report_tags}
            is_open={is_editing_report_tags}
            tags={tags}
            selected_tag_ids={report.tag_ids || []}
            is_showing_selected={is_showing_selected_tags}
            toggle_is_showing_selected={toggle_is_showing_selected_tags}
            search_string={tag_search_input}
            handle_update_search_string={handle_update_tag_search_string}
            handle_create_new_tag={handle_create_new_tag}
            handle_manage_tags={handle_manage_tags}
            handle_select_tag={handle_tag_or_untag}
          />
        </div>
      </div>

      <div>
        <span>
          { has_bespoke_viewer &&
            <TextLink
              className='small me-1'
              element={Link}
              to={`${REPORT}/${report.external_report_id}`}
            >[open in main Cipher]</TextLink>
          }
          {alphabetised_tags.map((tag, i) => (
            <TagButton
              key={i}
              name={tag.name}
              on_click={() => handle_select_tag(tag)}
            />
          ))}
        </span>
      </div>
    </div>
  )

}

export default withUser(ReportNameField)