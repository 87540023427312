import React, { useRef, useEffect } from 'react'
import cn from 'classnames'

import { RetryIcon } from './IconSet.js'
import Spinner from './Spinner.js'
import InputClearControl from './InputClearControl.js'
import { Input } from 'reactstrap'

import s from './ClearableSearchInput.module.scss'

const ClearableSearchInput = ({
  disabled,
  inputClassName,
  clearClassName,
  refreshClassName,
  spinnerClassName,
  containerClassName,
  value,
  auto_focus,
  input_ref,
  handle_change,
  handle_refresh,
  handle_key_down,
  placeholder,
  show_clear,
  show_refresh,
  show_spinner,
  feedback,
  invalid
}) => {

  const default_input_ref = useRef()

  input_ref = input_ref || default_input_ref

  const is_active = value != null && value !== ''

  useEffect(() => {
    if (auto_focus) {
      // Workaround to ensure focus is set
      // This runs when the component first mounts
      input_ref.current.focus()
    }
  }, [auto_focus, input_ref])


  return (
    <div className={cn(s.container, containerClassName)}>
      <Input
        ref={input_ref}
        className={cn('form-control', s.input, inputClassName)}
        autoComplete='off'
        autoCorrect='off'
        spellCheck='false'
        placeholder={(placeholder != null) ? placeholder : 'Filter by keyword'}
        value={value}
        disabled={disabled}
        type='text'
        autoFocus={auto_focus} // NOTE: don't focus() on tablets, as keyboard will keep popping back up on every following navigation change.
        onChange={(event) => handle_change(event.target.value)}
        onKeyDown={(event) => {return handle_key_down ? handle_key_down(event) : null}}
        invalid={invalid}
      />

      {feedback}

      <div className={cn('d-flex', s.input_addon, {[s.input_addon__active]: is_active})}>
        {show_spinner &&
          <span className={cn('d-flex', 'align-items-center', 'me-2', s.spinner, spinnerClassName)}>
            <Spinner />
          </span>
        }

        {show_refresh &&
          <span
            className={cn('d-flex', 'align-items-center', 'me-2', refreshClassName)}
            onClick={() => {
              handle_refresh()
            }}
            title='Refresh'
          >
            <RetryIcon />
          </span>
        }

        {show_clear &&
          <InputClearControl
            className={clearClassName}
            on_click={() => {
              handle_change('') // clear input
              input_ref.current.focus()
            }}
          />
        }
      </div>
    </div>
  )
}

export default ClearableSearchInput