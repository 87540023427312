import React from 'react'
import cn from 'classnames'

import FamilyActions from '../tech_explorer/FamilyActions.js'
import { FamilyDetailsTile } from './FamilyDetailsTile.js'
import { Label } from './FamilyDetailsLabel.js'
import InputStatusMarker from '../tech_explorer/InputStatusMarker.js'

import s from './TechExplorerSelectionControl.module.scss'

const TechExplorerSelectionControls = (
  {
    family_id,

    knn_family_ids,
    knn_blocklist,
    knn_bookmarked,
    on_add_to_knn_family_ids,
    on_add_to_knn_blocklist,
    on_add_to_knn_bookmarked,

    className
  }) => {
  return (
    <FamilyDetailsTile
      className={cn('py-0 px-0', className)}
    >
      <InputStatusMarker
        family_id={family_id}
        positives={knn_family_ids}
        negatives={knn_blocklist}
        bookmarked={knn_bookmarked}
        display='card'
        className={s.status_marker}
      />
      <div className={cn('px-3 d-flex justify-content-between', s.controls)}>
        <Label inline>Actions</Label>
        <FamilyActions
          family_id={family_id}
          family_ids={knn_family_ids}
          blocklist={knn_blocklist}
          bookmarked={knn_bookmarked}

          on_add_to_family_ids={on_add_to_knn_family_ids}
          on_add_to_blocklist={on_add_to_knn_blocklist}
          on_add_to_bookmarked={on_add_to_knn_bookmarked}
        />
      </div>
    </FamilyDetailsTile>
  )
}

export default TechExplorerSelectionControls