import React from 'react'
import cn from 'classnames'

import TextLink from '../widgets/TextLink.js'
import { MoveAsGroupOutIcon, TrashIcon } from '../widgets/IconSet.js'
import Spinner from '../widgets/Spinner.js'
import { is_organisation } from '../../utils/organisation_utils.js'
import OrgTagsPopup from '../orgs/OrgTagsPopup.js'

import s from './Basket.module.scss'

const Basket = ({items, on_remove, show_spinner, className}) => {

  const has_items = (items && items.length > 0)

  return (
    <div className={cn('p-1', className)}>
      {(show_spinner || !has_items) &&
        <div className={cn('w-100', s.spinner_overlay)}>
          <div className={cn('w-100 h-100 d-inline-flex')}>
            {show_spinner &&
              <Spinner className='m-auto'/>
            }

            {!show_spinner && !has_items &&
              <div className='m-auto'>no organisations selected for grouping</div>
            }
          </div>
        </div>
      }

      <div className={cn('d-flex flex-wrap', s.wrapper)}>
        {items.map((item, i) => {
          const {name, tags, id} = item

          const is_org = is_organisation(item)

          return (
            <div className={cn('d-flex me-2 mb-2 p-1', s.item, {[s.item__org]: is_org})} key={i}>
              {is_org &&
                <span className='me-1'>
                 <MoveAsGroupOutIcon className={s.icon} />
                </span>
              }
              {name}
              {tags && <OrgTagsPopup className='ms-1' tags={tags} popup_reference={'basket_tags_'+id}/>}
              <TextLink className='ms-1' onClick={() => on_remove(i)} no_decoration><TrashIcon /></TextLink>
            </div>
          )})}
      </div>
    </div>
  )
}

export default Basket