import React, { useEffect, useState } from 'react'
import { DropdownItem } from 'reactstrap'
import _ from 'underscore'
import { Autocomplete, TextField } from '@mui/material'

import {
  from_str_to_tag_permission,
  tag_permission_as_str,
  TagPermission,
  UUIDType,
} from './family_tag_utils'
import TextLink from '../widgets/TextLink.js'
import { FormFeedback } from '../widgets/FormFeedback.js'
import BaseDropdown from '../widgets/BaseDropdown.js'


interface EntityPermissionProps {
  entities: Array<any>,
  placeholder: string,
  disabled: boolean,
  selected_entity: Array<any>,
  set_selected_entity: Function,
  selected_permission: TagPermission,
  set_selected_permission: Function,
  update_permissions: Function,
  className: string,
}

const TagPermissionsKeys = Object.keys(TagPermission).filter((key) => isNaN(Number(key)))
const USER_STR_MARKER = '(USER) '
const GROUP_STR_MARKER = '(GROUP) '

function is_user(entity: any) {
  return entity.type === UUIDType.USER
}

export const EntityPermission = (
  {
    entities,
    placeholder: default_placeholder,
    disabled,
    selected_entity,
    set_selected_entity,
    selected_permission,
    set_selected_permission,
    update_permissions,
    className
  }: EntityPermissionProps) => {

  const [autocomplete_entities, set_autocomplete_entities] = useState<Array<any>>([])
  const [invalid_selection, set_invalid_selection] = useState<boolean>(false)

  useEffect(() => {
    const marked_entities = entities.map((entity: any) => {
      return {label: (is_user(entity) ? USER_STR_MARKER : GROUP_STR_MARKER) + entity.display_name, id: entity.id}
    })
    set_autocomplete_entities(marked_entities)

  }, [entities])

  const placeholder = (selected_entity || []).length ? '' : default_placeholder

  return (
    <div className={className}>
      <div className='d-flex align-baseline'>
        <Autocomplete
          options={autocomplete_entities}
          onChange={(_event, selection) => {
            set_selected_entity(selection)
            if (!_.isEmpty(selection)) {
              set_invalid_selection(false)
            }
          }}
          multiple={true}
          freeSolo={false}
          disablePortal={true}
          clearOnBlur={true}
          limitTags={2}
          disabled={disabled}
          className='w-50 me-1'
          renderInput={(params) => (<TextField {...params} placeholder={placeholder}/>)}
          ListboxProps={{style: {maxHeight: '10rem'} /* workaround for long menu flicker */}}
        />

        <BaseDropdown
          className='ms-1'
          disabled={disabled}
          label={tag_permission_as_str(selected_permission)}
        >
          {TagPermissionsKeys.map((option, i) => {
            const is_selected = from_str_to_tag_permission(option) === selected_permission
            return (
              <DropdownItem key={i} onClick={() => set_selected_permission(option)} active={is_selected}>
                {tag_permission_as_str(option)}
              </DropdownItem>
            )
          })}
        </BaseDropdown>
        {/*//@ts-expect-error*/}
        <TextLink onClick={check_and_update_permissions} className={'ms-2 mt-2'}>
          Add
        </TextLink>
      </div>
      <div className='mb-2'>
        <FormFeedback
          valid={!invalid_selection}
          validation_text='At least one user or group should be selected'
        />
      </div>
    </div>
  )

  function check_and_update_permissions() {
    if (_.isEmpty(selected_entity)) {
      set_invalid_selection(true)
    } else {
      update_permissions()
    }
  }
}