import React, { useRef, useEffect } from 'react'

import { Input } from 'reactstrap'
import { FormFeedback } from './FormFeedback.js'

/**
 * Allows us to autoFocus on an input field within a Modal.
 * (The usual autoFocus property on the Input is no good here as it is overridden by the Modal.)
 */
export const InputWithAutofocus = ({value, on_change, on_key_down, on_key_up, invalid, validation_text, auto_focus, size='md' /* 'lg' or 'sm'*/, disabled}) => {

  const input_field = useRef(null)

  useEffect(() => {
    if (auto_focus && auto_focus !== true) {
      // auto focus can mess up navigation on a tablet; use this property to override it when needed
      return
    }
    input_field.current.focus()
  }, [auto_focus])

  return (
    <div className='w-100'>
      <Input
        value={value}
        onChange={on_change}
        onKeyDown={on_key_down}
        onKeyUp={on_key_up}
        invalid={invalid}
        innerRef={input_field}
        disabled={disabled}
        bsSize={size}
        autoComplete='off'
      />
      {validation_text &&
        <FormFeedback
          valid={!invalid}
          validation_text={validation_text}
        />
      }
    </div>
  )
}

