import React, {useState} from 'react'
import Modal from '../widgets/Modal.js'
import { PrimaryButton } from '../widgets/Button.js'
import FileUploadDropzone from './FileUploadDropzone.tsx'

const FileUploadModal = ({ title, on_hide, on_upload, disabled, max_file_count, max_file_size}) => {
  const [files_to_upload, set_files_to_upload] = useState([])

  const has_files_to_upload = files_to_upload && files_to_upload.length > 0

  return (
    <Modal
      on_hide={on_hide}
      title={title}
      close_label='Cancel'
      primary_button={(
        <PrimaryButton
          onClick={() => on_upload(files_to_upload)}
          disabled={disabled || !has_files_to_upload}
        >
          Upload
        </PrimaryButton>
      )}
    >
      <FileUploadDropzone
        dropzone_options={{
          maxFiles: max_file_count,
          maxFilesize: max_file_size,
          autoFocus: true,
          onDropAccepted: set_files_to_upload,
          disabled: disabled,
        }}
      />
    </Modal>
  )
}

export default FileUploadModal