import React from 'react'
import cn from 'classnames'

import TextLink from './TextLink.js'
import { RemoveIcon } from './IconSet.js'

import cs from '../cipher_styles.module.scss'
import s from './Lozenge.module.scss'

export const Lozenge = ({label, title, on_click, on_click_remove, className, removeButtonClassName}) => {

  function handle_click() {
    if (!on_click) return
    on_click()
  }

  return (
    <div className={cn('d-inline', s.lozenge, {[cs.cursor_pointer]: on_click}, className)} onClick={handle_click} title={title}>
      <span className='my-auto'>{label}</span>
      {on_click_remove &&
        <TextLink
          onClick={on_click_remove}
          no_decoration
          className={cn('ms-2', s.remove_btn, removeButtonClassName)}
        >
          <RemoveIcon />
        </TextLink>
      }
    </div>
  )
}

