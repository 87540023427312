import axios from 'axios'

import { OCYPOD_BASE_URL } from '../constants/urls.js'
import { add_source_err_to_target_err } from './axios_utils.js'

export function queue_ocypod_job(payload, job_queue) {
  return axios.post(`${OCYPOD_BASE_URL}/queue/${job_queue}/job`, payload)
    .catch((err) => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to queue set theory report job: ')
    })
}