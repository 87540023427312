import React, { useState, useRef } from 'react'
import cn from 'classnames'

import FamilyImage from './FamilyImage.js'
import Spinner from '../widgets/Spinner.js'

import s from './ImagesSelector.module.scss'

function images_loaded(wrapper_el) {
  const images = [...wrapper_el.querySelectorAll('img')]
  for (let i = 0; i < images.length; i++) {
    const image = images[i]
    if (!image.complete) {
      return false
    }
  }
  return true
}

const ImageSelector = ({images, selected_image_idx, on_click_handler, is_vertical= false}) => {
  const carousel_ref = useRef()

  const [is_loading, set_is_loading] = useState(true)

  function on_image_load_or_error() {
    // Here we ignore the input args.
    // But other handlers (i.e. in the image modal) do use these args.
    // TODO: perhaps simpler to move some of this data logic uptree (then these downtree components become stateless)?
    // TODO: better to explicitly have on_load and on_error handlers (i.e. I have overloaded this here for now)
    const is_all_images_loaded = images_loaded(carousel_ref.current)

    set_is_loading(!is_all_images_loaded)
  }

  return (
    <div className={cn(s.block)}>
      {is_loading &&
        <span className={s.spinner_wrapper}><Spinner/></span>
      }

      <div className={cn(s.carousel, {'d-flex flex-column pt-3': is_vertical})} ref={carousel_ref}>
        {images.map((image, i) => (
          <FamilyImage
            key={i}
            selected={i===selected_image_idx}
            className={cn([
              'me-1',
              {'mb-3': is_vertical},
              s.image
            ])}
            image={image}
            is_thumbnail={true}
            on_click_handler={() => on_click_handler(i)}
            on_image_load_or_error={on_image_load_or_error}
          />
        ))}
      </div>
    </div>
  )
}

export default ImageSelector