import React from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import cn from 'classnames'

import { AppsIcon } from '../widgets/IconSet.js'
import TextLink from '../widgets/TextLink.js'
import { TrademarkSymbol } from '../widgets/TrademarkSymbol.js'
import { useToggle } from '../../hooks/general_hooks.js'
import { is_tech_discovery } from '../../utils/utils.js'

import cs from '../cipher_styles.module.scss'
import s from './HeaderAppsMenu.module.scss'

const HeaderAppsMenu = () => {
  const [is_apps_menu_open, toggle_apps_menu] = useToggle(false)

  return (
    <Dropdown
      isOpen={is_apps_menu_open}
      toggle={toggle_apps_menu}
      size='sm'
    >
      <DropdownToggle
        tag='div'
        className={s.apps_menu_toggle}
      >
        <span className={cn('px-1 h-100 d-flex', {[s.apps_menu_toggle__active]: is_apps_menu_open})}><AppsIcon className='my-auto'/></span>
      </DropdownToggle>

      <DropdownMenu
        container='body'
        className={s.apps_menu}
      >
        <div className='d-flex'>
          <div className={cn('p-4 d-flex flex-column') }>
            <TextLink element='a' href='https://go.patentsight.com' target='_blank' className={cn('mb-2', cs.white_text, s.apps_menu_link)}>PatentSight<sup>®</sup></TextLink>
            <TextLink element='a' href='https://www.totalpatentone.com/search' target='_blank' className={cn('mb-2', cs.white_text, s.apps_menu_link)}>TotalPatentOne<sup>®</sup></TextLink>
            <TextLink element='a' href='https://go.patentadvisor.com/login.php' target='_blank' className={cn('mb-2', cs.white_text, s.apps_menu_link)}>PatentAdvisor<sup>®</sup></TextLink>
            <TextLink element='a' href='https://www.patentoptimizer.com/' target='_blank' className={cn('mb-2', cs.white_text, s.apps_menu_link)}>PatentOptimizer<sup>®</sup></TextLink>
            {is_tech_discovery() &&
              <TextLink element='a' href='https://app.cipher.ai' target='_blank' className={cn('mb-2', cs.white_text, s.apps_menu_link)}>Classification</TextLink>
            }
            <TextLink element='a' href='https://platform.iplytics.com/login' target='_blank' className={cn(cs.white_text, s.apps_menu_link)}>IPLytics<TrademarkSymbol /></TextLink>
          </div>
          <div className={cn('my-4', s.apps_menu_divider)} />
          <div className={cn('p-4 d-flex flex-column') }>
            <TextLink element='a' href='https://www.lexisnexisip.com/products/ip-data-direct' target='_blank' className={cn('mb-2', cs.white_text, s.apps_menu_link__small)}>IP DataDirect</TextLink>
            <TextLink element='a' href='https://www.law360.com/ip' target='_blank' className={cn('mb-2', cs.white_text, s.apps_menu_link__small)}>Law360<sup>®</sup></TextLink>
            <TextLink element='a' href='https://plus.lexis.com' target='_blank' className={cn('mb-2', cs.white_text, s.apps_menu_link__small)}>Lexis+<sup>®</sup></TextLink>
            <TextLink element='a' href='https://law.lexmachina.com' target='_blank' className={cn('mb-2', cs.white_text, s.apps_menu_link__small)}>Lex Machina<sup>®</sup></TextLink>
            <TextLink element='a' href='https://reedtechip.com/pairwatch' target='_blank' className={cn(cs.white_text, s.apps_menu_link__small)}>PAIR Watch</TextLink></div>
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}

export default HeaderAppsMenu
