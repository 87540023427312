import React from 'react'
import cn from 'classnames'
import _ from 'underscore'

import ScrollableList from './ScrollableList.js'
import DatePicker from 'react-datepicker'
import { DropdownItem } from 'reactstrap'
import { ChevronLeftIcon, ChevronRightIcon, TriangleDownIcon } from './IconSet.js'
import TextLink from './TextLink.js'
import BaseDropdown from './BaseDropdown.js'
import { get_date_obj_as_date_string, MONTH_NAMES_LONG } from '../../utils/date_range_utils.js'

import s from './CalendarDatePicker.module.scss'

function get_available_month_idxs(min_date, max_date, year_range) {
  if (year_range.length > 2) {
    return _.range(0,12)
  }
  const start_month_idx = min_date.getMonth()
  const end_month_idx = max_date.getMonth()
  if (min_date.getYear() === max_date.getYear()) {
    // two dates are in the same year
    return _.range(start_month_idx, end_month_idx + 1)
  }
  return new Set([
    ..._.range(start_month_idx, 12),
    ..._.range(0, end_month_idx + 1)
  ])
}

const CalendarDatePicker = ({selected_date, on_date_change, className, iconClassName, min_date, max_date, is_clearable, disabled}) => {
  const min_year = min_date ? min_date.getFullYear() : 1980
  const max_year = max_date ? max_date.getFullYear() : 2040
  const year_range = _.range(min_year, max_year + 1)
  const available_month_idxs = get_available_month_idxs(min_date, max_date, year_range)

  function handle_change(new_date) {
    if (!new_date) {
      if (is_clearable) {
        on_date_change(false)
      }
      return
    }
    const date_regex = new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}$')
    const new_date_as_string = get_date_obj_as_date_string(new_date)
    if (!date_regex.test(new_date_as_string)) return
    on_date_change(new_date_as_string)
  }

  function render_header_dropdown_label(label) {
    return (
      <span>
        {label}
        <span className='ms-1'>
          <TriangleDownIcon/>
        </span>
      </span>
    )
  }

  function render_custom_header({date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled}) {
    const month_shown = MONTH_NAMES_LONG[date.getMonth()]
    const year_shown = date.getFullYear()

    return (
      <div className='mx-2 mb-1 d-flex justify-content-between'>
        <BaseDropdown
          buttonClassName={s.calendar_dropdown_button}
          menuClassName={s.calendar_dropdown}
          label={render_header_dropdown_label(month_shown)}
          hide_chevron={true}
        >
          <ScrollableList>
            {MONTH_NAMES_LONG.map((month, idx) => {
              if (!_.contains(available_month_idxs, idx)) {
                return null
              }
              return (
                <DropdownItem
                  key={month}
                  active={month === month_shown}
                  onClick={() => changeMonth(idx)}
                >
                  {month}
                </DropdownItem>
              )})
            }
          </ScrollableList>
        </BaseDropdown>
        <BaseDropdown
          buttonClassName={cn(s.calendar_dropdown_button, s.calendar_year_dropdown_button)}
          menuClassName={s.calendar_dropdown}
          label={render_header_dropdown_label(year_shown)}
          hide_chevron={true}
        >
          <ScrollableList>
            {year_range.map(year => {
              return (
                <DropdownItem
                  key={year}
                  active={year === year_shown}
                  onClick={() => changeYear(year)}
                >
                  {year}
                </DropdownItem>
              )})
            }
          </ScrollableList>

        </BaseDropdown>
        <TextLink
          className='my-auto ms-2 me-1'
          disable={prevMonthButtonDisabled}
          onClick={decreaseMonth}
          no_decoration
        >
          <ChevronLeftIcon/>
        </TextLink>
        <TextLink
          className='my-auto mx-1'
          disable={nextMonthButtonDisabled}
          onClick={increaseMonth}
          no_decoration
        >
          <ChevronRightIcon/>
        </TextLink>
      </div>
    )
  }

  return (
    <DatePicker
      selected={selected_date}
      onChange={handle_change}
      dateFormat='yyyy-MM-dd'
      className={cn(s.date_input, {[s.date_input__disabled]: disabled}, className)}
      showPopperArrow={false}
      formatWeekDay={nameOfDay => nameOfDay.substr(0,1)}
      minDate={min_date}
      maxDate={max_date}
      isClearable={is_clearable && selected_date}
      renderCustomHeader={render_custom_header}
      popperPlacement='bottom-start'
      showIcon
      icon={cn('icon icon-Interactive-Icon---Time-Filter', {'react-datepicker__calendar-icon__disabled': disabled}, iconClassName)}
      toggleCalendarOnIconClick={true}
      disabled={disabled}
    />
  )
}

export default CalendarDatePicker