import React, { useEffect, useState } from 'react'
import _ from 'underscore'
import cn from 'classnames'

import { KEYCLOAK_ROLES_TO_EXCLUDE, KEYCLOAK_ROLES_TO_EXCLUDE_AT_USER_LEVEL, fetch_user_roles } from '../../utils/user_management_utils.js'

import Spinner from '../widgets/Spinner.js'
import ErrorBody from '../ErrorBody.js'
import RoleRow from './RoleRow.js'
import AddRoleModal from './AddRoleModal.js'
import RemoveRoleModal from './RemoveRoleModal.js'
import Modal from '../widgets/Modal.js'
import { fetch_child_roles, get_all_child_role_ids } from '../../utils/user_group_utils.js'
import { PlusIcon } from '../widgets/IconSet.js'
import { TertiaryButton } from '../widgets/Button.js'

import s from './UserRolesModal.module.scss'

const UserRolesModal = ({
  user,
  group_level_roles,
  group_child_role_ids,
  on_close,
}) => {

  const [user_roles, set_user_roles]               = useState(null)
  const [id_to_child_roles, set_id_to_child_roles] = useState(null)
  const [is_fetching, set_is_fetching]             = useState(true)
  const [error_fetching, set_error_fetching]       = useState(null)

  const [role_to_remove, set_role_to_remove]       = useState(null)
  const [is_show_add_modal, set_is_show_add_modal] = useState(false)

  const roles_to_exclude = [...KEYCLOAK_ROLES_TO_EXCLUDE, ...KEYCLOAK_ROLES_TO_EXCLUDE_AT_USER_LEVEL]

  const user_child_role_ids = id_to_child_roles == null ? [] : get_all_child_role_ids(id_to_child_roles)

  const all_child_role_ids = _.unique([...user_child_role_ids, ...group_child_role_ids])

  const user_roles__clean = user_roles == null ?
                                null :
                                user_roles.filter(role => !roles_to_exclude.includes(role.name))
                                          .filter(role => !all_child_role_ids.includes(role.id))

  useEffect(() => {
    // Fetch user roles
    fetch_user_roles(user.id)
    .then(roles => {
      return fetch_child_roles(roles)
        .then(id_to_child_roles => {
          return [roles, id_to_child_roles]
        })
    })
    .catch(err => {
      // FAIL
      set_error_fetching(err)
      throw err
    })
    .then(([roles, id_to_child_roles]) => {
      // SUCCESS
      set_user_roles(roles)
      set_id_to_child_roles(id_to_child_roles)
    })
    .finally(() => {
      set_is_fetching(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* MAIN modal */}
      <Modal
        size='sm'
        title={`Roles for ${user.email}`}
        close_label={'Close'}
        on_hide={on_close}
      >
        {is_fetching &&
          <Spinner size={'sm'}/>
        }

        {user_roles &&
          <div className='my-2'>
            {user_roles__clean.map((role, idx) => {
              return (
                <RoleRow
                  key={idx}
                  role={role}
                  id_to_child_roles={id_to_child_roles}
                  on_remove={() => set_role_to_remove(role)}
                />
              )
            })}

            {user_roles__clean.length === 0 &&
              <div className={cn('text-muted')}>
                (No roles)
              </div>
            }

            <TertiaryButton
              size='sm'
              className='mt-2'
              onClick={() => set_is_show_add_modal(true)}
            >
              <PlusIcon/>
              <span className='ms-1'>Add role</span>
            </TertiaryButton>
          </div>
        }

        <div className={cn( 'mt-3', s.note)}>Note that user also receives group roles.</div>

        {/* ERRORS */}
        {error_fetching &&
          <ErrorBody
            error={error_fetching}
            context={'fetching roles'}
          />
        }
      </Modal>


      {/* REMOVE modal */}
      {role_to_remove &&
        <RemoveRoleModal
          user={user}
          role_to_delete={role_to_remove}
          local_roles={user_roles}
          set_local_roles={set_user_roles}

          id_to_child_roles={id_to_child_roles}
          set_id_to_child_roles={set_id_to_child_roles}

          on_close={() => set_role_to_remove(null)}
        />
      }

      {/* ADD modal */}
      {is_show_add_modal &&
        <AddRoleModal
          user={user}
          group_level_roles={group_level_roles}
          child_role_ids={all_child_role_ids}
          local_roles={user_roles}
          set_local_roles={set_user_roles}

          id_to_child_roles={id_to_child_roles}
          set_id_to_child_roles={set_id_to_child_roles}

          on_close={() => set_is_show_add_modal(false)}
        />
      }
    </>
    
  )
}

export default UserRolesModal