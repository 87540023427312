import React from 'react'
import cn from 'classnames'

import TextLink from '../widgets/TextLink.js'
import { Link } from '../widgets/Link.js'
import { InfoIcon } from '../widgets/IconSet.js'
import { on_click_from_help_link } from '../../utils/help_utils.js'

import s from './TileHelpLink.module.scss'

export const TileHelpLink = ({ help_url, on_click, icon, className }) => {

  function render_icon() {
    return icon || (<InfoIcon/>)
  }

  function on_help_link_click(e, help_url) {
    on_click_from_help_link(help_url)
    e.stopPropagation()
  }

  function on_hint_click(e) {
    on_click()
    e.stopPropagation()
  }

  if (help_url) {
    return (
      <TextLink element={Link} href={help_url} target='_blank' className={cn(s.link, className)} onClick={(e) => on_help_link_click(e, help_url)}>{render_icon()}</TextLink>
    )
  }

  return (
    <TextLink onClick={(e) => on_hint_click(e)} className={cn(s.link, className)} >{render_icon()}</TextLink>
  )
}