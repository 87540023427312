import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import cn from 'classnames'
import _ from 'underscore'

import { LIST_VIEW_PAGE_TOP } from '../../constants/layout.js'
import {
  ID_TO_FIELD,
  PRIORITY_DATE_FIELD_ID,
  SCORE_ID,
  REPORT_PATENT_FAMILY_FIELDS,
  REPORT_PATENT_FAMILY_SORT_FIELDS,
  TAGS_ID,
  get_default_selected_report_field_ids,
} from '../../model/patent_family_fields.js'
import { TABLE } from '../../model/patent_family_list_views.js'
import { DEFAULT_PAGE_SIZE } from '../../model/patent_family_list_page_sizes.js'
import { DESCENDING } from '../../model/sort_directions.js'
import { SPEC_ID_TO_GROUP, SPEC_ID_TO_SPEC_REF } from '../../model/spec_groups.js'
import { ALL_STATUS_IDS } from '../../model/statuses.js'
import {can_tag_families, can_view_tagged_families, has_classifiers_edit} from '../../utils/user_permissions.js'

import { get_default_custom_score_range, NO_FILTER } from '../classifiers_editor/model/filters.js'
import { SCORE_FILTER_GROUPS } from '../classifiers_editor/model/filters.js'
import { trigger_download, MIMETYPE_CSV, get_clean_filename, MIMETYPE_XLSX, CSV_FILE_EXT, EXCEL_FILE_EXT } from '../../utils/download_utils.js'
import { with_prev_subidx, with_next_subidx } from '../../utils/pagination_utils.js'
import {
  get_patent_families_by_query,
  get_patent_families_by_query_as_csv,
  update_list_field_ids_preferences,
  update_page_size_preferences,
  get_page_size_preference,
  get_patent_families_by_query_as_xls,
  get_normalised_search_phrase,
  FAMILIES_TO_DOWNLOAD_THRESHOLD,
  remove_unavailable_field_ids,
  get_list_field_ids_preference,
  update_patent_link_option_id_preference,
  get_patent_link_option_id_preference,
  get_list_view_preference,
  update_list_view_preference,
  order_field_ids_by_group,
  REPORT_FROM_LIST_UNKNOWN_TYPE,
} from '../../utils/patent_family_list_utils.js'
import {
  get_family_id_from_cipher_family_id,
  create_cipher_family_id_from_family_id,
  order_patent_numbers_and_select_best_representation
} from '../../utils/family_view_utils.js'
import { is_family_details_selected_patent_page, is_selections_empty } from '../../utils/viewer_utils.js'
import {
  track_download_event,
  track_report_builder_event,
  track_list_event, track_report_viewer_event
} from '../../utils/tracking_utils.js'
import { PORTFOLIO_SIZE_NO_PASS_THRESHOLD } from '../../utils/report_builder_utils.js'
import { get_short_spec_name, get_spec_name } from '../../utils/spec_utils.js'
import { get_subselection_string } from '../../utils/name_utils.js'
import { react_strap_popover_clear_popovers_workaround } from '../../utils/keyboard_shortcuts/keyboard_utils.js'
import { update_patents_with_ts_and_pending_and_errors } from '../classifiers_editor/utils/training_set_utils.js'
import { get_search_phrases_and_colours } from '../classifiers_editor/utils/phrases_to_highlight_utils.js'
import {
  fetch_full_name_tags_for_families,
  fetch_executable_tags,
  fetch_viewable_tags,
  get_tag_value_ids
} from '../family_tagging/family_tag_utils'
import { add_global_filters_to_query } from '../../utils/report_deck_utils.js'
import FamilyTags from '../family_tagging/FamilyTags'
import PatentFamiliesListWithControls from './PatentFamiliesListWithControls.js'
import PatentFamilyListActions from './PatentFamilyListActions.js'
import BadSyntaxAlertModal from './BadSyntaxAlertModal.js'
import PatentKeyboardShortcutsWrapper from './PatentKeyboardShortcutsWrapper.js'
import ErrorModal from '../ErrorModal.js'
import ErrorBody from '../ErrorBody.js'
import NoDataInSelection from '../NoDataInSelection.js'
import { withUser } from '../UserContext.js'
import ItemHeader from '../viewer/ItemHeader.js'
import Spinner, { DownloadSpinner } from '../widgets/Spinner.js'
import ForwardBackSelector from '../widgets/ForwardBackSelector.js'
import { PATENT_LINK_OPTION_DEFAULT_ID } from '../../model/patent_links.js'
import { is_400_error } from '../../utils/axios_utils.js'
import { ALL_FAMILIES_ID } from '../../model/spec_ids.js'
import { get_csv_string, parse_csv } from '../../utils/csv_utils.js'
import { InstantReportFromSubsetModal, NewReportFromSubsetModal } from '../viewer/ReportFromSubsetModal.js'
import { get_as_map } from '../../utils/utils.js'
import {
  is_family_details_view_page,
  create_subset_url_with_new_subset_id
} from '../../utils/viewer_utils.js'
import { SUBSET_SUBPATH } from '../../constants/viewer_paths.js'
import { add_family_subpath } from '../../utils/url_utils.js'
import { DEFAULT_REPORT_TYPE, UTT_REPORT_TYPE } from '../../constants/constants.js'
import { get_language_preference } from '../../utils/user_settings_utils.js'
import PatentFamilyListDetailsContainer from './PatentFamilyListDetailsContainer.js'
import { SPEC_GROUP_TO_THUMBNAIL_CLASSNAME } from '../viewer/dataset_groups_styles.js'

import s from './PatentsContainer.module.scss'

const DEFAULT_PAGE_STATE = {
  page: 0,
  subidx: null
}

const FETCH_DEBOUNCE_PERIOD = 400

const FETCH_DEBOUNCE_PERIOD_LONG = 1500

const HIGHLIGHT_PREFIX_ONLY = true

let current_req_id = 0

/**
 * Given csv string data, adds in the training_set labels (pos / neg / ignore).
 */
function add_training_set_user_class_to_csv_string(csv_string, id_to_tsfam) {
  const rows = parse_csv(csv_string)

  const rows_updated = rows
    .map((elements, idx) => {
      if (idx === 0) {
        // Header
        return [...elements, "Training set label"]
      }

      // Normal row
      const cipher_family_id = elements[0]
      const patfam_id = get_family_id_from_cipher_family_id(cipher_family_id)
      const ts_fam = id_to_tsfam[patfam_id]
      const user_class = ts_fam ? ts_fam.user_class : ''

      return [...elements, `${user_class}`]
    })

  return get_csv_string(rows_updated)
}

/**
 * Fetches families from ReportReader/ElasticSearch, renders them in a PatentFamilyList.
 */
class PatentsContainer extends Component {
  constructor(props) {
    super(props)

    this.fetch                           = this.fetch.bind(this)
    this.on_page_size_change             = this.on_page_size_change.bind(this)
    this.on_page_change                  = this.on_page_change.bind(this)
    this.on_change_subidx                = this.on_change_subidx.bind(this)
    this.on_prev_subidx                  = this.on_prev_subidx.bind(this)
    this.on_next_subidx                  = this.on_next_subidx.bind(this)
    this.on_show_detail                  = this.on_show_detail.bind(this)
    this.on_hide_detail                  = this.on_hide_detail.bind(this)

    this.on_change_status_ids            = this.on_change_status_ids.bind(this)
    this.on_change_selected_field_ids    = this.on_change_selected_field_ids.bind(this)
    this.on_change_sort_direction_id     = this.on_change_sort_direction_id.bind(this)
    this.on_change_sort_field_id         = this.on_change_sort_field_id.bind(this)
    this.on_change_sort_field_id_and_sort_direction_id = this.on_change_sort_field_id_and_sort_direction_id.bind(this)
    this.on_change_patent_family_list_view_id = this.on_change_patent_family_list_view_id.bind(this)
    this.on_change_patent_link_option_id = this.on_change_patent_link_option_id.bind(this)

    this.download_as_csv                 = this.download_as_csv.bind(this)
    this.download_as_excel               = this.download_as_excel.bind(this)
    this.create_report_from_subset       = this.create_report_from_subset.bind(this)
    this.apply_search_input              = this.apply_search_input.bind(this)
    this.on_change_from_search_input     = this.on_change_from_search_input.bind(this)
    this.on_clear_search_input           = this.on_clear_search_input.bind(this)

    this.on_change_score_filter_id = this.on_change_score_filter_id.bind(this)
    this.on_change_custom_score_range = this.on_change_custom_score_range.bind(this)

    this.set_search_tag_input = this.set_search_tag_input.bind(this)
    this.set_selected_family_ids = this.set_selected_family_ids.bind(this)
    this.fetch_user_family_tags  = this.fetch_user_family_tags.bind(this)
    this.create_subset_query_params = this.create_subset_query_params.bind(this)
    this.change_subset_id = this.change_subset_id.bind(this)
    this.get_subselections = this.get_subselections.bind(this)
    this.update_or_delay_new_listed_tags = this.update_or_delay_new_listed_tags.bind(this)
    this.update_patent_families_table = this.update_patent_families_table.bind(this)
    this.on_family_tags_filter_change = this.on_family_tags_filter_change.bind(this)
    this.change_apply_tag_filter = this.change_apply_tag_filter.bind(this)
    this.refresh_patent_families = this.refresh_patent_families.bind(this)
    this.change_selected_patent_number = this.change_selected_patent_number.bind(this)

    this.fetch_debounced = _.debounce(this.fetch, FETCH_DEBOUNCE_PERIOD)
    this.change_subset_id_debounced = _.debounce(this.change_subset_id, FETCH_DEBOUNCE_PERIOD)
    this.change_subset_id_debounced__long = _.debounce(this.change_subset_id, FETCH_DEBOUNCE_PERIOD_LONG)

    this.search_input_ref = React.createRef()
    this.return_btn_ref = React.createRef()

    const { user, report_has_scores, ref_data } = this.props
    const { schema_version } = ref_data || {}

    // Reference data (store as object properties rather than state, as static - i.e. could also evaluate on demands, but convient to do this once)
    const all_field_ids      = order_field_ids_by_group(remove_unavailable_field_ids(REPORT_PATENT_FAMILY_FIELDS.map(field => field.id),     {report_has_scores, schema_version, user}))

    const all_sort_field_ids = remove_unavailable_field_ids(REPORT_PATENT_FAMILY_SORT_FIELDS.map(field => field.id),  {report_has_scores, schema_version, user})
    this.all_fields          = all_field_ids.map(id => ID_TO_FIELD[id])
    this.all_sort_fields     = all_sort_field_ids.map(id => ID_TO_FIELD[id])

    // Initial selected values
    const default_selected_report_field_ids = get_default_selected_report_field_ids({schema_version})
    this.default_selected_field_ids     = remove_unavailable_field_ids(default_selected_report_field_ids, {report_has_scores, schema_version, user})

    const selected_field_ids_preference = get_list_field_ids_preference() // try to read from localStorage
    const selected_field_ids_in_order   = all_field_ids.filter(field_id => _.contains((selected_field_ids_preference || default_selected_report_field_ids), field_id))
    const selected_field_ids            = remove_unavailable_field_ids(selected_field_ids_in_order, {report_has_scores, schema_version, user})

    const patent_link_option_id_preference = get_patent_link_option_id_preference()
    const patent_link_option_id = patent_link_option_id_preference || PATENT_LINK_OPTION_DEFAULT_ID

    const default_sort_field_id       = report_has_scores ? SCORE_ID : PRIORITY_DATE_FIELD_ID

    //tagging permissions
    const can_user_view_tagged_families = can_view_tagged_families(user)

    this._is_mounted = false
    this.state = {
      fetching_data: true,
      fetching_pat_families_error: null,
      bad_search_syntax_error: null,
      patent_families: null,
      total: null,
      page: 0,
      page_size: get_page_size_preference() || DEFAULT_PAGE_SIZE,
      subidx: null, // currently selected family in this page
      selected_patent_number: null,

      search_phrase: null,      // already applied
      next_search_phrase: null, // not yet been applied
      is_search_input_fetch: false,
      status_ids: ALL_STATUS_IDS,
      patent_family_list_view_id: get_list_view_preference(user) || TABLE,
      selected_field_ids,
      sort_field_id: default_sort_field_id,
      sort_direction_id: DESCENDING,
      patent_link_option_id: patent_link_option_id,

      score_filter_id: NO_FILTER, // Eval Classifier stuff
      custom_score_range: get_default_custom_score_range(),

      is_csv_downloading: false,
      is_xls_downloading: false,
      csv_download_error: null,
      xls_download_error: null,

      user_tags: [],
      selected_viewable_family_tags: [],
      apply_tag_filter: false,
      user_viewable_family_tags: [],
      show_filter_tags_spinner: true,
      fetch_user_tags_error: null,
      fetch_viewable_tags_error: null,
      is_bulk_tagging_available: false,
      search_tag_input: '',
      selected_family_ids: [],
      are_family_tags_changed: false,
      can_user_view_tagged_families: can_user_view_tagged_families,

      report_from_subset_type: null,
    }
  }

  componentDidMount() {
    this._is_mounted = true
    const { user } = this.props
    this.fetch()
    this.fetch_user_family_tags(user)
    this.fetch_viewable_family_tags(user)
    this.set_selected_family_ids([])
  }

  componentDidUpdate(prevProps, prevState) {
    const { selections, item, add_temporary_item, user, subset_id, is_clickthrough } = this.props
    const { page, page_size, selected_family_ids, status_ids, sort_field_id} = this.state
    const { are_family_tags_changed } = this.state
    if (!item && add_temporary_item) {
      // Handle case where user is viewing a "temporary" item (not in their report).
      // We need to add this item, and fetch the data for it.
      // Ideally this would be done in the ReportContainer.
      // But this is hard to do there, as route deals with getting the item.
      add_temporary_item()
    }

    if (is_selections_empty(selections)) {
      // Selections are empty, so don't bother fetching
      return
    }

    if (!_.isEqual(prevProps.selections, selections)) {
      // Selections (orgs, techs, geos) have changed, so need to refetch data
      // IMPORTANT: debounce this call, as user may click around a few times....
      this.setState({ ...DEFAULT_PAGE_STATE, selected_family_ids: []})
    }

    if (prevProps.subset_id !== subset_id) {

      this.setState({fetching_data: true})

      if (is_clickthrough) {
        this.fetch()
      } else {
        this.fetch_debounced()
      }
    }

    if (!_.isEqual(prevProps.user.user_id, user.user_id) || !_.isEqual(prevState.are_family_tags_changed, are_family_tags_changed)){
      this.fetch_user_family_tags(user)
      this.fetch_viewable_family_tags(user)
    }
    if ((!_.isEqual(prevState.page, page)
          || !_.isEqual(prevState.status_ids, status_ids)
          || !_.isEqual(prevState.sort_field_id, sort_field_id)
          || page_size < prevState.page_size)
          && !_.isEmpty(selected_family_ids)){
      this.setState({ selected_family_ids: []})
    }
  }

  componentWillUnmount() {
    this._is_mounted = false
  }

  get_subselections() {
    const {subselections} = this.props
    return subselections
  }

  fetch_user_family_tags(user) {
    if (this._is_mounted) {
      const use_family_tags = can_tag_families(user) && !this.props.is_report_deck
      this.setState({'is_bulk_tagging_available': use_family_tags})
      if (use_family_tags) {
        fetch_executable_tags()
          .then(executable_tags => {
            this.setState({user_tags: executable_tags, fetch_user_tags_error: null, are_family_tags_changed: false})
          })
          .catch(err => {
            this.setState({user_tags: [], fetch_user_tags_error: err, are_family_tags_changed: false})
          })
      }
    }
  }

  fetch_viewable_family_tags(user){
    if (this._is_mounted) {
      const view_family_tags = can_view_tagged_families(user) && !this.props.is_report_deck
      if (view_family_tags) {
        this.setState({show_filter_tags_spinner:true})
        fetch_viewable_tags()
          .then(viewable_tags => {
            this.setState({
              user_viewable_family_tags: viewable_tags,
              selected_viewable_family_tags: [],
              show_filter_tags_spinner: false,
              fetch_viewable_tags_error: null,
              are_family_tags_changed: false
            })
          })
          .catch(err => {
            this.setState({
              user_viewable_family_tags: [],
              selected_viewable_family_tags: [],
              show_filter_tags_spinner:false,
              fetch_viewable_tags_error: err,
              are_family_tags_changed:false
            })
          })
      }
    }
  }

  download_as_csv() {
    const { selected_field_ids, sort_field_id, sort_direction_id, search_phrase, status_ids, score_filter_id, custom_score_range, can_user_view_tagged_families, selected_viewable_family_tags, apply_tag_filter } = this.state
    const { report_title, minimal_selections, internal_report_id, spec, data_creation_date, id_to_tsfam } = this.props
    const { get_query, get_query_for_clickthroughs } = spec

    this.setState({ is_csv_downloading: true }) // show spinner

    const subselections = this.get_subselections()

    track_download_event(`obj="${(subselections) ? 'clickthrough' : 'list'}" format="CSV"`)

    const normalised_search_phrase = get_normalised_search_phrase(search_phrase)

    const patfams_query = this.generate_query({get_query, get_query_for_clickthroughs, data_creation_date})

    get_patent_families_by_query_as_csv({
      query: patfams_query,
      selections: minimal_selections,
      subselections,
      status_ids,
      selected_field_ids,
      sort_field_id,
      sort_direction_id,
      search_phrase: normalised_search_phrase,
      internal_report_id,
      score_filter_id,
      custom_score_range,
      can_see_tags: can_user_view_tagged_families,
      selected_family_tags: selected_viewable_family_tags,
      apply_tag_filter: apply_tag_filter
    })
      .then(response => {
        const csv_string = response.data
        const csv_string_with_labels = id_to_tsfam ? add_training_set_user_class_to_csv_string(csv_string, id_to_tsfam) : csv_string

        const filename = get_clean_filename(report_title + '_export' + CSV_FILE_EXT)
        this.setState({is_csv_downloading: false})
        trigger_download(csv_string_with_labels, MIMETYPE_CSV, filename)
      })
      .catch(error => {
        this.setState({is_csv_downloading: false, csv_download_error: error})
        throw error
      })
  }

  download_as_excel() {
    const { selected_field_ids, sort_field_id, sort_direction_id, search_phrase, status_ids, score_filter_id, custom_score_range, can_user_view_tagged_families, selected_viewable_family_tags, apply_tag_filter, patent_link_option_id } = this.state
    const { report_title, minimal_selections, internal_report_id, spec, data_creation_date } = this.props
    const { get_query, get_query_for_clickthroughs } = spec

    this.setState({ is_xls_downloading: true }) // show spinner
    const subselections = this.get_subselections()
    track_download_event(`obj="${(subselections) ? 'clickthrough' : 'list'}" format="XLS"`)

    const normalised_search_phrase = get_normalised_search_phrase(search_phrase)

    const patfams_query = this.generate_query({get_query, get_query_for_clickthroughs, data_creation_date})

    get_patent_families_by_query_as_xls({
      query: patfams_query,
      selections: minimal_selections,
      subselections,
      status_ids,
      selected_field_ids,
      sort_field_id,
      sort_direction_id,
      search_phrase: normalised_search_phrase,
      internal_report_id,
      report_title,
      description: spec.description,
      score_filter_id,
      custom_score_range,
      can_see_tags: can_user_view_tagged_families,
      selected_family_tags: selected_viewable_family_tags,
      apply_tag_filter: apply_tag_filter,
      publication_link_type: patent_link_option_id
    })
      .then(response => {
        const filename = get_clean_filename(report_title + '_export' + EXCEL_FILE_EXT)
        this.setState({is_xls_downloading: false})
        trigger_download(response, MIMETYPE_XLSX, filename)
      })
      .catch(error => {
        this.setState({is_xls_downloading: false, xls_download_error: error})
        throw error
      })
  }

  create_subset_query_params() {
    const { search_phrase, status_ids, score_filter_id, custom_score_range, selected_viewable_family_tags, apply_tag_filter } = this.state
    const { spec, minimal_selections, internal_report_id, data_creation_date } = this.props
    const { get_query, get_query_for_clickthroughs } = spec

    const subselections = this.get_subselections()

    const patfams_query = this.generate_query({get_query, get_query_for_clickthroughs, data_creation_date})

    return  {query: patfams_query, selections: minimal_selections, subselections, status_ids, search_phrase, internal_report_id, score_filter_id, custom_score_range, selected_viewable_family_tags, apply_tag_filter}
  }

  create_report_from_subset(report_from_subset_type) {
    if (report_from_subset_type === REPORT_FROM_LIST_UNKNOWN_TYPE) {
      track_report_builder_event('action="build_report" report_type="filtered_list" context="report"')
    }

    this.setState({ report_from_subset_type })
  }

  on_change_from_search_input(new_search_phrase) {
    const { next_search_phrase } = this.state

    if (next_search_phrase === new_search_phrase) {
      return
    }

    this.setState({ next_search_phrase: new_search_phrase }) // store in "next_search_phrase" (once search is applied, we move it to "search_phrase")
  }

  on_clear_search_input() {
    const subselections = this.get_subselections()

    track_report_viewer_event(`obj="${(subselections) ? 'clickthrough' : 'list'}" option="keyword_search" action="clear"`)

    this.setState({ ...DEFAULT_PAGE_STATE, search_phrase: null, next_search_phrase: null }, () => {
      this.change_subset_id()
    })
  }

  apply_search_input() {
    const subselections = this.get_subselections()
    track_report_viewer_event(`obj="${(subselections) ? 'clickthrough' : 'list'}" option="keyword_search" action="search"`)

    this.setState({ ...DEFAULT_PAGE_STATE, is_search_input_fetch: true }, () => { // reset page state
      this.change_subset_id() // fetch already applies the "next_search_phrase" (and moves it to "search_phrase")
    })
  }

  fetch() {
    const { spec, minimal_selections, internal_report_id, data_creation_date, history, subset_id, is_clickthrough, set_families_subselections, location, user_settings } = this.props
    const { status_ids, sort_field_id, sort_direction_id, score_filter_id, custom_score_range, page, page_size, next_search_phrase, is_search_input_fetch, subidx, can_user_view_tagged_families, selected_viewable_family_tags, apply_tag_filter } = this.state

    this.setState({
      patent_families: null,
      fetching_pat_families_error: null,
      bad_search_syntax_error: null,
      search_phrase: next_search_phrase, // apply search_phrase
      is_search_input_fetch: false,
      are_family_tags_changed: false,
    })

    const preferred_language = get_language_preference(user_settings)
    const offset = page * page_size
    const limit = page_size

    const { get_query, get_query_for_clickthroughs } = spec

    const patfams_query = this.generate_query({get_query, get_query_for_clickthroughs, data_creation_date})

    const normalised_search_phrase = get_normalised_search_phrase(next_search_phrase)

    // increment current_req_id
    current_req_id++

    // the id of this closure
    const req_id = current_req_id

    const subselections = this.get_subselections()

    get_patent_families_by_query({
      query: patfams_query,
      selections: minimal_selections,
      subselections,
      status_ids,
      internal_report_id,
      score_filter_id,
      custom_score_range,
      size: limit,
      from: offset,
      sort_field_id,
      sort_direction_id,
      search_phrase: normalised_search_phrase,
      subset_id,
      can_see_tags: can_user_view_tagged_families,
      selected_family_tags: selected_viewable_family_tags,
      apply_tag_filter: apply_tag_filter
    })
      .then(response  => {
        if (req_id !== current_req_id) {
          // Stale response, so ignore
          return
        }

        const cipher_family_id_in_path = is_family_details_view_page(location.pathname)
        const patent_number_in_path = is_family_details_selected_patent_page(location.pathname)

        const { results, input={} } = response || {}
        const { subselections, status_ids, size, from, sort_field_id, sort_direction_id, search_phrase, score_filter_id, custom_score_range } = input.filters || {}
        const { searchResults: patent_families, totalResults: total } = results || {}

        const { representative_patent_number } = order_patent_numbers_and_select_best_representation(_.find(patent_families, {patFamId: get_family_id_from_cipher_family_id(cipher_family_id_in_path)}), preferred_language)

        const selected_patent_number = patent_number_in_path || representative_patent_number

        const new_state = {
          patent_families,
          total,
          ...(search_phrase) ? {search_phrase, next_search_phrase: search_phrase} : {},
          ...(sort_field_id) ? {sort_field_id} : {},
          ...(sort_direction_id) ? {sort_direction_id} : {},
          ...(status_ids) ? {status_ids} : {},
          ...(score_filter_id) ? {score_filter_id} : {},
          ...(custom_score_range) ? {custom_score_range} : {},
          ...(size) ? {page_size: size} : {},
          ...(from) ? {page: from/(size || limit)} : {},
          ...(cipher_family_id_in_path && (subidx == null)) ? {subidx: _.findIndex(patent_families, {patFamId: get_family_id_from_cipher_family_id(cipher_family_id_in_path)})} : {},
          selected_patent_number,
          fetching_data: false
        }

        this.setState(new_state, () => {
          if (is_clickthrough && !this.props.subselections) {
            set_families_subselections(subselections, total, true)
          }
        })

        if (cipher_family_id_in_path && !patent_number_in_path) {
          const details_path = this.get_detail_path(cipher_family_id_in_path, selected_patent_number)
          history.replace(details_path)
        }

        if (subidx != null && cipher_family_id_in_path) {
          const selected_patfam = patent_families[subidx]
          const selected_family_id = selected_patfam.patFamId
          const selected_cipher_family_id = create_cipher_family_id_from_family_id(selected_family_id)
          const details_path = this.get_detail_path(selected_cipher_family_id)
          history.push(details_path)
        }

        if (is_search_input_fetch) {
          // Fetch was triggered from the search input,
          // so put the focus back to the search input
          this.set_focus_on_search_input()
        }
      }).catch(err => {
        if (is_400_error(err)) {
          // ERROR: Bad syntax
          this.setState({ fetching_data: false, bad_search_syntax_error: err })
          return null
        } else {
          // ERROR: Other error
          this.setState({ fetching_data: false, fetching_pat_families_error: err })
          throw err
        }
      })
  }

  generate_query({get_query, get_query_for_clickthroughs, data_creation_date}) {
    const get_query_fn = get_query_for_clickthroughs || get_query
    const selected_timerange = this.get_selected_timerange_for_query(data_creation_date)
    const {spec, minimal_selections, is_report_deck, ref_data, clickthrough_item} = this.props

    const {report_region_column} = minimal_selections

    const query = get_query_fn({ data_creation_date, region_column: report_region_column, ...minimal_selections, selected_timerange, clickthrough_item })

    if (is_report_deck) {
      const {org_lists} = ref_data
      return add_global_filters_to_query({chart: spec, query, selections: minimal_selections, org_lists})
    }
    return query
  }

  get_selected_timerange_for_query(data_creation_date) {
    const {item, spec} = this.props
    if (item && item.timerange) {
      return item.timerange
    } else if (spec && spec.get_default_selected_timerange) {
      return spec.get_default_selected_timerange(data_creation_date)
    }
    return null
  }

  set_focus_on_search_input() {
    // Workaround to set focus on search input (only do if input is rendered)
    const search_input = this.search_input_ref.current
    if (search_input) {
      search_input.focus()
    }
  }

  change_subset_id() {
    const { history, location } = this.props
    const {pathname} = location
    history.replace(create_subset_url_with_new_subset_id(pathname))
  }

  on_change_score_filter_id(score_filter_id) {
    // For eval classifier
    this.setState({ ...DEFAULT_PAGE_STATE, score_filter_id, fetching_data: true /* show spinner immeditely */ }, () => {
      this.change_subset_id_debounced__long()
    })
  }

  on_change_custom_score_range(custom_score_range) {
    // For eval classifier
    this.setState({ ...DEFAULT_PAGE_STATE, custom_score_range, fetching_data: true /* show spinner immeditely */ }, () => {
      this.change_subset_id_debounced__long()
    })
  }

  on_page_size_change(page_size) {
    track_list_event(`action="change_page_size" page_size="${page_size}" context="report"`)

    update_page_size_preferences(page_size) // saves to local storage

    this.setState({ ...DEFAULT_PAGE_STATE, page_size, fetching_data: true /* show spinner immeditely */ }, () => {
      this.change_subset_id_debounced()
    })
  }

  on_page_change(page) {
    this.setState({ page, subidx: null, fetching_data: true /* show spinner immediately */ }, () => {
      this.change_subset_id()
    })

    track_list_event(`action="pager" page="${page}" context="report"`)
  }

  on_change_subidx(subidx) {
    this.setState({ subidx })
  }

  on_show_detail(subidx, cipher_family_id) {
    const { history, user_settings } = this.props

    if (subidx == null) {
      return
    }

    // Set subidx
    this.on_change_subidx(subidx)

    // Change to detail page
    const preferred_language = get_language_preference(user_settings)
    const selected_patfam = this.state.patent_families[subidx]
    const { representative_patent_number } = order_patent_numbers_and_select_best_representation(selected_patfam, preferred_language)
    this.setState({selected_patent_number: representative_patent_number})
    const details_path = this.get_detail_path(cipher_family_id, representative_patent_number)
    history.push(details_path)
  }

  on_hide_detail() {
    const { history } = this.props
    const list_path = this.get_list_path()
    history.push(list_path)
  }

  on_prev_subidx() {
    const { page, subidx, page_size, fetching_data } = this.state
    if (fetching_data) {
      return
    }
    const { page: new_page, subidx: new_subidx } = with_prev_subidx(subidx, page, page_size)

    if (new_subidx < 0) return //accidental click when there are no prev items to show

    this.handle_family_prev_next({new_page, new_subidx})
  }

  on_next_subidx() {
    const { page, subidx, page_size, total, fetching_data } = this.state
    if (fetching_data) {
      return
    }

    const total_num_pages = this.get_total_num_pages()

    if (!total_num_pages || !total) {
      return
    }

    const { page: new_page, subidx: new_subidx } = with_next_subidx(subidx, page, page_size, total_num_pages, total)

    this.handle_family_prev_next({new_page, new_subidx})
  }

  handle_family_prev_next({ new_page, new_subidx }) {
    const { page, patent_families } = this.state

    const { history, location, user_settings } = this.props
    react_strap_popover_clear_popovers_workaround()

    if (new_page !== page) {
      this.setState({ page: new_page, subidx: new_subidx, fetching_data: true }, () => {
        this.change_subset_id()
      })
    } else {
      const preferred_language = get_language_preference(user_settings)
      const selected_patfam = patent_families[new_subidx]
      const selected_family_id = selected_patfam.patFamId
      const selected_cipher_family_id = create_cipher_family_id_from_family_id(selected_family_id)
      this.setState({ page: new_page, subidx: new_subidx })
      if (is_family_details_view_page(location.pathname)) {
        const { representative_patent_number } = order_patent_numbers_and_select_best_representation(selected_patfam, preferred_language)
        this.setState({selected_patent_number: representative_patent_number})

        history.push(this.get_detail_path(selected_cipher_family_id, representative_patent_number))
      }
    }
  }

  on_change_selected_field_ids(selected_field_ids) {
    this.setState({ selected_field_ids })
    update_list_field_ids_preferences(selected_field_ids) // save to localStorage
  }

  on_change_patent_family_list_view_id(patent_family_list_view_id) {
    update_list_view_preference(patent_family_list_view_id)
    this.setState({ patent_family_list_view_id })
  }

  on_change_sort_direction_id(sort_direction_id) {
    const { sort_field_id } = this.state
    track_list_event(`action="sort" column="${sort_field_id}" dir="${sort_direction_id}" context="report"`)
    this.setState({ sort_direction_id, ...DEFAULT_PAGE_STATE, fetching_data: true /* show spinner immeditely */ }, () => {
      this.change_subset_id_debounced()
    })
  }

  on_change_sort_field_id(sort_field_id) {
    const { sort_direction_id } = this.state
    track_list_event(`action="sort" column="${sort_field_id}" dir="${sort_direction_id}" context="report"`)
    this.setState({ sort_field_id, ...DEFAULT_PAGE_STATE, fetching_data: true /* show spinner immeditely */ }, () => {
      this.change_subset_id_debounced()
    })
  }

  on_change_sort_field_id_and_sort_direction_id(sort_field_id, sort_direction_id) {
    track_list_event(`action="sort" column="${sort_field_id}" dir="${sort_direction_id}" context="report"`)
    this.setState({ sort_field_id, sort_direction_id, ...DEFAULT_PAGE_STATE, fetching_data: true /* show spinner immeditely */ }, () => {
      this.change_subset_id_debounced()
    })
  }

  on_change_status_ids(status_ids) {
    if (status_ids.length === 0) {
      // Do nothing (don't allow user to select no status - there will no results - plus the backend doesn't like the query)
      return
    }

    track_list_event('action="filter" column="status"')
    this.setState({ status_ids, ...DEFAULT_PAGE_STATE, fetching_data: true /* show spinner immeditely */ }, () => {
      this.change_subset_id_debounced()
    })
  }

  on_change_patent_link_option_id(patent_link_option_id) {
    this.setState({ patent_link_option_id })
    update_patent_link_option_id_preference(patent_link_option_id)
  }

  get_detail_path(cipher_family_id, selected_patent_number) {
    const { base_path, is_clickthrough, subset_id } = this.props
    const base_url = is_clickthrough ? `${base_path}/${SUBSET_SUBPATH}/${subset_id}` : `${base_path}`
    return add_family_subpath(base_url, cipher_family_id, selected_patent_number)
  }

  get_list_path() {
    const { base_path, is_clickthrough, subset_id } = this.props
    return is_clickthrough ? `${base_path}/${SUBSET_SUBPATH}/${subset_id}` : base_path
  }

  get_total_num_pages() {
    const { total, page_size } = this.state
    if (!total || !page_size) {
      return 0
    }

    return Math.ceil(total / page_size)
  }

  get_patent_families_merged(is_eval) {
    const { id_to_tsfam, id_to_tsfam_pending, id_to_tsfam_error } = this.props
    const { patent_families, is_fetching } = this.state

    if (is_fetching || !patent_families || !is_eval || !id_to_tsfam) {
      return patent_families
    }

    // It's a classifier evaluation report, so merge in the training set data.
    // The functions here expect each patfam to have an 'id' field, so we add these before processing...
    const patent_families_with_ids = patent_families.map(patent => ({...patent, id: patent.patFamId }))
    const patent_families_merged = update_patents_with_ts_and_pending_and_errors(patent_families_with_ids, id_to_tsfam, id_to_tsfam_pending, id_to_tsfam_error)

    return patent_families_merged
  }

  set_search_tag_input(new_search_tag_input) {
    this.setState( {'search_tag_input': new_search_tag_input})
  }

  set_selected_family_ids(new_selected_family_ids){
    this.setState( {'selected_family_ids': new_selected_family_ids})
  }

  update_or_delay_new_listed_tags(families_to_update, new_selected_tags, is_new_tag_value){
    const { patent_families, selected_viewable_family_tags, apply_tag_filter } = this.state
    const new_selection_as_tag_value_ids = !_.isEmpty(new_selected_tags)? get_tag_value_ids(new_selected_tags) : [-1]
    const all_new_selection_in_filters = _.isEmpty(new_selection_as_tag_value_ids.filter(tag_value_id => !selected_viewable_family_tags.includes(tag_value_id)))
    if (apply_tag_filter && !all_new_selection_in_filters){
     this.setState({are_family_tags_changed: true})
    } else {
      fetch_full_name_tags_for_families(families_to_update)
        .then(family_to_tag_labels => {
          this.update_patent_families_table(patent_families, families_to_update, TAGS_ID, family_to_tag_labels, [], is_new_tag_value)
        })
        .catch(() => {
          this.update_patent_families_table(patent_families, families_to_update, TAGS_ID, {}, 'Couldn\'t retrieve tags', is_new_tag_value)
        })
    }
  }

  update_patent_families_table(patent_families, families_to_update, field, family_to_tag_labels, default_field_value, is_new_tag_value) {
    const new_patent_families_table = patent_families.map((row) => {
      const pat_fam_id = row['patFamId']
      if (_.contains(families_to_update, pat_fam_id)) {
        const field_value = family_to_tag_labels[pat_fam_id] || default_field_value
        return { ...row, [field]: field_value }
      }
      return row
    })
    this.setState({patent_families: new_patent_families_table, are_family_tags_changed: is_new_tag_value})
  }

  get_family_techs(family) {
    const {ref_data} = this.props
    const {techs=[]} = ref_data || {}
    const {technology=[]} = family || {}
    const tech_by_name = get_as_map(techs, 'name')
    return technology.map(name => tech_by_name[name])
  }

  on_family_tags_filter_change(new_selected_tags){
    track_list_event('action="filter" column="tags"')
    this.setState({ selected_viewable_family_tags: new_selected_tags,
      'selected_family_ids': [],
      ...DEFAULT_PAGE_STATE, fetching_data: true }, () => {
      this.change_subset_id_debounced()
    })
  }

  change_apply_tag_filter (new_apply_tag_filter){
    this.setState({apply_tag_filter: new_apply_tag_filter})
  }

  refresh_patent_families() {
    const {are_family_tags_changed, can_user_view_tagged_families} = this.state
    if (can_user_view_tagged_families && are_family_tags_changed){
      //this refetches the patent families table
      this.setState({  fetching_data: true }, () => {
        this.fetch_debounced()
      })
    }
  }

  change_selected_patent_number(family, patent_number) {
    const {history} = this.props
    const {cipherFamilyId} = family || {}
    this.setState({selected_patent_number: patent_number})
    const details_path = this.get_detail_path(cipherFamilyId, patent_number)
    history.replace(details_path)
  }

  render() {
    const {
      base_path, subset_id, selections, spec, on_close,
      is_clickthrough,
      report_input,
      total_patents, // only available for "patent" datasets (i.e. "granted patents per priority year")
      report_title,
      controls_row_className,
      table_header_className,
      family_details_item_header_className,
      family_details_controls_row_className,

      report_has_scores,

      // Eval classifier stuff
      eval_training_set_id,
      id_to_tsfam,
      phrases_to_highlight,
      no_highlighting,
      set_label,
      eval_classifier_data,

      is_family_tagging_mode_on,
      is_hide_list_actions,
      family_tagging_search_phrase,
      on_toggle_family_tagging_mode,
      on_update_family_tagging_search_phrase,

      user,
      user_settings,

      className,
      listClassName,
      detailsClassName
    } = this.props

    const {
      fetching_data, total, fetching_pat_families_error, bad_search_syntax_error, page, page_size, next_search_phrase, is_search_input_fetch,
      status_ids, patent_link_option_id, subidx,
      patent_family_list_view_id, sort_field_id, sort_direction_id, score_filter_id, custom_score_range,
      is_csv_downloading, csv_download_error, is_xls_downloading, xls_download_error, selected_field_ids,
      user_tags, fetch_user_tags_error, is_bulk_tagging_available, search_tag_input, selected_family_ids, selected_viewable_family_tags,
      user_viewable_family_tags, fetch_viewable_tags_error, apply_tag_filter, show_filter_tags_spinner,
      report_from_subset_type, selected_patent_number
    } = this.state

    if (is_selections_empty(selections)) {
      return (
        <NoDataInSelection className='mb-2' />
      )
    }

    const is_eval = !!eval_training_set_id

    const user_has_classifier_builder_access = has_classifiers_edit(user)

    const has_results = (total > 0)

    const patent_families = this.get_patent_families_merged(is_eval)


    const {id: main_spec_id, name, deck_chart_header, deck_chart_title } = spec || {}

    const {name: group_name, id: group_id} = SPEC_ID_TO_GROUP[main_spec_id] || {}
    const is_all_families_view = (main_spec_id === ALL_FAMILIES_ID)

    const main_spec_name = (deck_chart_title) ? deck_chart_title : (is_all_families_view ? name : get_spec_name(spec, SPEC_ID_TO_SPEC_REF))
    const main_spec_name_short = (deck_chart_title) ? deck_chart_title : (is_all_families_view ? name : get_short_spec_name(spec, SPEC_ID_TO_SPEC_REF))

    const num_pages = this.get_total_num_pages()
    const idx = page * page_size + (subidx || 0)

    // Patfam for details/shortcuts
    const selected_patfam = !fetching_data && patent_families && subidx !== null ? patent_families[subidx] : null

    const subselections = this.get_subselections()

    const subselections_string = get_subselection_string(subselections)
    const selected_family_id = selected_patfam != null ? selected_patfam.patFamId : null
    const selected_cipher_family_id = selected_patfam != null ? create_cipher_family_id_from_family_id(selected_family_id) : null
    const cipher_family_id_in_path = is_family_details_view_page(window.location.pathname)

    const is_show_details = (cipher_family_id_in_path != null)
    const level_1_path  = base_path
    const level_2_path  = is_clickthrough ? `${base_path}/${SUBSET_SUBPATH}/${subset_id}` : add_family_subpath(base_path, selected_cipher_family_id, selected_patent_number)
    const level_2_label = is_clickthrough ? subselections_string || 'families' : selected_cipher_family_id
    const level_3_path  = is_clickthrough ? add_family_subpath(`${base_path}/${SUBSET_SUBPATH}/${subset_id}`, selected_cipher_family_id, selected_patent_number) : null
    const level_3_label = is_clickthrough ? selected_cipher_family_id : null

    const { search_phrases, search_colours } = get_search_phrases_and_colours(phrases_to_highlight)

    return (
      <div className={className}>

        <PatentKeyboardShortcutsWrapper
          disable={fetching_data || !total || fetching_pat_families_error || is_search_input_fetch }
          source={'PatentsContainer'}
          on_prev_patent={this.on_prev_subidx}
          on_next_patent={this.on_next_subidx}
          on_show_details={this.on_show_detail.bind(null, subidx, selected_cipher_family_id)}
          on_hide_details={this.on_hide_detail}
          set_label={(!is_show_details && is_eval && id_to_tsfam) ? set_label : null} // only enable for eval reports List
          selected_patent={is_eval && id_to_tsfam && !fetching_data && patent_families && subidx ? patent_families[subidx] : null} // only enable for eval reports
        />

        {/* Various error modals */}

        {csv_download_error &&
          <ErrorModal
            on_hide={() => this.setState({csv_download_error: null})}
            error={csv_download_error}
            context='exporting families list as csv'
          />
        }

        {xls_download_error &&
          <ErrorModal
            on_hide={() => this.setState({xls_download_error: null})}
            error={xls_download_error}
            context='exporting families list to Excel'
          />
        }

        {bad_search_syntax_error &&
          <BadSyntaxAlertModal
            on_hide={() => {
              this.setState({ bad_search_syntax_error: null })
              this.set_focus_on_search_input()
            }}
          />
        }

        {fetching_pat_families_error &&
          <ErrorModal
            on_hide={() => this.setState({fetching_pat_families_error: null})}
            on_retry={() => this.fetch() }
            error={fetching_pat_families_error}
            context='fetching families via text search in ListView'
          />
        }

        {report_from_subset_type && report_from_subset_type !== REPORT_FROM_LIST_UNKNOWN_TYPE &&
          <InstantReportFromSubsetModal
            on_hide={() => this.setState({report_from_subset_type: null})}
            query_params={this.create_subset_query_params()}
            report_title={report_title}
            evaluation_classifier_id={eval_training_set_id}
            report_from_subset_type={(report_from_subset_type === UTT_REPORT_TYPE) ? report_from_subset_type : DEFAULT_REPORT_TYPE}
            user_settings={user_settings}
          />
        }

        {report_from_subset_type === REPORT_FROM_LIST_UNKNOWN_TYPE &&
          <NewReportFromSubsetModal
            on_hide={() => this.setState({report_from_subset_type: null})}
            query_params={this.create_subset_query_params()}
            report_type={DEFAULT_REPORT_TYPE}
            evaluation_classifier_id={eval_training_set_id}
          />
        }
          {/* Header */}
        <ItemHeader
          on_close={on_close}

          group_id={group_id}
          group_name={deck_chart_header || group_name || ''}
          spec_name={main_spec_name}
          short_spec_name={main_spec_name_short}

          level_1_path={level_1_path}

          level_2_path={level_2_path}
          level_2_label={level_2_label}

          level_3_path={level_3_path}
          level_3_label={level_3_label}

          refresh={this.refresh_patent_families}

          return_btn_ref={this.return_btn_ref}

          className={is_show_details ? cn('sticky-top', s.family_details_item_header, family_details_item_header_className) : null}
        >
          {!is_show_details &&
            <div className={'d-flex w-100'}>
              {(is_csv_downloading || is_xls_downloading) &&
                <DownloadSpinner
                  text={`Downloading ${is_csv_downloading?'CSV':''}${is_xls_downloading?'Excel':''}`}
                  className='my-auto me-2'
                />
              }
              {is_bulk_tagging_available && !fetching_data && !fetching_pat_families_error &&
                <FamilyTags
                  user={user}
                  className={cn('me-2', s.no_jumping)}
                  user_tags={user_tags}
                  error_fetching_custom_tags={fetch_user_tags_error}
                  search_input={search_tag_input}
                  set_search_input={this.set_search_tag_input}
                  is_advanced_mode_on={false}
                  is_multiple_tagging={true}
                  family_ids={selected_family_ids}
                  notify_tag_change={this.update_or_delay_new_listed_tags}
                  on_close_selector={this.refresh_patent_families}
                />
              }

              {!is_hide_list_actions &&
                <PatentFamilyListActions
                  className={cn('ms-auto me-2')}
                  download_as_csv_handler={this.download_as_csv}
                  download_as_xls_handler={this.download_as_excel}
                  is_export_in_progress={is_csv_downloading || is_xls_downloading}
                  no_field_ids_selected={selected_field_ids.length === 0}
                  create_report_from_subset_handler={this.create_report_from_subset}
                  list_no_results={!has_results}
                  list_fetching={fetching_data}
                  list_too_long={total > FAMILIES_TO_DOWNLOAD_THRESHOLD}
                  list_too_long_to_create_report={total > PORTFOLIO_SIZE_NO_PASS_THRESHOLD}
                />
             }

            </div>
          }
          {is_show_details &&
            <ForwardBackSelector
              label={'family'}
              current_idx={idx}
              min_idx_incl={0}
              max_idx_excl={total}
              on_prev={this.on_prev_subidx}
              on_next={this.on_next_subidx}

              className='ms-auto'
              buttonClassName={SPEC_GROUP_TO_THUMBNAIL_CLASSNAME[group_id]}
            />
          }

        </ItemHeader>

        {!is_show_details &&
          <PatentFamiliesListWithControls
            search_input_ref={this.search_input_ref}
            next_search_phrase={next_search_phrase}
            on_change_from_search_input={this.on_change_from_search_input}
            on_clear_search_input={this.on_clear_search_input}
            apply_search_input={this.apply_search_input}

            controls_row_className={is_show_details ? family_details_controls_row_className : controls_row_className}
            table_header_className={table_header_className}

            status_ids={status_ids}
            on_change_status_ids={this.on_change_status_ids}

            all_sort_fields={this.all_sort_fields}
            sort_field_id={sort_field_id}
            on_change_sort_field_id={this.on_change_sort_field_id}
            sort_direction_id={sort_direction_id}
            on_change_sort_direction_id={this.on_change_sort_direction_id}
            on_change_sort_field_id_and_sort_direction_id={this.on_change_sort_field_id_and_sort_direction_id}

            patent_family_list_view_id={patent_family_list_view_id}
            on_change_patent_family_list_view_id={this.on_change_patent_family_list_view_id}

            all_fields={this.all_fields}
            selected_field_ids={selected_field_ids}
            default_selected_field_ids={this.default_selected_field_ids}
            on_change_selected_field_ids={this.on_change_selected_field_ids}

            patent_link_option_id={patent_link_option_id}

            page_size={page_size}
            on_page_size_change={this.on_page_size_change}
            page={page}
            num_pages={num_pages}
            on_page_change={this.on_page_change}

            subidx={subidx}
            on_change_subidx={this.on_change_subidx}
            on_prev_subidx={this.on_prev_subidx}
            on_next_subidx={this.on_next_subidx}
            on_show_detail={this.on_show_detail}

            patent_families={patent_families}
            has_results={has_results}

            total={total}
            total_patents={total_patents}
            fetching_data={fetching_data}
            fetching_pat_families_error={fetching_pat_families_error}

            // Eval classifier stuff
            score_filter_id={score_filter_id}
            on_change_score_filter_id={report_has_scores ? this.on_change_score_filter_id : null} // only pass in if it's a classifier report
            custom_score_range={custom_score_range}
            on_change_custom_score_range={report_has_scores ? this.on_change_custom_score_range : null} // only pass in if it's a classifier report
            score_filter_groups={SCORE_FILTER_GROUPS}
            eval_training_set_id={eval_training_set_id}
            eval_classifier_data={eval_classifier_data}
            is_classifier_evaluation_report={is_eval && id_to_tsfam}
            has_classifier_builder_access={user_has_classifier_builder_access}
            set_label={set_label}
            search_phrases={search_phrases}
            search_colours={search_colours}
            no_highlighting={no_highlighting}
            highlight_prefix_only={HIGHLIGHT_PREFIX_ONLY}

            //family tagging related
            is_bulk_tagging_available={is_bulk_tagging_available}
            selected_family_ids={selected_family_ids}
            viewable_family_tags={user_viewable_family_tags}
            selected_family_tags={selected_viewable_family_tags}
            apply_tag_filter={apply_tag_filter}
            change_apply_tag_filter={this.change_apply_tag_filter}
            fetching_tags_error={fetch_viewable_tags_error}
            on_family_tags_filter_change={this.on_family_tags_filter_change}
            set_selected_family_ids={this.set_selected_family_ids}
            show_filter_tags_spinner={show_filter_tags_spinner}
            return_btn_ref={this.return_btn_ref}
            is_all_families_view={is_all_families_view}
            level_1_path={level_1_path}
            level_2_path={level_2_path}
            level_3_path={level_3_path}
            refresh={this.refresh_patent_families}
            on_close={on_close}

            className={listClassName}
          />
        }

        {is_show_details &&

          <div className={detailsClassName}>

            {fetching_data &&
              <Spinner />
            }

            {fetching_pat_families_error &&
              <ErrorBody
                on_retry={this.fetch}
                error={fetching_pat_families_error}
                context='fetching families'
              />
            }

            {!fetching_data && !fetching_pat_families_error && selected_patfam &&
              <PatentFamilyListDetailsContainer
                patent_families={patent_families}
                family={selected_patfam}
                on_change_subidx={this.on_change_subidx}
                report_input={report_input}
                reset_selected_classifier_id_on_change_patfam={is_eval} // Eval reports reset, normal reports are sticky
                top_className={family_details_controls_row_className}
                page_top={LIST_VIEW_PAGE_TOP}

                // Eval classifier stuff
                eval_training_set_id={eval_training_set_id}
                eval_classifier_data={eval_classifier_data}
                on_set_local_classifier_label={set_label}
                search_phrases={search_phrases}
                search_colours={search_colours}
                no_highlighting={no_highlighting}
                highlight_prefix_only={HIGHLIGHT_PREFIX_ONLY}

                patent_link_option_id={patent_link_option_id}
                on_change_patent_link_option_id={this.on_change_patent_link_option_id}

                is_family_tagging_mode_on={is_family_tagging_mode_on}
                family_tagging_search_phrase={family_tagging_search_phrase}
                on_toggle_family_tagging_mode={on_toggle_family_tagging_mode}
                on_update_family_tagging_search_phrase={on_update_family_tagging_search_phrase}

                selected_patent_number={selected_patent_number}
                on_change_selected_patent_number={(patent_number) => {this.change_selected_patent_number(selected_patfam, patent_number)}}

                technology={this.get_family_techs(selected_patfam)}
                notify_tag_change={this.update_or_delay_new_listed_tags}

                is_clickthrough={is_clickthrough}

                className='pt-2 h-100'
              />
            }
          </div>
        }
          
      </div>
    )
  }
}

export default withRouter(withUser(PatentsContainer))