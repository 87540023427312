import React, { Component } from 'react'
import cn from 'classnames'

import { TABLE_COLUMN_HEADER } from '../../model/table.js'
import { BaseTable } from './BaseTable.js'
import { get_csv_string } from '../../utils/csv_utils.js'
import {get_keys_to_value, get_value_column_idx, IS_NEXT_AGGLOM} from '../../utils/column_data_utils.js'
import {
  format_percent,
  format_percent_plusminus,
  X_COLUMN_IDX,
  Y_COLUMN_IDX
} from '../../utils/treemap_utils.js'
import { get_timerange } from '../../utils/time_range_utils.js'
import { format_integer_with_comma } from '../../utils/utils.js'
import {should_hide_next_agglom_item} from '../../utils/main_items_utils.js'
import { get_share_data, get_year_min_max_totals } from '../../utils/growth_view_utils.js'



function get_csv_value_rows({ spec, data, key_dims, item }) {
  const timerange = get_timerange({spec, item, key_dims})

  const tabular_data_rows = get_processed_data({ spec, data, key_dims, item })

  const header_row = get_columns({spec, timerange}).map(item => (item.label))
  return [
    header_row,
    ...tabular_data_rows
  ]
}

function get_plotly_data({ spec, data, key_dims, item }) {
  const csv_data_rows = get_csv_value_rows({ spec, data, key_dims, item })

  const csv_string = get_csv_string(csv_data_rows)

  return csv_string
}

function get_share_change_when_0s(val_1, val_2) {
  if (val_1 === 0 && val_2 === 0) return 0
  if (val_1 !== 0 || val_2 !== 0) return 100 * (val_2 === 0 ? -1 : 1 )
}

function get_processed_data({ spec, item, data, key_dims }) {
  const x_items = key_dims[X_COLUMN_IDX]
  const hide_next = should_hide_next_agglom_item(spec, item, X_COLUMN_IDX)
  const x_items_filtered = !hide_next ? x_items : x_items.filter(item => (!item[IS_NEXT_AGGLOM]))
  const timerange = get_timerange({spec, item, key_dims})

  const value_column_idx = get_value_column_idx(data)
  const keys_to_value = get_keys_to_value(data, [X_COLUMN_IDX, Y_COLUMN_IDX], value_column_idx)

  const { year_min_totals, year_max_totals } = get_year_min_max_totals({ data, timerange})

  const x_items_with_sizes = x_items_filtered.map(x_item => {
    const {year_min_size, year_max_size, year_min_share, year_max_share, share_change_rel} = get_share_data({keys_to_value, x_item, timerange, year_min_totals, year_max_totals})

    const change = share_change_rel ? format_percent_plusminus(share_change_rel -1).replace('%', '') * 1 : get_share_change_when_0s(year_min_share, year_max_share)

    return [
      x_item.name,
      year_min_size,
      year_max_size,
      format_percent(year_min_share).replace('%', '') * 1,
      format_percent(year_max_share).replace('%', '') * 1,
      change
    ]
  })

  return x_items_with_sizes
}

function get_columns({spec, timerange}) {
  const { column_names } = spec

  const [year_min, year_max] = timerange

  return [
    {...TABLE_COLUMN_HEADER,
      id: column_names[0],
      label: column_names[0],
      headerClassName: cn('text-start', 'text-endbold'),
      field: row => (row[0])
    },
    {...TABLE_COLUMN_HEADER,
      id: 'year_min_size',
      label: `Size in ${year_min}`,
      field: row => (row[1]),
      headerClassName: cn('text-end', 'text-endbold'),
      cell_render: function YearMinSize(row) {
        return (
          <div className='text-end'>{format_integer_with_comma(row.original[1])}</div>
        )
      }
    },
    {...TABLE_COLUMN_HEADER,
      id: 'year_max_size',
      label: `Size in ${year_max}`,
      field: row => (row[2]),
      headerClassName: cn('text-end', 'text-endbold'),
      cell_render: function YearMaxSize(row) {
        return (
          <div className='text-end'>{format_integer_with_comma(row.original[2])}</div>
        )
      }
    },
    {...TABLE_COLUMN_HEADER,
      id: 'year_min_share',
      label: `Share in ${year_min} (%)`,
      field: row => (row[3]),
      headerClassName: cn('text-end', 'text-endbold'),
      cell_render: function YearMinShare(row) {
        return (
          <div className='text-end'>{row.original[3] || 0}</div>
        )
      }
    },
    {...TABLE_COLUMN_HEADER,
      id: 'year_max_share',
      label: `Share in ${year_max} (%)`,
      field: row => (row[4]),
      headerClassName: cn('text-end', 'text-endbold'),
      cell_render: function YearMaxShare(row) {
        return (
          <div className='text-end'>{row.original[4] || 0}</div>
        )
      }
    },
    {...TABLE_COLUMN_HEADER,
      id: 'share_change_rel',
      label: 'Change  (%)',
      field: row => (row[5]),
      headerClassName: cn('text-end', 'text-endbold'),
      cell_render: function ShareChangeRel(row) {
        return (
          <div className='text-end'>{row.original[5]}</div>
        )
      }
    }
  ]
}

class GrowthTabularView extends Component {
  render() {
    const { spec, data, key_dims, item, is_thumbnail } = this.props
    const timerange = get_timerange({spec, item, key_dims})
    const columns = get_columns({spec, timerange})
    const tabular_data_rows = get_processed_data({spec, item, data, key_dims })

    return (
      <BaseTable
        columns={columns}
        data={tabular_data_rows}
        is_thumbnail={is_thumbnail}
      />
    )
  }
}

GrowthTabularView.get_plotly_data = get_plotly_data

GrowthTabularView.get_csv_value_rows = get_csv_value_rows

export default GrowthTabularView