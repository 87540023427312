import React from 'react'
import cn from 'classnames'

import DefaultPageContainer from '../DefaultPageContainer.js'
import Header from '../header/Header.js'
import ContainerFullWidth from '../ContainerFullWidth.js'
import TextLink from '../widgets/TextLink.js'
import { ChevronRightIcon } from '../widgets/IconSet.js'
import { ADMIN } from '../../constants/paths.js'

import s from './AdminPageWrapper.module.scss'

const AdminPageWrapper = ({page_name, admin_page_name, admin_page_path, breadcrumbs_tools, children}) => {
  return (
    <DefaultPageContainer className={s.block}>
      <div className='sticky-top'>
        <Header is_admin={true} />
        <ContainerFullWidth className={cn('p-0', s.breadcrumbs_wrapper)}>
          <ContainerFullWidth className='d-flex py-2 justify-content-between'>
            <div className='d-flex'>
              {!page_name && <h2 className='my-auto'>{admin_page_name || 'Admin'}</h2>}
              {page_name &&
                <>
                  <TextLink element='a' href={`${admin_page_path || ADMIN}`} title='Admin home page' className={cn('my-auto', s.breadcrumb_link)} >
                    {admin_page_name || 'Admin'}
                  </TextLink>

                  <ChevronRightIcon className={cn('mx-2 my-auto', s.chevron)}/>

                  <span className={cn('my-auto', s.page_name)}>{page_name}</span>
                </>
              }
            </div>

            {breadcrumbs_tools != null && breadcrumbs_tools}
          </ContainerFullWidth>
        </ContainerFullWidth>

      </div>

      <ContainerFullWidth>
        {children}
      </ContainerFullWidth>
    </DefaultPageContainer>
  )
}

export default AdminPageWrapper