import axios from 'axios'
import _ from 'underscore'

import { REPORT_DECK_URL } from '../constants/urls.js'
import { fetch_data_from_report_reader } from './report_reader_utils.js'
import { SELECTED_ORG_LISTS, SELECTED_PORTFOLIOS, SELECTED_TECH_AREAS } from '../model/deref.js'
import {
  DECK_BACKGROUND_ORG_COLUMN_COLOR,
  DECK_DEFAULT_COLUMN_COLOR,
  DECK_MISC_SPOTLIGHT_COLOR,
  DECK_SPOTLIGHTED_ORG_COLUMN_COLOR,
  DECK_TECH_AREAS_COLOR_SCHEME,
  PS_RED
} from '../constants/colours.js'
import { get_report_region_column } from './regions_utils.js'
import { REPORT_REGION_GROUPING_IP5 } from '../constants/regions.js'
import { ID_TO_DECK_SPEC  } from '../model/deck_specs.js'
import { STATUS_ACTIVE_ID, STATUS_GRANTED_ID } from '../model/statuses.js'
import { EXTENDED_TABLE_ID } from '../model/view_ids.js'
import { get_as_map, is_array_non_empty_non_null } from './utils.js'
import { BENCHMARKING_REPORT_TYPE } from '../constants/constants.js'
import { IS_TARGET_ORG_TAG } from '../constants/report_deck.js'
import { in_org_lists_constraint, in_tech_areas_constraint, is_company_list_target_list } from './deck_spec_utils.js'

export function fetch_report_deck_config(external_report_id) {
  return axios.get(`${REPORT_DECK_URL}/${external_report_id}`)
    .then(response => {
      const {data} = response || {}
      const {deck_config={}} = data
      return deck_config
    })
}

export function update_report_deck_config(external_report_id, deck_config) {
  return axios.post(`${REPORT_DECK_URL}/${external_report_id}`, {deck_config})
}

export function get_tech_area_column_color(tech_area_id) {
  return DECK_TECH_AREAS_COLOR_SCHEME[tech_area_id % DECK_TECH_AREAS_COLOR_SCHEME.length]
}

export function get_dominant_colour_from_spotlighted_techs({spotlighted_tech_areas}) {
  const has_spotlighted_techs = !_.isEmpty(spotlighted_tech_areas)
  if (has_spotlighted_techs && spotlighted_tech_areas.length === 1) {
    return get_tech_area_column_color(spotlighted_tech_areas[0])
  }
  return has_spotlighted_techs ? DECK_MISC_SPOTLIGHT_COLOR : null
}

export function get_org_lists_from_data_summary({portfolios=[], company_lists=[]}) {
  const id_to_portfolio = get_as_map(portfolios || [], 'id')

  const org_lists = []
  const portfolio_to_company_list = {}

  if (company_lists) {
    company_lists.forEach(item => {
      const {id, name, portfolio_ids, tags} = item
      const is_target_org = ((tags || []).indexOf(IS_TARGET_ORG_TAG) > -1)
      const portfolios = portfolio_ids.map(portfolio_id => {
        const {name: portfolio_name} = id_to_portfolio[portfolio_id]

        portfolio_to_company_list[portfolio_id] = {id, name, is_target_org}

        return {id: portfolio_id, name: portfolio_name}
      })
      org_lists.push({id, name, portfolios, is_target_org})
    })
  }

  return {org_lists, portfolio_to_company_list}
}

export function get_tech_areas_from_data_summary({technologies=[], technology_areas=[]}) {
  const id_to_technology = get_as_map(technologies || [], 'id')

  const tech_areas = (technology_areas || []).map(item => {
    const {id, name, technology_ids} = item

    const techs = (technology_ids || []).map(technology_id => {
      const {name} = id_to_technology[technology_id]
      return {id: technology_id, name}
    })

    const color = get_tech_area_column_color(id)
    return { id, name, techs, color}
  })

  return tech_areas
}

export function get_deck_selections(deck_key_data, deck_config) {
  const { org_lists=[], tech_areas=[] } = deck_key_data || {}

  const { region_grouping=REPORT_REGION_GROUPING_IP5.id, selected_org_lists, selected_tech_areas, spotlighted_tech_areas=[], spotlighted_orgs=[], status_filter=STATUS_ACTIVE_ID, selected_geo_filters} = deck_config || {}
  return {
    selected_org_lists: selected_org_lists || org_lists.map(item => item.id),
    selected_tech_areas: selected_tech_areas || tech_areas.map(item => item.id),
    selected_geo_filters,
    spotlighted_tech_areas,
    spotlighted_orgs,
    region_grouping,
    report_region_column: get_report_region_column(region_grouping),
    status_filter,
  }
}

export function get_deck_deref_data(deck_key_data, selections) {
  const { org_lists=[], tech_areas=[], all_portfolios=[], portfolio_to_company_list={} } = deck_key_data || {}

  const {selected_org_lists=[], selected_tech_areas=[], spotlighted_orgs=[]} = selections || {}

  const org_lists_selected_items = org_lists.filter(item => selected_org_lists.indexOf(item.id) > -1).map(item => {
    if (item.id === 0) {
      return {...item, color: PS_RED, name: item.portfolios[0].name}
    }

    return {...item, color: DECK_DEFAULT_COLUMN_COLOR}
  })

  const tech_areas_selected_items = tech_areas.filter(item => selected_tech_areas.indexOf(item.id) > -1)

  const portfolios = all_portfolios.map(portfolio => {
    const {id} = portfolio
    const is_spotlighted = (spotlighted_orgs.indexOf(id) > -1)
    const company_list = portfolio_to_company_list[id]
    const color = get_org_column_colour(company_list, is_spotlighted)

    return {...portfolio, color}
  })

  return {
    [SELECTED_ORG_LISTS]: org_lists_selected_items,
    [SELECTED_TECH_AREAS]: tech_areas_selected_items,
    [SELECTED_PORTFOLIOS]: portfolios
  }
}

export function portfolio_ids_in_target_list(portfolio_ids, portfolio_to_company_list) {
  return is_array_non_empty_non_null(portfolio_ids) && _.all(portfolio_ids, portfolio_id => is_company_list_target_list(portfolio_to_company_list[portfolio_id]))
}

function find_target_list_in_org_lists(org_lists) {
  return _.find(org_lists, org_list => is_company_list_target_list(org_list))
}

export function get_target_org_display_name(org_lists) {
  const target_org = find_target_list_in_org_lists(org_lists) || {}
  // it's possible to create a deck with no target org, or with multiple portfolios in this list
  return is_array_non_empty_non_null(target_org.portfolios) ? target_org.portfolios.map(portfolio => portfolio.name).join(', ') : ''
}

function get_org_column_colour(company_list, is_spotlighted) {
  if (!company_list) {
    return DECK_BACKGROUND_ORG_COLUMN_COLOR
  }
  if (is_company_list_target_list(company_list)) {
    return PS_RED
  }
  return is_spotlighted ? DECK_SPOTLIGHTED_ORG_COLUMN_COLOR : DECK_DEFAULT_COLUMN_COLOR
}

export function get_deck_spec(spec_id) {
  return ID_TO_DECK_SPEC[spec_id]
}

export function has_no_data_to_fetch(chart, selections, org_lists) {
  const { spotlighted_tech_areas_only, list_orgs_only, exclude_target_org } = chart
  const { selected_org_lists, selected_tech_areas, spotlighted_tech_areas, selected_geo_filters } = selections

  const visible_spotlighted_tech_areas = selected_tech_areas.filter(id => _.contains(spotlighted_tech_areas, id))
  const non_target_org_lists_selected = _.some(org_lists.filter(list => _.contains(selected_org_lists, list.id)), list => !list.is_target_org)
  const no_orgs_to_show = (list_orgs_only && _.isEmpty(selected_org_lists)) || (exclude_target_org && !non_target_org_lists_selected)

  if (_.isEmpty(selected_tech_areas) || (selected_geo_filters && _.isEmpty(selected_geo_filters))) {
    return true
  }
  return (spotlighted_tech_areas_only && _.isEmpty(visible_spotlighted_tech_areas)) || no_orgs_to_show
}

export function fetch_chart_data(internal_report_id, chart, selections, created_at, org_lists, clickthrough_item) {
  const { get_query, status_filter: chart_status_filter, include_relative_values, report_reader_endpoint } = chart
  const { status_filter: deck_status_filter, selected_org_lists, spotlighted_tech_areas, report_region_column } = selections

  if (has_no_data_to_fetch(chart, selections, org_lists)) {
    return Promise.resolve([null, null])
  }

  const query = get_query({
    region_column: report_region_column,
    data_creation_date: created_at,
    status_filter: chart_status_filter || deck_status_filter,
    spotlighted_tech_areas,
    selected_org_lists,
    clickthrough_item
  })

  const {constraint} = query

  const query_for_total_count = {
    "distinct": true,
    "value": ["COUNT DISTINCT PF.pat_fam_id"],
    constraint,
  }

  const query_with_global_filters = add_global_filters_to_query({chart, query, selections, org_lists})
  const query_for_total_count_with_global_filters = add_global_filters_to_query({chart, query: query_for_total_count, selections, org_lists})

  return Promise.all([
    fetch_data_from_report_reader(internal_report_id, query_with_global_filters, report_reader_endpoint),
    include_relative_values ? fetch_data_from_report_reader(internal_report_id, query_for_total_count_with_global_filters) : Promise.resolve(null)
  ])
    .then(([chart_data, total]) => {
      if (!total) return [chart_data, null]
      return [chart_data, total.data[0]]
    })
}

export function add_global_filters_to_query({chart, query, selections, org_lists}) {
  const {spotlighted_tech_areas_only, list_orgs_only, exclude_target_org, add_custom_selections_to_query} = chart
  const {selected_tech_areas, selected_org_lists, spotlighted_tech_areas, selected_geo_filters} = selections
  const {constraint=[]} = query

  // for some 'list orgs' charts we want to exclude the target ('my org')
  const target_org_list_id = (find_target_list_in_org_lists(org_lists) || {}).id
  const org_lists_to_include = (exclude_target_org && target_org_list_id) ? selected_org_lists.filter(id => id !== target_org_list_id) : selected_org_lists

  const query_with_non_geo_constraints = {
    ...query,
    constraint: [
      ...constraint,
      in_tech_areas_constraint(selected_tech_areas),
      ...(spotlighted_tech_areas_only ? [in_tech_areas_constraint(spotlighted_tech_areas)] : []),
      ...(list_orgs_only ? [in_org_lists_constraint(org_lists_to_include)] : [])
    ]
  }

  if (add_custom_selections_to_query) {
    return add_custom_selections_to_query(query_with_non_geo_constraints, {selected_geo_ids: selected_geo_filters})
  }

  return {
    ...query_with_non_geo_constraints,
    ...(selected_geo_filters ? {territory: selected_geo_filters} : {})
  }
}


export function set_data(spec_id_to_fetch_obj, spec_id, data, families_total_count) {
  const spec = get_deck_spec(spec_id)
  const { transform_data } = spec || {}
  const item_data = transform_data ? transform_data(data) : data

  return {
    ...spec_id_to_fetch_obj,
    [spec_id]: { data: item_data, families_total_count }
  }
}

export function calculate_chart_height({parent_dimensions, view_id}) {
  if (!parent_dimensions) return null
  const {width, height} = parent_dimensions || {}

  if (width < (992-48)) return null //at lg breakpoint charts are top and bottom oriented, take full width and don't need height correction

  const layout_total_height = (view_id === EXTENDED_TABLE_ID) ? 180 : 200
  return Math.max(height - layout_total_height, 0)
}

export function get_title_for_display({chart, status_filter, timerange, spotlighted_tech_areas, tech_areas, org_lists, clickthrough_item}) {
  if (!chart) return ''
  const {get_title, title } = chart
  const granted_only = (status_filter === STATUS_GRANTED_ID)
  return (get_title ? get_title({granted_only, timerange, spotlighted_tech_areas, tech_areas, org_lists, clickthrough_item}) : title) || ''
}

export function get_description_for_display({chart, status_filter, spotlighted_tech_areas, clickthrough_item}) {
  if (!chart) return ''
  const { get_description, description } = chart
  const granted_only = (status_filter === STATUS_GRANTED_ID)
  return (get_description ? get_description({granted_only, spotlighted_tech_areas, clickthrough_item}) : description) || ''
}

export function check_if_deck_type_is_benchmarking(deck_type) {
  return (deck_type === BENCHMARKING_REPORT_TYPE)
}

export function get_relevant_selections_from_root_chart(root_chart_selections) {
  return _.pick(root_chart_selections, 'status_filter', 'selected_timerange')
}