import React, { useState } from 'react'
import { withRouter } from 'react-router'
import { Redirect } from 'react-router-dom'
import _ from 'underscore'
import {RouteWithTracing} from '../../RouteWithTracing'
import cn from 'classnames'

import LandingPage from './LandingPage.js'
import DeckSlide from './DeckSlide.js'
import PatentsContainer from '../../patent_family_list/PatentsContainer.js'
import { generate_subset_id } from '../../../utils/viewer_utils.js'
import { SUBSET_SUBPATH } from '../../../constants/viewer_paths.js'
import { remove_trailing_slashes } from '../../../utils/history_utils.js'
import { DECK_PAGE_SUBPATH, DECK_CHART_SUBPATH, LANDING_PAGE_ID } from '../../../constants/report_deck.js'
import { get_deck_spec, get_title_for_display } from '../../../utils/report_deck_utils.js'
import { BENCHMARKING_REPORT_TYPE} from '../../../constants/constants.js'
import { track_deck_viewer_event } from '../../../utils/tracking_utils.js'

import s from './DeckViewer.module.scss'

const DeckViewer = (
  {
    report_deck,
    deck_type,
    is_edit_mode,

    match,
    history,
    selections,
    minimal_selections,
    data_creation_date,
    ref_data,
    deref_data,
    chart_selections,
    internal_report_id,
    report_title,
    clickthrough_item,

    spec_id_to_fetch_obj,
    set_current_page,
    on_chart_description_change,
    on_chart_status_filter_change,
    on_chart_timerange_change,
    on_clickthrough_item_change,
    on_deck_spotlighted_tech_areas_change,
    on_deck_spotlighted_orgs_change,
  }
) => {

  const [clickthrough_params, set_clickthrough_params] = useState(null)
  const [is_family_tagging_mode_on, set_is_family_tagging_mode_on] = useState(false)
  const [family_tagging_search_phrase, set_family_tagging_search_phrase] = useState(null)

  function handle_families_clickthrough(params) {
    const {spec_id} = params.chart
    track_deck_viewer_event(`obj="dataset" dataset="${spec_id}" action="clickthrough" deck_type="${deck_type}"`)
    set_clickthrough_params(params)

    history.push(`${match.url}/${DECK_CHART_SUBPATH}/${spec_id}/${SUBSET_SUBPATH}/${generate_subset_id()}`)
  }

  function close_clickthrough(page_id) {
    set_current_page(page_id)
    set_clickthrough_params(null)
  }

  function on_toggle_family_tagging_mode() {
    set_is_family_tagging_mode_on(!is_family_tagging_mode_on)
  }

  function on_update_family_tagging_search_phrase(text) {
    set_family_tagging_search_phrase(text)
  }

  const page_props = {
    report_deck,
    deck_type,
    selections,
    minimal_selections,
    data_creation_date,
    ref_data,
    deref_data,
    clickthrough_item,
    spec_id_to_fetch_obj,
    is_edit_mode,
    on_chart_description_change,
    on_chart_status_filter_change,
    on_chart_timerange_change,
    on_clickthrough_item_change,
    on_deck_spotlighted_tech_areas_change,
    on_deck_spotlighted_orgs_change,
    set_current_page,
    handle_families_clickthrough
  }

  return (
    <>
      <RouteWithTracing
        path={match.url}
        exact
        render={() => {
          return <Redirect to={`${remove_trailing_slashes(match.url)}/${DECK_PAGE_SUBPATH}/${LANDING_PAGE_ID}`}/>
        }}
      />
      <RouteWithTracing
        path={`${match.url}/${DECK_PAGE_SUBPATH}/${LANDING_PAGE_ID}`}
        render={() => {
          return <LandingPage
            report_deck={report_deck}
            deck_type={deck_type}
            set_current_page={set_current_page}
            chart_selections={chart_selections}
            selections={selections}
            ref_data={ref_data}
            data_creation_date={data_creation_date}
          />
        }}
      />
      <RouteWithTracing
        path={`${match.url}/${DECK_PAGE_SUBPATH}/:page_id`}
        render={({match: inner_match}) => {
          const {page_id} = inner_match.params

          if (page_id === LANDING_PAGE_ID) {
            return null
          }

          return (
            <DeckSlide
              page={report_deck.get_page_by_id(page_id) || {}}
              chart_selections={chart_selections}
              {...page_props}
            />
          )
        }}
      />
      <RouteWithTracing
        path={`${match.url}/${DECK_CHART_SUBPATH}/:spec_id/${SUBSET_SUBPATH}/:subset_id`}
        render={({match: inner_match}) => {

          const {subset_id, spec_id} = inner_match.params
          const chart = report_deck.get_chart_by_spec_id(spec_id)
          const {page_id} = chart

          const {clickthrough_subselections, processed_data} = clickthrough_params || {}

          const spec = get_deck_spec(spec_id)
          const {root_chart_spec_id} = spec || {}

          const chart_clickthrough_selections = _.pick({...chart_selections[root_chart_spec_id], ...chart_selections[spec_id]}, 'status_filter')
          const clickthrough_selections = {...minimal_selections, ...chart_clickthrough_selections}

          const item = {...chart, timerange: (chart_selections[spec_id] || {}).selected_timerange}
          const { header } = item || {}

          return (

            <div className={s.list_container}>
              <PatentsContainer
                base_path={inner_match.url}
                is_clickthrough={false}
                clickthrough_item={clickthrough_item}
                subset_id={subset_id}
                data_creation_date={data_creation_date}
                spec={{
                  ...spec,
                  deck_chart_header: header,
                  deck_chart_title: get_title_for_display({
                    chart: item,
                    status_filter: (selections || {}).status_filter,
                    spotlighted_tech_areas: (selections || {}).spotlighted_tech_areas || [],
                    tech_areas: ref_data.tech_areas,
                    org_lists: ref_data.org_lists,
                    clickthrough_item
                  })}}
                data={processed_data}
                selections={selections}
                item={item}
                minimal_selections={clickthrough_selections}
                subselections={clickthrough_subselections || []}
                internal_report_id={internal_report_id}
                report_title={report_title}
                report_type={BENCHMARKING_REPORT_TYPE}
                report_has_scores={false}
                is_report_deck={true}
                on_close={() => close_clickthrough(page_id)}
                controls_row_className={s.controls_row} // for setting sticky top
                table_header_className={s.table_header} // for setting sticky top
                family_details_item_header_className={s.family_details_header}
                family_details_controls_row_className={s.family_details_controls_row} // for setting sticky top
                ref_data={ref_data}

                is_family_tagging_mode_on={is_family_tagging_mode_on}
                family_tagging_search_phrase={family_tagging_search_phrase}
                on_toggle_family_tagging_mode={on_toggle_family_tagging_mode}
                on_update_family_tagging_search_phrase={on_update_family_tagging_search_phrase}

                detailsClassName={s.family_details_wrapper}

                className={cn('h-100', s.list)}
              />
            </div>
          )
        }}
      />
    </>
  )
}

export default withRouter(DeckViewer)
