import React from 'react'
import cn from 'classnames'
import _ from 'underscore'
import PropTypes from 'prop-types'

import ControlsBarDropdown from './ControlsBarDropdown.js'
import CheckboxGroupWithControls from './CheckboxGroupWithControls.js'
import TerritorySelector from './TerritorySelector.js'
import TimerangeSelector from './TimerangeSelector.js'

import { get_count_inc_rollups, get_non_empty_items, get_technology_description } from '../../utils/ref_data_utils.js'
import { get_items_as_tree, remove_single_child_nodes } from '../../utils/classifier_tree_utils.js'
import { GEO_SELECTOR_ID, ORG_SELECTOR_ID, TECH_SELECTOR_ID, TIME_SELECTOR_ID } from '../../constants/filters.js'
import { SPEC_GROUP_TO_CLASSNAME } from './dataset_groups_styles.js'

import s from './Filters.module.scss'

function get_selected_portfolio_count_incl_rollups(portfolios, selected_portfolio_ids) {
  const id_to_portfolio = portfolios.reduce((acc, portfolio) => {
    acc[portfolio.id] = portfolio // mutate, as may be a large array
    return acc
  }, {})
  const selected_portfolios = selected_portfolio_ids.map(id => id_to_portfolio[id])
  return get_count_inc_rollups(selected_portfolios)
}

function get_items_as_single_tree(items) {
  // Parse into a single tree
  const root_tree = get_items_as_tree(items, 'Technologies')

  // Remove any shared paths
  const root_tree_trimmed = remove_single_child_nodes(root_tree)
  return root_tree_trimmed
}

function get_tech_as_classifier(item) {
  const { id } = item
  return {
    ...item,
    classifier_id: id + "", // ClassifiersCheckboxTree expects 'classifier_id' string property (used for identifying nodes), so we coerce the int here.
  }
}


const Filters = (
  {
    portfolios,
    portfolios_count_incl_rollups,
    techs,
    techs_count_incl_rollups,
    geos,
    continent_id_to_geo_ids,
    selected_portfolio_ids,
    selected_tech_ids,
    selected_geo_ids,
    set_selected_portfolio_ids,
    set_selected_tech_ids,
    set_selected_geo_ids,
    selected_timerange,
    set_selected_timerange,
    timerange_field_id_to_default_extent,
    chart_filters,
    report_has_ungrouped_families,
    spotlighted_portfolios,
    spotlighted_techs,
    set_spotlighted_portfolios,
    set_spotlighted_techs,
    can_clear_portfolio_spotlights,
    can_clear_tech_spotlights,
    show_ungrouped_families,
    toggle_show_ungrouped_families,
    portfolios_next_agglom_visible,
    techs_next_agglom_visible,
    on_change_portfolios_next_agglom_item_visibility,
    on_change_techs_next_agglom_item_visibility,
    portfolios_agglom_controls,
    techs_agglom_controls,
    selected_portfolios_count,
    selected_techs_count,
    geos_count_incl_rollups,
    geos_agglom_controls,
    geos_next_agglom_visible,
    on_change_geos_next_agglom_item_visibility,
    selected_geos_count,
    update_report_selected_portfolios,
    update_report_selected_techs,
    update_report_selected_geos,
    no_rollups,
    group_id,
    className
}) => {

  function set_spotlighted_portfolio_ids(ids) {
    const spotlighted = ids && ids.length ? {ids, apply: true} : null
    set_spotlighted_portfolios(spotlighted)
  }

  function set_spotlighted_tech_ids(ids) {
    const spotlighted = ids && ids.length ? {ids, apply: true} : null
    set_spotlighted_techs(spotlighted)
  }

  function toggle_spotlighted_portfolios() {
    if (!spotlighted_portfolios) return
    const {apply=false} = spotlighted_portfolios

    if (!can_clear_portfolio_spotlights) {
      set_spotlighted_portfolios({apply: !apply})
      return
    }

    set_spotlighted_portfolios({...spotlighted_portfolios, apply: !apply})
  }

  function toggle_spotlighted_techs() {
    if (!spotlighted_techs) return
    const {apply=false} = spotlighted_techs

    if (!can_clear_tech_spotlights) {
      set_spotlighted_techs({apply: !apply})
      return
    }

    set_spotlighted_techs({...spotlighted_techs, apply: !apply})
  }

  const selected_portfolio_count_incl_rollups = !portfolios ? null : selected_portfolios_count ? selected_portfolios_count : get_selected_portfolio_count_incl_rollups(portfolios, selected_portfolio_ids)

  const non_empty_techs = get_non_empty_items(techs)

  // Currently CheckboxTree expects "classifier" data structures.
  // For now, we fake these below.
  // TODO: consider making CheckboxTree more generic, or extracting a generic version.
  const show_tech_tree           = non_empty_techs && (non_empty_techs.length > 1) && non_empty_techs.some(tech => tech.tree_path) && !chart_filters
  const non_empty_techs_clean    = !show_tech_tree ? null : non_empty_techs.map(get_tech_as_classifier) // fake "classifier" datastructures

  const non_empty_tech_ids = non_empty_techs.map(item => item.id)
  const selected_tech_ids_excluding_empty = (selected_tech_ids || []).filter(id => non_empty_tech_ids.indexOf(id) > -1)

  const items_as_single_tree     = !show_tech_tree ? null : get_items_as_single_tree(non_empty_techs_clean)
  const tech_tree                = items_as_single_tree && _.some(items_as_single_tree.children, item => (item.children != null)) ? items_as_single_tree : null
  const selected_tech_tree_items = !show_tech_tree ? null : non_empty_techs_clean.filter(item => _.contains(selected_tech_ids_excluding_empty, item.id))
  
  const { ids: spotlighted_portfolio_ids, apply: apply_portfolios_spotlights } = spotlighted_portfolios || {}
  const { ids: spotlighted_tech_ids, apply: apply_techs_spotlights }           = spotlighted_techs || {}

  return (
    <div className={cn('d-flex', className)}>
      {/* Portfolio =================================== */}

      {(!chart_filters || (chart_filters && portfolios)) &&
        <ControlsBarDropdown
          label={'Organisations'}
          short_label={'Orgs'}
          disable={!portfolios}
          items={portfolios}
          selected_item_ids={selected_portfolio_ids}
          items_count={portfolios_count_incl_rollups}
          selected_count={selected_portfolio_count_incl_rollups}
          toggle_highlighted_classname={chart_filters ? SPEC_GROUP_TO_CLASSNAME[group_id] : undefined}
          className={'my-auto'}
        >
          <CheckboxGroupWithControls
            chart_filters={chart_filters}
            selector_id={ORG_SELECTOR_ID}
            id_key={'portfolio_id'}
            items={portfolios}
            selected_item_ids={selected_portfolio_ids}
            set_selected_item_ids={set_selected_portfolio_ids}
            spotlighted_item_ids={spotlighted_portfolio_ids || []}
            set_spotlighted_item_ids={set_spotlighted_portfolio_ids}
            toggle_spotlighting={toggle_spotlighted_portfolios}
            should_apply_spotlights={apply_portfolios_spotlights}
            can_clear_spotlights={can_clear_portfolio_spotlights}
            is_paginated={true}
            show_selection_view_controls={!chart_filters}
            show_sort_controls={!chart_filters}
            default_sort_info={'by default, organisations are sorted by size active (the number of granted or pending patent families owned)'}
            agglom_controls={portfolios_agglom_controls}
            next_agglom_selected={portfolios_next_agglom_visible}
            toggle_next_agglom_visibility={on_change_portfolios_next_agglom_item_visibility}
            update_global_filters={update_report_selected_portfolios}
            no_rollups={no_rollups}
          />

        </ControlsBarDropdown>
      }

      {/* Technology ================================= */}
      {(!chart_filters || (chart_filters && techs)) &&
        <ControlsBarDropdown
          className={'my-auto ms-1 ms-lg-2'}
          label={'Technologies'}
          short_label={'Techs'}
          disable={!non_empty_techs}
          items={non_empty_techs}
          selected_item_ids={selected_tech_ids_excluding_empty}
          items_count={techs_count_incl_rollups}
          selected_count={selected_techs_count}
          menu_className={cn({
            [s.tech_tree_dropdown_menu]: tech_tree
          })}
          toggle_highlighted_classname={chart_filters ? SPEC_GROUP_TO_CLASSNAME[group_id] : undefined}
        >
          <CheckboxGroupWithControls
            chart_filters={chart_filters}
            selector_id={TECH_SELECTOR_ID}
            id_key={'technology_id'}
            items={techs}
            selected_item_ids={selected_tech_ids_excluding_empty}
            set_selected_item_ids={set_selected_tech_ids}
            empty_items_section_header={'Empty technologies'}
            spotlighted_item_ids={spotlighted_tech_ids || []}
            set_spotlighted_item_ids={set_spotlighted_tech_ids}
            toggle_spotlighting={toggle_spotlighted_techs}
            should_apply_spotlights={apply_techs_spotlights}
            can_clear_spotlights={can_clear_tech_spotlights}
            tree={tech_tree} // may be null
            selected_tree_items={selected_tech_tree_items} // may be null
            show_selection_view_controls={false}
            show_sort_controls={false}
            agglom_controls={techs_agglom_controls}
            next_agglom_selected={techs_next_agglom_visible}
            toggle_next_agglom_visibility={on_change_techs_next_agglom_item_visibility}
            update_global_filters={update_report_selected_techs}
            no_rollups={no_rollups}
            is_paginated={chart_filters}

            get_description={(item) => get_technology_description((item || {}).source_value )}
          />
        </ControlsBarDropdown>
      }

      {/* Territory ================================== */}
      {(!chart_filters || (chart_filters && geos)) &&
        <ControlsBarDropdown
          className={'my-auto ms-1 ms-lg-2'}
          label={'Territories'}
          short_label={'Geos'}
          disable={!geos}
          items={geos}
          selected_item_ids={selected_geo_ids}
          items_count={geos_count_incl_rollups}
          selected_count={selected_geos_count}
          toggle_highlighted_classname={chart_filters ? SPEC_GROUP_TO_CLASSNAME[group_id] : undefined}
        >
          {chart_filters &&
            <CheckboxGroupWithControls
              chart_filters={chart_filters}
              selector_id={GEO_SELECTOR_ID}
              id_key={'country_code'}
              items={geos}
              selected_item_ids={selected_geo_ids}
              set_selected_item_ids={set_selected_geo_ids}
              is_paginated={true}
              show_selection_view_controls={false}
              show_sort_controls={false}
              agglom_controls={geos_agglom_controls}
              next_agglom_selected={geos_next_agglom_visible}
              toggle_next_agglom_visibility={on_change_geos_next_agglom_item_visibility}
              update_global_filters={update_report_selected_geos}
              no_rollups={no_rollups}
            />
          }
          {!chart_filters &&
            <TerritorySelector
              selector_id={GEO_SELECTOR_ID}
              geos={geos}
              continent_id_to_geo_ids={continent_id_to_geo_ids}
              selected_geo_ids={selected_geo_ids}
              set_selected_geo_ids={set_selected_geo_ids}
              toggle_show_ungrouped_families={toggle_show_ungrouped_families}
              show_ungrouped_families={show_ungrouped_families}
              report_has_ungrouped_families={report_has_ungrouped_families}
            />
          }
        </ControlsBarDropdown>
      }

      {!chart_filters &&
        <ControlsBarDropdown
          className={'my-auto ms-1 ms-lg-2'}
          label={'Time'}
          short_label={'Time'}
          selected_timerange={selected_timerange}
          disable={!timerange_field_id_to_default_extent}
        >
          <TimerangeSelector
            selector_id={TIME_SELECTOR_ID}
            timerange_field_id_to_default_extent={timerange_field_id_to_default_extent}
            selected_timerange={selected_timerange}
            set_selected_timerange={set_selected_timerange}
            className='py-1 px-2'
          />
        </ControlsBarDropdown>
      }
    </div>

  )
}

Filters.propTypes = {
  portfolios:                 PropTypes.arrayOf(PropTypes.object), // may be null
  techs:                      PropTypes.arrayOf(PropTypes.object), // may be null
  geos:                       PropTypes.arrayOf(PropTypes.object), // may be null
  selected_portfolio_ids:     PropTypes.array,                     // may be null
  selected_tech_ids:          PropTypes.array,                     // may be null
  selected_geo_ids:           PropTypes.array,                     // may be null
  set_selected_portfolio_ids: PropTypes.func.isRequired,
  set_selected_tech_ids:      PropTypes.func.isRequired,
  set_selected_geo_ids:       PropTypes.func.isRequired,
}

export default Filters