import _ from 'underscore'

import {
  TECH_PARTITIONING_TYPE_CLASSIFIER,
  TECH_PARTITIONING_TYPE_CLUSTERING,
  TECH_PARTITIONING_TYPE_UTT
} from '../model/technology_basket.js'
import { SELECTED_PORTFOLIOS, SELECTED_TECHS } from '../model/deref.js'

export function overlap_unavailable_reason({deref_data, is_org_selected, technology_partitioning}) {
  const selections = deref_data || {}
  const selected_portfolios = selections[SELECTED_PORTFOLIOS] || []
  const selected_techs = selections[SELECTED_TECHS] || []
  const {multi_label= false, type: partitioning_type} = technology_partitioning || {}

  if (is_org_selected) {
    return (selected_portfolios.length < 2) ? 'Not enough organisations in this report or selection.' : null
  }
  if (selected_techs.length < 2) {
    return 'Not enough technologies in this report or selection.'
  }
  if (partitioning_type === TECH_PARTITIONING_TYPE_CLASSIFIER && !multi_label) {
    return 'This classifier report was run in \'mutually exclusive\' mode. To see the overlap between these technologies, please rerun the report with \'overlapping technologies\'.'
  }
  if (_.contains([TECH_PARTITIONING_TYPE_UTT, TECH_PARTITIONING_TYPE_CLUSTERING], partitioning_type)) {
    return 'In this type of report, families are assigned to only one technology, so it is not possible to analyse overlap.'
  }
  return null
}