import React, { Component } from 'react'
import cn from 'classnames'
import _ from 'underscore'

import { TABLE_COLUMN_HEADER } from '../../model/table.js'
import { BaseTable } from './BaseTable.js'
import { get_csv_string } from '../../utils/csv_utils.js'
import { get_display_value, IS_NEXT_AGGLOM } from '../../utils/column_data_utils.js'
import { get_timerange } from '../../utils/time_range_utils.js'
import { KEY_COLUMN_IDX_1D } from '../../model/column_data.js'
import { format_integer_with_comma } from '../../utils/utils.js'
import { should_hide_next_agglom_item } from '../../utils/main_items_utils.js'

function get_csv_value_rows({ spec, data, key_dims, item, data_creation_date }) {
  const timerange = get_timerange({spec, item, key_dims, data_creation_date})

  const tabular_data_rows = get_processed_data({ spec, data, key_dims, item, data_creation_date })

  const header_row = get_columns({spec, timerange}).map(item => (item.label))
  return [
    header_row,
    ...tabular_data_rows
  ]
}

function get_plotly_data({ spec, data, key_dims, item, data_creation_date }) {
  const csv_data_rows = get_csv_value_rows({ spec, data, key_dims, item, data_creation_date })

  return get_csv_string(csv_data_rows)
}

function get_processed_data({ spec, item, data, key_dims }) {
  const x_items = key_dims[KEY_COLUMN_IDX_1D]
  const hide_next = should_hide_next_agglom_item(spec, item, KEY_COLUMN_IDX_1D)
  const x_items_filtered = !hide_next ? x_items : x_items.filter(item => (!item[IS_NEXT_AGGLOM]))

  const value_column_idxs = [1,2,3]

  let rows = []

  x_items_filtered.forEach(x_item => {
    const row_idx = data.data[KEY_COLUMN_IDX_1D].indexOf(x_item.id)
    if (row_idx !== -1) {
      const values = value_column_idxs.map(col_idx => data.data[col_idx][row_idx])
      rows = [...rows, [x_item.name, ...values]]
    }
  })

  // show sorted by CAGR descending, to match columns
  return !_.isEmpty(rows) ? _.sortBy(rows, row => row[3]).reverse() : rows
}

function get_cagr_display_value(value, value_formatter) {
  // only show units (%) in column header
  return get_display_value(value, value_formatter).replace('%', '')
}

function get_columns({spec, timerange}) {
  const { column_names, value_formatter } = spec
  const [year_min, year_max] = timerange

  return [
    {...TABLE_COLUMN_HEADER,
      id: column_names[0],
      label: column_names[0],
      headerClassName: cn('text-start', 'text-endbold'),
      field: row => (row[0])
    },
    {...TABLE_COLUMN_HEADER,
      id: 'year_min_size',
      label: `Active families in ${year_min}`,
      field: row => (row[1]),
      headerClassName: cn('text-end', 'text-endbold'),
      cell_render: function YearMinSize(row) {
        return (
          <div className='text-end'>{format_integer_with_comma(row.original[1])}</div>
        )
      }
    },
    {...TABLE_COLUMN_HEADER,
      id: 'year_max_size',
      label: `Active families in ${year_max}`,
      field: row => (row[2]),
      headerClassName: cn('text-end', 'text-endbold'),
      cell_render: function YearMaxSize(row) {
        return (
          <div className='text-end'>{format_integer_with_comma(row.original[2])}</div>
        )
      }
    },
    {...TABLE_COLUMN_HEADER,
      id: column_names[1],
      label: 'Compound Annual Growth Rate (%)',
      field: row => (row[3]),
      headerClassName: cn('text-end', 'text-endbold'),
      cell_render: function Cagr(row) {
        return (
          <div className='text-end'>{get_cagr_display_value(row.original[3], value_formatter)}</div>
        )
      }
    }
  ]
}

class CagrTabularView extends Component {
  render() {
    const { spec, data, key_dims, item, is_thumbnail, data_creation_date } = this.props

    const timerange = get_timerange({spec, item, key_dims, data_creation_date})
    const columns = get_columns({spec, timerange})

    const tabular_data_rows = get_processed_data({ spec, item, data, key_dims, data_creation_date })

    return (
      <BaseTable
        columns={columns}
        data={tabular_data_rows}
        is_thumbnail={is_thumbnail}
      />
    )
  }
}

CagrTabularView.get_plotly_data = get_plotly_data
CagrTabularView.get_csv_value_rows = get_csv_value_rows

export default CagrTabularView