import React from 'react'

import { TagsIcon } from '../widgets/IconSet.js'
import Tooltip from '../widgets/Tooltip.js'
import { PaneHeader } from '../widgets/PaneHeader.js'
import OrgTagSelector from './OrgTagSelector.js'
import TextLink from '../widgets/TextLink.js'

const OrgTagsPopup = ({className, tags, reference}) => {

  if ((tags || []).length === 0) return null

  return (
    <div className={className}>
      <Tooltip
        placement='bottom'
        toggler={(<span id={reference}><TextLink><TagsIcon /></TextLink></span>)}
      >
        <PaneHeader text='Tags'/>
        <OrgTagSelector
          selected_tags={tags}
          view_only_selected={true}
        />
      </Tooltip>
    </div>
  )
}

export default OrgTagsPopup