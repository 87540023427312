import React, { useState, useEffect } from 'react'
import cn from 'classnames'

import Tooltip from '../widgets/Tooltip.js'
import Spinner from '../widgets/Spinner.js'
import { useToggle } from '../../hooks/general_hooks.js'
import ErrorModal from '../ErrorModal.js'

import s from './SamplePopup.module.scss'

const SamplePopup = ({reference, org, fetch_items_handler, toggler, title, get_heading_handler, item_display_handler}) => {
  const [open, toggle] = useToggle(false)
  const [show_spinner, set_show_spinner] = useState(true)
  const [items, set_items] = useState(null)
  const [error, set_error] = useState(null)

  useEffect(() => {
    // if org changes (as it can in OrgSearchMultipleMatchesDropdown) items need to be refreshed
    set_show_spinner(true)
    set_items(null)
  }, [org])

  useEffect(() => {
    let did_cancel = false

    if (open && !items) {
      fetch_items_handler(org)
        .then(items => {
          if (!did_cancel) {
            set_items(items)
            set_show_spinner(false)
          }
        })
        .catch(error => {
          if (!did_cancel) {
            set_items([])
            set_error(error)
          }
        })
    }

    return () => {
      did_cancel = true
    }

  }, [open, items, org, fetch_items_handler])

  if (error) {
    return (
      <ErrorModal
        context={'fetching samples'}
        on_hide={() => set_error(null)}
        error={error}
      />
    )
  }

  return (
    <div className={s.active}>
      <Tooltip
        on_show={toggle}
        on_hide={toggle}
        title={title}
        toggler={(<span id={reference}>{toggler}</span>)}
        is_in_modal
        light
      >
        {show_spinner &&
          <div className='text-center'>
            <div>Fetching results</div>
            <Spinner />
          </div>
        }

        {!show_spinner && items &&
          <div>
            <div className={s.heading}>{get_heading_handler(items)}</div>
            {items.map((item, i) => (<div className={cn('mb-1', s.item)} key={i}>{item_display_handler(item)}</div>))}
            {items.length === 0 && <div className='mb-1'>[no data to display]</div>}
          </div>
        }
      </Tooltip>
    </div>
  )

}

export default SamplePopup