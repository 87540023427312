import {
  AGE_GROUP_ID, COST_GROUP_ID,
  GEOGRAPHY_GROUP_ID, LITIGATION_GROUP_ID,
  PATENTING_ACTIVITY_GROUP_ID,
  PORTFOLIO_SIZE_GROUP_ID, PORTFOLIO_STATS_GROUP_ID,
  PORTFOLIO_TRENDS_GROUP_ID, PVIX_SCORE_GROUP_ID, SCORE_GROUP_ID, SELECTED_GROUP_ID
} from '../../model/spec_groups/spec_group_ids.js'

import dsg from './dataset_groups.module.scss'

export const SPEC_GROUP_TO_CLASSNAME = {
  [PORTFOLIO_SIZE_GROUP_ID]: dsg.portfolio_size_spec,
  [PORTFOLIO_TRENDS_GROUP_ID]: dsg.portfolio_trends_spec,
  [PATENTING_ACTIVITY_GROUP_ID]: dsg.patenting_activity_spec,
  [GEOGRAPHY_GROUP_ID]: dsg.geo_spec,
  [AGE_GROUP_ID]: dsg.age_spec,
  [COST_GROUP_ID]: dsg.cost_spec,
  [LITIGATION_GROUP_ID]: dsg.dispute_spec,
  [PORTFOLIO_STATS_GROUP_ID]: dsg.stats_spec,
  [SCORE_GROUP_ID]: dsg.score_spec,
  [PVIX_SCORE_GROUP_ID]: dsg.pvix_spec,
  [SELECTED_GROUP_ID]: dsg.selected_spec
}

export const SPEC_GROUP_TO_THUMBNAIL_CLASSNAME = {
  [PORTFOLIO_SIZE_GROUP_ID]: dsg.portfolio_size_spec_thumbnail,
  [PORTFOLIO_TRENDS_GROUP_ID]: dsg.portfolio_trends_spec_thumbnail,
  [PATENTING_ACTIVITY_GROUP_ID]: dsg.patenting_activity_spec_thumbnail,
  [GEOGRAPHY_GROUP_ID]: dsg.geo_spec_thumbnail,
  [AGE_GROUP_ID]: dsg.age_spec_thumbnail,
  [COST_GROUP_ID]: dsg.cost_spec_thumbnail,
  [LITIGATION_GROUP_ID]: dsg.dispute_spec_thumbnail,
  [PORTFOLIO_STATS_GROUP_ID]: dsg.stats_spec_thumbnail,
  [SCORE_GROUP_ID]: dsg.score_spec_thumbnail,
  [PVIX_SCORE_GROUP_ID]: dsg.pvix_spec_thumbnail,
}

export const SPEC_GROUP_TO_MARKER_CLASSNAME = {
  [PORTFOLIO_SIZE_GROUP_ID]: dsg.portfolio_size_marker,
  [PORTFOLIO_TRENDS_GROUP_ID]: dsg.portfolio_trends_marker,
  [PATENTING_ACTIVITY_GROUP_ID]: dsg.patenting_activity_marker,
  [GEOGRAPHY_GROUP_ID]: dsg.geo_marker,
  [AGE_GROUP_ID]: dsg.age_marker,
  [COST_GROUP_ID]: dsg.cost_marker,
  [LITIGATION_GROUP_ID]: dsg.dispute_marker,
  [PORTFOLIO_STATS_GROUP_ID]: dsg.stats_marker,
  [SCORE_GROUP_ID]: dsg.score_marker,
  [PVIX_SCORE_GROUP_ID]: dsg.pvix_marker,
  [SELECTED_GROUP_ID]: dsg.selected_marker,
}

export const SPEC_GROUP_TO_BORDER_CLASSNAME = {
  [PORTFOLIO_SIZE_GROUP_ID]: dsg.portfolio_size_border,
  [PORTFOLIO_TRENDS_GROUP_ID]: dsg.portfolio_trends_border,
  [PATENTING_ACTIVITY_GROUP_ID]: dsg.patenting_activity_border,
  [GEOGRAPHY_GROUP_ID]: dsg.geo_border,
  [AGE_GROUP_ID]: dsg.age_border,
  [COST_GROUP_ID]: dsg.cost_border,
  [LITIGATION_GROUP_ID]: dsg.dispute_border,
  [PORTFOLIO_STATS_GROUP_ID]: dsg.stats_border,
  [SCORE_GROUP_ID]: dsg.score_border,
  [PVIX_SCORE_GROUP_ID]: dsg.pvix_border,
}

export const SPEC_GROUP_TO_TOOLTIP_CLASSNAME = {
  [PORTFOLIO_SIZE_GROUP_ID]: dsg.portfolio_size_spec_tooltip,
  [PORTFOLIO_TRENDS_GROUP_ID]: dsg.portfolio_trends_spec_tooltip,
  [PATENTING_ACTIVITY_GROUP_ID]: dsg.patenting_activity_spec_tooltip,
  [GEOGRAPHY_GROUP_ID]: dsg.geo_spec_tooltip,
  [AGE_GROUP_ID]: dsg.age_spec_tooltip,
  [COST_GROUP_ID]: dsg.cost_spec_tooltip,
  [LITIGATION_GROUP_ID]: dsg.dispute_spec_tooltip,
  [PORTFOLIO_STATS_GROUP_ID]: dsg.stats_spec_tooltip,
  [SCORE_GROUP_ID]: dsg.score_spec_tooltip,
  [PVIX_SCORE_GROUP_ID]: dsg.pvix_spec_tooltip,
  [SELECTED_GROUP_ID]: dsg.selected_spec_tooltip
}

export const SPEC_GROUP_TO_TOOLTIP_OVERLAY_CLASSNAME = {
  [PORTFOLIO_SIZE_GROUP_ID]: dsg.portfolio_size_spec_tooltip_overlay,
  [PORTFOLIO_TRENDS_GROUP_ID]: dsg.portfolio_trends_spec_tooltip_overlay,
  [PATENTING_ACTIVITY_GROUP_ID]: dsg.patenting_activity_spec_tooltip_overlay,
  [GEOGRAPHY_GROUP_ID]: dsg.geo_spec_tooltip_overlay,
  [AGE_GROUP_ID]: dsg.age_spec_tooltip_overlay,
  [COST_GROUP_ID]: dsg.cost_spec_tooltip_overlay,
  [LITIGATION_GROUP_ID]: dsg.dispute_spec_tooltip_overlay,
  [PORTFOLIO_STATS_GROUP_ID]: dsg.stats_spec_tooltip_overlay,
  [SCORE_GROUP_ID]: dsg.score_spec_tooltip_overlay,
  [PVIX_SCORE_GROUP_ID]: dsg.pvix_spec_tooltip_overlay,
  [SELECTED_GROUP_ID]: dsg.selected_spec_tooltip_tooltip_overlay
}