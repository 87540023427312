import React, { useState } from 'react'
import _ from 'underscore'
import cn from 'classnames'

import {
  get_processed_data_and_key_dims,
  get_selected_timerange_items,
  is_data_empty
} from '../../../utils/column_data_utils.js'
import { is_int } from '../../../utils/utils.js'
import Spinner from '../../widgets/Spinner.js'
import ErrorBody from '../../ErrorBody.js'
import NoDataInSelection from '../../NoDataInSelection.js'
import { ID_TO_VIEW } from '../../../model/views.js'
import {
  calculate_chart_height,
  get_deck_spec,
  get_description_for_display,
  get_title_for_display,
  portfolio_ids_in_target_list
} from '../../../utils/report_deck_utils.js'
import ReportDeckSeriesPopover from './ReportDeckSeriesPopover.js'
import { REPORT_SERIES_SORT_BY_SIZE } from '../../../utils/report_utils.js'
import ChartDescription from './ChartDescription.js'
import StatusToggle from './StatusToggle.js'
import {
  add_or_remove_selected_item,
  is_portfolio_id,
  is_technology_area_id
} from '../../../utils/report_deck_filter_utils.js'
import TimerangeSelect from './TimerangeSelect.js'
import ClickthroughItemSelector from './ClickthroughItemSelector.js'
import { get_extent_from_key_items } from '../../../utils/time_range_utils.js'

import {
  EXCEL_FILE_EXT,
  fetch_excel_document,
  get_download_filename,
  MIMETYPE_XLSX,
  trigger_download
} from '../../../utils/download_utils.js'
import { ScrollModal } from '../../widgets/Modal.js'
import { PrimaryButton } from '../../widgets/Button.js'
import ErrorModal from '../../ErrorModal.js'
import { track_deck_viewer_event } from '../../../utils/tracking_utils.js'

import s from './DeckPageView.module.scss'

const DeckPageView = (
  {
    report_deck,
    deck_type,
    chart,
    chart_selection,
    parent_dimensions,
    selections,
    minimal_selections,
    internal_report_id,
    external_report_id,
    data_creation_date,
    ref_data,
    deref_data,
    clickthrough_item,
    spec_id_to_fetch_obj,
    is_edit_mode,
    on_chart_description_change,
    on_chart_status_filter_change,
    on_chart_timerange_change,
    on_clickthrough_item_change,
    on_deck_spotlighted_tech_areas_change,
    on_deck_spotlighted_orgs_change,
    set_current_page,
    handle_families_clickthrough
  }
) => {
  const [active_chart_click, set_active_chart_click] = useState(null)
  const [download_input, set_download_input]         = useState(null)
  const [download_error, set_download_error]         = useState(null)

  if (!chart) return null

  const report_series_sort = REPORT_SERIES_SORT_BY_SIZE.id
  const { status_filter: deck_status_filter, spotlighted_tech_areas=[], spotlighted_orgs=[] } = selections
  const { spec_id, view_id, header, is_family_count, get_accent_colour, more_info_url, no_data_message, clickthrough_detail_page } = chart
  const { description: custom_description, status_filter: custom_status_filter, selected_timerange } = chart_selection || {}
  const spec = get_deck_spec(spec_id)
  const { timeseries_key_dim_idxs, can_apply_status_switch, default_status, get_hidden_dim_full_timerange, can_apply_clickthrough_item_switch } = spec
  const is_any_timeseries = (timeseries_key_dim_idxs && timeseries_key_dim_idxs.length) || get_hidden_dim_full_timerange

  const { is_fetching, data, families_total_count, fetch_error } = spec_id_to_fetch_obj[spec_id] || {}

  const view = ID_TO_VIEW[view_id]

  const { ViewComponent } = view

  const [processed_data, processed_key_dims, original_key_dims] = !data ? [null, null, null] : get_processed_data_and_key_dims(spec, view_id, data_creation_date, {...chart, ...is_any_timeseries ? {timerange: selected_timerange} : {}}, data, ref_data, deref_data, report_series_sort, false, (chart.rollup_thresholds != null))

  const data_is_empty = !data || (data && is_data_empty(data, original_key_dims, selections, spec))
  const dataset_is_empty = _.some(processed_key_dims, (dim_keys) => (!dim_keys || dim_keys.length === 0))
  // time series charts are super ugly when empty
  const selected_timerange_is_empty = (selected_timerange && ((selected_timerange[1] - selected_timerange[0]) <= 1)) || (data && data.data[0].length <= 1)

  // temporary fix for empty cagr charts
  // todo: charts with an extra calculation/ transform function should have their own 'get_filtered_key_dims' style function to exclude 0 series
  const processed_data_is_empty = processed_data && is_data_empty(processed_data, original_key_dims, selections, spec)

  const no_data_to_display = is_fetching ? null : (data_is_empty || processed_data_is_empty || dataset_is_empty || selected_timerange_is_empty)

  function on_chart_click(chart_click) {
    const {coords, value} = chart_click

    if (coords) {
      const {get_series_clickthrough_value} = chart

      const value_to_display = get_series_clickthrough_value ? get_series_clickthrough_value({chart_click, data: processed_data}) : value
      // click on a chart: display info in a popover
      return set_active_chart_click({...chart_click, value: value_to_display})

    }
    // click on a table: go straight to families
    handle_families_clickthrough({chart, processed_data, clickthrough_subselections: chart_click})
  }

  function on_spotlight(clicked_item) {
    const { key, items } = clicked_item
    const item = _.first(items)
    if (is_int(item.id) && is_portfolio_id(key)) {
      add_or_remove_selected_item(item.id, spotlighted_orgs, on_deck_spotlighted_orgs_change)
    }
    if (is_int(item.id) && is_technology_area_id(key)) {
      add_or_remove_selected_item(item.id, spotlighted_tech_areas, on_deck_spotlighted_tech_areas_change)
    }
  }

  function is_spotlighted(clicked_item) {
    const { key, items } = clicked_item
    const item = _.first(items)
    if (is_int(item.id) && is_portfolio_id(key)) {
      return _.contains(spotlighted_orgs, item.id)
    }
    if (is_int(item.id) && is_technology_area_id(key)) {
      return _.contains(spotlighted_tech_areas, item.id)
    }
    return false
  }

  function do_export() {
    const filename = get_download_filename({report_title: title_to_render, view_id: download_input.view_id, file_ext: EXCEL_FILE_EXT})

    fetch_excel_document([[download_input]], false, true)
      .then(arraybuffer => {
        trigger_download(arraybuffer, MIMETYPE_XLSX, filename)
      })
      .catch(error => {
        set_download_error(error)
      })
  }

  function on_detail_clickthrough() {
    const {item} = active_chart_click
    const {key, items} = (item || {})
    if (is_portfolio_id(key)) {
      const ids = (items || []).map(item => item.id)
      item.is_target_org = portfolio_ids_in_target_list(ids, ref_data.portfolio_to_company_list)
    }
    on_clickthrough_item_change(spec_id, item)
    // close popup
    set_active_chart_click(null)
    track_deck_viewer_event(`obj="dataset" dataset="${spec_id}" action="view_clickthrough_charts" deck_type="${deck_type}"`)
    set_current_page(clickthrough_detail_page)
  }

  const chart_status_filter = custom_status_filter || deck_status_filter || default_status

  const is_renderable = (data && !no_data_to_display)

  const title_to_render = get_title_for_display({
    chart,
    status_filter: chart_status_filter,
    timerange: is_any_timeseries && data ? get_extent_from_key_items(get_selected_timerange_items((timeseries_key_dim_idxs ? original_key_dims[timeseries_key_dim_idxs[0]] : get_hidden_dim_full_timerange(data_creation_date)), selected_timerange, spec, data_creation_date)) : null,
    spotlighted_tech_areas,
    tech_areas: ref_data.tech_areas,
    org_lists: ref_data.org_lists,
    clickthrough_item
  })
  const [main_title, subtitle] = title_to_render.split(":")
  const accent_color = get_accent_colour ? get_accent_colour({spotlighted_tech_areas}) : null

  const chart_height = calculate_chart_height({parent_dimensions, view_id})
  const clickthrough_detail_text = clickthrough_detail_page ? report_deck.get_page_by_id(clickthrough_detail_page).title : null

  return (
    <div className={cn('mt-1 mt-md-2', s.block)}>
      <div className={cn('pb-1 mb-2 px-2 d-flex justify-content-between', s.title_container)}>
        <div>
          <span className={s.title__category}>{header}</span>
          <span className='ms-1'>{main_title}</span>
          {subtitle &&
            <>:&nbsp;<span style={{color: accent_color}}>{subtitle}</span></>
          }
        </div>
      </div>

      {!is_renderable &&
        <div>
          {is_fetching &&
            <div className='mt-2 text-center'>
              <Spinner/>
            </div>
          }

          {fetch_error &&
            <ErrorBody
              error={fetch_error}
              context={'fetching report data'}
            />
          }
        </div>
      }

      { !is_fetching && !fetch_error &&
        <div className={cn('d-flex justify-content-end mb-3', s.chart_controls_block)}>
          {(no_data_to_display || selected_timerange_is_empty) &&
            <div className='ms-0 me-auto'>
              <NoDataInSelection className='mb-2' no_data_message={no_data_message} />
            </div>
          }
          { is_edit_mode &&
            <>
              { !data_is_empty && is_any_timeseries &&
                <TimerangeSelect
                  is_benchmarking={true}
                  spec={spec}
                  data_creation_date={data_creation_date}
                  original_key_dims={original_key_dims}
                  chart_selection={chart_selection}
                  on_change_timerange={(timerange) => on_chart_timerange_change(spec_id, timerange)}
                  className='me-3'
                />
              }

              { can_apply_clickthrough_item_switch &&
                <ClickthroughItemSelector
                  clickthrough_item={clickthrough_item}
                  selected_org_lists={selections.selected_org_lists}
                  org_lists={ref_data.org_lists}
                  on_clickthrough_item_change={(clickthrough_item) => on_clickthrough_item_change(spec_id, clickthrough_item)}
                />
              }

              { can_apply_status_switch &&
                <StatusToggle
                  status_filter={chart_status_filter}
                  on_click={(status_filter) => on_chart_status_filter_change(spec_id, status_filter)}
                  in_chart={true}
                  is_benchmarking={true}
                />
              }
            </>
          }
        </div>
      }

      {is_renderable &&
        <>
          {ViewComponent &&
            <>
              {active_chart_click &&
                <ReportDeckSeriesPopover
                  active_chart_click={active_chart_click}
                  on_toggle_spotlight={on_spotlight}
                  is_spotlighted={is_spotlighted(active_chart_click.item)}
                  on_hide={() => set_active_chart_click(null)}
                  ref_data={ref_data}
                  is_family_count={is_family_count}
                  value_formatter={spec.full_length_value_formatter || spec.value_formatter}
                  on_detail_clickthrough={clickthrough_detail_page ? on_detail_clickthrough : null}
                  detail_clickthrough_text={clickthrough_detail_text}
                  on_families_clickthrough={(params) => handle_families_clickthrough({...params, processed_data, chart})}
                />
              }

              {!selected_timerange_is_empty &&
                <>
                  <div className={s.chart_container}>
                    <ViewComponent
                      spec={spec}
                      data={processed_data}
                      families_total_count={families_total_count}
                      key_dims={processed_key_dims}
                      item={{...chart, timerange: selected_timerange}}
                      selections={selections}
                      minimal_selections={minimal_selections}
                      ref_data={ref_data}
                      deref_data={deref_data}
                      clickthrough_item={clickthrough_item}
                      internal_report_id={internal_report_id}
                      external_report_id={external_report_id}
                      is_thumbnail={false}
                      is_report_deck={true}
                      set_families_subselections={on_chart_click}
                      report_series_sort={report_series_sort}
                      data_creation_date={data_creation_date}
                      status_filter={chart_status_filter}

                      chart_wrapper_height={chart_height ? chart_height + 'px' : null}
                      accent_color={accent_color}
                    />
                  </div>

                  <ChartDescription
                    description={custom_description || get_description_for_display({chart, status_filter: chart_status_filter, spotlighted_tech_areas, clickthrough_item})}
                    can_edit={is_edit_mode}
                    on_change_handler={(description) => on_chart_description_change(spec_id, description)}
                    more_info_url={more_info_url}
                  />
                </>
              }
            </>
          }
        </>
      }

      {download_input &&
        <ScrollModal
          is_open={true}
          on_hide={() => set_download_input(null)}
          footer={(<PrimaryButton onClick={() => do_export()}>Download</PrimaryButton>)}
        >
          <pre>
            {JSON.stringify(download_input, undefined, 2)}
          </pre>
        </ScrollModal>
      }

      {download_error &&

        <ErrorModal
          error={download_error}
          on_hide={() => set_download_error(null)}
          context='exporting deck chart to Excel'
        />

      }
    </div>
  )
}

export default DeckPageView
