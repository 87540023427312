import React from 'react'
import cn from 'classnames'

import s from './RadioStatic.module.scss'
import cs from '../cipher_styles.module.scss'

const RadioStatic = ({is_checked, is_disabled, onClick, is_dark_bg, inputClassName, className}) => {
  is_disabled = (is_disabled != null) ? is_disabled : false

  return (
    <div className={cn(s.block, {[s.block__enabled]: !is_disabled}, className)} onClick={!is_disabled? onClick : null}>
      <input
        type='radio'
        tabIndex='0'
        className={cn(s.input, {[cs.cursor_pointer]: !is_disabled, inputClassName})}
      />

      <div className={
        cn(s.radiobox,
          {
            [s.__checked]: is_checked,
            [s.__checked_dark]: is_checked && is_dark_bg,
            [s.radiobox__disabled]: is_disabled,
            [s.radiobox__disabled_dark]: is_disabled && is_dark_bg
          }
        )}
      >
        <div className={cn(s.radiobox_inner, {[s.radiobox_inner__dark]: is_dark_bg})} />
      </div>
    </div>
  )
}

export default RadioStatic