import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { FamilyTagsSection } from './FamilyTagsSection'
import { GroupedTags, fetch_grouped_displayable_tags } from './family_tag_utils'
import { Pane } from '../widgets/Block.js'
import Spinner from '../widgets/Spinner.js'
import ErrorBody from '../ErrorBody.js'
import OrgSuggestionsByFamiliesDisplay from '../orgs/OrgSuggestionsByFamiliesDisplay'
import TextLink from '../widgets/TextLink.js'
import { withUser } from '../UserContext.js'
import { can_tag_families } from '../../utils/user_permissions.js'
import { FTAGS_UI } from '../../constants/paths.js'


interface FamilyTagSetsDisplayProps {
  user:any,
  add_to_basket_handler: Function,
  portfolio_name: string,
  portfolio_items: Array<any>,
  on_click_similar_portfolio: Function,
  update_group_handler: Function,
  reset_similar_portfolio: Function,

  className: string
}

const FamilyTagSectionsDisplay = (props: FamilyTagSetsDisplayProps) => {

  const [user_tags, set_user_tags] = useState<Array<GroupedTags>>([])
  const [fetch_data_error, set_fetch_data_error] = useState(null)
  const [show_spinner, set_show_spinner] = useState(false)

  const {className} = props || {}

  useEffect(() => {
    let did_cancel = false
    set_show_spinner(true)
    fetch_grouped_displayable_tags()
      .catch(error => {
        if (!did_cancel) {
          set_fetch_data_error(error)
          set_show_spinner(false)
        }
      })
      .then(custom_tags => {
        if (!did_cancel && custom_tags) {
          set_fetch_data_error(null)
          set_user_tags(custom_tags)
          set_show_spinner(false)
        }
      })
    return () => {
      did_cancel = true
    }
  }, [])

  const show_similar_portfolios = props.portfolio_name && props.portfolio_items
  const can_user_tag_families = can_tag_families(props.user)

  return (
    <div className={className}>
      {fetch_data_error && !show_spinner &&
        <ErrorBody
          error={fetch_data_error}
          context={'Error fetching family tags'}
        />
      }
      {!fetch_data_error && !show_spinner && can_user_tag_families && !show_similar_portfolios &&
      //@ts-expect-error
        <TextLink
          className='float-end mb-2'
          element={Link}
          target='_blank'
          to={FTAGS_UI}
        >
          Manage family tags
        </TextLink>
      }

      {!fetch_data_error && user_tags.length > 0 && !show_spinner && !show_similar_portfolios &&
        <div className={'pt-3'}>
          {user_tags.map((grouped: GroupedTags, index) => (
            <FamilyTagsSection
              title={grouped.tag_section}
              tags={grouped.tags}
              on_tag_select_handler={props.add_to_basket_handler}
              key={index}
            />
          ))}
        </div>
      }

      {!fetch_data_error && user_tags.length === 0 && !show_spinner &&
      //@ts-expect-error
        <Pane className={'text-center'}>
          No family tags defined
        </Pane>
      }

      {show_spinner &&
        <div className='text-center mt-2'>
          <div>Fetching tags</div>
          <Spinner />
        </div>
      }

      {show_similar_portfolios &&
        <OrgSuggestionsByFamiliesDisplay
          base_name={props.portfolio_name}
          base_items={props.portfolio_items}
          on_click_suggestion={props.on_click_similar_portfolio}
          update_group_handler={props.update_group_handler}
          on_reset_suggestion={props.reset_similar_portfolio}
          tracking_context={'tagged_families'}
        />
      }

    </div>
  )
}

export default withUser(FamilyTagSectionsDisplay)