import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import _ from 'underscore'

import { Table } from '../../widgets/Table.js'
import SortingColumnHeaderCell from '../../patent_family_list/SortingColumnHeaderCell.js'
import { EditableSource } from './EditableSource.js'
import CheckboxStatic from '../../widgets/CheckboxStatic.js'
import { match_patent_family, UPLOAD_MODE_SPIF } from '../../../utils/custom_search_utils.js'
import CipherFamilyLink from '../../widgets/CipherFamilyLink.js'
import { ScrollModal } from '../../widgets/Modal.js'
import { DEFAULT_PAGE_SIZE, PAGE_SIZES } from '../../../model/patent_family_list_page_sizes.js'
import { get_object_values } from '../../../utils/utils.js'
import { ASCENDING } from '../../../model/sort_directions.js'
import {
  APPLICATION_DATE,
  ASSIGNEE,
  CIPHER_FAMILY_ID,
  ID_TO_ALL_FIELDS,
  ID_TO_IGNORE_VALUES,
  ID_TO_TYPE_VALUES,
  IGNORE,
  LINENO,
  OWNER,
  PUB_NUMBER,
  SERIAL,
  SOURCE,
  STATUS,
  ID_TO_STATUS,
  TITLE,
  TYPE,
  AMBIGUOUS,
  FOUND,
  UNKNOWN_NUMBER,
  UNRECOGNISED_FORMAT,
  STATUSES,
  get_all_ignore_value_ids,
  get_all_type_ids,
  get_all_spif_status_ids,
  get_all_status_ids,
  DEFAULT_FIELDS
} from './matched_patents_table_model.js'
import {
  create_line,
  is_status_in_matches,
  is_status_not_found
} from './matched_patents_table_utils.js'
import { SelectablePublication } from './SelectablePublication.js'
import { MatchedPatentsTableControls } from './MatchedPatentsTableControls.js'
import { MatchAmbiguousIcon, MatchFoundIcon, MatchNotFoundIcon } from '../MatchSymbols.js'
import FamilyFetchAndDisplay from '../../family_view/FamilyFetchAndDisplay.js'
import { COLUMNS } from './table_columns.js'
import MatchedPatentsSummary from './MatchedPatentsSummary.js'
import MatchedPatentsExportControls from './MatchedPatentsExportControls.js'

import s from './MatchedPatentsTable.module.scss'

const FIRST_PAGE = 0

export const MatchedPatentsTable = ({ lines=[], update_lines, input_file, upload_mode, is_wizard, controlsRowClassName }) => {
  const is_spif = (upload_mode === UPLOAD_MODE_SPIF.id)

  const [selected_sort_field, set_selected_sort_field] = useState(STATUS)
  const [selected_sort_direction, set_selected_sort_direction] = useState(ASCENDING)
  const [selected_column_ids, set_selected_columns_ids] = useState(DEFAULT_FIELDS)
  const [selected_family, set_selected_family] = useState(null)
  const [page_size, set_page_size] = useState(DEFAULT_PAGE_SIZE)
  const [current_page, set_current_page] = useState(FIRST_PAGE)
  const [selected_ignore_ids, set_selected_ignore_ids] = useState(get_all_ignore_value_ids())
  const [selected_type_ids, set_selected_type_ids] = useState(get_all_type_ids())
  const [selected_statuses_ids, set_selected_statuses_ids] = useState(is_spif ? get_all_spif_status_ids() : get_all_status_ids())

  const [should_reset_current_page, set_should_reset_current_page] = useState(false)

  useEffect(() => {
    set_should_reset_current_page(true)
  },[selected_statuses_ids, selected_ignore_ids, selected_type_ids])

  useEffect(() => {
    if (!should_reset_current_page) return

    set_should_reset_current_page(false)
    set_current_page(FIRST_PAGE)

  }, [should_reset_current_page])

  function get_column_className(column) {
    const {id, className} = column

    if ([ASSIGNEE, OWNER].indexOf(id) > -1) {
      return s.one_line_text
    }

    if (id === TITLE) {
      return cn(s.no_fixed_max_width, s.one_line_text)
    }

    return className
  }

  const columns = COLUMNS.filter(column => (selected_column_ids.indexOf(column.id) > -1)).map(column => {
    const {id} = column

    return {
      ...column,
      className: get_column_className(column),
      ...(id === TITLE) ? {headerClassName: s.no_fixed_max_width} : {},
      header: render_custom_sort_header(ID_TO_ALL_FIELDS[id])
    }
  })
  const rows = get_rows()

  const is_none_selected = selected_column_ids.length === 0

  const statuses_available = STATUSES.filter(status => is_status_in_matches(lines, status))

  const num_pages = Math.ceil(get_total_visible() / page_size)

  return (
    <div className='w-100'>
      <div className='d-sm-flex justify-content-between mt-2 mb-4'>
        <MatchedPatentsSummary
          lines={lines}
          input_file={input_file}
          upload_mode={upload_mode}
          className='me-2'
        />

        {!is_wizard &&
          <MatchedPatentsExportControls
            lines={lines}
            input_file={input_file}
            disabled={(selected_column_ids || []).length === 0}
            className='mt-2 mt-sm-0'
          />
        }
      </div>

      {lines.length > 0 &&
        <MatchedPatentsTableControls
          selected_columns_ids={selected_column_ids}
          change_column_selection={set_selected_columns_ids}
          status_available={statuses_available}
          selected_status_ids={selected_statuses_ids}
          on_change_status={set_selected_statuses_ids}
          selected_ignore_ids={selected_ignore_ids}
          on_change_ignore={set_selected_ignore_ids}
          selected_type_ids={selected_type_ids}
          on_change_type={set_selected_type_ids}

          num_pages={num_pages}
          page_sizes={PAGE_SIZES}
          page_size={page_size}
          on_change_page_size={set_page_size}
          current_page={current_page}
          on_change_current_page={set_current_page}

          className={cn('pt-1 pt-sm-2 position-sticky', s.controls_wrapper, controlsRowClassName)}
        />
      }

      {rows.length > 0 && !is_none_selected &&
        <Table
          columns={columns}
          data={rows}
          className='w-100'
          noDataText='No data found'
          getTdProps={style_warning_cells}
        />
      }

      {selected_family &&
        <ScrollModal on_hide={() => set_selected_family(null)} bodyClassName='pt-0 mt-3'>
          <FamilyFetchAndDisplay
            selected_family={selected_family.family_id}
            selected_patent_number={selected_family.patent_number}

            top_className={s.family_details_top}
          />
        </ScrollModal>
      }
    </div>
  )

  function is_ambiguous_cell(column, status) {
    return column.id === ID_TO_ALL_FIELDS[SERIAL].id && status === AMBIGUOUS.id
  }

  function is_not_matched_cell(column, status) {
    return column.id === ID_TO_ALL_FIELDS[SOURCE].id && is_status_not_found(status)
  }

  function style_warning_cells(state, rowInfo, column, instance) {
    const {data: instance_data} = (instance || {}).props || {}

    const {index} = rowInfo || {}

    const {meta_data} = (instance_data || {})[index] || {}

    if (meta_data) {
      const { status } = meta_data
      const is_ambiguous = is_ambiguous_cell(column, status)
      const is_not_matched = is_not_matched_cell(column, status)
      if (is_ambiguous || is_not_matched) {
        return {className: is_ambiguous ? s.warn : s.error}
      }
    }

    return {}
  }

  function convert_to_row(line) {
    const lineno = Number(line.lineno)
    const row = {meta_data: {status: line.status, row_number: line.row_number}}
    selected_column_ids.forEach(column_id => {
      switch (column_id) {
        case LINENO:
          row[LINENO] = render_cell(lineno, line.ignore)
          break
        case SOURCE:
          row[SOURCE] = render_cell(get_editable_source(line), line.ignore)
          break
        case IGNORE:
          row[IGNORE] = render_cell(get_ignore_cell(line), line.ignore)
          break
        case STATUS:
          row[STATUS] = render_cell(get_status_icon(line.status), line.ignore)
          break;
        case SERIAL:
          row[SERIAL] = render_cell(get_selectable_serial_number(line), line.ignore)
          break;
        case TYPE:
          row[TYPE] = render_cell(line.type, line.ignore)
          break
        case PUB_NUMBER:
          row[PUB_NUMBER] = render_cell(line.publication, line.ignore)
          break
        case CIPHER_FAMILY_ID:
          row[CIPHER_FAMILY_ID] = render_cell(get_cipher_family_link(line.family, line.publication, (line.ignore || is_status_not_found(line.status))), line.ignore)
          break
        case APPLICATION_DATE:
          row[APPLICATION_DATE] = render_cell(line.application_date, line.ignore)
          break
        case ASSIGNEE:
          row[ASSIGNEE] = render_cell(line.assignee, line.ignore, line.assignee)
          break
        case OWNER:
          row[OWNER] = render_cell(line.owner, line.ignore, line.owner)
          break
        case TITLE:
          row[TITLE] = render_cell(line.title, line.ignore, line.title)
          break
        default:
          break
      }
    })
    return row
  }

  function apply_filters(lines, selected_statuses_ids, selected_ignore_ids, selected_type_ids) {
    const new_status_selection = selected_statuses_ids.map(id => (ID_TO_STATUS[id] || {}).id).filter(item => item != null)
    const new_ignore_selection = selected_ignore_ids.map(id => ID_TO_IGNORE_VALUES[id].value)
    const new_types_selection = selected_type_ids.map(id => ID_TO_TYPE_VALUES[id].value)

    return lines.filter(line => {
      return (new_status_selection.includes(line.status) && new_ignore_selection.includes(line.ignore) && (new_types_selection.includes(line.type)))
    })
  }

  function get_rows() {
    const filtered_chunk = apply_filters(lines, selected_statuses_ids, selected_ignore_ids, selected_type_ids)
    const start_from = current_page * page_size
    const filtered_chunk_to_display = filtered_chunk.slice(start_from, start_from + page_size)
    return filtered_chunk_to_display.map((match) => convert_to_row(match))
  }

  function get_total_visible() {
    const filtered_lines = apply_filters(lines, selected_statuses_ids, selected_ignore_ids, selected_type_ids)
    return (filtered_lines || []).length
  }

  function render_cell(value, ignore, tooltip, no_value = '-') {
    return (<span className={cn([{[s.disabled]: ignore, [s.tooltip]: tooltip}])}>{value || no_value}</span>)
  }

  function render_custom_sort_header(field) {
    return (
      <SortingColumnHeaderCell
        field={field}
        selected_sort_field_id={selected_sort_field}
        selected_sort_direction_id={selected_sort_direction}
        on_change_sort_field_id_and_sort_direction_id={on_change_sort}
      />
    )
  }

  function on_change_sort(field_to_sort_by, direction_to_sort_by) {
    sort_lines_by_field_and_direction(field_to_sort_by, direction_to_sort_by)
    set_selected_sort_field(field_to_sort_by)
    set_selected_sort_direction(direction_to_sort_by)
    set_should_reset_current_page(true)
  }

  function sort_lines_by_field_and_direction(field_to_sort_by, direction_to_sort_by) {
    const updated_lines = [...lines].sort((direction_to_sort_by === 'asc') ? asc_sort_func : desc_sort_func)
    update_lines(updated_lines)

    function no_value(value) {
      return value === undefined || value === null || value === '-'
    }

    function asc_sort_func(elem1, elem2) {
      let value1 = elem1[field_to_sort_by]
      if (no_value(value1))
        return 2

      let value2 = elem2[field_to_sort_by]

      if (no_value(value2))
        return -2

      if (_.isString(value1))
        value1 = value1.toLowerCase()

      if (_.isString(value2))
        value2 = value2.toLowerCase()

      return (value1 > value2) ? 1 : ((value1 < value2) ? -1 : 0)
    }

    function desc_sort_func(elem1, elem2) {
      let value1 = elem1[field_to_sort_by]
      if (no_value(value1))
        return -2

      let value2 = elem2[field_to_sort_by]
      if (no_value(value2))
        return 2

      if (_.isString(value1))
        value1 = value1.toLowerCase()

      if (_.isString(value2))
        value2 = value2.toLowerCase()

      return (value1 < value2) ? 1 : ((value1 > value2) ? -1 : 0)
    }
  }

  function amend_ignore_cell(previous_line) {
    const new_line = previous_line
    new_line.ignore = !previous_line.ignore
    new_line.lineno = previous_line.lineno
    return new_line
  }

  function amend_row_cell(row_number, amend_cell_func, func_args = []) {
    return lines.map(line => (line.row_number === row_number) ? amend_cell_func(line, func_args) : line)
  }

  function change_new_validated_data(previous_line, new_line_as_array) {
    const new_line = create_line(new_line_as_array[0], 0, null, previous_line.row_number)
    new_line.lineno = previous_line.lineno //have to override the line number
    return new_line
  }

  function validate(patent_list, row_number) {
    return match_patent_family(patent_list)
      .then(response => {
        const validated_line = get_object_values(response.data)
        update_lines(amend_row_cell(row_number, change_new_validated_data, [validated_line]))
      })
      .catch(error => {
        throw error
      })
  }

  function on_click_ignore_cell(row_number) {
    update_lines(amend_row_cell(row_number, amend_ignore_cell))
  }

  function get_ignore_cell(line) {
    return (
      <CheckboxStatic
        className='mx-auto'
        is_checked={line.ignore}
        is_disabled={is_status_not_found(line.status)}
        onClick={() => on_click_ignore_cell(line.row_number)}
      />
    )
  }

  function get_status_icon(status) {
    let icon
    switch (status) {
      case (UNRECOGNISED_FORMAT.id):
        icon = <MatchNotFoundIcon />
        break
      case (UNKNOWN_NUMBER.id):
        icon = <MatchNotFoundIcon />
        break
      case (AMBIGUOUS.id):
        icon = <MatchAmbiguousIcon />
        break
      case FOUND.id:
        icon = <MatchFoundIcon />
        break
      default:
        icon = '-'
    }

    return (<span>{icon}</span>)
  }

  function get_cipher_family_link(family_id, patent_number, disabled) {
    if (!family_id) return '-'

    return (
      <CipherFamilyLink
        family_id={family_id}
        selected_patent_number={patent_number}
        on_family_id_click={() => set_selected_family({family_id, patent_number})}
        display_text_as_link={true}
        display_link_icon={true}
        disabled={disabled}
        show_similar_families_search={true}
      />
    )
  }

  function get_editable_source(line) {
    const {source, row_number} = line || {}

    return <EditableSource
      initial_text={source}
      do_validate={validate}
      row_number={row_number}
    />
  }

  function change_pub_alternative(previous_line, selected_as_array) {
    const new_line = create_line(previous_line.matches, selected_as_array[0], false, previous_line.row_number)
    new_line.lineno = previous_line.lineno
    return new_line
  }

  function change_patent_number_selection(row_number, selected) {
    update_lines(amend_row_cell(row_number, change_pub_alternative, [selected]))
  }

  function get_selectable_serial_number(line) {
    const {matches, status, match_selected: selected, row_number, ignore} = line

    if (is_status_not_found(status)) return null

    if (matches.length === 1) return (matches[0].serial)

    return (
      <SelectablePublication
        options={matches}
        selected={selected}
        row_number={row_number}
        ignore={ignore}
        change_selection={change_patent_number_selection}
      />
    )
  }
}
