import React, { useState, useEffect } from 'react'
import _ from 'underscore'

import { get_cpc_info } from '../../utils/cpc_utils.js'

import ErrorBody from '../ErrorBody.js'

import Modal from '../widgets/Modal.js'
import Spinner from '../widgets/Spinner.js'
import { Highlighter } from '../widgets/Highlighter.js'
import { TABLE_COLUMN_HEADER } from '../../model/table.js'
import { Table } from '../widgets/Table.js'

const AllCpcsModal = ({
  cpc_codes,
  primary,
  highlighter_props,
  on_close
}) => {

  const [is_fetching, set_is_fetching] = useState(true)
  const [error_fetching, set_error_fetching] = useState(null)
  const [cpc_id_to_description, set_cpc_id_to_description] = useState(null)

  const cpc_codes_sorted = _.sortBy(cpc_codes)

  useEffect(() => {
    let cancelled = false

    set_is_fetching(true)
    set_error_fetching(null)
    set_cpc_id_to_description(null)

    get_cpc_info(cpc_codes)
      .then(cpc_id_to_description => {
        if (!cancelled) {
          set_is_fetching(false)
          set_cpc_id_to_description(cpc_id_to_description)
        }
      })
      .catch(err => {
        if (!cancelled) {
          set_is_fetching(false)
          set_error_fetching(err)
      }})

    // Cleanup
    return () => cancelled = true
  }, [cpc_codes])

  const columns = [
    {
      ...TABLE_COLUMN_HEADER,
      field: 'cpc',
      label: 'CPC',
      sortable: false,
      width: 140,
      cell_render: (row) => {
        const {original} = row || {}
        const {is_primary} = original || {}

        return (
          <span>
            <Highlighter
              {...highlighter_props}
              text_to_highlight={row.value}
            />
            {is_primary && <br />}
            {is_primary && <span>(primary)</span>}
          </span>
        )}
    },
    {
      ...TABLE_COLUMN_HEADER,
      field: 'desc',
      label: 'Description',
      sortable: false,
      cell_render: (row) => (
        <span>
          <Highlighter
            {...highlighter_props}
            text_to_highlight={row.value}
          />
        </span>
      )
    }
  ]

  const data = cpc_codes_sorted.map(cpc => {
    const is_primary = cpc === primary
    const desc = (cpc_id_to_description || {})[cpc]
    return {cpc, desc, is_primary}
  })

  return (
    <Modal
      size='sm'
      title={`All CPC codes`}
      on_hide={on_close}
    >
      {is_fetching &&
        <Spinner />
      }

      {error_fetching &&
        <ErrorBody
          error={error_fetching}
          context={'fetching cpc codes'}
        />
      }

      <Table
        columns={columns}
        data={data}
      />

    </Modal>
  )
}

export default AllCpcsModal
