import React from 'react'

import { NEGATIVES_PROCESSING_OPTIONS } from '../../model/negatives_processing.js'
import { RadiobuttonWithLabel } from '../widgets/RadiobuttonWithLabel.js'
import { withUser } from '../UserContext.js'
import { ReportBuilderNegativesProcessingDisabledTooltip } from './ReportBuilderBasketWarning.js'

import Label from '../widgets/Label.js'

const NegativesProcessingOptions = ({user, selected, is_nd_report, is_report_big, on_select, className}) => {
  return (
    <div className={className}>
      <div className='d-flex'>
        <Label>
          What to do with unclassified families:
        </Label>

        {is_report_big &&
          <ReportBuilderNegativesProcessingDisabledTooltip
            is_in_modal={true}
            buttonClassName='ms-1'
          />
        }
      </div>

      <div className='mb-1'>
        {NEGATIVES_PROCESSING_OPTIONS.map(item => {
          const {id, label, check_if_available} = item

          const is_available = check_if_available ? check_if_available({user, is_nd_report}) : true

          if (!is_available) return null

          return (
            <RadiobuttonWithLabel
              key={id}
              is_checked={selected === id}
              on_click={() => on_select(id)}
              label={label}
              is_disabled={is_report_big}
              className='mb-1'
            />
          )
        })}
      </div>

    </div>
  )
}

export default withUser(NegativesProcessingOptions)