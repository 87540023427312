import React from 'react'

import AlertModal from '../AlertModal.js'

const BadInputAlertModal = ({ error, on_hide }) => {
  const {response} = error || {}
  const {data} = response || {}
  const {detail=''} = data || {}

  return (
    <AlertModal
      title='Input not recognised'
      on_hide={on_hide}
    >
      <div className='mt-2'>There is a problem with your input{detail ? ':' : '.'}</div>

      {detail &&
        <div className='ms-4 mt-2'><em>{detail}</em></div>
      }
      <div className='my-2'>Please edit it and try again. </div>
    </AlertModal>
  )
}

export default BadInputAlertModal