import React from 'react'
import ReactTable from 'react-table'
import cn from 'classnames'

import s from './ReactTableWrapper.module.scss'

// NOTE: adds global css overrides to react-table.override.scss

/**
 * Wraps the ReactTable component,
 * adding some css to make sure that:
 * a) table container has a horizontal scrollbar (and doesn't overflow)
 * b) workaround for the bug where 22px of extra width is added
 */
const ReactTableWrapper = ({ className, headerClassName, ...rest }) => {

  return (
    <ReactTable

      // className
      className={cn(s.table_container, className)}

      // Header
      TheadComponent={({
        children,
        className,
        ...rest
      }) => (
        <div
          className={cn('rt-thead', headerClassName, className)}
          {...rest}
        >
          {children}
        </div>
      )}

      // All other props (can override TheadComponent if required)
      {...rest}
    />
  )
}

export default ReactTableWrapper