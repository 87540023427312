import { useEffect } from 'react'

import { ok_to_proceed_with_shortcuts } from '../../../utils/keyboard_shortcuts/keyboard_utils.js'

import { S_KEY } from '../../../constants/keys.js'
import { toggle_scope_modal } from './scope_modal_utils.js'

export const SHORTCUTS = [
  { keys: ['s'],             description: 'show / hide scope notes'},
  { keys: ['left', 'right'], description: 'prev / next patent'},
  { keys: ['1', '2', '3'],   description: 'label patent as positive / negative / ignore'},
  { keys: ['4', '5'],        description: 'label patent as test-positive / test-negative'},
  { keys: ['0'],             description: 'remove patent from training set'},
  { keys: ['enter'],         description: 'show detail (for current patent)'},
  { keys: ['delete'],        description: 'back to list (from detail)'},
  { keys: ['i'],             description: 'show / hide images modal (within detail)'},
  { keys: ['esc'],           description: 'use keyboard control for cards (in Boolean Search)'}
]

/**
 * Custom react hook, which registers keyboard shortcuts.
 */
export function useClassifierKeyboardShortcuts(props) {
  const { disable } = props

  useEffect(() => {
    if (disable) {
      return undefined
    }

    // Register handler (post-render)
    const on_keyup_with_props = on_keyup.bind(null, props)
    document.addEventListener('keyup', on_keyup_with_props)

    return () => {
      // Unregister handler (on pre-render/unmount)
      document.removeEventListener('keyup', on_keyup_with_props)
    }
  })
}

function on_keyup(props, event) {
  const {
    history
  } = props

  const { keyCode } = event

  if (keyCode === S_KEY) {
    // Special logic to allow keyboard shortcuts while the Scope modal is showing...
    if (!ok_to_proceed_with_shortcuts(event, true /* allow modal */)) {
      return
    }
    toggle_scope_modal(history)
    return
  }
}