import React from 'react'
import cn from 'classnames'
import _ from 'underscore'

import PatentCard, { PageControlCard } from './PatentCard.js'

import s from './PatentCardList.module.scss'

const PatentCardList = (
  {
    patents,
    subidx,

    show_classifier_controls,

    show_suggestion_strategy,
    no_highlighting,
    search_phrases,
    search_colours,
    highlight_prefix_only,
    source,
    set_label,
    className,
    on_show_details,

    eval_training_set_id,
    eval_classifier_data,

    num_pages,
    current_page,
    on_change_current_page,
    on_change_subidx,
    hide_current_subidx,

    similar_search_input_family_ids,
    similar_search_input,
    on_include_family_id_in_similar_search_input,

    knn_family_ids,
    knn_blocklist,
    knn_bookmarked,
    on_add_to_knn_family_ids,
    on_add_to_knn_blocklist,
    on_add_to_knn_bookmarked,

    is_fetching
}) => {
  const wrapper_ref = React.useRef(null)

  const show_next_page_control = (num_pages > 0) && (current_page < (num_pages - 1))
  const show_prev_page_control = (num_pages > 0) && (current_page > 0)

  if (is_fetching || !patents || (patents.length === 0)) {
    return (
      // return an empty div
      <div/>
    )
  }

  const empty_cards_count = patents.length < 4 ? (4 - patents.length) : 0

  return (
    <div
      className={cn(s.grid_container, className)}

      ref={wrapper_ref}
    >
      {show_prev_page_control &&
        <PageControlCard
          show_prev={true}
          num_pages={num_pages}
          current_page={current_page}
          on_change_current_page={on_change_current_page}
        />
      }

      {patents.map((patent, i) => {
        const is_selected = (i === subidx)

        return (
          <PatentCard
            key={i}
            wrapper={(wrapper_ref || {}).current}
            idx={i}
            subidx={i}
            patent={patent}
            show_suggestion_strategy={show_suggestion_strategy}
            show_classifier_controls={show_classifier_controls}
            is_selected={is_selected && !hide_current_subidx}
            no_highlighting={no_highlighting}
            search_phrases={search_phrases}
            search_colours={search_colours}
            highlight_prefix_only={highlight_prefix_only}
            source={source}
            set_label={set_label}
            on_show_details={on_show_details}
            on_set_selected={() => {
              if (!is_selected) {
                on_change_subidx(i)
              }
            }}
            eval_training_set_id={eval_training_set_id}
            eval_classifier_data={eval_classifier_data}

            similar_search_input_family_ids={similar_search_input_family_ids}
            similar_search_input={similar_search_input}
            on_include_family_id_in_similar_search_input={on_include_family_id_in_similar_search_input}

            knn_family_ids={knn_family_ids}
            knn_blocklist={knn_blocklist}
            knn_bookmarked={knn_bookmarked}
            on_add_to_knn_family_ids={on_add_to_knn_family_ids}
            on_add_to_knn_blocklist={on_add_to_knn_blocklist}
            on_add_to_knn_bookmarked={on_add_to_knn_bookmarked}
          />
        )
      })}

      {empty_cards_count > 0 &&
        _.range(empty_cards_count).map((_, i) => {
          return (
            <div key={i}></div>
          )})
      }

      {show_next_page_control &&
        <PageControlCard
          show_prev={false}
          num_pages={num_pages}
          current_page={current_page}
          on_change_current_page={on_change_current_page}
        />
      }
    </div>
  )
}

export default PatentCardList