import React from 'react'
import cn from 'classnames'

import { IGNORE, NEGATIVE, POSITIVE, TEST_NEGATIVE, TEST_POSITIVE } from '../classifiers_editor/constants/labels.js'

import s from './PatentFamilyClassifierMarker.module.scss'

const PatentFamilyClassifierMarker = ({label}) => {
  const is_positive = label === POSITIVE
  const is_negative = label === NEGATIVE
  const is_test_positive = label === TEST_POSITIVE
  const is_test_negative = label === TEST_NEGATIVE
  const is_ignore = label === IGNORE

  return (
    <div className={cn(
      'd-block',
      s.marker,
      {
        [s.positive]: is_positive,
        [s.negative]: is_negative,
        [s.test_positive]: is_test_positive,
        [s.test_negative]: is_test_negative,
        [s.ignore]: is_ignore,
      })}
    />
  )
}

export default PatentFamilyClassifierMarker