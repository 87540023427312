import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import { send_error_to_sentry } from '../../utils/sentry_utils.js'
import { add_source_err_to_target_err } from '../../utils/axios_utils.js'
import { get_domain_prefix } from '../../utils/utils.js'
import { get_ifi_image_url, convert_ifi_image_pkey_to_ucid } from '../../utils/patent_utils.js'
import { useOnScreen } from '../../hooks/general_hooks.js'

import s from './FamilyImage.module.scss'

const FamilyImage = ({image, is_thumbnail, selected, className , on_click_handler, on_image_load_or_error}) => {
  const [is_loading, set_is_loading] = useState(true)
  const [is_error, set_is_error] = useState(false)

  const image_ref = useRef()
  const is_visible = useOnScreen(image_ref)

  useEffect(() => {
    set_is_loading(true)
    set_is_error(false)
  }, [image])

  useEffect(() => {
    if (selected && !is_visible && image_ref) {
      image_ref.current.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  function on_click(image) {
    if (!on_click_handler) return

    on_click_handler(image)
  }

  function on_load({target:img}) {
    if (on_image_load_or_error) {
      on_image_load_or_error(img)
    }

    set_is_loading(false)
  }

  function on_error(url, err){
    const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch image from IFI claims (via html img), url: ' + url)

    if (on_image_load_or_error) {
      on_image_load_or_error(null, wrapped_err)
    }

    // Log error in sentry
    send_error_to_sentry(wrapped_err, {})

    set_is_loading(false)
    set_is_error(true)
  }

  const { pkey, filename } = image
  const ucid = convert_ifi_image_pkey_to_ucid(pkey)
  const url = get_domain_prefix() + get_ifi_image_url(filename, ucid)

  if (is_error) {
    return (
      <span
        className={cn(className, s.not_found_block)}
      >
        image failed to load
      </span>
    )
  }

  return (
    <img
      onLoad={on_load}
      onError={on_error.bind(null, url)}
      className={cn(

       {[s.image]: !is_thumbnail},
       {[s.thumbnail]: is_thumbnail},
       {[s.thumbnail__selected]: (is_thumbnail && selected && !is_loading)},
       className
      )}
      src={url}
      alt={filename}
      onClick={() => on_click(image)}
      style={{visible: `${(!is_thumbnail && is_loading) ? 'false' : 'true'}`}}
      ref={image_ref}

   />
  )
}

export default FamilyImage