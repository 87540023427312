import React from 'react'
import cn from 'classnames'

import { TABLE_COLUMN_HEADER } from '../../model/table.js'
import { LEGAL_EVENT_CATEGORY_NAME_MAP } from '../../model/family_view.js'
import { format_string_first_character_capitalised } from '../../utils/utils.js'
import { PatentLink } from '../widgets/PatentLink.js'
import { InfoPopover } from '../widgets/Tooltip.js'
import { Table } from '../widgets/Table.js'
import Spinner from '../widgets/Spinner.js'
import { Heading } from './FamilyDetailsLabel.js'
import { NavigationAnchor } from './FamilyDetailsNavigation.js'

const DATE_DECLARED_HINT = 'The date on which the information is made available, in gazette or bulletin'
const EFFECTIVE_DATE_HINT = 'The date that the legal event has come into force'

/**
 * @param {Object} event - the legal event data including relevantDate, dateDeclared and optionally spcExtensionDate
 */


function get_legal_event_label_value_element(label, value) {
  return (
    <div>
      <span className='text-endbold'>{label}</span> {value}
    </div>
  )
}

const LegalEventsView = ({family, legal_events, is_fetching, className, patent_link_mode_id, legal_events_ref, error_fetching, is_in_modal }) => {
  const {patFamId} = family || {}

  function render_event_details(event) {

    const { spcExtensionDate, designatedCountries, text, newOwners, description, category, code } = event
    const split_countries = (designatedCountries) ? designatedCountries.split(',').join(', ') : []

    const split_text = text ? text.map((txt, idx) => <div key={idx.toString()}>{txt}</div>) : []
    const new_owners = newOwners ? newOwners.map((owner, idx) => <span key={idx.toString()}>{owner} </span>) : []

    return (
      <div>
        {get_legal_event_label_value_element('Event category:', LEGAL_EVENT_CATEGORY_NAME_MAP[category])}
        {get_legal_event_label_value_element('Legal code:', code)}
        {description && (description.toLowerCase() !== 'unavailable') && get_legal_event_label_value_element('Event description:', format_string_first_character_capitalised(description))}
        {split_countries.length > 0 && get_legal_event_label_value_element('Relevant countries:', split_countries)}
        {new_owners.length > 0 && get_legal_event_label_value_element('New owners:', new_owners)}
        {spcExtensionDate && get_legal_event_label_value_element('SPC Extension date:', spcExtensionDate)}
        {split_text.length > 0 && <div className='pt-4'>{split_text}</div>}
      </div>
    )
  }

  function render_patent_numbers(publications) {
    return (publications || []).map((pub, idx) =>
      <div key={idx.toString()}>
        <PatentLink
          patent_number={pub}
          patent_link_mode_id={patent_link_mode_id}
        />
      </div>)
  }

  const have_events = legal_events != null && legal_events.length > 0

  const tooltip_props = {
    is_in_modal,
    placement: 'right',
    wrapper: legal_events_ref.current
  }

  return (
    <div
      className={cn('position-relative', className)}
      key={`legal_events_${patFamId}`}
    >
      <NavigationAnchor ref={legal_events_ref}/>

      <Heading>Legal Events <span>{have_events ? `(${legal_events.length})` : ''}</span></Heading>

      {is_fetching &&
        <Spinner/>
      }

      {!is_fetching && !error_fetching && have_events &&
        <Table
          className='border-0'
          data={legal_events}
          columns={[
            {
              ...TABLE_COLUMN_HEADER,
              label: 'Publication',
              field: 'publicationNumbers',
              sortable: false,
              cell_render: (row) => render_patent_numbers(row.value)
            },
            {
              ...TABLE_COLUMN_HEADER,
              label: (<span>Declared date <InfoPopover {...tooltip_props}>{DATE_DECLARED_HINT}</InfoPopover></span>),
              field: 'dateDeclared',
              sortable: false
            },
            {
              ...TABLE_COLUMN_HEADER,
              label: (<span>Effective date <InfoPopover {...tooltip_props}>{EFFECTIVE_DATE_HINT}</InfoPopover></span>),
              field: 'relevantDate',
              sortable: false
            },
            {
              ...TABLE_COLUMN_HEADER,
              label: 'Event details',
              field: '',
              sortable: false,
              cell_render: (row) => render_event_details(row.value)
            },
          ]}
        />
      }

      {!is_fetching && !error_fetching && !have_events &&
        <div><span>No legal events to display</span></div>
      }

      {error_fetching &&
        <div><span>There was an error fetching legal events</span></div>
      }

    </div>
  )
}

export default LegalEventsView
