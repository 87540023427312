import React, { useEffect } from 'react'
import cn from 'classnames'
import { withRouter } from 'react-router-dom'

import DatasetGroup from './DatasetGroup.js'
import { DISPLAY_NAVIGATION } from '../../utils/spec_group_utils.js'
import { get_as_map } from '../../utils/utils.js'
import MainReportItem from './MainReportItem.js'
import { spec_requires_assignee_links } from '../../utils/spec_utils.js'
import TextLink from '../widgets/TextLink.js'
import {
  get_mutual_items_count,
  is_family_details_view_page,
  is_subset_path,
  scroll_window_to_top
} from '../../utils/viewer_utils.js'
import { get_selected_items_for_display } from '../../utils/main_items_selection_utils.js'
import { SELECTED_GROUP_ID } from '../../model/spec_groups/spec_group_ids.js'
import { SELECTED_GROUP } from '../../model/spec_groups/selected.js'
import SelectedView from './SelectedView.js'
import { SelectedChartTagsDisplay } from './ChartTag.js'

import s from './DatasetView.module.scss'

const DatasetView = (
  {
    location,

    zoomed_group_id,
    zoomed_dataset_id,
    groups,
    is_group_collapsed,
    toggle_group,
    base_path,
    on_change_dataset_view,
    on_change_dataset_region_grouping,
    on_change_data_orientation,
    on_change_data_include_all_zeros,
    on_change_dataset_dispute_type,
    on_change_next_agglom_item_visibility,
    on_change_dataset_timerange,
    on_change_dataset_histogram_range,
    on_change_dataset_rollup_thresholds,
    on_change_dataset_portfolios_selection,
    on_change_dataset_techs_selection,
    on_change_dataset_geo_selection,
    on_change_report_portfolios_selection,
    on_change_report_techs_selection,
    on_change_report_geo_selection,
    on_change_dataset_plaintiff_type_ids,
    on_change_dataset_defendant_type_ids,
    on_change_dataset_outcome_ids,
    on_change_dataset_start_date_range,
    on_change_dataset_spotlighted_portfolios,
    on_change_dataset_spotlighted_techs,
    on_back_to_report,
    continent_id_to_geo_ids,
    timerange_field_id_to_default_extent,
    selected_timerange,
    show_selected_only,
    on_selected_only_toggle,
    selected_dataset_tags,
    on_filter_datasets_by_tags,

    training_set,
    id_to_tsfam,
    id_to_tsfam_pending,
    id_to_tsfam_error,
    phrases_to_highlight,
    no_highlighting,
    eval_training_set_id,
    set_label,
    eval_classifier_data,

    user_chart_sets,
    on_save_user_chart_set,
    on_remove_user_chart_set,
    on_select_user_chart_set,
    on_clear_selected_datasets,

    user_settings,
    selected_items,
    has_missing_assignee_links,
    report_input,
    report_has_scores,
    spec_id_to_fetch_obj,
    data_creation_date,
    ref_data,
    deref_data,
    deref_data_for_thumbnails,
    internal_report_id,
    external_report_id,
    report_series_sort,
    show_charts_in_thumbnails,
    minimal_selections,
    selections,
    report_title,
    report_region_grouping,
    is_family_tagging_mode_on,
    family_tagging_search_phrase,

    selected_charts_display_order_preference,
    change_selected_charts_display_order_preference,

    show_all_datasets_refresh,
    enable_all_dataset_refresh,
    all_datasets_refresh_handler,

    on_datasets_select,
    on_datasets_deselect,
    on_datasets_reshuffle,
    on_dataset_zoom,
    on_group_zoom,

    on_toggle_family_tagging_mode,
    on_update_family_tagging_search_phrase,

    is_bulk_download_enabled
  }) => {

  const is_selected_group_view = zoomed_group_id === SELECTED_GROUP_ID

  const selected_group_children = get_selected_items_for_display(selected_items, selected_charts_display_order_preference)

  const selected_group = {...SELECTED_GROUP, children: selected_group_children}

  const selected_items_ids = selected_group_children.map(item => item.spec_id)

  const available_groups = is_selected_group_view ? [selected_group] : groups

  const spec_id_to_selected_items = get_as_map(selected_items || [], 'spec_id')

  const group_ids = available_groups.map(group => group.id)
  const zoomed_group_idx = group_ids.indexOf(zoomed_group_id)
  const group =  available_groups[zoomed_group_idx]
  const { children=[] } = group || {}

  const group_items_ids = children.map(item => item.spec_id)

  const zoomed_dataset_idx = get_zoomed_dataset_idx(group_items_ids, zoomed_dataset_id)

  useEffect(() => {
    scroll_window_to_top()
  }, [])

  function get_zoomed_dataset_idx(items_ids, spec_id) {
    return (items_ids || []).indexOf(spec_id)
  }

  function change_show_selected_only() {
    const should_show_selected_only = !show_selected_only

    if (should_show_selected_only && selected_items_ids.indexOf(zoomed_dataset_id) === -1) {
      const selected_group_children = children.filter(item => selected_items_ids.indexOf(item.spec_id) > -1)
      on_dataset_zoom(zoomed_group_id, selected_group_children[0].spec_id)
    }

    on_selected_only_toggle({should_show_selected_only, group_id: zoomed_group_id})
  }

  function on_next_item() {
    let item_id_to_zoom

    if (zoomed_dataset_idx === children.length -1) {
      const next_available_group = available_groups[zoomed_group_idx + 1]
      const { children } = next_available_group
      item_id_to_zoom = children[0].spec_id
      on_group_zoom(next_available_group, item_id_to_zoom)

    } else {
      item_id_to_zoom = group_items_ids[zoomed_dataset_idx + 1]
      on_dataset_zoom(zoomed_group_id, item_id_to_zoom)
    }
  }

  function on_prev_item() {
    let item_id_to_zoom

    if (zoomed_dataset_idx === 0) {
      const next_available_group = available_groups[zoomed_group_idx - 1]
      const { children } = next_available_group
      item_id_to_zoom = children[children.length - 1].spec_id
      on_group_zoom(next_available_group, item_id_to_zoom)
    } else {
      item_id_to_zoom = group_items_ids[zoomed_dataset_idx - 1]
      on_dataset_zoom(zoomed_group_id, item_id_to_zoom)
    }
  }

  function on_datasets_deselect_handler(spec_ids) {
    if (spec_ids.indexOf(zoomed_dataset_id) !== -1 && (is_selected_group_view || show_selected_only)) {
      if (can_zoom_to_next) { on_next_item() }
      if (can_zoom_to_prev) { on_prev_item() }
    }

    on_datasets_deselect(spec_ids)
  }

  const spec = zoomed_dataset_idx !== -1 ? {...children[zoomed_dataset_idx], ...(spec_id_to_selected_items[zoomed_dataset_id] || {}) } : null
  const not_available = has_missing_assignee_links && spec_requires_assignee_links(zoomed_dataset_id)

  if (!spec || !group || not_available) {
    // spec not found or not available for this kind of report, so show error (this should never happen, unless user inputs a wrong url)
    return (
      <span>
        <div className='mb-4'>Dataset not {not_available ? 'available for this report' : 'found'}</div>
        <TextLink onClick={on_back_to_report}>Return to report</TextLink>
      </span>
    )
  }

  const can_zoom_to_prev = !(zoomed_group_idx === 0 && zoomed_dataset_idx === 0)
  const can_zoom_to_next = !(zoomed_group_idx === available_groups.length -1 && zoomed_dataset_idx === children.length -1)

  const is_selected_only_mode_enabled = get_mutual_items_count(group_items_ids, selected_items_ids) > 0

  const is_chart_view = !(is_subset_path(location.pathname) || is_family_details_view_page(location.pathname))

  return (
    <div className='d-flex h-100'>
      <MainReportItem
        base_path={base_path}
        on_close={on_back_to_report}

        user_settings={user_settings}
        report_title={report_title}
        internal_report_id={internal_report_id}
        external_report_id={external_report_id}
        report_input={report_input}
        report_has_scores={report_has_scores}
        data_creation_date={data_creation_date}
        selections={selections}
        minimal_selections={minimal_selections}
        item={spec}
        fetch_obj={spec_id_to_fetch_obj[zoomed_dataset_id]}
        ref_data={ref_data}
        deref_data={deref_data}
        available_groups={groups}
        on_change_selected_view_id={(view_id) => on_change_dataset_view(zoomed_dataset_id, view_id)}
        on_change_selected_dispute_type={(selected_dispute_type) => on_change_dataset_dispute_type(zoomed_dataset_id, selected_dispute_type)}
        on_change_rollup_thresholds={(next_thresholds, deselected_items_to_reset_key_name) => on_change_dataset_rollup_thresholds(zoomed_dataset_id, next_thresholds, deselected_items_to_reset_key_name)}
        on_change_next_agglom_item_visibility={(next_agglom_visibility) => on_change_next_agglom_item_visibility(zoomed_dataset_id, next_agglom_visibility)}
        report_series_sort={report_series_sort}
        on_change_report_region_grouping={(selected_region_grouping) => on_change_dataset_region_grouping(zoomed_dataset_id, selected_region_grouping)}
        report_region_grouping={spec.selected_region_grouping || report_region_grouping}
        on_change_data_orientation={(data_orientation) => on_change_data_orientation(zoomed_dataset_id, data_orientation)}
        on_change_data_include_all_zeros={(should_include_all_zeros) => on_change_data_include_all_zeros(zoomed_dataset_id, should_include_all_zeros)}
        on_change_dataset_timerange={(timerange) => on_change_dataset_timerange(zoomed_dataset_id, timerange)}
        on_change_dataset_histogram_range={(histogram_range) => on_change_dataset_histogram_range(zoomed_dataset_id, histogram_range)}
        on_change_dataset_portfolios_selection={(deselected_portfolio_ids, next_agglom) => on_change_dataset_portfolios_selection(zoomed_dataset_id, deselected_portfolio_ids, next_agglom)}
        on_change_dataset_techs_selection={(deselected_tech_ids, next_agglom) => on_change_dataset_techs_selection(zoomed_dataset_id, deselected_tech_ids, next_agglom)}
        on_change_dataset_geo_selection={(deselected_geo_ids, next_agglom) => on_change_dataset_geo_selection(zoomed_dataset_id, deselected_geo_ids, next_agglom)}
        on_change_dataset_plaintiff_type_ids={(plaintiff_type_ids) => on_change_dataset_plaintiff_type_ids(zoomed_dataset_id, plaintiff_type_ids)}
        on_change_dataset_defendant_type_ids={(defendant_type_ids) => on_change_dataset_defendant_type_ids(zoomed_dataset_id, defendant_type_ids)}
        on_change_dataset_outcome_ids={(outcome_ids) => on_change_dataset_outcome_ids(zoomed_dataset_id, outcome_ids)}
        on_change_dataset_start_date_range={(start_date_range) => on_change_dataset_start_date_range(zoomed_dataset_id, start_date_range)}
        on_change_dataset_spotlighted_portfolios={(spotlighted_portfolios) => on_change_dataset_spotlighted_portfolios(zoomed_dataset_id, spotlighted_portfolios)}
        on_change_dataset_spotlighted_techs={(spotlighted_techs) => on_change_dataset_spotlighted_techs(zoomed_dataset_id, spotlighted_techs)}
        is_selected={selected_items_ids.indexOf(zoomed_dataset_id) > -1}
        on_dataset_select={(spec_id) => on_datasets_select([spec_id])}
        on_dataset_deselect={(spec_id) => on_datasets_deselect_handler([spec_id])}

        on_change_report_portfolios_selection={on_change_report_portfolios_selection}
        on_change_report_techs_selection={on_change_report_techs_selection}
        on_change_report_geo_selection={on_change_report_geo_selection}

        continent_id_to_geo_ids={continent_id_to_geo_ids}
        timerange_field_id_to_default_extent={timerange_field_id_to_default_extent}
        selected_timerange={selected_timerange}

        training_set={training_set}
        id_to_tsfam={id_to_tsfam}
        id_to_tsfam_pending={id_to_tsfam_pending}
        id_to_tsfam_error={id_to_tsfam_error}
        phrases_to_highlight={phrases_to_highlight}
        no_highlighting={no_highlighting}
        eval_training_set_id={eval_training_set_id}
        set_label={set_label}
        eval_classifier_data={eval_classifier_data}

        is_family_tagging_mode_on={is_family_tagging_mode_on}
        family_tagging_search_phrase={family_tagging_search_phrase}
        on_toggle_family_tagging_mode={on_toggle_family_tagging_mode}
        on_update_family_tagging_search_phrase={on_update_family_tagging_search_phrase}

        className={cn('h-100', s.item_wrapper, {[s.item_wrapper__with_ribbon]: is_chart_view})}
      />

      {is_selected_group_view && is_chart_view &&
        <SelectedView
          display_mode={DISPLAY_NAVIGATION}
          user_chart_sets={user_chart_sets}
          zoomed_dataset_id={zoomed_dataset_id}
          is_group_collapsed={is_group_collapsed}
          toggle_group={toggle_group}

          show_all_datasets_refresh={show_all_datasets_refresh}
          enable_all_dataset_refresh={enable_all_dataset_refresh}
          all_datasets_refresh_handler={all_datasets_refresh_handler}

          on_save_user_chart_set={on_save_user_chart_set}
          on_remove_user_chart_set={on_remove_user_chart_set}
          on_select_user_chart_set={on_select_user_chart_set}
          on_clear_selected_datasets={on_clear_selected_datasets}

          user_settings={user_settings}
          selected_items={selected_group_children}
          spec_id_to_fetch_obj={spec_id_to_fetch_obj}
          data_creation_date={data_creation_date}
          ref_data={ref_data}
          deref_data={deref_data}
          report_series_sort={report_series_sort}
          show_charts_in_thumbnails={show_charts_in_thumbnails}
          minimal_selections={minimal_selections}
          selections={selections}
          report_title={report_title}
          selected_charts_display_order_preference={selected_charts_display_order_preference}
          change_selected_charts_display_order_preference={change_selected_charts_display_order_preference}
          on_datasets_deselect={on_datasets_deselect_handler}
          on_datasets_reshuffle={on_datasets_reshuffle}
          on_dataset_zoom={on_dataset_zoom}
          is_bulk_download_enabled={is_bulk_download_enabled}

          on_next_item={can_zoom_to_next ? on_next_item : null}
          on_prev_item={can_zoom_to_prev ? on_prev_item : null}
        />
      }

      {!is_selected_group_view && is_chart_view &&
        <>
          {!is_group_collapsed &&
            <SelectedChartTagsDisplay
              on_deselect={on_filter_datasets_by_tags}
              selected_tags={selected_dataset_tags}
              selected_only_enabled={is_selected_only_mode_enabled}
              on_selected_only_click={change_show_selected_only}
              show_selected_only={show_selected_only}
            />
          }

          <DatasetGroup
            display_mode={DISPLAY_NAVIGATION}
            groups={available_groups}
            group={group}
            selected_items={selected_items}
            zoomed_dataset_id={zoomed_dataset_id}
            is_group_collapsed={is_group_collapsed}
            toggle_group={toggle_group}
            show_all_datasets_refresh={show_all_datasets_refresh}
            enable_all_dataset_refresh={enable_all_dataset_refresh}
            all_datasets_refresh_handler={all_datasets_refresh_handler}

            ref_data={ref_data}
            deref_data={deref_data}
            report_series_sort={report_series_sort}
            show_charts_in_thumbnails={show_charts_in_thumbnails}
            minimal_selections={minimal_selections}
            selections={selections}
            deref_data_for_thumbnails={deref_data_for_thumbnails}
            data_creation_date={data_creation_date}
            spec_id_to_fetch_obj={spec_id_to_fetch_obj}
            show_selected_only={show_selected_only}
            on_selected_only_toggle={change_show_selected_only}

            on_datasets_select={on_datasets_select}
            on_datasets_deselect={on_datasets_deselect_handler}
            on_group_zoom={on_group_zoom}
            on_dataset_zoom={on_dataset_zoom}

            on_next_item={can_zoom_to_next ? on_next_item : null}
            on_prev_item={can_zoom_to_prev ? on_prev_item : null}
          />
        </>
      }

    </div>
  )
}

export default withRouter(DatasetView)