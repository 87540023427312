import React from 'react'
import { withRouter } from 'react-router-dom'

import Modal from './widgets/Modal.js'
import FeedbackForm from './FeedbackForm.js'

const FEEDBACK = 'Feedback'

const FeedbackModal = ({is_open, title, on_hide, subject, category, additional_comment, disable_subject_change}) => {
  const modal_title = title || FEEDBACK
  return (
    <div>
      <Modal
        is_open={is_open}
        on_hide={on_hide}
        title={modal_title}
        close_label={'Cancel'}
        bodyClassName='p-0'
        no_footer={true}
        size='md'
      >
        { modal_title === FEEDBACK &&
          <p>Please give us your feedback.</p>
        }

        <FeedbackForm
          title={title}
          subject={subject}
          category={category}
          additional_comment={additional_comment}

          on_submit={on_hide}
          on_cancel={on_hide}
          disable_subject_change={disable_subject_change}
        />

      </Modal>
    </div>
  )
}

export default withRouter(FeedbackModal)