import React from 'react'
import cn from 'classnames'

import ButtonGroup from '../widgets/ButtonGroup.js'


const SelectionViewControl = ({ className, available_selection_views, selection_view_id, on_change_selection_view_id }) => {

  return (

    <div className={cn('d-inline-flex', 'align-items-center')}>

      <span className={'me-1'}>
        Show
      </span>

      <ButtonGroup
        size={'xs'}
        items={available_selection_views}
        selected_id={selection_view_id}
        on_change_selected_id={on_change_selection_view_id}
        className={className}
        btnClassName='px-1'
      />
    </div>
  )
}

export default SelectionViewControl