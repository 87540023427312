import React from 'react'

import { ScrollModal } from '../widgets/Modal.js'
import UserSearchesTable from './UserSearchesTable.js'

const UserSearchesModal = (
  {
    current_id,
    on_update_current_search,
    on_modal_hide,
  }) => {

  return (
    <ScrollModal on_hide={on_modal_hide} title='Pinned and recent searches' no_footer={true}>

      <UserSearchesTable
        current_id={current_id}
        on_update_current_search={on_update_current_search}

        on_select_search={on_modal_hide}
      />

    </ScrollModal>

  )
}

export default UserSearchesModal