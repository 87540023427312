import React, { useState } from 'react'
import { InputGroup } from 'reactstrap'
import { Redirect } from 'react-router-dom'

import ErrorModal from '../ErrorModal.js'
import { DashboardSmallTile } from './DashboardTile.js'
import { DashboardTileDesc } from './DashboardTileDesc.js'
import { run_speedy_org_report } from '../../utils/report_builder_utils'
import { is_creator } from '../../utils/user_permissions.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'
import { withUser } from '../UserContext.js'
import { useOrgSearch } from '../../hooks/organisation_hooks.js'
import { QuickSearchOrgsDisplay, ResultsMenu } from './DashboardDisplays.js'
import ClearableSearchInput from '../widgets/ClearableSearchInput.js'
import { normalise_search_phrase } from '../../utils/url_utils.js'
import { DEFAULT_REPORT_TYPE } from '../../constants/constants.js'
import { DashboardTileInner } from './DashboardTileInner.js'

const SpeedyReportForOrgPanel = ({user}) => {
  const [report_id, set_report_id] = useState(null)
  const [search_phrase, set_search_phrase] = useState(null)
  const [show_report_spinner, set_show_report_spinner] = useState(false)
  const [build_report_error, set_build_report_error] = useState(null)

  const [show_search_spinner, results, org_search_error, clear_org_search_error ] = useOrgSearch(search_phrase, false, true)

  function on_change_from_search_input(value) {
    if (search_phrase === value) {
      return
    }

    set_search_phrase(value)
  }

  function do_speedy_report(org) {
    track_report_builder_event('action="build_report" report_type="quick_org" context="dashboard"')

    set_show_report_spinner(true)

    const is_fetch_comparables = is_creator(user)
    run_speedy_org_report(org, DEFAULT_REPORT_TYPE, is_fetch_comparables)
      .then((external_report_id) => {
        set_show_report_spinner(false)
        set_report_id(external_report_id)
      })
      .catch((error) => {
        set_show_report_spinner(false)
        set_build_report_error(error)
      })
  }

  const panel_title = `${is_creator(user) ? 'Comparison' : 'Single'} organisation search`

  const has_results = ((results || []).length > 0)

  const no_results_found = Array.isArray(results) && (results.length === 0)

  const is_search_phrase = (search_phrase && (search_phrase.length > 3))

  if (report_id) {
    return (<Redirect to={`/report/${report_id}`} />)
  }

  const results_to_show = (results || []).slice(0,5)

  return (
    <DashboardSmallTile title={panel_title} className='d-flex flex-column'>
      <DashboardTileInner>
        <DashboardTileDesc className='flex-grow-1'>
          Create a new report in one click for your target organisation{is_creator(user) && <span>&nbsp;with Cipher suggestions for seven similar portfolios</span>}
        </DashboardTileDesc>
        <InputGroup>
          <ClearableSearchInput
            placeholder={''}
            value={search_phrase || ''}
            show_spinner={show_search_spinner || show_report_spinner}
            handle_change={on_change_from_search_input}
            containerClassName='flex-grow-1'
            show_clear={true}
          />
        </InputGroup>

        {build_report_error &&
          <ErrorModal
            on_hide={() => {set_build_report_error(null)}}
            error = {build_report_error}
            context = 'building a report'
          />
        }

        {org_search_error &&
          <ErrorModal
            on_hide={clear_org_search_error}
            error = {org_search_error}
            context = 'fetching organisation results'
          />
        }

        {is_search_phrase && (has_results || no_results_found) && !show_search_spinner && !build_report_error &&
          <ResultsMenu>
            {no_results_found &&
              <div className='text-center py-3 px-2'>
                No results found for {`${search_phrase}`}
              </div>
            }

            {has_results &&
              <QuickSearchOrgsDisplay
                title='Top matches'
                orgs={results_to_show}
                search_phrase={normalise_search_phrase(search_phrase)}
                on_click={do_speedy_report}
                className='p-2'
              />
            }
          </ResultsMenu>
        }
      </DashboardTileInner>
    </DashboardSmallTile>
  )

}

export default withUser(SpeedyReportForOrgPanel)