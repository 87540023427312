import React from 'react'
import cn from 'classnames'

import TextLink from '../widgets/TextLink.js'
import { CogIcon } from '../widgets/IconSet.js'
import { list_is_empty } from '../../utils/company_list_utils.js'
import { SAVED_ORGS_LISTS_ADMIN } from '../../constants/paths.js'
import Label from '../widgets/Label.js'

import s from './UserCreatedCompanyListsPanel.module.scss'

const CompanyList = (
  {
    list,
    is_empty_list,
    on_list_select_handler,
  }
) => {
  const {list_name, company_list_id, is_shared} = list

  return (
    <div className={cn('d-flex flex-nowrap mb-1', s.list_add_link)}>
      <TextLink
        onClick={() => on_list_select_handler(company_list_id, list_name, is_shared)}
        disable={is_empty_list}
      >
        {list_name}
      </TextLink>
      <div className={cn('d-flex my-auto', s.list_name_spacer)}>
        <TextLink className={s.list_action_icon} element='a' target='_blank' href={`${SAVED_ORGS_LISTS_ADMIN}?company_list_id=${company_list_id}`}><CogIcon/></TextLink>
      </div>
    </div>
  )
}

const UserCreatedCompanyListsPanel = ({label, company_lists, on_list_select_handler, className }) => {

  return (
    <div className={className}>
      <Label className='mb-2'>{label}</Label>
      <div className='d-flex flex-column'>
        {company_lists.map(list => {
          const {company_list_id} = list
          const is_empty_list = list_is_empty(list)
          return (
            <CompanyList
              key={company_list_id}
              list={list}
              is_empty_list={is_empty_list}
              on_list_select_handler={on_list_select_handler}
            />
        )})}
      </div>
    </div>
  )
}

export default UserCreatedCompanyListsPanel