import React from 'react'
import cn from 'classnames'

import {
  PlusIcon,
  AddNewUserIcon,
  AddressIcon,
  AddToClipboardIcon,
  AreaViewIcon,
  TriangleLeftIcon,
  BellIcon,
  BlockedIcon,
  BubbleViewIcon,
  CalendarIcon,
  CardsViewIcon,
  ChangesIcon,
  CheckIcon,
  CogIcon,
  ColourPaletteIcon,
  ColumnsClusterViewIcon,
  ColumnsStackViewIcon,
  ConfigureIcon,
  DeckColumnsStackIcon,
  DeckColumnsBoldIcon,
  DeckColumnsLightIcon,
  DeckColsPosNegSpotlightIcon,
  DeckBarsSpotlightIcon,
  DeckBarsPosNegSpotlightIcon,
  DeckLandscapeBarsIcon,
  DeckLineIcon,
  EditIcon,
  ExclamationMarkIcon,
  ExportIcon,
  ExternalLinkIcon,
  ExtractIcon,
  EyeBlockedIcon,
  EyeIcon,
  FastBackwardIcon,
  FastForwardIcon,
  FeedbackIcon,
  FilterIcon,
  TriangleRightIcon,
  TriangleDownIcon,
  GoogleSearchIcon,
  GridIcon,
  HeatmapViewIcon,
  HistoryIcon,
  InfoIcon,
  InvestigateIcon,
  KeyboardIcon,
  LanguageIcon,
  LineViewIcon,
  ListIcon,
  LitigationIcon,
  LoopIcon,
  MailIcon,
  MakeGroupIcon,
  MapViewIcon,
  MenuIcon,
  MoveAsGroupOutIcon,
  MoveAssigneeUpIcon,
  NewReportFromParamsIcon,
  NotesIcon,
  PieViewIcon,
  PortfolioIcon,
  PropagateUpIcon,
  RemoveIcon,
  RepeatInLeftIcon,
  RepeatInRightIcon,
  QueryReportDataIcon,
  TagIcon,
  TagsIconSolid,
  TagsIcon,
  RetryIcon,
  RotateLeftIcon,
  RotateRightIcon,
  SaveIcon,
  ScatterViewDotsIcon,
  ShareIcon,
  QuestionMarkIcon,
  ShowSimilarIcon,
  SimpleErrorIcon,
  TabularViewIcon,
  ChevronDownIcon,
  TransformResetIcon,
  TrashIcon,
  TreemapViewIcon,
  UndoIcon,
  UnsaveIcon,
  UserIcon,
  WikipediaIcon,
  ZoomInIcon,
  ZoomOutIcon,
  BarsIcon,
  AddToProjectIcon,
  ProjectIcon,
  ProjectUnlinkIcon,
  GroupIcon,
  CompareIcon,
  BarsStackViewIcon,
  BarsClusterViewIcon,
  UploadIcon,
  LightbulbCrossedIcon,
  LightbulbOnIcon,
  LightbulbOffIcon,
  MergeIcon,
  AppsIcon,
  RightArrowInCircleIcon,
  ArrowLeftIcon,
  MoreVertIcon,
  DragIndicatorIcon,
  SearchIcon,
  OpenWithIcon,
  ChevronRightIcon,
  PinIcon,
  UnpinIcon,
  SearchAgainIcon,
  StarActiveIcon,
  StarInactiveIcon,
  ChevronUpIcon,
  ChevronLeftIcon,
  CircleUncheckedIcon,
  CircleCheckedIcon,
  SimpleInfoIcon,
  SelectedNavIcon,
  ListViewNavIcon,
  ColumnChartIcon,
  MinusIcon,
  AddCircleIcon,
  PositiveLabelControlIcon,
  NegativeLabelControlIcon,
  PositiveTestLabelControlIcon,
  NegativeTestLabelControlIcon,
  RelevantLabelControlIcon,
  IrrelevantLabelControlIcon,
  BookmarkLabelControlIcon,
  TriangleIcon,
  TriangleOutlineIcon,
  KeyboardOutlineIcon,
  ChainOpenedIcon,
  ChainClosedIcon
} from './widgets/IconSet.js'

import s from './IconFinder.module.scss'

const IconInfo = ({Icon, component_name, library, used_for}) => {
  const is_svg = library === 'SVG'

  return (
    <div className={cn('d-flex mb-1 px-2 py-1', s.icon_info)}>
      <div className={cn(s.icon)}><Icon className={cn({[s.icon__svg]: is_svg})} /></div>
      <div className={s.component_name}>{component_name}</div>
      <div className={s.library}>{library}</div>
      <div>{used_for}</div>
    </div>
  )
}

const IconFinder = () => {
  return (
    <div className={cn('mx-auto d-flex flex-wrap my-2', s.wrapper)}>
      <IconInfo
        Icon={CheckIcon}
        component_name='CheckIcon'
        library={'PS IM'}
      />
      <IconInfo
        Icon={RemoveIcon}
        component_name='RemoveIcon'
        library={'PS IM'}
      />
      <IconInfo
        Icon={TriangleRightIcon}
        component_name='TriangleRightIcon'
        library={'PS IM'}
        used_for='report viewer (next dataset)'
      />
      <IconInfo
        Icon={TriangleLeftIcon}
        component_name='TriangleLeftIcon'
        library={'PS IM'}
        used_for='report viewer (prev dataset)'
      />
      <IconInfo
        Icon={TriangleDownIcon}
        component_name='TriangleDownIcon'
        library={'PS IM'}
        used_for='datepicker'
      />
      <IconInfo
        Icon={FastForwardIcon}
        component_name='FastForwardIcon'
        library={'PS IM'}
        used_for='paginated dropdown'
      />
      <IconInfo
        Icon={FastBackwardIcon}
        component_name='FastBackwardIcon'
        library={'PS IM'}
        used_for='paginated dropdown'
      />
      <IconInfo
        Icon={ChevronUpIcon}
        component_name='ChevronUpIcon'
        library={'PS IM'}
        used_for='table column headers'
      />
      <IconInfo
        Icon={ChevronDownIcon}
        component_name='ChevronDownIcon'
        library={'PS IM'}
        used_for='table column headers'
      />
      <IconInfo
        Icon={ChevronRightIcon}
        component_name='ChevronRightIcon'
        library={'PS IM'}
        used_for='breadcrumb, navigation'
      />
      <IconInfo
        Icon={ChevronLeftIcon}
        component_name='ChevronLeftIcon'
        library={'PS IM'}
        used_for='navigation'
      />
      <IconInfo
        Icon={TriangleIcon}
        component_name='TriangleIcon'
        library='PS IM'
        used_for='sorting table columns'
      />
      <IconInfo
        Icon={TriangleOutlineIcon}
        component_name='TriangleOutlineIcon'
        library='PS IM'
        used_for='sorting table columns'
      />
      <IconInfo
        Icon={ArrowLeftIcon}
        component_name='ArrowLeftIcon'
        library={'PS IM'}
        used_for={'tiles'}
      />
      <IconInfo
        Icon={CalendarIcon}
        component_name='CalendarIcon'
        library={'PS IM'}
        used_for='calendar pickers'
      />
      <IconInfo
        Icon={MailIcon}
        component_name='MailIcon'
        library={'PS IM'}
        used_for='monitor'
      />
      <IconInfo
        Icon={PortfolioIcon}
        component_name='PortfolioIcon'
        library={'IM'}
        used_for={'org search'}
      />
      <IconInfo
        Icon={AddressIcon}
        component_name='AddressIcon'
        library={'PS IM'}
        used_for='org search'
      />
      <IconInfo
        Icon={LitigationIcon}
        component_name='LitigationIcon'
        library={'PS IM'}
        used_for={'org search'}
      />
      <IconInfo
        Icon={AddToProjectIcon}
        component_name='AddToProjectIcon'
        library={'PS IM'}
        used_for={'projects'}
      />
      <IconInfo
        Icon={ProjectIcon}
        component_name='ProjectIcon'
        library={'PS IM'}
        used_for={'projects'}
      />
      <IconInfo
        Icon={ProjectUnlinkIcon}
        component_name='ProjectUnlinkIcon'
        library={'PS IM'}
        used_for={'projects'}
      />
      <IconInfo
        Icon={InfoIcon}
        component_name='InfoIcon'
        library={'PS IM'}
        used_for={'header, dashboard tiles'}
      />
      <IconInfo
        Icon={QuestionMarkIcon}
        component_name='QuestionMarkIcon'
        library={'PS IM'}
        used_for={'report builder basket, patent upload'}
      />
      <IconInfo
        Icon={ShowSimilarIcon}
        component_name='ShowSimilarIcon'
        library={'PS IM'}
        used_for={'report builder basket'}
      />
      <IconInfo
        Icon={NewReportFromParamsIcon}
        component_name='NewReportFromParamsIcon'
        library={'PS IM'}
        used_for='report topbar, history'
      />
      <IconInfo
        Icon={ShareIcon}
        component_name='ShareIcon'
        library={'PS UI'}
        used_for={'header'}
      />
      <IconInfo
        Icon={HistoryIcon}
        component_name='HistoryIcon'
        library={'PS IM'}
        used_for={'header'}
      />
      <IconInfo
        Icon={ChangesIcon}
        component_name='ChangesIcon'
        library={'IM'}
        used_for={'AG tool'}
      />
      <IconInfo
        Icon={FeedbackIcon}
        component_name='FeedbackIcon'
        library={'PS IM'}
        used_for={'dashboard tiles'}
      />
      <IconInfo
        Icon={UserIcon}
        component_name='UserIcon'
        library={'IM'}
        used_for={'header'}
      />
      <IconInfo
        Icon={GroupIcon}
        component_name='GroupIcon'
        library={'PS IM'}
        used_for={'projects'}
      />
      <IconInfo
        Icon={EditIcon}
        component_name='EditIcon'
        library={'PS IM'}
        used_for={'report (title)'}
      />
      <IconInfo
        Icon={TrashIcon}
        component_name='TrashIcon'
        library={'PS IM'}
      />
      <IconInfo
        Icon={UndoIcon}
        component_name='UndoIcon'
        library={'IM'}
        used_for='report viewer (go back)'
      />
      <IconInfo
        Icon={SaveIcon}
        component_name='SaveIcon'
        library={'PS IM'}
        used_for={'report header, history'}
      />
      <IconInfo
        Icon={UnsaveIcon}
        component_name='UnsaveIcon'
        library={'PS IM'}
        used_for={'report header, history'}
      />
      <IconInfo
        Icon={TagIcon}
        component_name='TagIcon'
        library={'PS IM'}
        used_for={'report header, history'}
      />
      <IconInfo
        Icon={TagsIconSolid}
        component_name='TagsIconSolid'
        library={'PS IM'}
        used_for={'family tagging'}
      />
      <IconInfo
        Icon={TagsIcon}
        component_name='TagsIcon'
        library={'PS IM'}
        used_for={'history, family tagging, AG UI'}
      />
      <IconInfo
        Icon={RetryIcon}
        component_name='RetryIcon'
        library={'PS IM'}
        used_for='eval reports, rerun failed'
      />
      <IconInfo
        Icon={AddNewUserIcon}
        component_name='AddNewUserIcon'
        library={'PS IM'}
        used_for='report sharing'
      />
      <IconInfo
        Icon={PlusIcon}
        component_name='PlusIcon'
        library={'PS IM'}
      />
      <IconInfo
        Icon={MinusIcon}
        component_name='MinusIcon'
        library={'PS IM'}
      />
      <IconInfo
        Icon={ExternalLinkIcon}
        component_name='ExternalLinkIcon'
        library={'PS IM'}
        used_for='disputes, image viewer'
      />
      <IconInfo
        Icon={ZoomInIcon}
        component_name='ZoomInIcon'
        library={'PS IM'}
        used_for={'image viewer'}
      />
      <IconInfo
        Icon={ZoomOutIcon}
        component_name='ZoomOutIcon'
        library={'PS IM'}
        used_for={'image viewer'}
      />
      <IconInfo
        Icon={RotateRightIcon}
        component_name='RotateRightIcon'
        library={'PS IM'}
        used_for={'image viewer'}
      />
      <IconInfo
        Icon={RotateLeftIcon}
        component_name='RotateLeftIcon'
        library={'PS IM'}
        used_for={'image viewer'}
      />
      <IconInfo
        Icon={TransformResetIcon}
        component_name='TransformResetIcon'
        library={'PS IM'}
        used_for={'image viewer'}
      />
      <IconInfo
        Icon={ColourPaletteIcon}
        component_name='ColourPaletteIcon'
        library={'PS IM'}
        used_for={'classifier ui'}
      />
      <IconInfo
        Icon={BlockedIcon}
        component_name='BlockedIcon'
        library={'PS IM'}
        used_for='classifiers ui'
      />
      <IconInfo
        Icon={CompareIcon}
        component_name='CompareIcon'
        library={'PS IM'}
        used_for='classifier evaluate page'
      />
      <IconInfo
        Icon={ExclamationMarkIcon}
        component_name='ExclamationMarkIcon'
        library={'PS IM'}
        used_for='patent upload, org lists'
      />
      <IconInfo
        Icon={SimpleErrorIcon}
        component_name='SimpleErrorIcon'
        library={'PS IM'}
      />
      <IconInfo
        Icon={SimpleInfoIcon}
        component_name='SimpleInfoIcon'
        library={'PS IM'}
      />
      <IconInfo
        Icon={KeyboardIcon}
        component_name='KeyboardIcon'
        library={'PS IM'}
        used_for={'classifier ui'}
      />
      <IconInfo
        Icon={KeyboardOutlineIcon}
        component_name='KeyboardOutlineIcon'
        library={'PS IM'}
        used_for={'classifier ui'}
      />
      <IconInfo
        Icon={StarActiveIcon}
        component_name='StarActiveIcon'
        library={'PS IM'}
        used_for={'classifier ui'}
      />
      <IconInfo
        Icon={StarInactiveIcon}
        component_name='StarInactiveIcon'
        library={'PS IM'}
        used_for={'classifier ui'}
      />
      <IconInfo
        Icon={MoveAsGroupOutIcon}
        component_name='MoveAsGroupOutIcon'
        library={'SVG'}
        used_for={'AG tool'}
      />
      <IconInfo
        Icon={MakeGroupIcon}
        component_name='MakeGroupIcon'
        library={'IM'}
        used_for={'AG tool'}
      />
      <IconInfo
        Icon={GoogleSearchIcon}
        component_name='GoogleSearchIcon'
        library={'PS IM'}
        used_for={'AG tool'}
      />
      <IconInfo
        Icon={WikipediaIcon}
        component_name='WikipediaIcon'
        library={'IM'}
        used_for={'AG tool'}
      />
      <IconInfo
        Icon={LanguageIcon}
        component_name='LanguageIcon'
        library={'IM'}
        used_for={'AG tool'}
      />
      <IconInfo
        Icon={ExtractIcon}
        component_name='ExtractIcon'
        library={'IM'}
        used_for={'AG tool'}
      />
      <IconInfo
        Icon={BellIcon}
        component_name='BellIcon'
        library={'PS UI'}
        used_for='alerts'
      />
      <IconInfo
        Icon={RepeatInLeftIcon}
        component_name='RepeatInLeftIcon'
        library={'PS IM'}
        used_for={'AG tool'}
      />
      <IconInfo
        Icon={RepeatInRightIcon}
        component_name='RepeatInRightIcon'
        library={'PS IM'}
        used_for={'AG tool'}
      />
      <IconInfo
        Icon={CardsViewIcon}
        component_name='CardsViewIcon'
        library={'PS IM'}
        used_for={'list view (families as cards)'}
      />
      <IconInfo
        Icon={ColumnsStackViewIcon}
        component_name='ColumnsStackViewIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={ColumnsClusterViewIcon}
        component_name='ColumnsClusterViewIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={BarsStackViewIcon}
        component_name='BarsStackViewIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={BarsClusterViewIcon}
        component_name='BarsClusterViewIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={PieViewIcon}
        component_name='PieViewIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={HeatmapViewIcon}
        component_name='HeatmapViewIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={LineViewIcon}
        component_name='LineViewIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={TabularViewIcon}
        component_name='TabularViewIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={TreemapViewIcon}
        component_name='TreemapViewIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={MapViewIcon}
        component_name='MapViewIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={BubbleViewIcon}
        component_name='BubbleViewIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={AreaViewIcon}
        component_name='AreaViewIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={ScatterViewDotsIcon}
        component_name='ScatterViewDotsIcon'
        library={'SVG'}
        used_for={'chart view'}
      />
      <IconInfo
        Icon={DeckColumnsStackIcon}
        component_name='DeckColumnsStackIcon'
        library={'SVG'}
        used_for={'benchmarking UI intro page'}
      />
      <IconInfo
        Icon={DeckColumnsBoldIcon}
        component_name='DeckColumnsBoldIcon'
        library={'SVG'}
        used_for={'benchmarking UI intro page'}
      />
      <IconInfo
        Icon={DeckColumnsLightIcon}
        component_name='DeckColumnsLightIcon'
        library={'SVG'}
        used_for={'benchmarking UI intro page'}
      />
      <IconInfo
        Icon={DeckColsPosNegSpotlightIcon}
        component_name='DeckColsPosNegSpotlightIcon'
        library={'SVG'}
        used_for={'benchmarking UI intro page'}
      />
      <IconInfo
        Icon={DeckBarsSpotlightIcon}
        component_name='DeckBarsSpotlightIcon'
        library={'SVG'}
        used_for={'benchmarking UI intro page'}
      />
      <IconInfo
        Icon={DeckBarsPosNegSpotlightIcon}
        component_name='DeckBarsPosNegSpotlightIcon'
        library={'SVG'}
        used_for={'benchmarking UI intro page'}
      />
      <IconInfo
        Icon={DeckLandscapeBarsIcon}
        component_name='DeckLandscapeBarsIcon'
        library={'SVG'}
        used_for={'benchmarking UI intro page'}
      />
      <IconInfo
        Icon={DeckLineIcon}
        component_name='DeckLineIcon'
        library={'SVG'}
        used_for={'benchmarking UI intro page'}
      />
      <IconInfo
        Icon={EyeIcon}
        component_name='EyeIcon'
        library={'PS IM'}
        used_for={'next xx series'}
      />
      <IconInfo
        Icon={EyeBlockedIcon}
        component_name='EyeBlockedIcon'
        library={'PS IM'}
        used_for={'next xx series'}
      />
      <IconInfo
        Icon={ConfigureIcon}
        component_name='ConfigureIcon'
        library={'PS IM'}
      />
      <IconInfo
        Icon={InvestigateIcon}
        component_name='InvestigateIcon'
        library={'PS IM'}
        used_for='dev debugging features'
      />
      <IconInfo
        Icon={QueryReportDataIcon}
        component_name='QueryReportDataIcon'
        library={'PS IM'}
        used_for={'report monitor'}
      />
      <IconInfo
        Icon={FilterIcon}
        component_name='FilterIcon'
        library='PS IM'
        used_for='benchmarking UI'
      />
      <IconInfo
        Icon={GridIcon}
        component_name='GridIcon'
        library={'PS IM'}
        used_for={'report (grid link)'}
      />
      <IconInfo
        Icon={ColumnChartIcon}
        component_name='ColumnChartIcon'
        library={'PS IM'}
        used_for={'report (grid link)'}
      />
      <IconInfo
        Icon={SelectedNavIcon}
        component_name='SelectedNavIcon'
        library='PS IM'
        used_for='report viewer navigation (TBD)'
      />
      <IconInfo
        Icon={ListViewNavIcon}
        component_name='ListViewNavIcon'
        library='PS IM'
        used_for='report viewer navigation (TBD)'
      />
      <IconInfo
        Icon={NotesIcon}
        component_name='NotesIcon'
        library={'PS IM'}
        used_for={'AG UI'}
      />
      <IconInfo
        Icon={MoveAssigneeUpIcon}
        component_name='MoveAssigneeUpIcon'
        library={'PS IM'}
        used_for={'AG tool'}
      />
      <IconInfo
        Icon={PropagateUpIcon}
        component_name='PropagateUpIcon'
        library={'PS IM'}
        used_for={'report (copy to global)'}
      />
      <IconInfo
        Icon={ListIcon}
        component_name='ListIcon'
        library={'PS IM'}
        used_for={'report (list view link)'}
      />
      <IconInfo
        Icon={ExportIcon}
        component_name='ExportIcon'
        library={'PS IM'}
        used_for={'report (exports link)'}
      />
      <IconInfo
        Icon={UploadIcon}
        component_name='UploadIcon'
        library={'PS IM'}
        used_for={'projects'}
      />
      <IconInfo
        Icon={LoopIcon}
        component_name='LoopIcon'
        library={'IM'}
        used_for={'report (refresh thumbnails)'}
      />
      <IconInfo
        Icon={CogIcon}
        component_name='CogIcon'
        library={'PS IM'}
        used_for={'report header'}
      />
      <IconInfo
        Icon={MenuIcon}
        component_name='MenuIcon'
        library={'IM'}
        used_for={'AG tool'}
      />
      <IconInfo
        Icon={AddToClipboardIcon}
        component_name='AddToClipboardIcon'
        library={'PS IM'}
        used_for={'shareable link modal'}
      />
      <IconInfo
        Icon={LightbulbCrossedIcon}
        component_name='LightbulbCrossedIcon'
        library={'PS IM'}
        used_for={'spotlight'}
      />
      <IconInfo
        Icon={LightbulbOnIcon}
        component_name='LightbulbOnIcon'
        library={'PS IM'}
        used_for={'spotlight'}
      />
      <IconInfo
        Icon={LightbulbOffIcon}
        component_name='LightbulbOffIcon'
        library={'PS IM'}
        used_for={'spotlight'}
      />
      <IconInfo
        Icon={BarsIcon}
        component_name='BarsIcon'
        library={'PS IM'}
        used_for={'benchmarking UI - header'}
      />
      <IconInfo
        Icon={MergeIcon}
        component_name='MergeIcon'
        library={'PS IM'}
        used_for={'trendspotter'}
      />
      <IconInfo
        Icon={AppsIcon}
        component_name='AppsIcon'
        library={'PS IM'}
        used_for={'header'}
      />
      <IconInfo
        Icon={RightArrowInCircleIcon}
        component_name='RightArrowInCircleIcon'
        library={'PS IM'}
        used_for={'tiles'}
      />
      <IconInfo
        Icon={MoreVertIcon}
        component_name='MoreVertIcon'
        library={'PS IM'}
        used_for='user management, headers'
      />
      <IconInfo
        Icon={DragIndicatorIcon}
        component_name='DragIndicatorIcon'
        library={'PS IM'}
        used_for='org basket menu'
      />
      <IconInfo
        Icon={SearchIcon}
        component_name='SearchIcon'
        library={'PS IM'}
        used_for={'MUI search input'}
      />
      <IconInfo
        Icon={OpenWithIcon}
        component_name='OpenWithIcon'
        library={'PS IM'}
        used_for={'DnD'}
      />
      <IconInfo
        Icon={AddCircleIcon}
        component_name='AddCircleIcon'
        library={'PS IM'}
        used_for={'tech discovery, classifiers ui, org search'}
      />
      <IconInfo
        Icon={RelevantLabelControlIcon}
        component_name='RelevantLabelControlIcon'
        library={'PS IM'}
        used_for={'tech discovery'}
      />
      <IconInfo
        Icon={IrrelevantLabelControlIcon}
        component_name='IrrelevantLabelControlIcon'
        library={'PS IM'}
        used_for={'tech discovery'}
      />
      <IconInfo
        Icon={BookmarkLabelControlIcon}
        component_name='BookmarkLabelControlIcon'
        library={'PS IM'}
        used_for={'tech discovery'}
      />
      <IconInfo
        Icon={PinIcon}
        component_name='PinIcon'
        library={'PS IM'}
        used_for={'tech discovery'}
      />
      <IconInfo
        Icon={UnpinIcon}
        component_name='UnpinIcon'
        library={'PS IM'}
        used_for={'tech discovery'}
      />
      <IconInfo
        Icon={SearchAgainIcon}
        component_name='SearchAgainIcon'
        library={'PS IM'}
        used_for={'tech discovery'}
      />
      <IconInfo
        Icon={CircleUncheckedIcon}
        component_name='CircleUncheckedIcon'
        library={'PS IM'}
        used_for={'report builder progress'}
      />
      <IconInfo
        Icon={CircleCheckedIcon}
        component_name='CircleCheckedIcon'
        library={'PS IM'}
        used_for={'report builder progress'}
      />
      <IconInfo
        Icon={PositiveLabelControlIcon}
        component_name='PositiveLabelControlIcon'
        library={'PS IM'}
        used_for={'classifier label'}
      />
      <IconInfo
        Icon={NegativeLabelControlIcon}
        component_name='NegativeLabelControlIcon'
        library={'PS IM'}
        used_for={'classifier label'}
      />
      <IconInfo
        Icon={PositiveTestLabelControlIcon}
        component_name='PositiveTestLabelControlIcon'
        library={'PS IM'}
        used_for={'classifier label'}
      />
      <IconInfo
        Icon={NegativeTestLabelControlIcon}
        component_name='NegativeTestLabelControlIcon'
        library={'PS IM'}
        used_for={'classifier label'}
      />
      <IconInfo
        Icon={ChainOpenedIcon}
        component_name='ChainOpenedIcon'
        library={'PS IM'}
        used_for={'PS export'}
      />
      <IconInfo
        Icon={ChainClosedIcon}
        component_name='ChainClosedIcon'
        library={'PS IM'}
        used_for={'PS export'}
      />
    </div>
  )
}

export default IconFinder