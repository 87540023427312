import React, { useState } from 'react'
import cn from 'classnames'
import _ from 'underscore'

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { CheckboxAndLabel } from '../../widgets/CheckboxAndLabel.js'
import { RadiobuttonWithLabel } from '../../widgets/RadiobuttonWithLabel.js'
import {
  add_or_remove_selected_item,
  ALL_FILTER_COUNTRIES,
  FILTER_GEOS
} from '../../../utils/report_deck_filter_utils.js'
import {  get_target_org_display_name } from '../../../utils/report_deck_utils.js'
import { is_company_list_target_list } from '../../../utils/deck_spec_utils.js'
import SpotlightControl from '../../widgets/SpotlightControl.js'

import s from './Filters.module.scss'

const Filter = ({toggle_label, toggle_short_label, toggle_title, is_disabled, children}) => {
  const [dropdown_open, set_dropdown_open] = useState(false)

  function toggle() {
    const should_open = !dropdown_open
    set_dropdown_open(should_open)
  }

  return (
    <Dropdown
      isOpen={dropdown_open}
      toggle={toggle}
      disabled={is_disabled}
    >

      <DropdownToggle
        tag='div'
        className={
          cn(
            'py-1 px-1 px-sm-2',
            s.dropdown_toggle,
            {[s.dropdown_toggle__open]: dropdown_open}
          )
        }
        disabled={is_disabled}
        title={toggle_title || toggle_label}
      >
        <span className='d-none d-sm-block'>{toggle_label}</span>
        <span className='d-block d-sm-none'>{toggle_short_label}</span>
      </DropdownToggle>

      <DropdownMenu
        end={true}
        className={s.dropdown_menu}
      >
        {children}
      </DropdownMenu>
    </Dropdown>
    )
}

const OrgsSelector = ({ items, selected_ids, on_selected_change, is_disabled }) => {
  function on_click(item_id) {
    add_or_remove_selected_item(item_id, selected_ids, on_selected_change)
  }

  const label = 'Company Lists'
  const is_all_selected = (items.length === selected_ids.length)

  const target_org = _.find(items, company_list => is_company_list_target_list(company_list))
  const benchmarking_lists = items.filter(company_list => !is_company_list_target_list(company_list))
  const target_org_name = get_target_org_display_name(items)

  const how_many_selected_label = is_all_selected ? 'All' : `${selected_ids.length}/${items.length}`
  return (
    <Filter
      toggle_title={label}
      toggle_short_label='Orgs'
      toggle_label={`${label} [${how_many_selected_label}]`}
      is_disabled={is_disabled}
    >
      <DropdownItem header>My Organisation</DropdownItem>
      <DropdownItem className={s.dropdown_item} tag='div' toggle={false}>
        <CheckboxAndLabel
          is_checked={selected_ids.indexOf(target_org.id) > -1}
          label={target_org_name}
          on_click={() => on_click(target_org.id)}
        />
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem header>Benchmark Groups</DropdownItem>
      {benchmarking_lists.map((item, i) => {
        const {id, name} = item
        const is_selected = selected_ids.indexOf(id) > -1

        return (
          <DropdownItem key={i} className={s.dropdown_item} tag='div' toggle={false}>
            <CheckboxAndLabel
              is_checked={is_selected}
              label={name}
              on_click={() => on_click(id)}
            />
          </DropdownItem>
        )
      })}
    </Filter>
  )
}

const TechsSelector = ({items, selected_ids, on_selected_change, spotlighted_ids=[], on_spotlighted_change, is_disabled}) => {

  function on_select(item_id) {
    add_or_remove_selected_item(item_id, selected_ids, on_selected_change)
  }

  function on_spotlight(item_id) {
    add_or_remove_selected_item(item_id, spotlighted_ids, on_spotlighted_change)
  }

  const label = 'Technology Areas'
  const is_all_selected = (items.length === selected_ids.length)
  const how_many_selected_label = is_all_selected ? 'All' : `${selected_ids.length}/${items.length}`
  return (
    <Filter
      toggle_title={label}
      toggle_short_label='Techs'
      toggle_label={`${label} [${how_many_selected_label}]`}
      is_disabled={is_disabled}
    >
      {items.map((item, i) => {
        const {id, name} = item
        const is_selected = selected_ids.indexOf(id) > -1
        const is_spotlighted = spotlighted_ids.indexOf(id) > -1
        return (
          <DropdownItem key={i} className={cn('d-flex justify-content-between', s.dropdown_item, s.option_wrapper)} tag='div' toggle={false}>
            <CheckboxAndLabel
              is_checked={is_selected}
              label={name}
              on_click={() => on_select(id)}
              className={cn({'text-endbold': is_spotlighted})}
            />

            <SpotlightControl
              is_spotlighted={is_spotlighted}
              on_click={() => on_spotlight(id)}
              className={s.spotlight_control}
            />
          </DropdownItem>
        )
      })}
    </Filter>
  )
}

const GeoSelector = ({selected_geos, on_selected_change, is_disabled}) => {
  const has_selected_geos = (selected_geos != null)

  function on_click_from_territory(geo) {
    const {country_codes} = geo
    const is_selected = is_geo_selected(geo)

    on_selected_change(is_selected ? _.difference(selected_geos, country_codes) : [...selected_geos, ...country_codes])
  }

  function is_geo_selected(geo) {
    return _.intersection(selected_geos || [], geo.country_codes).length !== 0
  }

  function get_selected_ids_label() {
    if (!has_selected_geos) return 'All'
    if (selected_geos.length === 0) return 'None'
    const selected_ids = []
    FILTER_GEOS.forEach(geo => {
      if (is_geo_selected(geo)) {
        selected_ids.push(geo.id)
      }
    })

    return selected_ids.join(',')
  }

  const label = 'Geography'

  return (
    <Filter
      toggle_title={label}
      toggle_short_label='Geos'
      toggle_label={`${label} [${get_selected_ids_label()}]`}
      is_disabled={is_disabled}
    >
      <DropdownItem className={s.dropdown_item} tag='div' toggle={false}>
        <RadiobuttonWithLabel on_click={() => on_selected_change(null)} is_checked={!has_selected_geos} label='No filters (show all)'/>
      </DropdownItem>
      <DropdownItem className={cn('d-flex flex-column', s.dropdown_item)} tag='div' toggle={false}>
        <RadiobuttonWithLabel is_checked={has_selected_geos} label='Filter to selected territories:' on_click={() => on_selected_change(ALL_FILTER_COUNTRIES)}/>
        <div className='ps-3 pt-1'>
          {FILTER_GEOS.map(geo => {
            const {id, name} = geo

            const is_selected = is_geo_selected(geo)

            return (
              <CheckboxAndLabel
                key={id}
                is_checked={is_selected}
                on_click={() => on_click_from_territory(geo)}
                label={name}
                is_disabled={!has_selected_geos}
              />
            )
          })}
        </div>
      </DropdownItem>
    </Filter>
  )
}

const Filters = (
  {
    org_lists,
    tech_areas,
    selections,
    on_deck_selected_org_lists_change,
    on_deck_selected_tech_areas_change,
    on_deck_spotlighted_tech_areas_change,
    on_deck_selected_geo_filters_change,
    className,
  }) => {

  const {selected_org_lists, selected_tech_areas, selected_geo_filters, spotlighted_tech_areas} = selections

  return (
    <div className={cn('d-flex', className)}>
      <div>
        <OrgsSelector
          items={org_lists}
          selected_ids={selected_org_lists}
          on_selected_change={on_deck_selected_org_lists_change}
        />
      </div>
      <div className='ms-2'>
        <TechsSelector
          items={tech_areas}
          selected_ids={selected_tech_areas}
          on_selected_change={on_deck_selected_tech_areas_change}
          spotlighted_ids={spotlighted_tech_areas}
          on_spotlighted_change={on_deck_spotlighted_tech_areas_change}
        />
      </div>
      <div className='ms-2'>
        <GeoSelector
          selected_geos={selected_geo_filters}
          on_selected_change={on_deck_selected_geo_filters_change}
        />
      </div>
    </div>
  )
}

export default Filters