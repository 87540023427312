import React, { useState } from 'react'

import ClearableSearchInput from '../widgets/ClearableSearchInput.js'
import { MAX_LIST_NAME_LENGTH } from '../../utils/company_list_utils.js'
import { PrimaryButton } from '../widgets/Button.js'
import Modal from '../widgets/Modal.js'
import { FormFeedback } from '../widgets/FormFeedback.js'

const ListNameModal = (
  {
    initial_text,
    on_submit,
    is_name_unique,
    submit_label,
    title,
    on_cancel
  }) => {
  const [list_name_input, set_list_name_input] = useState(null)


  function get_validation_text() {
    if (!is_unique) return 'There is already a list with this name'
    if (!is_correct_length) return `List name cannot be empty or longer than ${MAX_LIST_NAME_LENGTH} characters.`
    return ''
  }

  function on_key_down(e) {
    if (e.which === 13 && value && is_correct_length) {
      on_submit(value.trim())
    }
  }


  const value = (list_name_input != null) ? list_name_input : (initial_text) ? initial_text : null

  const is_unique = value ? is_name_unique((value || '').trim()) : true
  const is_correct_length = ((value || '').trim().length > 0) && ((value || '').trim().length < MAX_LIST_NAME_LENGTH + 1)

  const primary_button = (
    <PrimaryButton onClick={() => on_submit(value.trim())} disabled={!value || !is_correct_length}>
      {submit_label || 'Submit'}
    </PrimaryButton>
  )

  return (
    <Modal is_open={true} on_hide={on_cancel} primary_button={primary_button} close_label='Cancel' title={title} size='sm'>
      <div>
        <ClearableSearchInput
          placeholder={'Enter list name'}
          value={value}
          handle_change={input_string => set_list_name_input(input_string)}
          auto_focus={true}
          handle_key_down={on_key_down}
          show_clear
        />

        {value && (!is_correct_length || !is_unique) &&
          <FormFeedback valid={false} validation_text={get_validation_text()}/>
        }
      </div>
    </Modal>
  )
}

export default ListNameModal