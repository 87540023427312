export const WHITE = '#ffffff'
export const BLACK = '#000000'

// colours from the PS style guide (see _colours.scss for more)
//const PS_RED_500 = '#F6271C'
const PS_RED_700 = '#D60019'
const PS_LIGHT_GREEN_400 = '#9CCC65'
const PS_GREEN_400 = '#66BB6A'
const PS_GREEN_600 = '#43A047'
const PS_LIGHT_BLUE_300 = '#4FC3F7'
const PS_LIGHT_BLUE_600 = '#039ae5'
const PS_LIGHT_BLUE_800 = '#006ebb'
const PS_BLUE_50 = '#E3F2FD'
const PS_BLUE_500 = '#2196F3'
const PS_BLUE_900 = '#0D47A1'
const PS_BLUE_GREY_50 = '#ECEFF1'
const PS_BLUE_GREY_200 = '#B0BEC5'
const PS_BLUE_GREY_600 = '#546E7A'
const PS_BLUE_GREY_700 = '#455A64'
export const PS_DARK_100 = '#ACBAC9'
const PS_DARK_300 = '#687C96'
const PS_DARK_800 = '#132A43'
const PS_DARK_900 = '#00172E'
const PS_DARK_CUSTOM_1 = '#EAEEF2'
const PS_DARK_CUSTOM_2 = '#DFE6EC'
const PS_GREY_100 = '#F5F5F5'
const PS_GREY_400 = '#BDBDBD'
const PS_GREY_800 = '#424242'
const PS_GREY_900 = '#212121'
const PS_ORANGE_400 = '#FFA726'
const PS_ORANGE_800 = '#EF6C00'
const PS_YELLOW_ORANGE = '#FFC107'

export const LIGHT_GREY_COLOUR   = PS_DARK_CUSTOM_2

export const DEFAULT_TEXT_COLOUR = PS_GREY_900
export const DARK_TEXT_COLOUR = PS_DARK_900
export const DEFAULT_TEXT_COLOUR_OVER_DARK_BG = WHITE

export const PS_INPUT_HIGHLIGHT_BLUE = PS_BLUE_900
export const PS_LIGHT_BLUE_HIGHLIGHT = PS_BLUE_50
export const PS_CONTROL_DISABLED = PS_BLUE_GREY_50
export const PS_CONTROL_DISABLED_GREY = '#8C9EB1'
export const PS_CONTROL_DISABLED_CHECKED = PS_DARK_100
export const PS_LABEL_TEXT_COLOUR = PS_BLUE_GREY_700
export const PS_INPUT_FOCUS_BORDER_COLOUR = PS_LIGHT_BLUE_300
export const PS_INPUT_HOVER_BORDER_COLOUR = PS_BLUE_GREY_700
export const PS_INPUT_ACTIVE_BORDER_COLOUR = PS_GREY_800
export const PS_BLUE_GREY_BG_1 = PS_BLUE_GREY_50
export const PS_PLACEHOLDER_TEXT = PS_BLUE_GREY_200

export const PS_CHIP_HOVER_BORDER_COLOUR = PS_DARK_300
export const PS_CHIP_ACTIVE_BORDER_COLOUR = PS_LIGHT_BLUE_300
export const PS_CHIP_FOCUS_BORDER_COLOUR = PS_GREY_800

export const PS_ICON_GREY = PS_DARK_300
export const PS_ICON_HOVER_HALO = PS_DARK_CUSTOM_1
export const PS_SEE_THRU_ACTIVE_BLUE = 'rgba(13, 71, 161, .1)'
export const PS_TABLE_ROW_BG_EVEN = PS_GREY_100
export const PS_GREY_BORDER = PS_DARK_100
export const PS_GLOBAL_TEXT_COLOUR = PS_GREY_900
export const PS_TOPBAR_BLUE = PS_DARK_800
export const PS_LINK_TEXT_COLOUR = PS_LIGHT_BLUE_800
export const PS_LINK_TEXT_HOVER = PS_LIGHT_BLUE_600
export const PS_RED = PS_RED_700
export const PS_GREEN = PS_GREEN_400
export const METRICS_CHARTS_COLOURS = [PS_LIGHT_BLUE_800, PS_ORANGE_800]

export const CATEGORY_20 = [
  PS_BLUE_GREY_600,
  '#b4d3e0',
  '#f58e33',
  '#ffcb78',
  '#015c44',
  '#56ba70',
  PS_RED,
  '#de8392',
  '#764e9c',
  '#c5a1e3',
  PS_TOPBAR_BLUE,
  '#3e79b0',
  '#630e3d',
  '#b8518a',
  '#726d7d',
  '#b6b0c2',
  '#97b06a',
  '#e8e288',
  '#17becf',
  '#c4f6ff'
]

export const PORTFOLIO_COLOR_SCHEME = [
  PS_RED,
  PS_BLUE_GREY_600,
  '#B4D3E0',
  '#9226a3',
  '#E59500',
  '#E8E288',
  PS_TOPBAR_BLUE,
  '#de8392',
  '#630e3d',
  '#a4bf71',
  '#44ad96',
  '#b8a9c9',
  '#5f48c7',
  '#99ACFF',
  '#42cceb'
]

export const TECH_COLOR_SCHEME = [
  '#42cceb',
  '#5f48c7',
  '#de8392',
  '#630e3d',
  '#99ACFF',
  '#a4bf71',
  '#44ad96',
  '#b8a9c9',
  PS_TOPBAR_BLUE,
  '#E59500',
  '#E8E288',
  '#9226a3',
  '#B4D3E0',
  '#447c94',
  PS_RED,
]

export const GEO_COLOR_SCHEME = CATEGORY_20

export const MISC_COLOR_SCHEME = [
  PS_TOPBAR_BLUE,
  '#f0ec78',
  '#a4bf71',
  PS_RED,
  '#5f48c7',
  '#630e3d',
  '#EA9388',
  '#17becf',
  '#9226a3',
  '#b4d3e0'
]

export const DEFAULT_1D_COLOR_SCHEME = CATEGORY_20

export const POSITIVE_LABEL = PS_GREEN_600
export const NEGATIVE_LABEL = PS_RED

//patent family status colours
export const GRANTED_COLOR     = PS_LIGHT_GREEN_400
export const PENDING_COLOR     = PS_ORANGE_400
export const EXPIRED_COLOR     = PS_RED
export const OTHER_COLOR       = PS_DARK_300
export const INACTIVE_COLOR    = PS_GREY_400
export const REJECTED_COLOR    = '#EA9388'
// end of patent family status colours

export const PLAINTIFF_COLOR = '#9467bd'
export const DEFENDANT_COLOR = '#aec7e8'

export const APPLICATIONS_COLOR = '#69B2B6'
export const RENEWALS_COLOR = '#F19310'

export const DEFAULT_CHART_COLOR = PS_RED

export const DEFAULT_NEXT_AGGLOM_COLOR = LIGHT_GREY_COLOUR

export const TREEMAP3D_MIN_COLOR = '#3777FE'
export const TREEMAP3D_MAX_COLOR = '#FF6600'
export const TREEMAP3D_ZERO_COLOR = '#CDD2D6'

// user messages etc
export const PS_INFO_MESSAGE = PS_BLUE_500
export const PS_SUCCESS_MESSAGE = PS_GREEN_400
export const PS_WARNING_MESSAGE = PS_YELLOW_ORANGE
export const PS_ERROR_ALERT = PS_RED_700

//report deck colours
export const DECK_TARGET_ORG_COLUMN_COLOUR = PS_RED
export const DECK_DEFAULT_COLUMN_COLOR = PS_DARK_100
export const DECK_LIGHTER_DEFAULT_COLUMN_COLOR = PS_DARK_CUSTOM_2 // non-highlighted series in line charts
export const DECK_MISC_SPOTLIGHT_COLOR = PS_LIGHT_BLUE_800
export const DECK_SPOTLIGHTED_ORG_COLUMN_COLOR = DECK_MISC_SPOTLIGHT_COLOR
export const DECK_BACKGROUND_ORG_COLUMN_COLOR = PS_DARK_CUSTOM_1 // orgs not in lists
export const DECK_TECH_AREAS_COLOR_SCHEME = [
  '#E59500',
  '#5f48c7',
  '#97b06a',
  '#d15641',
  '#17becf',
  '#b8518a',
  '#726d7d',
  '#f0ec78',
  '#3e79b0',
  '#EA9388',
  '#56ba70',
  '#9226a3'
]
//end of report deck colours



