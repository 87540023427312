import React, { useState } from 'react'
import cn from 'classnames'

import { SimpleErrorIcon } from './IconSet.js'

import ErrorModal from '../ErrorModal.js'

import s from './InlineError.module.scss'


const InlineError = ({ className, icon_className, title, error, context, on_retry, on_clear }) => {

  const [is_show_modal, set_is_show_modal] = useState(false)

  function close_modal() {
    set_is_show_modal(false)
  }

  return (
    <span
      className={cn(s.block, className)}
      onClick={() => set_is_show_modal(true)}
    >
      <span
        className={cn(s.error_icon, icon_className)}
      >
        <SimpleErrorIcon />
      </span>
      {is_show_modal &&
        <ErrorModal
          title={title}
          error={error}
          context={context}
          on_hide={close_modal}
          on_retry={on_retry}
          on_clear={on_clear}
        />
      }

    </span>

  )
}

export default InlineError