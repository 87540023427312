import React from 'react'
import cn from 'classnames'

import CalendarDatePicker from '../widgets/CalendarDatePicker.js'

import s from './AlertScheduleDatePicker.module.scss'


const AlertScheduleDatePicker = ({min_date, max_date, on_select, selected_date, disabled, className, iconClassName}) => {

  return (
    <div
      title='Reschedule'
      className='d-flex'
    >
      <CalendarDatePicker
        selected_date={selected_date}
        on_date_change={on_select}
        min_date={min_date}
        max_date={max_date}
        className={cn(s.date_input, className)}
        iconClassName={iconClassName}
        disabled={disabled}
      />
    </div>
  )
}

export default AlertScheduleDatePicker