import React from 'react'

const OrgSuggestionsHeading = ({org_name, className}) => {
  return (
    <div className={className}>
      Organisations with portfolios similar to <span className='text-endbold'>{org_name}</span>
    </div>
  )
}

export default OrgSuggestionsHeading