import React from 'react'

import { Label } from './FamilyDetailsLabel.js'
import { InfoPopover } from '../widgets/Tooltip.js'
import { Highlighter } from '../widgets/Highlighter.js'
import { WRAPPER_ELEMENT_PARENT } from '../../constants/tooltip.js'

export const FamilyAssignees = ({family, is_in_modal, highlighter_props}) => {

  const {owners, assignees, patFamId} = family

  const owners_string = (owners || []).join(', ')
  const assignees_string = (assignees || []).join(', ')

  const tooltip_props = {
    is_in_modal,
    placement: 'right',
    wrapper: WRAPPER_ELEMENT_PARENT
  }

  return (
    <div key={`assignees_${patFamId}`}>
      {owners &&
        <div>
          <div className='d-flex'>
            <Label>Owners</Label>
            <InfoPopover buttonClassName='ms-1' {...tooltip_props}>
              Top level organisations who own this patent</InfoPopover>
          </div>
            <div>
              <Highlighter
                {...highlighter_props}
              text_to_highlight={owners_string}
            />
          </div>
        </div>
      }
      {assignees &&
        <div>
          <div className='d-flex'>
            <Label>Assignees</Label>
            <InfoPopover buttonClassName='ms-1' {...tooltip_props}>
              Assignee named on patent (or most recent assignment)
            </InfoPopover>
          </div>
          <div>
            <Highlighter
              {...highlighter_props}
              text_to_highlight={assignees_string}
            />
          </div>
        </div>
      }
    </div>
  )

}