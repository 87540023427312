import React from 'react'
import cn from 'classnames'
import { DropdownItem } from 'reactstrap'

import { COUNTRY_PREFIXES, DOCUMENT_KIND, DOCUMENT_KIND_NOT_SPECIFIED } from '../../utils/custom_search_utils.js'
import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'
import BaseDropdown from '../widgets/BaseDropdown.js'
import { InfoPopover } from '../widgets/Tooltip.js'

import s from './PatentUploadOptions.module.scss'

export const PatentUploadOptions = ({
  className,
  is_disabled,
  strict_selector,
  toggle_strict_selector,
  selected_country_code,
  set_selected_country_code,
  selected_document_kind,
  set_selected_document_kind
}) => {

  return (
    <div className={className}>
      <h6>Input settings</h6>
      <div className='d-flex mb-2'>
        <CheckboxAndLabel
          is_checked={strict_selector}
          label='Strict mode'
          on_click={toggle_strict_selector}
          is_disabled={is_disabled}
        />
        <InfoPopover buttonClassName='ms-1 my-auto'>
          <div>
            Strict mode offers the fastest matching, but requires structured input with one patent number or family ID per line.
          </div>
          <div className='mt-1'>
            Deselect this option if your input is less structured, includes multiple patents per line or any other text that should be ignored.
          </div>
        </InfoPopover>
      </div>
      <div className='d-flex mb-2'>
        <div className={cn('ps-1 my-auto', s.option_label)}>
          Patent numbers with no country code should be recognized as
        </div>
        <BaseDropdown
          menuClassName={s.country_code_dropdown}
          buttonClassName={cn('ms-1', s.dropdown_toggle)}
          label={selected_country_code}
        >
          {COUNTRY_PREFIXES.map((country_code, i) => {
            return (
              <DropdownItem
                onClick={() => set_selected_country_code(country_code)}
                key={i}
                active={country_code === selected_country_code}
              >
                {country_code}
              </DropdownItem>
            )
          })}
        </BaseDropdown>
      </div>

      <div className='d-flex mb-2'>
        <div className={cn('ps-1 my-auto', s.option_label)}>
          Patent numbers include
        </div>
        <BaseDropdown
          className='d-inline-flex'
          label={selected_document_kind || DOCUMENT_KIND_NOT_SPECIFIED}
          buttonClassName={cn('ms-1', s.dropdown_toggle)}
        >
          <DropdownItem
            onClick={() => set_selected_document_kind(null)}
            key={DOCUMENT_KIND_NOT_SPECIFIED}
            active={!selected_document_kind}
          >
            {DOCUMENT_KIND_NOT_SPECIFIED}
          </DropdownItem>
          {DOCUMENT_KIND.map((document_kind, i) => {
            return (
              <DropdownItem
                onClick={() => set_selected_document_kind(document_kind)}
                key={i}
                active={document_kind === selected_document_kind}
              >
                {document_kind}
              </DropdownItem>
            )
          })}
        </BaseDropdown>
      </div>
    </div>
  )
}
