import React, { useEffect, useState } from 'react'
import _ from 'underscore'
import cn from 'classnames'

import { add_roles_for_keycloak_group, fetch_available_roles_for_keycloak_group, fetch_child_roles } from '../../utils/user_group_utils.js'
import { KEYCLOAK_ROLES_TO_EXCLUDE, KEYCLOAK_ROLES_TO_EXCLUDE_AT_USER_LEVEL, add_user_roles, fetch_user_roles__available } from '../../utils/user_management_utils.js'

import Spinner from '../widgets/Spinner.js'
import Modal from '../widgets/Modal.js'
import ChildRoleRows from './ChildRoleRows.js'
import ErrorBody from '../ErrorBody.js'
import { NavTab, NavWrapper } from '../widgets/NavTab.js'

import s from './AddRoleModal.module.scss'

const AddRoleModal = (
  {
    group,             // optional: for group-level roles

    user,              // optional: for user-level roles
    group_level_roles, // optional: for user, shows group-level roles (will be excluded)

    child_role_ids,        // These roles will be excluded (may include composite children from group and user levels)

    id_to_child_roles,     // For updating composite roles after an add (only includes composites for a single level i.e. only group, or only user)
    set_id_to_child_roles, // For updating composite roles after an add (only includes composites for a single level i.e. only group, or only user)

    local_roles,       // local state
    set_local_roles,   // for calling on success

    on_close,
}) => {

  const [is_fetching, set_is_fetching] = useState(true)
  const [error_fetching, set_error_fetching] = useState(null)
  const [available_roles, set_available_roles] = useState(null)
  const [available_id_to_child_roles, set_available_id_to_child_roles] = useState(null)

  const [is_adding, set_is_adding] = useState(false)
  const [error_adding, set_error_adding] = useState(null)

  const [is_show_cipher_taxonomies, set_is_show_cipher_taxonomies] = useState(false)

  if (!group && !user) {
    throw new Error('No group or user provided')
  }

  const title_suffix = group ? `group ${group.name}` : `user ${user.email}`
  const title = `Add role to ${title_suffix}`

  const roles_to_exclude = user != null ? [...KEYCLOAK_ROLES_TO_EXCLUDE, ...KEYCLOAK_ROLES_TO_EXCLUDE_AT_USER_LEVEL] : KEYCLOAK_ROLES_TO_EXCLUDE

  useEffect(() => {
    // fetch available roles
    Promise.resolve(true)
    .then(() => {
      if (group) {
        return fetch_available_roles_for_keycloak_group(group.id)
      }
      return fetch_user_roles__available(user.id)
    })
    .catch(err => {
      set_error_fetching(err)
      throw err
    })
    .then(available_roles => {
      const available_roles__filtered = available_roles.filter(role => !roles_to_exclude.includes(role.name))

      return fetch_child_roles(available_roles)
        .then(available_id_to_child_roles => {
          const available_roles__sorted = _.sortBy(available_roles__filtered, 'name')

          set_available_roles(available_roles__sorted)
          set_available_id_to_child_roles(available_id_to_child_roles)
        })
    })
    .finally(() => {
      set_is_fetching(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function do_add(role) {
    set_is_adding(true)

    Promise.resolve(true)
      .then(() => {
        // Add
        if (group) {
          return add_roles_for_keycloak_group(group.id, [role])
        }
        return add_user_roles(user.id, [role])
      })
      .then(() => {
        // Fetch child roles
        return fetch_child_roles([role])
      })
      .catch(err => {
        // FAIL
        set_error_adding(err)
        throw err
      })
      .then((extra_id_to_child_roles) => {
        // SUCCESS
        // update local state
        const new_local_roles = [...local_roles, role]
        const new_local_roles__sorted = _.sortBy(_.unique(new_local_roles), 'name')
        set_local_roles(new_local_roles__sorted)

        // update composite roles
        const new_id_to_child_roles = { ...id_to_child_roles, ...extra_id_to_child_roles }
        set_id_to_child_roles(new_id_to_child_roles)

        // close modal
        on_close()
      })
      .finally(() => {
        set_is_adding(false)
      })
  }

  return (
    <Modal
      size='sm'
      bodyClassName={s.modal_body}
      title={title}
      close_label={'Cancel'}
      on_hide={on_close}
    >
      {(is_adding || is_fetching) &&
        <Spinner size={'sm'}/>
      }

      {(!is_adding && !is_fetching && available_roles) &&
        <div>

          {/* Checkbox for show/hide cipher taxonomies */}
          <NavWrapper className='mb-2'>
            <NavTab className='me-4' is_active={!is_show_cipher_taxonomies} on_click={() => set_is_show_cipher_taxonomies(false)}>Show general</NavTab>
            <NavTab is_active={is_show_cipher_taxonomies} on_click={() => set_is_show_cipher_taxonomies(true)}>Show taxonomies</NavTab>
          </NavWrapper>

          <div className={cn(s.rows_container)}>
            {available_roles.map((role, idx) => {
              const { name } = role

              if (!is_show_cipher_taxonomies && name.startsWith('has_cipher_')) {
                return null
              }
              if (is_show_cipher_taxonomies && !name.startsWith('has_cipher_')) {
                return null
              }

              const is_role_in_group_level_roles = group_level_roles && group_level_roles.some(r => r.name === name)
              const is_role_in_child_role_ids = child_role_ids && child_role_ids.includes(role.id)

              if (is_role_in_group_level_roles) {
                return (
                  <div
                    key={idx}
                    className='p-1'
                  >
                    <span className={cn(s.action_link__disabled)} title={'this role has been added at group-level'}>{role.name}</span>
                  </div>
                )
              }

              if (is_role_in_child_role_ids) {
                return (
                  <div
                    key={idx}
                    className='p-1'
                  >
                    <span className={cn(s.action_link__disabled)} title={'this role has already been added by a composite role'}>{role.name}</span>
                  </div>
                )
              }

              return (
                <div
                  className={cn('p-1', s.role_container)}
                  key={idx}
                >
                  <div
                    className={s.action_link}
                    onClick={() => do_add(role)}
                  >
                    {role.name}
                  </div>
                  <ChildRoleRows
                    role={role}
                    id_to_child_roles={available_id_to_child_roles}
                  />
                </div>
              )
            })}
          </div>
        </div>
      }

      {/* ERRORS */}
      {error_adding &&
        <ErrorBody
          error={error_adding}
          context={'Adding role'}
        />
      }

      {error_fetching &&
        <ErrorBody
          error={error_fetching}
          context={'fetching roles'}
        />
      }

    </Modal>
  )
}

export default AddRoleModal
