import React, { useState } from 'react'
import _ from 'underscore'
import { DropdownItem } from 'reactstrap'

import ScrollableList from '../../widgets/ScrollableList.js'
import BaseDropdown from '../../widgets/BaseDropdown.js'
import { TagIcon } from '../../widgets/IconSet.js'
import ClearableSearchInput from '../../widgets/ClearableSearchInput.js'
import { REPORT_FILTERS, ID_TO_REPORT_FILTER, EVAL_ONLY, SAVED_REPORTS } from '../model/filters.js'

import { filter_on_search_string } from '../utils/sort_and_filter_utils.js'
import { pluralise_text } from '../../../utils/utils.js'

import s from './TagsFilter.module.scss'


const TagsFilter = ({tags, selected_tag_id, select_tag_id_to_filter_by, report_filter_id, select_report_filter_id, has_projects, can_evaluate_classifiers}) => {

  // shuffle tags with no reports to the bottom of the list
  const sorted_tags = _.sortBy(tags, tag => tag.report_count < 1)

  const [search_string, set_search_string] = useState('')

  const selected_filter = ID_TO_REPORT_FILTER[report_filter_id]
  const selected_filter_name = selected_filter.name || selected_filter.get_name({has_projects, can_evaluate_classifiers})

  const button_label = selected_tag_id === null ?
    selected_filter_name : (tags.find(tag => tag.tag_id === selected_tag_id) || {}).name

  const filtered_tags = filter_on_search_string(sorted_tags, search_string, 'name')

  return (
    <span className={'mt-2 d-flex align-items-center'}>
      <span>
        Show
      </span>
      <BaseDropdown
        className={'ms-1'}
        label={(selected_tag_id ? (<span><TagIcon/> {button_label}</span>) : button_label )}
        menuClassName={s.filter_dropdown_menu}
        labelClassName={s.filter_dropdown_label}
      >

      <ScrollableList className={s.filter_dropdown_container}>
        {REPORT_FILTERS
          .filter(filter => filter.can_show({has_projects, can_evaluate_classifiers}))
          .map((filter) => {
            const {id} = filter
            return (
              <div key={id}>
                {((can_evaluate_classifiers && id === EVAL_ONLY) || (has_projects && id === SAVED_REPORTS)) &&
                  <DropdownItem divider/>
                }
                <DropdownItem
                  active={!selected_tag_id && selected_filter.id === id}
                  onClick={() => select_report_filter_id(id)}
                >
                  {filter.name || filter.get_name({has_projects, can_evaluate_classifiers})}
                </DropdownItem>
              </div>
            )
          })
        }
        { tags && tags.length > 0 &&
          <>
            <DropdownItem divider/>
            <ClearableSearchInput
              containerClassName='mb-1 me-1'
              value={search_string}
              placeholder={'Search tags'}
              auto_focus={true}
              handle_change={(search_string => set_search_string(search_string))}
              show_clear={true}
            />
            {filtered_tags.length === 0 &&
              <span>No matches</span>
            }
            {filtered_tags.map(tag =>
              <DropdownItem
                key={tag.tag_id}
                onClick={() => select_tag_id_to_filter_by(tag.tag_id)}
                disabled={tag.report_count < 1}
                active={selected_tag_id === tag.tag_id}
              >
                <span className='d-inline'>
                  <TagIcon/>
                  <span className='ms-1'>{tag.name} [{tag.report_count} {pluralise_text(tag.report_count, 'report')}]</span>
                </span>
              </DropdownItem>
            )}
          </>
        }
      </ScrollableList>
    </BaseDropdown>

    </span>
  )
}

export default TagsFilter
