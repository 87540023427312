import React from 'react'
import cn from 'classnames'

import { AddressIcon } from '../widgets/IconSet.js'

import s from './AddressDisplay.module.scss'

const AddressControl = ({address}) => {
  return (
    <div className={cn('d-flex', s.address_wrapper)}>
      <span className={cn('me-1', s.icon, [{[s.inactive]: !address}])}><AddressIcon /></span>
      <span className={cn(s.info, [{[s.inactive]: !address}])}>{(address) ? address : '(no address)'}</span>
    </div>
  )
}

export default AddressControl