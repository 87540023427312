import React, { useState } from 'react'
import { DropdownItem } from 'reactstrap'
import _ from 'underscore'
import cn from 'classnames'

import {
  choice_as_str, delete_tag,
  delete_tag_value,
  is_valid_name,
  persist_tag_value,
  rename_tag_value_name,
  SectionSorting,
  Tag,
  TagValue
} from './family_tag_utils'
import SortingColumnHeaderCell from '../patent_family_list/SortingColumnHeaderCell.js'
import TextLink from '../widgets/TextLink.js'
import { PlusIcon, CogIcon, TrashIcon } from '../widgets/IconSet.js'
import EditableTextLink from '../report_management/components/EditableTextLink.js'
import { TABLE_COLUMN_HEADER } from '../../model/table.js'
import { Table } from '../widgets/Table.js'
import { ASCENDING } from '../../model/sort_directions.js'
import ErrorModal from '../ErrorModal.js'
import { Pane } from '../widgets/Block.js'
import Spinner from '../widgets/Spinner.js'
import { ConfirmDeleteModal } from '../widgets/ConfirmDeleteModal'
import BaseDropdown from '../widgets/BaseDropdown.js'

import cs from '../cipher_styles.module.scss'
import s from './FamilyTagValuesManagement.module.scss'

interface FamilyTagValueManagementProps {
  tag: Tag,
  className: string,
  notify_parent: Function,
  change_tag:Function,
  sorting:Array<SectionSorting>,
  update_sorting: Function,
  show_index:boolean | null,
}

export const FamilyTagValuesManagement = React.forwardRef(({tag, className, notify_parent, change_tag, sorting, update_sorting, show_index}: FamilyTagValueManagementProps, ref: any) => {

  const TAG_LABELS_COLUMN = 'value'
  const TAG_FAMILIES_COLUMN = 'families_count'
  const TAG_DELETE_COLUMN = 'delete'

  const [is_amending_tag_value, set_is_amending_tag_value] = useState<Array<boolean>>(new Array(tag.values.length).fill(false))
  const [add_new_label, set_add_new_label] = useState<boolean>(false)
  const [sorted_by, set_sorted_by] = useState({id: TAG_LABELS_COLUMN, direction: ASCENDING})

  const [show_modal_delete_tag, set_show_modal_delete_tag] = useState<boolean>(false)
  const [show_modal_delete_tag_value, set_show_modal_delete_tag_value] = useState<boolean>(false)
  const [tag_value_to_delete, set_tag_value_to_delete] = useState<TagValue | null>(null)
  const [show_spinner, set_show_spinner] = useState<boolean>(false)
  const [error, set_error] = useState<any>(null)

  const TAG_TABLE_COLUMNS = [
    {
      label: 'Tags',
      sub_label: tag.type !== null ? choice_as_str(tag.type) : null,
      name: TAG_LABELS_COLUMN,
      field: TAG_LABELS_COLUMN,
      id: TAG_LABELS_COLUMN,
      sortable: true
    },
    {
      label: 'Tagged families',
      name: TAG_FAMILIES_COLUMN,
      field: TAG_FAMILIES_COLUMN,
      id: TAG_FAMILIES_COLUMN,
      sortable: true,
      max_width: 160
    },
    {
      label: '',
      name: TAG_DELETE_COLUMN,
      field: TAG_DELETE_COLUMN,
      id: TAG_DELETE_COLUMN,
      sortable: false,
      max_width: 50
    }
  ]
  const columns = get_columns()
  const rows = get_rows()

  function get_columns() {
    return TAG_TABLE_COLUMNS.map((column: any) => {
      return {
        ...TABLE_COLUMN_HEADER,
        ...column,
        header: render_column_header(column),
        headerClassName: 'no-sort-mark',
      }
    })
  }

  function get_rows(){
    const sorted_values = (sorted_by.direction === ASCENDING)? _.sortBy(tag.values,sorted_by.id) : _.sortBy(tag.values,sorted_by.id).reverse()
    return sorted_values.map((tag_value: TagValue, i) => convert_to_row(tag_value, i))
  }

  function convert_to_row(tag_value: TagValue, row_number:number) {
    const row: any = {number: row_number}
    TAG_TABLE_COLUMNS.forEach((column: any) => {
      switch (column.id) {
        case TAG_LABELS_COLUMN:
          row[TAG_LABELS_COLUMN] = render_editable_cell(tag_value, row_number)
          break
        case TAG_FAMILIES_COLUMN:
          row[TAG_FAMILIES_COLUMN] = render_cell(tag_value.families_count)
          break
        case TAG_DELETE_COLUMN:
          row[TAG_DELETE_COLUMN] = render_delete_label_button(tag_value)
          break
      }
    })
    return row
  }

  function render_column_header(field: any) {
    return (<SortingColumnHeaderCell
      field={{
        ...field,
        id: field.name,
        name: field.label
      }}
      selected_sort_field_id={sorted_by.id}
      selected_sort_direction_id={sorted_by.direction}
      on_change_sort_field_id_and_sort_direction_id={(sort_field_id: string, sort_direction_id: string) => {
        set_sorted_by({id: sort_field_id, direction: sort_direction_id})
      }}
    />)
  }

  function render_delete_label_button(tag_value:TagValue) {
    return (
      // @ts-expect-error
      <TextLink
        onClick={() => check_and_delete_label(tag_value)}
        title='Delete tag'
        no_decoration
      >
        <TrashIcon className={null}/>
      </TextLink>
    )
  }

  function render_editable_cell(tag_value: TagValue, row_number: number) {
    return (
      // @ts-expect-error
      <EditableTextLink
        key={tag_value.id}
        link_text={tag_value.value}
        on_confirm={(new_name: string) => rename_tag_value(tag_value, new_name, row_number)}
        on_cancel={() => on_cancel_amending_tag_value(row_number)}
        on_edit={() => on_edit_amending_tag_value(row_number)}
        is_edit={is_amending_tag_value[row_number]}
        is_valid={(new_name: string) => is_valid_name(new_name, tag.values.map((v) => v.value), null)}
        is_clickable={true}
        is_editable={true}
        inputClassName={cs.in_table_input}
      />
    )
  }

  function on_cancel_amending_tag_value(row_number:number){
    const new_tag_values_amending = [...is_amending_tag_value]
    new_tag_values_amending[row_number] = false
    set_is_amending_tag_value(new_tag_values_amending)
  }

  function on_edit_amending_tag_value(row_number:number){
    const new_tag_values_amending = [...is_amending_tag_value]
    new_tag_values_amending[row_number] = true
    set_is_amending_tag_value(new_tag_values_amending)
  }

  function style_cells(state: any, rowInfo: any, column: any) {
    switch (column.Header.props.field.id) {
      case TAG_FAMILIES_COLUMN:
        return {className: 'text-end'}
      case TAG_DELETE_COLUMN:
        return {className: 'text-center'}
      default:
        return {}
    }
  }

  function render_cell(value: any) {
    const value_to_display = (!value) ? '0' : value
    return (<span>{value_to_display}</span>)
  }

  function rename_tag_value(tag_value: TagValue, new_name: string, row_number: number) {
    set_show_spinner(true)
    rename_tag_value_name(tag_value, new_name)
      .then(()=>{
        set_show_spinner(false)
        const new_tag_values_amending = [...is_amending_tag_value]
        new_tag_values_amending[row_number] = false
        notify_parent()
        set_is_amending_tag_value(new_tag_values_amending)
      })
      .catch((error) => {
        set_show_spinner(false)
        set_error(error)
      })
  }

  function add_tag_value(new_label: string) {
    set_show_spinner(true)
    persist_tag_value(tag.id, new_label)
      .then(() => {
        set_show_spinner(false)
        notify_parent()
        set_add_new_label(false)
      })
      .catch((error:any) => {
        set_show_spinner(false)
        set_error(error)
      })
  }

  function check_and_delete_label(tag_value: TagValue){
    set_tag_value_to_delete(tag_value)
    set_show_modal_delete_tag_value(true)
  }

  function delete_label(){
    set_show_spinner(true)
    delete_tag_value(tag_value_to_delete)
      .then(() => {
        set_show_spinner(false)
        notify_parent()
      })
      .catch((error) => {
        set_show_spinner(false)
        set_error(error)
      })
  }

  function check_and_delete_tag (){
    set_show_modal_delete_tag(true)
  }

  function delete_family_tag(){
    set_show_spinner(false)
    delete_tag(tag)
      .then(() => {
        set_show_spinner(false)
        notify_parent()
      })
      .catch((error) => {
        set_show_spinner(false)
        set_error(error)
      })
  }

  return (
    <div className={className} ref={ref}>
      {show_spinner && !error &&
      //@ts-expect-error
      <Pane className={'text-center'}>
        <Spinner/>
      </Pane>

      }
      {error &&
        //@ts-expect-error
        <ErrorModal
          error={error}
          context ='Error editing family tags'
          on_hide = {() => set_error(null)}
        />
      }
      {show_modal_delete_tag &&
      <ConfirmDeleteModal
        title={'Are you sure you want to delete \'' + tag.name + '\'?'}
        on_confirm={delete_family_tag}
        on_cancel={() => set_show_modal_delete_tag(false)}
        error={null}
      >
        <div className={'m-2 pb-2'}>Deleting this tag group will <strong>permanently remove</strong> the following tags from any families that have been applied to:</div>
        <div className={'m-2'}><ul>{tag.values.map((tv, i) => (<li className='flex-column' key={i}> {tv.value} </li>))}</ul></div>
        <div className={'m-2'}>This change will affect all users who have access to &lsquo;{tag.name}&rsquo;.</div>
      </ConfirmDeleteModal>

      }
      {show_modal_delete_tag_value &&
        <ConfirmDeleteModal
          title={'Are you sure you want to delete the tag \'' + tag_value_to_delete?.value + '\' from the tag group \'' + tag.name + '\'?'}
          on_confirm={delete_label}
          on_cancel={() => set_show_modal_delete_tag_value(false)}
          error={null}

        >
          <div className={'m-2 pb-2'}>
            Deleting this tag will <strong>permanently remove</strong> it from any families it has been applied to.
          </div>
          <div className='m-2'>
            This change will affect all users who have access to &lsquo;{tag_value_to_delete?.value}&rsquo;.
          </div>
        </ConfirmDeleteModal>
      }
      <div className={cn('d-flex justify-content-between', s.tag_group_row)}>
        <div className='mb-2 mt-1'>
          <h5 className='d-inline'>
            {tag.name}
          </h5>
          {/*// @ts-expect-error*/}
          <TextLink
            onClick={change_tag}
            className='ms-2'
            title='Edit tag group'
            no_decoration
          >
            <CogIcon className={null}/>
          </TextLink>
          {/*// @ts-expect-error*/}
          <TextLink
            onClick={check_and_delete_tag}
            className='ms-2'
            title='Delete tag group'
            no_decoration
          >
            <TrashIcon className={null} />
          </TextLink>
        </div>
        {show_index &&
          <span className='d-flex'>
            <span className='mt-2 me-1'>
              Position:
            </span>
            <BaseDropdown
              buttonClassName={s.position_dropdown_button}
              menuClassName={s.position_dropdown}
              label={tag.sort_index}
              right
            >
              {sorting.map((option, index) => (
                <DropdownItem
                  key={index}
                  onClick={() => update_sorting(sorting, tag, index)}
                  active={option.is_current}
                >
                  {option.sort_index}
                </DropdownItem>
              ))}
            </BaseDropdown>
          </span>
        }
      </div>
      {/*//@ts-expect-error*/}
      <Table
        columns={columns}
        data={rows}
        no_data_text='No tag values linked to the category'
        getTdProps={style_cells}
        className='border-1 overflow-hidden'
        manual={true}
      />
      <div className='mt-2 d-flex justify-content-between'>
        {!add_new_label &&
          // @ts-expect-error
          <TextLink
            onClick={() => set_add_new_label(true)}
            className='ms-2'
            no_decoration
          >
            <PlusIcon/>
            <span className={cn('ms-1', cs.underline_on_hover)}>
              Add a new tag in this group
            </span>
          </TextLink>
          }
        {add_new_label &&
          <div className={cn(s.heading,'mb-2 w-75')}>
            {/*//@ts-expect-error*/}
            <EditableTextLink
              link_text={''}
              on_confirm={(name: string) => add_tag_value(name)}
              on_cancel={() => set_add_new_label(false)}
              on_edit={() => set_add_new_label(true)}
              is_edit={add_new_label}
              is_valid={(new_name: string) => is_valid_name(new_name, tag.values.map((v)=> v.value),null)}
              is_clickable={true}
              is_editable={true}
              inputClassName={undefined}
            />
          </div>
        }
      </div>
    </div>
  )
})