export const DEFAULT_TITLE = 'Untitled'

export const CLASSIFIERS_DESCRIPTION = 'shows Training Sets created by you (or shared with you)'
export const LIBRARY_DESCRIPTION = 'shows all non-private Training Sets in your group'
export const TAXONOMIES_DESCRIPTION = 'shows how your taxonomies will appear in the Builder / Wizard (only includes built classifiers)'
export const ADMIN_DESCRIPTION = 'shows all Training Sets in your group, and allows you to share / copy / transfer / delete them'

export const CLASSIFIER_DEBOUNCE_PERIOD = 3000

export const NAME_MAX_LENGTH             = 1024
export const HIGHLIGHT_PHRASE_MAX_LENGTH = 4096
export const NOTES_MAX_LENGTH            = 50000
export const DESCRIPTION_MAX_LENGTH      = 9999

