import {
  NUM_FAMILIES_BY_PVIX_BAND_BY_PORTFOLIO_ID,
  NUM_FAMILIES_BY_PVIX_BAND_BY_TECHNOLOGY_ID,
  PVIX_MEAN_BY_PORTFOLIO_BY_PRIORITY_YEAR_ID,
  PVIX_MEAN_BY_PORTFOLIO_BY_SIZE_ID,
  PVIX_MEAN_BY_PORTFOLIO_ID,
  PVIX_MEAN_BY_TECH_BY_PORTFOLIO_ID,
  PVIX_MEAN_BY_TECH_BY_PRIORITY_YEAR_ID,
  PVIX_MEAN_BY_TECH_ID,
  PVIX_MEAN_BY_TECHNOLOGY_BY_SIZE_ID,
  PVIX_TOTAL_BY_PORTFOLIO_ID,
  PVIX_TOTAL_BY_TECH_BY_PORTFOLIO_ID,
  PVIX_TOTAL_BY_TECH_ID
} from '../spec_ids.js'
import { TAG_ORG, TAG_TECH, TAG_TIME } from './spec_tags.js'
import { KEY_ORGANISATION, KEY_PRIORITY_YEAR, KEY_TECHNOLOGY } from './spec_keys.js'
import { PVIX_SCORE_GROUP_ID } from './spec_group_ids.js'
import {
  DEFAULT_1D_NON_TIMESERIES_AVG_VIEW,
  DEFAULT_1D_NON_TIMESERIES_AVG_VIEWS, DEFAULT_1D_NON_TIMESERIES_VIEW, DEFAULT_1D_NON_TIMESERIES_VIEWS,
  DEFAULT_2D_NON_TIMESERIES_VIEW, DEFAULT_2D_NON_TIMESERIES_VIEWS
} from '../views.js'
import { BAR_STACK_ID, COLUMN_CLUSTER_ID, HEATMAP_ID, SCATTER_ID, TABLE_ID } from '../view_ids.js'
import { has_pvix_scores } from '../../utils/data_availability_utils.js'
import { get_tabular_view_pvix_score_total } from '../../utils/tabular_view_utils.js'

const HELP_ARTICLE = '28889309311251-PVIX-charts-datasets'

export const PVIX_SCORE_GROUP = {
  id: PVIX_SCORE_GROUP_ID,
  name: 'Portfolio Value Index',
  short_name: 'PVIX',
  help_article: HELP_ARTICLE,
  check_if_available: has_pvix_scores,
  children: [
    {
      spec_id: NUM_FAMILIES_BY_PVIX_BAND_BY_PORTFOLIO_ID,
      name: 'PVIX distribution',
      description: 'Distribution of PVIX score by organisation. The percentage of patent families is shown in each score category for each organisation and takes into account all families with PVIX scores (including those with scores of 0).',
      help_article: `${HELP_ARTICLE}#h_622e3692f0`,
      by: [KEY_ORGANISATION],
      tags: [TAG_ORG],
      available_view_ids: [COLUMN_CLUSTER_ID, HEATMAP_ID, TABLE_ID],
      default_view_id: COLUMN_CLUSTER_ID,
    },
    {
      spec_id: NUM_FAMILIES_BY_PVIX_BAND_BY_TECHNOLOGY_ID,
      name: 'PVIX distribution',
      description: 'Distribution of PVIX score by technology. The percentage of patent families is shown in each score category for each technology and takes into account all families with PVIX scores (including those with scores of 0).',
      help_article: `${HELP_ARTICLE}#h_f5e715b3fc`,
      by: [KEY_TECHNOLOGY],
      tags: [TAG_TECH],
      available_view_ids: [COLUMN_CLUSTER_ID, HEATMAP_ID, TABLE_ID],
      default_view_id: COLUMN_CLUSTER_ID,
    },
    {
      spec_id: PVIX_MEAN_BY_PORTFOLIO_ID,
      name: 'average PVIX',
      description: 'The average PVIX score illustrating portfolio strength, split by organisation. The average score is across size and strength in order to enable a direct comparison by organisation.',
      help_article: `${HELP_ARTICLE}#h_7e8aba7967`,
      by: [KEY_ORGANISATION],
      tags: [TAG_ORG],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_AVG_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_AVG_VIEW,
    },
    {
      spec_id: PVIX_MEAN_BY_TECH_ID,
      name: 'average PVIX',
      description: 'The average PVIX score illustrating portfolio strength, split by technology. The average score is across size and strength in order to enable a direct comparison by technology.',
      help_article: `${HELP_ARTICLE}#h_4852de6c06`,
      by: [KEY_TECHNOLOGY],
      tags: [TAG_TECH],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_AVG_VIEWS,
      default_view_id: BAR_STACK_ID,
    },
    {
      spec_id: PVIX_MEAN_BY_TECH_BY_PORTFOLIO_ID,
      name: 'average PVIX',
      description: 'The average PVIX score illustrating portfolio strength, split by organisation and technology. The average score is across size and strength in order to enable a direct comparison by organisation and technology.',
      help_article: `${HELP_ARTICLE}#h_a3b8f460ae`,
      by: [KEY_ORGANISATION, KEY_TECHNOLOGY],
      tags: [TAG_ORG, TAG_TECH],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_VIEW,
    },
    {
      spec_id: PVIX_MEAN_BY_PORTFOLIO_BY_PRIORITY_YEAR_ID,
      name: 'average PVIX',
      description: 'The average PVIX score split by organisation and priority year. It allows you to see when organisations were filing their highest or lowest scoring patents.',
      help_article: `${HELP_ARTICLE}#h_344165ecb8`,
      by: [KEY_ORGANISATION, KEY_PRIORITY_YEAR],
      tags: [TAG_ORG, TAG_TIME],
      available_view_ids: [HEATMAP_ID, TABLE_ID],
      default_view_id: HEATMAP_ID,
    },
    {
      spec_id: PVIX_MEAN_BY_TECH_BY_PRIORITY_YEAR_ID,
      name: 'average PVIX',
      description: 'The average PVIX score split by technology and priority year. It allows you to see when organisations were filing their highest or lowest scoring patents, in each technology area.',
      help_article: `${HELP_ARTICLE}#h_921420e831`,
      by: [KEY_TECHNOLOGY, KEY_PRIORITY_YEAR],
      tags: [TAG_TECH, TAG_TIME],
      available_view_ids: [HEATMAP_ID, TABLE_ID],
      default_view_id: HEATMAP_ID,
    },
    {
      spec_id: PVIX_MEAN_BY_PORTFOLIO_BY_SIZE_ID,
      name: 'average PVIX',
      description: 'The average PVIX score by organisation and size. This chart shows the size of the portfolio in comparison to its strength (average PVIX score).',
      help_article: `${HELP_ARTICLE}#h_381f43164e`,
      by: [KEY_ORGANISATION, 'size'],
      tags: [TAG_ORG],
      available_view_ids: [SCATTER_ID, TABLE_ID],
      default_view_id: SCATTER_ID,
    },
    {
      spec_id: PVIX_MEAN_BY_TECHNOLOGY_BY_SIZE_ID,
      name: 'average PVIX',
      description: 'The average PVIX score by technology and size. This chart shows the number of granted families in the technology in comparison to their strength (average PVIX score).',
      help_article: `${HELP_ARTICLE}#h_f7cb424f55`,
      by: [KEY_TECHNOLOGY, 'size'],
      tags: [TAG_TECH],
      available_view_ids: [SCATTER_ID, TABLE_ID],
      default_view_id: SCATTER_ID,
    },
    {
      spec_id: PVIX_TOTAL_BY_PORTFOLIO_ID,
      name: 'total PVIX',
      description: 'The total PVIX score illustrating portfolio strength, split by organisation. The score is normalised for size and strength, for instance you could have a portfolio of half the size and strength, scoring the same as a portfolio of twice the size and half the strength.',
      help_article: `${HELP_ARTICLE}#h_f1f6f36029`,
      by: [KEY_ORGANISATION],
      tags: [TAG_ORG],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_VIEW,
      get_tabular_view_summary: get_tabular_view_pvix_score_total
    },
    {
      spec_id: PVIX_TOTAL_BY_TECH_ID,
      name: 'total PVIX',
      description: 'The total PVIX score illustrating portfolio strength, split by technology. The score is normalised for size and strength, for instance you could have a portfolio of half the size and strength, scoring the same as a portfolio of twice the size and half the strength.',
      help_article: `${HELP_ARTICLE}#h_34e699c9b9`,
      by: [KEY_TECHNOLOGY],
      tags: [TAG_TECH],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_VIEW,
      get_tabular_view_summary: get_tabular_view_pvix_score_total
    },
    {
      spec_id: PVIX_TOTAL_BY_TECH_BY_PORTFOLIO_ID,
      name: 'total PVIX',
      description: 'The total PVIX score illustrating portfolio strength, split by organisation, and technology. The score is normalised for size and strength, for instance you could have a portfolio of half the size and strength, scoring the same as a portfolio of twice the size and half the strength.',
      help_article: `${HELP_ARTICLE}#h_01HYZ6D18N6QRZ3WTE2B660PSE`,
      by: [KEY_ORGANISATION, KEY_TECHNOLOGY],
      tags: [TAG_ORG, TAG_TECH],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_VIEW,
      get_tabular_view_summary: get_tabular_view_pvix_score_total
    }
  ]
}