import React from 'react'
import cn from 'classnames'

import { CircleCheckedIcon, CircleUncheckedIcon } from '../widgets/IconSet.js'

import cs from '../cipher_styles.module.scss'
import s from './Stepper.module.scss'

const Connector = ({visible, is_completed, className}) => {
  return (
    <div
      className={cn(s.connector, {[s.connector__complete]: is_completed}, {[s.connector__invisible]: !visible}, className)}
    />
  )
}

const Marker = ({is_active, is_completed, on_click, className}) => {

  return (
    <div
      className={cn(s.marker, {[s.marker__active]: is_active}, {[s.marker__complete]: is_completed}, {[cs.cursor_pointer]: on_click != null}, className)}
      onClick={on_click}
    >
      {is_completed && <CircleCheckedIcon />}
      {!is_completed && <CircleUncheckedIcon />}
    </div>
  )
}

const Stepper = (
  {
    steps,
    active_step_id,
    can_continue
  }) => {
  if ((steps || []).length === 0) return

  const step_ids = steps.map(step => step.id)

  const active_step_idx = step_ids.indexOf(active_step_id)
  return (
    <div className='d-flex justify-content-center w-100 position-relative'>
      {steps.map((step, i) => {

        const {label, subtitle, on_click} = step

        const is_active = (i === active_step_idx)
        const is_completed = (i < active_step_idx)

        return (
          <div key={i} style={{width: `${100/steps.length}%`}}>
            <div className='d-flex justify-content-center'>
              <Connector visible={i !== 0} is_completed={is_completed || is_active} className='my-auto'/>
              <Marker is_active={is_active} is_completed={is_completed || (is_active && can_continue)} className='my-auto' on_click={on_click}/>
              <Connector visible={i !== steps.length - 1} is_completed={is_completed || (is_active && can_continue)} className='my-auto'/>
            </div>
            <div className={cn('text-center px-3 pt-1', s.label)}>{label}</div>
            <div className={cn('text-center px-3 d-none d-lg-block', cs.white_space_nowrap, s.subtitle)}>{subtitle}</div>
          </div>
        )
      })}
    </div>
  )
}

export default Stepper