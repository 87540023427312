import React from 'react'
import cn from 'classnames'

import {
  FastBackwardIcon,
  FastForwardIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from '../widgets/IconSet.js'

import s from './CheckboxGroupPaginationControls.module.scss'

const CheckboxGroupPaginationControls = ({
  total_num_items, num_pages, page, handle_change_page, className
}) => {

  const has_single_page = (num_pages != null) && (num_pages === 1)
  const is_first_page = (page === 0)
  const is_last_page = (num_pages != null) && (page === (num_pages - 1))
  const exists_next_page = (num_pages != null) && (page < (num_pages - 1))
  const exists_prev_page = (page !=null) && (page !== 0)

  function go_to_first_page() {
    handle_change_page(0)
  }

  function go_to_last_page() {
    handle_change_page(num_pages - 1)
  }

  function go_to_next_page() {
    handle_change_page(page + 1)
  }

  function go_to_prev_page() {
    handle_change_page(page - 1)
  }

  return (

    <div className={cn(className)}>
      <span
        className={cn(s.icon, s.icon__small, {[s.icon__disabled]: is_first_page}, 'me-1')}
        onClick={go_to_first_page}
      >
        <FastBackwardIcon />
      </span>
      <span
        className={cn(s.icon, s.icon__small, {[s.icon__disabled]: !exists_prev_page}, 'me-auto')}
        onClick={go_to_prev_page}
      >
        <ChevronLeftIcon />
      </span>
      {(total_num_items > 0) &&
        <span className={cn(s.page_label, {[s.page_label__disabled]: has_single_page }, 'me-auto', 'px-1')}>
          Page {page + 1} of {num_pages}
        </span>
      }
      <span
        className={cn(s.icon, s.icon__small, {[s.icon__disabled]: !exists_next_page}, 'me-1')}
        onClick={go_to_next_page}
      >
        <ChevronRightIcon />
      </span>
      <span
        className={cn(s.icon, s.icon__small, {[s.icon__disabled]: is_last_page || (total_num_items === 0)})}
        onClick={go_to_last_page}
      >
        <FastForwardIcon />
      </span>
    </div>

  )
}

export default CheckboxGroupPaginationControls