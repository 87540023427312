import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import qs from 'query-string'
import { withRouter } from 'react-router-dom'
import SplitterLayout from 'react-splitter-layout'
import _ from 'underscore'
import { InputGroup, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

import { withUser } from '../UserContext.js'
import {
  create_new_organisation,
  fetch_organisation_tree_by_id,
  get_all_assignees,
  update_tree_with_parents,
  get_org_id,
  is_assignee,
  get_all_assignee_ids,
  SEARCH_RESULTS_ORDER_OPTIONS,
  SEARCH_RESULTS_ORDER_BY_RELEVANCE,
  SEARCH_RESULTS_ORDER_BY_ORG_ID_DESC
} from '../../utils/organisation_utils.js'

import { GROUPING_CONTEXT } from '../../model/organisation.js'
import { PrimaryButton } from '../widgets/Button.js'
import { MenuIcon, RepeatInLeftIcon, RepeatInRightIcon } from '../widgets/IconSet.js'
import OrgSearchInput from '../orgs/OrgSearchInput.js'
import OrgSearchResultsDisplay from '../orgs/OrgSearchResultsDisplay.js'
import Basket from './Basket.js'
import ErrorModal from '../ErrorModal.js'
import TextLink from '../widgets/TextLink.js'
import EmptyOrganisations from './EmptyOrganisations.js'
import OrgNewModal from '../orgs/OrgNewModal.js'
import FullPageSpinner from './FullPageSpinner.js'
import { useOrgSearch } from '../../hooks/organisation_hooks.js'
import { useToggle } from '../../hooks/general_hooks.js'
import PanelOptions from './PanelOptions.js'
import { get_from_local_storage, save_to_local_storage } from '../../utils/local_storage_utils.js'
import { get_ag_basket_resource } from '../../utils/static_data_utils.js'
import BadSyntaxAlertModal from '../patent_family_list/BadSyntaxAlertModal.js'
import { is_400_error } from '../../utils/axios_utils.js'
import { get_csv_string } from '../../utils/csv_utils.js'
import { TABLE_ID } from '../../model/view_ids.js'
import {
  EXCEL_FILE_EXT,
  fetch_excel_document,
  get_clean_filename,
  MIMETYPE_XLSX,
  trigger_download
} from '../../utils/download_utils.js'
import { RadiobuttonWithLabel } from '../widgets/RadiobuttonWithLabel.js'
import {
  BASKET_S3_KEY_PARAM,
  BASKET_S3_PARAM,
  CIPHER_AG_SHOW_ORG_IDS,
  SOURCE_SEARCH_PARAM,
  TARGET_SEARCH_PARAM
} from '../../constants/ag_tool.js'
import { update_url_with_search_phrase } from '../../utils/url_utils.js'
import Organisation from '../orgs/Organisation.js'

import s from './AssigneeGrouping.module.scss'

const PanelMenuToggle = () => {
  return (
    <DropdownToggle className={cn('px-1 py-0', s.org_options_toggle)}><MenuIcon /></DropdownToggle>
  )
}

const AssigneeGrouping = ({location, history, user}) => {
  document.title = 'Assignee Grouping'

  const cipher_ag_show_organisation_ids = get_from_local_storage(CIPHER_AG_SHOW_ORG_IDS)
  const query_params = qs.parse(location.search)

  const {basket_resource, basket_resource_key} = parse_temp_basket_resource_details_from_url(query_params)

  const [show_page_spinner, set_show_page_spinner] = useState(false)

  const [source_search_phrase, set_source_search_phrase] = useState(decodeURIComponent(query_params[SOURCE_SEARCH_PARAM] || ''))
  const [target_search_phrase, set_target_search_phrase] = useState(decodeURIComponent(query_params[TARGET_SEARCH_PARAM] || ''))
  const [source, set_source] = useState(null)
  const [target, set_target] = useState(null)
  const [exclude_empty_orgs_in_source, set_exclude_empty_orgs_in_source] = useToggle(true)
  const [exclude_empty_orgs_in_target, set_exclude_empty_orgs_in_target] = useToggle(true)
  const [source_order, set_source_order] = useState(SEARCH_RESULTS_ORDER_BY_RELEVANCE)

  const [basket, set_basket] = useState([])
  const [show_basket_spinner, set_show_basket_spinner] = useState(false)
  const [temp_basket, set_temp_basket] = useState(basket_resource)
  const [temp_basket_key, set_temp_basket_key] = useState(basket_resource_key)

  const [add_org_modal, toggle_add_org_modal] = useToggle(false)
  const [empty_orgs_modal, toggle_empty_orgs_modal] = useToggle(false)
  const [show_org_ids, set_show_org_ids] = useState( cipher_ag_show_organisation_ids != null ? cipher_ag_show_organisation_ids : false)

  const [add_new_error, set_add_new_error] = useState(null)
  const [ungroup_error, set_ungroup_error] = useState(null)
  const [changelog_error, set_changelog_error] = useState(null)
  const [changelog_undo_error, set_changelog_undo_error] = useState(null)
  const [fetch_tree_error, set_fetch_tree_error] = useState(null)

  const [show_source_spinner, source_search_results, source_search_error, clear_source_search_error, refresh_source_search] = useOrgSearch(source_search_phrase, true, exclude_empty_orgs_in_source, source_order)
  const [show_target_spinner, target_search_results, target_search_error, clear_target_search_error, refresh_target_search] = useOrgSearch(target_search_phrase, true, exclude_empty_orgs_in_target)

  useEffect(() => {
    if (temp_basket && temp_basket.length > 0) {
      set_show_basket_spinner(true)

      get_ag_basket_resource(temp_basket, temp_basket_key)
        .then(data => {
          const {target_org, basket_items} = data || {}
          set_basket(basket_items || [])
          if (target_org) {
            set_target_search_phrase(target_org)
          }
          set_temp_basket(null)
          set_temp_basket_key(null)
          set_show_basket_spinner(false)
        })
        .catch(() => {
          set_temp_basket(null)
          set_temp_basket_key(null)
          set_show_basket_spinner(false)
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_basket])

  function parse_temp_basket_resource_details_from_url(query_params) {
    if (!query_params[BASKET_S3_PARAM]) {
      return {}
    }

    const new_query = _.omit(query_params, BASKET_S3_PARAM, BASKET_S3_KEY_PARAM)
    history.replace({pathname: location.pathname, search: `?${qs.stringify(new_query)}`})

    return {
      basket_resource: decodeURIComponent(query_params[BASKET_S3_PARAM]),
      ...query_params[BASKET_S3_KEY_PARAM] ? {basket_resource_key: decodeURIComponent(query_params[BASKET_S3_KEY_PARAM])} : {}
    }
  }

  function get_actual_source_results() {
    return source || source_search_results
  }

  function get_actual_target_results() {
    return target || target_search_results
  }

  function update_source_search_phrase(new_search_phrase) {
    if (source_search_phrase === new_search_phrase) {
      return
    }

    update_url_with_search_phrase(new_search_phrase, SOURCE_SEARCH_PARAM, location, history)
    set_source_search_phrase(new_search_phrase)
    set_source(null)
  }

  function update_target_search_phrase(new_search_phrase) {
    if (target_search_phrase === new_search_phrase) {
      return
    }

    update_url_with_search_phrase(new_search_phrase, TARGET_SEARCH_PARAM, location, history)
    set_target_search_phrase(new_search_phrase)
    set_target(null)
  }

  function on_refresh_source_results() {
    set_source(null)
    refresh_source_search()
  }

  function on_refresh_target_results() {
    set_target(null)
    refresh_target_search()
  }

  function add_to_basket({org, parents, parent_tags, flat}) {
    (flat) ? add_to_basket_flat(org, parents, parent_tags) : add_to_basket_as_group(org, parents, parent_tags)
  }

  function add_to_basket_as_group(org, parents, parent_tags) {
    set_basket([...basket, {...org, parents, parent_tags}])
  }

  function get_org_idx(org, results) {
    const org_id = get_org_id(org)
    const org_ids = results.map(item => get_org_id(item))
    return org_ids.indexOf(org_id)
  }

  function add_to_basket_flat(org, parents, parent_tags) {
    if (is_assignee(org)) {
      set_basket([...basket, {...org, parents, parent_tags}])
      return
    }

    const {children} = org

    if (children) {
      const updated_tree = update_tree_with_parents({...org, parents, parent_tags})
      const assignees = get_all_assignees(updated_tree)
      set_basket([...basket, ...assignees])
      return
    }

    set_show_basket_spinner(true)

    fetch_organisation_tree_by_id(get_org_id(org))
      .then(org_tree => {
        const results = get_actual_source_results()
        const idx = get_org_idx(org, results)
        results[idx] = org_tree

        const updated_tree = update_tree_with_parents(org_tree)
        const assignees = get_all_assignees(updated_tree)

        set_source(results)
        set_basket([...basket, ...assignees])
        set_show_basket_spinner(false)
      })
      .catch(error => {
        set_show_basket_spinner(false)
        set_fetch_tree_error(error)
      })
  }

  function on_click_remove_from_basket(i) {
    let new_basket = [...basket]
    new_basket.splice(i, 1)
    set_basket(new_basket)
  }

  function get_all_basket_assignees() {
    let basket_assignees = []

    basket.forEach(item => {
      const ids = get_all_assignee_ids(item)
      basket_assignees = [...basket_assignees, ...ids]
    })

    return basket_assignees
  }

  function get_selected_parents() {
    const selected_parents = (basket || []).map(item => (item.parents))
    const selected_parents_set =  [...new Set(_.flatten(selected_parents))]
    return selected_parents_set
  }

  function check_if_organisation_will_be_empty(org, basket_assignees, empty_orgs=[]) {
    if (empty_orgs.indexOf(org.id) !== -1) return true
    const org_assignees = get_all_assignee_ids(org)
    if (!org_assignees || org_assignees.length === 0 || basket_assignees.length === 0) return false
    return org_assignees.every(v => basket_assignees.includes(v))
  }

  function update_org_in_source_and_target_results(org_tree) {
    const basket_assignees = get_all_basket_assignees()
    const selected_organisations = (basket || []).map(item => (get_org_id(item)))
    const selected_parents = get_selected_parents()

    const _empty_org_ids = []

    if (source_search_results && source_search_results.length > 0) {
      const updated_source_results = get_actual_source_results().map(org => {
        if (get_org_id(org) === get_org_id(org_tree)) {
          delete org_tree.show_children
          return org_tree
        }

        const is_selected_parent = selected_parents.indexOf(get_org_id(org)) > -1
        const is_organisation_becoming_empty = (!is_selected_parent) ? false : check_if_organisation_will_be_empty(org, basket_assignees)
        if (is_organisation_becoming_empty) {
          _empty_org_ids.push(org.id)
        }
        const should_hide = (selected_organisations.indexOf(get_org_id(org)) !== -1) || is_organisation_becoming_empty

        return {...org, ...(should_hide) ? {is_hidden: true} : {}}
      })

      set_source(updated_source_results)
    }

    const target_results = get_actual_target_results()
    if (target_results && target_results.length > 0) {
      const updated_target_results = target_results.map(org => {
        if (get_org_id(org) === get_org_id(org_tree)) {
          return org_tree
        }
        const is_selected_parent = selected_parents.indexOf(get_org_id(org)) > -1
        const is_organisation_becoming_empty = (!is_selected_parent) ? false : check_if_organisation_will_be_empty(org, basket_assignees, _empty_org_ids)
        const should_hide = (selected_organisations.indexOf(get_org_id(org)) !== -1) || is_organisation_becoming_empty

        return {...org, ...(should_hide) ? {is_hidden: true} : {}, ...(is_selected_parent) ? {refresh: true} : {}}
      })
      set_target(updated_target_results)
    }
  }

  function on_merge(org_tree) {
    update_org_in_source_and_target_results(org_tree)
    clear_basket()
    unfreeze()
  }

  function create_organisation(org_to_add) {
    const { name, notes, tags } = org_to_add

    if (!name) {
      toggle_add_org_modal()
      return
    }
    const {user_id} = user
    create_new_organisation({...org_to_add, user_id})
      .then(org_id => {
        const new_org = { name, id: org_id, type: 'organisation', notes, tags}
        toggle_add_org_modal()
        set_target([new_org])
      })
      .catch(error => {
        set_add_new_error(error)
      })
  }

  function clear_basket() {
    set_basket([])
  }

  function on_extract(parent_org, org_tree, should_load) {
    if (!should_load) {
      update_org_in_source_and_target_results(parent_org)
    } else {
      set_target([org_tree])

      const updated_source_results = source_search_results.map(org => {
        if (get_org_id(org) === get_org_id(parent_org)) {
          return parent_org
        }

        return org
      })

      set_source(updated_source_results)
    }

    unfreeze()
  }

  function on_add_child(org_tree) {
    update_org_in_source_and_target_results(org_tree)
    unfreeze()
  }

  function on_edit(parent_id) {
    fetch_organisation_tree_by_id(parent_id)
      .then(org_tree => {
        update_org_in_source_and_target_results(org_tree)
      })
      .catch(error => {
        set_fetch_tree_error(error)
      })
      .finally(unfreeze)
  }

  function on_select_empty_org(org) {
    toggle_empty_orgs_modal()
    set_target([org])
  }

  function freeze() {
    set_show_page_spinner(true)
  }

  function unfreeze() {
    set_show_page_spinner(false)
  }

  function freeze_grouping_actions(should_freeze) {
    return should_freeze ? freeze() : unfreeze()
  }

  function toggle_org_ids_in_ui() {
    const new_show_org_ids_flag = !show_org_ids

    set_show_org_ids(new_show_org_ids_flag)
    save_to_local_storage(CIPHER_AG_SHOW_ORG_IDS, new_show_org_ids_flag)
  }

  const updated_source_results = source || source_search_results
  const updated_target_results = target || target_search_results

  function on_results_export(results=[], search_phrase) {
    if (!results || results.length === 0) return

    freeze()

    const to_fetch = results.map(org => {
      return fetch_organisation_tree_by_id(get_org_id(org))
    })

    Promise.all(to_fetch)
      .then(org_trees => {

        const columns = ['Organisation', 'ID', 'Assignee name']
        let data = []

        org_trees.forEach(org_tree => {
          const { name } = org_tree
          const org_id = get_org_id(org_tree)
          const assignees = get_all_assignees(org_tree)

          const assignees_data = assignees.map(item => {
            const { name, id } = item
            return ['', id, name]
          })

          data = [...data, [name, org_id, ''], ...assignees_data]
        })

        const csv = get_csv_string([columns, ...data])

        const input = {
          title: `Organisations and assignees - results of search for "${search_phrase}"`,
          description: '',
          view_id: TABLE_ID,
          data: csv,
        }

        return fetch_excel_document([input], false, true)
      })


      .then(arraybuffer => {
        unfreeze()
        trigger_download(arraybuffer, MIMETYPE_XLSX, get_clean_filename(search_phrase + '_search' + EXCEL_FILE_EXT))
      })

  }

  function change_source_order(order_id) {
    set_source_order(order_id)
  }


  function order_results(results, order_by) {
    if (!results || results.length === 0) return results

    if (order_by === SEARCH_RESULTS_ORDER_BY_ORG_ID_DESC) {
      return order_results_by_ids(results)
    }
    return results
  }

  function order_results_by_ids(results) {
    return [...results || []].sort((a,b) => (b.id - a.id))
  }

  const is_bad_syntax_error = is_400_error(source_search_error) || is_400_error(target_search_error)

  return (
    <div>
      {source_search_error && !is_bad_syntax_error &&
        <ErrorModal
          error={source_search_error}
          on_hide={clear_source_search_error}
          context='searching for organisations'
        />
      }

      {target_search_error && !is_bad_syntax_error &&
        <ErrorModal
          error={target_search_error}
          on_hide={clear_target_search_error}
          context='searching for organisations'
        />
      }

      {is_bad_syntax_error &&
        <BadSyntaxAlertModal
          on_hide={() => {
            clear_source_search_error()
            clear_target_search_error()
          }}
        />
      }

      {add_new_error &&
        <ErrorModal
          error={add_new_error}
          on_hide={() => {set_add_new_error(null)}}
          context='adding new organisation'
        />
      }

      {ungroup_error &&
        <ErrorModal
          error={ungroup_error}
          on_hide={() => {set_ungroup_error(null)}}
          context='ungrouping org'
        />
      }

      {changelog_error &&
        <ErrorModal
          error={changelog_error}
          on_hide={() => {set_changelog_error(null)}}
          context='fetching change log for user'
        />
      }

      {changelog_undo_error &&
        <ErrorModal
          error={changelog_undo_error}
          on_hide={() => {set_changelog_undo_error(null)}}
          context='reverting changes'
        />
      }

      {fetch_tree_error &&
        <ErrorModal
          error={fetch_tree_error}
          on_hide={() => {set_fetch_tree_error(null)}}
          context='fetching organisation details'
        />
      }

      {add_org_modal &&
        <OrgNewModal
          on_hide={toggle_add_org_modal}
          provisional_name={target_search_phrase}
          on_confirm={create_organisation}
          should_find_similar={true}
          on_select_similar={(org) => { set_target([{...org, show_children: false}]) }}
          FoundOrgDisplay={Organisation}
        />
      }

      {empty_orgs_modal &&
        <EmptyOrganisations
          on_hide={toggle_empty_orgs_modal}
          on_org_select_handler={on_select_empty_org}
        />
      }

      {show_page_spinner &&
        <FullPageSpinner/>
      }

      <SplitterLayout vertical percentage secondaryInitialSize={20} primaryMinSize={50} secondaryMinSize={5}>
        <SplitterLayout primaryIndex={0} percentage primaryMinSize={20} secondaryMinSize={20} customClassName={s.panel}>
          <div className='p-2 h-100'>
            <PanelOptions
              title='Source'

              menu = {
                <UncontrolledDropdown>
                  <PanelMenuToggle />
                  <DropdownMenu>
                    <DropdownItem onClick={() => on_results_export(updated_source_results, source_search_phrase)}>Export source results</DropdownItem>
                    <DropdownItem toggle={false} onClick={set_exclude_empty_orgs_in_source}>{!exclude_empty_orgs_in_source ? 'Exclude' : 'Include'} empty organisation</DropdownItem>
                    <DropdownItem toggle={false}>
                      <div className='d-flex flex-column'>
                        <span>Order source results by:</span>
                        {SEARCH_RESULTS_ORDER_OPTIONS.map((option, i) => {
                          const {id, name} = option

                          const is_selected = (source_order===id)
                          return (
                            <RadiobuttonWithLabel
                              key={i}
                              label={name}
                              is_checked={is_selected}
                              disabled={is_selected}
                              on_click={() => change_source_order(id)}
                            />
                          )
                        })}
                      </div>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem toggle={false} onClick={toggle_org_ids_in_ui}>{show_org_ids ? 'Hide' : 'Show'} organisation ids</DropdownItem>
                    <DropdownItem onClick={toggle_empty_orgs_modal}>Show empty organisations list</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              }
            />
            <div className='d-flex mb-1'>

              <InputGroup>
                <OrgSearchInput
                  on_change_handler={update_source_search_phrase}
                  on_refresh_handler={() => {on_refresh_source_results()}}
                  value={source_search_phrase}
                  show_spinner={show_source_spinner}
                  show_refresh={true}
                  autofocus
                />

                <PrimaryButton title='Apply search to target' outline onClick={() => update_target_search_phrase(source_search_phrase)}>
                  <RepeatInRightIcon/>
                </PrimaryButton>
              </InputGroup>
            </div>

            <div className={s.results_wrapper}>
              <OrgSearchResultsDisplay
                results={order_results(updated_source_results, source_order)}
                search_phrase={source_search_phrase}
                show_spinner={show_source_spinner}
                on_result_check_in={add_to_basket}
                context={{...GROUPING_CONTEXT, source: true, tooltip_suffix: 'grouping_source'}}
                selected_organisations={basket}
                org_ungroup_handler={on_extract}
                add_child_handler={on_add_child}
                edit_handler={on_edit}
                freeze_grouping_actions={freeze_grouping_actions}
                show_org_ids={show_org_ids}
                filter_empty={exclude_empty_orgs_in_source}
              />
            </div>

          </div>
          <div  className='p-2 h-100'>
            <PanelOptions
              title='Target'

              menu = {
                <UncontrolledDropdown>
                  <PanelMenuToggle />
                  <DropdownMenu>
                    <DropdownItem onClick={toggle_add_org_modal}>Add new organisation</DropdownItem>
                    <DropdownItem onClick={() => on_results_export(updated_target_results, target_search_phrase)}>Export target results</DropdownItem>
                    <DropdownItem toggle={false} onClick={set_exclude_empty_orgs_in_target}>{!exclude_empty_orgs_in_target ? 'Exclude' : 'Include'} empty organisation</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem toggle={false} onClick={toggle_org_ids_in_ui}>{show_org_ids ? 'Hide' : 'Show'} organisation ids</DropdownItem>
                    <DropdownItem onClick={toggle_empty_orgs_modal}>Show empty organisations list</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              }
            />
            <div className='d-flex mb-1'>
              <InputGroup>
                <PrimaryButton title='Apply search to source' outline onClick={() => update_source_search_phrase(target_search_phrase)}><RepeatInLeftIcon/></PrimaryButton>

                <OrgSearchInput
                  on_change_handler={update_target_search_phrase}
                  on_refresh_handler={() => {on_refresh_target_results()}}
                  value={target_search_phrase}
                  show_spinner={show_target_spinner}
                  show_refresh={true}
                />

              </InputGroup>
            </div>

            <div className={s.results_wrapper}>
              <OrgSearchResultsDisplay
                results={updated_target_results}
                search_phrase={target_search_phrase}
                show_spinner={show_target_spinner}
                on_result_check_in={on_merge}
                context={{...GROUPING_CONTEXT, target: true,  tooltip_suffix: 'grouping_target'}}
                selected_organisations={basket}
                org_ungroup_handler={on_extract}
                add_child_handler={on_add_child}
                edit_handler={on_edit}
                freeze_grouping_actions={freeze_grouping_actions}
                show_org_ids={show_org_ids}
                filter_empty={exclude_empty_orgs_in_target}
              />
            </div>

          </div>
        </SplitterLayout>

        <div className={cn('h-100 d-flex position-relative', s.basket_wrapper)}>

          <Basket
            items={basket}
            on_remove={on_click_remove_from_basket}
            on_clear_basket={clear_basket}
            show_spinner={show_basket_spinner}
            className='h-100'
          />

          {(basket || []).length > 0 &&
            <div className={cn('p-1', s.basket_link)}>
              <TextLink className='text-endbold' onClick={clear_basket}>[clear all]</TextLink>
            </div>
          }

        </div>

      </SplitterLayout>
    </div>
  )
}

export default withRouter(withUser(AssigneeGrouping))
