import React, { useEffect, useState } from 'react'
import _ from 'underscore'
import cn from 'classnames'
import ReactTable from 'react-table'

import {
  create_new_company_list,
  delete_companies_from_list,
  extract_properties_for_saving_to_list,
  fetch_list_companies_for_basket,
  get_max_companies_allowed_in_list,
  rename_list,
  share_or_unshare_list,
  SHARED_LIST_INFO,
  update_companies_fields,
  update_companies_in_list,
} from '../../utils/company_list_utils.js'
import Spinner from '../widgets/Spinner.js'
import ErrorBody from '../ErrorBody.js'
import EditableTextLink from '../report_management/components/EditableTextLink.js'

import TextLink from '../widgets/TextLink.js'
import { format_integer_with_comma, get_as_map } from '../../utils/utils.js'
import { EditIcon, SimpleErrorIcon } from '../widgets/IconSet.js'
import { PrimaryButton } from '../widgets/Button.js'
import { InfoPopover } from '../widgets/Tooltip.js'
import OrgSearchMultiple from '../orgs/OrgSearchMultiple.js'
import { get_org_as_portfolio_item } from '../../model/portfolio_basket.js'
import { ScrollModal } from '../widgets/Modal.js'
import ErrorModal from '../ErrorModal.js'
import ListNameModal from './ListNameModal.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'
import { CSV_FILE_EXT, get_clean_filename, MIMETYPE_CSV, trigger_download } from '../../utils/download_utils.js'
import { get_csv_string } from '../../utils/csv_utils.js'
import { withUser } from '../UserContext.js'
import { is_aistemos } from '../../utils/user_permissions.js'
import {
  COMPANY_ID_FIELD_ID,
  DISPLAY_NAME_FIELD_ID,
  FIELD_DISPLAY_NAME,
  FIELD_IS_MISSING,
  FIELD_IS_SELECTED,
  FIELD_ORIGINAL_NAME,
  FIELD_SIZE,
  FIELD_SIZE_ACTIVE,
  FIELDS_TO_EXPORT
} from '../../model/saved_org_lists.js'
import { ASCENDING } from '../../model/sort_directions.js'
import SortingColumnHeaderCell from '../patent_family_list/SortingColumnHeaderCell.js'
import { sort_table_data } from '../../utils/item_utils.js'
import CheckboxStatic from '../widgets/CheckboxStatic.js'

import cs from '../cipher_styles.module.scss'
import s from './CompanyListSettings.module.scss'


const CompanyListSettings = ({user, list, on_rename_handler, on_sharing_change_handler, on_delete_handler, on_copy_handler, is_new_list_name_unique}) => {
  const [show_spinner, set_show_spinner] = useState(true)
  const [is_rename, set_is_rename] = useState(false)
  const [is_copy, set_is_copy] = useState(false)

  const [items_in_list, set_items_in_list] = useState(null)
  const [selected_item_ids, set_selected_item_ids] = useState([])
  const [in_edit, set_in_edit] = useState(null)
  const [to_delete, set_to_delete] = useState(false)
  const [to_change_share_status, set_to_change_share_status] = useState(false)

  const [should_show_search, set_should_show_search] = useState(false)
  const [should_fetch_items, set_should_fetch_items] = useState(true)

  const [fetch_error, set_fetch_error] = useState(null)
  const [update_error, set_update_error] = useState(null)
  const [copy_error, set_copy_error] = useState(null)

  const [selected_sort_field_id, set_selected_sort_field_id] = useState(DISPLAY_NAME_FIELD_ID)
  const [selected_sort_direction_id, set_selected_sort_direction_id] = useState(ASCENDING)

  const {company_list_id, list_name, is_shared} = list || {}

  const has_missing_orgs = _.some(items_in_list || [], item => item.is_missing )
  const max_companies_in_list = get_max_companies_allowed_in_list(is_aistemos(user))

  const list_items_sorted = sort_table_data(items_in_list, selected_sort_field_id, selected_sort_direction_id)

  useEffect(() => {
    if (!should_fetch_items) return
    fetch_list_companies_for_basket(company_list_id, true)
      .then(response => {
        const [orgs, missing_orgs] = response

        const items = [...orgs, ...missing_orgs.map(org => {return {...org, is_missing: true}})]

        set_items_in_list(items)
        set_selected_item_ids((missing_orgs || []).map(org => org.company_id))
        set_show_spinner(false)
        set_should_fetch_items(false)
      })
      .catch(err => {
        set_fetch_error(err)
        set_show_spinner(false)
       set_should_fetch_items(false)
      })
  }, [company_list_id, should_fetch_items])

  function is_new_name_valid(new_name) {
    if (new_name == null) return true
    return new_name.trim().length !== 0
  }

  function on_delete() {
    on_delete_handler(list)
  }

  function on_copy(copy_list_name) {
    set_show_spinner(true)
    set_is_copy(false)
    const companies = items_in_list.map(item => ({...item, organisation_id: item.id || null}))
    create_new_company_list(copy_list_name, companies, false)
      .catch(err => {
        set_show_spinner(false)
        set_copy_error(err)
        throw err
      })
      .then(response => {
        const {company_list_id} = response
        on_copy_handler({list_name: copy_list_name, company_list_id})
        set_show_spinner(false)
      })
  }

  function on_list_new_name(new_name) {
    if (!new_name || (new_name.trim().length === 0)) return

    if (new_name === list_name) {
      set_is_rename(false)
      return
    }

    set_show_spinner(true)

    return rename_list(company_list_id, new_name)
      .then(() => {
        set_is_rename(false)
        set_show_spinner(false)
        on_rename_handler(list, new_name)
      })
      .catch(err => {
        set_fetch_error(err)
        set_show_spinner(false)
      })
  }

  function on_list_sharing_status_change() {
    set_to_change_share_status(false)
    set_show_spinner(true)

    return share_or_unshare_list(company_list_id, !is_shared)
      .then(() => {
        set_show_spinner(false)
        on_sharing_change_handler(list, !is_shared)
      })
      .catch(err => {
        set_fetch_error(err)
        set_show_spinner(false)
      })
  }

  function can_add_more_orgs({orgs_to_add=[]}) {
    return items_in_list.length + orgs_to_add.length < max_companies_in_list + 1
  }

  function on_orgs_select(orgs) {
    const {add=[], remove=[]} = orgs

    if (add.length > 0) {
      on_add_selected(add)
    }

    if (remove.length > 0) {
      const org_ids_to_remove = remove.map(org => org.id)
      const company_ids_to_remove = items_in_list.filter(item => org_ids_to_remove.indexOf(item.org_id) > -1).map(item => item[COMPANY_ID_FIELD_ID])

      on_remove_selected(company_ids_to_remove)
    }
  }

  function render_item_select_cell(params) {
    const {original} = params
    const {company_id} = original
    const is_selected = _.contains(selected_item_ids, company_id)
    return (
      <CheckboxStatic
        key={company_id}
        is_checked={is_selected}
        onClick={() => {
          if (is_selected) {
            set_selected_item_ids(selected_item_ids.filter(id => id !== company_id))
          } else {
            set_selected_item_ids([...selected_item_ids, company_id])
          }
        }}
      />
    )
  }

  function render_select_all_cell() {
    const all_company_ids = items_in_list.map(item => item.company_id)
    const is_all_selected = _.every(all_company_ids, company_id => _.contains(selected_item_ids, company_id))
    const has_selections = selected_item_ids.length > 0
    return (
      <CheckboxStatic
        is_checked={is_all_selected}
        is_partial={has_selections && !is_all_selected}
        onClick={() => {
          if (is_all_selected) {
            set_selected_item_ids([])
          } else {
            set_selected_item_ids(all_company_ids)
          }
        }}
      />
    )
  }

  function on_add_selected(orgs=[]) {
    set_show_spinner(true)

    track_report_builder_event(`action="add_orgs" obj="org_list" owner="${is_shared ? 'shared' : 'private'}"`)

    Promise.all(orgs.map(org_to_add => extract_properties_for_saving_to_list(org_to_add)))
      .then((items_to_add) => {
        const companies = [...items_in_list.map(item => ({...item, organisation_id: item.id || null})), ...items_to_add.map(item => ({...item, original_name: item.name}))]
        return update_companies_in_list(company_list_id, companies)
      })
      .then(() => {
        set_should_fetch_items(true)
      })
      .catch((error) => {
        set_update_error(error)
        set_show_spinner(false)
      })
  }

  function on_remove_selected(selected_item_ids=[]) {
    track_report_builder_event(`action="remove_orgs" obj="org_list" owner="${is_shared ? 'shared' : 'private'}"`)
    const filtered_orgs = items_in_list.filter(org => {
      const {company_id} = org
      return selected_item_ids.indexOf(company_id) === -1
    })

    set_show_spinner(true)
    delete_companies_from_list(company_list_id, selected_item_ids)
      .then(() => {
        set_items_in_list(filtered_orgs)
        set_selected_item_ids([])
        set_show_spinner(false)
      })
      .catch((error) => {
        set_update_error(error)
        set_selected_item_ids([])
        set_show_spinner(false)
      })
  }

  function on_display_name_update(params, with_enter_key) {
    const { company_id, field, value } = params

    const item_idx = _.findIndex(list_items_sorted, item => item[COMPANY_ID_FIELD_ID] === company_id)
    const next_to_edit = (with_enter_key && item_idx < list_items_sorted.length -1) ? list_items_sorted[item_idx + 1] : null
    const next_to_edit_id = next_to_edit ? next_to_edit[COMPANY_ID_FIELD_ID] : null

    if (!is_new_name_valid(value) || field !== DISPLAY_NAME_FIELD_ID) {
      set_in_edit(next_to_edit_id)
      return
    }

    set_show_spinner(true)

    let is_update = false
    const updated_items = items_in_list.map(item => {
      if (item[COMPANY_ID_FIELD_ID] === company_id && item[DISPLAY_NAME_FIELD_ID] !== value) {
        is_update = true
        return {...item, name: value}
      }
      return item
    })

    if (!is_update) {
      set_in_edit(next_to_edit_id)
      set_show_spinner(false)
      return
    }
    update_companies_fields(company_list_id, [{company_id, display_name: value}])
      .then(() => {
        set_items_in_list(updated_items)
        set_in_edit(next_to_edit_id)
        set_show_spinner(false)
      })
      .catch((error) => {
        set_show_spinner(false)
        set_items_in_list(updated_items)
        set_update_error(error)
      })
  }

  function set_display_name_in_edit_mode(id) {
    on_cell_edit_start(id)
  }

  function on_cell_edit_start(id) {
    set_in_edit(id)
  }

  function get_display_name_cell(params) {
    const {original} = params
    const {name, is_missing, company_id} = original

    const is_edit_enabled = !(to_delete || to_change_share_status) && (in_edit == null) && !is_missing
    const is_in_edit = (company_id === in_edit)

    if (is_in_edit && show_spinner) {
      return (<Spinner size='sm'/>)
    }

    if (!is_in_edit) {
      return (
        <div className={s.display_name_wrapper}>
          <span>{name}</span>
          {is_edit_enabled &&
            <TextLink
              title='Change name'
              key={`name_${company_id}`}
              onClick={() => {
                set_display_name_in_edit_mode(company_id)
              }}
              className={cn('px-1', s.edit_display_name_icon)}
              no_decoration
            >
              <EditIcon/>
            </TextLink>
          }
        </div>
      )
    }

    return (
      <EditableTextLink
        is_edit={true}
        link_text={name}
        on_confirm={(new_name, with_enter_key) => on_display_name_update({company_id, field: DISPLAY_NAME_FIELD_ID, value: new_name}, with_enter_key)}
        on_cancel={() => set_display_name_in_edit_mode(null)}
        is_valid={(is_new_name_valid)}
        inputClassName={cs.in_table_input}
      />
    )
  }

  function get_missing_cell(params) {
    const {original} = params
    const {is_missing} = original

    if (!is_missing) return null

    return (
      <div
        title={'Missing organisation'}
        className='w-100 text-center'
      >
        <SimpleErrorIcon />
      </div>
    )
  }

  function on_change_sort_field_id_and_sort_direction_id(sort_field_id, sort_direction_id) {
    set_selected_sort_field_id(sort_field_id)
    set_selected_sort_direction_id(sort_direction_id)
  }

  function render_column_header(field) {
    return (
      <SortingColumnHeaderCell
        field={field}
        selected_sort_field_id={selected_sort_field_id}
        selected_sort_direction_id={selected_sort_direction_id}
        on_change_sort_field_id_and_sort_direction_id={on_change_sort_field_id_and_sort_direction_id}
      />
    )
  }

  function handle_on_delete() {
    set_to_delete(true)
    if (in_edit != null) {
      set_in_edit(null)
    }
  }

  function handle_on_export() {
    const field_to_column = get_as_map(columns, 'id')

    const headings = FIELDS_TO_EXPORT.map(field => field_to_column[field].name || '')

    const items = items_in_list.map(item => {
      return FIELDS_TO_EXPORT.map(field => item[field] || '')
    })

    const csv = get_csv_string([headings, ...items])
    const filename = get_clean_filename(`${list_name} list`)

    trigger_download(csv, MIMETYPE_CSV, `${filename}${CSV_FILE_EXT}`)
  }

  const columns = [
    {
      ...FIELD_IS_SELECTED,
      width: 45,
      Header: render_select_all_cell,
      Cell: render_item_select_cell,
      sortable: false
    },
    ...(!has_missing_orgs ? [] : [
      {
        ...FIELD_IS_MISSING,
        width: 30,
        Cell: get_missing_cell
      }
    ]),
    {
      ...FIELD_ORIGINAL_NAME,
      Header: render_column_header(FIELD_ORIGINAL_NAME),
      minWidth: 200,
      sortable: false
    },
    {
      ...FIELD_DISPLAY_NAME,
      minWidth: 200,
      editable: false,
      Header: render_column_header(FIELD_DISPLAY_NAME),
      Cell: get_display_name_cell,
      sortable: false
    },
    {
      ...FIELD_SIZE_ACTIVE,
      Header: render_column_header(FIELD_SIZE_ACTIVE),
      Cell: (params) => format_integer_with_comma(params.original.size_active),
      width: 125,
      sortable: false
    },
    {
      ...FIELD_SIZE,
      Header: render_column_header(FIELD_SIZE),
      Cell: (params) => format_integer_with_comma(params.original.size),
      width: 125,
      sortable: false
    }
  ]

  const is_delete_or_change_status = to_delete || to_change_share_status

  const items_count = (items_in_list || []).length
  const is_list_empty = items_count === 0

  const share_link_label = is_shared ? 'Stop sharing' : 'Share'
  return (
    <div className='w-100 position-relative'>

      <div className='d-flex'>
        <TextLink onClick={() => set_is_rename(true)} className='my-auto' disable={is_delete_or_change_status} title='Rename'>Rename</TextLink>
        <TextLink onClick={() => {set_should_show_search(true)}} className='ms-3 my-auto' disable={is_delete_or_change_status || (items_count  === max_companies_in_list) }>Add companies</TextLink>
        <TextLink onClick={() => set_to_change_share_status(true)} className='ms-3 my-auto' disable={is_delete_or_change_status || is_list_empty} title={share_link_label}>{share_link_label}</TextLink>
        <TextLink onClick={() => set_is_copy(true)} className='ms-3 my-auto' disable={is_delete_or_change_status || is_list_empty} title='Copy'>Copy</TextLink>
        <TextLink onClick={handle_on_export} className='ms-3 my-auto' disable={is_list_empty}>Export as CSV</TextLink>
        <TextLink onClick={handle_on_delete} className='ms-3 my-auto' disable={is_delete_or_change_status} title='Delete'>Delete list</TextLink>
      </div>

      <div>
        <div className={s.spinner_wrapper}>
          {show_spinner &&
            <div><Spinner size='sm'/></div>
          }
          {to_delete &&
            <div className='d-flex justify-content-center my-auto'>
              <div className={cs.font_weight_bold}>Are you sure you want to delete this list?</div>
              <PrimaryButton size='xs' className='ms-2' onClick={() => on_delete()}>Delete list</PrimaryButton>
              <PrimaryButton size='xs' className='ms-2' outline onClick={() => set_to_delete(false)}>Cancel</PrimaryButton>
            </div>
          }
          {to_change_share_status &&
            <div className='d-flex justify-content-center my-auto'>
              <div><span className={cs.font_weight_bold}>Are you sure you want to {`${is_shared ? 'stop sharing' : 'share'} this list`}? </span><InfoPopover interactive={true} placement='bottom'>{SHARED_LIST_INFO}</InfoPopover></div>
              <PrimaryButton size='xs' className='ms-2' onClick={() => on_list_sharing_status_change()}>{`${is_shared ? 'Stop sharing' : 'Share'}`} list</PrimaryButton>
              <PrimaryButton size='xs' className='ms-2' outline onClick={() => set_to_change_share_status(false)}>Cancel</PrimaryButton>
            </div>
          }
        </div>

        {fetch_error &&
          <ErrorBody
            error={fetch_error}
            context={'fetching organisation in the list'}
          />
        }

        {update_error &&
          <ErrorModal
            error={update_error}
            context={'updating organisations in the list'}
            on_hide={() => set_update_error(null)}
          />
        }

        {copy_error &&
          <ErrorModal
            error={copy_error}
            context={'making list copy'}
            on_hide={() => set_copy_error(null)}
          />
        }

        {items_in_list && (items_in_list.length > 0) &&
          <>
            <TextLink
              className='d-flex'
              disable={to_delete || (selected_item_ids.length === 0)}
              onClick={() => on_remove_selected(selected_item_ids)}>
              Remove selected companies
            </TextLink>
            <ReactTable
              className='border-0 -striped w-75'
              manual={true}
              showPagination={false}
              filterable={false}
              sortable={false}
              columns={columns}
              data={list_items_sorted}
              minRows={5}
            />
          </>
        }

        {items_in_list && items_count === 0 &&
          <div className='my-3 text-center'>There are no companies in this list</div>
        }

      </div>

      {is_rename &&
        <ListNameModal
           initial_text={list_name}
           on_submit={on_list_new_name}
           submit_label='Rename'
           on_cancel={() => set_is_rename(false)}
           is_name_unique={(name) => is_new_list_name_unique(name, [list])}
           title={'Rename list'}
        />
      }

      {is_copy &&
        <ListNameModal
           initial_text={list_name + ' (copy)'}
           on_submit={on_copy}
           submit_label='Create list'
           on_cancel={() => set_is_copy(false)}
           is_name_unique={is_new_list_name_unique}
           title={`Make a copy of "${list_name}"`}
        />
      }

      {should_show_search &&
        <ScrollModal
          is_open={true}
          on_hide={() => set_should_show_search(false)}
          title={(<div className='d-flex'>
            <span>Add companies to &quot;{list_name}&quot; ({(items_in_list || []).length}/{max_companies_in_list})</span>
            {show_spinner &&
              <div className='ms-1 my-auto d-flex'><Spinner size='sm'/></div>
            }
          </div>)}
        >
          <OrgSearchMultiple
            portfolio_basket={(items_in_list || []).map(item => get_org_as_portfolio_item(item))}
            update_basket_handler={on_orgs_select}
            blockClassName='h-100 position-relative'

            enable_remove_action={true}
            enable_ignore_action={false}
            can_add_orgs_handler={can_add_more_orgs}
            heroClassName={s.org_search_pane}
            inputContainerClassName={s.org_search_input_container}
            inputClassName={s.org_search_input}
          />
        </ScrollModal>
      }

    </div>
  )
}

export default withUser(CompanyListSettings)