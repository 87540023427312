import React, { useContext, useEffect, useState } from 'react'
import qs from 'query-string'
import _ from 'underscore'
import * as FullStory from '@fullstory/browser'
import cn from 'classnames'

import ContainerFullWidth from '../ContainerFullWidth.js'
import {
  change_user_selected_anonymous_mode,
  CONTEXT,
  create_search_name,
  find_similar_families,
  generate_input_id, mask_knn_id,
  get_ids_and_count_from_url,
  get_similar_families_search_data,
  has_user_selected_anonymous_mode,
  is_history_enabled,
  parse_text_input,
  save_similar_families_search_input,
  update_similar_families_input_name,
  update_similar_families_input_saved_status,
  update_similar_families_search_settings,
  get_search_unique_name,
  get_default_count_setting,
  transform_search_to_tech_explorer_context,
  update_similar_families_input_context,
  save_default_count_setting_to_ls,
} from '../../utils/knn_search.js'
import InputUnparsed from './InputUnparsed.js'
import { is_400_error, is_404_error } from '../../utils/axios_utils.js'
import { PRIORITY_DATE_FIELD_ID, SIMILARITY_SCORE_ID } from '../../model/patent_family_fields.js'
import { ASCENDING, DESCENDING } from '../../model/sort_directions.js'
import { DEFAULT_PAGE_SIZE } from '../../model/patent_family_list_page_sizes.js'
import Spinner from '../widgets/Spinner.js'
import { withUser } from '../UserContext.js'
import { withRouter } from 'react-router-dom'
import InputParsed from './InputParsed.js'
import { get_patent_families_by_ids } from '../../utils/patent_family_list_utils.js'
import {
  get_as_map,
  get_cipher_hostname,
  is_aws_dev_environment,
  is_dev_environment,
  is_ln_environment
} from '../../utils/utils.js'
import ErrorBody from '../ErrorBody.js'
import BadInputAlertModal from './BadInputAlertModal.js'
import BadSyntaxAlertModal from '../patent_family_list/BadSyntaxAlertModal.js'
import KNNSearchResults from './SearchResultsDisplay.js'
import { BUILD_REPORT, BUILD_SIMILAR_FAMILIES_REPORT, KNN } from '../../constants/paths.js'
import { has_tracking_disabled, has_utt, is_cipher_engineering, is_creator } from '../../utils/user_permissions.js'
import { get_family_id_from_cipher_family_id } from '../../utils/family_view_utils.js'
import { UserSettingsContext } from '../UserSettingsContext.js'
import { get_save_knn_searches } from '../../utils/user_settings_utils.js'
import WelcomeModal from './WelcomeModal.js'
import ErrorModal from '../ErrorModal.js'
import { is_family_details_view_page } from '../../utils/viewer_utils.js'
import { FS_ORG_ID } from '../../utils/full_story.js'
import { set_custom_url, set_referrer_url, track_knn_events, track_knn_search_input } from '../../utils/tracking_utils.js'
import { prepare_pathname_for_pendo } from '../../utils/pendo_utils.js'

import s from './TechExplorerContainer.module.scss'

const TechExplorerContainer = (
  {
    history,
    location,
    match,
    user,
  }) => {
  document.title = 'Classification: TechDiscovery'
  const has_fs_enabled = !(is_dev_environment() || is_aws_dev_environment() || is_ln_environment() || has_tracking_disabled(user))

  const user_anonymous_mode_preference = has_user_selected_anonymous_mode()

  const user_has_utt = has_utt(user)

  const { user_settings } = useContext(UserSettingsContext)
  const should_save_knn_searches = get_save_knn_searches(user_settings)

  const {pathname, search} = location || {}

  const is_details_view = is_family_details_view_page(pathname)

  const url_params = qs.parse(search)
  const [family_id_query_param, count_query_param] = get_ids_and_count_from_url(location)

  const { params } = match || {}
  const { id_from_url } = get_params(params)

  function get_params(params) {
    const { id: id_from_url } = params || {}

    return {
      id_from_url,
    }
  }

  const [anonymous_mode_enabled, set_anonymous_mode_enabled] = useState(user_anonymous_mode_preference != null ? user_anonymous_mode_preference : false)

  const [is_fetching, set_is_fetching] = useState(false)
  const [should_fetch, set_should_fetch] = useState(false)
  const [should_filter, set_should_filter] = useState(false)
  const [id, set_id] = useState(null)
  const [search_id, set_search_id] = useState(null)
  const [revision_id, set_revision_id] = useState(null)
  const [search_query_params, set_search_query_params] = useState(null)

  const [is_input_creator, set_is_input_creator] = useState(false)

  const [family_ids, set_family_ids] = useState(null)
  const [blocklist, set_blocklist] = useState(null)
  const [text, set_text] = useState(null)
  const [count, set_count] = useState(null)

  const [search_name, set_search_name] = useState(null)
  const [is_search_saved, set_is_search_saved] = useState(false)
  const [unparsed_text, set_unparsed_text] = useState(null)
  const [bookmarked_family_ids, set_bookmarked_family_ids] = useState(null)
  const [include_search_families_in_results, set_include_search_families_in_results] = useState(true)

  const [similarity_results, set_similarity_results] = useState(null)
  const [search_results, set_search_results] = useState(null)
  const [size, set_size] = useState(null)
  const [search_phrase, set_search_phrase] = useState(null)
  const [sort, set_sort] = useState({sort_field_id: SIMILARITY_SCORE_ID, sort_direction_id: DESCENDING})

  const [similarity_search_error, set_similarity_search_error] = useState(null)
  const [similarity_search_input_error, set_similarity_search_input_error] = useState(null)
  const [similarity_search_input_not_fount_error, set_similarity_search_input_not_fount_error] = useState(null)
  const [similarity_search_update_input_error, set_similarity_search_update_input_error] = useState(null)
  const [patfam_search_error, set_patfam_search_error] = useState(null)
  const [patfam_search_bad_syntax_error, set_patfam_search_bad_syntax_error] = useState(null)

  const history_enabled = is_history_enabled({should_save_knn_searches, anonymous_mode_enabled})

  useEffect(() => {

    if (!has_fs_enabled) return

    const is_fs_init = FullStory.isInitialized()

    if (!is_fs_init) {
      FullStory.init({ orgId: FS_ORG_ID })
      const {group} = user || {}
      const group_name = (group || [])[0] || '/Unknown group'

      FullStory.setUserVars({
        displayName: `${group_name} user`,
        role: is_cipher_engineering(user) ? 'Cipher engineering' : 'user'
      })
    }

    if (is_fs_init) {
      FullStory.restart()
    }

    return () => {
      if (has_fs_enabled) {
        FullStory.shutdown()
      }
    }
  }, [has_fs_enabled, user])

  useEffect(() => {
    if ((family_id_query_param != null) && !is_fetching) {
      set_is_fetching(true)

      get_search_unique_name(family_id_query_param, history_enabled)
        .then(({name}) => {
          const input =  {pat_fam_ids: [get_family_id_from_cipher_family_id(family_id_query_param)], k: count_query_param || get_default_count_setting()  }

          const settings = {
            sort_field_id: SIMILARITY_SCORE_ID,
            sort_direction_id: DESCENDING
          }

          return save_similar_families_search_input(
            {input, settings, name},
            generate_input_id(),
            history_enabled ? generate_input_id() : null,
            history_enabled ? generate_input_id() : null,
            history_enabled
          )}
        )
        .then(({id}) => {
          track_knn_events(`context="${CONTEXT}" action="knn_search" obj="family_id" id="${mask_knn_id(id)}"`)
          history.replace(`${KNN}/${id}`)
        })
    }

    if ((family_id_query_param == null) && !_.isEmpty(url_params)) {
      history.replace(KNN)
    }

  }, [family_id_query_param, count_query_param, history_enabled, url_params, history, is_fetching])

  useEffect(() => {
    const has_id = id_from_url != null

    set_id(id_from_url)
    set_should_fetch(has_id)

    if (has_id) {
      const custom_url = `${get_cipher_hostname()}${KNN}/${mask_knn_id(id_from_url)}`
      set_custom_url(custom_url)
      set_referrer_url(custom_url)
      prepare_pathname_for_pendo(custom_url)
    }
  }, [id_from_url])

  useEffect(() => {
    if (!should_fetch) return

    set_is_fetching(true)

    get_similar_families_search_data(id)
      .then(data => {
        const {context} = data

        if (!context) return data

        if (history_enabled) {
          return transform_search_to_tech_explorer_context(id)
        }

        return update_similar_families_input_context(id, null)
          .then(() => {
            return data
          })
      })
      .then(({ input, settings, user_id: input_user_id, name, is_saved, search_id, revision_id }) => {
        const {size, search_phrase, sort_field_id, sort_direction_id, bookmarked, included, excluded} = settings || {}
        const {
          pat_fam_ids: family_ids_from_saved_input,
          text: text_from_saved_input,
          blocklist: blocklist_from_saved_input,
          k
        } = input || {}

        set_search_query_params(input)
        set_search_id(search_id)
        set_revision_id(revision_id)
        const {user_id} = user || {}
        set_is_input_creator(input_user_id === user_id)

        set_family_ids(included || family_ids_from_saved_input)
        set_text(text_from_saved_input)
        set_blocklist(excluded || blocklist_from_saved_input)
        set_count(k)
        set_unparsed_text(null)

        set_bookmarked_family_ids(bookmarked || [])
        set_search_name(name || create_search_name(input))
        set_is_search_saved(is_saved)
        set_size(size || DEFAULT_PAGE_SIZE)
        set_search_phrase(search_phrase)
        set_sort({...sort, ...sort_field_id ? {sort_field_id} : {}, ...sort_direction_id ? {sort_direction_id} : {}})

        const updated_blocklist = [...blocklist_from_saved_input || [], ...include_search_families_in_results ? [] : (family_ids_from_saved_input || [])]

        return find_similar_families(family_ids_from_saved_input, text_from_saved_input, k, updated_blocklist)
      })
      .then(similarity_search_results => {
        set_similarity_results(similarity_search_results)
        set_should_filter(true)
      })
      .catch(err => {
        set_is_fetching(false)

        const is_40x_error = is_400_error(err) || is_404_error(err)
        if (is_40x_error) {
          if (is_404_error(err)) {
            set_similarity_search_input_not_fount_error(err)
          } else {
            set_similarity_search_input_error(err)
          }
        } else {
          set_similarity_search_error(err)
        }
      })

    set_should_fetch(false)

  }, [should_fetch, id, family_ids, text, count, blocklist, sort, include_search_families_in_results, history_enabled, user])

  useEffect(() => {
    if (!should_filter) return

    if ((similarity_results || []).length === 0) {
      set_should_filter(false)
      set_is_fetching(false)
      return
    }

    set_is_fetching(true)

    const family_ids = (similarity_results || []).map(p => p.patfam_id)

    get_patent_families_by_ids(family_ids, search_phrase, family_ids.length, 0, PRIORITY_DATE_FIELD_ID, ASCENDING)
      .catch(err => {
        if (is_400_error(err)) {
          set_patfam_search_bad_syntax_error(err)
        } else {
          set_patfam_search_error(err)
        }
        set_search_results([])
        set_is_fetching(false)
        throw err
      })
      .then((families_data_search_results) => {
        const { searchResults } = families_data_search_results

        const similarity_search_results_by_patfam_id = get_as_map(similarity_results, 'patfam_id')

        const search_results_extended_by_similarity = (searchResults || []).map(family => {
          const {patFamId} = family || {}

          return {
            ...family || {}, ...similarity_search_results_by_patfam_id[patFamId] || {}
          }
        })

        set_search_results(search_results_extended_by_similarity)
        set_is_fetching(false)
      })

    set_should_filter(false)
  }, [should_filter, search_phrase, similarity_results])

  function toggle_anonymous_mode() {
    const new_anonymous_mode_enabled = !anonymous_mode_enabled

    set_anonymous_mode_enabled(new_anonymous_mode_enabled)
    change_user_selected_anonymous_mode(new_anonymous_mode_enabled)

    track_knn_events(`context="${CONTEXT}" action="set_privacy_mode" value="${new_anonymous_mode_enabled}" id="${mask_knn_id(id)}"`)
  }

  function on_change_sort_field_id(sort_field_id) {
    return update_or_save_settings({...build_settings(), sort_field_id})
      .then(() => set_sort({...sort, sort_field_id}))
  }

  function on_change_sort_direction_id(sort_direction_id) {
    return update_or_save_settings({...build_settings(), sort_direction_id})
      .then(() => set_sort({...sort, sort_direction_id}))
  }

  function on_change_sort_field_id_and_sort_direction_id(sort_field_id, sort_direction_id) {
    return update_or_save_settings({...build_settings(), sort_field_id, sort_direction_id})
      .then(() => set_sort({sort_field_id, sort_direction_id}))
  }

  function on_change_page_size(size) {
    return update_or_save_settings({...build_settings(), size})
      .then(() => set_size(size))
  }

  function on_change_count(count) {
    track_knn_events(`context="${CONTEXT}" action="set_k" value="${count}" id="${mask_knn_id(id)}"`)
    save_default_count_setting_to_ls(count)
    set_count(count)
  }

  function on_toggle_include_search_families_in_results() {
    set_include_search_families_in_results(!include_search_families_in_results)

    if (similarity_results != null) {
      return update_or_save_settings(build_settings())
        .then(() => {set_should_fetch(true)})
    }
  }

  function on_add_to_family_ids({family_id, component}) {
    if (!family_id) return

    if ((blocklist || []).indexOf(family_id) > -1) {
      set_blocklist(blocklist.filter(item => item !== family_id))
    }
    if ((bookmarked_family_ids || []).indexOf(family_id) > -1) {
      set_bookmarked_family_ids(bookmarked_family_ids.filter(item => item !== family_id))
    }

    const is_selected = (family_ids || []).indexOf(family_id) > -1

    const updated_family_ids = is_selected ? family_ids.filter(item => item !== family_id) : [...family_ids || [], family_id]

    track_knn_events(`context="${CONTEXT}" action="relevant" value="${!is_selected}" component="${component}" id="${mask_knn_id(id)}"`)

    set_family_ids(updated_family_ids)
    return update_or_save_settings({...build_settings(), included: updated_family_ids})
  }

  function on_add_to_blocklist({family_id, component}) {
    if (!family_id) return

    if ((family_ids || []).indexOf(family_id) > -1) {
      set_family_ids(family_ids.filter(item => item !== family_id))
    }
    if ((bookmarked_family_ids || []).indexOf(family_id) > -1) {
      set_bookmarked_family_ids(bookmarked_family_ids.filter(item => item !== family_id))
    }

    const is_selected = (blocklist || []).indexOf(family_id) > -1
    const updated_family_ids = is_selected ? blocklist.filter(item => item !== family_id) : [...blocklist || [], family_id]

    track_knn_events(`context="${CONTEXT}" action="irrelevant" value="${!is_selected}" component="${component}" id="${mask_knn_id(id)}"`)

    set_blocklist(updated_family_ids)
    return update_or_save_settings({...build_settings(), excluded: updated_family_ids})
  }

  function on_add_to_bookmarked({family_id, component}) {
    if (!family_id) return

    if ((family_ids || []).indexOf(family_id) > -1) {
      set_family_ids(family_ids.filter(item => item !== family_id))
    }

    if ((blocklist || []).indexOf(family_id) > -1) {
      set_blocklist(blocklist.filter(item => item !== family_id))
    }

    const is_selected = (bookmarked_family_ids || []).indexOf(family_id) > -1
    const updated_family_ids = is_selected ? bookmarked_family_ids.filter(item => item !== family_id) : [...bookmarked_family_ids || [], family_id]

    track_knn_events(`context="${CONTEXT}" action="bookmarked" value="${!is_selected}" component="${component}" id="${mask_knn_id(id)}"`)

    set_bookmarked_family_ids(updated_family_ids)
    return update_or_save_settings({...build_settings(), bookmarked: updated_family_ids})
  }

  function on_change_search_phrase(new_search_phrase) {

    if (search_phrase === new_search_phrase) return

    track_knn_events(`context="${CONTEXT}" action="filter_results" value="boolean_search" id="${mask_knn_id(id)}"`)

    set_search_phrase(new_search_phrase)

    set_search_results(null)
    set_patfam_search_error(null)
    set_similarity_search_error(null)

    const new_request_data = {...build_settings(), search_phrase: new_search_phrase}

    set_should_filter(true)

    return update_or_save_settings(new_request_data)
  }

  function on_change_search_name(new_name) {
    if (is_input_creator) {
      track_knn_events(`context="${CONTEXT}" action="rename" id="${mask_knn_id(id)}"`)
      return update_similar_families_input_name(id, new_name)
        .then(() => {
          set_search_name(new_name)
        })
        .catch(error => {
          set_similarity_search_update_input_error(error)
        })
    } else {
      set_is_search_saved(true)
      return save_input(search_query_params, build_settings(), new_name, false)
        .then(({id}) => {
          track_knn_events(`context="${CONTEXT}" action="rename" id="${mask_knn_id(id)}"`)
        })
    }
  }

  function on_save_search() {

    const new_saved_flag = !is_search_saved

    if (is_input_creator) {
      track_knn_events(`context="${CONTEXT}" action="pin" value="${new_saved_flag}" id="${mask_knn_id(id)}"`)
      return update_similar_families_input_saved_status(id, new_saved_flag)
        .then(() => {
          set_is_search_saved(!is_search_saved)
        })
        .catch(error => {
          set_similarity_search_update_input_error(error)
        })
    } else {
      set_is_search_saved(true)
      return save_input(search_query_params, build_settings(), search_name, true)
        .then(({id}) => {
          track_knn_events(`context="${CONTEXT}" action="pin" value="true" id="${mask_knn_id(id)}"`)
        })
    }
  }

  function parse_additional_text() {
    if ((unparsed_text || '') !== '') return parse_text_input(unparsed_text)

    return Promise.resolve(null)
  }

  function merge_new_text({new_text, current_text, text_idx_to_edit}) {
    if ((text_idx_to_edit == null) || ((new_text || []).length === 0) || ((current_text || []).length === 0)) {
      return [...current_text || [], ...new_text || []]
    }

    const updated_text = current_text.map((item, i) => {
      if (i === text_idx_to_edit) return new_text[0]
      return item
    })

    return [...updated_text, ...new_text.slice(1)]

  }

  function do_fetch_results({text_idx_to_edit}) {

    set_patfam_search_error(null)
    set_similarity_search_error(null)

    set_is_fetching(true)

    parse_additional_text()
      .then(response => {
        set_similarity_results(null)
        set_search_results(null)

        const {query} = response || {}

        const { pat_fam_ids: new_pat_fam_ids, text: new_text_objs } = query || {}

        track_knn_search_input(query || {}, id)

        const updated_text = merge_new_text({new_text: new_text_objs, current_text: text, text_idx_to_edit})

        const query_params = {
          pat_fam_ids: [...family_ids || [], ...new_pat_fam_ids || []],
          text: updated_text,
          k: count || get_default_count_setting(),
          blocklist: blocklist || []
        }

        const new_settings = _.omit(build_settings(), ['included', 'excluded'] )

        return save_input(query_params, new_settings, search_name, !history_enabled ? false : is_search_saved)
      })
      .then(({id}) => {
        track_knn_events(`context="${CONTEXT}" action="update_results" id="${mask_knn_id(id)}"`)
      })
      .catch(error => {
        set_is_fetching(false)
        set_similarity_search_input_error(error)
      })
  }

  function save_input(input, settings, name, is_saved) {
    const new_id = generate_input_id()
    const new_search_id = !history_enabled ? null : (is_input_creator ? search_id : generate_input_id())
    const new_revision_id = history_enabled ? generate_input_id() : null

    return save_similar_families_search_input(
      {
        input,
        settings,
        name: name || null,
        is_saved: is_saved != null ? is_saved : false
      },
      new_id,
      new_search_id,
      new_revision_id,
      history_enabled
    )
      .then(({id}) => {
        history.replace(`${KNN}/${id}`)
        set_id(id)
        set_should_fetch(true)

        return {id}
      })
  }

  function update_settings(settings) {
    return update_similar_families_search_settings(settings, id)
      .catch(error => {
        set_similarity_search_update_input_error(error)
      })
  }

  function update_or_save_settings(settings) {
    if (!history_enabled) {
      return revision_id != null ? save_input(search_query_params, settings) : update_settings(settings)
    }

    return is_input_creator ? update_settings(settings) : save_input(search_query_params, settings, search_name, is_search_saved)
  }

  function on_search_restart() {
    history.push(`${KNN}`)

    set_is_fetching(false)
    set_id(null)
    set_search_id(null)
    set_revision_id(null)
    set_search_query_params(null)
    set_is_input_creator(false)
    set_family_ids(null)
    set_blocklist(null)
    set_text(null)
    set_unparsed_text(null)
    set_count(null)
    set_search_phrase(null)
    set_similarity_results(null)
    set_search_results(null)
    set_include_search_families_in_results(true)

    set_similarity_search_input_error(null)
    set_similarity_search_error(null)
    set_patfam_search_error(null)
    set_patfam_search_bad_syntax_error(null)
  }

  function build_settings() {
    const {
      pat_fam_ids: family_ids_from_input,
      blocklist: blocklist_from_input,
    } = search_query_params || {}

    return {
      ...(bookmarked_family_ids || []).length > 0 ? {bookmarked: bookmarked_family_ids} : {},
      ...!_.isEqual(family_ids_from_input || [], family_ids || []) ? {included: family_ids} : {},
      ...!_.isEqual(blocklist_from_input || [], blocklist || []) ? {excluded: blocklist} : {},
      ...(search_phrase || '') !== '' ? {search_phrase} : {},
      ...size != null ? {size} : {},
      ...sort
    }
  }

  const clustered_report_path = `${BUILD_SIMILAR_FAMILIES_REPORT}?id=${id}`
  const utt_report_path = `${clustered_report_path}&utt=true`
  const {sort_field_id, sort_direction_id } = sort

  const show_search_start = (id == null) && (family_id_query_param == null)

  if (!is_creator(user)) {
    return (
      <ContainerFullWidth>
        You do not have permission to view this page.
      </ContainerFullWidth>
    )
  }

  return (
    <div className={cn('w-100', s.block, {[s.__dark_bg]: show_search_start})}>

      {should_save_knn_searches == null &&
        <WelcomeModal />
      }

      {show_search_start &&
        <InputUnparsed
          should_save_knn_searches={should_save_knn_searches}
          history_enabled={history_enabled}
          anonymous_mode_enabled={anonymous_mode_enabled}
          toggle_anonymous_mode={toggle_anonymous_mode}
        />
      }

      {id && !is_details_view &&
        <InputParsed
          base_path={`${KNN}/${id}`}
          current_id={id}
          search_query_params={search_query_params}
          family_ids={family_ids}
          blocklist={blocklist}
          bookmarked={bookmarked_family_ids}
          text={text}
          count={count}

          families_list={search_results}
          unparsed_text={unparsed_text}
          include_search_families_in_results={include_search_families_in_results}
          search_name={search_name}
          is_saved={is_search_saved}
          should_save_knn_searches={should_save_knn_searches}
          anonymous_mode_enabled={anonymous_mode_enabled}

          on_change_text={set_text}
          on_change_count={on_change_count}
          on_change_unparsed_text={set_unparsed_text}

          on_add_to_family_ids={on_add_to_family_ids}
          on_add_to_blocklist={on_add_to_blocklist}
          on_add_to_bookmarked={on_add_to_bookmarked}
          on_change_search_name={on_change_search_name}
          on_save_search_handler={on_save_search}

          on_toggle_include_search_families_in_results={on_toggle_include_search_families_in_results}
          on_fetch_search_results={do_fetch_results}
          on_search_restart={on_search_restart}
          toggle_anonymous_mode={toggle_anonymous_mode}

          topClassName='mt-2'
          bottomClassName='mb-3'
        />
      }

      {id &&
        <ContainerFullWidth>

          {!is_details_view &&
            <div className='mt-2'>
              {is_fetching &&
                <Spinner />
              }
            </div>
          }

          {!is_fetching && search_results &&
            <KNNSearchResults
              current_id={id}
              families_list={search_results}
              family_ids={family_ids}
              blocklist={blocklist}
              bookmarked={bookmarked_family_ids}

              base_path={`${KNN}/${id}`}
              build_report_from_list_path={`${BUILD_REPORT}?knn_search_input_id=${id}`}
              build_clustered_report_from_list_path={clustered_report_path}
              build_utt_report_from_list_path={utt_report_path}
              sort_field_id={sort_field_id || SIMILARITY_SCORE_ID}
              sort_direction_id={sort_direction_id || DESCENDING}
              size={size}
              search_phrase={search_phrase}

              export_file_name={`${search_name} export`}

              on_change_sort_field_id_handler={on_change_sort_field_id}
              on_change_sort_direction_id_handler={on_change_sort_direction_id}
              on_change_sort_field_id_and_sort_direction_id_handler={on_change_sort_field_id_and_sort_direction_id}
              on_change_page_size_handler={on_change_page_size}
              on_change_search_phrase_handler={on_change_search_phrase}

              on_add_to_family_ids={on_add_to_family_ids}
              on_add_to_blocklist={on_add_to_blocklist}
              on_add_to_bookmarked={on_add_to_bookmarked}

              create_report_button_title={`Build ${user_has_utt ? 'UTT' : 'clustered'} report from search results`}
              quick_report_path={user_has_utt ? utt_report_path : clustered_report_path}
            />
          }

          {similarity_search_error &&
            <ErrorBody
              context={'searching for similar families'}
              error={similarity_search_error}
            />
          }

          {patfam_search_error &&
            <ErrorBody
              context={'searching for families data'}
              error={patfam_search_error}
            />
          }

          {similarity_search_update_input_error &&
            <ErrorModal
              error={similarity_search_update_input_error}
              on_hide={() => set_similarity_search_update_input_error(null)}
              context='updating search params'
            />
          }

          {similarity_search_input_not_fount_error &&
            <div>
              Search input could not be found. Please start again.
            </div>
          }

          {similarity_search_input_error &&
            <BadInputAlertModal
              error={similarity_search_input_error}
              on_hide={() => set_similarity_search_input_error(null)}
            />
          }

          {patfam_search_bad_syntax_error &&
            <BadSyntaxAlertModal
              on_hide={() => set_patfam_search_bad_syntax_error(null)}
            />
          }

        </ContainerFullWidth>
      }
    </div>
  )
}

export default withRouter(withUser(TechExplorerContainer))
