import React from 'react'
import { DropdownItem } from 'reactstrap'

import { RadiobuttonWithLabel } from '../widgets/RadiobuttonWithLabel.js'
import BaseDropdown from '../widgets/BaseDropdown.js'
import Label from '../widgets/Label.js'

const ALLOW_MULTI_LABEL_TEXT = 'Allow overlapping technologies'
const EXCLUDE_MULTI_LABEL_TEXT = 'Group in mutually exclusive technologies'

const MultiLabelOptionsWrapper = ({className, labelClassName, children}) => {
  return (
    <div className={className}>
      <Label className={labelClassName}>Multi label:</Label>
      {children}
    </div>
  )
}

const MultiLabelOptionsDropdown = (
  {
    technology_multi_label,
    update_technology_multi_label,
  }) => {

  const label = technology_multi_label ? ALLOW_MULTI_LABEL_TEXT : EXCLUDE_MULTI_LABEL_TEXT

  return (
    <BaseDropdown
      label={label}
      right={false}
      menuClassName='w-auto'
    >
      <DropdownItem
        tag='div'
        active={!technology_multi_label}
        onClick={() => update_technology_multi_label(false)}
      >
        {EXCLUDE_MULTI_LABEL_TEXT}
      </DropdownItem>

      <DropdownItem
        tag='div'
        active={technology_multi_label}
        onClick={() => update_technology_multi_label(true)}
      >
        {ALLOW_MULTI_LABEL_TEXT}
      </DropdownItem>
    </BaseDropdown>
  )
}

const MultiLabelOptionsRadiobuttons = (
  {
    technology_multi_label,
    update_technology_multi_label,
  }) => {
  return (
    <>
      <div className='mb-1'>
        <RadiobuttonWithLabel
          is_checked={!technology_multi_label}
          on_click={() => update_technology_multi_label(false)}
          label={EXCLUDE_MULTI_LABEL_TEXT}
        />
      </div>

      <RadiobuttonWithLabel
        is_checked={technology_multi_label}
        on_click={() => update_technology_multi_label(true)}
        label={ALLOW_MULTI_LABEL_TEXT}
      />
    </>
  )
}


const MultiLabelOptions = (
  {
    technology_multi_label,
    update_technology_multi_label,

    show_as_dropdown,

    className,
    labelClassName

 }) => {

  const props = {
    technology_multi_label,
    update_technology_multi_label
  }

  return (
    <MultiLabelOptionsWrapper
      className={className}
      labelClassName={labelClassName}
    >
      {show_as_dropdown &&
        <MultiLabelOptionsDropdown
          {...props}
        />
      }

      {!show_as_dropdown &&
        <MultiLabelOptionsRadiobuttons
          {...props}
        />
      }

    </MultiLabelOptionsWrapper>
  )
}

export default MultiLabelOptions