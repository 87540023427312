import React, { useState } from 'react'
import cn from 'classnames'

import { PrimaryButton } from '../widgets/Button.js'
import TextLink from '../widgets/TextLink.js'
import { TrashIcon } from '../widgets/IconSet.js'
import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'
import { send_grouping_suggestion } from '../../utils/organisation_utils.js'
import { send_error_to_sentry } from '../../utils/sentry_utils.js'
import { InfoPopover } from '../widgets/Tooltip.js'
import Spinner from '../widgets/Spinner.js'

import s from './OrgGroup.module.scss'

const OrgGroup = ({on_cancel, on_submit, orgs=[], on_remove_org}) => {

  const [name, setName] = useState('')
  const [show_spinner, set_show_spinner] = useState(false)
  const [should_send_feedback, set_should_send_feedback] = useState(false)

  function on_change_from_input(event) {
    const group_name = event.target.value
    setName(group_name)
  }

  function do_cancel() {
    setName('')
    on_cancel()
  }

  function do_submit() {
    if (!should_send_feedback) {
      return on_submit(name)
    }

    set_show_spinner(true)
    send_grouping_suggestion(name, orgs)
      .then(() => {
        set_show_spinner(false)
        on_submit(name)
      })
      .catch(error => {
        send_error_to_sentry(error, {}) //don't notify the user
        set_show_spinner(false)
        on_submit(name)
      })
  }

  function get_items() {
    const items = orgs.map((item,i) => (

      <div className='d-flex justify-content-between mb-1' key={i}>
        <span>{item.name}</span>
        <TextLink className='ms-2' onClick={() => on_remove_org(i)}>
          <TrashIcon />
        </TextLink>
      </div>

    ))
    return items
  }

  const items = get_items()
  const is_name = name.length > 0

  return (
    <div>
      <div className={cn('p-2', s.header_wrapper)}>
        <h5>Custom group</h5>
      </div>

      <div className={cn('d-flex justify-content-between p-2', s.section_header)}>
        <div className='d-flex'>
          <span className='me-1'>Organisations</span>
        </div>
        <TextLink onClick={do_cancel}>Reset</TextLink>
      </div>

      <div className={cn('p-2', s.section)}>
        {items}

        <div className={s.feedback_select}>
          {!show_spinner &&
            <div className='d-flex align-items-center h-100'>
              <CheckboxAndLabel
                label='Request corporate tree update'
                is_checked={should_send_feedback}
                on_click={() => set_should_send_feedback(!should_send_feedback)}
                is_disabled={show_spinner}
              />
              <InfoPopover
                placement='bottom'
                is_in_modal={true}
                buttonClassName='ms-1 my-auto'
              >
                Select this option if you would like to request this grouping to be permanently added to Classification
              </InfoPopover>
            </div>
          }

          {show_spinner &&
            <div className='d-flex align-items-center h-100'>
              <div className={cn('ms-1', s.message)}>Sending request</div>
              <div><Spinner size='xs'/></div>
            </div>
          }
        </div>


        <div className='d-flex'>
          <input
            type='text'
            autoComplete='off'
            autoCorrect='off'
            spellCheck='false'
            placeholder='Enter a name'
            onChange={on_change_from_input}
            value={name}
            autoFocus
            className='form-control'
            disabled={show_spinner}
          />
          <PrimaryButton className='ms-1' disabled={!is_name || show_spinner} onClick={do_submit}>Add</PrimaryButton>
        </div>
      </div>
    </div>
  )
}

export default OrgGroup
