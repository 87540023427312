import React, { useEffect, useState } from 'react'

import { PrimaryButton } from '../widgets/Button.js'
import { file_details, is_big_file, is_too_big_file } from '../../utils/custom_search_utils.js'
import {
  CSV_FILE_EXT,
  EXCEL_FILE_EXT,
  EXCEL_MICROSOFT_FILE_EXT,
  MIMETYPE_MS_XLSX,
  MIMETYPE_XLSX,
  TSV_FILE_EXT
} from '../../utils/download_utils.js'
import ConfirmModal from '../ConfirmModal.js'

const NO_FILE_SELECTED = 'No file chosen'
const DEFAULT_FILES_ACCEPTED = [CSV_FILE_EXT, TSV_FILE_EXT, EXCEL_FILE_EXT, EXCEL_MICROSOFT_FILE_EXT, MIMETYPE_XLSX, MIMETYPE_MS_XLSX].join(',')


export const FileInputUpload = ({className, on_change_file_upload, file_ext_accepted, max_size_accepted}) => {

  const hidden_file_input = React.useRef(null)
  const [file_name, set_file_name] = useState(NO_FILE_SELECTED)
  const [file_to_upload, set_file_to_upload] = useState(null)
  const [needs_confirmation, set_needs_confirmation] = useState(false)

  const ext_accepted = file_ext_accepted ? file_ext_accepted.join(',') : DEFAULT_FILES_ACCEPTED
  const can_file_be_processed = file_to_upload!=null && !is_too_big_file(file_to_upload, max_size_accepted)

  useEffect(() => {
    if (file_to_upload != null && !is_big_file(file_to_upload)) {
      on_change_file_upload(file_to_upload)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file_to_upload])

  useEffect(() => {
    if (file_to_upload && file_name !== NO_FILE_SELECTED) {
      set_needs_confirmation(is_big_file(file_to_upload))
    }
  }, [file_name, file_to_upload])

  function on_click() {
    hidden_file_input.current.click()
  }

  function on_change(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0]
      set_file_name(file.name)
      set_file_to_upload(file)
    } else {
      set_file_name(NO_FILE_SELECTED)
      set_file_name(null)
    }
  }

  function on_modal_cancel() {
    set_file_name(NO_FILE_SELECTED)
    set_needs_confirmation(false)
  }

  function on_modal_confirm() {
    set_needs_confirmation(false)
    if (file_to_upload != null) {
      on_change_file_upload(file_to_upload)
    }
  }

  return (
    <div className={className}>
      {on_change_file_upload &&
        <>
          <PrimaryButton onClick={on_click} outline>
            Upload file
          </PrimaryButton>
          <input
            type='file'
            ref={hidden_file_input}
            onChange={on_change}
            className='d-none'
            accept={ext_accepted}
          />
          {file_name !== NO_FILE_SELECTED &&
            <span className={'p-2'}>{file_name}</span>
          }
        </>
      }
      {needs_confirmation &&
        <ConfirmModal
          title={'Large upload'}
          on_cancel={can_file_be_processed? on_modal_cancel : null}
          on_confirm={can_file_be_processed? on_modal_confirm : on_modal_cancel}
          confirm_label={can_file_be_processed? 'Confirm' : 'OK'}
        >
          {can_file_be_processed &&
            <div>
              The file to upload - {file_details(file_to_upload)} - is of a considerable size, the matching may take up some time to process.
            </div>
          }
          {!can_file_be_processed &&
            <div>
              The file to upload is too big to process.
            </div>
          }
        </ConfirmModal>
      }
    </div>
  )
}