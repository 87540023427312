import React from 'react'
import cn from 'classnames'

import PatentSamplePopup from './PatentSamplePopup.js'
import LitigationSamplePopup from './LitigationSamplePopup.js'
import AddressDisplay from './AddressDisplay.js'

import s from './OrgDetails.module.scss'

const OrgDetails = ({org, level, className}) => {
  const is_top_level = level === 0

  function get_address(org) {
    const {address} = org
    return (address && address !== '') ? address : null
  }

  return (
    <div className={cn('d-flex flex-wrap', s.wrapper, className)}>
      <div className={cn(s.icon_wrapper, 'me-1')}>
        <PatentSamplePopup
          org={org}
        />
      </div>

      <div className={cn(s.icon_wrapper, 'me-1')}>
        <LitigationSamplePopup
          org={org}
        />
      </div>

      {is_top_level &&
        <div className={cn(s.address_wrapper, 'me-1')}>
          <AddressDisplay
            address={get_address(org)}
          />
        </div>
      }

    </div>
  )
}

export default OrgDetails
