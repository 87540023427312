import React from 'react'

import TextLink from '../widgets/TextLink.js'
import { withUser } from '../UserContext.js'
import Label from '../widgets/Label.js'

const OrgSetsByGroup = ({ user, group, on_org_set_select_handler, className }) => {
  const { label, sets } = group

  return (
    <div className={className}>
      <Label className='mb-2'>{label}</Label>
      <div className='d-flex flex-column'>
        {sets.map((set, i) => {
          const { label, permissions_endpoint } = set
          const can_display = permissions_endpoint ? permissions_endpoint({user}) : true

          if (!can_display) return null

          return (<TextLink key={i} className='me-3 mb-1' onClick={() => on_org_set_select_handler(group, set)}>{label}</TextLink>)
        })}
      </div>
    </div>
  )
}

export default withUser(OrgSetsByGroup)