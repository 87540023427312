import React, {useState} from 'react'
import cn from 'classnames'
import {remove_not_allowed_chars_from_text} from '../../../utils/name_utils.js'
import {is_aistemos} from '../../../utils/user_permissions.js'
import {InputWithAutofocus} from '../../widgets/InputWithAutofocus.js'
import Modal from '../../widgets/Modal.js'
import Spinner from '../../widgets/Spinner.js'

import {PrimaryButton} from '../../widgets/Button.js'
import {withUser} from '../../UserContext.js'


export interface CreateProjectModalProps{
  user?: any,
  on_hide: Function,
  on_create_project: Function,
  is_creating_project: boolean
}

const CreateProjectModal = (props: CreateProjectModalProps) => {
  const [new_project_name, set_new_project_name] = useState('')

  function render() : JSX.Element {
    return (
      // @ts-ignore
      <Modal
        on_hide={props.on_hide}
        title={`Create a new project`}
        close_label='Close'
        size='sm'
        primary_button={
          <PrimaryButton
            onClick={() => props.on_create_project(new_project_name)}
            title='Create'
            disabled={props.is_creating_project}
          >
            {'Create'}
          </PrimaryButton>
        }
        footer={props.is_creating_project ? <Spinner/> : null}
      >
        <InputWithAutofocus
          value={new_project_name}
          on_change={(event: any) => set_new_project_name(remove_not_allowed_chars_from_text(event.target.value))}
          invalid={!new_project_name || new_project_name.length === 0 || new_project_name.length > 255}
          validation_text={undefined}
          on_key_down={undefined}
          on_key_up={undefined}
          auto_focus={true}
          disabled={!is_aistemos(props.user) || props.is_creating_project}
        />
        <div className={cn('mt-1')}>Enter a new project name between 1 and 255 characters.</div>
      </Modal>
    )
  }

  return render()
}

export default withUser(CreateProjectModal)