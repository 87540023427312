import React from 'react'
import cn from 'classnames'

import { RemoveIcon } from './IconSet.js'

import s from './CloseIconButton.module.scss'

const CloseIconButton = ({on_click, small, className}) => {
  return (
    <span
      onClick={on_click}
      className={cn(s.close_btn, {[s.close_btn__small]: small}, className)}>
      <RemoveIcon />
    </span>
  )
}

export default CloseIconButton