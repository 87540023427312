import React, { useState, useEffect, useRef } from 'react'
import cn from 'classnames'

import Spinner from '../widgets/Spinner.js'
import { get_spec_group_and_view } from '../../model/main_items.js'
import { get_processed_data_and_key_dims, is_data_empty } from '../../utils/column_data_utils.js'
import NoDataInSelection from '../NoDataInSelection.js'
import { useOnScreen } from '../../hooks/general_hooks.js'
import DatasetSelectToggle, { DatasetSelectedMark } from './DatasetSelectToggle.js'
import { SPEC_KEYS_BY_ID } from '../../model/spec_groups/spec_keys.js'
import { format_dataset_name_for_thumbnail } from '../../utils/viewer_utils.js'
import ChartIcon from './ChartIcon.js'
import { get_filtered_disputes } from '../../utils/disputes_utils.js'

import s from './DatasetThumbnail.module.scss'

export const DatasetShowMore = ({count, spec, on_dataset_zoom}) => {
  const { spec_id } = spec
  return (
    <div className={cn('d-flex', s.block, s.show_more)} onClick={() => on_dataset_zoom(spec_id)}>
      <div className='text-center m-auto'>
        <div className={s.show_more__count}>+<span>{count}</span></div>
        <div>more</div>
      </div>
    </div>
  )
}

export const NoDatasets = ({message, className}) => {
  return (
    <div className={cn(className)}>
      {message}
    </div>
  )
}

const DatasetThumbnail = (
  {
    spec,
    is_selected,
    on_dataset_zoom,
    on_dataset_select,
    on_dataset_deselect,
    fetch_obj,
    is_zoomed,
    show_selected_icon,

    ref_data,
    deref_data,
    internal_report_id,
    report_series_sort,
    minimal_selections,
    selections,
    data_creation_date,
    show_charts_in_thumbnails,
    thumbnail_className
  }) => {

  const thumbnail_ref = useRef()
  const is_visible = useOnScreen(thumbnail_ref)

  const [show_spinner, set_show_spinner] = useState(true)

  useEffect(() => {
    let did_cancel = false

    if (!show_charts_in_thumbnails) return

    window.setTimeout(() => {
      // In the next cycle, render the page.
      // This can sometimes take a while (for very large numbers of DOM elements).
      // So at least the user will see a spinner meanwhile.
      if (!did_cancel) {
        set_show_spinner(false)
      }
    }, 1)

    return () => {
      did_cancel = true
    }
  }, [show_charts_in_thumbnails])


  useEffect(() => {
    if (is_zoomed && !is_visible) {
      thumbnail_ref.current.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_zoomed])

  const { name, short_name, by=[], spec_id } = spec
  const { spec: spec_def, view } = get_spec_group_and_view(spec)
  const { view_id, ViewComponent } = view || {}
  const { to_fetch, is_fetching, data, error } = fetch_obj || {}

  const {filter_by_dispute_type} = spec_def

  // Filter and limit data/keys
  const [processed_data, processed_key_dims, original_key_dims] = !data ? [null, null] : get_processed_data_and_key_dims(spec_def, view_id, data_creation_date, spec, data, ref_data, deref_data, report_series_sort, true)

  const is_disputes_dataset = (filter_by_dispute_type === true)
  const disputes = is_disputes_dataset && processed_data ? get_filtered_disputes({data: processed_data, deref_data, item: spec}) : []

  const empty = !data ? null : is_data_empty(data, original_key_dims, selections, spec)

  const is_renderable = !show_spinner && (data && !empty)

  function get_slicing_names() {
    return by.map(item => {
      const { name, short_name } = SPEC_KEYS_BY_ID[item] || {}
      return short_name || name || item || ''
    }).join(' & ')
  }

  const slice_label = get_slicing_names()

  const subject = short_name || name

  const should_show_selected_icon = show_selected_icon != null ? show_selected_icon : true

  return (
    <div className={cn(s.block, [{[s.__zoomed]: is_zoomed}], thumbnail_className)} onClick={() => {on_dataset_zoom(spec_id)}} ref={thumbnail_ref}>
      <div className={cn('text-center', s.name_wrapper)}>
        <div className={cn(s.subject, {'pe-3': subject.length > 13 && subject.length < 19 && is_selected})}>{format_dataset_name_for_thumbnail(subject)}</div>
        <div className={s.slice}>{slice_label}</div>
      </div>


      <div className='px-1 position-relative'>
        {((show_spinner || to_fetch || is_fetching) && show_charts_in_thumbnails) && <div className='text-center pt-3'><Spinner /></div>}
        {error && <span>Could not fetch data due to an error</span>}
        {empty && show_charts_in_thumbnails &&
          <NoDataInSelection className='text-center mt-2'/>
        }
        {(is_renderable && ViewComponent && show_charts_in_thumbnails) &&
          <ViewComponent
            internal_report_id={internal_report_id}
            selections={selections}
            minimal_selections={minimal_selections}
            spec={spec_def}
            data={processed_data}
            key_dims={processed_key_dims}
            item={spec}
            is_thumbnail={true}
            deref_data={deref_data}
            report_series_sort={report_series_sort}
            data_creation_date={data_creation_date}
            thumbnail_className={s.icon}
            disputes={disputes}
          />
        }

        {!show_charts_in_thumbnails &&
          <ChartIcon
            view_id={view_id}
            className={cn('mt-3 text-center', s.icon)}
          />
        }


      </div>

      <DatasetSelectToggle
        spec_id={spec_id}
        is_selected={is_selected}
        on_dataset_select={on_dataset_select}
        on_dataset_deselect={on_dataset_deselect}
        className={cn(s.spec_header_control, s.add_or_remove_link)}
      />

      {is_selected && should_show_selected_icon &&
        <span className={cn(s.spec_header_control, s.selected_icon)}><DatasetSelectedMark className /></span>
      }
    </div>
  )
}

export default DatasetThumbnail