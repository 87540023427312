import { useRef, useEffect, useState, useCallback } from 'react'

/**
 * Returns the previous value (similar to how prevProps used to work in class components),
 */
export function usePrevious(value) {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

export function useDebounce(value, delay) {
  const [debounced_value, set_debounced_value] = useState(value)

  useEffect(
    () => {
      const handler = setTimeout(() => { set_debounced_value(value) }, delay)
      return () => { clearTimeout(handler) }
    },
    [value, delay]
  )
  return debounced_value
}

export function useToggle(initialValue) {
  const [toggleValue, setToggleValue] = useState(initialValue)
  const toggler = useCallback(() => {setToggleValue(!toggleValue)}, [toggleValue])

  return [toggleValue, toggler]
}

export function useInterval(callback, delay, first_run) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Run callback if first_run
  useEffect(() => {
    if (first_run) {
      savedCallback.current()
    }
  }, [first_run])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export function useOnScreen(ref) {
  const [is_intersecting, set_is_intersecting] = useState(false)

  useEffect(() => {
    if (!ref) {
      return
    }

    const observer = new IntersectionObserver(([entry]) => {
      set_is_intersecting(entry.isIntersecting)
    })

    observer.observe(ref.current)
    // Remove the observer as soon as the component is unmounted
    return () => { observer.disconnect() }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return is_intersecting
}


const DOUBLE_CLICK_DELAY = 250

export function useClick({on_single_click: onSingleClick, on_multiple_click: onMultipleClick}) {
  const [clicks, setClicks] = useState(0)

  useEffect(() => {
    let singleClickTimer
    if (clicks === 1) {
      singleClickTimer = setTimeout(function () {
        onSingleClick()
        setClicks(0)
      }, DOUBLE_CLICK_DELAY)
    } else if (clicks === 2) {
      onMultipleClick()
      setClicks(0)
    }

    return () => clearTimeout(singleClickTimer)

  }, [clicks, onSingleClick, onMultipleClick])

  return (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'click') {
      setClicks(clicks + 1)
    }
  }
}

