import React from 'react'
import { NavTab, NavWrapper } from '../widgets/NavTab.js'
import { TAB_PERMISSIONS, TAB_REPORTS_AND_FILES } from '../../constants/project.js'

const ProjectNavigation = ({current_tab, on_click_tab, can_edit, className}) => {
  return (
      <NavWrapper className={className}>
        <NavTab
          is_active={current_tab === TAB_REPORTS_AND_FILES}
          on_click={() => on_click_tab(TAB_REPORTS_AND_FILES)}
        >
          Project content
        </NavTab>
        {can_edit &&
          <NavTab
            is_active={current_tab === TAB_PERMISSIONS}
            on_click={() => on_click_tab(TAB_PERMISSIONS)}
            className='ms-4'
          >
            Project permissions
          </NavTab>
        }
      </NavWrapper>
    )
}

export default ProjectNavigation