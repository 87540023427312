import React from 'react'
import { ChartSetsDisplay } from './ChartSetsDisplay.js'
import { PrimaryButton } from '../../widgets/Button.js'
import { ScrollModal } from '../../widgets/Modal.js'


const SaveSetsModal = (
  {
    is_open,
    on_hide,
    sets,
    sets_to_highlight,
    sets_to_warn,
    on_save,
    title
  }) => {
  return (
    <ScrollModal
      is_open={is_open}
      on_hide={on_hide}
      footer={(<PrimaryButton onClick={on_save}>Save</PrimaryButton>)}
      title={title || 'Preview of chart sets after update'}
      close_label='Cancel'
    >
      <ChartSetsDisplay
        sets={sets || []}
        sets_to_highlight={sets_to_highlight || []}
        sets_to_warn={sets_to_warn || []}
        className='me-3 my-auto'
      />
    </ScrollModal>
  )
}

export default SaveSetsModal