import React from 'react'
import Modal from './Modal.js'
import { PrimaryButton } from './Button.js'
import ErrorBody from '../ErrorBody.js'

interface ConfirmDeleteModalProps {
  title: string,
  on_confirm: Function,
  on_cancel: Function,
  children: any,
  error: any,

}

export const ConfirmDeleteModal = ({title, on_confirm, on_cancel, children, error}: ConfirmDeleteModalProps) => {
  return (
    //@ts-expect-error
    <Modal
      title={title}
      no_close_button={true}
      on_hide={on_cancel}
      primary_button={<PrimaryButton onClick={on_cancel}>{'Cancel'}</PrimaryButton>}
      footer={<PrimaryButton onClick={on_confirm} outline={true}>{'Delete'}</PrimaryButton>}
    >

      {children}

      {error &&
      <ErrorBody error={error}/>
      }
    </Modal>
  )
}