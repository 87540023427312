import React from 'react'
import cn from 'classnames'
import { Input } from 'reactstrap'

import s from './NoResizeTextArea.module.scss'

const NoResizeTextArea = ({id, value, placeholder, on_change, rows_count=1, inner_ref, className}) => {

  const input_ref = inner_ref || React.createRef()
  return (
    <Input
      type='textarea'
      id={id}
      value={value || ''}
      placeholder={placeholder}
      onChange={(event) => on_change(event.target.value)}
      className={cn(s.input, className)}
      rows={rows_count}
      innerRef={input_ref}
      autoComplete='off'
    />
  )
}

export default React.forwardRef((props, ref) => <NoResizeTextArea inner_ref={ref} {...props} />)