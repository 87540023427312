import React from 'react'

import { Label } from './FamilyDetailsLabel.js'
import { to_date } from '../../utils/utils.js'
import { FAMILY_STATUS_EXPIRED_ID, STATUS_TO_STATUS_LABEL } from '../../constants/family_status.js'

export const FamilyDates = ({family}) => {
  const { priorityDate, grantedDate, expiryDate } = family

  function get_expired_label() {
    const { status } = family
    const is_expired = (status === STATUS_TO_STATUS_LABEL[FAMILY_STATUS_EXPIRED_ID])

    return `Expiry${(is_expired || !grantedDate) ? '' : ' (est.)'}`
  }

  return (
    <div>
      <div className='d-lg-flex'>
        <Label inline>Priority</Label>
        <div>{to_date(priorityDate, '')}</div>
      </div>

      <div className='d-lg-flex'>
        <Label inline>Granted</Label>
        <div>{to_date(grantedDate, 'Not yet granted')}</div>
      </div>

      <div className='d-lg-flex'>
        <Label inline>{get_expired_label()}</Label>
        <div>{(grantedDate) ? to_date(expiryDate, '') : 'N/A' }</div>
      </div>
    </div>
  )
}