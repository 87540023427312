import React from 'react'
import { MinusIcon, PlusIcon } from './IconSet.js'
import cn from 'classnames'

import TextLink from './TextLink.js'
import { FormFeedback } from './FormFeedback.js'

import s from './NumberInputStepper.module.scss'


const NumberInputStepper = ({min, max, value, on_change, is_strict_range, invalid, validation_text, className, disabled}) => {

  const can_decrement = !disabled && (!is_strict_range || (min && min < value))
  const can_increment = !disabled && (!is_strict_range || (max && max > value))

  return (
    <div className='d-block'>
      <div className={cn(
        s.stepper,
        {
          [s.stepper__invalid]: invalid,
          [s.stepper__disabled]: disabled
        },
        className
      )}>
        <TextLink
          className={cn(
            s.decrement,
            s.button,
            {[s.button__disabled]: !can_decrement}
          )}
          onClick={() => on_change(value - 1)}
          disable={!can_decrement}
          no_decoration
        >
          <MinusIcon/>
        </TextLink>
        <div>
          {value}
        </div>
        <TextLink
          className={cn(
            s.increment,
            s.button,
            {[s.button__disabled]: !can_increment}
          )}
          onClick={() => on_change(value + 1)}
          disable={!can_increment}
          no_decoration
        >
          <PlusIcon/>
        </TextLink>
      </div>
      <FormFeedback
        valid={!invalid}
        validation_text={validation_text}
      />
    </div>
  )
}

export default NumberInputStepper