import React, { useRef, useState } from 'react'
import { DropdownItem } from 'reactstrap'

import SearchBar from '../widgets/SearchBar.js'
import { contains } from '../../utils/utils.js'
import BaseDropdown from '../widgets/BaseDropdown.js'
import ScrollableList from '../widgets/ScrollableList.js'

export const SearchableDropdown = ({selected_value, label, items, on_change_handler, disabled, enable_search}) => {
  const [search_phrase, set_search_phrase] = useState(null)

  const search_input_ref = useRef()

  function get_items_to_display() {
    if (!enable_search || !search_phrase) return items

    const search_phrase_clean = search_phrase.toLowerCase()

    return items.filter(item => {
      const {name} = item
      return contains(name.toLowerCase(), search_phrase_clean)
    })
  }

  const filtered_items = get_items_to_display()

  return (
    <BaseDropdown
      disabled={disabled}
      label={selected_value || label || ''}
      buttonClassName='w-100'
    >
      {enable_search &&
        <>
          <SearchBar
            className='m-2'
            placeholder={'Search'}
            search_input_ref={search_input_ref}
            search_input={search_phrase || ''}
            on_change_search_input={set_search_phrase}
            no_enter_trigger={true}
            is_search_valid={true}
            autofocus={true}
          />
          <DropdownItem divider className='mb-1'/>
        </>
      }
      <ScrollableList>
        {filtered_items.map((item, i) => (
          <DropdownItem key={i} onClick={() => on_change_handler(item.id)} active={selected_value === item.name}>
            {item.name}
          </DropdownItem>
        ))}
      </ScrollableList>
    </BaseDropdown>
  )
}
