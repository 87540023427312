import React from 'react'
import cn from 'classnames'
import { DropdownItem, Label } from 'reactstrap'

import BaseDropdown from '../../widgets/BaseDropdown.js'

import s from './CustomClusteredOptions.module.scss'

export const CSV_FORMAT = 'csv'
export const TSV_FORMAT = 'tsv'

export const CustomClusteredOptions = ({className, data_format, set_data_format}) => {

  return (
    <div className={cn('d-flex', className)}>
      <Label className='my-auto'>Data format:</Label>
      <BaseDropdown
        label={data_format}
        buttonClassName={cn('ms-1', s.dropdown_toggle)}
      >
        <DropdownItem onClick={() => set_data_format(CSV_FORMAT)} active={data_format === CSV_FORMAT}>
          {CSV_FORMAT}
        </DropdownItem>
        <DropdownItem onClick={() => set_data_format(TSV_FORMAT)} active={data_format === TSV_FORMAT}>
          {TSV_FORMAT}
        </DropdownItem>
      </BaseDropdown>
    </div>
  )
}