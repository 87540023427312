import React from 'react'

const ReportBasketContext = React.createContext({})

export function withReportBasket(Component) {
  return function ComponentWithReportBasket(props) {
    return (
      <ReportBasketContext.Consumer>
        {basket => <Component
          {...props}
          portfolio_basket={basket.portfolio_basket}
          portfolio_basket_sizes={basket.portfolio_basket_sizes}
          portfolio_basket_orgs_total_size={basket.portfolio_basket_orgs_total_size}
          classifiers={basket.classifiers}
          org_group={basket.org_group}
          technology_partitioning={basket.technology_partitioning}
        />}
      </ReportBasketContext.Consumer>
    )
  }
}

export default ReportBasketContext