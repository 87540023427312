import React from 'react'
import cn from 'classnames'

import cs from '../cipher_styles.module.scss'

import { TriangleDownIcon } from './IconSet.js'

const DropdownChevron = ({className, is_down_arrow=true}) => {
  return (
    <span
      className={cn(
        {[cs.rotate_180]: !is_down_arrow},
        className
      )}
    >
      <TriangleDownIcon/>
    </span>
  )
}

export default DropdownChevron