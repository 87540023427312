import React from 'react'
import cn from 'classnames'

import LabelledSlider from '../LabelledSlider.js'
import CalendarDatePicker from './CalendarDatePicker.js'
import { get_date_obj_as_date_string, get_years_extent_from_date_string_extent } from '../../utils/date_range_utils.js'

/**
 * Extent and date_range are 2-elements arrays of date string in YYYY-MM-DD format
 * i.e. ['2012-01-23', '2018-11-31']
 * The first element represents 'from' (inclusive), the second 'to' (also inclusive).
 *
 * The component assumes that 'extent' and 'date_range' are always non-null
 */
const DateRangeSelectorWithSliderAndCalendars = ({ extent, date_range, show_slider_labels, on_change_date_range, className, sliderWrapperClassName, datesWrapperClassName}) => {
  const [from_date_string, to_date_string] = date_range

  // For the slider, we need to convert ranges from date strings to year integers (to-exclusive)
  // i.e. ['2015-01-12', '2018-11-31'] => [2015, 2019]
  const years_extent = get_years_extent_from_date_string_extent(extent)
  const years_range  = get_years_extent_from_date_string_extent(date_range)

  // calendar datepicker limits
  const [min_year, max_year_exc] = years_extent || []
  const min_selectable_date = min_year ? new Date(min_year, 0, 1) : null
  const max_selectable_date = max_year_exc ? new Date(max_year_exc - 1, 11, 31) : null

  function handle_change_slider([from_year, to_year_exclusive]) {
    // Which side of the slider has changed - the "from" or the "to"?
    const [current_from_year, current_to_year] = years_range || []
    const is_from_changed = current_from_year !== from_year
    const is_to_changed   = current_to_year !== to_year_exclusive

    if (!is_from_changed && !is_to_changed) {
      // no change, so do nothing
      return
    }

    const to_year_inclusive = to_year_exclusive - 1
    const from_date_obj = new Date(from_year, 0, 1)
    const to_date_obj   = new Date(to_year_inclusive, 11, 31)
    const new_from_date_string = get_date_obj_as_date_string(from_date_obj)
    const new_to_date_string   = get_date_obj_as_date_string(to_date_obj)

    const new_date_range = [
      is_from_changed ? new_from_date_string : from_date_string,
      is_to_changed   ? new_to_date_string   : to_date_string
    ]

    on_change_date_range(new_date_range)
  }

  if (! (years_extent && years_range)) return null

  return (
    <div
      className={className}
    >
      <LabelledSlider
        extent={years_extent}
        value={years_range}
        show_end_only={false}
        slider_between_values={true}
        min_distance={1}
        extent_as_labels={true}
        handleChange={handle_change_slider}
        hide_label={!show_slider_labels}
        className={sliderWrapperClassName}
      />

      <div className={cn('d-flex', datesWrapperClassName)}>
        <CalendarDatePicker
          selected_date={new Date(from_date_string)}
          on_date_change={(new_from_date_obj) => {
            if (new_from_date_obj > to_date_string) return
            on_change_date_range([new_from_date_obj, to_date_string])
          }}
          min_date={min_selectable_date}
          max_date={max_selectable_date}
        />
        <span
          className='ms-1 align-self-center'
        >
          to
        </span>
        <CalendarDatePicker
          selected_date={new Date(to_date_string)}
          on_date_change={(new_to_date_obj) => {
            if (from_date_string > new_to_date_obj) return
            on_change_date_range([from_date_string, new_to_date_obj])
          }}
          className={'ms-1'}
          min_date={min_selectable_date}
          max_date={max_selectable_date}
        />
      </div>

    </div>
  )
}

export default DateRangeSelectorWithSliderAndCalendars