import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import cn from 'classnames'
import _ from 'underscore'

import OrgSearchMultipleControls from './OrgSearchMultipleControls.js'
import OrgSearchMultipleMatchesTable from './OrgSearchMultipleMatchesTable.js'
import { Pane } from '../widgets/Block.js'
import Spinner from '../widgets/Spinner.js'
import ErrorModal from '../ErrorModal.js'
import BadSyntaxAlertModal from '../patent_family_list/BadSyntaxAlertModal.js'
import { get_org_id, extract_multiple_org_names_to_search, search_multiple_orgs_by_phrase_list, is_org_type } from '../../utils/organisation_utils.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'
import { is_400_error } from '../../utils/axios_utils.js'
import OrgSearchModeWrapper from './OrgSearchModeWrapper.js'
import { PrimaryButton, TertiaryButton } from '../widgets/Button.js'
import { is_aistemos } from '../../utils/user_permissions.js'
import { withUser } from '../UserContext.js'
import { Input } from 'reactstrap'
import { pluralise_text } from '../../utils/utils.js'
import ProgressPrompt from '../builder/wizard/ProgressPrompt.js'
import { FormFeedback } from '../widgets/FormFeedback.js'

import cs from '../cipher_styles.module.scss'
import s from './OrgSearchMultiple.module.scss'

const OrgSearchMultiple = (
  {
    user,

    portfolio_basket,
    update_basket_handler,
    on_search_mode_change,
    enable_remove_action,
    enable_ignore_action,
    can_add_orgs_handler,

    is_wizard,
    is_wizard_final_step,
    can_wizard_continue,

    blockClassName,
    inputContainerClassName,
    inputClassName,
  }) => {

  const DEFAULT_SIZE_ROWS = 15
  const MAX_ORGS_IN_SEARCH_INPUT = is_aistemos(user) ? 500 : 100
  const MAX_INPUT_LENGTH_CHARS = MAX_ORGS_IN_SEARCH_INPUT * 50 // in case someone tries to paste something really enormous

  const organisations_in_basket = (portfolio_basket || []).filter(item => is_org_type(item))
  const org_ids_in_basket = organisations_in_basket.map(org => get_org_id(org))

  const [multi_search_input_value, set_multi_search_input_value] = useState('')
  const [show_spinner, set_show_spinner] = useState(false)
  const [org_search_error, set_org_search_error] = useState(null)

  const [search_names, set_search_names] = useState(null)
  const [search_name_to_org_search_results, set_search_name_to_org_search_results] = useState(null)
  const [search_name_to_best_match, set_search_name_to_best_match] = useState({})
  const [search_names_to_ignore, set_search_names_to_ignore] = useState([])

  function on_change_from_search_input(new_orgs_to_search_value) {
    if (multi_search_input_value !== new_orgs_to_search_value) {
      set_multi_search_input_value(new_orgs_to_search_value)
    }
  }

  function search_for_orgs() {
    set_show_spinner(true)
    track_report_builder_event('action="search" obj="orgs" context="multiple_org_search"')
    search_multiple_orgs_by_phrase_list(org_names_in_input, false, true)
      .catch(error => {
        set_show_spinner(false)
        set_org_search_error(error)
        throw error
      })
      .then(results => {
        // set top results as best matches to begin with
        const search_name_to_top_result = {}

        const search_names = _.keys(results)

        set_search_names(search_names)

        search_names.forEach(name => {
          const top_result = _.first(results[name])
          if (top_result) {
            search_name_to_top_result[name] = top_result
          }
        })
        set_show_spinner(false)
        set_search_name_to_best_match(search_name_to_top_result)
        set_search_name_to_org_search_results(results)
      })
  }

  function update_name_and_search(new_name, name_idx) {
    set_show_spinner(true)

    search_multiple_orgs_by_phrase_list([new_name], false, true)
      .catch(error => {
        set_show_spinner(false)
        set_org_search_error(error)
        throw error
      })
      .then(results => {
        const updated_search_names = [...search_names]
        const prev_name = updated_search_names[name_idx]
        updated_search_names[name_idx] = new_name
        set_search_names(updated_search_names)

        const all_names = extract_multiple_org_names_to_search(multi_search_input_value)
        all_names[all_names.indexOf(prev_name)] = new_name
        set_multi_search_input_value(all_names.join("\n"))

        const top_result = _.first(results[new_name])

        const updated_search_name_to_org_search_results = {...search_name_to_org_search_results || {}, ...results}
        const updated_search_name_to_best_match = {...search_name_to_best_match || {}, ...top_result ? {[new_name]: top_result} : {}}

        delete updated_search_name_to_org_search_results[prev_name]
        delete updated_search_name_to_best_match[prev_name]

        set_search_name_to_best_match(updated_search_name_to_best_match)
        set_search_name_to_org_search_results(updated_search_name_to_org_search_results)

        set_show_spinner(false)
      })
  }

  function orgs_available_to_add() {
    // filter ignored and non-existent matches
    const search_matches_not_in_ignored_list =
      _.keys(search_name_to_org_search_results)
        .filter(name => !_.contains(search_names_to_ignore, name))
        .map(name => search_name_to_best_match[name])
        .filter(best_match => best_match != null)
    // filter already selected
    const orgs_not_already_in_basket = search_matches_not_in_ignored_list.filter(org => !_.contains(org_ids_in_basket, get_org_id(org)))
    // return unique orgs
    return _.uniq(orgs_not_already_in_basket, org => get_org_id(org))
  }

  function add_all_to_basket() {
    track_report_builder_event('action="add_to_basket" obj="orgs" context="multiple_org_search"')
    update_basket_handler({ add: orgs_available_to_add() }, false)
  }

  function on_clear_matches_and_return_to_input() {
    set_search_name_to_org_search_results(null)
    set_search_name_to_best_match({})
    set_search_names_to_ignore([])
  }

  function on_org_click_handler({org, add}) {
    add ? on_click_add(org) : on_click_remove(org)
  }

  function on_click_add(org) {
    track_report_builder_event('action="add_to_basket" obj="org" context="multiple_org_search"')
    update_basket_handler({add: [org]})
  }

  function on_click_remove(org) {
    track_report_builder_event('action="remove_from_basket" obj="org" context="multiple_org_search"')
    update_basket_handler({ remove: [org]})
  }

  function on_select_best_match_handler(search_term, org) {
    track_report_builder_event('action="update_best_match" obj="org" context="multiple_org_search"')
    on_update_ignore_search_name(search_term, false)
    set_search_name_to_best_match({...search_name_to_best_match, [search_term]: org})
  }

  function on_update_ignore_search_name(search_name, is_ignore) {
    if (is_ignore) {
      track_report_builder_event('action="ignore_org_search_match", obj="org" context="mulitple_org_search"')
      set_search_names_to_ignore([...search_names_to_ignore, search_name])
    } else {
      set_search_names_to_ignore(search_names_to_ignore.filter(name => name !== search_name))
    }
  }

  function feedback_message() {
    // todo: any other situations where we can give feedback on bad input?
    if (too_many_orgs_in_input) {
      const excess_input_rows = input_rows - MAX_ORGS_IN_SEARCH_INPUT
      return `Classification searches are limited to ${MAX_ORGS_IN_SEARCH_INPUT} organisations. 
      Please remove ${excess_input_rows} ${pluralise_text(excess_input_rows, 'name')} from the input field to proceed.`
    }
  }

  function check_if_can_add_all() {
    const orgs_to_add = orgs_available_to_add()
    const has_orgs_to_add = orgs_to_add && orgs_to_add.length > 0

    if (!has_orgs_to_add) return false

    if (can_add_orgs_handler) {
      return can_add_orgs_handler({orgs_to_add})
    }

    return true
  }

  const can_add_all = check_if_can_add_all()

  const is_bad_syntax_error = is_400_error(org_search_error)

  const org_names_in_input = extract_multiple_org_names_to_search(multi_search_input_value)

  const number_matched = (_.values(search_name_to_best_match) || []).length
  const show_controls_at_page_bottom = number_matched >= 10 && !is_wizard

  const input_rows = org_names_in_input.length
  const has_input = input_rows && input_rows > 0
  const too_many_orgs_in_input = input_rows > MAX_ORGS_IN_SEARCH_INPUT

  if (org_search_error && !is_bad_syntax_error) {
    return(
      <ErrorModal
        on_hide={() => set_org_search_error(null)}
        error={org_search_error}
        context='fetching organisation results for multiple search'
      />
    )
  }

  if (is_bad_syntax_error) {
    return(
      <BadSyntaxAlertModal
        on_hide={() => set_org_search_error(null)}
      />
    )
  }

  return (
    <div className={blockClassName}>

      {is_wizard &&
        <div className={cn('d-sm-flex mb-3', s.progress_prompt)}>
          <ProgressPrompt
            is_wizard_final_step={is_wizard_final_step}
            can_wizard_continue={can_add_all || can_wizard_continue}
            on_step_complete={add_all_to_basket}
            className={cs.border_none}
          >
            <span>Search for organisations and add them to the report.</span>
          </ProgressPrompt>
          {search_name_to_org_search_results &&
            <TertiaryButton
              onClick={on_clear_matches_and_return_to_input}
              className={cn('ms-sm-2 mt-2 mt-sm-0', cs.white_space_nowrap)}
            >
              Back to input
            </TertiaryButton>
          }
        </div>
      }

      { show_spinner &&
        <Pane className='text-center'>
          <Spinner />
          <p>Fetching results</p>
        </Pane>
      }

      { !search_name_to_org_search_results && !show_spinner &&
        <>
          <OrgSearchModeWrapper on_search_mode_change={on_search_mode_change} search_mode={'multi'}>
            <div className={cn('w-100 d-flex justify-content-between mx-1 my-auto', s.intro_wrapper)}>
              <span className='my-auto'>Type or paste organisation names in the input below, one name per line.</span>
              <PrimaryButton
                onClick={search_for_orgs}
                disabled={!has_input || too_many_orgs_in_input}
                className={cn('my-auto', cs.small_button)}
              >
                Search
              </PrimaryButton>
            </div>
          </OrgSearchModeWrapper>

          <div className={cn('p-3 mb-1', s.input_wrapper, inputContainerClassName)}>
            <Input
              type='textarea'
              name='text'
              rows={ DEFAULT_SIZE_ROWS }
              onChange={(e) => on_change_from_search_input(e.target.value)}
              value={multi_search_input_value || ''}
              autoFocus={true}
              className={inputClassName}
              invalid={too_many_orgs_in_input}
              maxLength={MAX_INPUT_LENGTH_CHARS}
              spellCheck={false}
              autoComplete='off'
            />
            <FormFeedback valid={!too_many_orgs_in_input} validation_text={feedback_message()} />
          </div>
        </>
      }

      { !show_spinner && search_name_to_org_search_results &&
        <>
          <div className='d-flex flex-wrap mb-1 my-2'>
            <span className='me-2 pt-3'>Found matches for {number_matched} of {org_names_in_input.length} organisations</span>

            {!is_wizard &&
              <span className='ms-auto me-0'>
                <OrgSearchMultipleControls
                  can_add_all={can_add_all}
                  on_add_all={() => add_all_to_basket()}
                  on_clear={() => on_clear_matches_and_return_to_input()}
                />
              </span>
            }
          </div>

          <OrgSearchMultipleMatchesTable
            search_names={search_names}
            search_name_to_org_search_results={search_name_to_org_search_results}
            search_name_to_best_match={search_name_to_best_match}
            search_names_to_ignore={search_names_to_ignore}
            selected_org_ids={org_ids_in_basket}
            on_result_check_in={on_org_click_handler}
            on_update_best_match={on_select_best_match_handler}
            on_update_ignore_search_name={on_update_ignore_search_name}
            on_update_search_name={update_name_and_search}
            enable_remove_action={enable_remove_action}
            enable_ignore_action={enable_ignore_action}
            can_add_orgs_handler={can_add_orgs_handler}
            is_wizard={is_wizard}
          />

          { show_controls_at_page_bottom &&
            <OrgSearchMultipleControls
              can_add_all={can_add_all}
              on_add_all={() => add_all_to_basket()}
              on_clear={() => on_clear_matches_and_return_to_input()}
            />
          }

        </>
      }
    </div>
  )
}

export default withRouter(withUser(OrgSearchMultiple))