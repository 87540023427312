import React from 'react'
import * as d3 from 'd3'

import { WHITE, PS_RED, PS_TOPBAR_BLUE } from '../../../constants/colours.js'
import { FONT_SIZE, FONT_WEIGHT, LINE_HEIGHT, FILL, TEXT_FONT_FAMILY } from '../svg/svg_styles.js'

const FORMAT_INTEGER = d3.format('.0%'); // 0 decimal places for percentages

// For now, assume values are all percent (i.e. between 0 and 100)
const MAX_VALUE = 100

const MIN_RADIUS_INNER_LABEL = 16

class Mini4BubbleChart extends React.Component{
  constructor(props) {
    super(props)
    this.container_ref = React.createRef()
  }

  componentDidMount() {
    const containerWidth  = this.props.width || 100,
          containerHeight = this.props.height || 100,
          values          = this.props.values,
          dims            = this.props.dims,
          base_colour     = this.props.base_colour || PS_RED

    const labels = dims[1]

    // Render chart
    const margin = {top: 0, right: 0, bottom: 0, left: 0},
          width  = containerWidth - margin.left - margin.right,
          height = containerHeight - margin.top - margin.bottom

    // We'll use a grid with x and y both in [0, 1, 2, 3, 4].
    // Our circles might be centred at (1, 1), (3, 1), (1, 3), (3, 3).

    const x = d3.scaleLinear()
      .range([0, width])
      .domain([0, 4])

    const y = d3.scaleLinear()
      .range([0, height])
      .domain([0, 4])

    const max_radius = Math.min(width, height) / 4

    const radius_scale = d3.scaleLinear()
      .range([0, max_radius])
      .domain([0, Math.sqrt(MAX_VALUE)]) // so area of circle is proportional to value

    function get_radius(d) {
      return radius_scale(Math.sqrt(d))
    }

    function is_label_within_circle(d) {
      const radius = get_radius(d)
      return radius >= MIN_RADIUS_INNER_LABEL
    }

    function get_x(d, i) {
      return i%2 === 0 ? x(1) : x(3)
    }

    function get_y(is_label, d, i) {
      let offset = 0
      if (!is_label && !is_label_within_circle(d)) {
        // Circle is too small for inner label
        // So display label outside the circle.
        const radius = get_radius(d)
        offset = -radius - 10 // centre the circle so that it is just above the label (need 10px to compensate for top of label)
      }

      if (i < 2) {
        // Top row
        return y(1) + offset - 10 // also just move the top row up (can bleed into the area above)
      } else {
        // Bottom row
        return y(3) + offset
      }
    }

    function get_text_colour(d) {
      return is_label_within_circle(d) ? WHITE : PS_TOPBAR_BLUE
    }

    // Colour scale
    const full_colour_scale = d3.scaleLinear()
      .range([WHITE, base_colour])
      .domain([0, 100])

    const colour = d3.scaleLinear()
      .range([full_colour_scale(30), full_colour_scale(100)])
      .domain([0, MAX_VALUE])

    const container_node = this.container_ref.current
    const canvas = d3.select(container_node)

    const data_values = values.map(value => value*100) //percentages
    const items = canvas.append('g')
      .selectAll('bubble-item')
      .data(data_values)
      .enter()
      .append('g')
      .attr('class', 'bubble-item')

    items
      .append('circle')
      .attr('class', 'bubble')
      .attr('cx', get_x)
      .attr('cy', function(d, i) { return get_y(false, d, i) })
      .attr('r', get_radius)
      .style('fill', function(d) { return colour(d) })

    const label_container = items.append('g').attr('class', 'label-container')

    label_container.append('text')
      .attr('class', 'bubble-label')
      .attr('x', get_x )
      .attr('y', function(d, i) { return get_y(true, d, i) })
      .attr('text-anchor', 'middle')
      .style('font-weight', FONT_WEIGHT)
      .style('font-size', FONT_SIZE)
      .style('font-family', TEXT_FONT_FAMILY)
      .style('line-height', LINE_HEIGHT)
      .style('fill', FILL)
      .style('font-size', '11px')
      .style('fill', get_text_colour)
      .style('font-weight', 'bold')
      .text(function(d, i) { return labels[i].short_name })

    label_container.append('text')
      .attr('class', 'bubble-value')
      .attr('x', get_x )
      .attr('y', function(d, i) { return get_y(true, d, i) + 11 })
      .attr('text-anchor', 'middle')
      .style('font-weight', FONT_WEIGHT)
      .style('font-size', FONT_SIZE)
      .style('font-family', TEXT_FONT_FAMILY)
      .style('line-height', LINE_HEIGHT)
      .style('fill', FILL)
      .style('font-size', '11px')
      .style('fill', get_text_colour)
      .text(function(d, i) { return FORMAT_INTEGER(values[i]) })
  }

  render() {
    return (
      <g ref={this.container_ref} className='mini-4-bubble-chart'/>
    )
  }
}

export default Mini4BubbleChart
