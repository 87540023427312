import React, {useState} from 'react'
import { DropdownMenu, UncontrolledDropdown } from 'reactstrap'
import cn from 'classnames'

import SelectDropdownToggleStatic from './SelectDropdownToggleStatic.js'

import s from './BaseDropdown.module.scss'

/**
 * Renders a dropdown menu.
 * @param {} children Array of react-strap DropdownItem (or any elements)
 * @param {} label Label to show on the button
 * @param {} right If true, aligns dropdown to right of button (by default aligned left)
 * @param {} className Outer className
 * @param {} buttonClassName className for button - can set width of button here.
 * @param {} menuClassName className for menu - can set width of menu (when dropdown revealed) here.
 * @param {} disabled if true, disables the dropdown
 * @param {} direction
 * @param {} flip
 * @param {} always_visible_when_open
 * @param {} on_toggle function to execute when the dropdown opens/closes
 */
const BaseDropdown = (
  {
    children,
    label,
    labelClassName,
    right,
    className,
    buttonClassName,
    menuClassName,
    disabled,
    direction,
    flip,
    always_visible_when_open,
    on_toggle,
    hide_chevron
  }) => {

  const [is_dropdown_open, set_is_dropdown_open] = useState(false)

  function dropdown_toggled() {
    set_is_dropdown_open(!is_dropdown_open)
    if (on_toggle) {
      on_toggle()
    }
  }

  return (
    <UncontrolledDropdown
      className={className}
      inNavbar={!always_visible_when_open}
      onToggle={dropdown_toggled}
    >
      <SelectDropdownToggleStatic
        className={cn(s.dropdown_toggle, buttonClassName)}
        disabled={disabled}
        labelClassName={labelClassName}
        hide_chevron={hide_chevron}
        is_dropdown_open={is_dropdown_open}
      >
        {label}
      </SelectDropdownToggleStatic>

      <DropdownMenu
        end={right}
        flip={(flip != null) ? flip : false} // By default flip is false, so prevents dropups (as can be underneath the sticky header)
        className={cn(menuClassName)}
        direction={direction || 'down'}
      >
        {is_dropdown_open &&
          <>
            {children}
          </>
        }
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default BaseDropdown