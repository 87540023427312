import _ from 'underscore'

import { PRODUCT_ND } from '../constants/cipher_product_ids.js'

import { ID_TO_TAXONOMY_PRODUCT, TAXONOMY_PRODUCTS } from '../model/classifiers_products.js'

import { get_product_classifiers } from './static_data_utils.js'
import { has_nd_related_permissions, get_permitted_product_ids } from './user_permissions.js'
import { fetch_classifiers } from './training_set_grpc_utils.js'
import {
  add_parent_refs,
  get_as_list,
  get_classifiers_group_as_tree,
  get_leaf_nodes_as_array,
  is_search_match
} from './classifier_tree_utils.js'
import { get_as_map } from './utils.js'
import { UTT_GROUP_ID } from '../constants/utt.js'
import { send_error_to_sentry } from './sentry_utils.js'

export const IS_PAGINATABLE = 'is_paginatable'

export const IS_USER_TAXONOMY = 'is_user_taxonomy'

export const USER_OTHER_ID = 'USER_OTHER'

export const UNCATEGORISED_GROUP_NAME = 'Uncategorised'

/**
 * Returns a list of classifier_group objects.
 *
 * Each classifier_group has 'name', 'id', and 'children'.
 * The children can be a classifier object, or another classifier_group.
 * Each child has a property 'parent', an list of paths for the parent (i.e. ['Energy', 'Solar', 'Panels'])
 *
 * classifier_groups come from
 * 1) product taxonomies (i.e. json in S3)
 * 2) user taxonomies (i.e. each user training set can have an optional 'taxonomy_path' string)
 * 3) user other (i.e. user training sets that have no 'taxonomy_path' string)
 */
export function get_classifier_groups(user, include_nd) {
  return Promise.all([
    get_product_taxonomies(user, include_nd),
    get_user_classifiers_groups(),
  ])
  .then(([product_taxonomies, user_classifier_groups]) => {
    // Combine into single list
    return [...product_taxonomies, ...user_classifier_groups]
  })
  .then(groups => {
    // add parent refs everywhere
    return groups.map(add_parent_refs)
  })
}

export function get_product_taxonomies(user, include_nd) {
  // NOTE: Product permissions are currently done as roles. TODO: move these to a multi-value group-level attribute.

  const product_ids = get_permitted_product_ids(user)

  // If required, add in the nd product (i.e. it is not included in product ids above, it has a slightly different form)
  const has_nd = include_nd && has_nd_related_permissions(user)
  const product_ids_all = [
    ...product_ids,
    ...(has_nd ? [PRODUCT_ND] : [])
  ]

  const [missing_product_ids, found_product_ids] = _.partition(product_ids_all, id => (ID_TO_TAXONOMY_PRODUCT[id] == null))
  if (missing_product_ids.length > 0) {
    // Unrecognised taxonomy product ids. So send to Sentry and log (but then continue as normal)
    const message = `taxonomy product id(s) not recognised: ${missing_product_ids.join(', ')}`
    const err = new Error(message)
    send_error_to_sentry(err, {})
    if (console && console.log) {
      console.log(message)
    }
  }

  // Get products in order specified by TAXONOMY_PRODUCTS
  const product_ids_set = new Set(found_product_ids)
  const taxonomy_products = TAXONOMY_PRODUCTS.filter(product => product_ids_set.has(product.id))

  return Promise.all(taxonomy_products.map((product) => {
    const { id } = product
    return get_product_classifiers(id)
    .then(classifiers_tree => {
      const full_product = { ...product, ...classifiers_tree } // merge in extra metadata (i.e. bespoke_name)
      return full_product
    })
  }))
}

export function get_user_classifiers_groups() {
  return fetch_classifiers()
    .then(classifiers => {
      // User classifiers can have a 'taxonomy_path' list.
      // For example:
      //   ['Energy']
      //   ['Energy', 'Solar', 'Turbines']
      //   ['Hair Dryers']
      //   ['Hair Dryers', 'Fans']
      //   []   (no taxonomy path)

      // Remove any initial slash in taxonomy_path list. For example convert ['/', 'Energy'] to ['Energy'].
      const classifiers_clean = classifiers.map(classifier => {
        const { taxonomy_path } = classifier
        const prefix = taxonomy_path.length ? taxonomy_path[0] : null
        if (prefix === '/') {
          return { ...classifier, taxonomy_path: taxonomy_path.slice(1) }
        }
        return classifier
      })

      // Group the classifiers by top-level path prefix.
      // So for example above, 'Energy' and 'Hair Dryers' are top-level paths.
      // All classifiers with no path get put in the 'other_classifiers' list.
      const [prefix_to_classifiers, other_classifiers] = classifiers_clean.reduce(([prefix_to_classifiers, other_classifiers], classifier) => {
        const { taxonomy_path } = classifier

        if (taxonomy_path.length === 0) {
          return [prefix_to_classifiers, [...other_classifiers, classifier]]
        }

        const prefix = taxonomy_path[0]
        const existing_classifiers = prefix_to_classifiers[prefix] || []

        return [
          { ...prefix_to_classifiers, [prefix]: [...existing_classifiers, classifier] },
          other_classifiers
        ]
      }, [{}, []])

      // Convert the groups into trees.
      const prefixes = Object.keys(prefix_to_classifiers).sort()
      const classifier_groups = prefixes.map(prefix => {
        const classifiers = prefix_to_classifiers[prefix]
        const classifiers_tree = get_classifiers_group_as_tree(classifiers)
        return { ...classifiers_tree, id: prefix, [IS_USER_TAXONOMY]: true }
      })

      return other_classifiers && other_classifiers.length > 0 ?
        [
          ...classifier_groups,                                                                                  // trees
          { name: UNCATEGORISED_GROUP_NAME, id: USER_OTHER_ID, children: other_classifiers, [IS_PAGINATABLE]: true }      // flat list
        ] :

        classifier_groups
    })
}

export function get_id_to_classifier(classifier_groups) {
  const classifiers = _.flatten(classifier_groups.map(group => get_leaf_nodes_as_array(group)))
  return get_as_map(classifiers, 'classifier_id')
}

export function get_group_heading(classifiers_group) {
  const { id, name, bespoke_name } = classifiers_group || {}

  if (bespoke_name) {
    return bespoke_name
  }

  if (id === USER_OTHER_ID) {
    return name
  }

  const name_lower = name.toLowerCase()
  const is_non_standard_name = (name_lower.indexOf('classifiers') !== -1) || (name_lower.indexOf('taxonomy') !== -1)
  if (is_non_standard_name) {
    // Most names are just the name of the client i.e. 'Global Corp'
    // But occasionally for historical reasons the name is non-standard i.e. 'Global Corp classifiers', 'Global Corp taxonomy 2023' etc...
    // We return non-standard names as they are.
    return name
  }

  // Otherwise we add ' classifiers'
  return name + ' classifiers'
}

export function get_group_classifiers_ids(classifier_group) {
  return get_leaf_nodes_as_array(classifier_group).map(item => item.classifier_id)
}

export function get_group_selected_classifiers(classifier_group, all_selected_classifiers) {
  const classifiers_ids = get_group_classifiers_ids(classifier_group)

  return (all_selected_classifiers || []).filter(item => classifiers_ids.indexOf(item.classifier_id) > -1)
}

export function get_group_indirectly_selected_classifiers(classifier_group, all_selected_classifiers) {
  const {name} = classifier_group

  const selected_classifiers = get_group_selected_classifiers(classifier_group, all_selected_classifiers)
  const classifier_ids_to_group_classifiers = get_classifier_ids_to_group_classifiers(classifier_group)

  return selected_classifiers.filter(item => {
    const {path: item_path, classifier_id} = item || {}

    if ((item_path || [])[0] === name) return false //in the uncommon case a classifier is displayed multiple times in the same group

    const {path} = classifier_ids_to_group_classifiers[classifier_id] || {}
    return _.difference(item_path, path).length !== 0
  })
}

export function get_group_classifiers(classifier_group) {
  return get_leaf_nodes_as_array(classifier_group).map(get_classifier_for_report_input)
}

export function get_classifier_groups_to_ids(classifier_groups) {
  return classifier_groups == null ? null : get_as_map(classifier_groups, 'id')
}

export function get_classifier_group_by_id(classifier_groups, id) {
  return (get_classifier_groups_to_ids(classifier_groups) || {})[id]
}

function get_classifier_ids_to_group_classifiers(classifier_group) {
  const classifiers = get_group_classifiers(classifier_group)

  return get_as_map(classifiers, 'classifier_id')
}

export function get_classifier_for_report_input({classifier_id, parent, name, description, classifier_group_id}) {
  const is_utt = classifier_group_id === UTT_GROUP_ID

  const classifier_description = description && description.trim().length > 0 ? description : null

  return  {
    classifier_id,
    name,
    path: parent || [],
    ...classifier_description ? {description: classifier_description} : {},
    ...is_utt ? {is_utt} : {}
  }
}

export function get_filtered_classifiers(search_phrase, classifier_group) {
  if (!classifier_group) {
    return []
  }
  if (!search_phrase) {
    return get_leaf_nodes_as_array(classifier_group)
  }

  const filtered = get_as_list(classifier_group).filter(item => is_search_match(search_phrase, item.name))

  let classifiers = []

  filtered.forEach(item => {
    const new_items = (item.children) ? get_leaf_nodes_as_array(item) : [item]

    classifiers = [...classifiers, ...new_items]
  })

  return classifiers
}
