import React from 'react'
import cn from 'classnames'

import TextLink from '../../widgets/TextLink.js'
import { STATUS_GRANTED_ID } from '../../../model/statuses.js'
import { InfoPopover } from '../../widgets/Tooltip.js'
import { InfoIcon } from '../../widgets/IconSet.js'
import ChartIcon from '../ChartIcon.js'
import { ID_TO_DECK_SPEC } from '../../../model/deck_specs.js'
import { track_deck_viewer_event } from '../../../utils/tracking_utils.js'

import s from './LandingPage.module.scss'

const LandingPage = ({report_deck, deck_type, set_current_page, selections, chart_selections, ref_data, data_creation_date}) => {
  const {tech_areas, org_lists} = ref_data

  const {status_filter: deck_status_filter, spotlighted_tech_areas=[]} = selections

  function on_navigate_to_page(page_id) {
    track_deck_viewer_event(`obj="report" action="navigate" page="${page_id}" context="landing_page" deck_type="${deck_type}"`)
    set_current_page(page_id)
  }

  return (
    <div className='w-100'>
      {report_deck.contents.map(section => {
        return (
          <div className='my-3' key={section.id}>
            <h4>{section.title}</h4>

            {section.pages.filter(page => !page.hidden_in_main_nav).map(page => {
              const {title, id, charts} = page
              return (
                <div className={cn('d-sm-flex my-2', s.page_wrapper)} key={id} onClick={() => on_navigate_to_page(id)}>
                  <div className={cn('py-2 px-1', s.title_column)}>
                    <TextLink element='div'>{title}</TextLink>
                  </div>

                    {charts.map(chart => {
                      const {spec_id, view_id, view_icon, title, description, get_title, get_description} = chart

                      const { description: custom_description, status_filter: custom_status_filter, selected_timerange } = chart_selections[spec_id] || {}

                      const {get_default_selected_timerange} = ID_TO_DECK_SPEC[spec_id]

                      const is_granted_only = (custom_status_filter || deck_status_filter) === STATUS_GRANTED_ID

                      const timerange = selected_timerange ? selected_timerange : (get_default_selected_timerange ? get_default_selected_timerange(data_creation_date) : null)

                      const title_to_display = get_title ? get_title({granted_only: is_granted_only, timerange, spotlighted_tech_areas, tech_areas, org_lists}) : title
                      const description_to_display = custom_description ? custom_description : get_description ? get_description({granted_only: is_granted_only, spotlighted_tech_areas}) : description
                      return (
                        <div key={chart.spec_id} className={cn('py-2 px-1 d-flex', s.chart_wrapper)}>
                          <ChartIcon
                            view_id={view_id}
                            view_icon={view_icon}
                            dimension={3}
                            className={cn('me-2', s.chart_icon)}
                          />
                          <div>
                            <span className={cn('mb-1', s.chart_name)}>{title_to_display}</span>
                            <InfoPopover toggler={<span className='ms-1'><InfoIcon /></span>}>
                              {description_to_display}
                            </InfoPopover>
                          </div>
                        </div>
                    )})}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default LandingPage