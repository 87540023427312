import React from 'react'
import { withRouter } from 'react-router-dom'

import { DashboardSmallTile } from './DashboardTile.js'
import { TileHelpLink } from './TileHelpLink.js'
import { get_new_nd_report_help_url } from '../../utils/help_utils.js'
import { DashboardTileDesc } from './DashboardTileDesc.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'
import { DashboardTileInner } from './DashboardTileInner.js'
import { BUILD_ND_REPORT } from '../../constants/paths.js'
import TileTitle from './TileTitle.js'

const NewNDReportPanel = ({history}) => {

  function on_click_from_new_report_btn() {
    track_report_builder_event('action="select_builder" report_type="n/d" context="dashboard"')
    history.push(BUILD_ND_REPORT)
  }

  const title = (
    <TileTitle
      title={<span><sup>n</sup>/<sub>d</sub> report</span>}
    />
  )

  return (
    <DashboardSmallTile title={title} hint={<TileHelpLink help_url={get_new_nd_report_help_url()} />} on_click={on_click_from_new_report_btn}>
      <DashboardTileInner>
        <DashboardTileDesc>
          Create a new <sup>n</sup>/<sub>d</sub> report using the AST Product Taxonomy
        </DashboardTileDesc>
      </DashboardTileInner>
    </DashboardSmallTile>
  )
}

export default withRouter(NewNDReportPanel)