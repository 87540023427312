import React from 'react'

import { PdfViewer } from '../widgets/PdfViewer.js'
import ContainerFullWidth from '../ContainerFullWidth.js'
import { get_patent_link_option_id_preference } from '../../utils/patent_family_list_utils.js'
import { track_visit_event } from '../../utils/tracking_utils.js'
import { get_domain_prefix } from '../../utils/utils.js'

const FamilyGraphView = ({match}) => {
  const cipher_family_id = match.params.id

  document.title = 'Cipher: Family History'
  track_visit_event('page="family_graph"')

  const patent_link_mode = get_patent_link_option_id_preference()

  const src = `${get_domain_prefix()}/api/family_graph/${cipher_family_id}?link_type=${patent_link_mode}`

  return (
    <ContainerFullWidth className='px-0 w-100 h-100'>
      <PdfViewer
        src={src}
        className='w-100 h-100'
        title={`${cipher_family_id} history graph`}
      />
    </ContainerFullWidth>
  )
}

export default FamilyGraphView