import React from 'react'
import cn from 'classnames'

import s from './TagButton.module.scss'

const TagButton = ({ name, on_click }) => {

  return (
    <span
      className={cn('me-1', s.tag_shape)}
      onClick={on_click}
    >
      <span className={s.tag_name}>{name}</span>
    </span>
  )
}
export default TagButton