import React from 'react'
import TimerangeControl from '../TimerangeControl.js'

import './TimerangeSelect.override.scss'

const TimerangeSelect = (
  {
    spec,
    data_creation_date,
    original_key_dims,
    chart_selection,
    on_change_timerange,
    className,
  }) => {

  const { selected_timerange } = chart_selection || {}

  return (
    <TimerangeControl
      is_benchmarking={true}
      spec={spec}
      data_creation_date={data_creation_date}
      original_key_dims={original_key_dims}
      item={{timerange: selected_timerange}}
      on_change_timerange={on_change_timerange}
      handleClassName={'timerange_slider_handle'}
      sliderLabelClassName={'timerange_slider_label'}
      trackClassName={'timerange_slider'}
      track1ClassName={'timerange_slider-1'}
      className={className}
    />
  )
}

export default TimerangeSelect