import React from 'react'
import { Menu, MenuItem } from '@mui/material'
import _ from 'underscore'
import cn from 'classnames'

import { DEFAULT_TEXT_COLOUR_OVER_DARK_BG } from '../../../constants/colours.js'
import { check_need_light_complimentary_colour } from '../../../utils/highlight_utils.js'

import s from './HighlightPhrasesContextMenu.module.scss'

function get_clean_existing_phrases(phrase, selected_phrases) {
  const selected_phrases_lower_case = selected_phrases.map(phrase => phrase.toLowerCase())
  const existing_phrases = phrase.split(',')

  const clean_existing_phrases = existing_phrases
                                    .map(s => s.trim())
                                    .filter(s => !_.contains(selected_phrases_lower_case, s.toLowerCase()))
                                    .filter(s => s.length > 0)

  return clean_existing_phrases
}

export const HIGHLIGHT_PHRASE_CONTEXT_MENU_CLASS = 'highlight_phrase_context_menu'

const HighlightPhrasesContextMenu = ({
  phrases_context_menu,
  close_phrases_context_menu,

  phrases_to_highlight,
  on_change_phrases_to_highlight,
}) => {

  if (!phrases_context_menu) {
    return null
  }

  const raw_text = phrases_context_menu.selected_text

  const selected_phrases = raw_text
                            .trim()
                            .split(/[,\t\n]/)
                            .map(s => s.trim())
                            .filter(s => s.length > 0)

  const selected_phrases_string = selected_phrases.map(s => `'${s}'`).join(', ')

  function on_click_colour(idx) {
    const new_phrases_to_highlight_list = phrases_to_highlight.map(({ phrase, colour }, i) => {
      const clean_existing_phrases = get_clean_existing_phrases(phrase, selected_phrases)

      const full_phrases = (idx === i) ?
          [...clean_existing_phrases, ...selected_phrases]  // clicked colour, so add
        : clean_existing_phrases                            // other colour, so just remove

      return { phrase: full_phrases.join(', '), colour }
    })

    on_change_phrases_to_highlight(new_phrases_to_highlight_list)
  }

  function on_click_remove() {
    const new_phrases_to_highlight_list = phrases_to_highlight.map(({ phrase, colour }) => {
      const clean_existing_phrases = get_clean_existing_phrases(phrase, selected_phrases)
      return { phrase: clean_existing_phrases.join(', '), colour }
    })

    on_change_phrases_to_highlight(new_phrases_to_highlight_list)
  }

  return (
    <Menu
      open={true}
      onClose={close_phrases_context_menu}
      anchorReference={'anchorPosition'}
      anchorPosition={{ top: phrases_context_menu.mouseY, left: phrases_context_menu.mouseX }}
      className={HIGHLIGHT_PHRASE_CONTEXT_MENU_CLASS}
    >
      <div
        disabled={true}
        className={cn(s.menu_title, 'px-3 pb-2')}
      >
        Add to highlight phrases: <span className='ms-2'><em>{selected_phrases_string}</em></span>
      </div>
      {(phrases_to_highlight != null) && phrases_to_highlight.map(({ phrase, colour }, i) => {

        const need_light_text = colour ? check_need_light_complimentary_colour(colour) : false

        return (
          <MenuItem
            key={i}
            dense
            onClick={() => on_click_colour(i)}
            sx={{
              backgroundColor: `${colour} !important`,
              ...(need_light_text ? { color: DEFAULT_TEXT_COLOUR_OVER_DARK_BG } : {}) ,
              '&:hover': {
                backgroundColor: `${colour} !important`,
              }
            }}
            className={cn(s.colour_menu_item)}
          >
            {phrase}
          </MenuItem>
        )
      })}

      <MenuItem
        dense
        onClick={on_click_remove}
      >
        remove
      </MenuItem>
    </Menu>
  )
}

export default HighlightPhrasesContextMenu
