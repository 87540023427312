import React from 'react'
import { Input } from 'reactstrap'
import autosize from 'autosize'
import cn from 'classnames'

import InputClearControl from './InputClearControl.js'

import s from './AutoResizeTextArea.module.scss'

/**
 * A text area that expands/contracts as the user types.
 */
class AutoResizeTextArea extends React.Component {

  constructor(props) {
    super(props)
    this.input_ref = this.props.inner_ref || React.createRef()
    this.autoset_height = this.autoset_height.bind(this);
  }

  componentDidMount() {
    // initialise autosize
    autosize(this.input_ref.current)
  }

  componentDidUpdate() {
    this.autoset_height()
  }

  componentWillUnmount() {
    autosize.destroy(this.input_ref.current)
  }

  autoset_height() {
    autosize.update(this.input_ref.current)
  }

  render() {

    const { value, className, containerClassName, on_change, on_click, on_key_down, on_focus, on_blur, is_clearable, on_clear, clearClassName, placeholder, rows, disabled, auto_focus } = this.props

    const is_active = value != null && value !== ''

    return (
      <div className={cn(s.container, containerClassName)}>
        {/* Input */}
        <Input
          rows={rows || 1}
          innerRef={this.input_ref}
          type='textarea'
          className={cn(
            s.text_area,
            {[s.text_area__clearable]: is_clearable},
            className
          )}
          value={value}
          placeholder={placeholder}
          onChange={(event) => on_change(event.target.value)}
          onClick={on_click ? (event) => on_click(event.target.value) : null}
          onKeyDown={on_key_down}
          onKeyUp={this.autoset_height}
          onFocus={on_focus}
          onBlur={on_blur}
          disabled={disabled}
          autoFocus={auto_focus}
          autoComplete='off'
          autoCorrect='off'
        />

        {/* OPTIONAL: Clear button */}
        {is_clearable &&
          <InputClearControl
            className={cn(
              'my-auto me-2 ',
              s.clear,
              {[s.clear__active]: is_active},
              clearClassName
            )}
            on_click={() => {
              if (on_clear) {
                on_clear()
                return
              }

              // default clear implementation
              on_change('') // clear input
              this.input_ref.current.value = '' // Seems autosize needs this
              this.autoset_height()
              this.input_ref.current.focus()
            }}
          />
        }

      </div>
    )
  }
}

export default React.forwardRef((props, ref) => <AutoResizeTextArea inner_ref={ref} {...props} />)