import React, { useState, useEffect } from 'react'

import { PatentNumberSelector } from './PatentNumberSelector.js'
import ImagesView from './ImagesView.js'
import { fetch_and_process_patent_xml, get_images_by_publication_ids, order_patent_numbers_and_select_best_representation } from '../../utils/family_view_utils.js'
import ClaimsView from './ClaimsView.js'
import PublicationXMLPartView from './PublicationXMLPartView.js'
import { TOGGLE_ABSTRACT_KEY, TOGGLE_DESCRIPTION_KEY } from '../../constants/keys.js'

const FamilyXMLViews = (
  {
    family,
    selected_patent_number,
    on_change_selected_patent_number,
    images_block_ref,
    claims_block_ref,
    description_block_ref,
    abstract_block_ref,
    search_words,
    search_colours,
    highlight_prefix_only,
    no_highlighting,
    allow_public_access,

    preferred_language,

    className
  }) => {

  const [ family_patent_numbers, set_family_patent_numbers ] = useState(null)

  const [is_fetching_xml, set_is_fetching_xml] = useState(true)
  const [is_fetching_images, set_is_fetching_images] = useState(true)

  const [claims, set_claims] = useState(null)
  const [description, set_description] = useState(null)
  const [abstract, set_abstract] = useState(null)
  const [drawings, set_drawings] = useState(null)
  const [images, set_images] = useState(null)

  const [fetch_err, set_fetch_err] = useState(null)

  useEffect(() => {
      const { ordered_patent_numbers } = order_patent_numbers_and_select_best_representation(family, preferred_language)
      set_family_patent_numbers(ordered_patent_numbers)
  }, [family, preferred_language])

  useEffect(() => {
    if (!selected_patent_number) return

    let did_cancel = false

    set_images(null)
    set_description(null)
    set_claims(null)
    set_is_fetching_xml(true)
    set_is_fetching_images(true)

    set_fetch_err(null)

    fetch_and_process_patent_xml(selected_patent_number, preferred_language)
      .catch(err => {
        if (!did_cancel) {
          set_is_fetching_xml(false)
          set_fetch_err(err)
          throw err
        }
      })
      .then(patent_xml_response => {
        if (!did_cancel) {
          const {claims, description, abstract, drawings} = patent_xml_response || {}
          set_description(description)
          set_claims(claims)
          set_abstract(abstract)
          set_drawings(drawings)
          set_is_fetching_xml(false)
        }
      })

    get_images_by_publication_ids(selected_patent_number)
      .catch(err => {
        if (!did_cancel) {
          set_is_fetching_images(false)
          set_fetch_err(err)
          throw err
        }
      })
      .then(patent_images_response => {
        if (!did_cancel) {
          set_images(patent_images_response)
          set_is_fetching_images(false)
        }
      })

    return () => {
      did_cancel = true
    }
  }, [selected_patent_number, preferred_language
  ])

  function filter_image_attachments_by_drawings(images, drawings) {
    //drawings from publication xml don't contain image path, for that reason we fetch all attachments and then filter out those that are not images and not in the main xml section

    if ((drawings || []).length === 0) return []

    const image_names = drawings.reduce((arr, item) => {
      const {elements} = item || {}

      const filenames_by_elements = (elements || []).map(element => {
        const {attributes} = element || {}
        const {file=''} = attributes || {}
        return file
      })

      return [...arr, ...filenames_by_elements]
    }, [])

    return (images || []).filter(image => {
      const {filename} = image || {}
      return filename && image_names.indexOf(filename) > -1
    })
  }

  const show_spinner_xml = is_fetching_xml && (selected_patent_number != null)
  const show_spinner_images = is_fetching_images && (selected_patent_number != null)

  const images_to_show = filter_image_attachments_by_drawings(images, drawings)

  return (
    <div key={`xml_${family.patFamId}`} className={className}>
      <div className='mb-3'>
        <PatentNumberSelector
          patent_numbers={family_patent_numbers}
          selected_patent_number={selected_patent_number}
          on_click_from_selected_patent_handler={on_change_selected_patent_number}
        />
      </div>

      <ImagesView
        images={images_to_show}
        show_spinner={show_spinner_images}
        error={fetch_err}
        images_block_ref={images_block_ref}
        allow_public_access={allow_public_access}
      />

      <PublicationXMLPartView
        show_spinner={show_spinner_xml}

        context={'abstract'}
        content={abstract}
        block_ref={abstract_block_ref}
        toggle_view_key={TOGGLE_ABSTRACT_KEY}
        images={images}

        no_highlighting={no_highlighting}
        highlight_prefix_only={highlight_prefix_only}
        search_phrases={search_words}
        search_colours={search_colours}

        error={fetch_err}
      />

      <PublicationXMLPartView
        show_spinner={show_spinner_xml}

        context={'description'}
        content={description}
        block_ref={description_block_ref}
        toggle_view_key={TOGGLE_DESCRIPTION_KEY}
        images={images}

        no_highlighting={no_highlighting}
        highlight_prefix_only={highlight_prefix_only}
        search_phrases={search_words}
        search_colours={search_colours}

        error={fetch_err}
      />

      <ClaimsView
        show_spinner={show_spinner_xml}
        claims_block_ref={claims_block_ref}
        claims={claims}
        no_highlighting={no_highlighting}
        highlight_prefix_only={highlight_prefix_only}
        search_phrases={search_words}
        search_colours={search_colours}
        error={fetch_err}
      />

    </div>
  )

}

export default FamilyXMLViews