import React from 'react'
import cn from 'classnames'

import { PrimaryButton, TertiaryButton } from '../widgets/Button'
import EditableText from '../widgets/EditableText.js'
import { get_normalised_name } from '../../utils/name_utils.js'

import cs from '../cipher_styles.module.scss'
import s from './UploadAction.module.scss'

export const ReturnToInputBtn = ({label, on_click, className}) => {
  return (
    <TertiaryButton
      onClick={on_click}
      className={className}
    >
      <span className={cs.white_space_nowrap}>{label || 'Back to input'}</span>
    </TertiaryButton>
  )
}

const UploadAction = (
  {
    name,
    on_submit_handler,
    on_cancel_handler,
    on_name_change_handler,
    submit_button_label,
    cancel_button_label,

    show_buttons,
    enable_rename,
    can_submit,
    className
  }) => {
  function check_if_name_valid(new_name) {
    return (new_name !== '' ) && (new_name !== name)
  }

  function on_confirm(new_name) {
    on_name_change_handler(new_name)
  }

  if (!enable_rename && !show_buttons) return null

  return (
    <div className={cn('d-flex justify-content-between', className)}>
      <div className='w-100 my-auto'>
        {enable_rename &&
          <EditableText
            text={name}
            on_confirm={on_confirm}
            on_normalise={get_normalised_name}
            on_check_if_valid={check_if_name_valid}
          />
        }
      </div>
      {show_buttons &&
        <div className={cn('d-flex justify-content-end', s.controls_wrapper)}>
          <ReturnToInputBtn
            on_click={on_cancel_handler}
            className='me-2'
            label={cancel_button_label}
          />

          <PrimaryButton
            onClick={on_submit_handler}
            disabled={!can_submit}
          >
            <span className={cs.white_space_nowrap}>{submit_button_label || 'Add to report'}</span>
          </PrimaryButton>

        </div>
      }
    </div>
  )
}

export default UploadAction