import React from 'react'
import cn from 'classnames'

import { Table } from '../widgets/Table.js'
import TableThumbnail from '../viewer/TableThumbnail.js'
import { get_tabular_view_total } from '../../utils/tabular_view_utils.js'
import { format_integer_with_comma } from '../../utils/utils.js'

import s from './BaseTable.module.scss'

// NOTE: adds global css in react-table.override.scss

export const BaseTable = ({columns, data, item, is_thumbnail, className, chart_wrapper_height, react_table_props, thumbnail_className}) => {

  const {getProps, getTheadThProps, getTdProps, getTrProps} = react_table_props || {}

  const inline_styles = {
    ...chart_wrapper_height ? {height: chart_wrapper_height} : {}
  }

  if (is_thumbnail) {
    const {get_tabular_view_summary} = item || {}
    const summary_handler = get_tabular_view_summary || get_tabular_view_total
    const label = summary_handler({data, formatter: format_integer_with_comma})

    return (
      <TableThumbnail
        label={label}
        thumbnail_className={thumbnail_className}
      />
    )
  }

  return (
    <div className={cn(s.tabular, className)} style={inline_styles}>
      <Table
        getProps={getProps}
        getTheadThProps={getTheadThProps}
        getTrProps={getTrProps}
        getTdProps={getTdProps}
        columns={columns}
        data={data}
      />
    </div>
  )
}