import React from 'react'
import ReactSlider from 'react-slider'
import cn from 'classnames'

import s from './LabelledSlider.module.scss'

// NOTE: adds global css to react-slider.override.scss

// Safari problem
// On Safari, repeatedly moving the slider for a long time can cause an error.
// Perhaps this is caused by too many setState calls to react
// (both interal to the slider, and to the top-level component).
// The problem goes away if Safari is in debugger mode,
// or when the external callback is disabled (i.e. handleChange here).
// One workaround is to debounce the calls (i.e. 50ms), but then the
// animation is not as responsive.  Setting a timeout does not seem to
// help.

const LabelledSlider = (
  {
    className,
    sliderClassName,
    trackClassName,
    track1ClassName,

    sliderLabelClassName,
    handleClassName,
    value,
    step,
    show_end_only,
    min_distance,
    extent_as_labels,
    extent,
    slider_between_values,
    handleChange,
    hide_label
}) => {
    // try to coerce values to integers
    const derived_value = value.map(d => +d)

    min_distance = (min_distance == null) ? 1 : min_distance

    // show end derived_value inclusive (although data is exclusive)
    const to_label = (+value[1] - 1)

    if (show_end_only) {
      return (
        <span className={cn('d-flex', className)} >
          <ReactSlider
            className={cn(s.block, sliderClassName)}
            trackClassName={cn(s.slider_track, trackClassName)}
            barClassName={'bar'}
            value={derived_value[1]} /* Passed as a single value which causes ReactSlider to show a single handle */
            min={extent[0] + 1}   /* To take account of the fact that there is no from handle */
            max={extent[1]}
            onChange={(value) => handleChange([Math.min(value[0], value - 1), value])}
            pearling
            withBars
            snapDragDisabled={false}
            minDistance={min_distance}
            step={step}
            renderThumb={(props) => {
              return (
                <div
                  {...props}
                  className={cn(s.slider_handle, s.slider_handle_single, handleClassName)}
                />
              )
            }}
          />
          {!hide_label &&
            <div className={s.slider_label}>
              {to_label}
            </div>
          }
        </span>
      )
    } else {
      return (
        <span className={cn('d-flex', className)}>
          {slider_between_values && !hide_label &&
            <span className={cn(s.slider_label, sliderLabelClassName)}>
              {extent_as_labels ? extent[0] : value[0]}
            </span>
          }
          <ReactSlider
            className={cn(s.block, sliderClassName)}
            barClassName={'bar'}
            value={derived_value}
            min={extent[0]}
            max={extent[1]}
            onChange={handleChange}
            pearling
            withBars
            snapDragDisabled={false}
            minDistance={min_distance}
            step={step}
            renderTrack={(props, state) => {
              const {index} = state
              return (
                <div
                  {...props}
                  className={cn(
                    s.slider_track,
                    trackClassName,
                    ...(index === 1 ? [s.slider_track_active, track1ClassName] : [])
                  )}
                />
              )
            }}
            renderThumb={(props) => {
              return (
                <div
                  {...props}
                  className={cn(s.slider_handle, handleClassName)}
                />
              )
            }}
          />
          {slider_between_values && !hide_label && <span className={cn(s.slider_label, sliderLabelClassName)}>{extent_as_labels ? extent[1] -1 : to_label}</span>}

          {!slider_between_values && !hide_label &&
            <span className={cn(s.slider_label, sliderLabelClassName)}>
              <span>{value[0]}</span>&nbsp;to&nbsp;<span>{to_label}</span>
            </span>
          }
        </span>
      )
    }

}

export default LabelledSlider