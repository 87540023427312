import React, { useState } from 'react'

import { PrimaryButton } from '../../widgets/Button.js'
import Modal from '../../widgets/Modal.js'
import { TagNameInput } from './TagNameInput.js'

import { is_valid_tag_name  } from '../../../utils/report_management_utils.js'
import { remove_not_allowed_chars_from_text } from '../../../utils/name_utils.js'
import Spinner from '../../widgets/Spinner.js'


export const CreateNewTagModal = ({handle_create_new_tag, existing_tags, is_creating_tag, on_close}) => {

  const [tag_name, set_tag_name] = useState('')
  const [has_touched_input, set_has_touched_input] = useState(false)

  const is_valid_input = is_valid_tag_name(tag_name, existing_tags)

  function on_change_tag_name_input(input) {
    if (!has_touched_input) {
      set_has_touched_input(true)
    }
    set_tag_name(remove_not_allowed_chars_from_text(input))
  }

  function on_key_up(e) {
    switch (e.keyCode) {
      case 13:
        e.stopPropagation()
        e.preventDefault()
        if (is_valid_tag_name(tag_name, existing_tags)) {
          handle_create_new_tag(tag_name.trim())
        }
        break
      case 27:
        e.preventDefault()
        on_close()
        break
      default:
        return
    }
  }

  return (
    <Modal
      on_hide={on_close}
      size='sm'
      title={'Create new tag'}
      primary_button={
        <PrimaryButton
          onClick={() => handle_create_new_tag(tag_name.trim())}
          disabled={is_creating_tag || !is_valid_input}
        >
          Save
        </PrimaryButton>
      }
      footer={is_creating_tag ? <Spinner/> : null}
    >
      <TagNameInput
        tag_name={tag_name}
        on_change={on_change_tag_name_input}
        on_key_up={on_key_up}
        is_valid_input={!has_touched_input || is_valid_input}
      />
    </Modal>
  )
}