import React from 'react'
import { Link } from 'react-router-dom'
import _ from 'underscore'

import {
  is_report_with_classifiers,
  is_report_with_clustered_techs,
  is_report_with_custom_clustered_techs,
  is_report_with_multiclass,
} from '../../model/technology_basket.js'
import { ScrollModal } from '../widgets/Modal.js'
import TextLink from '../widgets/TextLink.js'
import { InvestigateIcon } from '../widgets/IconSet.js'
import { get_long_date_string } from '../../utils/time_utils.js'
import { withUser } from '../UserContext.js'
import { is_cipher_engineering } from '../../utils/user_permissions.js'
import {
  DEFAULT_GROUP_BY_OWNER_LEVEL,
  ID_TO_GROUP_BY_OWNER_LEVELS,
} from '../../model/group_by_owner_level.js'
import { MONITOR, REPORT } from '../../constants/paths.js'
import {
  is_classifier_landscape_report_type,
  is_nd_report_type,
  is_utt_landscape_report_type,
  is_utt_report_type
} from '../../utils/report_utils.js'
import { DEFAULT_UTT_VERSION } from '../../model/utt_version.js'
import FamiliesCountDisplay from './FamiliesCountDisplay'

const NAMES_THRESHOLD = 5

const ReportDetailsModal = (
  {
    user,
    is_open,
    on_close,

    user_state,
    user_settings,

    report_input,
    external_report_id,
    internal_report_id,
    report_title,
    created_at,
    is_saved,
    is_eval_report,
    is_incomplete,
    families_count
  }) => {

  function get_clustered_portfolios_names(portfolios, portfolios_to_cluster) {
    if (portfolios.length === portfolios_to_cluster.length) return 'all'

    const how_many = (portfolios_to_cluster.length === NAMES_THRESHOLD + 1) ? NAMES_THRESHOLD + 1 : Math.min(portfolios_to_cluster.length, NAMES_THRESHOLD)
    const names = portfolios_to_cluster.slice(0, how_many).map(id => portfolios[id].name).join(', ')

    return how_many < portfolios_to_cluster.length ? names + ' and more' : names
  }

  function get_selected_grouping_level_label(grouping_level) {
    const { name='' }  = ID_TO_GROUP_BY_OWNER_LEVELS[grouping_level || DEFAULT_GROUP_BY_OWNER_LEVEL]
    return name.toLowerCase()
  }

  if (!is_open) {
    return null
  }

  const { technology_partitioning, portfolios, portfolio_group_by_owner_ancestor_type, report_type, input_schema_version } = report_input || {}
  const { type: tech_partitioning_type, include_negatives /* deprecated by negatives_processing*/, negatives_processing, multi_label, threshold, portfolios_to_cluster, version_id: utt_version = DEFAULT_UTT_VERSION } = technology_partitioning || {}

  const is_clustered_report = is_report_with_clustered_techs({tech_partitioning_type})
  const is_classifier_report = is_report_with_classifiers({tech_partitioning_type})
  const is_utt_report = is_utt_report_type(report_type) || is_report_with_multiclass({tech_partitioning_type})
  const is_custom_cluster_report = is_report_with_custom_clustered_techs({tech_partitioning_type}) && !is_utt_report
  const is_landscape_report = is_classifier_landscape_report_type(report_type) && !is_eval_report
  const is_classifier_evaluation_landscape_report = is_eval_report && is_classifier_landscape_report_type(report_type)
  const is_nd_report = is_nd_report_type(report_type)
  const is_utt_landscape = is_utt_landscape_report_type(report_type)

  const is_grouped_by_owner = _.some((portfolios || []), item => item.group_by_owner)

  const is_engineering = is_cipher_engineering(user)

  const report_flags = []

  if (is_saved) report_flags.push('saved')
  if (is_incomplete) report_flags.push('incomplete')

  return (
    <ScrollModal
      is_open={is_open}
      on_hide={on_close}
      title={'Report parameters'}
    >
      <div className='mb-2'>
        <label>Title</label>
        <div className='d-flex'>
          {(report_flags.length > 0) &&
            <span className='me-1'>[{report_flags.join(' | ')}]</span>
          }

          <span>{report_title}</span>

          {is_cipher_engineering(user) && external_report_id &&
            <TextLink className='ms-2' element={Link} title='View in report monitor' no_decoration target='_blank' to={`${MONITOR}${REPORT}/${external_report_id}`}>
              <InvestigateIcon/>
            </TextLink>
          }
        </div>
      </div>

      {created_at != null &&
        <div className='mb-2'>
          <label>Created</label>
          <div>{get_long_date_string(created_at)}</div>
        </div>
      }

      {families_count &&
        <div className='mb-2'>
          <label>Size</label>
          <FamiliesCountDisplay
            families_count={families_count}

            user_state={user_state}
            user_settings={user_settings}
          />
        </div>
      }

      {report_input &&
        <>
          <div className='mb-2'>
            <label>Type</label>
            <div>
              {is_clustered_report && <span>clustered</span>}
              {is_custom_cluster_report && !is_landscape_report && <span>custom clustered</span>}
              {is_classifier_report && !(is_landscape_report || is_classifier_evaluation_landscape_report) && <span>{is_nd_report ? 'n/d ' : ''} classifier {(is_eval_report) ? 'evaluation ': ''}</span>}
              {is_utt_landscape && <span>UTT Landscape</span>}
              {is_landscape_report && <span>classifier landscape</span>}
              {is_classifier_evaluation_landscape_report && <span>classifier landscape evaluation</span>}
              {is_utt_report && !is_utt_landscape && <span>UTT{is_eval_report ? ' classifier evaluation' : ''}</span>}
            </div>
          </div>

          {is_clustered_report &&
            <div className='mb-2'>
              <label>Clustered by</label>

              <div>
                {get_clustered_portfolios_names(portfolios, portfolios_to_cluster)}
              </div>
            </div>
          }

          {(is_grouped_by_owner || is_landscape_report) &&
            <div className='mb-2'>
              <label>Grouped by</label>
              <div>
                {get_selected_grouping_level_label(portfolio_group_by_owner_ancestor_type || DEFAULT_GROUP_BY_OWNER_LEVEL)}
              </div>
            </div>
          }

          {is_classifier_report &&
            <>
              <div className='mb-2'>
                <label>Technologies</label>
                <div>
                  {multi_label && <span>overlapping</span>}
                  {!multi_label && <span>mutually exclusive</span>}
                </div>
              </div>
              <div className='mb-2'>
                <label>Unrelated</label>

                <div className='d-flex'>
                  {include_negatives != null &&
                    <div>
                      {include_negatives && <span>included</span>}
                      {!include_negatives && <span>excluded</span>}
                    </div>
                  }
                  {negatives_processing &&
                    <div>
                      {negatives_processing.type}
                    </div>
                  }
                </div>
              </div>
              <div className='mb-2'>
                <label>Threshold</label>
                <div>{threshold}</div>
              </div>
            </>
          }
        </>
      }

      {is_engineering &&
        <div className='mt-2'>
          {internal_report_id &&
            <div className='mb-2'>
              <label>Internal id:</label>
              <div>{internal_report_id}</div>
            </div>
          }
          {report_input &&
            <div className='mb-2'>
              <label>Input version:</label>
              <div>{input_schema_version || 'pre-versioning'}</div>
            </div>
          }

          {(is_utt_landscape || is_utt_report) &&
            <div className='mb-2'>
              <label>UTT version: </label>
              <div>{utt_version}</div>
            </div>
          }

        </div>
      }

    </ScrollModal>
  )
}

export default withUser(ReportDetailsModal)