// Temporary workaround for scrolling to table rows.
// TODO: find a way of calculating this stuff dynamically (or remove sticky-top headers).

export const LIST_VIEW_PAGE_TOP = 227  // y where the headers end

export const CLASSIFIERS_PAGE_TOP = 170  // y where the headers end

export const REPORT_PAGE_TOP = 160 // y where the header, titlebar and report controls bar ends

// PS style guide values
export const PS_INPUT_HEIGHT = '40px'
export const PS_INPUT_BORDER_DEFAULT = '2px solid #ACBAC9'
export const PS_INPUT_FOCUS_OUTLINE = '1px solid rgba(79, 195, 247, .40)'
export const PS_CHIP_ACTIVE_OUTLINE = '2px solid rgba(79, 195, 247, .30)'

export const MODAL_CONTENT_PADDING = 2 * 16 //2rem
export const MODAL_HEADER_HEIGHT = 32