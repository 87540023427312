export const PORTFOLIO_ID = 'portfolio_id'

export const PORTFOLIO_META_TYPE_ROLLUP_FIELD = 'rollup'

export const UTT_PORTFOLIO_TYPE = 'utt'
export const PATENT_FAMILIES_S3_PORTFOLIO_TYPE = 'patent_families_s3'
export const PATENT_FAMILIES_PORTFOLIO_TYPE = 'patent_families'

export const S3_ALL_FAMILIES_PORTFOLIO = {
  type: PATENT_FAMILIES_S3_PORTFOLIO_TYPE,
  custom_families_id: 'all' // server-side this is replaced by configured S3 bucket info for the choreo
}

export function is_s3_all_families(portfolio) {
  // to recognise s3 landscapes portfolio in older report inputs
  const {type, key} = portfolio
  return type && key && type === PATENT_FAMILIES_S3_PORTFOLIO_TYPE && key.indexOf('all') > -1
}