import React from 'react'
import cn from 'classnames'

import s from './FamilyDetailsLabel.module.scss'

export const Label = ({inline, disabled, className, children}) => {
  return(
    <div className={cn('fs-unmask', inline ? s.label_inline : s.label, {[s.label__disabled]: disabled}, className)}>{children}</div>
  )
}

export const Heading = ({children}) => {
  return(
    <div className={cn('fs-unmask mb-2', s.heading)}>{children}</div>
  )
}