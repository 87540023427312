import React from 'react'
import { NavLink as RSNavLink } from 'reactstrap'
import cn from 'classnames'

import s from './TextLink.module.scss'

/**
 * Renders a text link.
 * This is a workaround for Bootstrap4, which seems to require
 * anchors to have an href (for styling to be applied).
 */
const TextLink = ({ children, className, disable, element, no_decoration, not_selectable, not_clickable, ...other_props}) => {
  const Wrapper = element || 'span'

  return (
    <Wrapper
      {...other_props}
      className={cn(
        s.link,
        (no_decoration ? s.link__no_decoration : s.link__decoration),
        {
          [s.link__disabled]: disable,
          [s.link__not_selectable]: not_selectable,
          [s.link_not_clickable]: not_clickable
        },
        className
      )}
    >
      {children}
    </Wrapper>
  )
}

export const NavLink = ({children, active, ...other_props}) => {
  return (
    <RSNavLink
      {...other_props}
      className={cn(
        {active: active},
        s.link
      )}
    >
      {children}
    </RSNavLink>
  )
}

export default TextLink