import React from 'react'
import cn from 'classnames'

import { track_visit_event } from '../utils/tracking_utils.js'
import { get_domain_prefix } from '../utils/utils.js'
import ContainerFullWidth from './ContainerFullWidth.js'
import { get_ifi_image_url } from '../utils/patent_utils.js'

import s from './PatentImageView.module.scss'

const PatentImageView = ({ match }) => {
  document.title = 'Classification: Family image'
  track_visit_event('page="patent_image"')

  const { id, ucid } = match.params
  const src_url = get_domain_prefix() + get_ifi_image_url(decodeURIComponent(id), decodeURIComponent(ucid))

  return (
    <ContainerFullWidth className='px-0 text-center'>
      <img
        src={src_url}
        alt=''
        className={cn(s.patent_img, 'mx-auto')}
      />
    </ContainerFullWidth>
  )
}

export default PatentImageView