import React from 'react'
import cn from 'classnames'

import { LitigationIcon } from '../widgets/IconSet.js'
import { get_litigations_sample_with_cache } from '../../utils/organisation_utils.js'
import { format_integer_with_comma, pluralise_text, timestamp_to_formatted_string } from '../../utils/utils.js'
import SamplePopup from './SamplePopup.js'

import s from './LitigationsSamplePopup.module.scss'

const LitigationSamplePopup = ({org}) => {

  const { num_defensive_disputes=0, num_offensive_disputes=0 } = org
  const litigation_count = num_defensive_disputes + num_offensive_disputes
  const formatted_litigation_count = format_integer_with_comma(litigation_count)

  if (litigation_count === 0) {
    return (<span><span className={cn(s.icon, s.inactive)}><LitigationIcon /></span> <span className={cn(s.info, s.inactive)}>0</span></span>)
  }

  function display_litigation(litigation) {
    const {title, startDate} = litigation
    return `${title} (start date: ${startDate ? timestamp_to_formatted_string(startDate) : 'unknown'})`
  }

  function get_heading_for_sample(litigations) {
    return `Sample ${pluralise_text(litigations.length,'dispute')}:`
  }

  const toggler = (<span><span className={s.icon}><LitigationIcon /></span> <span className={s.info}>{formatted_litigation_count}</span></span>)
  const defensive_string = num_defensive_disputes > 0 ? `${num_defensive_disputes} defensive` : null
  const offensive_string = num_offensive_disputes > 0 ? `${num_offensive_disputes} offensive` : null
  const title = `${formatted_litigation_count} ${pluralise_text(litigation_count,'dispute')} (${[defensive_string, offensive_string].filter(item => item).join(', ')})`

  return (
    <SamplePopup
      org={org}
      fetch_items_handler={get_litigations_sample_with_cache}

      toggler={toggler}
      title={title}
      get_heading_handler={get_heading_for_sample}
      item_display_handler={display_litigation}
    />

  )
}

export default LitigationSamplePopup