import React from 'react'
import cn from 'classnames'

import s from './ClassifierLabelMarker.module.scss'

const ClassifierLabelMarker = ({is_positive, is_negative, is_test_positive, is_test_negative, is_ignore, className}) => {
  return (
    <div
      className={cn(
        {
          [s.positive]: is_positive,
          [s.negative]: is_negative,
          [s.test_positive]: is_test_positive,
          [s.test_negative]: is_test_negative,
          [s.ignore]: is_ignore
        },
        className
      )}
    />
  )
}

export default ClassifierLabelMarker
