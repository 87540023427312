import React, { useEffect, useState } from 'react'
import _ from 'underscore'

import { withUser } from '../UserContext'
import FamilyTagsManagement from '../family_tagging/FamilyTagsManagement'
import { fetch_all_grouped_tags } from '../family_tagging/family_tag_utils'
import { fetch_all_active_keycloak_groups } from '../../utils/user_group_utils'
import AdminPageWrapper from './AdminPageWrapper.js'

const FamilyTagsAdmin = ({user}) => {

  const [cipher_groups, set_cipher_groups] = useState([])
  const [search_group, set_search_group] = useState()

  useEffect(() => {
    let did_cancel = false
    fetch_all_active_keycloak_groups()
      .catch(() => {
        if (!did_cancel) {
          set_cipher_groups([])
        }
      })
      .then((keycloak_orgs) => {
        if (!did_cancel && keycloak_orgs) {
          const keycloak_orgs_with_no_duplicates = _.uniq(keycloak_orgs, x => x.id)
          set_cipher_groups(keycloak_orgs_with_no_duplicates)
          const keycloak_aistemos_group = keycloak_orgs_with_no_duplicates.filter(group => group.name === 'Aistemos')
          set_search_group(keycloak_aistemos_group)
        }
      })
    return () => {
      did_cancel = true
    }
  }, [user.user_id])

  return (
    <AdminPageWrapper page_name='Family tags'>
      <FamilyTagsManagement
        fetch_tags_func={fetch_all_grouped_tags}
        cipher_groups={cipher_groups}
        search_client={search_group}
        set_search_client={set_search_group}
      />
    </AdminPageWrapper>
)}

export default withUser(FamilyTagsAdmin)
