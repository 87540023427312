import React from 'react'
import cn from 'classnames'

import {
  BookmarkLabelControlIcon,
  IrrelevantLabelControlIcon,
  RelevantLabelControlIcon,
} from '../widgets/IconSet.js'

import s from './FamilyActions.module.scss'

const FamilyActions = (
  {
    family_id,
    family_ids,
    blocklist,
    bookmarked,

    on_add_to_family_ids,
    on_add_to_blocklist,
    on_add_to_bookmarked,

    className
  }) => {

  const is_positive = (family_ids || []).indexOf(family_id) > -1
  const is_negative = (blocklist || []).indexOf(family_id) > -1
  const is_bookmarked = (bookmarked || []).indexOf(family_id) > -1

  return (
    <div className={cn('d-flex h-100 py-1', className)}>
      <span
        className={cn(s.btn, s.btn_positive, {[s.btn_positive__active]: is_positive})}
        onClick={(e) => {
          e.stopPropagation()
          on_add_to_family_ids(family_id)
        }}
        title={is_positive ? 'Remove from relevant list' : `Mark as relevant`}
      >
        <RelevantLabelControlIcon />
      </span>

      <span
        className={cn('mx-1', s.btn, s.btn_negative, {[s.btn_negative__active]: is_negative})}
        onClick={(e) => {
          e.stopPropagation()
          on_add_to_blocklist(family_id)
        }}
        title={is_negative ? 'Remove from irrelevant list' : `Mark as irrelevant`}
      >
        <IrrelevantLabelControlIcon />
      </span>

      <span
        className={cn(s.btn, s.btn_bookmark, {[s.btn_bookmark__active]: is_bookmarked})}
        onClick={(e) => {
          e.stopPropagation()
          on_add_to_bookmarked(family_id)
        }}
        title={is_bookmarked ? 'Remove from list to review' : `Review later`}
      >
        <BookmarkLabelControlIcon />
      </span>
    </div>
  )
}

export default FamilyActions