import React, {useState} from 'react'
import { DropdownItem } from 'reactstrap'
import cn from 'classnames'
import _ from 'underscore'

import { withUser } from '../UserContext.js'
import { ProminentButton } from '../widgets/Button.js'
import Spinner from '../widgets/Spinner.js'
import BaseDropdown from '../widgets/BaseDropdown.js'
import Tooltip from '../widgets/Tooltip.js'
import { InfoIcon } from '../widgets/IconSet.js'
import ReportNameInput from '../ReportNameInput.js'
import { ScrollModal } from '../widgets/Modal.js'
import ErrorModal from '../ErrorModal.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'
import { remove_not_allowed_chars_from_text } from '../../utils/name_utils.js'
import { queue_ocypod_job } from '../../utils/ocypod_utils'
import { PORTFOLIO_GROUP_DROPDOWN_ITEMS, TECH_GROUP_DROPDOWN_ITEMS } from '../../model/set_theory.js'
import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'

const SetTheoryReportBuilderOptionsModal = (
  {
    user,
    operands,
    group_tech_mode,
    group_portfolio_mode,
    formula,
    benchmarking_view,

    on_update_group_tech_mode,
    on_update_group_portfolio_mode,

    on_hide
  }) => {
  const QUEUEING_REPORT_TEXT = 'Sending report request'
  const FORMULA_USAGE_TEXT = 'Use results from formula calculation'
  const QUEUE_NAME = 'sts-reports'

  const [new_report_title, set_new_report_title] = useState(null)
  const [group_tech_obj, set_group_tech_obj] = useState(
    _.find(TECH_GROUP_DROPDOWN_ITEMS, x => x.mode === group_tech_mode)
  )
  const [group_portfolio_obj, set_group_portfolio_obj] = useState(
    _.find(PORTFOLIO_GROUP_DROPDOWN_ITEMS, x => x.mode === group_portfolio_mode)
  )
  const [operable_by_tech, set_operable_by_tech] = useState(false)
  const [operable_by_portfolio, set_operable_by_portfolio] = useState(false)

  const [queue_report_flag, set_queue_report_flag] = useState(false)
  const [error_message, set_error_message] = useState(null)

  function on_report_name_input_change(value) {
    set_new_report_title(remove_not_allowed_chars_from_text(value))
  }

  function is_form_valid() {
    return !!new_report_title
  }

  function send_to_queue() {
    track_report_builder_event('obj="queue_set_theory_report" action="request"')
    set_queue_report_flag(true)

    const input = {
      operands,
      report_name: new_report_title,
      owner_id: user.user_id,
      group_tech_mode,
      group_portfolio_mode,
      formula,
      operable_selection: {
        by_tech: operable_by_tech,
        by_portfolio: operable_by_portfolio
      },
      use_new_choreo: true
    }

    const payload = { input }

    return queue_ocypod_job(payload, QUEUE_NAME)
      .catch((err) => set_error_message(err.message))
      .then(() => {
        set_queue_report_flag(false)
        on_hide()
      })
  }

  function build_modal_footer() {
    return (
      <div className='d-flex'>
        {queue_report_flag &&
          <div className='m-auto d-flex align-items-center'>
            <Spinner size='sm'/>
            {QUEUEING_REPORT_TEXT}
          </div>
        }

        <ProminentButton onClick={() => send_to_queue()} disabled={!is_form_valid() || queue_report_flag}>Run report</ProminentButton>
      </div>
    )
  }

  if (error_message) {
    return (
      <ErrorModal
        error={error_message}
        on_hide={() => set_error_message(null)}
        context={'building set theory report'}
      />
    )
  }

  return (
    <ScrollModal
      on_hide={on_hide}
      footer={build_modal_footer()}
      title='Report options'
      is_open={true}
    >
      <ReportNameInput
        report_name={new_report_title}
        on_change={on_report_name_input_change}
        on_key_up={null}
      />
      <div className='mt-3'>
        <h4>Technology grouping</h4>
        <CheckboxAndLabel
          on_click={() => set_operable_by_tech(!operable_by_tech)}
          is_checked={operable_by_tech}
          is_disabled={benchmarking_view}
          label={FORMULA_USAGE_TEXT}
        />
        {!operable_by_tech &&
          <BaseDropdown label={group_tech_obj?.name}>
            {TECH_GROUP_DROPDOWN_ITEMS.map((obj, idx) =>
              <DropdownItem
                key={idx}
                onClick={() => {
                  on_update_group_tech_mode(obj.mode)
                  set_group_tech_obj(obj)
                }}
                active={group_tech_mode === TECH_GROUP_DROPDOWN_ITEMS[idx].mode}
                className={cn('d-flex')}
              >
                <span className='d-flex flex-nowrap'>
                  {obj.name}
                    <Tooltip
                      toggler={<span className={`my-auto ms-1`}><InfoIcon/></span>}
                      is_in_modal={true}
                      placement={'bottom'}
                    >
                    {obj.info}
                  </Tooltip>
                </span>
              </DropdownItem>
              )}
          </BaseDropdown>
        }
      </div>

      <div className='mt-3'>
        <h4>Portfolio grouping</h4>
        <CheckboxAndLabel
          on_click={() => set_operable_by_portfolio(!operable_by_portfolio)}
          is_checked={operable_by_portfolio}
          is_disabled={benchmarking_view}
          label={FORMULA_USAGE_TEXT}
        />
        {!operable_by_portfolio &&
          <BaseDropdown
            label={group_portfolio_obj?.name}
            disabled={benchmarking_view}
          >
            {PORTFOLIO_GROUP_DROPDOWN_ITEMS.map((obj, idx) =>
              <DropdownItem
                key={idx}
                onClick={() => {
                  on_update_group_portfolio_mode(obj.mode)
                  set_group_portfolio_obj(obj)
                }}
                active={group_portfolio_mode === PORTFOLIO_GROUP_DROPDOWN_ITEMS[idx].mode}
                className={cn('d-flex')}
              >
                <span className='d-flex flex-nowrap'>
                  {obj.name}
                  <Tooltip
                    toggler={<span className={`my-auto ms-1`}><InfoIcon /></span>}
                    is_in_modal={true}
                    placement={'bottom'}
                  >
                    {obj.info}
                  </Tooltip>
                </span>
              </DropdownItem>
            )}
          </BaseDropdown>
        }
      </div>
    </ScrollModal>
  )
}

export default withUser(SetTheoryReportBuilderOptionsModal)