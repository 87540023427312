import React, { useState } from 'react'
import _  from 'underscore'
import { DropdownItem } from 'reactstrap'
import cn from 'classnames'

import { Table } from '../widgets/Table.js'
import { format_integer_with_comma, get_object_values } from '../../utils/utils.js'
import { COST_OPTIONS, COST_OPTIONS_BY_ID } from '../../model/family_view.js'
import { TABLE_COLUMN_HEADER } from '../../model/table.js'

import { ID_TO_CURRENCY } from '../../model/currencies.js'

import BaseDropdown from '../widgets/BaseDropdown.js'
import TextLink from '../widgets/TextLink.js'
import {
  CSV_FILE_EXT,
  EXCEL_FILE_EXT,
  fetch_excel_document,
  get_clean_filename,
  MIMETYPE_CSV,
  MIMETYPE_XLSX,
  trigger_download
} from '../../utils/download_utils.js'
import { DownloadSpinner } from '../widgets/Spinner.js'
import { TABLE_ID } from '../../model/view_ids.js'
import { get_csv_string } from '../../utils/csv_utils.js'

import s from './CostsView.module.scss'

const DOLLAR_SIGN = ID_TO_CURRENCY.USD.short_symbol

function format_cost_value(value) {
  if (!value) return 0
  return DOLLAR_SIGN + format_integer_with_comma(value)
}

const CostsView = ({family, cost_per_year}) => {
  const [costs_mode_id, set_cost_mode_id] = useState(COST_OPTIONS_BY_ID.all.id)
  const [show_download_spinner, set_show_download_spinner] = useState(false)

  const {cipherFamilyId} = family || {}

  const all_years_sorted = get_all_years_sorted()

  function handle_change_costs_mode_id(id) {
    set_cost_mode_id(id)
  }

  function get_cost_value(cost, costs_mode_id) {
    if (!cost) {
      return 0
    }

    const { applicationCost=0, renewalCost=0 } = cost || {}

    switch (costs_mode_id) {
      case COST_OPTIONS_BY_ID.all.id:
        return  applicationCost + renewalCost
      case COST_OPTIONS_BY_ID.application.id:
        return applicationCost
      case COST_OPTIONS_BY_ID.renewal.id:
        return renewalCost
      default:
        return 0
    }
  }

  function get_cost_total_for_values(years_obj, costs_mode_id) {
    const costs = get_object_values(years_obj).map(cost => get_cost_value(cost, costs_mode_id))
    return costs.reduce((a, b) => a + b, 0)
  }

  function get_cost_total(costs_mode_id) {
    const costs_per_year = get_object_values(cost_per_year).map(cost => get_cost_total_for_values(cost, costs_mode_id))
    return costs_per_year.reduce((a, b) => a + b, 0)
  }

  function get_cost_total_for_single_year(year, costs_mode_id) {
    // An array of arrays with [year, value]
    const costs_per_country = get_object_values(cost_per_year).map(x => _.pairs(x))
    const costs_per_country_filtered = costs_per_country.map(
      // For each pair of country value
      pair => pair.filter(c => c[0] === year).map(c => get_cost_value(c[1], costs_mode_id))
    )
    const year_values = _.flatten(costs_per_country_filtered)
    return year_values.reduce((a, b) => a + b, 0)
  }

  function get_cost_data_by_mode_as_csv(costs_mode_id) {
    const {countries, cost_data, totals} = process_cost_data(costs_mode_id)

    const min_year = all_years_sorted[0] * 1
    const max_year = all_years_sorted[all_years_sorted.length -1] * 1

    const year_range = _.range(min_year, max_year + 1)

    const data = countries.map((country, i) => {
      const country_costs = cost_data[i]
      const country_costs_by_year = year_range.map(year => (country_costs[year] || 0))
      return [country, totals[i], ...country_costs_by_year]
    })

    return get_csv_string([['Country', 'Total', ...year_range], ...data])
  }

  function handle_csv_download() {
    const csv = get_cost_data_by_mode_as_csv(costs_mode_id)

    trigger_download(csv, MIMETYPE_CSV, get_clean_filename(`${cipherFamilyId}_${costs_mode_id}_costs${CSV_FILE_EXT}`))
  }

  function handle_excel_download() {
    set_show_download_spinner(true)

    const export_inputs = COST_OPTIONS.map(option => {
      const {id} = option

      const csv = get_cost_data_by_mode_as_csv(id)

      return {
        title: `${cipherFamilyId} - ${id} costs`,
        description: '',
        view_id: TABLE_ID,
        data: csv,
      }
    })

    return fetch_excel_document(export_inputs, false, true)
      .then(arraybuffer => {
        set_show_download_spinner(false)
        trigger_download(arraybuffer, MIMETYPE_XLSX, get_clean_filename(`${cipherFamilyId}_costs${EXCEL_FILE_EXT}`))
      })
      .catch(error => {
        set_show_download_spinner(false)
        throw error
      })
  }

  function get_all_years_sorted() {
    const costs = get_object_values(cost_per_year)
    const all_years = _.flatten(costs.map(byYear => _.keys(byYear)))
    return _.uniq(all_years).sort()
  }

  function process_cost_data(costs_mode_id) {
    const countries = [..._.keys(cost_per_year), 'TOTAL']

    const costs = get_object_values(cost_per_year)

    const costs_years = costs.map(cost_by_year => {
      const cost_year = {}

      _.keys(cost_by_year).forEach((year) => {
        cost_year[year] = get_cost_value(cost_by_year[year], costs_mode_id)
      })

      return cost_year
    })

    const year_totals = {}
    all_years_sorted.forEach((year) => {
      year_totals[year] = get_cost_total_for_single_year(year, costs_mode_id)
    })

    const cost_data = [...costs_years, year_totals]

    const totals = [...get_object_values(cost_per_year).map((row) => (get_cost_total_for_values(row, costs_mode_id))), get_cost_total(costs_mode_id)]

    return {countries, cost_data, totals}
  }

  if (!cost_per_year || _.keys(cost_per_year).length === 0 ) return (<div>No costs to display</div>)

  const columns = [
    {
      ...TABLE_COLUMN_HEADER,
      label: 'Country',
      field: 'country',
      sortable: false,
      width: 120,
      className: s.sticky_column,
      headerClassName: cn(s.sticky_column, s.header)
    },
    {
      ...TABLE_COLUMN_HEADER,
      label: 'TOTAL',
      field:'total',
      sortable: false,
      cell_render: (row) => (<span>{format_cost_value(row.value)}</span>)
    },
    ...all_years_sorted.map((year) => ({
      ...TABLE_COLUMN_HEADER,
      label: year,
      field: year,
      sortable: false,
      cell_render: (row) => (<span>{format_cost_value(row.value || 0)}</span>)
    }))
  ]

  const {countries, cost_data, totals} = process_cost_data(costs_mode_id)

  return (
    <div key={`costs_${cipherFamilyId}`}>
      <div className='d-flex justify-content-between mb-3'>
        <BaseDropdown
          label={COST_OPTIONS_BY_ID[costs_mode_id].name}
          menuClassName={s.menu}
        >
          {COST_OPTIONS.map((option,i) => (
            <DropdownItem active={costs_mode_id === option.id} key={i} onClick={() => handle_change_costs_mode_id(option.id)}>
              <span>{option.name}</span>
            </DropdownItem>
          ))}
        </BaseDropdown>

        <div className='d-flex'>
          {show_download_spinner && <DownloadSpinner className='my-auto me-1'/>}
          <div className='my-auto'>Download as: <TextLink onClick={handle_csv_download}>CSV</TextLink> | <TextLink onClick={handle_excel_download}>Excel</TextLink></div>
        </div>
      </div>

      <Table
        columns={columns}
        data={countries.map((country,i) => {
          const total = totals[i]
          return {country, total, ...cost_data[i]}
        })}

        className={s.cost_table}
      />
    </div>
  )
}

export default CostsView
