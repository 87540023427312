import React from 'react'
import cn from 'classnames'

import { Highlighter } from '../widgets/Highlighter.js'

import s from './FamilyTitle.module.scss'

export const FamilyTitle = (
  {
    family,
    no_highlighting,
    highlight_prefix_only,
    search_words,
    search_colours,

    className
  }) => {
  const { title, patFamId } = family

  return (
    <div
      className={cn(s.family_title, className)}
      key={`title_${patFamId}`}
    >
      <Highlighter
        no_highlighting={no_highlighting}
        highlight_prefix_only={highlight_prefix_only}
        search_words={search_words}
        search_colours={search_colours}
        text_to_highlight={title}
      />
    </div>
  )
}