import React from 'react'
import { withRouter } from 'react-router-dom'

import { pluralise_text } from '../../../utils/utils.js'

import Modal from '../../widgets/Modal.js'
import TextLink from '../../widgets/TextLink.js'
import { PrimaryButton } from '../../widgets/Button.js'
import { HISTORY } from '../../../constants/paths.js'


const SavedReportConfirmationModal = ({on_hide, report_count, on_edit_tags, history}) => {

  const tag_button_label = `Tag ${pluralise_text(report_count, 'report')}`

  return (
    <Modal
      on_hide={on_hide}
      title={`${pluralise_text(report_count, 'Report')} saved`}
      close_label='Close'
      size='md'
      primary_button={(<PrimaryButton onClick={on_edit_tags} title={tag_button_label}>{tag_button_label}</PrimaryButton>)}
    >
      <div>This report will remain available on your <TextLink onClick={() => history.push(HISTORY)}>history</TextLink> page, and will not expire.</div>
      <div className='mt-2'>You can <TextLink onClick={on_edit_tags}>add tags</TextLink> to your saved reports to help organise and find them later.
        Tags are only visible to you and cannot be seen by anyone you share reports with.</div>
    </Modal>
  )
}

export default withRouter(SavedReportConfirmationModal)