import React, { useState } from 'react'

import { withUser } from '../UserContext.js'
import { PrimaryButton } from '../widgets/Button.js'
import Spinner from '../widgets/Spinner.js'
import ErrorModal from '../ErrorModal.js'
import { queue_ocypod_job } from '../../utils/ocypod_utils.js'

const LotnetExport = ({user, internal_report_id}) => {
  const [show_spinner, set_show_spinner] = useState(false)
  const [is_started, set_is_started] = useState(false)
  const [error, set_error] = useState(null)

  function request_lotnet_slidedeck() {
    const queue_name = 'lotnet-ppt-deck';
    const payload ={
      input: {
        owner_id: user.user_id,
        target_report_id: internal_report_id
      }
    }

    set_show_spinner(true)
    set_is_started(true)
    return queue_ocypod_job(payload, queue_name)
      .then(() => {set_show_spinner(false)})
      .catch(err => {set_error(err)})
  }

  return (
    <div>

      {/* Error Handling */}
      {error &&
        <ErrorModal
          on_hide={() => set_error(null)}
          error={error}
          context={`fetching lotnet slide deck - ${error.message}`}
        />
      }

      {!show_spinner && !is_started &&
        <div>
          <div className='mt-2'>Press the &#39;Start&#39; button below to begin export.</div>
          <PrimaryButton
            className='mt-3 mt-md-0'
            onClick={() => {request_lotnet_slidedeck()}}
          >
            Start
          </PrimaryButton>
        </div>
      }

      {show_spinner &&
        <div>
          <div>Fetching data to export, your report will start within a couple of minutes.</div>
          <Spinner/>
        </div>
      }

      {!show_spinner && is_started &&
        <div>
          Your slide deck request has been sent for processing and you will receive an email with the slides upon completion.
        </div>
      }
    </div>
  )
}

export default withUser(LotnetExport)