import React from 'react'
import cn from 'classnames'

import DefaultLabelMultiItemDropdownSelector from '../../widgets/DefaultLabelMultiItemDropdownSelector.js'
import Label from '../../widgets/Label.js'

import cs from '../../cipher_styles.module.scss'

const StatusControl = ({
  className,
  statuses,
  selected_status_ids,
  on_change_status_ids
}) => {

  return (
    <span className={className}>
      <Label className={cn(cs.font_weight_normal, 'me-2')}>
        Status
      </Label>
      <DefaultLabelMultiItemDropdownSelector
        items={statuses}
        selected_item_ids={selected_status_ids}
        on_change_item_ids={on_change_status_ids}
      />
    </span>
  )
}

export default StatusControl