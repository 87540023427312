import React from 'react'
import cn from 'classnames'

import { create_cipher_family_id_from_family_id } from '../../utils/family_view_utils.js'
import CipherFamilyLink from '../widgets/CipherFamilyLink.js'
import TextLink from '../widgets/TextLink.js'
import { InvestigateIcon } from '../widgets/IconSet.js'
import { withUser } from '../UserContext.js'
import { is_cipher_engineering } from '../../utils/user_permissions.js'
import { FAMILY, SOURCES } from '../../constants/paths.js'

import s from './FamilyId.module.scss'

const FamilyId = (
  {
    user,
    family_id,
    selected_patent_number,
    heading,
    on_family_id_click,
    display_as_text_only,
    display_as_linkable_text,
    display_as_linkable_icon,
    is_missing,

    eval_training_set_id,
    eval_classifier_data,

    className
  }) => {
  const id = create_cipher_family_id_from_family_id(family_id)

  return (
    <div>
      <div className={cn('d-flex', className)}>
        <CipherFamilyLink
          family_id={id}
          selected_patent_number={selected_patent_number}
          display_text={display_as_text_only}
          display_text_as_link={display_as_linkable_text}
          display_link_icon={display_as_linkable_icon}
          on_family_id_click={on_family_id_click}

          familyIDClassName={s.content}
          externalLinkClassName={s.icon}
          show_similar_families_search={true}

          eval_training_set_id={eval_training_set_id}
          eval_classifier_data={eval_classifier_data}
          is_missing={is_missing}
        />

        {is_cipher_engineering(user) &&
          <TextLink element='a' target='_blank' no_decoration href={`${FAMILY}/${id}/${SOURCES}`} className={cn('my-auto ms-2', s.icon)}><InvestigateIcon /></TextLink>
        }

      </div>
    </div>
  )
}

export default withUser(FamilyId)