import React from 'react'
import { withRouter } from 'react-router-dom'

import { DashboardSmallTile } from './DashboardTile.js'
import { DashboardTileDesc } from './DashboardTileDesc.js'
import { TileHelpLink } from './TileHelpLink.js'
import { get_custom_clustered_report_help_url } from '../../utils/help_utils.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'
import { DashboardTileInner } from './DashboardTileInner.js'
import { BUILD_CUSTOM_CLUSTERED_REPORT } from '../../constants/paths.js'

const CustomUploadPanel = ({history}) => {
  function on_click_from_new_report_btn() {
    track_report_builder_event('action="select_builder" report_type="custom_clustered" context="dashboard"')
    history.push(BUILD_CUSTOM_CLUSTERED_REPORT)
  }

  return (
    <DashboardSmallTile title='Custom clustered report' hint={<TileHelpLink help_url={get_custom_clustered_report_help_url()} />} on_click={on_click_from_new_report_btn}>
      <DashboardTileInner>
        <DashboardTileDesc>
          Create a new manually clustered report
        </DashboardTileDesc>
      </DashboardTileInner>
    </DashboardSmallTile>
  )
}

export default withRouter(CustomUploadPanel)