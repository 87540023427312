import React, { useState } from 'react'

import { Table } from '../widgets/Table.js'
import {
  format_status_with_role,
  is_role_defensive,
  PAGING_THRESHOLD,
  render_cell
} from '../../utils/disputes_utils.js'
import { TABLE_COLUMN_HEADER } from '../../model/table.js'
import { OUTCOMES } from '../../model/disputes.js'
import { PaginationControls } from '../widgets/Pagination.js'
import { DEFAULT_PAGE_SIZE, PAGE_SIZES } from '../../model/disputes_table_page_sizes.js'
import SortingColumnHeaderCell from '../patent_family_list/SortingColumnHeaderCell.js'
import { sort_table_data } from '../../utils/item_utils.js'

export const RoleDisputesSummaryTable = ({summary, dispute_role, selected_sort_field_id, selected_sort_direction_id, handle_change_sort_field_and_direction_id}) => {
  const [page, set_page] = useState(0)
  const [page_size, set_page_size] = useState(DEFAULT_PAGE_SIZE)

  function set_selected_page_size(selected_page_size) {
    set_page_size(selected_page_size)
    set_current_page(0)
  }

  function set_current_page(current_page) {
    set_page(current_page)
  }

  function on_change_sort_field_and_direction_id(sort_field_id, sort_direction_id) {
    handle_change_sort_field_and_direction_id(sort_field_id, sort_direction_id)
  }

  function get_sorted_paginated_table_data() {
    const sorted_data = sort_table_data(summary || [], selected_sort_field_id, selected_sort_direction_id)

    if (!data_needs_pagination) {
      return sorted_data
    }

    const first_row_idx = page * page_size
    const last_row_idx = ( page * page_size) + page_size

    return sorted_data.slice(first_row_idx, last_row_idx)
  }

  function get_columns() {
    const outcome_columns = OUTCOMES.map(field => (
      {
        ...TABLE_COLUMN_HEADER,
        id: field,
        label: format_status_with_role(field, dispute_role),
        field: field,
        cell_render: (row) => render_cell(row.value),
        className: 'text-center',
        headerClassName: 'text-endbold'
      }
    ))

    const table_columns = [
      {
        ...TABLE_COLUMN_HEADER,
        id: 'portfolio_name',
        label: 'Organisation',
        field: 'portfolio_name',
        headerClassName: 'text-start text-endbold'
      },
      {
        ...TABLE_COLUMN_HEADER,
        id: 'total',
        label: 'Total',
        field: 'total',
        cell_render: (row) => render_cell(row.value),
        className: 'text-center',
        headerClassName: 'text-endbold'
      },
      ...outcome_columns,
      ...(is_role_defensive(dispute_role) ? [{
        ...TABLE_COLUMN_HEADER,
        id: 'npe',
        label: 'NPEs',
        field: 'npe',
        className: 'text-center',
        headerClassName: 'text-endbold'
      }] : [])
    ]

    const columns_with_sort = table_columns.map(column => {
      const {id, label} = column

      return {
        ...column,
        sortable: false,
        header: function get_header() {
          return (
            <SortingColumnHeaderCell
              field={{id, label, name: label}}
              on_change_sort_field_id_and_sort_direction_id={on_change_sort_field_and_direction_id}
              selected_sort_field_id={selected_sort_field_id}
              selected_sort_direction_id={selected_sort_direction_id}
            />
          )},
      }
    })

    return columns_with_sort
  }

  const total_data_rows = (summary ||[]).length
  const num_pages = Math.ceil(total_data_rows / page_size)
  const data_needs_pagination = total_data_rows > PAGING_THRESHOLD

  if ((page >= num_pages) && data_needs_pagination) {
    set_current_page(0)
  }


  return (
    <div>

      {data_needs_pagination &&
        <div className='d-flex justify-content-end mb-1'>
          <PaginationControls
            className={'ms-auto'}
            num_pages={num_pages}
            selected_page_size={page_size}
            on_change_page_size={set_selected_page_size}
            current_page={page}
            on_change_current_page={set_current_page}
            available_page_sizes={PAGE_SIZES}
          />
        </div>
      }

      <Table
        columns={get_columns()}
        data={get_sorted_paginated_table_data()}
        manual
      />

    </div>
  )
}