import React from 'react'
import _ from 'underscore'
import cn from 'classnames'
import {DropdownItem, FormGroup, Label} from 'reactstrap'
import CalendarDatePicker from '../widgets/CalendarDatePicker.js'
import BaseDropdown from '../widgets/BaseDropdown.js'

import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'
import { SELECTED_PORTFOLIOS } from '../../model/deref.js'
import { get_as_map } from '../../utils/utils.js'
import {
  APRIORI_CUTOFF_PARAM_NAME,
  FILING_STRATEGY_PARAM_NAME,
  OVERLAP_PARAM_NAME,
  STATUS_FILTERS_PARAM_NAME,
  VERSION_PARAM_NAME
} from '../../model/hyperscripts.js'
import { RadiobuttonWithLabel } from '../widgets/RadiobuttonWithLabel.js'
import { can_select_nd_version } from '../../utils/user_permissions.js'
import { FormFeedback } from '../widgets/FormFeedback.js'
import NumberInputStepper from '../widgets/NumberInputStepper.js'
import { overlap_unavailable_reason } from '../../utils/overlap_utils.js'

import 'react-datepicker/dist/react-datepicker.css'
import '../../css_overrides/react-datepicker.override.scss'

import s from './HyperscriptSetupParams.module.scss'

export const CheckboxList = ({setup_params, current_param_values, on_change, is_inline_list, disabled, user, report_has_scores}) => {
  return (
    <>
      {setup_params.map((item, i) => {
        const {label, id, check_if_available} = item

        const is_available = check_if_available ? check_if_available({user, report_has_scores}) : true
        if (!is_available) return null

        const is_checked = current_param_values[id]
        return (
          <CheckboxAndLabel
            label={label}
            is_checked={is_checked}
            is_disabled={disabled}
            className={is_inline_list ? 'me-3' : ''}

            on_click={() => {
              const updated_values = {...current_param_values, [id]: !is_checked}
              on_change(updated_values)
            }}
            key={i}
          />
      )})}
    </>
  )
}

export const NdReportSetupParams = ({ user, current_param_values, on_change, disabled }) => {
  function on_version_change(selected_version) {
    on_change({...current_param_values, [VERSION_PARAM_NAME]: selected_version})
  }

  const selected_version = current_param_values[VERSION_PARAM_NAME] || 1

  return (
    <>
      <FormGroup className='mb-2'>
        <Label className={cn('ms-0 pe-2')}>
          Exclude families expiring before (optional):
        </Label>
        <CalendarDatePicker
          selected_date={current_param_values['expiry_cutoff']}
          on_date_change={date => on_change({...current_param_values, expiry_cutoff: date})}
          disabled={disabled}
          is_clearable={true}
          className='w-100'
        />
      </FormGroup>

      {can_select_nd_version(user) &&
        <FormGroup className='d-flex mb-2'>
          <Label >Version:</Label>
          <RadiobuttonWithLabel className='ms-2' label='1' is_checked={selected_version === 1} on_click={() => on_version_change(1)}/>
          <RadiobuttonWithLabel className='ms-1' label='2' is_checked={selected_version === 2} on_click={() => on_version_change(2)}/>
        </FormGroup>
      }
    </>
  )
}

export const PublicationsSetupParams = ({setup_params, current_param_values, on_change, disabled, user, report_has_scores}) => {
  return <CheckboxList
    setup_params={setup_params}
    current_param_values={current_param_values}
    on_change={on_change}
    disabled={disabled}
    user={user}
    report_has_scores={report_has_scores}
  />
}

export const BenchmarkMetricsSetupParams = ({setup_params, current_param_values, on_change, disabled}) => {
  return <CheckboxList
    setup_params={setup_params}
    current_param_values={current_param_values}
    on_change={on_change}
    disabled={disabled}
  />
}

export const SPIFSetupParams = ({setup_params, current_param_values, on_change, disabled}) => {
  return <CheckboxList
    setup_params={setup_params}
    current_param_values={current_param_values}
    on_change={on_change}
    disabled={disabled}
  />
}

export const OverlapSetupParams = ({current_param_values, report_input, deref_data, on_change, disabled}) => {
  const is_org_selected = current_param_values[OVERLAP_PARAM_NAME]
  const {technology_partitioning} = report_input || {}
  const help_prompt = overlap_unavailable_reason({deref_data, is_org_selected, technology_partitioning})

  function on_click(is_org_selected) {
    on_change({[OVERLAP_PARAM_NAME]: is_org_selected})
  }

  return (
    <FormGroup>
      <Label>Measure overlap of:</Label>
      <RadiobuttonWithLabel label='technologies'  is_disabled={disabled} is_checked={!is_org_selected} on_click={() => on_click(false)}/>
      <RadiobuttonWithLabel label='organisations' is_disabled={disabled} is_checked={is_org_selected}  on_click={() => on_click(true)} />
      <FormFeedback
        valid={help_prompt === null}
        validation_text={help_prompt}
      />
    </FormGroup>
  )
}

export const FilingStrategySetupParams = ({deref_data, current_param_values, on_change, disabled}) => {
  const selections = deref_data || {}

  const selected_portfolios = selections[SELECTED_PORTFOLIOS] || []

  const portfolio_by_id = get_as_map(selected_portfolios, 'id')
  const selected_portfolio = portfolio_by_id[current_param_values[FILING_STRATEGY_PARAM_NAME]] || {}

  const portfolio_by_name = _.indexBy(selected_portfolios, (item) => (item.name+item.id)) //names are no unique

  const multiple_portfolios = selected_portfolios.length >= 2

  return (
    <FormGroup>
      <BaseDropdown
        disabled={disabled}
        label={selected_portfolio.name || 'Select organisation'}
        menuClassName={s.menu}
      >
        {Object.keys(portfolio_by_name || []).sort().map((name_and_id, i) => {
          const item = portfolio_by_name[name_and_id]
          const {id, name} = item

          return (
            <DropdownItem key={i} active={selected_portfolio && selected_portfolio.id === id} onClick={() => on_change({[FILING_STRATEGY_PARAM_NAME]: id})}>
              {name}
            </DropdownItem>
          )})}
      </BaseDropdown>
      <FormFeedback valid={multiple_portfolios} validation_text='Please make sure the report includes multiple organisations.'/>
    </FormGroup>
  )
}

export const GeoComparisonSetupParams = ({current_param_values, on_change}) => {
  const filing_status_options = {
    'Granted patents': `granted`,
    Applications: `pending`,
    Both: `granted,pending`
  }
  const selected_filing_status = _.invert(filing_status_options)[current_param_values[STATUS_FILTERS_PARAM_NAME]]
  return (
    <FormGroup className={cn(s.block__overlap)}>
      <Label>Filing status:</Label>
      {Object.keys(filing_status_options).map((o_key, idx) => {
        return (
          <RadiobuttonWithLabel
            label={o_key} key={idx}
            is_checked={o_key===selected_filing_status}
            on_click={() => on_change({[STATUS_FILTERS_PARAM_NAME]: filing_status_options[o_key]})}
          />
        )
      })}
    </FormGroup>
  )
}

export const TrendspotterSetupParams = ({current_param_values, on_change}) => {
  const min_apriori = 1
  const max_apriori = 5

  const selected_apriori_cutoff_year = current_param_values[APRIORI_CUTOFF_PARAM_NAME]
  return (
    <FormGroup className={cn(s.flex_form_group)}>
      <Label>Apriori cutoff years:</Label>
      <NumberInputStepper
        className='ms-2'
        value={selected_apriori_cutoff_year}
        on_change={new_value => on_change({[APRIORI_CUTOFF_PARAM_NAME]: new_value})}
        min={min_apriori}
        max={max_apriori}
        is_strict_range={true}
      />
    </FormGroup>
  )
}