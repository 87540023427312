import React from 'react'
import cn from 'classnames'

import TextLink from '../widgets/TextLink.js'
import ForwardBackSelector from '../widgets/ForwardBackSelector.js'
import {
  ExternalLinkIcon,
  RotateLeftIcon,
  RotateRightIcon,
  TransformResetIcon,
  ZoomInIcon,
  ZoomOutIcon
} from '../widgets/IconSet.js'

import s from './ImageControls.module.scss'
import { TertiaryButton } from '../widgets/Button.js'

export const ImageTransformControls = ({className, on_flip_left, on_flip_right, on_zoom_out, on_zoom_in, on_reset, image_meta}) => {
  const { magnify=1 } = image_meta
  
  return (
    <div className={cn('d-flex justify-content-end', className)}>
      <TertiaryButton title='Rotate left' className={cn('me-1', s.control)} onClick={on_flip_left} icon_only><RotateLeftIcon/></TertiaryButton>
      <TertiaryButton title='Rotate right' className={cn('me-1', s.control)} onClick={on_flip_right} icon_only><RotateRightIcon/></TertiaryButton>
      <TertiaryButton title='Zoom out' disable={magnify === 1} className={cn('me-1 ', s.control)} onClick={on_zoom_out} icon_only><ZoomOutIcon/></TertiaryButton>
      <TertiaryButton title='Zoom in' disable={magnify === 20} className={cn('me-1', s.control)} onClick={on_zoom_in} icon_only><ZoomInIcon/></TertiaryButton>
      <TertiaryButton title='Reset' className={s.control} onClick={on_reset} icon_only><TransformResetIcon/></TertiaryButton>
    </div>
  )
}

export const ImageExportControls = ({className, selected_image_url, show_link}) => {
  
  return (
    <div className={cn(className)}>
      {show_link &&
        <TextLink
          className={s.control}
          element={'a'}
          target='_blank'
          href={selected_image_url}
          no_decoration
        >
          <ExternalLinkIcon/>
        </TextLink>
      }
    </div>
  )
}

export const ImageBrowseControls = ({className, current_idx, max_idx, on_change_handler}) => {
  return (
    <div className={cn('d-flex justify-content-center', className)}>
      <ForwardBackSelector
        current_idx={current_idx}
        min_idx_incl={0}
        max_idx_excl={max_idx}
        on_prev={on_change_handler.bind(null, current_idx - 1)}
        on_next={on_change_handler.bind(null, current_idx + 1)}
        label='Image'
      />
    </div>
  )
}