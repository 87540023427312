import React from 'react'
import cn from 'classnames'
import { withRouter } from 'react-router-dom'

import {is_valid_report_name} from '../../../utils/name_utils.js'
import {get_project_viewer_url} from '../../../utils/report_url_utils.js'
import {track_project_viewer_event} from '../../../utils/tracking_utils.js'
import {withUser} from '../../UserContext.js'
import {ProjectIcon} from '../../widgets/IconSet.js'
import EditableTextLink from './EditableTextLink.js'
import { Project } from '../../project_management/model/project'

import s from './ProjectNameField.module.scss'

export interface ProjectNameFieldProps {
  user?: any,
  history?: any,
  project: Project,
  is_renaming_project: boolean,
  tracking_context: string,
  on_edit_project_name: Function,
  on_confirm_project_rename: Function,
  on_cancel_edit_project_name: Function,
}

const ProjectNameField = (props: ProjectNameFieldProps) => {
  function render(): JSX.Element {
    const url = get_project_viewer_url(props.project.project_id)

    const tracking_ctx = `obj="project" action="show" context="${props.tracking_context}"`

    function on_click_link() {
      track_project_viewer_event(tracking_ctx)
      props.history.push(url)
    }

    return (
      <div>
        <div className={'d-flex flex-wrap mb-1'}>
          <div className={cn('d-flex', 'flex-wrap', s.editable_project_name)}>
            <EditableTextLink
              link_text={props.project.name}
              icon={<ProjectIcon/>}
              on_edit={props.on_edit_project_name}
              is_valid={is_valid_report_name}
              to={undefined}
              on_click={on_click_link}
              on_confirm={props.on_confirm_project_rename}
              on_cancel={props.on_cancel_edit_project_name}
              is_editable={props.project.current_user_permissions.edit}
              is_clickable={props.project.current_user_permissions.view}
              is_edit={props.is_renaming_project}
              disable={undefined}
              invalid_message={undefined}
              input_id={undefined}
              inputClassName={undefined}
            />
          </div>
        </div>
      </div>
    )
  }

  return render()
}

export default withRouter(withUser(ProjectNameField))