export const PROJECT_TRACKING_CONTEXT = 'project_history'

export const TAB_REPORTS_AND_FILES = 'reports_and_files'
export const TAB_PERMISSIONS = 'permissions'

export const ENTITY_TYPE_ID_USER = 1
export const ENTITY_TYPE_ID_GROUP = 2

// table fields
const FIELD_ID_ENTITY_TYPE = 'entity_type'
export const FIELD_ID_ENTITY_ID = 'entity_id'
const FIELD_ID_HAS_EDIT_PERMISSION = 'edit'
const FIELD_ID_HAS_VIEW_PERMISSION = 'view'
// files table
export const FIELD_ID_FILENAME = 'filename'
const FIELD_ID_FILE_SIZE = 'size'
const FIELD_ID_LAST_MODIFIED = 'last_modified'
const FIELD_ID_FILE_ACTIONS = 'actions'

export const FIELD_ENTITY_TYPE = {id: FIELD_ID_ENTITY_TYPE, accessor: FIELD_ID_ENTITY_TYPE, name: 'Type', sortable: true}
export const FIELD_ENTITY_NAME = {id: FIELD_ID_ENTITY_ID, accessor: FIELD_ID_ENTITY_ID, name: 'Name', sortable: true}
export const FIELD_EDIT_PERMISSION = {id: FIELD_ID_HAS_EDIT_PERMISSION, accessor: FIELD_ID_HAS_EDIT_PERMISSION, name: 'Can edit', sortable: false}
export const FIELD_VIEW_PERMISSION = {id: FIELD_ID_HAS_VIEW_PERMISSION, accessor: FIELD_ID_HAS_VIEW_PERMISSION, name: 'Can view', sortable: false}

export const FIELD_FILENAME = {id: FIELD_ID_FILENAME, accessor: FIELD_ID_FILENAME, name: 'Name', sortable: true}
export const FIELD_FILE_SIZE = {id: FIELD_ID_FILE_SIZE, accessor: FIELD_ID_FILE_SIZE, name: 'Size', sortable: true}
export const FIELD_LAST_MODIFIED = {id: FIELD_ID_LAST_MODIFIED, accessor: FIELD_ID_LAST_MODIFIED, name: 'Last modified', sortable: true}
export const FIELD_FILE_ACTIONS = {id: FIELD_ID_FILE_ACTIONS, accessor: FIELD_ID_FILE_ACTIONS, name: 'Actions', sortable: false}