import React, { useState } from 'react'
import cn from 'classnames'

import { send_set_password_email } from '../../utils/user_management_utils.js'

import Spinner from '../widgets/Spinner.js'
import ErrorBody from '../ErrorBody.js'

import Modal from '../widgets/Modal.js'
import { PrimaryButton } from '../widgets/Button.js'

const SendPasswordEmailModal = ({
  user,
  on_close,
}) => {

  const [is_sending, set_is_sending]       = useState(false)
  const [error_sending, set_error_sending] = useState(null)
  const [is_sent_ok, set_is_sent_ok]       = useState(false)

  function do_send() {
    set_is_sending(true)

    send_set_password_email(user.id)
      .catch(err => {
        // FAIL
        set_error_sending(err)
        throw err
      })
      .then(() => {
        // SUCCESS
        set_is_sent_ok(true)
      })
      .finally(() => {
        set_is_sending(false)
      })
  }

  return (
    <Modal
      size='sm'
      title={`Confirm send password email`}
      close_label={is_sent_ok ? 'Close' : 'Cancel'}
      on_hide={on_close}
      primary_button={
        <PrimaryButton
          onClick={do_send}
          disabled={is_sending || error_sending || is_sent_ok}
        >
          Send email
        </PrimaryButton>
      }
      footer={
        <div>
          {is_sending &&
            <Spinner
              size={'sm'}
              className={cn('me-1')}
            />
          }
          {is_sent_ok &&
            <span>Email sent ok</span>
          }
        </div>
      }
    >

      <div>
        <p>Send the set password email to user {user.email}</p>

        <p>Note the email will expire in 48 hours.</p>
      </div>

      {/* ERRORS */}
      {error_sending &&
        <ErrorBody
          error={error_sending}
          context={'sending set password email'}
        />
      }
    </Modal>
  )
}

export default SendPasswordEmailModal