import React from 'react'
import cn from 'classnames'
import { PS_CONTROL_DISABLED_GREY } from '../../constants/colours.js'

import s from './CheckboxStatic.module.scss'
import cs from '../cipher_styles.module.scss'

const CheckboxStatic = ({ is_checked, is_partial, is_disabled, title, onClick, className }) => {
  is_disabled = (is_disabled != null) ? is_disabled : false

  const tick_colour = is_disabled ? PS_CONTROL_DISABLED_GREY : 'white'

  return (
    <div className={cn(s.block, {[s.__enabled]: !is_disabled}, className)} title={title} >
      <input
        readOnly
        type='checkbox'
        onClick={!is_disabled? onClick : null}
        tabIndex={0}
        checked={is_checked}
        className={cn(s.input, {[cs.cursor_pointer]: !is_disabled})}
      />

      <div className={cn(
        s.checkbox, {
          [s.__checked]: is_checked || is_partial,
          [s.__disabled]: is_disabled,
          [s.__disabled_checked]: is_disabled && (is_checked || is_partial)
        })}>
        <svg viewBox='0 0 20 20'>
          {!is_checked && <path stroke={tick_colour} strokeWidth='3' fill='none'></path>}

          {(is_partial && !is_checked) && <path stroke={tick_colour} strokeWidth='6' fill='none' d='M2,2 V18 H18 V2 H0 Z'></path>}

          {is_checked && <path stroke={tick_colour} strokeWidth='3' fill='none' d='M2,9 L7,15 L18,4'></path>}

        </svg>
      </div>
    </div>
  )
}

export default CheckboxStatic
