import React, {useState} from 'react'
import moment from 'moment'
import cn from 'classnames'

import TextLink from '../widgets/TextLink.js';
import { InfoIcon } from '../widgets/IconSet.js'
import Modal from '../widgets/Modal.js'

import { get_tasks } from '../../utils/report_progress_utils.js'
import { format_integer, format_integer_with_comma, is_number } from '../../utils/utils.js';
import { is_completed_status } from '../../utils/report_status_utils.js'

import {
  STATUS_QUEUED,
  STATUS_STARTED,
  STATUS_WAITING,
  STATUS_COMPLETED,
  STATUS_NOT_STARTED,
  STATUS_FAILED
} from '../../model/report_tasks_and_statuses.js'

import s from './ReportProgress.module.scss'


const SECONDS = 'seconds'

const STATUS_TO_LABEL = {
  [STATUS_QUEUED]: 'Queued',
  [STATUS_NOT_STARTED]: 'Not started',
  [STATUS_WAITING]: 'Waiting',
  [STATUS_STARTED]: 'Running',
  [STATUS_COMPLETED]: 'Complete',
  [STATUS_FAILED]: 'Failed'
}

function get_status_bar_class(status, percent_progress) {
  if (percent_progress) {
    return percent_progress === 100 ? s.status_bar_completed : s.status_bar_running
  }
  switch (status) {
    case (STATUS_COMPLETED):
      return s.status_bar_completed
    case (STATUS_STARTED):
      return s.status_bar_running
    case (STATUS_WAITING): case (STATUS_QUEUED): case (STATUS_NOT_STARTED):
      return s.status_bar_not_started
    default:
      return ''
  }
}

function format_start_time(time_iso_string) {
  if (!time_iso_string) {
    return null
  }
  return moment(time_iso_string).fromNow()
}

function get_classifier_remaining_time_string(estimated_duration) {
  if (!estimated_duration || !estimated_duration.secs) {
    return ''
  }

  return moment.duration(estimated_duration.secs * 1000).humanize()
}

function format_duration(task) {
  const {status, start_time, end_time, total_finished_runtime, task_details} = task || {}
  if (total_finished_runtime != null) {
    // for a group of tasks, show their combined, finished runtime if available
    return moment.duration(total_finished_runtime, SECONDS).humanize()
  } else if (end_time && start_time) {
    // if end time available, show time taken
    return moment(end_time).from(start_time, true)
  } else if (status === STATUS_STARTED && start_time && task_details && task_details.estimatedDuration) {
    // for CC tasks, show estimated time remaining
    return `${get_classifier_remaining_time_string(task.task_details.estimatedDuration)} remaining`
  }
  return null
}

const TaskProgress = ({ task }) => {
  const [is_show_details, set_is_show_details] = useState(false)

  const {label, details, status} = task || {}
  const {progress_percent} = details || {}
  const task_status = STATUS_TO_LABEL[status] || 'Unknown'

  return (
    <div className={cn(s.status_bar, get_status_bar_class(status, progress_percent))}>
      {is_show_details &&
        <Modal
          title={label}
          on_hide={() => set_is_show_details(false)}
          size='md'
        >
          <ClassifierTaskDetails
            task_status={status}
            task_details={details}
          />
        </Modal>
      }
      {task_status && !is_number(progress_percent) &&
        <span>{task_status}</span>
      }
      {is_number(progress_percent) &&
        <span className='ms-1'>{progress_percent === 100 ? 'Complete' : `${progress_percent}% complete`}</span>
      }
      {details &&
        <TextLink
          className='ms-1'
          onClick={() => set_is_show_details(!is_show_details)}
          no_decoration
        >
          <InfoIcon/>
        </TextLink>
      }
    </div>
  )
}

const ClassifierTaskDetails = ({ task_details, task_status }) => {
  const { num_classifiers, num_positives_found, num_pat_fams, progress_percent, estimated_duration } = task_details

  const num_classified = (is_number(num_pat_fams) && is_number(progress_percent)) ? num_pat_fams * (progress_percent / 100) : 0
  const progress_percent_string = progress_percent ? `${format_integer(progress_percent)}%` : '0%'

  const estimated_duration_string = get_classifier_remaining_time_string(estimated_duration)

  return (
    <table className={cn(s.classifier_task_details, 'table')}>
      <thead>
        <tr>
          <th>Number of Classifiers</th>
          <th>Positives</th>
          <th>Patent families classified</th>
          <th>Time remaining</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{num_classifiers}</td>
          <td>{format_integer_with_comma(num_positives_found)}</td>
          <td>{format_integer_with_comma(num_classified)} / {format_integer_with_comma(num_pat_fams)} ({progress_percent_string})</td>
          <td>{is_completed_status(task_status) ? 'None' : estimated_duration_string}</td>
        </tr>
      </tbody>
    </table>
  )
}

const ReportProgress = ({ choreo_status }) => {
  const tasks = get_tasks(choreo_status)
  const show_tasks = tasks && tasks.length > 0

  return (
    <div>
      {show_tasks &&
        <table className={cn('table', s.progress_table)}>
          <thead>
          <tr>
            <th>Task</th>
            <th>Progress</th>
            <th>Started</th>
            <th>Duration</th>
          </tr>
          </thead>
          <tbody>
          {tasks.map((task, i) => {
            return (
              <tr key={i}>
                <td>{task.label}</td>
                <td>
                  <TaskProgress
                    task={task}
                  />
                </td>
                <td>{format_start_time(task.start_time)}</td>
                <td>{format_duration(task)}</td>
              </tr>
            )
          })}
          </tbody>
        </table>
      }
    </div>
  )
}

export default ReportProgress