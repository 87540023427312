import React from 'react'
import cn from 'classnames'

import RadioStatic from './RadioStatic.js'

import cs from '../cipher_styles.module.scss'
import s from './RadiobuttonWithLabel.module.scss'

export const RadiobuttonWithLabel = ({label, is_checked, is_disabled, on_click, className, labelClassName, is_dark_bg}) => {
  return (
    <div className={cn('d-flex py-1', className)}>
      <RadioStatic
        is_checked={is_checked}
        is_disabled={is_disabled}
        onClick={on_click}
        is_dark_bg={is_dark_bg}
        className='my-auto'
      />{' '}<span onClick={is_disabled ? null : on_click} className={cn('ms-1 my-auto', s.label, {[s.label__disabled] : is_disabled && !is_checked}, {[cs.cursor_default]: is_disabled}, labelClassName)}>{label}</span>
    </div>
  )
}