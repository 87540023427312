import React, { useState, useEffect, useRef } from 'react'
import cn from 'classnames'
import _ from 'underscore'

import { send_error_to_sentry } from '../../utils/sentry_utils.js'
import { ProgressBanner } from '../viewer/ReportInProgressBanner.js'
import { withUser } from '../UserContext.js'
import Spinner from '../widgets/Spinner.js'
import {
  match_pub_or_pat_fams,
  match_pub_or_pat_fams_from_file_synch,
  is_big_file,
  match_pub_or_pat_fams_from_file_asynch,
  check_patent_matching_job_progress,
  file_details,
  cancel_patent_matching,
  ONE_COLUMN_UPLOAD_MODES,
  UPLOAD_MODE_SPIF,
  add_cc_prefix_to_patents,
  DOCUMENT_KIND,
  DEFAULT_COUNTRY_PREFIX,
  MAX_CHARS_FOR_SYNC_MATCHING,
  MODE_1_COLUMN,
  DEFAULT_UPLOAD_MODE,
  SPIF_FILE_UPLOAD_MODE_ID,
  TEXT_UPLOAD_MODE_ID,
  ID_TO_UPLOAD_MODE,
  FILE_UPLOAD_MODE_ID
} from '../../utils/custom_search_utils.js'
import { get_object_values } from '../../utils/utils.js'
import {
  consider_selected_matches,
  transform_matches_to_table_rows,
} from './table/matched_patents_table_utils.js'
import { BadInputAlertModal } from './BadInputAlertModal.js'
import OrgSuggestionsByFamiliesDisplay from '../orgs/OrgSuggestionsByFamiliesDisplay'
import { PatentUploadHelp } from './PatentUploadHelp.js'
import { PatentUploadOptions } from './PatentUploadOptions.js'
import { TextInputUpload } from './TextInputUpload.js'
import { FileInputUpload } from './FileInputUpload.js'
import UploadAction, { ReturnToInputBtn } from './UploadAction.js'
import { MatchedPatentsTable } from './table/MatchedPatentsTable.js'
import ProgressPrompt from '../builder/wizard/ProgressPrompt.js'
import { get_short_date_string_for_now } from '../../utils/time_utils.js'
import { OnOffButton, PrimaryButton } from '../widgets/Button.js'
import { SCROLL_INTO_VIEW_BEHAVIOUR_INSTANT, scroll_to_top } from '../../utils/scroll_utils.js'

import cs from '../cipher_styles.module.scss'
import s from './PatentUpload.module.scss'

const CHECK_MATCHING_INTERVAL_TIME_MS = 2 * 60 * 1000 // 2 min

const PatentUpload = (
  {
    user,
    available_upload_mode_ids,
    parsed_results_action_handler,
    external_items_for_suggestions,
    reset_items_for_suggestions_handler,
    update_group_handler,
    on_select_org_handler,

    job_id,

    is_wizard, is_wizard_final_step, can_wizard_continue,

    enable_rename,

    className
}) => {
  const container_ref = useRef()

  const [show_spinner, set_show_spinner] = useState(false)
  const [upload_mode, set_upload_mode] = useState(available_upload_mode_ids && available_upload_mode_ids.length > 0 ? available_upload_mode_ids[0] : DEFAULT_UPLOAD_MODE)
  const [portfolio_name, set_portfolio_name] = useState('')
  const [text_input, set_text_input] = useState('')
  const [is_upload_validated, set_is_upload_validated] = useState(false)
  const [table_rows, set_table_rows] = useState(null)
  const [parsing_portfolio_error, set_parsing_portfolio_error] = useState(null)

  const [input_for_1column_upload, set_input_for_1column_upload] = useState(null)
  const [file_input, set_file_input] = useState(null)

  const [submitted_job, set_submitted_job] = useState(null)
  const [polling_time, set_polling_time] = useState(null)
  const [progress_msg, set_progress_msg] = useState(null)

  const is_file_upload_submitted = submitted_job != null

  useEffect(() => {
    scroll_to_top(container_ref, SCROLL_INTO_VIEW_BEHAVIOUR_INSTANT)
  }, [])

  useEffect(() => {
    if (!input_for_1column_upload) return

    let did_cancel = false
    const is_spif = (upload_mode === SPIF_FILE_UPLOAD_MODE_ID)
    const {text_input, strict_mode, country_code_prefix, apps_only, pubs_only} = input_for_1column_upload

    let patents = text_input
    if (country_code_prefix) {
      patents = add_cc_prefix_to_patents(patents, country_code_prefix)
    }

    match_pub_or_pat_fams(patents, strict_mode, is_spif, apps_only, pubs_only)
      .then(response => {
        if (!did_cancel) {

          const matches_found = get_object_values(response.data)
          set_table_rows(transform_matches_to_table_rows(matches_found))
          set_is_upload_validated(true)
          set_show_spinner(false)
          set_input_for_1column_upload(null)
        }
      })
      .catch(error => {
        if (!did_cancel) {
          set_show_spinner(false)
          set_input_for_1column_upload(null)
          set_parsing_portfolio_error(error.msg)
        }
      })

    return () => {
      did_cancel = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input_for_1column_upload])

  useEffect(() => {
    if (!file_input) return
    let did_cancel = false
    const is_spif = (upload_mode === UPLOAD_MODE_SPIF.id)
    const file = file_input
    if (is_big_file(file)) {
      match_pub_or_pat_fams_from_file_asynch(file, is_spif, user.user_id)
        .then(response => {
          if (!did_cancel) {
            const job_number = response.data[0]
            set_submitted_job(job_number)
            set_progress_msg('queued')
            set_show_spinner(false)
          }
        })
        .catch(error => {
          if (!did_cancel) {
            set_show_spinner(false)
            set_file_input(null)
            set_parsing_portfolio_error(error)
          }
        })
    } else {
      match_pub_or_pat_fams_from_file_synch(file, is_spif)
        .then(response => {
          if (!did_cancel) {
            set_portfolio_name(get_default_portfolio_name())

            const matches_found = get_object_values(response.data)
            set_table_rows(transform_matches_to_table_rows(matches_found))
            set_is_upload_validated(true)
            set_show_spinner(false)
          }
        })
        .catch(error => {
          if (!did_cancel) {
            set_show_spinner(false)
            set_file_input(null)
            set_parsing_portfolio_error(error)
          }
        })
    }

    return () => {
      did_cancel = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file_input, user.user_id])

  useEffect(() => {
    if (submitted_job != null) {
      set_polling_time(CHECK_MATCHING_INTERVAL_TIME_MS)
    }
  }, [submitted_job])

  useEffect(() => {
    if (job_id != null) {
      set_submitted_job(job_id)
    }
  }, [job_id])

  function poll_patent_matching() {
    if (polling_time != null) {
      const interval = setInterval(check_submitted_job, polling_time)
      return () => clearInterval(interval)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(poll_patent_matching, [polling_time])

  function get_upload_options() {
    return (available_upload_mode_ids && available_upload_mode_ids.length > 0) ? available_upload_mode_ids.map(id => ID_TO_UPLOAD_MODE[id]) : ONE_COLUMN_UPLOAD_MODES
  }

  const [strict_selector, set_strict_selector] = useState(true)
  const [selected_country_code, set_selected_country_code] = useState(DEFAULT_COUNTRY_PREFIX)
  const [selected_document_kind, set_selected_document_kind] = useState(null)

  const show_similar_portfolios = external_items_for_suggestions && external_items_for_suggestions.name && external_items_for_suggestions.items
  const show_input = !is_upload_validated && !is_file_upload_submitted && !parsing_portfolio_error && !show_spinner && !show_similar_portfolios
  const show_results = is_upload_validated && !show_spinner && !parsing_portfolio_error && !show_similar_portfolios

  const is_text_input = (upload_mode === TEXT_UPLOAD_MODE_ID)
  const is_file_input = _.contains([FILE_UPLOAD_MODE_ID, SPIF_FILE_UPLOAD_MODE_ID], upload_mode)

  const portfolio = table_rows ? consider_selected_matches(table_rows) : []

  const can_submit_portfolio = portfolio.length > 0

  const upload_options = get_upload_options()
  const can_select_upload_mode = upload_options.length > 1

  return (
    <div className={cn('pt-3', className)} ref={container_ref}>
      {parsing_portfolio_error &&
        <BadInputAlertModal
          error_msg={parsing_portfolio_error.message || parsing_portfolio_error}
          title='Input parsing error'
          on_hide={() => set_parsing_portfolio_error(null)}
        />
      }

      {is_wizard &&
        <div className={cn('d-sm-flex', s.progress_prompt)}>
          <ProgressPrompt
            is_wizard_final_step={is_wizard_final_step}
            can_wizard_continue={can_wizard_continue || (show_results && can_submit_portfolio)}
            on_step_complete={handle_results}
            className={cs.border_none}
          >
            <span>Add list of families to report.</span>
          </ProgressPrompt>
          {show_results &&
            <ReturnToInputBtn
              on_click={on_edit_portfolio}
              className={cn('ms-sm-2 mt-2 mt-sm-0', cs.white_space_nowrap)}
            />
          }
        </div>
      }

      {show_spinner &&
        <div className='text-center mt-2'>
          <div>Fetching results</div>
          <Spinner/>
        </div>
      }
      
      {show_input &&
        <>
          {can_select_upload_mode &&
            <div className='mb-3'>
              {upload_options.map(item => {
                const {id, name} = item || {}

                return (
                  <OnOffButton
                    key={id}
                    is_on={id === upload_mode}
                    onClick={change_upload_mode.bind(null, id)}
                    className='px-3'
                  >
                    {name}
                  </OnOffButton>
                )
              })}
            </div>
          }
          <PatentUploadHelp
            className={cn('mb-3')}
            upload_mode={upload_mode}
          />
          {is_text_input &&
            <>
              <PatentUploadOptions
                className={cn('mb-1', s.options_wrapper)}
                strict_selector={strict_selector}
                toggle_strict_selector={toggle_strict_selector}
                selected_country_code={selected_country_code}
                set_selected_country_code={set_selected_country_code}
                selected_document_kind={selected_document_kind}
                set_selected_document_kind={set_selected_document_kind}
              />
              <TextInputUpload
                input_value={text_input}
                input_rows={10}
                placeholder={'Paste patents here'}
                on_change_input={(event) => set_text_input(event.target.value)}
                on_submit={on_submit_patent_upload}
                max_processing_size_input={MAX_CHARS_FOR_SYNC_MATCHING}
              />
            </>
          }
          {is_file_input &&
            <FileInputUpload
              on_change_file_upload={update_custom_patent_portfolio_from_file}
            />
          }
        </>
      }

      {is_file_upload_submitted && !show_spinner && !parsing_portfolio_error &&
        <div>
          <div>
          <ProgressBanner title='Processing uploaded file'/>
          <span className='text-endbold'>{file_details(file_input)}</span>
          <PrimaryButton className='ms-2' outline={true} onClick={cancel_submitted_job}>Cancel</PrimaryButton>
          </div>
          <div>{' '}Status: {progress_msg}</div>
        </div>
      }

      {show_results &&
        <>
          <UploadAction
            name={portfolio_name}
            on_submit_handler={handle_results}
            on_cancel_handler={on_edit_portfolio}
            on_name_change_handler={set_portfolio_name}
            show_buttons={!is_wizard}
            enable_rename={enable_rename}
            mode={MODE_1_COLUMN}
            can_submit={can_submit_portfolio}
            className='my-2'
          />

          <MatchedPatentsTable
            lines={table_rows}
            update_lines={set_table_rows}
            input_file={file_input}
            upload_mode={upload_mode}
            is_wizard={is_wizard}
          />
        </>
      }

      {show_similar_portfolios &&
        <OrgSuggestionsByFamiliesDisplay
          base_name={external_items_for_suggestions.name}
          base_items={external_items_for_suggestions.items}
          on_reset_suggestion={reset_items_for_suggestions_handler}
          on_click_suggestion={on_select_org_handler}
          update_group_handler={update_group_handler}
          tracking_context={'patent_upload'}
        />
      }
    </div>
  )

  function update_custom_patent_portfolio_from_file(file) {
    set_show_spinner(true)
    set_parsing_portfolio_error(null)
    set_file_input(file)
  }

  function on_edit_portfolio() {
    // Reset all state (but leave the text input)

    set_show_spinner(false)
    set_portfolio_name('')
    set_is_upload_validated(false)
    set_table_rows(null)

    set_parsing_portfolio_error(null)

    set_input_for_1column_upload(null)
    set_file_input(null)

    set_submitted_job(null)
    set_polling_time(null)
    set_progress_msg(null)
  }

  function get_default_portfolio_name() {
    return `Patent upload (${get_short_date_string_for_now()})`
  }

  function fetch_patent_families(strict_mode, country_code_prefix, apps_only, pubs_only) {
    set_show_spinner(true)
    set_parsing_portfolio_error(null)
    set_portfolio_name(get_default_portfolio_name())
    return set_input_for_1column_upload({text_input, strict_mode, country_code_prefix, apps_only, pubs_only})
  }

  function handle_results() {
    parsed_results_action_handler({name: portfolio_name, found_families: portfolio, table_rows})

    set_portfolio_name('')
    set_text_input('')
    set_is_upload_validated(false)
  }

  function check_submitted_job() {
    check_patent_matching_job_progress(submitted_job)
      .catch(error => {
        set_parsing_portfolio_error(error)
        set_submitted_job(null)
        set_polling_time(null)
      })
      .then(response => {
        if (response != null && response.data != null) {
          const msg = response.data
          if (msg.progress) {
            set_progress_msg(msg.progress)
          } else {
            const matches_found = get_object_values(msg)
            set_portfolio_name(get_default_portfolio_name())
            set_table_rows(transform_matches_to_table_rows(matches_found))
            set_is_upload_validated(true)
            set_submitted_job(null)
            set_polling_time(null)
            set_progress_msg(null)
          }
        }
      })
  }

  function cancel_submitted_job() {
    cancel_patent_matching(submitted_job)
      .catch(error => {
        send_error_to_sentry(error)
      })
      .then(() => {
        set_submitted_job(null)
        set_polling_time(null)
      })
  }

  function change_upload_mode(upload_mode_id) {
    set_upload_mode(upload_mode_id)
  }

  function on_submit_patent_upload() {
    fetch_patent_families(strict_selector, selected_country_code,
      (selected_document_kind === DOCUMENT_KIND[0]),
      (selected_document_kind === DOCUMENT_KIND[1])
    )
  }

  function toggle_strict_selector() {
    set_strict_selector(!strict_selector)
  }

}

export default withUser(PatentUpload)
