import _ from 'underscore'

import { DEFAULT_PORTFOLIO_ROLLUP_LIMIT } from '../model/portfolio_rollup_limits.js'
import {
  UTT_PORTFOLIO_TYPE,
  S3_ALL_FAMILIES_PORTFOLIO,
  PATENT_FAMILIES_PORTFOLIO_TYPE
} from '../model/portfolios.js'
import { DEFAULT_GROUP_BY_OWNER_LEVEL } from '../model/group_by_owner_level.js'
import {
  CLASSIFIER_LANDSCAPE_REPORT_TYPE,
  CUSTOM_CLUSTERED_REPORT_TYPE,
  DEFAULT_REPORT_TYPE,
  UTT_LANDSCAPE_REPORT_TYPE,
  UTT_REPORT_TYPE
} from '../constants/constants.js'
import {
  is_classifier_landscape_report_type
} from './report_utils.js'
import {
  get_technology_partitioning_by_manual_clustering,
  get_technology_partitioning_by_utt,
  TECH_PARTITIONING_TYPE_CLASSIFIER,
  TECH_PARTITIONING_TYPE_CLUSTERING,
  TECH_PARTITIONING_TYPE_CUSTOM
} from '../model/technology_basket.js'
import { get_clean_classifier_description } from './classifier_description_utils.js'
import { CLASSIFIER_SCORE_THRESHOLD_DEFAULT } from '../constants/report_input.js'
import { get_negatives_processing, NEGATIVES_PROCESSING_EXCLUDE } from '../model/negatives_processing.js'

const EVALUATION_INPUT_META_TYPE = 'classifier_evaluation'
const SET_THEORY_INPUT_META_TYPE = 'set_theory'

function classifier_sources_from_array(classifiers) {
  return classifiers.map(classifier => {
    const {classifier_id, name, path, version, description: classifier_description} = classifier

    const description = get_clean_classifier_description(classifier_description)

    return {
      id: classifier_id,
      name,
      path,
      version: version || 'latest',
      ...description ? {description} : {},
    }
  })
}

export function build_meta({evaluation_classifier_id, group_tech_mode, group_portfolio_mode, operands, formula, operable_selection}) {
  if (formula) {
    return {
      type: SET_THEORY_INPUT_META_TYPE,
      group_tech_mode,
      group_portfolio_mode,
      operands,
      formula,
      operable_selection,
      ...(evaluation_classifier_id ? {evaluation_classifier_id} : {}) // probably shouldn't be present in this kind of report anyway?
    }

  } else if (evaluation_classifier_id) {
    return {
      type: EVALUATION_INPUT_META_TYPE,
      evaluation_classifier_id
    }
  }
  return null
}

function build_classifier_technology_partitioning({classifiers, multi_label, negatives_processing, threshold}) {
  return {
    type: TECH_PARTITIONING_TYPE_CLASSIFIER,
    classifier_sources: classifier_sources_from_array(classifiers),
    multi_label,
    negatives_processing,
    threshold
  }
}

function build_technology_partitioning(
  {
    report_type,
    portfolios_to_cluster,
    classifiers,
    technologies,
    negatives_processing,
    multi_label,
    threshold,
    utt_class_ids,
    use_utt_superclasses,
    utt_version
  }) {

  if ([UTT_LANDSCAPE_REPORT_TYPE, UTT_REPORT_TYPE].indexOf(report_type) !== -1) {
    return get_technology_partitioning_by_utt({use_utt_superclasses, utt_version, utt_class_ids})
  }

  if (report_type === CUSTOM_CLUSTERED_REPORT_TYPE) {
    return get_technology_partitioning_by_manual_clustering(technologies)
  }

  const is_classifier_landscape = is_classifier_landscape_report_type(report_type)
  if (is_classifier_landscape) {
    return build_classifier_technology_partitioning({classifiers, multi_label, negatives_processing: get_negatives_processing({type: NEGATIVES_PROCESSING_EXCLUDE}), threshold: (threshold != null) ? threshold : CLASSIFIER_SCORE_THRESHOLD_DEFAULT})
  }

  if (classifiers && classifiers.length > 0 && !is_classifier_landscape) {
    return build_classifier_technology_partitioning({classifiers, multi_label, negatives_processing, threshold})
  }

  if (portfolios_to_cluster) {
    return {
      type: TECH_PARTITIONING_TYPE_CLUSTERING,
      ...portfolios_to_cluster ? { portfolios_to_cluster } : {}
    }
  }

  return null
}

export function build_utt_landscape_report_input({report_name, classifier, group_by_owner_level, portfolio_roll_up_limit, utt_version}) {
  const report_type = UTT_LANDSCAPE_REPORT_TYPE
  const utt_class_as_portfolio = {
    type: UTT_PORTFOLIO_TYPE,
    class_ids: [classifier.id]
  }
  return build_choreo_report_input({
    report_name,
    report_type,
    portfolios: [utt_class_as_portfolio],
    group_by_owner_level: group_by_owner_level || DEFAULT_GROUP_BY_OWNER_LEVEL,
    portfolio_roll_up_limit: portfolio_roll_up_limit || DEFAULT_PORTFOLIO_ROLLUP_LIMIT,
    technology_partitioning: build_technology_partitioning({report_type, utt_version})
  })
}

export function build_classifier_landscape_report_input({report_name, portfolios, classifiers, group_by_owner_level, multi_label, evaluation_classifier_id, portfolio_roll_up_limit, threshold}) {
  const report_type = CLASSIFIER_LANDSCAPE_REPORT_TYPE
  const technology_partitioning = build_technology_partitioning({report_type, classifiers, multi_label, threshold})
  const meta = build_meta({evaluation_classifier_id})

  return build_choreo_report_input({
    report_name,
    report_type,
    portfolios: portfolios || [S3_ALL_FAMILIES_PORTFOLIO],
    technology_partitioning,
    group_by_owner_level: group_by_owner_level || DEFAULT_GROUP_BY_OWNER_LEVEL,
    portfolio_roll_up_limit: portfolio_roll_up_limit || DEFAULT_PORTFOLIO_ROLLUP_LIMIT,
    meta
  })
}

export function build_classifier_alert_report_input({report_name, pat_fam_ids, classifier_name}) {
  const patfams_as_portfolio = {
    name: 'New and updated',
    type: PATENT_FAMILIES_PORTFOLIO_TYPE,
    pat_fam_ids,
    group_by_owner: true,
  }
  const technology_partitioning = {
    type: TECH_PARTITIONING_TYPE_CUSTOM,
    technologies: [{
      name: classifier_name,
      pat_fam_ids
    }]
  }
  return build_choreo_report_input({
    report_name,
    report_type: CUSTOM_CLUSTERED_REPORT_TYPE,
    portfolios: [patfams_as_portfolio],
    technology_partitioning
  })
}

export function build_generic_builder_report_input(
  {
    report_name,
    report_type,
    portfolios,
    portfolios_to_cluster,
    group_by_owner_level,
    portfolio_roll_up_limit,
    classifiers,
    evaluation_classifier_id,
    negatives_processing,
    multi_label,
    threshold,
    utt_class_ids,
    use_utt_superclasses,
    utt_version
  }) {
  const meta = build_meta({evaluation_classifier_id})

  const technology_partitioning = build_technology_partitioning({
    report_type,
    portfolios_to_cluster,
    classifiers,
    negatives_processing,
    multi_label,
    threshold,
    utt_class_ids,
    use_utt_superclasses,
    utt_version
  })

  return build_choreo_report_input({
    report_name,
    report_type,
    portfolios,
    technology_partitioning,
    group_by_owner_level,
    portfolio_roll_up_limit,
    meta
  })
}

export function build_manual_clustering_report_input({report_name, portfolios, technologies, evaluation_classifier_id}) {
  const report_type = CUSTOM_CLUSTERED_REPORT_TYPE

  const technology_partitioning = build_technology_partitioning({
    report_type,
    technologies
  })

  const meta = evaluation_classifier_id ? build_meta({evaluation_classifier_id}) : null

  return build_choreo_report_input({report_name, report_type, portfolios, technology_partitioning, meta})
}

export function build_choreo_report_input({report_name, report_type, portfolios, technology_partitioning, group_by_owner_level, portfolio_roll_up_limit, meta }) {
  const company_list_to_member_idx = {}
  portfolios.forEach((portfolio, i) => {
    const {company_list} = portfolio
    if (company_list) {
      const other_list_members = company_list_to_member_idx[company_list] || []
      company_list_to_member_idx[company_list] = [...other_list_members, i]
    }
  })
  const company_lists = _.isEmpty(company_list_to_member_idx) ? null : _.keys(company_list_to_member_idx).map(name => ({name, member_index: company_list_to_member_idx[name], tags: []}))

  const name = (report_name && report_name.length > 0) ? report_name : 'Untitled report'

  return {
    name,
    report_type: report_type || DEFAULT_REPORT_TYPE,
    portfolios: portfolios.map(portfolio => _.omit(portfolio, 'company_list')),
    technology_partitioning,
    ...(company_lists ? {company_lists} : {}),
    ...(group_by_owner_level ? {portfolio_group_by_owner_ancestor_type: group_by_owner_level} : {}),
    ...(portfolio_roll_up_limit ? {portfolio_roll_up_limit} : {}),
    ...(meta ? {meta} : {})
  }
}