import React, { useEffect, useRef } from 'react'

import AutoResizeTextArea from '../widgets/AutoResizeTextArea.js'

const SearchTextInput = (
  {
    input_text,
    on_change_input_text,
    auto_focus,
    containerClassName
  }) => {

  const input_ref = useRef()

  useEffect(() => {
    if (auto_focus) {
      // Workaround to ensure focus is set
      // This runs when the component first mounts
      input_ref.current.focus()
    }
  }, [auto_focus, input_ref])

  return (
    <AutoResizeTextArea
      placeholder='Enter patent numbers or families, a technology, &#10;paragraphs of text or a combination. &#13; &#10;Use a new line &crarr; for each criteria type'
      value={input_text || ''}
      on_change={on_change_input_text}
      auto_focus={auto_focus}
      rows={6}

      inner_ref={input_ref}

      containerClassName={containerClassName}
    />
  )
}

export default SearchTextInput