import React, { useState } from 'react'
import cn from 'classnames'

import { track_project_viewer_event } from '../../utils/tracking_utils.js'
import { ExportIcon } from '../widgets/IconSet.js'
import TextLink from '../widgets/TextLink.js'
import Spinner from '../widgets/Spinner.js'
import { trigger_download } from '../../utils/download_utils.js'
import {
  get_project_archive_cache_id,
  get_project_file_cache_id,
  fetch_project_all_files_as_zip,
  fetch_project_file
} from '../../utils/project_and_versioning_utils.ts'

import { PROJECT_TRACKING_CONTEXT } from '../../constants/project.js'
import ErrorModal from '../ErrorModal.js'

import s from './ProjectControls.module.scss'
import cs from '../cipher_styles.module.scss'

export const DownloadProjectFile = (
  {
    project_id,
    file_meta,
    is_disabled,

    className
  }) => {

  const { filename } = file_meta || {}
  const [is_fetching, set_is_fetching] = useState(false)

  const [fetch_error, set_fetch_error] = useState(null)

  function download_file() {
    track_project_viewer_event(`obj="project" action="download_file" context="${PROJECT_TRACKING_CONTEXT}"`)

    set_is_fetching(true)

    return get_project_file_cache_id(project_id, file_meta)
      .then(data => {
        const {id} = data || {}

        return fetch_project_file({uuid: id})
      })
      .then(arraybuffer => {
        trigger_download(arraybuffer, null, filename)
        set_is_fetching(false)
      })
      .catch(error => {
        set_fetch_error(error)
        set_is_fetching(false)
      })
  }

  return (
    <div className={className}>
      {fetch_error &&
        <ErrorModal
          error={fetch_error}
          on_hide={() => set_fetch_error(null)}
          context='downloading a project file'
        />
      }

      {is_fetching &&
        <div className={s.icon_wrapper}><Spinner size='xs'/></div>
      }

      {!is_fetching &&
        <TextLink
          onClick={download_file}
          title={`Download ${filename}`}
          disable={is_disabled || is_fetching}
          className={s.icon_wrapper}
          no_decoration
        >
          <ExportIcon/>
        </TextLink>
      }
    </div>
  )
}

export const DownloadAllProjectFiles = ({project_id, is_disabled, text_label, className}) => {
  const [is_fetching, set_is_fetching] = useState(false)

  const [fetch_error, set_fetch_error] = useState(null)

  function download_files() {
    set_is_fetching(true)

    track_project_viewer_event(`obj="project" action="download_all_files" context="${PROJECT_TRACKING_CONTEXT}"`)

    return get_project_archive_cache_id(project_id)
      .then(data => {
        const {id, filename} = data || {}

        return Promise.all([fetch_project_all_files_as_zip({uuid: id}), Promise.resolve(filename)])
      })
      .then(([arraybuffer, filename]) => {
        trigger_download(arraybuffer, null, filename)
        set_is_fetching(false)
      })
      .catch(error => {
        set_fetch_error(error)
        set_is_fetching(false)
      })
  }

  return (
    <div className={className}>
      {fetch_error &&
        <ErrorModal
          error={fetch_error}
          on_hide={() => set_fetch_error(null)}
          context='downloading project files'
        />
      }

      <TextLink
        onClick={download_files}
        title='Download all files'
        disable={is_disabled || is_fetching}
        no_decoration
      >
        <span className='d-flex'>
          <div className={s.icon_wrapper}>
            {is_fetching && <Spinner size='xs' />}
            {!is_fetching && <ExportIcon />}
          </div>
          {text_label && <span className={cn(cs.underline_on_hover, 'ms-1')}>{text_label}</span>}
        </span>
      </TextLink>
    </div>
  )
}
