import React from 'react'
import cn from 'classnames'

import { BaseTable } from './BaseTable.js'
import { TABLE_COLUMN_HEADER } from '../../model/table.js'
import { format_integer_with_comma } from '../../utils/utils.js'
import { get_key_items_sorted_by_value, get_keys_to_value, get_subselections } from '../../utils/column_data_utils.js'
import { get_key_from_spec } from '../../utils/spec_utils.js'
import { lighten_color } from '../../utils/color_utils.js'
import { get_csv_string } from '../../utils/csv_utils.js'

import s from './TabularView.module.scss'

function get_plotly_data({spec, status_filter, data, ref_data, key_dims, selections }) {
  const csv_data_rows = get_csv_value_rows({ spec, status_filter, data, ref_data, key_dims, selections})

  const csv_string = get_csv_string(csv_data_rows)

  return csv_string
}

function get_csv_value_rows({ spec, status_filter, data, ref_data, key_dims, selections  }) {
  const processed_data = process_data({ data, ref_data, selections, key_dims, spec })

  const tabular_data_rows = processed_data.map(row => {
    return row.map(item => item.value)
  })
  const { column_names, get_column_names } = spec
  const columns = get_column_names ? get_column_names({status_filter}) : column_names

  const header_row = columns

  return [
    header_row,
    ...tabular_data_rows
  ]
}

function process_data({data, ref_data, selections, key_dims, spec}) {
  const {portfolio_to_company_list={}} = ref_data
  const {selected_org_lists=[], spotlighted_orgs=[]} = selections

  const y_key = key_dims[0]
  const keys_to_value = get_keys_to_value(data, [0], 1, spec.show_average)
  const y_items_sorted = get_key_items_sorted_by_value(y_key, keys_to_value)

  const key_for_subselections = get_key_from_spec(spec)[0]

  return y_items_sorted.map((y_item, i) => {
    const {id, name} = y_item
    const list = portfolio_to_company_list[id] || {}
    const {id: list_id, name: list_name, is_target_org} = list
    const is_spotlighted = is_target_org || (spotlighted_orgs.indexOf(id) > -1)
    return [
      { value: i+1, is_spotlighted },
      { value: name, is_spotlighted },
      { value: selected_org_lists.indexOf(list_id) !== -1 ? list_name: '', is_spotlighted },
      { value: keys_to_value[id], subselection: get_subselections(false,{y_key: key_for_subselections}, {y_items: [y_item]}), is_spotlighted }
    ]
  })
}

function get_columns({spec, status_filter, set_families_subselections, is_report_deck}) {
  const { column_names, get_column_names, column_widths, can_sort_data } = spec
  const columns = get_column_names ? get_column_names({status_filter}) : column_names

  const y_header_classes = [s.header_cell, s.table_y_values, ...(is_report_deck ? [s.report_deck_value_cell] : [])]
  const y_cell_classes = [s.table_y_values, ...(is_report_deck ? [s.report_deck_value_cell] : [])]

  return [
    {...TABLE_COLUMN_HEADER,
      cell_render: row => (cell_render_value(row.value)),
      id: columns[0],
      label: columns[0],
      max_width: column_widths[0],
      headerClassName: cn(y_header_classes),
      className: cn(y_cell_classes),
      sortable: can_sort_data,
      field: row => (row[0]),
    },
    {...TABLE_COLUMN_HEADER,
      cell_render: row => (cell_render_value(row.value)),
      id: columns[1],
      label: columns[1],
      max_width: column_widths[1],
      headerClassName: cn(y_header_classes),
      className: cn(y_cell_classes),
      sortable: can_sort_data,
      field: row => (row[1]),
    },
    {...TABLE_COLUMN_HEADER,
      cell_render: row => (cell_render_value(row.value)),
      id: columns[2],
      label: columns[2],
      max_width: column_widths[2],
      headerClassName: cn(y_header_classes),
      className: cn(y_cell_classes),
      sortable: can_sort_data,
      field: row => (row[2]),
    },
    {...TABLE_COLUMN_HEADER,
      id: columns[3],
      label: columns[3],
      max_width: column_widths[3],
      headerClassName: cn(s.header_cell, s.table_x_values, {[s.report_deck_value_cell]: is_report_deck}),
      className: cn(s.table_x_values, {[s.report_deck_value_cell]: is_report_deck}),
      sortable: can_sort_data,
      field: row => (row[3] || 0),
      cell_render: row => (cell_render_value_and_click(row.value, set_families_subselections, true))
    }
  ]
}

function cell_render_value(item) {
  const {value, is_spotlighted} = item
  return (
    <span
      className={cn({'text-endbold': is_spotlighted})}
    >
      {value}
    </span>
  )
}

function cell_render_value_and_click(item, set_families_subselections, has_clickthroughs, formatter) {
  const {value, subselection, is_spotlighted} = item
  const formatted_value = formatter ? formatter(value) : format_integer_with_comma(value)
  return (
    <span
      className={cn({ [s.cell__clickthrough]: has_clickthroughs}, {'text-endbold': is_spotlighted})}
      onClick={() => set_families_subselections(subselection, value)}
    >
      {formatted_value}
    </span>
  )
}

const ExtendedTabularView = ({ spec, status_filter, data, ref_data, deref_data, key_dims, selections, set_families_subselections, is_report_deck, chart_wrapper_height, accent_color }) => {
  const columns = get_columns({
    spec,
    status_filter,
    set_families_subselections,
    is_report_deck
  })
  const tabular_data_rows = process_data({ref_data, deref_data, data, selections, key_dims, spec})

  const react_table_props = get_table_accent_color_props(accent_color)

  return (
    <BaseTable
      react_table_props={react_table_props}
      columns={columns}
      data={tabular_data_rows}
      className={'w-100 pb-2'}
      chart_wrapper_height={chart_wrapper_height}
    />
  )
}

function get_table_accent_color_props(accent_color) {
  if (!accent_color) {
    return {}
  }
  const border_color = lighten_color(accent_color, 1)
  const row_shade = lighten_color(accent_color, 0.2)
  return {
    getProps :() => ({style: {'borderColor': border_color}}),
    getTheadThProps: () => ({style: {'borderColor': border_color}}),
    getTdProps: (state, rowInfo) => ({style: {...(rowInfo.index % 2 !== 0 ? {background: row_shade} : {}), 'borderColor': border_color}}),
  }
}

ExtendedTabularView.get_plotly_data = get_plotly_data
export default ExtendedTabularView