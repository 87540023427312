import React from 'react'

import { CARDS, TABLE } from '../../../model/patent_family_list_views.js'
import { OnOffButton } from '../../widgets/Button.js'
import { CardsViewIcon, ListIcon } from '../../widgets/IconSet.js'
import { withUser } from '../../UserContext.js'
import { has_family_details_links } from '../../../utils/user_permissions.js'

import s from './PatentFamilyListViewSelector.module.scss'

const PatentFamilyListViewSelector = ({
  className,
  size,
  patent_family_list_views,
  patent_family_list_view_id,
  on_change_patent_family_list_view_id,
  user
}) => {

  if (!has_family_details_links(user)) {
    // users who can't use the details view don't have patent cards either
    return null
  }

  return (
    <div className={className}>
      {patent_family_list_views.map((item, i) => {
        const { id, name } = item
        const is_selected = (id === patent_family_list_view_id)
        return (
          <OnOffButton
            key={i}
            className='px-1 py-0'
            is_on={is_selected}
            size={size}
            onClick={() => on_change_patent_family_list_view_id(id)}
            title={name}
            icon_only={true}
          >
            {(id === TABLE) &&
              <ListIcon
                className={s.label_icon}
              />
            }
            {(id === CARDS) &&
              <CardsViewIcon
                className={s.label_icon}
              />
            }
          </OnOffButton>
        )
      })}
    </div>    
  )
}

export default withUser(PatentFamilyListViewSelector)