import React, { useState } from 'react'
import cn from 'classnames'

import Tooltip, { InfoPopover } from '../widgets/Tooltip.js'
import { InfoIcon, ChevronDownIcon } from '../widgets/IconSet.js'
import { format_string_first_character_capitalised } from '../../utils/utils.js'
import { format_spec_name } from '../../utils/spec_utils.js'
import { SPEC_GROUP_TO_BORDER_CLASSNAME, SPEC_GROUP_TO_TOOLTIP_OVERLAY_CLASSNAME } from './dataset_groups_styles.js'
import { DatasetSelectedMark } from './DatasetSelectToggle.js'

import s from './DatasetGroupQuickNav.module.scss'

const DatasetGroupQuickNavItem = ({name, description, group_id, is_selected, on_click, is_anything_selected, className}) => {
  const [tooltip_visible, set_tooltip_visible] = useState(false)

  function toggle_tooltip_visible() {
    set_tooltip_visible(!tooltip_visible)
  }

  return (
    <div
      className={cn(
      'p-1 d-flex justify-content-between',
        SPEC_GROUP_TO_TOOLTIP_OVERLAY_CLASSNAME[group_id],
        s.group_info_item
      )}
      onClick={on_click}
    >
      <span className='d-flex'>
        {is_anything_selected &&
          <span className={s.icon_wrapper}>
            {is_selected &&
              <DatasetSelectedMark />
            }
          </span>
        }
        {name}
      </span>
      <Tooltip
        is_in_modal={true}
        toggler={<span className={cn('ms-1', s.spec_info)}><InfoIcon /></span>}
        popupClassName={className}
        arrow={false}
        on_show={toggle_tooltip_visible}
        on_hide={toggle_tooltip_visible}
      >
        <>
          <div className='text-endbold'>{name}</div>
          <div>{description}</div>
        </>
      </Tooltip>
    </div>
  )
}

const DatasetGroupQuickNav = ({group_id, items, selected_items_ids, selected_count, on_click}) => {

  return (
    <InfoPopover
      toggler={(
        <div className={s.spec_info}>
          <ChevronDownIcon/>
        </div>
      )}
      popupClassName={cn(SPEC_GROUP_TO_BORDER_CLASSNAME[group_id], s.nav_menu)}
      arrow={false}
      buttonClassName='my-auto me-1'
      placement='bottom-end'
      interactive={true}
    >
      <div className='pt-2 pb-1'>
        {items.map((item, i) => {
          const { spec_id, description } = item
          const is_selected = selected_items_ids.indexOf(spec_id) > -1
          const nicely_formatted_name = format_string_first_character_capitalised(format_spec_name(item))
          return (
            <DatasetGroupQuickNavItem
              key={i}
              name={nicely_formatted_name}
              description={description}
              group_id={group_id}
              on_click={() => on_click(spec_id)}
              is_selected={is_selected}
              is_anything_selected={selected_count > 0}
              className={cn(SPEC_GROUP_TO_BORDER_CLASSNAME[group_id], s.nav_menu)}
            />
          )
        })}
      </div>
    </InfoPopover>
  )

}

export default DatasetGroupQuickNav