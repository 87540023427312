import _ from 'underscore'
import moment from 'moment'
import axios from 'axios'

import {
  STATUS_QUEUED,
  STATUS_STARTED,
  STATUS_WAITING,
  STATUS_COMPLETED,
  STATUS_FAILED,
  TASK_TO_DISPLAY_PROPERTIES
} from '../model/report_tasks_and_statuses.js'
import {TEXT_SEARCH_TASK_DESC, FETCHING_TASK_GROUP_LABEL, WRITING_TASK_GROUP_LABEL} from '../model/report_tasks_and_statuses.js'
import { REPORT_HISTORY_BASE_URL } from '../constants/urls.js'

// move to choreo utils?
const TYPE_FETCHING = 'fetching'
const TYPE_RUNNING = 'running'
const TYPE_WRITING = 'writing'

const SECONDS = 'seconds'

function get_tasks(choreo_status) {
  const {tasks} = choreo_status

  if (!tasks || !tasks.length) {
    return
  }

  const report_is_queued = choreo_status.status === STATUS_QUEUED
  const task_groups = _.groupBy(tasks, t => t.type)

  const running_tasks = task_groups[TYPE_RUNNING] ?
    _.sortBy(set_properties_for_individual_tasks(task_groups[TYPE_RUNNING], report_is_queued), 'order') : []

  // text search is a 'running' task, but should always appear first
  const text_search_task = running_tasks.filter(t => t.task_description === TEXT_SEARCH_TASK_DESC)
  const running_tasks_main = running_tasks.filter(t => t.task_description !== TEXT_SEARCH_TASK_DESC)

  // we maybe don't get fetching tasks any more, but they might possibly return in future
  const fetching_task = task_groups[TYPE_FETCHING] ?
    set_properties_for_rolled_up_tasks(FETCHING_TASK_GROUP_LABEL, TYPE_FETCHING, task_groups[TYPE_FETCHING], report_is_queued) : null
  const writing_task = task_groups[TYPE_WRITING] ?
    set_properties_for_rolled_up_tasks(WRITING_TASK_GROUP_LABEL, TYPE_WRITING, task_groups[TYPE_WRITING], report_is_queued): null

  return [
    ...text_search_task,
    ...(fetching_task ? [fetching_task] : []),
    ...running_tasks_main,
    ...(writing_task ? [writing_task] : [])
  ]
}

function set_properties_for_individual_tasks(tasks, report_is_queued) {
  return tasks.map(task => {
    const display_properties = TASK_TO_DISPLAY_PROPERTIES[task.task_description]
    return { ...task, ...display_properties, ...(report_is_queued ? {status: STATUS_WAITING} : {})}
  })
}

function set_properties_for_rolled_up_tasks(task_group_label, task_type, tasks, report_is_queued) {
  return {
    label: task_group_label,
    'task_type': task_type,
    'status': task_group_status(tasks, report_is_queued),
    'start_time': earliest_start_time(tasks),
    'total_finished_runtime': total_finished_runtime(tasks)
  }
}

function task_group_status(tasks, report_is_queued) {
  if (report_is_queued) {
    return STATUS_QUEUED
  }
  const statuses = _.uniq(tasks.map(task => task.status))
  if (statuses.length === 1) {
    // if all tasks have the same status, status = this common status
    return _.first(statuses)
  }
  // if any tasks have been started in this batch, status = started
  if (_.contains(statuses, STATUS_STARTED) || _.contains(statuses, STATUS_COMPLETED)) {
    return STATUS_STARTED
  }
  // assume we are still waiting to begin
  return STATUS_WAITING
}

function earliest_start_time(tasks) {
  const started_tasks = tasks.filter(t => t.start_time !== null)
  if (started_tasks.length < 1) {
    return null  // none of these tasks has started yet
  }
  return _.first(_.sortBy(started_tasks, t => new Date(t.start_time))).start_time
}

function total_finished_runtime(tasks) {
  if (!_.every(tasks, t => t.status === STATUS_COMPLETED)) {
    return null // tasks have not all finished, so this is unknown
  }
  return _.reduce(tasks, (total, t) => total + runtime(t), 0)
}

function runtime(task) {
  if (task.start_time && task.end_time) {
    return moment(task.end_time).diff(moment(task.start_time), SECONDS)
  } else if (task.start_time) {
    return moment().diff(moment(task.start_time), SECONDS)
  }
  return 0
}

function is_final_status(status) {
  return _.contains([STATUS_COMPLETED, STATUS_FAILED], status)
}

function is_failed_status(status) {
  return status === STATUS_FAILED
}

function extract_failed_report_status_from_error(error) {
  const {message=''} = (error || {})

  try {
    const choreo_response = JSON.parse(message)
    if (is_failed_status(choreo_response.status)) {
      return choreo_response
    }
  } catch {
    return null
  }

  return null
}

function toggle_notify_when_report_completes(external_report_id, is_notify) {
  const params = {is_notify}
  return axios.put(`${REPORT_HISTORY_BASE_URL}/${external_report_id}/notify_when_complete`, null, {params})
}

export { get_tasks, is_final_status, is_failed_status, toggle_notify_when_report_completes, extract_failed_report_status_from_error }