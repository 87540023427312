import React, {useState, useEffect} from 'react'
import { Link, withRouter } from 'react-router-dom'
import moment from 'moment'
import _ from 'underscore'
import cn from 'classnames'
import { DropdownItem, FormGroup, Input } from 'reactstrap'

import {
  ALL_ALERT_TERRITORIES,
  ALL_TERRITORY_IDS,
  fetch_alert_to_modify,
  find_first_alert_date,
  find_next_date_for_saved_alert,
  get_alert_focus_description,
  get_available_alert_types,
  is_alert_params_valid,
  report_is_too_old_for_alerting,
  save_alert_from_report
} from '../../utils/alert_report_utils.js'
import { CheckboxList } from './HyperscriptSetupParams.js'
import {
  ALERT_FAMILY_STATUSES,
  ALERT_FREQUENCIES_BY_ID,
  ALERT_REPORT_TYPE_NAME_DELTA,
  ALERT_REPORT_TYPES_BY_ID,
  PUBLICATION_TYPES
} from '../../model/alert_reports.js'
import {
  ALERT_FILTER_TYPE_PVIX_THRESHOLD,
  ALERT_FILTER_TYPE_STATUS,
  ALERT_FILTER_TYPE_TERRITORY,
  ALERT_FREQUENCY_ID_QUARTERLY,
  ALERT_PARAM_NEW_FAMS,
  ALERT_PARAM_PUB_TYPE,
  ALERT_PUBLICATION_TYPE_APPLICATION,
  ALERT_PUBLICATION_TYPE_GRANT,
  ALERT_REPORT_FOCUS_FAMILIES,
  ALERT_TYPE_ID_DELTA,
  ALERT_TYPE_ID_NEW_FAMILIES,
  ALERT_TYPE_ID_NEW_FILINGS,
  ALERT_TYPE_ID_PVIX_INCREASE,
  ALERT_TYPE_ID_STATUS_CHANGE
} from '../../constants/alert_reports.js'
import { get_as_map } from '../../utils/utils.js'
import { RadiobuttonWithLabel } from '../widgets/RadiobuttonWithLabel.js'
import { InfoPopover } from '../widgets/Tooltip.js'
import { PrimaryButton, TertiaryButton } from '../widgets/Button.js'
import Modal from '../widgets/Modal.js'
import TerritorySelector from '../viewer/TerritorySelector.js'
import LabelledSlider from '../LabelledSlider.js'
import { CONTINENT_ID_TO_COUNTRY_IDS, IP5_COUNTRIES, UPC_COUNTRIES, CN } from '../../constants/countries.js'
import { STATUS_PENDING_ID } from '../../model/statuses.js'
import TextLink from '../widgets/TextLink.js'
import { EditIcon } from '../widgets/IconSet.js'
import { withUser } from '../UserContext.js'
import { has_pvix_enabled } from '../../utils/user_permissions.js'
import { MAX_REPORT_NAME_LENGTH } from '../../constants/constants.js'
import { is_valid_report_name } from '../../utils/name_utils.js'
import AlertScheduleDatePicker from '../subscriptions/AlertScheduleDatePicker.js'
import { FormFeedback } from '../widgets/FormFeedback.js'
import Label from '../widgets/Label.js'
import Spinner from '../widgets/Spinner.js'
import ErrorModal from '../ErrorModal.js'
import { ALERT_SETUP_ID } from '../../model/hyperscripts.js'
import { SUBSCRIPTIONS } from '../../constants/paths.js'
import { track_subscriptions_event } from '../../utils/tracking_utils.js'
import BaseDropdown from '../widgets/BaseDropdown.js'

import s from './AlertSetupParams.module.scss'
import cs from '../cipher_styles.module.scss'

const AlertSetupParams = (
  {
    user,
    params_id: alert_id,
    report_title,
    report_input,
    setup_params,
    current_param_values,
    internal_report_id,
    data_creation_date,
    on_change,
    disabled,
    location
  }) => {

  const [is_show_territory_modal, set_is_show_territory_modal] = useState(false)
  const [is_fetching_existing_params, set_is_fetching_existing_params] = useState(true)
  const [existing_saved_alert, set_existing_saved_alert] = useState(null)
  const [is_saving, set_is_saving] = useState(false)
  const [show_saved_message, set_show_saved_message] = useState(false)

  const [error_saving_alert, set_error_saving_alert] = useState(null)
  const [error_fetching_existing_params, set_error_fetching_existing_params] = useState(null)

  const {
    alert_name: input_alert_name,
    alert_type: selected_alert_type,
    frequency: selected_alert_frequency,
    filters,
    start_date
  } = current_param_values

  const alert_name = input_alert_name !== null ? input_alert_name : report_title
  const checkbox_params = setup_params.filter(param => param.is_checkbox)
  const alert_focus = get_alert_focus_description(report_input)
  const is_report_families_based = alert_focus === ALERT_REPORT_FOCUS_FAMILIES

  // existing alerts can be updated via this UI for older reports, but we shouldn't allow new alerts to be created
  const is_report_too_old_to_create_new_alert = data_creation_date && report_is_too_old_for_alerting(data_creation_date)

  // territories
  const selected_territories = filters.territory || ALL_TERRITORY_IDS

  // type
  const available_alert_types = get_available_alert_types(alert_focus, has_pvix_enabled(user))
  const alert_types_by_id = get_as_map(available_alert_types, 'id')

  // frequency
  const selected_alert_frequency_name = ALERT_FREQUENCIES_BY_ID[selected_alert_frequency].name

  // dates
  const max_first_alert_date = find_first_alert_date(ALERT_FREQUENCY_ID_QUARTERLY)
  const today_date = moment(new Date()).startOf('day').toDate()

  // extra filtering parameters
  const {new_families_only, pvix_threshold} = filters

  const status_param_values = ALERT_FAMILY_STATUSES.reduce((acc, item) => {
    acc[item.id] = _.contains(filters.status, item.id)
    return acc
  }, {})

  const pub_type_param_values = PUBLICATION_TYPES.reduce((acc, item) => {
    acc[item.id] = _.contains(filters.pub_type, item.id)
    return acc
  }, {})

  const is_families_alert = selected_alert_type === ALERT_TYPE_ID_NEW_FAMILIES
  const is_filings_alert = selected_alert_type === ALERT_TYPE_ID_NEW_FILINGS
  const is_status_alert = selected_alert_type === ALERT_TYPE_ID_STATUS_CHANGE
  const is_pvix_alert = selected_alert_type === ALERT_TYPE_ID_PVIX_INCREASE

  useEffect(() => {
    if (!alert_id) {
      set_is_fetching_existing_params(false)
      if (is_families_alert && is_report_families_based) {
        // the usual default is unavailable for this kind of report
        on_update_alert_type(ALERT_TYPE_ID_NEW_FILINGS)
      }
      return
    }
    let did_cancel = false
    set_is_fetching_existing_params(true)
    fetch_alert_to_modify(alert_id)
      .then(alert => update_setup_params_from_existing_alert(alert))
      .catch(err => {
        if (!did_cancel) {
          set_is_fetching_existing_params(false)
          set_error_fetching_existing_params(err)
        }
      })
    return () => {
      did_cancel = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert_id])

  function update_alert_id_in_url(alert_id) {
    const {pathname} = location
    const new_url = pathname.substring(0, pathname.indexOf(ALERT_SETUP_ID)) + ALERT_SETUP_ID + (alert_id ? `/${alert_id}` : '')
    window.history.replaceState(null, null, new_url)
  }

  function save_alert(save_as_new) {
    set_is_saving(true)
    track_save_alert(save_as_new)
    const alert_setup_params = save_as_new ? {...current_param_values, alert_id: null} : current_param_values
    save_alert_from_report({report_input, report_title, alert_setup_params, internal_report_id, data_creation_date})
      .then(({alert_id}) => {
        set_is_saving(false)
        set_show_saved_message(true)
        update_alert_id_in_url(alert_id)
        if (alert_id !== current_param_values.alert_id) {
          on_change({...current_param_values, alert_id})
        }
      })
      .catch(err => {
        set_error_saving_alert(err)
        set_is_saving(false)
      })
  }

  function track_save_alert(save_as_new) {
    const is_update = alert_id && !save_as_new
    const alert_type_name = (is_families_alert && filters.new_families_only) ? ALERT_REPORT_TYPE_NAME_DELTA : ALERT_REPORT_TYPES_BY_ID[selected_alert_type].name
    track_subscriptions_event(`obj="report_based_alert" action="${is_update ? 'update_existing' : 'create_new'}_alert" origin="report" alert_focus="${alert_focus}" alert_type="${alert_type_name}"`)
  }

  function update_setup_params_from_existing_alert(alert) {
    const {alert_id, alert_report_name, alert_frequency_id, alert_type_id, filters: existing_filters, skip_empty_alerts} = alert
    const is_delta_type_alert = alert_type_id === ALERT_TYPE_ID_DELTA
    const start_date = find_next_date_for_saved_alert(alert)
    const updated_filters = {
      ...filters,
      ...(is_delta_type_alert ? {[ALERT_PARAM_NEW_FAMS]: false} : {}),
      ...existing_filters.reduce((acc, filter) => {
        const {filter_type, filter_values} = filter
        if (filter_type === ALERT_FILTER_TYPE_PVIX_THRESHOLD) {
          acc[filter_type] = filter_values.threshold
        } else if (is_filings_alert && filter_type === ALERT_FILTER_TYPE_STATUS) {
          acc[ALERT_PARAM_PUB_TYPE] = filter_values.map(value => value === STATUS_PENDING_ID ? ALERT_PUBLICATION_TYPE_APPLICATION : ALERT_PUBLICATION_TYPE_GRANT)
        } else {
          acc[filter_type] = filter_values
        }
        return acc
      }, {})
    }
    const updated_params = {
      ...current_param_values,
      alert_id: alert_id,
      alert_name: alert_report_name,
      frequency: alert_frequency_id,
      alert_type: is_delta_type_alert ? ALERT_TYPE_ID_NEW_FAMILIES : alert_type_id,
      empty_alerts: !skip_empty_alerts,
      filters: updated_filters,
      start_date
    }
    on_change(updated_params)
    set_existing_saved_alert(alert)
    set_is_fetching_existing_params(false)
  }

  function on_update_params(updated_params) {
    set_show_saved_message(false)
    on_change({...current_param_values, ...updated_params})
  }

  function on_update_alert_type(alert_type) {
    on_update_params({alert_type})
  }

  function on_update_frequency(frequency) {
    if (existing_saved_alert) {
      const next_alert_date = find_next_date_for_saved_alert({...existing_saved_alert, alert_frequency_id: frequency})
      on_update_params({frequency, start_date: next_alert_date})
      return
    }
    const new_default_first_alert_date = find_first_alert_date(frequency)
    on_update_params({frequency, start_date: new_default_first_alert_date})
  }

  function on_update_selected_statuses(new_status_param_values) {
    const updated_statuses = Object.keys(new_status_param_values).filter(status => new_status_param_values[status])
    const updated_filters = {...filters, [ALERT_FILTER_TYPE_STATUS]: updated_statuses}
    on_update_params({filters: updated_filters})
  }

  function on_update_selected_pub_types(new_pub_type_param_values) {
    const updated_pub_types = Object.keys(new_pub_type_param_values).filter(pub_type => new_pub_type_param_values[pub_type])
    const updated_filters = {...filters, pub_type: updated_pub_types}
    on_update_params({filters: updated_filters})
  }

  function on_update_selected_territories(new_selected_territories) {
    const territories_sorted = new_selected_territories.sort()
    const updated_filters = _.isEqual(territories_sorted, ALL_TERRITORY_IDS.sort()) ?
      _.omit(filters, ALERT_FILTER_TYPE_TERRITORY) : {...filters, [ALERT_FILTER_TYPE_TERRITORY]: territories_sorted}
    on_update_params({filters: updated_filters})
  }

  function on_update_next_alert_date(new_next_alert_date) {
    on_update_params({start_date: new_next_alert_date})
  }

  function on_update_newness_preference(is_new_families_only) {
    const updated_filters = {...filters, new_families_only: is_new_families_only}
    on_update_params({filters: updated_filters})
  }

  function on_update_pvix_score_threshold(new_pvix_threshold) {
    const updated_filters = {...filters, pvix_threshold: new_pvix_threshold}
    on_update_params({filters: updated_filters})
  }

  function format_selected_territories_name() {
    const {territory} = filters
    if (!territory) {
      return 'All'
    } else if (_.isEmpty(territory)) {
      return 'None'
    } else if (territory.length === 1) {
      return `${territory[0]} only`
    } else if (_.isEqual(territory, IP5_COUNTRIES.sort())) {
      return 'IP5 countries'
    } else if (_.isEqual(territory, UPC_COUNTRIES.sort())) {
      return 'UPC countries'
    } else if (_.isEqual(territory, ALL_TERRITORY_IDS.filter(t => t !== CN ).sort())) {
      return 'All except China'
    } else {
      return territory.slice(0, 10).join(', ') + (territory.length > 10 ? '...' : '')
    }
  }

  if (is_fetching_existing_params) {
    return (
      <Spinner />
    )
  }

  const can_save_alert = is_alert_params_valid({current_param_values, is_report_families_based})

  return (
    <>
      {error_fetching_existing_params &&
        <ErrorModal
          on_hide={() => {
            set_error_fetching_existing_params(null)
            // remove alert id from url
            update_alert_id_in_url(null)
          }}
          error={error_fetching_existing_params}
          context='fetching parameters for existing alert'
        />
      }

      {error_saving_alert &&
        <ErrorModal
          on_hide={() => set_error_saving_alert(null)}
          error={error_saving_alert}
          context='saving alert'
        />
      }

      <FormGroup>
        {is_show_territory_modal &&
          <Modal
            className='w-50'
            title='Select territories for this alert'
            on_hide={() => set_is_show_territory_modal(false)}
            close_label={'Done'}
          >
            <TerritorySelector
              selector_id='alert-territory-filter'
              geos={ALL_ALERT_TERRITORIES}
              selected_geo_ids={selected_territories}
              continent_id_to_geo_ids={CONTINENT_ID_TO_COUNTRY_IDS}
              set_selected_geo_ids={on_update_selected_territories}
              show_ungrouped_families={true}
              report_has_ungrouped_families={false}
            />
          </Modal>
        }

        <div className='my-3 w-75'>
          <Label className='mb-1' is_input_invalid={!is_valid_report_name(alert_name)}>Alert name:</Label>
          <Input
            type='text'
            invalid={!is_valid_report_name(alert_name)}
            value={alert_name}
            onChange={e => on_update_params({alert_name: e.target.value})}
            autoComplete='off'
          />
          <FormFeedback
            valid={is_valid_report_name(alert_name)}
            validation_text={`Alert name cannot be empty or longer than ${MAX_REPORT_NAME_LENGTH} characters`}
          />
        </div>

        <div className='my-3 d-flex'>
          <div className='w-25'>
            <Label>Alert type:</Label>
            {Object.keys(alert_types_by_id).map(key => {
              const item = alert_types_by_id[key]
              const {name, id, description} = item
              return (
                <div className='d-flex' key={id}>
                  <RadiobuttonWithLabel
                    label={name}
                    className='me-1 mb-1'
                    is_checked={id === selected_alert_type}
                    on_click={() => on_update_alert_type(id)}/>
                  {description &&
                    <InfoPopover interactive={true}>{description}</InfoPopover>
                  }
                </div>
              )
            })}
          </div>
          <div>
            <div className='mb-3'>
              <Label className='d-block'>
                Territories:
              </Label>
              <div className={cn(s.input_block, cs.cursor_pointer)} onClick={() => set_is_show_territory_modal(true)}>
                <div className='p-1'>
                <span className='px-1'>
                  {format_selected_territories_name()}
                </span>
                  <TextLink
                    title='Select territories'
                    className='mx-1'
                    onClick={() => set_is_show_territory_modal(true)}
                    no_decoration
                  >
                    <EditIcon/>
                  </TextLink>
                </div>
              </div>
            </div>

            {is_families_alert && !is_report_families_based &&
              <>
                <Label className='d-block'>
                  Definition of new:
                </Label>
                <div className='mt-2 d-flex'>
                  <RadiobuttonWithLabel label='Newly published families' is_checked={new_families_only} on_click={() => on_update_newness_preference(true)}/>
                  <RadiobuttonWithLabel className='ms-3' label='All families new in the report' is_checked={!new_families_only} on_click={() => on_update_newness_preference(false)}/>
                </div>
              </>
            }

            {is_filings_alert &&
              <>
                <Label>Publication types:</Label>
                <div className='d-flex'>
                  <CheckboxList
                    setup_params={PUBLICATION_TYPES}
                    current_param_values={pub_type_param_values}
                    on_change={on_update_selected_pub_types}
                    is_inline_list
                  />
                </div>
              </>
            }

            {is_status_alert &&
              <>
                <Label>Statuses:</Label>
                <div className='d-flex'>
                  <CheckboxList
                    setup_params={ALERT_FAMILY_STATUSES}
                    current_param_values={status_param_values}
                    on_change={on_update_selected_statuses}
                    is_inline_list
                  />
                </div>
              </>
            }

            {is_pvix_alert &&
              <>
                <Label>PVIX threshold:</Label>
                <LabelledSlider
                  extent={[0, 100]}
                  value={[null, (pvix_threshold + 1)]}
                  show_end_only={true}
                  min_distance={10}
                  handleChange={(values) => {
                    const new_pvix_threshold = values[1] - 1
                    on_update_pvix_score_threshold(new_pvix_threshold)
                  }}
                />
              </>
            }

          </div>
        </div>
        <div className='my-3 d-flex'>
          <div className='w-25'>
            <Label>Alert frequency:</Label>
            <BaseDropdown
              label={selected_alert_frequency_name}
            >
              {Object.keys(ALERT_FREQUENCIES_BY_ID).map(key => {
                const item = ALERT_FREQUENCIES_BY_ID[key]
                const {id, name} = item
                return (
                  <DropdownItem
                    key={id}
                    active={selected_alert_frequency === id}
                    onClick={() => on_update_frequency(id)}>
                    {name}
                  </DropdownItem>
                )
              })}
            </BaseDropdown>
          </div>
          <div>
            <Label className='d-block'>{existing_saved_alert && existing_saved_alert.last_alert ? 'Next' : 'First'} alert:</Label>
            <div className={s.input_block}>
              <AlertScheduleDatePicker
                className={s.date_input}
                min_date={today_date}
                max_date={max_first_alert_date}
                on_select={on_update_next_alert_date}
                selected_date={start_date}
              />
            </div>
          </div>
        </div>
        <div className='mb-3'>
          <CheckboxList
            setup_params={checkbox_params}
            current_param_values={current_param_values}
            on_change={on_update_params}
            disabled={disabled}
          />
        </div>
      </FormGroup>

      {is_saving &&
        <Spinner/>
      }

      {show_saved_message &&
        <div>
            Alert saved. To view or modify details of your current alerts, go to <TextLink element={Link} to={SUBSCRIPTIONS}>Report-based alerts</TextLink>.
        </div>
      }

      {!is_saving && !show_saved_message &&
        <div>
          <PrimaryButton
            disabled={!can_save_alert}
            className='mt-3 mt-md-0'
            onClick={() => save_alert()}
          >
            { current_param_values.alert_id ? 'Save updates' : 'Create alert' }
          </PrimaryButton>

          { current_param_values.alert_id && !is_report_too_old_to_create_new_alert &&
            <TertiaryButton
              disabled={!can_save_alert}
              className='mt-3 mt-md-0 ms-3'
              onClick={() => save_alert(true)}
            >
              Create new alert
            </TertiaryButton>
          }
        </div>
      }
    </>
  )
}

export default withRouter(withUser(AlertSetupParams))