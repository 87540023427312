import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import _ from 'underscore'

import CheckboxGroupHeader from '../viewer/CheckboxGroupHeader.js'
import ScrollableList from '../widgets/ScrollableList.js'
import CheckboxTree from '../widgets/CheckboxTree.js'
import BaseDropdown from '../widgets/BaseDropdown'

import { get_tag_value_ids } from './family_tag_utils'
import Spinner from '../widgets/Spinner'
import Label from '../widgets/Label.js'
import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'

import cs from '../cipher_styles.module.scss'
import s from './FamilyTagsFilter.module.scss'
import ErrorBody from '../ErrorBody.js'

const FamilyTagsDisplay = (
  {
    show_spinner,
    all_tags,
    all_tags_as_items,
    apply_filter,

    set_apply_filter,
    on_filter_change,

    tags_error,
    className
  }) => {

  const [search_phrase, set_search_phrase] = useState('')
  const [checked_tags, set_checked_tags] = useState([])
  const [expanded_tags, set_expanded_tags] = useState([])

  function get_tags_as_nodes(filtered_tags) {
    return filtered_tags.map((tag) => {
      const tag_values_as_nodes = tag.values.map(tag_value => {
        return {value: tag_value.id, label: tag_value.value}
      })
      return {value: -tag.id, label: tag.name, children: tag_values_as_nodes}
    })
  }

  function on_check_node(new_checked_tags) {
    const new_selection = new_checked_tags.map(id => parseInt(id))
    set_checked_tags(new_selection)
    on_filter_change(new_selection)
  }

  function on_expand_node(new_expanded_tags) {
    const new_selection = new_expanded_tags.map(id => parseInt(id))
    set_expanded_tags(new_selection)
  }

  function select_all_tags() {
    const all_tag_value_ids = get_tag_value_ids(all_tags)
    set_checked_tags(all_tag_value_ids)
    on_filter_change(all_tag_value_ids)//no restrictions, to include patfams with tags the user don't have access to
  }

  function deselect_all_tags() {
    const new_selection = []
    set_checked_tags(new_selection)
    on_filter_change(new_selection)
  }

  function on_selected_filtered_ids(filtered_tag_ids) {
    set_checked_tags(filtered_tag_ids)
    on_filter_change(filtered_tag_ids)
  }

  function get_filtered_tags() {
    const search_phrase_lower_case = search_phrase.toLowerCase()
    const searched_tags = _.isEmpty(search_phrase) ? all_tags : all_tags.map(tag => {
      const any_tag_name_match = tag.name.toLowerCase().indexOf(search_phrase_lower_case) !== -1
      const matched_tag_values = tag.values.filter(tag_value => tag_value.value.toLowerCase().indexOf(search_phrase_lower_case) !== -1)
      if (any_tag_name_match) {
        // the phrase matches the name of the tag (parent)
        return tag
      }
      //the phrase matches the name of the tag value or none
      return matched_tag_values.length > 0 ? {...tag, values: matched_tag_values} : {...tag, id: -1}
    })
    return searched_tags.filter(tag => tag.id !== -1)
  }

  function select_deselect_filter() {
    const new_apply_filter = !apply_filter
    if (new_apply_filter === false) {
      //list all patfams in the list with no restrictions
      on_filter_change([], [])
    } else {
      on_filter_change(checked_tags)
    }
    set_apply_filter(new_apply_filter)
  }

  const filtered_tags = get_filtered_tags()

  const tags_as_nodes = get_tags_as_nodes(filtered_tags)

  const num_filtered_results = filtered_tags ? filtered_tags.length : 0
  const filtered_tags_as_items = _.flatten(filtered_tags.map(tag => tag.values))
  const is_filtered = (all_tags && filtered_tags) ? all_tags.length !== filtered_tags.length : false

  if (tags_error && !show_spinner) {
    return (
      <ErrorBody error={tags_error} context='fetching tags'/>
    )
  }

  return (
    <div className={className}>
      {show_spinner &&
        <Spinner size={'sm'}/>
      }

      {!show_spinner &&
        <>
          <CheckboxAndLabel
            on_click={select_deselect_filter}
            is_checked={apply_filter}
            is_disabled={all_tags.length === 0}
            label='Filter by tags'
          />

          {all_tags.length === 0 &&
            <span>No family tags</span>
          }

          {all_tags.length > 0 &&
            <div className={!apply_filter ? s.disabled : ''}>
              <CheckboxGroupHeader
                id_key={'id'}
                page_items={filtered_tags}
                filtered_items={filtered_tags_as_items}
                all_items={all_tags_as_items}
                selected_item_ids={checked_tags}
                is_filtered={is_filtered}
                num_filtered_results={num_filtered_results}
                num_selectable_items={all_tags.length}
                select_all={select_all_tags}
                deselect_all={deselect_all_tags}
                search_phrase={search_phrase || ''}
                set_selected_item_ids={on_selected_filtered_ids}
                handle_change_search_phrase={set_search_phrase}
                no_options_dropdown={true}
              />

              <ScrollableList>
                <CheckboxTree
                  nodes={tags_as_nodes}
                  checked={checked_tags}
                  expanded={expanded_tags}
                  on_check={on_check_node}
                  on_expand={on_expand_node}
                  show_expand_all={true}
                />
              </ScrollableList>
            </div>
          }
        </>
      }
    </div>
  )
}

const FamilyTagsFilter = (
  {
    show_spinner,
    viewable_tags,
    selected_tags,
    on_filter_change,
    apply_filter,
    set_apply_filter,

    tags_error,

    tags_display_only,
    className
  }) => {

  const [all_tags, set_all_tags] = useState([])

  const [checked_tags, set_checked_tags] = useState([])

  useEffect(() => {
    let did_cancel = false
    if (!did_cancel) {
      set_all_tags(viewable_tags || [])
    }
    return () => {
      did_cancel = true
    }
  }, [viewable_tags])

  useEffect(() => {
    let did_cancel = false
    if (!did_cancel) {
      set_checked_tags(selected_tags)
    }
    return () => {
      did_cancel = true
    }
  }, [selected_tags])

  const all_tags_as_items = _.flatten(all_tags.map(tag => tag.values))

  if (tags_display_only) {
    return (
      <FamilyTagsDisplay
        show_spinner={show_spinner}
        all_tags={all_tags}
        all_tags_as_items={all_tags_as_items}
        apply_filter={apply_filter}
        set_apply_filter={set_apply_filter}
        on_filter_change={on_filter_change}

        tags_error={tags_error}
      />
    )
  }

  return (
    <span className={cn('d-flex', 'align-items-center', className)}>
      <Label className={cn(cs.font_weight_normal, cs.white_space_nowrap)}>
        <span>Family Tags</span>
        {tags_error && !show_spinner &&
          <span className={s.error_message}>: Fetching error </span>
        }
      </Label>

      {!tags_error &&
        <BaseDropdown
          label={(!apply_filter) ? 'No filter' : (all_tags_as_items.length === checked_tags.length) ? 'All' : 'selected (' + checked_tags.length + ')'}
          className={cn('ms-2', s.dropdown)}
          menuClassName={s.dropdown_content}
          buttonClassName={s.button}
          labelClassName={s.label}
        >
          <FamilyTagsDisplay
            show_spinner={show_spinner}
            all_tags={all_tags}
            all_tags_as_items={all_tags_as_items}
            apply_filter={apply_filter}
            set_apply_filter={set_apply_filter}
            on_filter_change={on_filter_change}
            className={s.dropdown_content}
          />
        </BaseDropdown>
      }
  </span>
)}

export default FamilyTagsFilter