import React from 'react'

import { NavTab } from '../widgets/NavTab.js'

import s from './PortfolioSearchNavigationItem.module.scss'

const PortfolioSearchNavigationItem = ({ item, active, disabled, on_click }) => {

  function on_click_handler() {
    if (!is_active() && !is_disabled()) {
      on_click(item)
    }
  }

  function is_active() {
    return (item  === active)
  }

  function is_disabled() {
    return disabled || false
  }

  const {title, short_title} = item

  return (
    <NavTab is_active={is_active()} on_click={on_click_handler} className='me-4' disabled={is_disabled()}>
      <span className={s.title}>{title}</span>
      <span className={s.title__short}>{short_title}</span>
    </NavTab>
  )
}

export default PortfolioSearchNavigationItem