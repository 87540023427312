import React, { Component } from 'react'

import { fetch_training_sets } from '../../utils/training_set_grpc_utils.js'
import AddPatentToTrainingSetControl from './AddPatentToTrainingSetControl.js'
import Spinner from '../widgets/Spinner.js'

class AddPatentToTrainingSetWrapper extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fetching_classifiers: true,
      error_fetching_classifiers: null,
      technologies: null,
      owner_id: null
    }
  }

  componentDidMount() {
    fetch_training_sets()
      .catch(err => {
        // FAIL
        this.setState({ fetching_classifiers: false, error_fetching_classifiers: err })
        throw err
      })
      .then((technologies = []) => {
        // SUCCESS
        this.setState({ fetching_classifiers: false, technologies })
      })
  }

  render() {
    const { className, patent, report_type, eval_training_set_id, on_set_local_classifier_label, reset_selected_classifier_id_on_change_patfam } = this.props
    const { fetching_classifiers, error_fetching_classifiers, technologies } = this.state

    if (fetching_classifiers) {
      return (
        <Spinner
          size={'sm'}
        />
      )
    }

    if (error_fetching_classifiers) {
      return (
        <div>There was an error loading classifiers.</div>
      )
    }

    // Ensure family has an id (classifiers code expects this)
    const id = patent.id || patent.patFamId
    const patent_with_id = {...patent, id }

    return (
      <AddPatentToTrainingSetControl
        className={className}
        patent={patent_with_id}
        training_set_infos={technologies}

        report_type={report_type}
        local_training_set_id={eval_training_set_id}
        on_set_local_classifier_label={on_set_local_classifier_label}

        reset_selected_classifier_id_on_change_patfam={reset_selected_classifier_id_on_change_patfam}
      />
    )
  }
}

export default AddPatentToTrainingSetWrapper