import {
  HISTORY,
  BUILD_REPORT,
  BUILD_ND_REPORT,
  BUILD_CUSTOM_CLUSTERED_REPORT,
  REPORT,
  RUN_REPORT,
  CREATE_REPORT,
  KNN,
  CLASSIFIER_SUBSCRIPTIONS,
  REPORT_BASED_SUBSCRIPTIONS,
  BUILD_SET_THEORY_REPORT
} from '../constants/paths.js'

export const DEFAULT_HELP_ENDPOINT = '/categories/27797933228051'


export const RUN_REPORT_HELP   = { pathname: RUN_REPORT }
export const REPORT_HISTORY_HELP = { pathname: HISTORY,                       help_endpoint: '29153651356051-Report-History-and-editing-an-existing-report' }
export const BUILD_REPORT_HELP   = { pathname: BUILD_REPORT,                  help_endpoint: '28733341574419-Organisation-Classifier-Report' }
export const BUILD_REPORT_ORG_HELP        = { pathname: BUILD_REPORT, help_endpoint: '28733341574419-Organisation-Classifier-Report',  params: { portfolio_search_mode: 'org_search'} }
export const BUILD_REPORT_TECH_HELP       = { pathname: BUILD_REPORT, help_endpoint: '28736575271315-What-is-the-Boolean-Search-tool-and-how-do-I-use-it', params: { portfolio_search_mode: 'families_search'} }
export const BUILD_REPORT_UPLOAD_HELP     = { pathname: BUILD_REPORT, help_endpoint: '28733531773075-Supported-formats-for-patent-upload', params: { portfolio_search_mode: 'upload'} }
export const BUILD_REPORT_ORGSETS_HELP    = { pathname: BUILD_REPORT, help_endpoint: '28775406572563-Organisation-Sets', params: { portfolio_search_mode: 'org_sets'} }
export const BUILD_REPORT_TAGSETS_HELP    = { pathname: BUILD_REPORT, help_endpoint: '28740040221587-Patent-Family-Tagging', params: { portfolio_search_mode: 'tag_sets'} }
export const REPORT_HELP                  = { pathname: REPORT,                        help_endpoint: '28883138626323-Available-metrics-and-datasets' }
export const BUILD_ND_REPORT_HELP         = { pathname: BUILD_ND_REPORT,               help_endpoint: '29790158309779-What-is-N-D' }
export const BUILD_CC_REPORT_HELP         = { pathname: BUILD_CUSTOM_CLUSTERED_REPORT, help_endpoint: '28879075461139-Custom-Three-Column-Upload-report' }
export const BUILD_SET_THEORY_REPORT_HELP = { pathname: BUILD_SET_THEORY_REPORT,       help_endpoint: '28879027329939-Custom-Set-Theory' }
export const TECH_EXPLORER_HELP           = { pathname: KNN,                           help_endpoint: '28732354484115-Tech-Explorer' }
export const REPORT_WIZARD_HELP           = { pathname: CREATE_REPORT,                 help_endpoint: '28731679920275-Report-Wizard' }
export const CLASSIFIER_ALERTS_HELP       = { pathname: CLASSIFIER_SUBSCRIPTIONS,      help_endpoint: '28984974653459-Weekly-Classifier-Alerts' }
export const REPORT_BASED_ALERTS_HELP     = { pathname: REPORT_BASED_SUBSCRIPTIONS,    help_endpoint: '28985185291155-Customisable-Alerts-linked-to-your-reports' }
export const MULTI_SEARCH_HELP         = { help_endpoint: '28732201726867-Quick-Search-Similar-Family-Searching' }
export const RECENT_HISTORY_HELP       = { help_endpoint: '29152055586067-How-to-see-Recent-Reports' }
export const NEW_REPORT_HELP           = { help_endpoint: '28731958192275-New-Report-Tile' }
export const NEW_ND_REPORT_HELP        = { help_endpoint: '29790158309779-What-is-N-D' }
export const CLASSIFIER_REPORT_HELP    = { help_endpoint: '28733119492883-Global-Landscape-Report' }
export const UTT_LANDSCAPE_REPORT_HELP = { help_endpoint: '28735120878355-Universal-Technology-Taxonomy' }
export const PUBLICATION_FORMATS_HELP  = { help_endpoint: '28733531773075-Supported-formats-for-patent-upload' }
export const FAMILY_LIST_COLUMNS_HELP  = { help_endpoint: '28739578325907-How-to-interpret-the-Families-View' }

export const HELP_SECTIONS = [
  RUN_REPORT_HELP,
  BUILD_REPORT_HELP,
  BUILD_REPORT_ORG_HELP,
  BUILD_REPORT_ORGSETS_HELP,
  BUILD_REPORT_TAGSETS_HELP,
  BUILD_REPORT_TECH_HELP,
  BUILD_REPORT_UPLOAD_HELP,
  REPORT_HISTORY_HELP,
  REPORT_HELP,
  BUILD_ND_REPORT_HELP,
  BUILD_CC_REPORT_HELP,
  BUILD_SET_THEORY_REPORT_HELP,
  TECH_EXPLORER_HELP,
  REPORT_WIZARD_HELP,
  CLASSIFIER_ALERTS_HELP,
  REPORT_BASED_ALERTS_HELP
]