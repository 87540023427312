import React from 'react'
import cn from 'classnames'

import { ArrowLeftIcon } from '../widgets/IconSet.js'

import cs from '../cipher_styles.module.scss'
import s from './DashboardTile.module.scss'

export const DashboardSmallTile = ({title, hint, className, children, on_click}) => {
  return (
    <div  className={cn('h-100', {[s.__clickable]: on_click != null})} onClick={on_click}>

      <div className={cn('h-100', s.card, className)}>
        {(title || hint) &&
          <div className={cn(s.card_heading_wrapper)}>
            <div className={cn('d-flex justify-content-between p-2 position-relative p-3', s.card_heading )}>

              <div className='d-flex'>
                <h5>{title}</h5>
                {on_click && <span className={cn('ms-1 my-auto', cs.rotate_180)}><ArrowLeftIcon /></span>}
              </div>
              {hint}
            </div>
          </div>
        }
          {children}
      </div>

    </div>
  )
}
