import React from 'react'
import cn from 'classnames'

import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'

const PrivateSearchModeControl = (
  {
    is_enabled,
    is_checked,
    on_click,
    className
  }) => {

  if (!is_enabled) {
    return (<div className={cn('fs-unmask', className)}>You are searching privately</div>)
  }

  return (
    <CheckboxAndLabel
      label='Do not save searches'

      is_checked={is_checked}
      on_click={on_click}
      className={cn('fs-unmask', className)}
    />
  )
}

export default PrivateSearchModeControl