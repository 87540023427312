import React, { useState, useEffect } from 'react'
import cn from 'classnames'

import { withUser } from '../UserContext.js'
import {
  ShowSimilarIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  DragIndicatorIcon,
  TrashIcon,
  AddCircleIcon
} from '../widgets/IconSet.js'
import TextLink from '../widgets/TextLink.js'
import Spinner from '../widgets/Spinner.js'
import {
  update_organisation_tree,
  is_organisation,
  get_org_id,
  fetch_organisation_tree_by_id,
  update_organisation_meta,
  remove_organisation_from_parent,
  add_suborganisation,
  is_agglomeration,
  turn_agglomeration_into_organisation,
  is_assignee,
  create_new_organisation,
  is_organisation_empty,
  get_all_assignees,
  is_any_name_match
} from '../../utils/organisation_utils.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'
import ErrorModal from '../ErrorModal.js'
import OrgOptions from './OrgOptions.js'
import OrgDetails from './OrgDetails.js'
import OrgName from './OrgName.js'
import OrgUngroupModal from './OrgUngroupModal.js'
import OrgEditModal from './OrgEditModal.js'
import OrgNewModal from './OrgNewModal.js'
import OrgSortingControl from './OrgSortingControl.js'
import { GROUPING_CONTEXT } from '../../model/organisation.js'
import { useOrganisationTree } from '../../hooks/organisation_hooks.js'
import { useToggle, usePrevious } from '../../hooks/general_hooks.js'
import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'
import AgglomerationModal from './AgglomerationModal.js'
import OrgFilteringControl from './OrgFilteringControl.js'
import AssigneeUngroupModal from './AssigneeUngroupModal.js'
import GroupingConfirmationModal from './GroupingConfirmationModal.js'
import MoveAssigneesToParentModal from './MoveAssigneesToParentModal.js'
import { get_csv_string } from '../../utils/csv_utils.js'
import {
  EXCEL_FILE_EXT,
  fetch_excel_document,
  get_clean_filename,
  MIMETYPE_XLSX,
  trigger_download
} from '../../utils/download_utils.js'
import { TABLE_ID } from '../../model/view_ids.js'

import s from './Organisation.module.scss'

const Organisation = (
  {
    user,
    org,
    context,
    level,
    parents,
    parent_tags,
    similar_orgs_mode,
    result_reference,
    on_result_check_in,
    selected_organisations,
    selected_parents,
    selected_parents_tags,
    is_parent_selected,
    org_ungroup_handler,
    add_child_handler,
    edit_handler,
    show_similar_handler,
    freeze_grouping_actions,
    filter_by,
    parent_filter_by,

    get_parent_details,
    show_org_ids,
    filter_empty
}) => {
  const prev_org = usePrevious(org)

  const current_level = level || 0

  function is_top_level(level) {
    return level === 0
  }

  const [children_visible, set_children_visible] = useState(false)
  const [filter_phrase, set_filter_phrase] = useState(null)

  const [org_edit_modal, toggle_org_edit_modal] = useToggle(false)
  const [add_new_modal, toggle_add_new_modal] = useToggle(false)
  const [ungroup_modal, toggle_ungroup_modal] = useToggle(false)
  const [move_assignees_to_parent_modal, toggle_move_assignees_to_parent_modal] = useToggle(false)
  const [agglom_modal, toggle_agglom_modal] = useToggle(false)
  const [grouping_confirmation_modal, toggle_grouping_confirmation_modal] = useToggle(false)

  const [is_selected, set_is_selected] = useState(false)
  const [is_child_selected, set_is_child_selected] = useState(false)

  const [edit_error, set_edit_error] = useState(null)
  const [group_error, set_group_error] = useState(null)
  const [ungroup_error, set_ungroup_error] = useState(null)
  const [agglom_error, set_agglom_error] = useState(null)
  const [export_error, set_export_error] = useState(null)

  const [
    do_fetch_org_tree,
    do_reorder,
    do_group_by_type,
    show_children_spinner,
    org_tree,
    {selected_sort_by, selected_sort_dir},
    is_grouped_by_type,
    fetch_tree_error,
    clear_fetch_tree_error
  ] = useOrganisationTree(org, is_grouping_mode())

  useEffect(() => {
    const {refresh} = org

    if (!(prev_org || refresh)) return

    if (get_org_id(prev_org) !== get_org_id(org)) {
      set_children_visible(false)
    } else {
      if ( org !== prev_org && (children_visible || refresh)) {
        do_fetch_org_tree()
      }
    }
  }, [org, prev_org, do_fetch_org_tree, children_visible])

  useEffect(() => {
    const org_id = get_org_id(org)
    
    const is_selected_updated = (selected_organisations.indexOf(org_id) !== -1)
    const is_child_selected_updated = (selected_parents.indexOf(org_id) !== -1)

    set_is_selected(is_selected_updated)
    set_is_child_selected(is_child_selected_updated)

  }, [org, selected_organisations, selected_parents])

  function refresh_children() {
    if (!is_organisation(org)) return
    do_fetch_org_tree()
  }

  function toggle_children_visibility() {
    children_visible ? hide_children() : show_children()
  }

  function show_children() {
    set_children_visible(true)
    do_fetch_org_tree()
  }

  function hide_children() {
    set_children_visible(false)
  }

  function on_children_sort_change(sort_by, sort_dir) {
    if ((sort_by === selected_sort_by) && ( sort_dir === selected_sort_dir)) return
    do_reorder({selected_sort_by: sort_by, selected_sort_dir: sort_dir})
  }

  function edit_organisation({new_name, non_practicing_entity_override, tags, notes, grouping_only}) {
    if (!new_name && !tags && (non_practicing_entity_override == null) && !notes && (grouping_only == null)) {
      toggle_org_edit_modal()
      return
    }

    const {user_id} = user

    toggle_org_edit_modal()
    freeze_grouping_actions(true)

    edit_org_details({new_name, tags, non_practicing_entity_override, notes, grouping_only, user_id})
      .then(() => edit_handler((parents || [])[0] || get_org_id(org)))
      .catch(error => {
        set_edit_error(error)
      })
  }

  function add_organisation(org_to_add) {
    const {name, tags, notes} = org_to_add
    toggle_add_new_modal()

    if (!name && !tags && !notes) {
      return
    }

    const {user_id} = user
    freeze_grouping_actions(true)
    add_suborganisation(org, org_to_add, user_id)
      .then(new_org => {
        const updated_parent_org = org_tree ? {...org_tree, children: [...org_tree.children || [], new_org]} : org
        add_child_handler(updated_parent_org)
      })
      .catch(error => {
        set_edit_error(error)
      })
  }

  function edit_org_details({new_name, tags, non_practicing_entity_override, notes, grouping_only, user_id}) {
    if (new_name) {
      org.name = new_name
    }

    if (notes) {
      org.notes = notes
    }

    return update_organisation_meta({
      org_id: get_org_id(org),
      ...(new_name) ? {name: new_name} : {},
      ...(tags) ? {tags: (tags.length > 0) ? tags : null} : {},
      ...(non_practicing_entity_override != null) ? {non_practicing_entity_override} : {},
      ...(notes != null) ? {notes} : {},
      ...(grouping_only != null) ? {grouping_only} : {},
      user_id
    })
  }

  function select_for_grouping({org, parents, parent_tags, flat}) {
    on_result_check_in({org, parents, parent_tags, flat})
  }

  function do_select({org, parents}, e) {
    const with_special_key = e.ctrlKey || e.shiftKey

    if (with_special_key) {
      track_report_builder_event('action="start" obj="org_group"')
    }

    on_result_check_in({org, parents, add: true, with_special_key, result_reference})
  }

  function do_unselect({org, parents}) {
    on_result_check_in({org, parents, add: false})
  }

  function confirm_grouping() {
    if (selected_parents_tags.length > 0) {
      toggle_grouping_confirmation_modal()
    } else {
      do_group({org, parents: parents || []})
    }
  }

  function do_group({org, parents}) {
    freeze_grouping_actions(true)
    const {user_id} = user

    const org_id = get_org_id(org)
    update_organisation_tree({org_id, child_ids: selected_organisations, user_id})
      .then(() => {return fetch_organisation_tree_by_id(parents[0] || org_id)})
      .then(org_tree => {
        on_result_check_in(org_tree)
      })
      .catch(error => {
        set_group_error(error)
        set_children_visible(false)
        freeze_grouping_actions(false)
      })
  }

  function do_org_ungroup(ungroup_and_load) {
    freeze_grouping_actions(true)
    toggle_ungroup_modal()
    const {user_id} = user
    const org_id = get_org_id(org)
    remove_organisation_from_parent({org_id, user_id})
      .then(() => {return fetch_organisation_tree_by_id(parents[0])})
      .then(parent_org => {
        org_ungroup_handler(parent_org, org_tree || org, ungroup_and_load)
      })
      .catch(error => {
        freeze_grouping_actions(false)
        set_ungroup_error(error)
      })
  }

  function do_assignee_ungroup() {
    freeze_grouping_actions(true)
    toggle_ungroup_modal()
    const {user_id} = user
    const {name} = org
    const org_id = get_org_id(org)
    create_new_organisation({name, user_id, child_ids: [org_id]})
      .then(() => {return fetch_organisation_tree_by_id(parents[0])})
      .then(parent_org => {
        org_ungroup_handler(parent_org, null, false)
      })
      .catch(error => {
        freeze_grouping_actions(false)
        set_ungroup_error(error)
      })
  }

  function do_move_assignees_to_parent() {
    freeze_grouping_actions(true)
    toggle_move_assignees_to_parent_modal()
    const {user_id} = user
    const org_id = get_org_id(org)

    const immediate_parent = parents[parents.length - 1]

    const assignee_ids = get_all_assignees(org).map(item => get_org_id(item))

    remove_organisation_from_parent({org_id, user_id})
      .then(() => update_organisation_tree({org_id: immediate_parent, child_ids: assignee_ids, user_id}))
      .then(() => {return fetch_organisation_tree_by_id(parents[0])})
      .then(parent_org => {
        org_ungroup_handler(parent_org, org_tree || org, false)
      })
      .catch(error => {
        freeze_grouping_actions(false)
        set_ungroup_error(error)
      })
  }

  function do_agglom_into_org() {
    freeze_grouping_actions(true)
    toggle_agglom_modal()

    if (!is_agglomeration(org)) return
    const {user_id} = user

    turn_agglomeration_into_organisation({parent_id: parents[parents.length-1], agglomeration: org, user_id})
      .then(() => {return fetch_organisation_tree_by_id(parents[0])})
      .then(org_tree => {
        add_child_handler(org_tree)
      })
      .catch(error => {
        freeze_grouping_actions(false)
        set_agglom_error(error)
      })
  }

  function is_grouping_mode() {
    const org_tree_context = context || {}
    return (org_tree_context.id === GROUPING_CONTEXT.id)
  }

  function export_assignees() {
    const {name} = org
    fetch_organisation_tree_by_id(get_org_id(org))
      .then(response => {
        const assignees = get_all_assignees(response)
        const data = assignees.map(item => {
          const { name, size_active, id } = item
          return [id, name, size_active, (size_active === 0) ? 'Inactive' : 'Active']
        })

        const csv = get_csv_string([['ID', 'Name', 'Active size', 'Status'], ...data])

        const input = {
          title: `${name} - assignees`,
          description: '',
          view_id: TABLE_ID,
          data: csv,
        }

        return fetch_excel_document([input], false, true)
      })
      .then(arraybuffer => {
        trigger_download(arraybuffer, MIMETYPE_XLSX, get_clean_filename(name + EXCEL_FILE_EXT))
      })
      .catch(error => {
        set_export_error(error)
      })
  }

  const {is_hidden} = org

  if (is_hidden) {
    return null
  }

  const org_id = get_org_id(org)

  const { children } = org_tree || {}

  const has_children = (children && children.length > 0)

  const { source, target, tooltip_suffix } = context || {}

  const tooltip_ref_suffix = (tooltip_suffix || '') + current_level + result_reference.join('-')

  const wrapper_class_names = [
    {
      'mb-1 ms-0': is_top_level(current_level),
      'mb-0 ms-4': !is_top_level(current_level),
      [s.org_top_level_wrapper]: is_top_level(current_level),
      [s.org_next_level_wrapper]: !is_top_level(current_level),
    }
  ]

  const children_wrapper_class_names = [
    'mb-0 ms-4',
  ]

  const is_grouping = is_grouping_mode()

  if (filter_by && !is_any_name_match(org, filter_by)) {
    return null
  }

  const is_empty = current_level > 0 ? is_organisation_empty(org) : false

  if (is_empty && filter_empty) {
    return null
  }

  return (
    <div className={cn(wrapper_class_names)}>

      {edit_error &&
        <ErrorModal
          error={edit_error}
          on_hide={() => {set_edit_error(null)}}
          context='editing an organisation'
        />
      }

      {fetch_tree_error &&
        <ErrorModal
          error={fetch_tree_error}
          on_hide={() => {clear_fetch_tree_error()}}
          context='fetching organisation structure'
        />
      }

      {export_error &&
        <ErrorModal
          error={export_error}
          on_hide={() => {set_export_error(null)}}
          context='exporting organisation structure'
        />
      }

      {ungroup_error &&
        <ErrorModal
          error={ungroup_error}
          on_hide={() => {set_ungroup_error(null)}}
          context='removing organisation from group'
        />
      }

      {agglom_error &&
        <ErrorModal
          error={agglom_error}
          on_hide={() => {set_agglom_error(null)}}
          context='turning agglomeration into organisation'
        />
      }

      {group_error &&
        <ErrorModal
          error={group_error}
          on_hide={() => {set_group_error(null)}}
          context='doing grouping'
        />
      }

      {add_new_modal &&
        <OrgNewModal
          on_hide={toggle_add_new_modal}
          parent_org={org}
          on_confirm={add_organisation}
          should_find_similar={false}
        />
      }

      {org_edit_modal &&
        <OrgEditModal
          on_hide={toggle_org_edit_modal}
          org_id={org_id}
          on_confirm={edit_organisation}
        />
      }

      {ungroup_modal && is_organisation(org) &&
        <OrgUngroupModal
          on_hide={toggle_ungroup_modal}
          org_name={org.name}
          on_confirm={do_org_ungroup}
        />
      }

      {ungroup_modal && is_assignee(org) &&
        <AssigneeUngroupModal
          on_hide={toggle_ungroup_modal}
          org_name={org.name}
          on_confirm={do_assignee_ungroup}
        />
      }

      {move_assignees_to_parent_modal &&
        <MoveAssigneesToParentModal
          org={org}
          on_hide={toggle_move_assignees_to_parent_modal}
          on_confirm={do_move_assignees_to_parent}
        />
      }

      {agglom_modal &&
        <AgglomerationModal
          on_hide={toggle_agglom_modal}
          org_name={org.name}
          on_confirm={do_agglom_into_org}
        />
      }

      {grouping_confirmation_modal &&
        <GroupingConfirmationModal
          on_hide={toggle_grouping_confirmation_modal}
          on_confirm={() => do_group({org, parents: parents || []})}
        />
      }

      <div className='ps-2 py-2 pe-0 d-flex'>

        <div className={s.expand_control_wrapper}>
          {!is_assignee(org) &&
            <TextLink
              onClick={toggle_children_visibility}
              no_decoration
            >
              {(!children_visible) ? (<ChevronRightIcon />) : (<ChevronDownIcon />)}
            </TextLink>
          }
        </div>
        <div className={cn('d-flex flex-column flex-grow-1 position-relative', s.org_wrapper)}>

          <div className={cn('d-flex justify-content-between', s.org_name_block)}>
            <div className='d-flex'>
              <OrgName
                org={org}
                is_grouping_context={is_grouping}
                filter_by={filter_by}
                get_parent_details={get_parent_details}
                show_id={show_org_ids}
                tooltip_ref_suffix={tooltip_ref_suffix}
                on_click_from_edit_handler={toggle_org_edit_modal}
                export_assignees_handler={export_assignees}
                className='me-1 my-auto'
              />
            </div>

            {is_grouping &&
              <div className={s.show_more_icon}>
                <div className='d-flex flex-nowrap me-1'>
                  <DragIndicatorIcon />
                </div>
              </div>
            }

            {!is_grouping &&
              <div className={cn('d-flex me-2')}>
                {!is_selected &&
                  <TextLink
                    onClick={(e) => do_select({org, parents: parents || []}, e)}
                    title='Add'
                    no_decoration
                  >
                    <AddCircleIcon />
                  </TextLink>
                }

                {is_selected &&
                  <>
                    <TextLink
                      onClick={() => show_similar_handler(org)}
                      className='me-2'
                      title='Show similar organisations'
                      no_decoration
                    >
                      <ShowSimilarIcon />
                    </TextLink>
                    <TextLink
                      onClick={() =>  do_unselect({org, parents: parents || []})}
                      title='Remove'
                      no_decoration
                    >
                      <TrashIcon/>
                    </TextLink>
                  </>
                }
              </div>
            }
          </div>

          {is_grouping &&
            <OrgOptions
              org={org}
              on_click_from_edit_handler={toggle_org_edit_modal}
              on_click_from_ungroup_handler={toggle_ungroup_modal}
              on_click_from_move_assignees_to_parent_handler={toggle_move_assignees_to_parent_modal}
              on_click_from_add_handler={toggle_add_new_modal}
              on_click_from_turn_agglom_into_org={toggle_agglom_modal}

              tooltip_ref_suffix={tooltip_ref_suffix}
              level={current_level}
              is_selected={is_selected || is_parent_selected || is_child_selected}
              export_assignees_handler={export_assignees}
              className={s.options}
            />
          }

          <div className='d-flex flex-wrap mt-2'>

            <OrgDetails
              org={current_level === 0 && has_children ? org_tree : org}
              level={current_level}
              children_visible={children_visible}
              on_click_from_toggle_children={toggle_children_visibility}

              tooltip_ref_suffix={tooltip_ref_suffix}
            />

            {is_grouping &&
              <div className='ms-auto me-2'>
                {source &&
                  <div className={cn('d-flex', s.grouping_controls)}>
                    {is_organisation(org) &&
                      <TextLink
                        className='ms-1'
                        disable={is_selected || is_parent_selected || is_child_selected}
                        onClick={() => select_for_grouping({org, parents: parents || [], parent_tags: parent_tags || [], flat: false})}
                      >
                        [add organisation]
                      </TextLink>
                    }
                    <TextLink
                      disable={is_selected || is_parent_selected || is_child_selected}
                      className='ms-1'
                      onClick={() => select_for_grouping({org, parents: parents || [], parent_tags: parent_tags || [], flat: true})}
                    >
                      [add assignees]
                    </TextLink>
                  </div>
                }

                {target &&
                  <div className={cn('d-flex', s.grouping_controls)}>
                    {is_organisation(org) &&
                      <TextLink
                        disable={is_selected || is_child_selected || is_parent_selected || (selected_organisations.length === 0)}
                        className={'ms-1'}
                        onClick={confirm_grouping}
                      >
                        [group]
                      </TextLink>
                    }
                  </div>
                }
              </div>
            }

          </div>
        </div>
      </div>

      {children_visible && !org.show_children &&
        <div className='pb-2'>
          {show_children_spinner &&
            <div className={cn(children_wrapper_class_names, 'py-2 ps-3')}>
              <Spinner />
            </div>
          }

          {!show_children_spinner &&
            <div>
              {!has_children &&
                <div className={cn(children_wrapper_class_names, 'py-2 ps-3')}>
                  (no members to display)
                </div>
              }

              {has_children && children.length > 1 &&
                <div className={cn(children_wrapper_class_names, s.sorting_wrapper, 'd-flex ps-2 justify-content-between py-2')}>
                  <div className='d-flex flex-grow-1'>
                    <OrgSortingControl
                      selected_sort_by={selected_sort_by}
                      on_children_sort_change={on_children_sort_change}
                      selected_sort_dir={selected_sort_dir}
                    />

                    <div className='mx-1 flex-grow-1'>
                      <OrgFilteringControl
                        on_change={set_filter_phrase}
                        text={filter_phrase}
                      />
                    </div>
                  </div>

                  {is_grouping &&
                    <div className='my-auto ms-3'>
                      <CheckboxAndLabel
                        label='By type'
                        on_click={do_group_by_type}
                        is_checked={is_grouped_by_type}
                      />
                    </div>
                  }
                </div>
              }

              {has_children && children.map((child, i) => (
                <Organisation
                  key={i}
                  context={context}
                  org={child}
                  parents={[...(parents || []), org_id]}
                  parent_tags={[...(parent_tags || []), org.tags || []]}
                  on_result_check_in={on_result_check_in}
                  on_child_remove={refresh_children}
                  level={current_level + 1}
                  result_reference={[...result_reference || [], i]}
                  similar_orgs_mode={similar_orgs_mode}
                  selected_organisations={selected_organisations}
                  selected_parents={selected_parents}
                  selected_parents_tags={selected_parents_tags}
                  is_parent_selected={is_selected || is_parent_selected}
                  org_ungroup_handler={org_ungroup_handler}
                  add_child_handler={add_child_handler}
                  edit_handler={edit_handler}
                  show_similar_handler={show_similar_handler}
                  user={user}
                  filter_by={filter_phrase || parent_filter_by}
                  parent_filter_by={filter_phrase || parent_filter_by}

                  freeze_grouping_actions={freeze_grouping_actions}
                  show_org_ids={show_org_ids}
                  filter_empty={filter_empty}
                />
              ))}
            </div>
          }
        </div>
      }
    </div>
  )
}

export default withUser(Organisation)