import React from 'react'
import cn from 'classnames'

import PatentFamiliesSimpleList from './PatentFamiliesSimpleList.js'
import FamilyDetails from '../family_view/FamilyDetails.js'
import Spinner from '../widgets/Spinner.js'

import s from './PatentFamilyListDetailsContainer.module.scss'

const PatentFamilyListDetailsContainer = (
  {
    patent_families,
    family,
    on_change_subidx,

    report_input,
    reset_selected_classifier_id_on_change_patfam,
    top_className,
    page_top,

    is_fetching,
    fetching_pat_families_error,

    // Eval classifier stuff
    eval_training_set_id,
    eval_classifier_data,
    on_set_local_classifier_label,
    search_phrases,
    search_colours,
    no_highlighting,
    highlight_prefix_only,

    patent_link_option_id,
    on_change_patent_link_option_id,

    is_family_tagging_mode_on,
    family_tagging_search_phrase,
    on_toggle_family_tagging_mode,
    on_update_family_tagging_search_phrase,

    selected_patent_number,
    on_change_selected_patent_number,

    technology,
    notify_tag_change,

    knn_family_ids,
    knn_blocklist,
    knn_bookmarked,
    on_add_to_knn_family_ids,
    on_add_to_knn_blocklist,
    on_add_to_knn_bookmarked,
    className
  }) => {


  if (is_fetching) {
    return <div><Spinner /></div>
  }

  return (
    <div className={cn('d-flex w-100', className)}>
      <PatentFamiliesSimpleList
        selected_family={family}
        patent_families={patent_families}
        on_change_subidx={on_change_subidx}

        no_highlighting={no_highlighting}
        highlight_prefix_only={highlight_prefix_only}
        search_phrases={search_phrases}
        search_colours={search_colours}

        knn_family_ids={knn_family_ids}
        knn_blocklist={knn_blocklist}
        knn_bookmarked={knn_bookmarked}
        show_knn_actions = {on_add_to_knn_family_ids || on_add_to_knn_blocklist || on_add_to_knn_bookmarked}

        page_top={page_top}
        className={s.list_container}
      />

      <div className={s.details_container}>
        <FamilyDetails
          family={family}
          report_input={report_input}
          reset_selected_classifier_id_on_change_patfam={reset_selected_classifier_id_on_change_patfam} // Eval reports reset, normal reports are sticky
          top_className={top_className}

          // Eval classifier stuff
          eval_training_set_id={eval_training_set_id}
          eval_classifier_data={eval_classifier_data}
          on_set_local_classifier_label={on_set_local_classifier_label}
          search_phrases={search_phrases}
          search_colours={search_colours}
          no_highlighting={no_highlighting}
          highlight_prefix_only={highlight_prefix_only}

          patent_link_option_id={patent_link_option_id}
          on_change_patent_link_option_id={on_change_patent_link_option_id}

          is_family_tagging_mode_on={is_family_tagging_mode_on}
          family_tagging_search_phrase={family_tagging_search_phrase}
          on_toggle_family_tagging_mode={on_toggle_family_tagging_mode}
          on_update_family_tagging_search_phrase={on_update_family_tagging_search_phrase}

          selected_patent_number={selected_patent_number}
          on_change_selected_patent_number={on_change_selected_patent_number}

          technology={technology}
          notify_tag_change={notify_tag_change}

          knn_family_ids={knn_family_ids}
          knn_blocklist={knn_blocklist}
          knn_bookmarked={knn_bookmarked}
          on_add_to_knn_family_ids={on_add_to_knn_family_ids}
          on_add_to_knn_blocklist={on_add_to_knn_blocklist}
          on_add_to_knn_bookmarked={on_add_to_knn_bookmarked}
        />
      </div>
    </div>

  )
}

export default PatentFamilyListDetailsContainer