import React, { useEffect, useRef, useState } from 'react'
import { DropdownItem, Input } from 'reactstrap'

import cn from 'classnames'
import _ from 'underscore'

import { withUser } from '../UserContext.js'
import { PrimaryButton } from '../widgets/Button.js'
import ClearableSearchInput from '../widgets/ClearableSearchInput.js'
import { FormFeedback } from '../widgets/FormFeedback.js'
import ErrorModal from '../ErrorModal.js'
import ContainerFullWidth from '../ContainerFullWidth.js'
import { InfoPopover } from '../widgets/Tooltip.js'
import { InfoIcon } from '../widgets/IconSet.js'
import AutoResizeTextArea from '../widgets/AutoResizeTextArea.js'
import ReportManagementTable from '../report_management/components/ReportManagementTable'
import SetTheoryReportBuilderOptionsModal from './SetTheoryReportBuilderOptionsModal.js'
import { DESCENDING } from '../../model/sort_directions.js'
import { DEFAULT_PAGE_SIZE, PAGE_SIZES } from '../report_management/model/page_sizes.js'
import PageSizeControl from '../PageSizeControl.js'
import PageControl from '../PageControl.js'
import {
  CREATED_AT_FIELD_ID,
  LAST_VIEWED_FIELD_ID,
  NAME_FIELD_ID,
  OWNER_FIELD_ID,
  STATUS_FIELD_ID,
  IS_SELECTED_FIELD_ID,
  ID_TO_REPORT_FIELD
} from '../report_management/model/report_fields.js'
import TextLink from '../widgets/TextLink.js'
import { REPORT } from '../../constants/paths.js'
import { NO_FILTER } from '../report_management/model/filters.js'
import { is_creator } from '../../utils/user_permissions.js'
import { fetch_report_history, fetch_report_owners } from '../../utils/report_history_utils.js'
import {
  filter_reports,
  get_new_selected_external_report_ids,
  sort_reports
} from '../report_management/utils/sort_and_filter_utils.js'
import { track_report_management_event } from '../../utils/tracking_utils.js'
import { pluralise_text } from '../../utils/utils.js'
import { send_error_to_sentry } from '../../utils/sentry_utils.js'
import { useInterval } from '../../hooks/general_hooks.js'
import ReportBuilderNoAccessPanel from '../builder/ReportBuilderNoAccessPanel.js'
import BaseDropdown from '../widgets/BaseDropdown.js'
import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'
import { MAX_PRESET, MAX_REPORTS_FROM_HISTORY,POLLING_TIME } from '../../constants/set_theory_report_builder.js'
import {
  OP_REGEX,
  OPERATORS,
  PARSER,
  BENCHMARKING_PRESET,
  PRESET_OPTIONS,
  CPC_MODE,
  REPORT_TIMESTAMP_MODE,
  KEYBOARD_KEY_CODE_TO_OPERATOR
} from '../../model/set_theory.js'

import cs from '../cipher_styles.module.scss'
import s from './SetTheoryReportBuilder.module.scss'

const SetTheoryReportBuilder = ({user}) => {

  const formula_input_ref = useRef(null)

  const table_fields = [
    IS_SELECTED_FIELD_ID,
    NAME_FIELD_ID,
    OWNER_FIELD_ID,
    CREATED_AT_FIELD_ID,
    LAST_VIEWED_FIELD_ID,
    STATUS_FIELD_ID,
  ].map(field_id => ID_TO_REPORT_FIELD[field_id])

  const [reports, set_reports] = useState([])
  const [report_owners, set_report_owners] = useState({})
  const [is_fetching_owners, set_is_fetching_owners] = useState(false)
  const [formula, set_formula] = useState('')
  const [operands, set_operands] = useState({})
  const [preset_report_nr, set_preset_report_nr] = useState(1)
  const [group_tech_mode, set_group_tech_mode] = useState(CPC_MODE)
  const [group_portfolio_mode, set_group_portfolio_mode] = useState(REPORT_TIMESTAMP_MODE)

  const [is_options_modal_open, set_is_options_modal_open] = useState(false)
  const [formula_parse_error, set_formula_parse_error] = useState(null)
  const [error_message, set_error_message] = useState(null)

  // Table state
  const [is_fetching, set_is_fetching] = useState(true)
  const [sort_field_id, set_sort_field_id] = useState(CREATED_AT_FIELD_ID)
  const [sort_direction_id, set_sort_direction_id] = useState(DESCENDING)
  const [page_size, set_page_size] = useState(DEFAULT_PAGE_SIZE)
  const [page_number, set_page_number] = useState(0)

  const [report_search_input, set_report_search_input] = useState('')
  const [selected_reports_external_ids, set_selected_reports_external_ids] = useState([])
  const [selected_reports, set_selected_reports] = useState([])

  const reports_filtered = filter_reports(reports, report_owners, report_search_input, NO_FILTER, null, null)
  const reports_sorted = sort_reports(reports_filtered, report_owners, sort_field_id, sort_direction_id)
  const has_data_rows = reports_filtered && reports_filtered.length > 0

  const start_index = page_number * page_size
  const page_reports = !has_data_rows ? [] : reports_sorted.slice(start_index, start_index + page_size)
  const num_pages = !has_data_rows ? 0 : Math.ceil(reports_filtered.length / page_size)

  const page_reports_with_owner_names = page_reports.map((r) => ({...r, owner_name: report_owners[r.external_report_id]}))

  // Temp view states.
  const [benchmarking_view, set_benchmarking_view] = useState(false)

  function on_change_benchmarking_view() {
    set_benchmarking_view(benchmarking_view => !benchmarking_view)
    set_preset_report_nr(2)
  }

  function on_formula_input_button_press(key) {
    const dom_el = formula_input_ref.current
    if (dom_el?.selectionStart && dom_el?.selectionEnd) {
      set_formula(`${formula.substr(0, dom_el.selectionStart)}${key}${formula.substr(dom_el.selectionEnd)}`)
    } else {
      set_formula(formula + key)
    }
    dom_el?.focus()
  }

  function replace_shortcut_with_formula_key(event) {
    if (_.contains(Object.keys(KEYBOARD_KEY_CODE_TO_OPERATOR), event.code)){
      event.preventDefault()
      on_formula_input_button_press(KEYBOARD_KEY_CODE_TO_OPERATOR[event.code].symbol)
    }
  }

  function on_preset_report_nr_input_change(event) {
    set_preset_report_nr(Math.max(Math.min(parseInt(event.target.value), MAX_PRESET), 1))
  }

  function on_preset_selection(obj) {
    const {group_tech_mode, group_portfolio_mode, fn_formula_generation} = obj || {}

    set_formula(fn_formula_generation(preset_report_nr))
    set_group_tech_mode(group_tech_mode)
    set_group_portfolio_mode(group_portfolio_mode)
  }

  function is_form_valid() {
    return !formula_parse_error && !!formula && !is_fetching
  }

  function on_change_sort_field_id_and_sort_direction_id(sort_field_id, sort_direction_id) {
    set_sort_field_id(sort_field_id)
    set_sort_direction_id(sort_direction_id)
  }

  function toggle_selected(reports_to_toggle, is_selected) {
    track_report_management_event(`obj="${pluralise_text(reports_to_toggle.length, 'report')}" action="${is_selected ? 'select': 'deselect'}" context="set_theory_report_builder"`)

    const reports_to_toggle_external_ids = reports_to_toggle.map(report => report.external_report_id)
    const selected_reports_external_ids_updated = get_new_selected_external_report_ids(reports_to_toggle_external_ids, is_selected, selected_reports_external_ids)
    set_selected_reports_external_ids(selected_reports_external_ids_updated)

    let new_selected_reports = selected_reports
    if (is_selected) {
      new_selected_reports = _.union(new_selected_reports, reports_to_toggle)
    } else {
      new_selected_reports = _.reject(new_selected_reports,
        (report)=> _.find(reports_to_toggle, r => r.external_report_id === report.external_report_id))
    }
    set_selected_reports(new_selected_reports)
  }

  function load_reports() {
    set_is_fetching(true)
    if (!is_fetching_owners) {
      asynchronously_fetch_report_owners()
    }
    fetch_report_history(MAX_REPORTS_FROM_HISTORY, true, false, true)
      .then(data => {
        set_reports(data)
      })
      .catch(err => set_error_message(err))
      .finally(() => {
        set_is_fetching(false)
      })
  }

  useInterval(load_reports, POLLING_TIME, true)

  function asynchronously_fetch_report_owners() {
    set_is_fetching_owners(true)

    fetch_report_owners()
      .then(report_id_to_owner => {
        set_report_owners(report_id_to_owner)
        set_is_fetching_owners(false)
      })
      .catch(err => {
        set_is_fetching_owners(false)
        send_error_to_sentry(err, {}) // names are non-essential metadata; don't stop the user from getting on with things
      })
  }

  useEffect(() => {
    // Display errors from PEG parser if any
    try {
      if (formula){
        PARSER.parse(formula)
        let matches = formula.match(OP_REGEX)
        let missing = new Set()
        // @ts-expect-error
        for (let match of matches) {
          let is_operand_defined = _.some(
            selected_reports,
            (x, idx) => `R${idx+1}` === match
          )
          if (!is_operand_defined) {
            missing.add(match)
          }
        }
        if (missing.size > 0) {
          let singular = missing.size === 1
          set_formula_parse_error(`Report${singular ? '': 's'} [ ${[...missing].join(', ')} ] ${singular ? 'is' : 'are'} not defined.`)
        }
        else {
          set_formula_parse_error(null)
        }
      } else {
        set_formula_parse_error(null)
      }
    } catch (e) {
      set_formula_parse_error(`${e.message} @ [offset: ${e.location.start.offset}, char_pos: ${e.location.start.column}]`)
    }
  }, [formula, selected_reports])

  useEffect(() => {
    const ops = {}
    selected_reports.forEach((k, idx) => {
      ops[`R${idx+1}`] = k.internal_report_id
    })
    set_operands(ops)
  }, [selected_reports])

  useEffect(() => {
    if (!benchmarking_view) {
      set_preset_report_nr(selected_reports.length)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_reports])

  useEffect(() => {
    if (benchmarking_view) {
      on_preset_selection(BENCHMARKING_PRESET)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preset_report_nr, benchmarking_view])

  useEffect(() => {
    if (benchmarking_view && selected_reports_external_ids.length > 2 && selected_reports.length > 2) {
      const new_selected_reports = selected_reports.slice(0,2)
      set_selected_reports(new_selected_reports)
      set_selected_reports_external_ids(new_selected_reports.map(report => report.external_report_id))
    }
  }, [selected_reports_external_ids, selected_reports, benchmarking_view])

    if (error_message) {
      return (
        <ErrorModal
          error={error_message}
          on_hide={() => set_error_message(null)}
          context={'building set theory report'}
        />
    )}

    if (!is_creator(user)) {
      return (
        <ReportBuilderNoAccessPanel/>
    )}

    const has_reports_selected = !_.isEmpty(selected_reports)

    return (
      <ContainerFullWidth className='p-0 w-100 h-100'>
        <div className='h-100 w-100 m-0 d-flex'>
          <div className={cn('d-flex flex-column w-75 py-3 ps-3 pe-2')}>
            <div className={cn('d-md-flex justify-content-between px-2 mb-3', s.presets_wrapper)}>
              <div className='d-block h-md-100'>
                <div className={cn(benchmarking_view ? 'd-none' : 'd-md-flex h-100')}>
                  <label className={cn('my-auto me-1', cs.white_space_nowrap)}>Number of reports</label>
                  <Input
                    id='preset_report_nr'
                    value={preset_report_nr != null ? preset_report_nr : ''}
                    type='number'
                    onChange={on_preset_report_nr_input_change}
                    min={0}
                    max={100}
                    autoComplete='off'

                    disabled={benchmarking_view}
                    className='my-auto'
                  />

                  <label className={cn('my-auto ms-2 me-1', cs.white_space_nowrap)}>Preset</label>
                  <BaseDropdown
                    label='Select preset'
                    labelClassName={cs.white_space_nowrap}
                    className='my-auto'
                    disabled={benchmarking_view}
                  >
                    {PRESET_OPTIONS.map((obj, idx) =>
                      <DropdownItem
                        key={idx}
                        onClick={() => on_preset_selection(obj)}
                        className='d-flex flex-row flex-nowrap justify-content-between'
                      >
                        <span>{obj.name}</span>
                        <InfoPopover
                          toggler={<span className={`my-auto ms-1`}><InfoIcon/></span>}
                          is_in_modal={true}
                          placement='bottom'
                        >
                          {obj.description}
                        </InfoPopover>

                      </DropdownItem>
                    )}
                  </BaseDropdown>
                </div>
              </div>
              <CheckboxAndLabel
                className='my-auto'
                label='Benchmarking mode'
                on_click={() => on_change_benchmarking_view()}
                is_checked={benchmarking_view}
              />
            </div>

            <div className={cn('d-flex justify-content-between', s.operator_buttons_wrapper)}>
              <span className='mt-auto d-flex'>
                <label>Formula</label>
                {benchmarking_view && <span className='ms-2'>(Select the company report first (R1) and then the landscape report (R2))</span>}
              </span>

              {!benchmarking_view &&
                <div className='d-flex'>
                  {OPERATORS.map(operator => {
                    const { symbol, name} = operator
                    return (
                    <PrimaryButton
                      outline={true}
                      title={name}
                      key={symbol}
                      onClick={() => on_formula_input_button_press(symbol)}
                      icon_only
                      className={cn('ms-1 my-auto', s.operator_button)}
                    >
                      {symbol}
                    </PrimaryButton>
                  )})}

                  <InfoPopover buttonClassName='ms-2 my-auto' placement='bottom'>
                    <table className='table table-borderless'>
                      <thead>
                        <tr>
                          <th className='text-center'>Symbol</th>
                          <th>Name</th>
                          <th className={cn('text-center', cs.white_space_nowrap)}>Keyboard key</th>
                        </tr>
                      </thead>
                      <tbody>
                        {OPERATORS.map(operator=> {
                          const { symbol, name, keyboard_key} = operator
                          return (
                          <tr key={symbol}>
                            <td className={cn('text-center', cs.font_size_large)}>{symbol}</td>
                            <td valign='middle'>{name}</td>
                            <td valign='middle' className='text-center'>{keyboard_key}</td>
                          </tr>
                        )})}
                      </tbody>
                    </table>
                  </InfoPopover>
                </div>
              }
            </div>

            <AutoResizeTextArea
              value={formula || ''}
              on_change={set_formula}
              on_key_down={replace_shortcut_with_formula_key}
              rows={4}
              required={true}
              ref={formula_input_ref}
              className='h-100'
              disabled={benchmarking_view}
            />

            <FormFeedback
              valid={!formula_parse_error}
              validation_text={formula_parse_error}
            />

            <div
              className='m-0 mt-3 mb-3 w-100 d-flex flex-column justify-content-start overflow-auto'
              style={{ flex: '1 1 calc(1vh - 20.75rem)' }}
            >
              <h4>Report history</h4>

              <div className='d-flex flex-nowrap align-items-center'>
                <ClearableSearchInput
                  containerClassName='d-flex flex-grow-1 me-2'
                  value={report_search_input}
                  auto_focus={false}
                  handle_change={set_report_search_input}
                  show_clear={true}
                />
                {/* Right aligned items */}
                <div className='d-flex align-items-center ms-auto'>
                  <PageSizeControl
                    page_sizes={PAGE_SIZES}
                    selected_page_size={page_size}
                    on_change_page_size={set_page_size}
                    className='me-3 d-flex align-items-center'
                  />
                  <PageControl
                    current_page={page_number}
                    num_pages={num_pages}
                    on_change_current_page={set_page_number}
                  />
                </div>
              </div>

              <ReportManagementTable
                className='mt-3 pe-2'
                fields={table_fields}

                field_id_to_render_fn={{
                  [NAME_FIELD_ID]: function NameField(report) {
                    return (
                      <TextLink
                        element='a'
                        href={`${REPORT}/${report.external_report_id}`}
                        target='_blank'
                        title='Open in new tab'>
                        {report.title}
                      </TextLink>
                    )
                  }
                }}

                reports={page_reports_with_owner_names}
                selected_external_report_ids={selected_reports_external_ids}
                selected_sort_field_id={sort_field_id}
                selected_sort_direction_id={sort_direction_id}
                on_change_sort_field_id_and_sort_direction_id={on_change_sort_field_id_and_sort_direction_id}

                user={user}
                toggle_selected={toggle_selected}
                no_data_text={is_fetching ? null : ((report_search_input) ? 'No search results found' : 'Start creating reports to build your report history')}
                loading_text='Loading report history'
                loading={is_fetching}
              />

            </div>
          </div>

          <div className='d-flex flex-column w-25 py-3 pe-3 ps-2'>
            <div className='d-flex flex-column'>

              <div className={s.report_basket_container}>
                <div className={cn('px-2', s.report_basket_header_wrapper)}>
                  <div className='py-2'>Selected reports</div>
                </div>

                <div className={s.report_basket_items_container}>
                  {!has_reports_selected && <span>No reports selected</span>}
                  {has_reports_selected &&
                    <table className='w-100'>
                      <thead>
                        <tr>
                          <th className={s.report_basket_operand_column}>Operand</th>
                          <th>Report</th>
                        </tr>
                      </thead>

                      <tbody>
                        {selected_reports.map((report, idx) => {
                          return (
                            <tr key={idx}>
                              <td className={s.report_basket_operand_column}>R{idx + 1}</td>
                              <td>
                                <TextLink
                                  element='a'
                                  href={`${REPORT}/${report.external_report_id}`}
                                  target='_blank'
                                  title='Open in new tab'>
                                  {report.title}
                                </TextLink>
                              </td>
                            </tr>
                        )})}
                      </tbody>
                    </table>
                  }
                </div>
              </div>
            </div>
            <PrimaryButton
              onClick={()=> set_is_options_modal_open(true)}
              disabled={!is_form_valid()}
              className='mt-2 w-100'
            >
              Next
            </PrimaryButton>
          </div>
        </div>

        {is_options_modal_open &&
          <SetTheoryReportBuilderOptionsModal
            on_hide={()=> set_is_options_modal_open(false)}
            formula={formula}
            operands={operands}
            group_tech_mode={group_tech_mode}
            group_portfolio_mode={group_portfolio_mode}
            benchmarking_view={benchmarking_view}

            on_update_group_tech_mode={set_group_tech_mode}
            on_update_group_portfolio_mode={set_group_portfolio_mode}
          />
        }
      </ContainerFullWidth>
    )
}

export default withUser(SetTheoryReportBuilder)