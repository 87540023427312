import React, { Suspense, lazy, useState, useReducer, useEffect } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import _ from 'underscore'

import {
  HOME_PAGE,
  HISTORY,
  BUILD_REPORT,
  FAMILY,
  REPORT,
  SAVED,
  BUILD_ND_REPORT,
  BUILD_CLASSIFIER_ALERT_REPORT,
  BUILD_CUSTOM_CLUSTERED_REPORT,
  NOT_FOUND,
  DISPUTE,
  NOTIFICATIONS,
  CLASSIFIERS,
  LOGOUT,
  ABOUT,
  BUILD_CLASSIFIER_REPORT,
  BUILD_SET_THEORY_REPORT,
  HELP,
  SEARCH_GUIDE,
  CI_GUIDE,
  GRAPH,
  PATENT,
  DOC,
  IMAGE,
  SOURCES,
  TRENDS,
  REPORT_STATE,
  BENCHMARKING,
  SHARE_DECK,
  BUILD_UTT_LANDSCAPE_REPORT,
  BUILD_SIMILAR_FAMILIES_REPORT,
  BUILD_VALUATION_REPORT,
  SIMILAR_FAMILIES_SEARCH,
  SAVED_ORGS_LISTS_ADMIN,
  PROJECT,
  FTAGS_UI,
  CREATE_REPORT,
  REPORT_SCHEMA_GUIDE,
  KNN,
  SUBSCRIPTIONS
} from '../constants/paths.js'

import FamilyView from './family_view/FamilyView.js'
import PageNotFound from './PageNotFound.js'
import ProjectView from './project_management/ProjectView'
import WithProject from './project_management/WithProject'
import Dashboard from './Dashboard.js'
import ReportBuilder from './builder/ReportBuilder.js'
import ClassifierAlertReportBuilder from './ClassifierAlertReportBuilder.js'
import Reports from './report_management/components/Reports.js'
import {RouteWithTracing} from './RouteWithTracing'
import StyleGuide from './StyleGuide.js'
import ErrorTest from './ErrorTest.js'
import NDReportBuilder from './builder/NDReportBuilder.js'
import Notifications from './Notifications.js'
import CustomUploadReportBuilder from './CustomUploadReportBuilder.js'
import DisputeView from './disputes/DisputeView.js'
import About from './About.js'
import PatentFamilyListGuide from './patent_family_list/PatentFamilyListGuide.js'
import ExecutiveSummaryGuide from './executive_summary/ExecutiveSummaryGuide.js'
import FamilyGraphView from './family_view/FamilyGraphView.js'
import PatentPdfView from './PatentPdfView.js'
import PatentImageView from './PatentImageView.js'
import DisputeDocView from './DisputeDocView.js'
import FamilySourcesView from './family_view/FamilySourcesView.js'
import ViewerContainer from './viewer/ViewerContainer.js'
import SetTheoryReportBuilder from './set_theory/SetTheoryReportBuilder'
import IconFinder from './IconFinder.js'
import ReportState from './viewer/ReportState.js'
import Spinner from './widgets/Spinner.js'
import Benchmarking from './viewer/bespoke/Benchmarking.js'
import DeckState from './viewer/bespoke/DeckState.js'
import { lazy_with_retry } from '../utils/react_utils.js'
import SimilarFamiliesReportBuilder from './SimilarFamiliesReportBuilder.js'
import ValuationReportBuilder from './builder/ValuationReportBuilder.js'
import SavedListsAdmin from './org_sets/SavedListsAdmin.js'
import FamilyTagsManagement from './family_tagging/FamilyTagsManagement'
import { delete_user_settings, fetch_user_settings, save_user_setting } from '../utils/user_settings_utils.js'
import { SETTINGS_ENABLED_TO_RESET } from '../model/user_settings.js'
import { fetch_user_group_settings } from '../utils/user_group_settings_utils.js'
import { UserSettingsContext, UserSettingsDispatchContext } from './UserSettingsContext.js'
import DefaultPageWrapper from './DefaultPageWrapper.js'
import ErrorModal from './ErrorModal.js'
import ReportSchemaPdfView from './hyperscripts/ReportSchemaPdfView.js'
import AlertSubscriptionsContainer from './subscriptions/AlertSubscriptionsContainer.js'
import TechExplorerContainer from './tech_explorer/TechExplorerContainer.js'
import { PORTFOLIO_SEARCH_TYPE_LANDSCAPE_SEARCH_ID } from '../utils/report_builder_utils'
import { is_tech_discovery } from '../utils/utils.js'

import cs from './cipher_styles.module.scss'

// The below routes have some large thrid party dependencies.
// These can cause the vendor bundle to exceed Sentry's limit, breaking sourcemaps.
// So we load them lazily here, ensuring each is transpiled to its own separate js source and vendor bundles.
// See https://reactjs.org/docs/code-splitting.html
const TrendSpotterDisplayLazy           = lazy(() => lazy_with_retry(() => import('./trends/TrendSpotterDisplay.js')))
const ClassifiersContainer = lazy(() => lazy_with_retry(() => import('./classifiers_editor/components/ClassifiersContainer.js')))

const OLD_CIPHER_2_REPORT_PATHS = ['full', 'acquisition', 'ci', 'ta', 'risk', 'litigation', 'au']

function user_settings_reducer(state, data) {
  return {...state, ...data}
}

const MainRoutes = () => {

  const [is_fetching, set_is_fetching] = useState(true)
  const [user_settings, user_settings_dispatcher] = useReducer(user_settings_reducer, {})
  const [user_group_settings, set_user_group_settings] = useState(null)
  const [fetch_user_settings_error, set_fetch_user_settings_error] = useState(null)

  useEffect(() => {
    let did_cancel = false
    Promise.all([fetch_user_settings(), fetch_user_group_settings()])
      .then(([user_settings, user_group_settings]) => {
        if (!did_cancel) {
          set_is_fetching(false)
          user_settings_dispatcher(user_settings)
          set_user_group_settings(user_group_settings)
        }
      })
      .catch(error => {
        if (!did_cancel) {
          set_fetch_user_settings_error(error)
        }
      })

    return () => {
      did_cancel = true
    }
  }, [])

  function on_save_user_settings(key, value) {
    return save_user_setting(key, value)
      .then(() => {
        const new_user_settings = { ...user_settings, [key]: value }
        user_settings_dispatcher(new_user_settings)
      })
  }

  function on_reset_user_settings() {
    return delete_user_settings(SETTINGS_ENABLED_TO_RESET)
      .then(() => {
        const reset_settings = _.reduce(SETTINGS_ENABLED_TO_RESET, (settings, key) => {
          settings[key] = null
          return settings
        }, {})
        const new_user_settings = {...user_settings, ...reset_settings}
        user_settings_dispatcher(new_user_settings)
      })
  }

  if (is_fetching) return null

  if (is_tech_discovery()) {
    return (
      <UserSettingsContext.Provider value={{user_settings}}>
        <UserSettingsDispatchContext.Provider value={{save_user_settings_handler: on_save_user_settings, reset_user_settings_handler: on_reset_user_settings}}>

          {fetch_user_settings_error &&
            <ErrorModal
              content={'fetching user settings'}
              error={fetch_user_settings_error}
              on_hide={() => set_fetch_user_settings_error(null)}
            />
          }

          <Suspense fallback={<div><Spinner /></div>}>
            <Switch>

              <RouteWithTracing
                exact
                path={ABOUT}
                render={() => (<DefaultPageWrapper><About /></DefaultPageWrapper>)}
              />
              <RouteWithTracing
                exact
                path={HOME_PAGE}
                render={() => (<DefaultPageWrapper contentWrapperClassName={cs.overflow_unset}><TechExplorerContainer /></DefaultPageWrapper>)}
              />
              <RouteWithTracing
                path={`${KNN}/:id?`}
                render={() => (<DefaultPageWrapper contentWrapperClassName={cs.overflow_unset}><TechExplorerContainer /></DefaultPageWrapper>)}
              />

              <RouteWithTracing
                path={`${FAMILY}/${SOURCES}`}
                render={() => (<DefaultPageWrapper><FamilySourcesView /></DefaultPageWrapper>)}
              />
              <RouteWithTracing
                path={`${FAMILY}/:id/${GRAPH}`}
                render={({match}) => (<DefaultPageWrapper><FamilyGraphView match={match} /></DefaultPageWrapper>)}
              />
              <RouteWithTracing
                path={`${FAMILY}/:id/${SOURCES}`}
                render={() => (<DefaultPageWrapper><FamilySourcesView /></DefaultPageWrapper>)}
              />
              <RouteWithTracing
                path={`${FAMILY}/:id/p/:patent_id`}
                render={({match, history}) => (<DefaultPageWrapper><FamilyView match={match} history={history} /></DefaultPageWrapper>)}
              />
              <RouteWithTracing
                path={`${FAMILY}/:id`}
                render={({match, history}) => (<DefaultPageWrapper><FamilyView match={match} history={history} /></DefaultPageWrapper>)}
              />

              <RouteWithTracing
                path={`${PATENT}/${DOC}/:id`}
                render={({match}) => (<DefaultPageWrapper><PatentPdfView match={match} /></DefaultPageWrapper>)}
              />
              <RouteWithTracing
                path={`${PATENT}/${IMAGE}/:ucid/:id`}
                render={({match}) => (<DefaultPageWrapper><PatentImageView match={match} /></DefaultPageWrapper>)}
              />

              <RouteWithTracing
                path={NOT_FOUND}
                render={() => (<DefaultPageWrapper><PageNotFound /></DefaultPageWrapper>)}
              />

              {/* Route not found */}
              <RouteWithTracing
                render={({ location }) => (<DefaultPageWrapper><PageNotFound location={location}/></DefaultPageWrapper>)}
              />
            </Switch>
          </Suspense>

        </UserSettingsDispatchContext.Provider>
      </UserSettingsContext.Provider>
    )
  }

  return (
    <UserSettingsContext.Provider value={{user_settings, user_group_settings}}>
      <UserSettingsDispatchContext.Provider value={{save_user_settings_handler: on_save_user_settings, reset_user_settings_handler: on_reset_user_settings}}>
        {fetch_user_settings_error &&
          <ErrorModal
            content={'fetching user settings'}
            error={fetch_user_settings_error}
            on_hide={() => set_fetch_user_settings_error(null)}
          />
        }

        <Suspense fallback={<div><Spinner /></div>}>
          <Switch>

            {/* Old cipher2 routes */}
            <RouteWithTracing path='/custom-classifiers' component={() => (<Redirect to={CLASSIFIERS}/>)}/>
            <RouteWithTracing path='/manage-reports' component={() => (<Redirect to={HISTORY}/>)}/>
            <RouteWithTracing path='/create-report' component={() => (<Redirect to={HOME_PAGE}/>)}/>
            {OLD_CIPHER_2_REPORT_PATHS.map((path, index) => (
              <RouteWithTracing path={'/report/:external_report_id/' + path} key={'c2report' + index} render={({ match }) => (<Redirect to={'/report/' + match.params.external_report_id}/>) } />
            ))}

            {/* Old alerts routes */}
            <RouteWithTracing path='/subscribe' component={() => (<Redirect to={SUBSCRIPTIONS}/>)}/>
            <RouteWithTracing path='/subscribe-alerts' component={() => (<Redirect to={SUBSCRIPTIONS}/>)}/>

            {/* Old saved reports page */}
            <RouteWithTracing path={SAVED} component={() => (<Redirect to={HISTORY}/>)} />


            {/*C31 routes*/}
            <RouteWithTracing path={`${CREATE_REPORT}/:stream_id?/:stream_params?`} component={() => (<Redirect to={BUILD_REPORT}/>)} />
            <RouteWithTracing path={BUILD_UTT_LANDSCAPE_REPORT} component={() => (<Redirect to={BUILD_REPORT}/>)} />
            <RouteWithTracing path={BUILD_CLASSIFIER_REPORT} component={() => (<Redirect to={BUILD_REPORT}/>)} />

            {/* Main routes */}
            <RouteWithTracing
              exact
              path={ABOUT}
              render={() => (<DefaultPageWrapper><About /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              exact
              path={HOME_PAGE}
              render={() => (<DefaultPageWrapper dark={true} with_background_image={true}><Dashboard /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={BUILD_REPORT}
              render={() => (<DefaultPageWrapper><ReportBuilder /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={BUILD_ND_REPORT}
              render={() => (<DefaultPageWrapper><NDReportBuilder /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={BUILD_VALUATION_REPORT}
              render={() => (<DefaultPageWrapper><ValuationReportBuilder /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={BUILD_CUSTOM_CLUSTERED_REPORT}
              render={() => (<DefaultPageWrapper><CustomUploadReportBuilder /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={`${BUILD_CLASSIFIER_ALERT_REPORT}/:alert_tech_id`}
              render={() => (<DefaultPageWrapper><ClassifierAlertReportBuilder /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={BUILD_CLASSIFIER_REPORT}
              component={() => (<Redirect to={`${BUILD_REPORT}?portfolio_search_mode=${PORTFOLIO_SEARCH_TYPE_LANDSCAPE_SEARCH_ID}`}/>)}
            />
            <RouteWithTracing
              path={BUILD_UTT_LANDSCAPE_REPORT}
              component={() => (<Redirect to={`${BUILD_REPORT}?portfolio_search_mode=${PORTFOLIO_SEARCH_TYPE_LANDSCAPE_SEARCH_ID}`}/>)}
            />
            <RouteWithTracing
              path={BUILD_SIMILAR_FAMILIES_REPORT}
              render={() => (<DefaultPageWrapper><SimilarFamiliesReportBuilder /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={BUILD_SET_THEORY_REPORT}
              render={() => (<DefaultPageWrapper><SetTheoryReportBuilder /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={'/errorTest'}
              render={() => (<DefaultPageWrapper><ErrorTest /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={`${FAMILY}/${SOURCES}`}
              render={() => (<DefaultPageWrapper><FamilySourcesView /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={`${FAMILY}/:id/${GRAPH}`}
              render={({match}) => (<DefaultPageWrapper><FamilyGraphView match={match} /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={`${FAMILY}/:id/${SOURCES}`}
              render={() => (<DefaultPageWrapper><FamilySourcesView /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={`${FAMILY}/:id/p/:patent_id`}
              render={({match, history}) => (<DefaultPageWrapper><FamilyView match={match} history={history} /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={`${FAMILY}/:id`}
              render={({match, history}) => (<DefaultPageWrapper><FamilyView match={match} history={history} /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={`${DISPUTE}/${DOC}/:id`}
              render={({match}) => (<DefaultPageWrapper><DisputeDocView match={match} /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={`${DISPUTE}/:external_report_id/:dispute_id`}
              render={({match}) => (<DefaultPageWrapper><DisputeView match={match} /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={HISTORY}
              render={() => (<DefaultPageWrapper><Reports /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={NOT_FOUND}
              render={() => (<DefaultPageWrapper><PageNotFound /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={`${REPORT}/:external_report_id`}
              render={({match }) => (<DefaultPageWrapper display_header={false}><ViewerContainer key={`${REPORT}/${match.params.external_report_id}`}/></DefaultPageWrapper>)}
            /> {/* Explicit key ensures that the component is re-mounted if report_id changes. */}
            <RouteWithTracing
              path={`${BENCHMARKING}/:external_report_id`}
              render={({match }) => (<Benchmarking key={`${BENCHMARKING}/${match.params.external_report_id}`}/>)}
            />
            <RouteWithTracing
              path={`${SHARE_DECK}/:deck/:state_id`}
              render={({match }) => (<DeckState key={match.params.state_id}/>)}
            />
            <RouteWithTracing
              path={`${REPORT_STATE}/:state_id`}
              render={({match}) => (<ReportState key={match.params.state_id}/>) }
            />
            <RouteWithTracing
              path={`${PROJECT}/:project_id${REPORT}/:external_report_id`}
              render={({match }) => (
                <WithProject
                  key={`${PROJECT}/${match.params.project_id}${REPORT}/${match.params.external_report_id}`}
                  project_id={match.params.project_id}
                >
                  <DefaultPageWrapper display_header={false}><ViewerContainer key={`${REPORT}/${match.params.external_report_id}`}/></DefaultPageWrapper>
                </WithProject>
              )}
            />
            <RouteWithTracing
              path={`${PROJECT}/:project_id${BENCHMARKING}/:external_report_id`}
              render={({match }) => (
                <WithProject
                  key={`${PROJECT}/${match.params.project_id}${BENCHMARKING}/${match.params.external_report_id}`}
                  project_id={match.params.project_id}
                >
                  <Benchmarking key={`${BENCHMARKING}/${match.params.external_report_id}`}/>
                </WithProject>
              )}
            />
            <RouteWithTracing
              path={`${PROJECT}/:project_id`}
              render={({match}) => (<DefaultPageWrapper><ProjectView key={`${PROJECT}/${match.params.project_id}`} project_id={match.params.project_id}/></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={`${KNN}/:id?`}
              render={() => (<DefaultPageWrapper contentWrapperClassName={cs.overflow_unset}><TechExplorerContainer /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={SIMILAR_FAMILIES_SEARCH}
              render={() => (<Redirect to={KNN} />)}
            />
            <RouteWithTracing
              path={SAVED_ORGS_LISTS_ADMIN}
              render={() => (<DefaultPageWrapper><SavedListsAdmin /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              exact path='/run-report'
              component={() => (<Redirect to={'/'}/>)}
            /> {/* OLD run-report links */}
            <RouteWithTracing
              path={NOTIFICATIONS}
              render={() => (<DefaultPageWrapper><Notifications /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={'/styleguide'}
              render={() => (<DefaultPageWrapper><StyleGuide /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={'/icons'}
              render={() => (<DefaultPageWrapper><IconFinder /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={SUBSCRIPTIONS}
              render={() => (<DefaultPageWrapper><AlertSubscriptionsContainer /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={CLASSIFIERS}
              render={({match}) => (<DefaultPageWrapper><ClassifiersContainer match={match} /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={`${HELP}/${SEARCH_GUIDE}`}
              render={() => (<DefaultPageWrapper><PatentFamilyListGuide /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={`${HELP}/${CI_GUIDE}`}
              render={() => (<DefaultPageWrapper><ExecutiveSummaryGuide /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={`${HELP}/${REPORT_SCHEMA_GUIDE}`}
              render={() => (<DefaultPageWrapper><ReportSchemaPdfView /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={`${PATENT}/${DOC}/:id`}
              render={({match}) => (<DefaultPageWrapper><PatentPdfView match={match} /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={`${PATENT}/${IMAGE}/:ucid/:id`}
              render={({match}) => (<DefaultPageWrapper><PatentImageView match={match} /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={`${TRENDS}/:trend_id`}
              render={({match}) => (<DefaultPageWrapper><TrendSpotterDisplayLazy match={match} /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              path={FTAGS_UI}
              render={() => (<DefaultPageWrapper><FamilyTagsManagement /></DefaultPageWrapper>)}
            />
            <RouteWithTracing
              exact
              path={LOGOUT}
            />

            {/* Route not found */}
            <RouteWithTracing
              render={({ location }) => (<DefaultPageWrapper><PageNotFound location={location}/></DefaultPageWrapper>)}
            />

          </Switch>
        </Suspense>
      </UserSettingsDispatchContext.Provider>
    </UserSettingsContext.Provider>
  )
}

export default MainRoutes
