import React, { useEffect, useRef, useState } from 'react'
import { FormGroup, Input } from 'reactstrap'
import { SketchPicker } from 'react-color'
import cn from 'classnames'
import { Switch } from '@mui/material'

import { DEFAULT_TEXT_COLOUR_OVER_DARK_BG } from '../../../constants/colours.js'
import {
  add_new_phrase, add_new_phrase_below, remove_phrase, update_phrase, update_phrase_colour
} from '../utils/phrases_to_highlight_utils.js'
import {
  CLIENT_PREFIX,
  get_client_tags,
  get_status_tags,
  get_tag_tags,
  get_tags_string,
  STATUS_PREFIX,
  TAG_PREFIX
} from '../utils/tag_utils.js'
import { withUser } from '../../UserContext.js'
import { is_aistemos } from '../../../utils/user_permissions.js'
import { check_need_light_complimentary_colour } from '../../../utils/highlight_utils.js'
import { DEFAULT_TITLE } from '../constants/constants.js'
import AutoResizeTextArea from '../../widgets/AutoResizeTextArea.js'
import TextLink from '../../widgets/TextLink.js'
import {
  TrashIcon,
  ColourPaletteIcon,
  AddCircleIcon,
} from '../../widgets/IconSet.js'
import EditTrainingSetSingleValueTagControl from './EditTrainingSetSingleValueTagControl.js'
import { TRAINING_SET_STATUSES } from '../model/training_set_statuses.js'
import { TertiaryButton } from '../../widgets/Button.js'

import s from './Scope.module.scss'

const Scope = (
  {
    title,
    description,
    taxonomy_path,
    tags,
    training_set_id,
    training_set_info,
    training_set_infos,
    set_training_set_infos,
    set_current_training_set_info,
    notes,
    phrases_to_highlight,
    no_highlighting,
    on_change_no_highlighting,
    on_change_title,
    on_change_description,
    on_change_notes,
    on_change_phrases_to_highlight,
    handle_edit_taxonomy_path,
    handle_edit_tags,
    on_search_similar_families_by_name,
    on_search_similar_families_by_scope,
    user
  }) => {

  const [show_phrase_colour_picker_idx, set_show_phrase_colour_picker_idx] = useState(null)

  const name_input_ref = useRef(null)

  const tag_tags = get_tag_tags(tags || [])
  const client_tags = get_client_tags(tags || [])
  const status_tags = get_status_tags(tags || [])
  const is_group_aistemos = is_aistemos(user)

  useEffect(() => {
    name_input_ref.current.focus()
  }, [])

  function on_add_new_phrase_to_highlight(e, phrases_to_highlight) {
    e.stopPropagation()
    e.preventDefault()

    const new_phrases_to_highlight = add_new_phrase(phrases_to_highlight)
    on_change_phrases_to_highlight(new_phrases_to_highlight)
  }

  function on_add_new_phrase_to_highlight_below(phrases_to_highlight, idx) {
    const new_phrases_to_highlight = add_new_phrase_below(phrases_to_highlight, idx)
    on_change_phrases_to_highlight(new_phrases_to_highlight)
  }

  function on_delete_phrase(phrases_to_highlight, idx) {
    const new_phrases_to_highlight = remove_phrase(phrases_to_highlight, idx)
    on_change_phrases_to_highlight(new_phrases_to_highlight)
  }

  function on_change_phrase(phrases_to_highlight, idx, new_phrase) {
    const new_phrases_to_highlight = update_phrase(phrases_to_highlight, idx, new_phrase)
    on_change_phrases_to_highlight(new_phrases_to_highlight)
  }

  function on_change_colour(phrases_to_highlight, idx, new_colour) {
    const new_phrases_to_highlight = update_phrase_colour(phrases_to_highlight, idx, new_colour)
    on_change_phrases_to_highlight(new_phrases_to_highlight)
  }

  function handle_edit_client_tags() {
    handle_edit_tags(CLIENT_PREFIX)
  }

  function handle_edit_tag_tags() {
    handle_edit_tags(TAG_PREFIX)
  }

  return (
    <div className='position-relative d-md-flex w-100 pb-3'>
      <div className='w-100 w-md-50 pe-md-2'>
        <FormGroup>
          <div className='d-flex justify-content-between'>
            <label>Name</label>
            {on_search_similar_families_by_name &&
              <TextLink className='my-auto' onClick={on_search_similar_families_by_name} title='Search similar families' disable={(title || '') === '' || (title === DEFAULT_TITLE)} >Use name to find families</TextLink>
            }
          </div>
          <Input
            ref={name_input_ref}
            autoFocus={true}
            value={title || ''}
            placeholder={DEFAULT_TITLE}
            onChange={(e) => on_change_title(e.target.value)}
            onFocus={(e) => {
              if (title === DEFAULT_TITLE) {
              e.target.select()
            }}}
          />
        </FormGroup>

        {handle_edit_tags && is_group_aistemos &&
          <div className='row'>
            <FormGroup className='col-9'>
              <label>Client</label>
              <Input
                value={get_tags_string(client_tags)}
                placeholder='Add client'
                onClick={handle_edit_client_tags}
                onChange={handle_edit_client_tags}
              />
            </FormGroup>
            <FormGroup className='col-3'>
              <label>Status</label>
              <EditTrainingSetSingleValueTagControl
                buttonClassName={cn('w-100 h-100')}
                training_set_id={training_set_id}
                training_set_info={training_set_info}
                training_set_infos={training_set_infos}
                set_training_set_infos={set_training_set_infos}
                set_current_training_set_info={set_current_training_set_info}
                tag_prefix={STATUS_PREFIX}
                allowed_tag_values={TRAINING_SET_STATUSES}
                selected_tag_values={status_tags}
              />
            </FormGroup>
          </div>
        }

        <div className='row'>
          {handle_edit_taxonomy_path &&
            <FormGroup className='col'>
              <label>Taxonomy path</label>
              <Input
                value={taxonomy_path.join('/')}
                placeholder='Add taxonomy path'
                onClick={handle_edit_taxonomy_path}
                onChange={handle_edit_taxonomy_path}
                rows={1}
              />
            </FormGroup>
          }

          {handle_edit_tags &&
            <FormGroup className='col'>
              <label>Tags</label>
              <Input
                value={get_tags_string(tag_tags)}
                placeholder='Add tags'
                onClick={handle_edit_tag_tags}
                onChange={handle_edit_tag_tags}
              />
            </FormGroup>
          }
        </div>

        <FormGroup>
          <div className='d-flex justify-content-between'>
            <label>Scope</label>

            {on_search_similar_families_by_scope &&
              <TextLink className='my-auto' onClick={on_search_similar_families_by_scope} title='Search similar families' disable={(description || '') === ''} >Use scope to find families</TextLink>
            }
          </div>
          <AutoResizeTextArea
            value={description || ''}
            on_change={on_change_description}
            rows={4}
          />
        </FormGroup>

        <FormGroup>
          <label>Build notes</label>
          <AutoResizeTextArea
            value={notes}
            on_change={on_change_notes}
            rows={11}
          />
        </FormGroup>
      </div>

      <div className='w-100 w-md-50 ps-md-2'>
        <div className='mb-2'>
          <span className={s.label}>
            Show highlighted terms
          </span>
          <Switch
            className='ms-2'
            checked={!no_highlighting}
            onClick={on_change_no_highlighting.bind(null, !no_highlighting)}
          />
        </div>

        <FormGroup>
          <div className={cn('d-flex justify-content-between mb-2', s.phrase_heading)}>

            <span className={s.label}>
              Phrases to highlight
            </span>

            <TertiaryButton size='sm' onClick={(e) => on_add_new_phrase_to_highlight(e, phrases_to_highlight)} >
              <span><span className='me-2'><AddCircleIcon /></span><span>Add</span></span>
            </TertiaryButton>
          </div>

          {phrases_to_highlight.map(({ phrase, colour }, idx) => {

            const need_light_fg_colour = colour ? check_need_light_complimentary_colour(colour) : false

            return (
              <div
                key={idx}
                className={cn('d-flex mb-3 position-relative', s.phrase_row)}
              >
                <input
                  className={cn('form-control', s.phrase_input)}
                  style={{
                    backgroundColor: colour,
                    ...(need_light_fg_colour ? { color: DEFAULT_TEXT_COLOUR_OVER_DARK_BG }: {})
                  }}
                  autoComplete='off'
                  autoCorrect='off'
                  spellCheck='false'
                  //placeholder={`Enter phrase here...`}
                  placeholder={``}
                  value={phrase}
                  type='text'
                  onChange={(event) => on_change_phrase(phrases_to_highlight, idx, event.target.value)}
                />

                <div className={cn('d-flex', s.actions_wrapper)}>

                  <TextLink
                    title='Choose colour'
                    className={cn('my-auto ms-2', s.action_icon)}
                    onClick={() => set_show_phrase_colour_picker_idx(idx)}
                    no_decoration
                  >
                    <ColourPaletteIcon/>
                  </TextLink>
                  {show_phrase_colour_picker_idx === idx &&
                    <div className={s.colour_picker_popover}>
                      <div
                        className={s.colour_picker_cover}
                        onClick={() => set_show_phrase_colour_picker_idx(null)}
                      />
                      <SketchPicker
                        color={colour}
                        onChangeComplete={(new_colour) => on_change_colour(phrases_to_highlight, idx, new_colour.hex)}
                      />
                    </div>
                  }

                  <TextLink
                    title='Delete'
                    className={cn('my-auto ms-2', s.action_icon)}
                    onClick={() => on_delete_phrase(phrases_to_highlight, idx)}
                    no_decoration
                  >
                    <TrashIcon/>
                  </TextLink>

                  <TextLink
                    title='Add new phrase below this row'
                    className={cn('my-auto ms-2', s.action_icon)}
                    onClick={() => on_add_new_phrase_to_highlight_below(phrases_to_highlight, idx)}
                    no_decoration
                  >
                    <AddCircleIcon />
                  </TextLink>
                </div>
              </div>
            )
          })}
        </FormGroup>
      </div>
    </div>
  )
}

export default withUser(Scope)