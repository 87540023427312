import React from 'react'
import cn from 'classnames'

import ButtonGroup from '../widgets/ButtonGroup.js'
import { VIEW_ID_TO_VIEW_ICON } from '../../model/views.js'

import s from './ViewSelector.module.scss'


const ViewSelector = ({ className, available_view_items, selected_view_id, on_change_selected_view_id }) => {
  if (available_view_items.length < 2) {
    // No choice of views, so don't render selector
    return null
  }

  const available_view_items_with_ids = available_view_items.map(item => {
    const { view_id , name: title } = item
    const view_icon = VIEW_ID_TO_VIEW_ICON[view_id]
    const name = view_icon ? <view_icon.icon /> : title
    return {...item, id: view_id, title, name}
  })

  return (
    <ButtonGroup
      items={available_view_items_with_ids}
      selected_id={selected_view_id}
      on_change_selected_id={on_change_selected_view_id}
      className={className}
      btnClassName={cn('d-flex justify-content-center mb-2 p-2', s.view_button)}
    />
  )
}

export default ViewSelector