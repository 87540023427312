import axios, { AxiosError } from 'axios'

import {
  EntityPermissions,
  Project,
  Report,
  UserFile
} from '../components/project_management/model/project'
import { DOWNLOAD_BASE_URL, PROJECT_BASE_URL } from '../constants/urls.js'
import { add_source_err_to_target_err, MULTIPART_POST_HEADER } from './axios_utils.js'
import { fetch_file } from './download_utils.js'

export interface PostExternalReportIds {
  external_report_ids: string[]
}

export interface PostS3ResourceIds {
  resource_ids: string[]
}

export interface PostProjectPermissions {
  users: PostEntityPermission[],
  groups: PostEntityPermission[]
}

export interface PostEntityPermission {
  entity_uuid: string,
  permissions: EntityPermissions
}

export interface PaginationParams {
  page: number,
  limit: number
}

export function get_user_project_history(pagination?: PaginationParams): Promise<Project[]> {
  return axios.get(`${PROJECT_BASE_URL}/user_projects`, {params: pagination})
    .then(response => response.data)
    .catch((err: AxiosError) => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to load user project history')
    })
}

export function create_project(project_name: string): Promise<string> {
  return axios.post(`${PROJECT_BASE_URL}/`, null, {params: {project_name}})
    .then(response => response.data)
    .catch((err: AxiosError) => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to create project: ')
    })
}

export function get_project(project_id: string): Promise<Project> {
  return axios.get(`${PROJECT_BASE_URL}/${project_id}`)
    .then(response => response.data)
    .catch((err: AxiosError) => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to get project info: ')
    })
}

export function update_project_name(project_id: string, project_name: string): Promise<string> {
  return axios.put(`${PROJECT_BASE_URL}/${project_id}`, null, {params: {project_name}})
    .then(response => response.statusText)
    .catch((err: AxiosError) => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to update project name: ')
    })
}

export function delete_project(project_id: string): Promise<string> {
  return axios.delete(`${PROJECT_BASE_URL}/${project_id}`)
    .then(response => response.statusText)
    .catch((err: AxiosError) => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to delete project: ')
    })
}

export function get_project_reports(project_id: string, pagination?: PaginationParams): Promise<Report[]> {
  return axios.get(`${PROJECT_BASE_URL}/${project_id}/reports`, {params: pagination})
    .then(response => response.data)
    .catch((err: AxiosError) => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to get project reports: ')
    })
}

export function add_project_reports(project_id: string, payload: PostExternalReportIds): Promise<string> {
  return axios.post(`${PROJECT_BASE_URL}/${project_id}/reports`, payload)
    .then(response => response.statusText)
    .catch((err: AxiosError) => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to add reports to project: ')
    })
}

export function remove_project_reports(project_id: string, payload: PostExternalReportIds): Promise<string> {
  return axios.delete(`${PROJECT_BASE_URL}/${project_id}/reports`, {data: payload})
    .then(response => response.statusText)
    .catch((err: AxiosError) => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to remove reports to project: ')
    })
}

export function get_project_files(project_id: string, pagination?: PaginationParams): Promise<UserFile[]> {
  return axios.get(`${PROJECT_BASE_URL}/${project_id}/files`, {params: pagination})
    .then(response => response.data)
    .catch((err: AxiosError) => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to get project files list: ')
    })
}

export function add_project_files(project_id: string, files: File[]): Promise<string> {
  const data_form = new FormData()
  files.forEach((file: File) => {
    data_form.append('uploads', file)
  })

  return axios.post(`${PROJECT_BASE_URL}/${project_id}/files`, data_form, {
    headers: MULTIPART_POST_HEADER
  })
    .then(response => response.statusText)
    .catch((err: AxiosError) => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to upload project files: ')
    })
}

export function delete_project_files(project_id: string, payload: PostS3ResourceIds): Promise<string> {
  return axios.delete(`${PROJECT_BASE_URL}/${project_id}/files`, {data: payload})
    .then(response => response.statusText)
    .catch((err: AxiosError) => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to delete project files: ')
    })
}

export function add_project_file_version(project_id: string, s3_resource_id: string, file: File): Promise<string> {
  const data_form = new FormData()
  data_form.append('upload', file)
  return axios.post(`${PROJECT_BASE_URL}/${project_id}/files/${s3_resource_id}`, null, {
    headers: MULTIPART_POST_HEADER,
    data: data_form
  })
    .then(response => response.statusText)
    .catch((err: AxiosError) => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to update project file version: ')
    })
}

export function get_project_file_history(project_id: string, s3_resource_id: string): Promise<UserFile[]> {
  return axios.get(`${PROJECT_BASE_URL}/${project_id}/files/${s3_resource_id}/history`)
    .then(response => response.data)
    .catch((err: AxiosError) => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to get project file history: ')
    })
}

export function get_project_file_cache_id(project_id: string, file_meta: UserFile): Promise<void>  {
  const { id, s3_resource_id } = file_meta || {}
  return axios.get(`${PROJECT_BASE_URL}/${project_id}/files/${s3_resource_id}/${id}`)
    .then(response => response.data)
    .catch((err: AxiosError) => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to get project file id: ')
    })
}

export function get_project_archive_cache_id(project_id: string): Promise<void>  {
  return axios.get(`${PROJECT_BASE_URL}/${project_id}/download_files`)
    .then(response => response.data)
    .catch((err: AxiosError) => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to get project archive id: ')
    })
}

export function update_project_permissions(project_id: string, payload: PostProjectPermissions): Promise<string> {
  return axios.patch(`${PROJECT_BASE_URL}/${project_id}/permissions`, payload)
    .then(response => response.statusText)
    .catch((err: AxiosError) => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to update project permissions: ')
    })
}

export function fetch_project_file(inputs: any) {
  return fetch_file(`${DOWNLOAD_BASE_URL}/project/file`, inputs)
}

export function fetch_project_all_files_as_zip(inputs: any) {
  return fetch_file(`${DOWNLOAD_BASE_URL}/project/zipped_files`, inputs)
}

export function are_permission_rows_identical(a: any, b: any) {
  return a.entity_id === b.entity_id && a.entity_type === b.entity_type && a.view === b.view && a.edit === b.edit
}