import React, { useState } from 'react'

import { Label } from './FamilyDetailsLabel.js'
import CpcWithHover from './CpcWithHover.js'
import AllCpcsModal from './AllCpcsModal.js'
import TextLink from '../widgets/TextLink.js'

export function FamilyCpcCodes({ family, highlighter_props }) {

  const [show_all, set_show_all] = useState(false)
  const {cpcCodes: cpc_codes, primaryCpc, patFamId} = family

  const has_cpc_codes = (cpc_codes != null) && (cpc_codes.length > 0)

  const has_primary_cpc_code = has_cpc_codes && (cpc_codes.indexOf(primaryCpc) > -1)

  const secondary_cpc_codes = has_primary_cpc_code ? (cpc_codes || []).filter(cpc_code => cpc_code !== primaryCpc) : cpc_codes

  return (
    <div key={`cpc_codes_${patFamId}`}>
      <div className='d-flex'>
        <Label>CPC codes</Label>
        {has_cpc_codes &&
          <TextLink
            className='ms-auto'
            onClick={() => {set_show_all(true)}}
          >
            <span>Show all</span>
          </TextLink>
        }
      </div>

      {has_primary_cpc_code &&
        <div className='d-flex my-2'>
          <CpcWithHover
            cpc_codes={[primaryCpc]}
            highlighter_props={highlighter_props}
          />
          <span className='ms-1'>(primary)</span>
        </div>
      }

      <div className='d-flex flex-wrap'>
        {has_cpc_codes && secondary_cpc_codes.map((cpc_code, i) => {
          return (
            <CpcWithHover
              key={i}
              cpc_codes={[cpc_code]}
              highlighter_props={highlighter_props}
              show_separator={i < secondary_cpc_codes.length - 1}
              className='me-1'
            />
        )})}

        {!has_cpc_codes && <span>None</span>}
      </div>

      {show_all &&
        <AllCpcsModal
          cpc_codes={cpc_codes}
          primary={primaryCpc}
          highlighter_props={highlighter_props}
          on_close={() => { set_show_all(false)}}
        />
      }

    </div>
  )
}