import React, { useState } from 'react'
import { Autocomplete, capitalize, TextField } from '@mui/material'
import cn from 'classnames'
import _ from 'underscore'

import {
  CREATED_AT_FIELD_ID,
  ID_TO_REPORT_FIELD,
  IS_SELECTED_FIELD_ID,
  NAME_FIELD_ID,
  OWNER_FIELD_ID, STATUS_FIELD_ID
} from '../report_management/model/report_fields.js'
import { get_report_viewer_url, report_has_bespoke_viewer } from '../../utils/report_url_utils.js'
import TextLink from '../widgets/TextLink.js'
import { PROJECT, REPORT } from '../../constants/paths.js'
import { withUser } from '../UserContext.js'
import ReportManagementTable from '../report_management/components/ReportManagementTable.js'
import { DESCENDING } from '../../model/sort_directions.js'
import { sort_reports } from '../report_management/utils/sort_and_filter_utils.js'
import CheckboxStatic from '../widgets/CheckboxStatic.js'
import { ProjectUnlinkIcon } from '../widgets/IconSet.js'

import cs from '../cipher_styles.module.scss'

const ProjectReports = (
  {
    user,
    project_id,
    can_edit,
    user_reports,
    project_reports,
    report_owners,
    is_fetching_reports,
    selected_reports_external_ids,
    toggle_selected,
    handle_add_reports_to_project,
    handle_unlink_reports_from_project
  }) => {

  const [selected_sort_field_id, set_selected_sort_field_id] = useState(CREATED_AT_FIELD_ID)
  const [selected_sort_direction_id, set_selected_sort_direction_id] = useState(DESCENDING)

  function on_change_sort_field_and_direction(sort_field_id, sort_direction_id) {
    set_selected_sort_field_id(sort_field_id)
    set_selected_sort_direction_id(sort_direction_id)
  }

  // Report management table state
  const report_fields = [
    ...can_edit ? [IS_SELECTED_FIELD_ID] : [],
    NAME_FIELD_ID,
    OWNER_FIELD_ID,
    CREATED_AT_FIELD_ID,
    STATUS_FIELD_ID
  ].map(field_id => ID_TO_REPORT_FIELD[field_id])

  const reports_sorted = sort_reports(project_reports, report_owners, selected_sort_field_id, selected_sort_direction_id)
  const reports_to_display = reports_sorted.map(report => ({...report, owner_name: report_owners[report.external_report_id]}))

  const total_selected_reports = selected_reports_external_ids.length
  const is_select_all_project_reports = _.every(
    project_reports, report => _.contains(selected_reports_external_ids, report.external_report_id)
  )

  return (
    <>
      <h5 className='mt-4 mb-3'>
        Reports
      </h5>
      <div className={cn('d-flex', 'align-items-center')}>
        {can_edit &&
          <>
            <div className='w-50 me-2'>
              <Autocomplete
                options={user_reports}
                size='small'
                value={null}
                onChange={handle_add_reports_to_project}
                disabled={!can_edit || (user_reports && user_reports.length === 0)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={'Add report to project'}
                    placeholder={'Search reports by name'}
                  />
                )}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.external_report_id}>
                      {option.title}
                    </li>
                  )
                }}
                getOptionLabel={(option) => option.title}
                getOptionDisabled={(option) => _.some(project_reports, r => r.external_report_id === option.external_report_id)}
              />
            </div>
            <TextLink
              className='ms-1 d-flex'
              disable={project_reports.length === 0}
              onClick={() => toggle_selected(project_reports, !is_select_all_project_reports)}
              title={is_select_all_project_reports ? 'Deselect all reports' : 'Select all reports'}>
              <CheckboxStatic
                is_checked={is_select_all_project_reports}
                is_partial={total_selected_reports > 0}
                is_disabled={project_reports.length === 0}
              />
              <span className='ms-1'>{is_select_all_project_reports ? 'Deselect all reports' : 'Select all reports'}</span>
            </TextLink>
            {total_selected_reports > 0 &&
              <TextLink
                className='ms-3'
                onClick={handle_unlink_reports_from_project}
                disable={total_selected_reports === 0 || !can_edit}
                title='Remove from project'
                no_decoration
              >
                <ProjectUnlinkIcon/>
                <span className={cn('ms-1', cs.underline_on_hover)}>
                  Remove {total_selected_reports || ''} from project
                </span>
              </TextLink>
            }
          </>
        }
      </div>
      <ReportManagementTable
        className='mt-3'
        fields={report_fields}
        field_id_to_render_fn={{
          [NAME_FIELD_ID]: function NameField(report) {
            const has_bespoke_viewer = report_has_bespoke_viewer(report.report_type)
            return (
              <div className="d-flex flex-wrap mb-1">
                {has_bespoke_viewer &&
                  <span className="me-1">{capitalize(report.report_type)}:</span>
                }
                <TextLink
                  element="a"
                  href={`${PROJECT}/${project_id}${get_report_viewer_url(report.report_type, report.external_report_id)}`}
                  target="_blank"
                  title="Open in new tab"
                >
                  {report.title}
                </TextLink>
                {has_bespoke_viewer &&
                  <TextLink
                    className="small me-1"
                    element="a"
                    href={`${PROJECT}/${project_id}${REPORT}/${report.external_report_id}`}
                    target="_blank"
                    title="Open in new tab"
                  >
                    [open in main Cipher]
                  </TextLink>
                }
              </div>
            )
          }
        }}
        reports={reports_to_display}
        selected_external_report_ids={selected_reports_external_ids}
        selected_sort_field_id={selected_sort_field_id}
        selected_sort_direction_id={selected_sort_direction_id}
        on_change_sort_field_id_and_sort_direction_id={on_change_sort_field_and_direction}
        user={user}
        toggle_selected={toggle_selected}
        no_data_text={is_fetching_reports ? null : 'No reports in project'}
        loading_text='Loading report history'
        loading={is_fetching_reports}
      />
    </>
  )
}

export default withUser(ProjectReports)