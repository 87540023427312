import React from 'react'
import { DropdownItem } from 'reactstrap'
import cn from 'classnames'

import ScrollableList from '../widgets/ScrollableList.js'
import BaseDropdown from '../widgets/BaseDropdown.js'

import s from './PatentNumberSelector.module.scss'

export const PatentNumberSelector = ({patent_numbers, selected_patent_number, on_click_from_selected_patent_handler}) => {

  if (!patent_numbers || (patent_numbers.length < 2)) {
    return null
  }

  return (
    <BaseDropdown
      buttonClassName='fs-mask'
      menuClassName={cn('fs-mask', s.menu)}
      label={selected_patent_number}
    >
      <ScrollableList
        className='py-2 px-1'
      >
        {patent_numbers.map((item, i) => {
          const is_selected = (item === selected_patent_number)
          return (
            <DropdownItem key={i} onClick={() => on_click_from_selected_patent_handler(item)} active={is_selected}>
              {item}
            </DropdownItem>
          )})}
      </ScrollableList>
    </BaseDropdown>
  )
}