import React from 'react'
import cn from 'classnames'

import { PrimaryButton, ProminentButton } from '../../widgets/Button.js'

import s from './ProgressPrompt.module.scss'

const ProgressPrompt = ({is_wizard_final_step, can_wizard_continue, on_step_complete, className, children}) => {

  const button_label = is_wizard_final_step ? 'Create report' : 'Next'
  const ButtonElement = is_wizard_final_step ? ProminentButton : PrimaryButton

  return (
    <div className={cn('d-sm-flex pb-3 w-100', s.block, className)}>
      <div className='my-auto'>
        {children}
        {' '}
        <span>When you’re done with selections, click ‘{button_label}’ {is_wizard_final_step ? '' : 'to continue'}</span>
      </div>
      <ButtonElement className={cn('ms-0 ms-sm-2', s.btn)} onClick={on_step_complete} disabled={!can_wizard_continue}>{button_label}</ButtonElement>
    </div>
  )
}

export default ProgressPrompt