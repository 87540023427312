import React from 'react'
import cn from 'classnames'

import s from './Label.module.scss'

const Label = ({children, className, is_input_invalid=false}) => {
  return (
    <div className={cn(s.label, (is_input_invalid ? s.invalid : ''), className)}>{children}</div>
  )
}

export default Label