import axios from 'axios'
import _ from 'underscore'

import { USER_GROUP_SETTINGS_BASE_URL } from '../constants/urls.js'
import { add_source_err_to_target_err } from './axios_utils.js'
import { fetch_my_keycloak_groups } from './user_group_utils.js'
import { is_tech_discovery } from './utils.js'

export function fetch_user_group_settings() {
  if (is_tech_discovery()) {
    return Promise.resolve({})
  }

  return fetch_my_keycloak_groups()
    .then(groups => {
      const { id: group_id } = (groups || [])[0] || {}
      return fetch_group_settings(group_id)
    })
}

export function fetch_group_settings(group_id) {
  return axios.get(`${USER_GROUP_SETTINGS_BASE_URL}/${group_id}`)
    .then(response => {
      const key_to_string = response.data
      // parse values
      return _.mapObject(key_to_string, val => JSON.parse(val))
    })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch GroupSetting: ')
      throw wrapped_err
    })
}

export function save_group_setting_by_id(group_id, key, value) {
  const data = {
    group_id, key,
    value: JSON.stringify(value)
  }

  return axios.post(`${USER_GROUP_SETTINGS_BASE_URL}/save`, data, {headers: {'Content-Type': 'application/json' }})
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to save GroupSetting: ')
      throw wrapped_err
    })
}