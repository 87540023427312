import React from 'react'

const UnavailableClassifiersWarning = ({unavailable_classifiers, suggested_action_text}) => {

  const is_plural = unavailable_classifiers.length > 1

  return (
    <div>
      <div className='mb-2'>The following classifier{is_plural ? 's': ''} could not be located. This might be due to a change in ownership, or {is_plural ? 'they' : 'it'} may have been
        removed or replaced in a taxonomy:
      </div>
      {unavailable_classifiers.map((item, i) => {
        const {name} = item

        return (
          <div className='ms-2' key={i}>{name}</div>
        )
      })}

      {suggested_action_text &&
        <div className='mt-2'>{suggested_action_text}</div>
      }
    </div>
  )
}

export default UnavailableClassifiersWarning