import React, { useState, useEffect } from 'react'
import cn from 'classnames'

import Spinner from '../../widgets/Spinner.js'
import {
  THREE_COLUMN_UPLOAD_MODES,
  validate_custom_clustered_text_upload,
  validate_custom_clustered_file_upload,
  DEFAULT_UPLOAD_MODE,
  FILE_UPLOAD_MODE_ID,
  TEXT_UPLOAD_MODE_ID,
} from '../../../utils/custom_search_utils.js'
import { BadInputAlertModal } from '../BadInputAlertModal.js'
import { CustomClusteredUploadHelp } from './CustomClusteredUploadHelp.js'
import { CSV_FORMAT, CustomClusteredOptions, TSV_FORMAT } from './CustomClusteredOptions.js'
import { TextInputUpload } from '../TextInputUpload.js'
import { FileInputUpload } from '../FileInputUpload.js'
import { CSV_FILE_EXT, TSV_FILE_EXT } from '../../../utils/download_utils.js'
import { get_short_date_string_for_now } from '../../../utils/time_utils.js'
import { CustomClusteredResultItem } from './CustomClusteredResultItem.js'
import UploadAction from '../UploadAction.js'
import { OnOffButton } from '../../widgets/Button.js'

import s from './CustomClusteredUpload.module.scss'

const DEFAULT_ERROR_MSG = 'Error validating custom clustered report'

const CustomClusteredUpload = ({
  results_action_btn_label,
  parsed_results_action_handler
}) => {

  const [show_spinner, set_show_spinner] = useState(false)
  const [report_name, set_report_name] = useState('')
  const [input_text, set_input_text] = useState('')
  const [parsed_input, set_parsed_input] = useState({}) //parsed 3 column upload
  const [on_edit, set_on_edit] = useState(false)
  const [orgs, set_orgs] = useState([])
  const [techs, set_techs] = useState([])
  const [upload_mode, set_upload_mode] = useState(DEFAULT_UPLOAD_MODE)

  const [parsing_data_error, set_parsing_data_error] = useState(null)

  const [input_for_3column_upload, set_input_for_3column_upload] = useState(null)
  const [file_input, set_file_input] = useState(null)
  const [selected_format, set_selected_format] = useState(TSV_FORMAT)

  const default_report_name = `Custom clustered upload (${get_short_date_string_for_now()})`

  useEffect(() => {
    if (!input_for_3column_upload) return
    let did_cancel = false
    const {portfolio_by_user, format_type} = input_for_3column_upload
    validate_custom_clustered_text_upload(portfolio_by_user, format_type)
      .then(validated_clustered_report => {
        if (!did_cancel) {
          const parsed = {
            portfolios: validated_clustered_report.organisations.map((org) => {
              return {name: org.name, pat_fam_ids: org.items}
            }),
            clusters: validated_clustered_report.technologies.map((tech) => {
              return {name: tech.name, pat_fam_ids: tech.items}
            }),
            families: validated_clustered_report.families
          }
          set_input_for_3column_upload(null)
          set_show_spinner(false)
          set_parsed_input(parsed)
          set_orgs(parsed.portfolios)
          set_techs(parsed.clusters)
          set_report_name(default_report_name)
          set_parsing_data_error(null)
        }
      })
      .catch((err) => {
        if (!did_cancel) {
          set_input_for_3column_upload(null)
          set_show_spinner(false)
          const err_msg = err.status === 400 ? err.response.data.title : DEFAULT_ERROR_MSG
          set_parsing_data_error(err_msg)
        }
      })
    return () => {
      did_cancel = true
    }
  }, [input_for_3column_upload, default_report_name])

  useEffect(() => {
    if (!file_input) return
    let did_cancel = false
    const file = file_input
    validate_custom_clustered_file_upload(file)
      .then(validated_clustered_report => {
        if (!did_cancel) {
          const parsed = {
            portfolios: validated_clustered_report.organisations.map((org) => {
              return {name: org.name, pat_fam_ids: org.items}
            }),
            clusters: validated_clustered_report.technologies.map((tech) => {
              return {name: tech.name, pat_fam_ids: tech.items}
            }),
            families: validated_clustered_report.families
          }
          set_show_spinner(false)
          set_parsed_input(parsed)
          set_orgs(parsed.portfolios)
          set_techs(parsed.clusters)
          set_report_name(default_report_name)
        }
      })
      .catch((err) => {
        if (!did_cancel) {
          set_show_spinner(false)
          set_file_input(null)
          const err_msg = err.status === 400 ? err.response.data.title : DEFAULT_ERROR_MSG
          set_parsing_data_error(err_msg)
        }
      })
    return () => {
      did_cancel = true
    }
  }, [file_input, default_report_name])


  function update_custom_patent_portfolio_from_file(file) {
    set_show_spinner(true)
    set_parsing_data_error(null)
    set_on_edit(false)
    set_file_input(file)
  }

  function on_edit_portfolio() {
    // Reset all state (but leave the text input 'portfolio_by_user')

    set_show_spinner(false)
    set_report_name('')
    set_parsed_input({})
    set_orgs([])
    set_techs([])

    set_parsing_data_error(null)

    set_input_for_3column_upload(null)
    set_file_input(null)

    set_on_edit(true)
  }

  function on_change_input_text(input_text) {
    set_input_text(input_text)
    const first_line = input_text.split('\n')[0]
    const comma_count = (first_line.match(/,/g) || []).length
    const tab_count = (first_line.match(/\t/g) || []).length
    // set format based on what we have in the first row
    if (tab_count === 2) {
      set_selected_format(TSV_FORMAT)
    } else if (comma_count === 2) {
      set_selected_format(CSV_FORMAT)
    }
  }

  function trigger_text_validation(format_type) {
    // check if the input_text seems to follow the format_type
    if (is_format_input_consistent(input_text, format_type)) {
      set_show_spinner(true)
      set_parsing_data_error(null)
      set_on_edit(false)
      set_input_for_3column_upload({portfolio_by_user: input_text, format_type})
    } else {
      set_parsing_data_error('The input doesn\'t seem to follow the specified data format:' + format_type.toUpperCase() + ' ')
    }
  }

  function is_format_input_consistent(input, type) {
    const lines = input.split('\n')
    if (lines.length > 0) {
      const comma_separated = lines[0].split(',')
      const tab_separated = lines[0].split('\t')
      const consistent = ((type === CSV_FORMAT && comma_separated.length > 2)
        || (type === TSV_FORMAT && tab_separated.length > 2))
      return consistent
    }
    return true
  }

  function handle_results() {
    const {families} = parsed_input || {}

    parsed_results_action_handler({name: report_name, found_families: families, portfolios: orgs, clusters: techs})

    set_report_name('')
    set_input_text('')
    set_parsed_input({})
  }

  function change_upload_mode(upload_mode_id) {
    set_upload_mode(upload_mode_id)
  }

  const is_upload_validated = Object.keys(parsed_input).length > 0

  const show_input = (!is_upload_validated || on_edit) && !parsing_data_error && !show_spinner
  const show_results_for_custom_clustered_upload = is_upload_validated && !on_edit && !show_spinner && !parsing_data_error

  const is_text_input = (upload_mode === TEXT_UPLOAD_MODE_ID)
  const is_file_input = (upload_mode === FILE_UPLOAD_MODE_ID)

  // check for empty strings in the input cluster names; these can lead to missing org and tech names, and a broken report
  const is_input_completely_clustered = orgs && orgs.every(org => org.name) && techs && techs.every(tech => tech.name)

  return (
    <div>
      {parsing_data_error &&
        <BadInputAlertModal
          error_msg={parsing_data_error.message || parsing_data_error}
          title='Input parsing error'
          on_hide={() => set_parsing_data_error(null)}
        />
      }
      {show_spinner &&
        <div className='text-center'>
          <p>Fetching results ...</p>
          <Spinner/>
        </div>
      }
      {show_input &&
        <div>

          <div className='mb-3'>
            {THREE_COLUMN_UPLOAD_MODES.map(item => {
              const {id, name} = item || {}

              return (
                <OnOffButton
                  key={id}
                  is_on={id === upload_mode}
                  onClick={change_upload_mode.bind(null, id)}
                  className='px-3'
                >
                  {name}
                </OnOffButton>
              )
            })}
          </div>

          <CustomClusteredUploadHelp
            className='my-2'
            upload_mode={upload_mode}
          />
          <>
            {is_text_input &&
              <>
                <CustomClusteredOptions
                  className={cn('mb-1', s.options_wrapper)}
                  data_format={selected_format}
                  set_data_format={set_selected_format}
                />
                <TextInputUpload
                  input_value={input_text}
                  input_rows={10}
                  placeholder={'Paste custom clustered data'}
                  on_change_input={(event) => on_change_input_text(event.target.value)}
                  on_submit={() => trigger_text_validation(selected_format)}
                />
              </>
            }
            {is_file_input &&
              <FileInputUpload
                on_change_file_upload={update_custom_patent_portfolio_from_file}
                file_ext_accepted={[CSV_FILE_EXT, TSV_FILE_EXT]}
              />
            }

          </>
        </div>
      }
      {show_results_for_custom_clustered_upload &&
        <div>
          <div className={cn('alert', (is_input_completely_clustered ? 'alert-success' : 'alert-warning'))}>
            <CustomClusteredResultItem
              items={orgs || {}}
              section_name='Organisations'
              className='mb-3'
            />

            <CustomClusteredResultItem
              items={techs || {}}
              section_name='Technologies'
            />

          </div>

          {!is_input_completely_clustered &&
            <div className='text-end mb-3'>
              Some families in the input data were missing either an Organisation or a Technology name.
              Please complete or remove these rows before uploading.
            </div>
          }

          <UploadAction
            name={report_name}
            on_submit_handler={handle_results}
            on_cancel_handler={on_edit_portfolio}
            submit_button_label={results_action_btn_label || 'Create report'}
            show_buttons={true}
            enable_rename={is_input_completely_clustered}
            can_submit={is_input_completely_clustered}
            on_name_change_handler={set_report_name}
          />
        </div>
      }
    </div>
  )
}

export default CustomClusteredUpload