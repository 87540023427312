import React, { Component } from 'react'
import cn from 'classnames'
import _ from 'underscore'

import {
  is_classifier,
  is_search_match,
  get_checkbox_tree_value,
  get_child_nodes_as_array,
  get_leaf_nodes_as_array,
  is_deep_tree,
  update_selected_with_node_ids,
  is_node_fully_selected,
  is_node_partially_selected
} from '../../utils/classifier_tree_utils.js'
import { flatten_arrays } from '../../utils/utils.js'
import { InfoPopover } from '../widgets/Tooltip.js'
import { Highlighter } from '../widgets/Highlighter.js'
import { update_spotlighted_items } from '../../utils/viewer_utils.js'
import CheckboxTree from '../widgets/CheckboxTree.js'
import IndirectlySelectedMarker from '../widgets/IndirectlySelectedMarker.js'

import s from './ClassifiersCheckboxTree.module.scss'
// NOTE: adds global css to react-checkbox-tree.override.scss

const MAX_SIZE_EXPANDED_BY_DEFAULT = 15

class ClassifiersCheckboxTree extends Component {
  constructor(props) {
    super(props)

    this.state = {
      expanded: [],
      checked: [],
      is_fully_expanded: false
    }

    this.spotlight_toggle = this.spotlight_toggle.bind(this)
    this.get_item_description = this.get_item_description.bind(this)
  }

  componentDidMount() {
    this.expand_to_default_view()
  }

  componentDidUpdate(prevProps) {
    const { classifiers_data }                       = this.props
    const { classifiers_data: prev_classifier_data } = prevProps
    const { id }                                     = (classifiers_data || {})
    const { id: prev_id }                            = (prev_classifier_data || {})
    if (id != null && prev_id != null && id !== prev_id) {
      // Top level id has changed, so reset expanded state
      this.expand_to_default_view()
    }

    if (_.isMatch(prevProps, _.pick(this.props, 'search_input', 'show_selected_only'))) {

      if (this.props.show_selected_only && !this.is_showing_selected_only()) {
        this.props.update_is_showing_selected_only()
      }
      return
    }

    const {search_input, show_selected_only} = this.props
    const {is_fully_expanded} = this.state // expanding can be slow, so avoid doing this repeatedly

    const show_expanded = (search_input && search_input.length)

    // handle show/hide changes originating with ClassifierPanel controls
    if (show_expanded && !is_fully_expanded) {
      this.expand_all()

    } else if (show_selected_only) {
      this.expand_selected()

    } else if (is_fully_expanded && !show_expanded) {
      // might happen if the user has cleared the search field; reset to default view
      this.expand_to_default_view()
    }
  }

  expand_all() {
    // expanded needs to be set to an array of all the non-leaf nodes
    const nodes = this.get_classifiers_as_nodes()
    const expanded = this.get_node_values_to_expand(nodes)
    this.setState({expanded, is_fully_expanded: true})
  }

  expand_to_default_view() {
    // if we already have selections show expanded selected view by default;
    // if not, expand just first level of nodes, or whole tree if below a certain size
    const {selected_classifiers, classifiers_data} = this.props
    const total_classifiers_in_tree = get_leaf_nodes_as_array(classifiers_data).length
    if (selected_classifiers.length > 0) {
      this.expand_selected()
    } else if (total_classifiers_in_tree <= MAX_SIZE_EXPANDED_BY_DEFAULT) {
      this.expand_all()
    } else {
      this.setState({
        expanded: this.get_classifiers_as_nodes().map(n => n.value),
        is_fully_expanded: false
      })
    }
  }

  expand_selected() {
    this.setState({expanded: this.get_expandable_with_selections(), is_fully_expanded: false})
  }

  is_showing_selected_only() {
    const {expanded} = this.state
    return expanded && expanded === this.get_expandable_with_selections()
  }

  get_expandable_with_selections() {
    const {selected_classifiers} = this.props

    const checked = selected_classifiers ? selected_classifiers.map(c => get_checkbox_tree_value(c)) : []
    const nodes = this.get_classifiers_as_nodes()

    return this.get_nodes_with_children_in_selected(nodes, checked)
  }

  get_values_for_indirect_selections() {
    const {selected_indirectly} = this.props
    return (selected_indirectly || []).map(classifier => get_checkbox_tree_value(classifier))
  }

  get_classifiers_as_nodes() {
    const {classifiers_data} = this.props

    const indirect_selections_values = this.get_values_for_indirect_selections()

    if (classifiers_data.length) {
      // we have an array / more than one top-level node
      return classifiers_data.map(node => this.classifier_node_to_tree_node(node, indirect_selections_values))
    }
    return [this.classifier_node_to_tree_node(classifiers_data, indirect_selections_values)]
  }

  classifier_node_to_tree_node(node_data, values_selected_indirectly) {
    // generate react-checkbox-tree node properties
    const value = get_checkbox_tree_value(node_data)
    const {children} = node_data

    // any classifiers matching ones that have been selected in other trees should be disabled to preserve paths and some styling added
    const selected_indirectly = _.contains(values_selected_indirectly, value)
    const all_children_selected_indirectly = children && _.all(get_child_nodes_as_array(node_data).map(node => !is_classifier(node) || _.contains(values_selected_indirectly, get_checkbox_tree_value(node))))

    const is_disabled = node_data.disabled || all_children_selected_indirectly

    return {
      className: this.node_is_visible(node_data) ? '' : 'd-none',
      value,
      label: this.render_title_label(node_data, is_disabled, selected_indirectly),
      children: children ? children.map(node => this.classifier_node_to_tree_node(node, values_selected_indirectly)) : null,
      disabled: is_disabled,
    }
  }

  spotlight_toggle(node_data) {
    const {spotlighted_item_ids, set_spotlighted_item_ids, selected_classifiers, id_key} = this.props
    const value = get_checkbox_tree_value(node_data)

    const checked = selected_classifiers ? selected_classifiers.map(c => get_checkbox_tree_value(c)) : []
    const is_checked = _.contains(checked, value)

    return update_spotlighted_items(node_data[id_key], is_checked, spotlighted_item_ids, set_spotlighted_item_ids)
  }

  get_item_description(item) {
    const {get_description} = this.props
    const {description} = item || {}

    const item_description = get_description ? get_description(item) : description

    if (!item_description || item_description.trim() === '') return null

    return item_description
  }

  render_title_label(node_data, is_disabled, selected_indirectly) {
    const {search_input, spotlighted_item_ids, set_spotlighted_item_ids, id_key, post_label: PostLabel} = this.props
    const {name} = node_data || {}
    const description = this.get_item_description(node_data)

    const item_id = node_data[id_key]
    const is_spotlighted = _.contains(spotlighted_item_ids || [], item_id)

    return (
      // if not a leaf node, fall back on on-click behaviour defined in the CheckboxTree props below...
      <label className={s.label}>
        <span
          className={cn(`${is_disabled ? s.node_disabled : ''}`, 'me-1', {[s.label__spotlighted]: is_spotlighted})}
          onClick={(e) => {
            if (is_classifier(node_data) && !is_disabled) {
              if (e.altKey && set_spotlighted_item_ids) {
                return this.spotlight_toggle(node_data)
              }

              this.select_or_deselect_one(get_checkbox_tree_value(node_data))
            }}
          }
        >
          <Highlighter
            search_words={[search_input]}
            text_to_highlight={name}
          />
          {selected_indirectly &&
            <IndirectlySelectedMarker />
          }
        </span>
        {description &&
          <span>
            <InfoPopover wide interactive={true}>
              {description}
            </InfoPopover>
          </span>
        }
        {PostLabel &&
          <PostLabel
            node={node_data}
          />
        }
      </label>
    )
  }

  node_is_visible(node_data) {
    const {search_input} = this.props
    if (!search_input || !search_input.length) {
      return true
    }
    if (is_search_match(search_input, node_data.name)) {
      return true
    }
    if (node_data.children) {
      // hide parent level nodes only if all their children are hidden
      return _.some(get_child_nodes_as_array(node_data), child => this.node_is_visible(child))
    }
    return false
  }

  select_or_deselect_one(value) {
    const {selected_classifiers, is_selection_permitted, unpermitted_selection_handler} = this.props

    const checked = selected_classifiers ? selected_classifiers.map(c => get_checkbox_tree_value(c)) : []

    if (_.contains(checked, value)) {
      return this.on_update_checked(checked.filter(v => v !== value))
    }
    const can_select = is_selection_permitted != null ? is_selection_permitted : true

    if (!can_select) {
      unpermitted_selection_handler()
      return
    }

    this.on_update_checked([...checked, value])
  }

  get_node_child_ids(node, exclude_selected_indirectly) {
    const values_selected_indirectly = exclude_selected_indirectly ? this.get_values_for_indirect_selections() : []

    return get_leaf_nodes_as_array(node)
      .map(t => t.value)
      .filter(value => !_.contains(values_selected_indirectly, value))
  }

  select_or_deselect_children(node, checked) {
    const {should_exclude_if_partial_node_clicked} = this.props

    const available_child_values = this.get_node_child_ids(node, true)

    const updated_checked = update_selected_with_node_ids(checked, available_child_values, should_exclude_if_partial_node_clicked)

    this.on_update_checked(updated_checked)
  }

  get_node_values_to_expand(nodes) {
    if (!nodes) {
      return []
    }
    return flatten_arrays(nodes.map(node => {
      if (!node.children || node.className === 'd-none') {
        return []
      }
      return [node.value, ...this.get_node_values_to_expand(node.children)]
    }))
  }

  get_nodes_with_children_in_selected(nodes, selected) {
    if (!nodes) {
      return []
    }
    return flatten_arrays(nodes.map(node => {
      if (!node.children || !_.some(get_child_nodes_as_array(node), child => _.contains(selected, child.value))) {
        return []
      }
      return [node.value, ...this.get_nodes_with_children_in_selected(node.children, selected)]
    }))
  }

  get_selected_classifiers_from_values(values) {
    const classifiers = get_leaf_nodes_as_array(this.props.classifiers_data)
    const selected = classifiers.filter(c => _.contains(values, get_checkbox_tree_value(c)))

    return _.uniq(selected, c => JSON.stringify(c))
  }

  on_update_checked(checked, targetNode) {
    const {prohibit_parent_level_selection, selected_classifiers, is_selection_permitted, unpermitted_selection_handler, should_exclude_if_partial_node_clicked} = this.props

    const {checked: is_checked = false} = targetNode || {}

    const something_selected = (is_checked)
    const can_select = is_selection_permitted != null ? is_selection_permitted : true

    const is_node_selected = targetNode && targetNode.children && !prohibit_parent_level_selection

    const prev_checked = selected_classifiers ? selected_classifiers.map(c => get_checkbox_tree_value(c)) : []

    if (something_selected && !can_select) {

      const available_child_values = is_node_selected ? this.get_node_child_ids(targetNode, true) : []

      const will_deselect_node =
        is_node_selected &&
        should_exclude_if_partial_node_clicked && (
          is_node_fully_selected(prev_checked, available_child_values) ||
          is_node_partially_selected(prev_checked, available_child_values)
        )

      if (!will_deselect_node) {
        unpermitted_selection_handler()
        return
      }
    }

    if (is_node_selected) {
      return this.select_or_deselect_children(targetNode, prev_checked)
    }

    const classifiers = this.get_selected_classifiers_from_values(checked)
    this.props.on_selected_classifiers_updated(classifiers)
  }

  on_expand(to_expand, target_node) {
    // was this a click on the "collapse" label? if so we want to leave the top-level parent expanded
    const expanded = (target_node || to_expand.length) ? to_expand : this.get_classifiers_as_nodes().map(n => n.value)
    this.setState({ expanded })
  }

  render() {
    const {prohibit_parent_level_selection, selected_classifiers, check_icon, uncheck_icon, tree_control_className, hide_root_node } = this.props
    const {expanded} = this.state
    // 'checked' and 'expanded' need to be arrays of node values in the CheckboxTree
    const checked = selected_classifiers ? selected_classifiers.map(c => get_checkbox_tree_value(c)) : []
    const nodes = this.get_classifiers_as_nodes()
    const show_expand_all = is_deep_tree(nodes)

    const nodes_short = hide_root_node ? nodes[0].children : nodes

    return (
      <CheckboxTree
        nodes={nodes_short}
        checked={checked}
        expanded={expanded}
        on_check={(checked, targetNode) => this.on_update_checked(checked, targetNode)}
        on_expand={(expanded, targetNode) => this.on_expand(expanded, targetNode)}
        show_expand_all={show_expand_all}
        on_click={(node) => {
          // overridden because default on-click behaviour interferes with info icon/ popover on leaf nodes
          if (node.children && !prohibit_parent_level_selection) {
            this.select_or_deselect_children(node, checked)
          }
        }}
        expand_on_click={prohibit_parent_level_selection}
        only_leaf_checkboxes={prohibit_parent_level_selection}
        className={tree_control_className}
        check_icon={check_icon}
        uncheck_icon={uncheck_icon}
      />
    )
  }
}

export default ClassifiersCheckboxTree