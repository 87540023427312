import React from 'react'
import _ from 'underscore'
import cn from 'classnames'

import UserCreatedCompanyListsPanel from './UserCreatedCompanyListsPanel.js'
import OrgListAdminLink from './OrgListAdminLink.js'
import { CogIcon } from '../widgets/IconSet.js'

import cs from '../cipher_styles.module.scss'

const UserCreatedCompanyLists = ({company_lists, on_list_select_handler, className}) => {

  const [shared_lists, my_lists] = _.partition(company_lists, list => list.is_shared)

  const lists_to_display = []
  if (my_lists && !_.isEmpty(my_lists)) {
    lists_to_display.push({lists: my_lists, label: 'My lists'})
  }
  if (shared_lists && !_.isEmpty(shared_lists)) {
    lists_to_display.push({lists: shared_lists, label: 'Shared lists'})
  }

  return (
    <div className={className}>
      <div className='d-flex flex-nowrap me-3'>
        <h4>Saved lists</h4>
        <span className='mb-2 d-flex'>
          <OrgListAdminLink label={<CogIcon/>} className={cn('ms-1 my-auto', cs.no_underline_on_hover)} />
        </span>
      </div>
      {lists_to_display.map((item, i) => {
        const {lists, label} = item
        return (
          <UserCreatedCompanyListsPanel
            key={i}
            label={label}
            company_lists={lists}
            on_list_select_handler={on_list_select_handler}
            className='ps-2 mt-2'
          />
        )
      })}
    </div>
  )
}

export default UserCreatedCompanyLists