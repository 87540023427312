import React from 'react'

import Modal from '../widgets/Modal.js'
import { PrimaryButton } from '../widgets/Button.js'

const GroupingConfirmationModal = ({on_hide, on_confirm}) => {
  function handle_confirm() {
    on_hide()
    on_confirm()
  }

  const primary_button = (
    <PrimaryButton onClick={handle_confirm}>Continue</PrimaryButton>
  )

  return (
    <Modal is_open={true} on_hide={on_hide} primary_button={primary_button} title={''}>
      Organisations in the basket are currently grouped into org set members.
      Press &#39;Close&#39; to pause and review tags before grouping.
      Press &#39;Continue&#39; to finish grouping.
    </Modal>
  )
}

export default GroupingConfirmationModal