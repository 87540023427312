import React from 'react'
import cn from 'classnames'

import { AVAILABLE_PORTFOLIO_ROLLUP_LIMITS, DEFAULT_PORTFOLIO_ROLLUP_LIMIT } from '../../model/portfolio_rollup_limits.js'
import { format_integer_with_comma } from '../../utils/utils.js'
import ValueDropdownSelector from '../widgets/ValueDropdownSelector.js'
import { InfoPopover } from '../widgets/Tooltip.js'
import Label from '../widgets/Label.js'

import s from './PortfolioRollupLimitControl.module.scss'

const PortfolioRollupLimitControl = ({
  portfolio_roll_up_limit,
  set_portfolio_roll_up_limit,
  className
}) => {
  const portfolio_roll_up_limit_or_default = portfolio_roll_up_limit || DEFAULT_PORTFOLIO_ROLLUP_LIMIT

  return (
    <div className={className}>
      <div className='d-flex mb-2'>
        <Label>
          Maximum number of organisations:
        </Label>
        <InfoPopover
          is_in_modal={true}
          placement='bottom'
          buttonClassName='my-auto ms-1'
        >
        <span>
          <p>This value defines the maximum number of individual organisations in a report.</p>
          <p>A large quantity of organisations can significantly slow Classification reports.</p>
          <p>Hence a limit is applied, with excess organisations rolled up into a synthetic organisation called &quot;Next&quot;.</p>
          <p className='mb-0'>For example, suppose a selection contains 1234 organisations. If the maximum is set at 1000, the resulting report will contain the top 999 individual organisations by size active, and an additional organisation called &quot;Next 235&quot;.</p>
        </span>
        </InfoPopover>
      </div>

      <ValueDropdownSelector
        buttonClassName={s.portfolio_roll_up_limit_dropdown_btn}
        menuClassName={cn('text-end', s.portfolio_roll_up_limit_dropdown_menu)}
        values={AVAILABLE_PORTFOLIO_ROLLUP_LIMITS}
        format_value_fn={format_integer_with_comma}
        selected_value={portfolio_roll_up_limit_or_default}
        on_change_value={set_portfolio_roll_up_limit}
        right={false}
      />

    </div>
  )
}

export default PortfolioRollupLimitControl