import React from 'react'
import { withRouter, matchPath } from 'react-router'
import { DropdownItem } from 'reactstrap'
import cn from 'classnames'
import _ from 'underscore'

import { BAR_CLUSTER_ID, BUBBLE_ID, CHOROPLETH_MAP_ID, GROWTH_TREEMAP_ID, TREEMAP_ID } from '../../model/view_ids.js'
import { SPEC_ID_TO_GROUP, SPEC_ID_TO_SPEC_REF } from '../../model/spec_groups.js'
import { get_spec_name } from '../../utils/spec_utils.js'
import ItemHeader from './ItemHeader.js'
import { DownloadSpinner } from '../widgets/Spinner.js'
import MainReportItemFilters from './MainReportItemFilters.js'
import MainReportItemAgglom from './MainReportItemAgglom.js'
import BaseDropdown from '../widgets/BaseDropdown.js'

import cs from '../cipher_styles.module.scss'
import s from './MainReportItemHeader.module.scss'

const MainReportItemHeader = (
  {
    className,
    base_path,
    view_id,
    on_close,
    is_renderable,
    spec,
    item,
    too_big_for_microsoft_download,
    download_powerpoint,
    download_excel,
    download_csv,
    download_png,
    download_summary_csv,
    download_summary_excel,
    download_summary_powerpoint,
    is_selected,
    is_export_in_progress,
    is_disputes_dataset,


    filters_available,
    rollups_available,
    selections,
    ref_data,
    deref_data,
    data,
    continent_id_to_geo_ids,
    report_has_scores,
    data_creation_date,
    item_selections,
    processed_key_dims,
    report_series_sort,

    on_change_rollup_thresholds,
    on_change_dataset_portfolios_selection,
    on_change_dataset_techs_selection,
    on_change_dataset_geo_selection,
    on_change_dataset_spotlighted_portfolios,
    on_change_dataset_spotlighted_techs,
    on_change_report_portfolios_selection,
    on_change_report_techs_selection,
    on_change_report_geo_selection,
    on_change_next_agglom_item_visibility
}) => {

  const main_spec_id = spec.id
  const { name: group_name, id: group_id } = SPEC_ID_TO_GROUP[main_spec_id]

  const main_spec_name = get_spec_name(spec, SPEC_ID_TO_SPEC_REF)

  const is_chart_export_available = !_.contains([TREEMAP_ID, GROWTH_TREEMAP_ID, CHOROPLETH_MAP_ID, BUBBLE_ID, BAR_CLUSTER_ID], view_id)

  const base_path_exact_match = matchPath(window.location.pathname, { path: base_path, exact: true })

  const has_summary_download_options = download_summary_csv || download_summary_excel || download_summary_powerpoint

  return (
    <ItemHeader
      className={className}
      on_close={on_close}
      group_id={group_id}
      group_name={group_name}
      spec_name={main_spec_name}
      item={item}
      level_1_path={base_path}
      is_selected={is_selected}
    >
      <div className='ms-auto d-flex'>
        {is_export_in_progress &&
          <DownloadSpinner
            className='my-auto me-1'
          />
        }

        {(filters_available || rollups_available) &&
          <>
            {filters_available &&
              <MainReportItemFilters
                spec={spec}
                item={item}
                selections={selections}
                selected_view_id={view_id}
                data={data}
                ref_data={ref_data}
                deref_data={deref_data}
                continent_id_to_geo_ids={continent_id_to_geo_ids}
                report_has_scores={report_has_scores}
                data_creation_date={data_creation_date}
                item_selections={item_selections}
                item_processed_key_dims={processed_key_dims}
                report_series_sort={report_series_sort}

                on_change_rollup_thresholds={on_change_rollup_thresholds}
                on_change_dataset_portfolios_selection={on_change_dataset_portfolios_selection}
                on_change_dataset_techs_selection={on_change_dataset_techs_selection}
                on_change_dataset_geo_selection={on_change_dataset_geo_selection}
                on_change_dataset_spotlighted_portfolios={on_change_dataset_spotlighted_portfolios}
                on_change_dataset_spotlighted_techs={on_change_dataset_spotlighted_techs}
                on_change_report_portfolios_selection={on_change_report_portfolios_selection}
                on_change_report_techs_selection={on_change_report_techs_selection}
                on_change_report_geo_selection={on_change_report_geo_selection}
              />
            }

            {rollups_available &&
              <MainReportItemAgglom
                spec={spec}
                item={item}
                selected_view_id={view_id}
                data={data}
                ref_data={ref_data}
                deref_data={deref_data}
                data_creation_date={data_creation_date}
                item_processed_key_dims={processed_key_dims}

                on_change_rollup_thresholds={on_change_rollup_thresholds}
                on_change_next_agglom_item_visibility={on_change_next_agglom_item_visibility}
              />
            }
          </>
        }

        {base_path_exact_match &&
          <BaseDropdown
            label='Export'
            buttonClassName={cn('ms-2', s.dropdown_button)}
            disabled={!is_chart_export_available && !download_png}
            right
          >
            { has_summary_download_options &&
              <DropdownItem header>Summary</DropdownItem>
            }
            { download_summary_powerpoint &&
              <DropdownItem
                onClick={download_summary_powerpoint}
                disabled={!is_renderable || is_export_in_progress}
              >
                Powerpoint
              </DropdownItem>
            }
            { download_summary_excel &&
              <DropdownItem
                onClick={download_summary_excel}
                disabled={!is_renderable || is_export_in_progress}
              >
                Excel
              </DropdownItem>
            }
            { download_summary_csv &&
              <DropdownItem
                onClick={download_summary_csv}
                disabled={!is_renderable || is_export_in_progress}
              >
                CSV
              </DropdownItem>
            }

            { is_disputes_dataset && has_summary_download_options &&
              <>
                <DropdownItem divider />
                <DropdownItem header>Disputes</DropdownItem>
              </>
            }

            <DropdownItem
              onClick={download_powerpoint}
              disabled={too_big_for_microsoft_download || !download_powerpoint || !is_renderable || is_export_in_progress}
            >
              PowerPoint{!is_chart_export_available ? '*' : ''}
            </DropdownItem>

            <DropdownItem
              onClick={download_excel}
              disabled={!download_excel || !is_renderable || is_export_in_progress}
            >
              Excel{!is_chart_export_available ? '*' : ''}
            </DropdownItem>

            <DropdownItem
              onClick={download_csv}
              disabled={!download_csv || !is_renderable || is_export_in_progress}
            >
              CSV{!is_chart_export_available ? '*' : ''}
            </DropdownItem>

            {download_png &&
              <DropdownItem
                onClick={download_png}
                disabled={!is_renderable || is_export_in_progress}
              >
                PNG
              </DropdownItem>
            }

            {!is_chart_export_available &&
              <DropdownItem className={cn('mt-2', cs.font_size_x_small, cs.cursor_default, s.info)}>
                * This chart type cannot be exported but you can download the data.
              </DropdownItem>
            }
          </BaseDropdown>
        }

      </div>
    </ItemHeader>
  )
}

export default withRouter(MainReportItemHeader)