import React, { useEffect } from 'react'
import cn from 'classnames'
import { Chip } from '@mui/material'

import TextLink from '../widgets/TextLink.js'
import { ConfigureIcon } from '../widgets/IconSet.js'
import Spinner from '../widgets/Spinner.js'
import { set_sticky_top } from '../../utils/sticky_utils.js'
import { PrimaryButton } from '../widgets/Button.js'
import ThresholdControl from './ThresholdControl.js'

import s from './ClassifiersBasket.module.scss'

const ClassifiersBasket = (
  { heading_text, empty_basket_text, selected_classifiers, is_saving, remove_from_basket, update_success,
    show_config_controls, configure_threshold_handler, threshold_options, toggle_show_config_controls, submit_basket_handler
  }) => {

  useEffect(() => {
    set_sticky_top('.' + s.subs_basket_container)
  }, [])

  const is_basket_empty = (selected_classifiers || []).length === 0

  return (
    <div className={cn('mt-lg-1 sticky-top')}>
      <div className={cn(s.subs_basket_container)}>
        <div className={cn('px-2', s.subs_basket_header_wrapper)}>
          <span className='d-flex justify-content-between'>
            <span className='py-2'>{heading_text}</span>
            {toggle_show_config_controls && (
              <TextLink
                onClick={toggle_show_config_controls}
                className={s.settings_btn}
                disable={is_basket_empty}
                no_decoration
              >
                <ConfigureIcon/>
              </TextLink>
            )}
          </span>

        </div>
        <div className={cn(s.subs_basket_items_container)}>
          {is_basket_empty &&
            <span className={s.subs_basket_prompt}>
              {empty_basket_text}
            </span>
          }
          <div className={cn('d-flex justify-content-end', s.controls_label)}>
            {show_config_controls && configure_threshold_handler && !is_basket_empty &&
              <span>score threshold</span>
            }
          </div>

          <div>
            {(selected_classifiers || []).map((tech, idx) => (
              <div key={idx} className={cn('d-flex justify-content-between mb-1', s.subs_basket_item_wrapper)}>
                <Chip
                  label={tech.name}
                  onDelete={() => remove_from_basket(tech)}
                />
              {show_config_controls && configure_threshold_handler &&
                <ThresholdControl
                  threshold={tech.threshold}
                  threshold_options={threshold_options}
                  on_change_value={(value) => configure_threshold_handler(tech, value)}
                />
              }
            </div>
          ))}
        </div>
          { is_saving &&
            <Spinner/>
          }
          { update_success &&
            <div className={s.subs_basket_success_text}>Update saved.</div>
          }
        </div>
      </div>
      {submit_basket_handler &&
        <div className='mt-2'>
          <PrimaryButton className='w-100' disabled={(is_basket_empty)} onClick={submit_basket_handler}>Next</PrimaryButton>
        </div>
      }
    </div>
  )
}

export default ClassifiersBasket