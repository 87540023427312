import React from 'react'
import cn from 'classnames'
import { DropdownItem } from 'reactstrap'

import { SAMPLE_LIMIT } from '../../model/family_view.js'
import { PatentLink } from '../widgets/PatentLink.js'
import TextLink from '../widgets/TextLink.js'
import { Label } from './FamilyDetailsLabel.js'
import {
  PATENT_LINKS_OPTIONS,
  PATENT_LINKS_OPTIONS_BY_ID,
} from '../../model/patent_links.js'
import { FAMILY, GRAPH } from '../../constants/paths.js'
import { is_array_non_empty_non_null } from '../../utils/utils.js'
import BaseDropdown from '../widgets/BaseDropdown.js'

import s from './FamilyPatentsDisplay.module.scss'

export const FamilyPatentsDisplay = (
  {
    title,
    patent_list=[],
    patent_link_mode_id,
    set_patent_link_mode_id,
    on_show_full_set, // optional
    is_limit,
    show_graph_link,
    show_patent_link_mode_switch,
    cipher_family_id,
    error_fetching
}) => {

  function get_patents_to_display() {
    return is_limit ? patent_list.slice(0, Math.min(patent_list.length, SAMPLE_LIMIT)) : patent_list
  }

  const is_list_empty = !is_array_non_empty_non_null(patent_list)

  const patents =  is_list_empty ? [] : get_patents_to_display()

  return (
    <div key={title+cipher_family_id}>
      <div className='d-lg-flex justify-content-between'>
        {title &&
          <Label className='me-lg-2'>{title}</Label>
        }
        {show_graph_link && !is_list_empty &&
          <TextLink
            className='ms-lg-2 my-auto'
            element='a'
            target='_blank'
            href={`${FAMILY}/${cipher_family_id}/${GRAPH}`}
          >
            See family tree
          </TextLink>
        }
      </div>

      {error_fetching &&
        <div>There was an error fetching patent data.</div>
      }

      {is_list_empty &&
        <div>None</div>
      }

      {!is_list_empty &&
        <div className='d-flex flex-wrap'>
          {patents.map((patent, i) => (
            <span key={i} className='me-2' >
              <PatentLink
                patent_number={patent}
                patent_link_mode_id={patent_link_mode_id}
              />
            </span>
          ))}

          {on_show_full_set && (patent_list.length > patents.length) &&
           <span
             className={cn('me-3')}
           >
             <TextLink
               onClick={on_show_full_set}
               className='text-endbold'
             >
               Show full set
             </TextLink>
           </span>
          }
        </div>
      }

      {!on_show_full_set && !is_list_empty && (patent_list.length > patents.length) &&
        <div className=''>...</div>
      }

      {show_patent_link_mode_switch && !is_list_empty &&
        <div className={cn(is_limit ? 'd-lg-flex' : 'd-flex my-2')}>
          <span className='my-auto me-2'>Open with:</span>

          <BaseDropdown
            label={PATENT_LINKS_OPTIONS_BY_ID[patent_link_mode_id].label}
            menuClassName={s.menu}
          >
            {PATENT_LINKS_OPTIONS.map((item, i) => {
              const {id, label} = item
              return (
                <DropdownItem key={i} onClick={() => set_patent_link_mode_id(id)} active={patent_link_mode_id != null ? id === patent_link_mode_id : false}>{label}</DropdownItem>
              )
            })}
          </BaseDropdown>
        </div>
      }

    </div>
  )
}