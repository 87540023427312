import React, { useState, useEffect } from 'react'

import { PrimaryButton } from './widgets/Button.js'
import Modal from './widgets/Modal.js'
import { get_server_version } from '../utils/version_utils.js'

const OnlineDetector = () => {

  const [show_connection_warning, set_show_connection_warning] = useState(false)

  function show_warning_if_offline() {
    if (window.navigator.onLine) {
      set_show_connection_warning(false)
      return
    }
    // sometimes the onLine property is unreliable for slow connections, so send a little request to confirm
    get_server_version()
      .catch(() => set_show_connection_warning(true))
      .then(() => set_show_connection_warning(false))
  }

  const on_online  = () => set_show_connection_warning(false)
  const on_offline = () => show_warning_if_offline()

  useEffect(() => {
    show_warning_if_offline()

    // register handlers
    window.addEventListener('online',  on_online)
    window.addEventListener('offline', on_offline)

    return () => {
      // clean up
      window.removeEventListener('online',  on_online)
      window.removeEventListener('offline', on_offline)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {show_connection_warning &&
        <Modal
          size='md'
          on_hide={() => set_show_connection_warning(false)} // dismiss the warning for now
          close_label='Dismiss'
          title={'No internet connection'}
          primary_button={
            <PrimaryButton
              onClick={() => window.location.reload()} // reload the current page
            >
              Retry
            </PrimaryButton>
          }
        >
          <p>Internet connection does not seem to be available.</p>
          <p>Please check your connection.</p>
        </Modal>
      }
    </>
  )
}

export default OnlineDetector