import React, { useState } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import cn from 'classnames'
import _ from 'underscore'

import { ALL, SELECTION_VIEWS } from '../../model/selection_views.js'

import TextLink from '../widgets/TextLink.js'
import ClearableSearchInput from '../widgets/ClearableSearchInput.js'
import {
  LightbulbCrossedIcon,
  LightbulbOffIcon,
  LightbulbOnIcon,
  PropagateUpIcon,
  MoreVertIcon
} from '../widgets/IconSet.js'
import SelectionViewControl from './SelectionViewControl.js'
import SimpleSortControl from './SimpleSortControl.js'

import s from './CheckboxGroupHeader.module.scss'
import cs from '../cipher_styles.module.scss'

const CheckboxGroupHeader = (
  {
    id_key, page_items, filtered_items, all_items,
    selected_item_ids, set_selected_item_ids, handle_change_search_phrase, search_phrase,
    is_filtered, num_filtered_results, num_selectable_items,
    select_all, deselect_all,
    no_select_all_control,
    show_selection_view_controls, selection_view_id, on_change_selection_view_id,
    show_sort_controls, available_sort_items, sort_id, on_change_sort_id,
    no_options_dropdown,
    deselect_all_but_first_count,
    deselect_all_but_last_count,
    first_count,
    last_count,
    set_first_count,
    set_last_count,
    menu_right,
    extra_dropdown_option,
    agglom_controls,
    chart_filters,
    is_next_agglom_selected,
    update_global_filters,
    is_item_spotlighting_enabled,
    clear_spotlighting,
    toggle_spotlighting,
    should_apply_spotlights,
    can_clear_spotlights,
    className,
}) => {

  const [is_show_options, set_is_show_options] = useState(false)

  function is_all_selected(all_items, selected_item_ids, is_next_agglom_selected) {
    if (!all_items || !selected_item_ids) {
      return false
    }

    const all_item_ids = all_items.map(item => item.id)

    const diff = _.difference(all_item_ids, selected_item_ids)
    return (diff.length === 0) && (is_next_agglom_selected != null ? is_next_agglom_selected : true)
  }

  function handle_select_deselect_all() {
    const all_selected = is_all_selected(all_items, selected_item_ids, is_next_agglom_selected)

    if (all_selected) {
      deselect_all() // clear all
    } else {
      select_all()
    }
  }

  function do_select_only() {
    set_selected_item_ids(filtered_items.map(item => item[id_key]))
  }

  function select_deselect_all_filtered(select) {
    const filtered_items_ids = filtered_items.map(item => item[id_key])

    const all_selected = select ? _.uniq([...selected_item_ids, ...filtered_items_ids]) :  _.difference(selected_item_ids, filtered_items_ids)

    set_selected_item_ids(all_selected)
  }

  function disable_select_only() {
    const filtered_items_ids = filtered_items.map(item => item[id_key])
    return (_.difference(selected_item_ids, filtered_items_ids).length === 0) &&
      (_.difference(filtered_items_ids, selected_item_ids).length === 0)
  }

  function disable_select_all() {
    const filtered_items_ids = filtered_items.map(item => item[id_key])
    return _.every(filtered_items_ids, (id) => (selected_item_ids.indexOf(id) > -1))
  }

  function disable_deselect_all() {
    const filtered_items_ids = filtered_items.map(item => item[id_key])
    return !_.some(filtered_items_ids, (id) => (selected_item_ids.indexOf(id) > -1))
  }

  const exists_items = page_items && page_items.length > 0
  const all_selected = is_all_selected(all_items, selected_item_ids, is_next_agglom_selected)
  const selection_view_id_or_default = selection_view_id || ALL

  return (
    <div className={className}>

      {/* Top controls row */}
      {(show_selection_view_controls || show_sort_controls) &&
        <div className={cn('d-flex', 'align-items-center', 'mb-2')}>
          {show_selection_view_controls &&
            <SelectionViewControl
              available_selection_views={SELECTION_VIEWS}
              selection_view_id={selection_view_id_or_default}
              on_change_selection_view_id={on_change_selection_view_id}
            />
          }

          {show_sort_controls &&
            <SimpleSortControl
              className={'ms-auto'}
              available_sort_items={available_sort_items}
              sort_id={sort_id}
              on_change_sort_id={on_change_sort_id}
            />
          }
        </div>
      }

      {agglom_controls &&
        agglom_controls
      }

      {/* Search bar */}
      <div className={cn(s.row_container, 'd-flex')}>
        <span
          className={cn(s.search_container, 'flex-grow-1')}
        >
          <ClearableSearchInput
            inputClassName={cn(
              {'icon': (search_phrase || '') === ''},
              s.search_input
            )}
            placeholder='&#xe9c2;' // magnifying glass entity
            value={search_phrase}
            handle_change={handle_change_search_phrase}
            show_clear={true}
            autoComplete='off'
            autoCorrect='off'
            autoCapitalize='off'
            spellCheck={false}
          />
        </span>

        {!no_options_dropdown &&
          <Dropdown
            isOpen={is_show_options}
            toggle={() => set_is_show_options(!is_show_options)}
            className={cn('my-auto', s.options_menu_container)}
          >
            <DropdownToggle
              tag={'div'}
              className={cn(cs.cursor_pointer, 'px-2')}
            >
              <MoreVertIcon />
            </DropdownToggle>
            <DropdownMenu className={s.options_menu} right={menu_right}>
              {!extra_dropdown_option &&
                <>
                  <div className='mb-2'>
                    <span>
                      <span className={s.count_filter_label}>
                        <span className={s.count_filter_label_prefix}>
                          Select only first
                        </span>
                        <input
                          className={s.count_input}
                          value={first_count}
                          onChange={e => set_first_count(e.target.value)}
                        />
                      </span>
                      <TextLink
                        className={s.count_filter_submit}
                        onClick={deselect_all_but_first_count}
                      >
                        Submit
                      </TextLink>
                    </span>
                  </div>
                  <span>
                    <span className={s.count_filter_label}>
                      <span className={s.count_filter_label_prefix}>
                        Select only last
                      </span>
                      <input
                        className={s.count_input}
                        value={last_count}
                        onChange={e => set_last_count(e.target.value)}
                      />
                    </span>
                    <TextLink
                      className={s.count_filter_submit}
                      onClick={deselect_all_but_last_count}
                    >
                      Submit
                    </TextLink>
                  </span>
                </>
              }
              {extra_dropdown_option}
            </DropdownMenu>
          </Dropdown>

        }
      </div>

      {/* Search results summary, actions */}
      {is_filtered &&
        <div className={cn('mt-2')}>
          <div className={s.filter_info}>{num_filtered_results} matches found</div>

          {num_filtered_results > 0 &&
            <div className='d-flex'>
              <TextLink disable={disable_select_only()} onClick={do_select_only} >select only</TextLink>
              <div className={cn('mx-1 px-1', s.filtered_options__middle)}>
                <TextLink disable={disable_select_all()} onClick={() => select_deselect_all_filtered(true)}>add</TextLink>
              </div>
              <TextLink disable={disable_deselect_all()} onClick={() => select_deselect_all_filtered(false)}>remove</TextLink>
            </div>
          }
        </div>
      }

      {(!no_select_all_control && num_selectable_items > 1 && !is_filtered && exists_items && (selection_view_id_or_default === ALL)) &&
        <div
          className='d-flex justify-content-between mt-2'
        >
          {/* Select all control */}
          <TextLink onClick={handle_select_deselect_all}>{exists_items && all_selected ? 'Deselect all' : 'Select all'}</TextLink>

          {is_item_spotlighting_enabled &&
            <div className='d-flex'>
              <TextLink onClick={clear_spotlighting} title='Clear spotlighting' className={s.spotlight_control} disable={!can_clear_spotlights}><LightbulbCrossedIcon /></TextLink>
              <TextLink onClick={toggle_spotlighting} title={`Spotlighting ${should_apply_spotlights ? 'on' : 'off'}`} className={s.spotlight_control}>
                {should_apply_spotlights && <LightbulbOnIcon />}
                {!should_apply_spotlights && <LightbulbOffIcon />}
              </TextLink>
            </div>
          }

          {chart_filters &&
            <TextLink disable={(selected_item_ids || []).length === 0} onClick={update_global_filters}><PropagateUpIcon /> Apply to all charts</TextLink>
          }

        </div>
      }

    </div>
  )
}

export default CheckboxGroupHeader