import React from 'react'

import Modal from '../widgets/Modal.js'
import { PrimaryButton } from '../widgets/Button.js'

const AssigneeUngroupModal = ({org_name, on_confirm, on_hide}) => {
  return (
    <Modal is_open={true} on_hide={on_hide} primary_button={<PrimaryButton onClick={() => on_confirm()}>Yes, ungroup</PrimaryButton>} title={`Ungrouping ${org_name}`}>
      Are you sure you want to ungroup this assignee?
    </Modal>
  )
}

export default AssigneeUngroupModal