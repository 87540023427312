import React from 'react'
import ReactCheckboxTree from 'react-checkbox-tree'
import cn from 'classnames'

import CheckboxStatic from './CheckboxStatic.js'
import { ChevronRightIcon, ChevronDownIcon } from './IconSet.js'
import TextLink from './TextLink.js'

import s from './CheckboxTree.module.scss'

const CheckboxTree = (
  {
    nodes,
    checked,
    expanded,
    on_check,
    on_expand,
    show_expand_all,
    on_click,
    expand_on_click,
    only_leaf_checkboxes,
    check_icon,
    uncheck_icon,
    className,
  }) => {

  return (
    <ReactCheckboxTree
      nodes={nodes}
      checked={checked}
      expanded={expanded}
      onCheck={on_check}
      onExpand={on_expand}
      showExpandAll={show_expand_all}
      onClick={on_click}
      expandOnClick={expand_on_click}
      onlyLeafCheckboxes={only_leaf_checkboxes}
      optimisticToggle
      icons={{
        check: check_icon || <CheckboxStatic is_checked={true} />,
        uncheck: uncheck_icon || <CheckboxStatic is_checked={false} />,
        halfCheck: <CheckboxStatic is_checked={false} is_partial={true} />,
        expandClose: <span className={cn(s.tree_control_icon, 'pb-1', className)}><ChevronRightIcon /></span>,
        expandOpen: <span className={cn(s.tree_control_icon, 'pb-1', className)}><ChevronDownIcon /></span>,
        expandAll: <TextLink className={cn('me-2', s.tree_control, className)}>Expand</TextLink>,
        collapseAll: <TextLink className={cn(s.tree_control, className)}>Collapse</TextLink>,
        parentClose: null,
        parentOpen: null,
        leaf: null,
      }}
    />
  )
}

export default CheckboxTree