import React from 'react'
import cn from 'classnames'
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'

import OrganisationSimple from './OrganisationSimple.js'
import { ChevronDownIcon } from '../widgets/IconSet.js'

import s from './OrgSearchMultipleMatchesDropdown.module.scss'

const IgnoreDisplay = ({className}) => {
  return (
    <div className={className}>
      <OrganisationSimple
        org={{name: '(ignore)'}}
      >
        {''}
      </OrganisationSimple>
    </div>
  )
}

const MenuOptionWrapper = ({on_click, children, is_selected}) => {
  return (
    <DropdownItem onClick={on_click} tag='div' active={is_selected}>
      {children}
    </DropdownItem>
  )
}

const OrgSearchMultipleMatchesDropdown = ({ best_match, all_match_options, on_update_best_match, is_ignore, on_ignore }) => {
  const is_menu_disabled = is_ignore && !on_ignore

  const show_ignored_as_best_match = is_ignore && on_ignore

  return (
    <div className='d-flex flex-wrap'>
      <UncontrolledDropdown className='w-100 me-4'>
        <DropdownToggle disabled={is_menu_disabled} className={cn('p-2', 'w-100', s.org_match_dropdown_toggle)} tag='div'>
          <span className='d-flex'>
            <span className='w-100'>
              {show_ignored_as_best_match && <IgnoreDisplay />}
              {!show_ignored_as_best_match &&
                <OrganisationSimple org={best_match}/>
              }
            </span>
            <span className='d-flex flex-row'>
              <div className={cn('d-flex', 'align-items-center', s.chevron_icon)}>
              <ChevronDownIcon />
            </div>
            </span>
          </span>
        </DropdownToggle>

        <DropdownMenu className={s.org_match_dropdown} end >
          {all_match_options.map((result, i) => (
            <MenuOptionWrapper key={i} on_click={() => on_update_best_match(result)} is_selected={!is_ignore && best_match.id === result.id}>
              <OrganisationSimple
                org={result}
              />
            </MenuOptionWrapper>
          ))}

          {on_ignore &&
            <MenuOptionWrapper is_selected={is_ignore} on_click={on_ignore}>
              <IgnoreDisplay/>
            </MenuOptionWrapper>
          }
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  )
}

export default OrgSearchMultipleMatchesDropdown