import React from 'react'
import cn from 'classnames'

import { STATUS_ACTIVE_ID, STATUS_GRANTED_ID } from '../../../model/statuses.js'

import s from './StatusToggle.module.scss'


const StatusOption = ({id, label, is_on, on_select, in_chart}) => {
  return (
    <div
      onClick={() => on_select(id)}
      className={cn(
        'd-flex',
        s.btn,
        {[s.btn__header_inactive]: !is_on && !in_chart,
        [s.btn__active]: is_on,
        [s.btn__header_active]: is_on && !in_chart},
      )}
    >
      <span className='m-auto'>{label}</span>
    </div>
  )
}

const StatusToggle = ({status_filter, on_click, in_chart, className}) => {
  function on_select(id) {
    if (id !== status_filter) {
      on_click(id)
    }
  }

  return (
    <div className={cn('d-flex', className)}>
      <StatusOption
        id={STATUS_GRANTED_ID}
        label={'Granted'}
        is_on={STATUS_GRANTED_ID === status_filter}
        on_select={on_select}
        in_chart={in_chart}
      />
      <StatusOption
        id={STATUS_ACTIVE_ID}
        label={'Active'}
        is_on={STATUS_ACTIVE_ID === status_filter}
        on_select={on_select}
        in_chart={in_chart}
      />
    </div>
  )
}

export default StatusToggle