import React from 'react'
import { DropdownItem } from 'reactstrap'

import { AVAILABLE_SCORE_THRESHOLDS_BY_VALUE } from '../../model/technology_basket.js'
import { is_poweruser } from '../../utils/user_permissions.js'

import BaseDropdown from '../widgets/BaseDropdown.js'
import Label from '../widgets/Label.js'

const ScoreThresholdControl = ({
  score_threshold_objs,
  selected_score_threshold_value,
  on_change_score_threshold,
  user,
  className
}) => {
  const user_has_power_user_role = is_poweruser(user)
  const selected_threshold_obj = AVAILABLE_SCORE_THRESHOLDS_BY_VALUE[selected_score_threshold_value]

  return (
    <div className={className}>
      <Label className='mb-2'>Score threshold:</Label>
      <BaseDropdown
        label={selected_threshold_obj.label}
        right={false}
        menuClassName='w-auto'
      >
        {score_threshold_objs.map((score_threshold_obj, i) => {
          const { value, label, only_poweruser } = score_threshold_obj
          

          if (only_poweruser && !user_has_power_user_role) {
            return null
          }
          return (
            <DropdownItem
              key={i}
              onClick={() => on_change_score_threshold(value)}
              active={value === selected_threshold_obj.value}
            >
              {label}
            </DropdownItem>
          )
        })}
      </BaseDropdown>
    </div>
  )
}

export default ScoreThresholdControl