import React from 'react'
import { Link } from 'react-router-dom'

import {ID_TO_MODAL_REPORT_ACTION} from '../model/multi_report_actions.js'

import ConfirmModal from '../../ConfirmModal.js'
import TextLink from '../../widgets/TextLink.js'

import {is_failed_status} from '../../../utils/report_progress_utils.js'
import {track_report_viewer_event} from '../../../utils/tracking_utils.js'
import {to_local_date} from '../../../utils/utils.js'


export const ReportActionConfirmModal = ({modal_report_action_id, external_report_ids, report_to_display, on_confirm, on_cancel, tracking_context}) => {

  const action = ID_TO_MODAL_REPORT_ACTION[modal_report_action_id]

  function render_report_title(report) {
    const {external_report_id, status, title} = report

    if (is_failed_status(status)) {
      return (<span>{title}</span>)
    }

    return (
      <TextLink
        element={Link}
        to={`/report/${external_report_id}`}
        onClick={track_report_viewer_event(`obj="report" action="show" context="${tracking_context}"`)}
        target='_blank'
      >
        {title}
      </TextLink>
    )
  }

  return (
    <ConfirmModal
      title={action.modal_title(external_report_ids.length)}
      on_confirm={on_confirm}
      on_cancel={on_cancel}
      confirm_label={action.confirm_label}
    >
      { external_report_ids.length === 1 &&
        <div className='mb-3'>{to_local_date(report_to_display.created_at)} - {render_report_title(report_to_display)}</div>
      }
      { action.modal_text }
    </ConfirmModal>
  )
}