import * as peg from 'pegjs'
import { get_as_map } from '../utils/utils'

export const OP_DESC_MODE='operand_desc'
export const OP_ASC_MODE='operand_asc'
export const REPORT_TIMESTAMP_MODE='report_creation_date'
export const CPC_MODE='cpc'
export const ALL_MODE='all'

export const OP_REGEX = new RegExp('(R[0-9]+)', 'g')

export const PARSER = peg.generate(`
  Term = Primary (Ws Op Ws Primary)*
  Op = Union / Intersection / Sub / SymSub
  Union = "∪"
  Intersection = "∩"
  Sub = "-"
  SymSub = "△"
  Primary = Parens / Operand
  Parens = "(" Ws Term Ws ")"
  Operand = "R"[0-9]+
  Ws = [ \\t\\n\\r]*
`)

const OPERATOR_UNION = {symbol: '∪', name: 'Union', keyboard_key: 'u', keyboard_key_code: 'KeyU'}
const OPERATOR_INTERSECTION = {symbol: '∩', name: 'Intersection', keyboard_key: 'i', keyboard_key_code: 'KeyI'}
const OPERATOR_DIFFERENCE = {symbol: '-', name: 'Difference', keyboard_key: 'd', keyboard_key_code: 'KeyD'}
const OPERATOR_SYMMETRIC_DIFFERENCE = {symbol: '△', name: 'Symmetric Difference', keyboard_key: 's', keyboard_key_code: 'KeyS'}

export const OPERATORS = [
  OPERATOR_UNION,
  OPERATOR_INTERSECTION,
  OPERATOR_DIFFERENCE,
  OPERATOR_SYMMETRIC_DIFFERENCE
]

export const KEYBOARD_KEY_CODE_TO_OPERATOR = get_as_map(OPERATORS, 'keyboard_key_code')

export const PRESET_OPTIONS = [
  {
    name: 'CPC Merge',
    description: 'Perform set union over the number of operands, group patent families by dominant CPC. ' +
      'Portfolio grouping is done by report creation date in descending order.',
    fn_formula_generation: (op_nr) => [...Array(op_nr).keys()].map(idx => `R${idx+1}`).join(' ∪ '),
    group_portfolio_mode: REPORT_TIMESTAMP_MODE,
    group_tech_mode: CPC_MODE
  },
  {
    name: 'CPC Merge (No duplicate)',
    description: 'Perform set union over the number of operands, group patent families by dominant CPC and ignore portfolio grouping.',
    fn_formula_generation: (op_nr) => [...Array(op_nr).keys()].map(idx => `R${idx+1}`).join(' ∪ '),
    group_portfolio_mode: ALL_MODE,
    group_tech_mode: CPC_MODE
  },
  {
    name: 'Classifier merge',
    description: 'Technology grouping is done by operand ascending order (e.g. for R1, R2 the tech grouping of R1 is selected first and the remainder from R2). ' +
      'Portfolio grouping is done by report creation date in descending order.',
    fn_formula_generation: differentiated_union_formula,
    group_portfolio_mode: REPORT_TIMESTAMP_MODE,
    group_tech_mode: OP_ASC_MODE
  },
  {
    name: 'Classifier merge (No duplicate)',
    description: 'Technology grouping is done by operand ascending order (e.g. for R1, R2 the tech grouping of R1 is selected first and the remainder from R2). ' +
      'Portfolio grouping is ignored.',
    fn_formula_generation: differentiated_union_formula,
    group_portfolio_mode: ALL_MODE,
    group_tech_mode: OP_ASC_MODE
  }
]

export const BENCHMARKING_PRESET = {
  name: 'Benchmarking',
  description: 'Merge a company report and a landscape report.',
  fn_formula_generation: differentiated_union_formula,
  group_portfolio_mode: OP_ASC_MODE,
  group_tech_mode: OP_ASC_MODE
}

const COMMON_GROUP_DROPDOWN_ITEMS = [
  {
    name: 'Operand descending',
    mode: OP_DESC_MODE,
    info: 'Given reports R1, R2 both containing the same patent family, the mapping of R2 takes precedence.'
  },
  {
    name: 'Operand ascending',
    mode: OP_ASC_MODE,
    info: 'Given reports R1, R2 both containing the same patent family, the mapping of R1 takes precedence.'},
  {
    name: 'Report creation date',
    mode: REPORT_TIMESTAMP_MODE,
    info: 'Given reports R1, R2 both containing the same patent family, the mapping of the latest report takes precedence.'
  },
]

export const TECH_GROUP_DROPDOWN_ITEMS = [
  ...COMMON_GROUP_DROPDOWN_ITEMS,
  {
    name: 'Dominant CPC',
    mode: CPC_MODE,
    info: 'Group patent families by dominant CPC using Stochastic Gradient Descent classifiers.'
  }
]

export const PORTFOLIO_GROUP_DROPDOWN_ITEMS = [
  ...COMMON_GROUP_DROPDOWN_ITEMS,
  {
    name: 'All',
    mode: ALL_MODE,
    info: 'Ignore portfolio clustering and group all patent families together. This option is useful for avoiding duplications cause by co-ownerships.'
  }
]

export function differentiated_union_formula(op_nr) {
  const operand_arr = [...Array(op_nr).keys()].map(idx => `R${idx+1}`)
  let formula_operands= []
  operand_arr.forEach((op, idx) => {
    if (idx === 0){
      formula_operands.push(op)
    } else if (idx === 1) {
      formula_operands.push(`(${op} - R${idx})`)
    } else {
      formula_operands.push(`(${op} - (${[...Array(idx).keys()].map(idx => `R${idx+1}`).join(' ∪ ')}))`)
    }
  })
  return formula_operands.join(' ∪ ')
}
