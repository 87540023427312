import React, { useState } from 'react'
import cn from 'classnames'

import { InfoIcon } from '../../widgets/IconSet.js'
import TextLink from '../../widgets/TextLink.js'
import { ScrollModal } from '../../widgets/Modal.js'
import { CheckboxAndLabel } from '../../widgets/CheckboxAndLabel.js'
import { get_spec_name } from '../../../utils/spec_utils.js'
import { SPEC_ID_TO_SPEC_REF } from '../../../model/spec_groups.js'

import s from './ChartSetsDisplay.module.scss'

export const ChartSetsDisplay = ({sets=[], sets_to_highlight=[], sets_to_warn=[]}) => {
  const [show_pretty_json, set_show_pretty_json] = useState(false)

  return (
    <div>
      <CheckboxAndLabel
        label='Show as JSON'
        is_checked={show_pretty_json}
        on_click={() => {set_show_pretty_json(!show_pretty_json)}}
        className='mb-2'
      />

      {show_pretty_json &&
        <pre>
          {JSON.stringify(sets, undefined, 2)}
        </pre>
      }

      {!show_pretty_json &&
        <div>
          {sets.map((set, i) => {
            const { name, items } = set

            const is_highlighted = sets_to_highlight.indexOf(name) > -1
            const is_warning = sets_to_warn.indexOf(name) > -1

            return (
              <div key={i} className={cn('p-1', {[s.highlight_block]: is_highlighted}, {[s.warning_block]: is_warning})}>
                <b>
                  {name}
                </b>
                <div className='ps-3'>
                  {items.map((item, j) => {
                    return (
                      <div key={j}>
                        {get_spec_name({id: item.spec_id}, SPEC_ID_TO_SPEC_REF)}
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}

        </div>
      }

    </div>
  )
}

export const ChartSetsModal = (
  {
    sets,
    label,
    className
  }) => {

  const [is_open, set_is_open] = useState(false)

  return (
    <>
      <TextLink title='Show current chart sets' className={className} disable={(!sets) || (sets.length === 0)} onClick={() => {set_is_open(true)}} no_decoration={!label}>{label || <InfoIcon />}</TextLink>

      <ScrollModal is_open={is_open} on_hide={() => set_is_open(false)}>

        <ChartSetsDisplay
          sets={sets}
        />

      </ScrollModal>
    </>
  )
}
