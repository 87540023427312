import React from 'react'

import Modal from '../widgets/Modal.js'
import { PrimaryButton } from '../widgets/Button.js'
import { get_all_assignees } from '../../utils/organisation_utils.js'

const MoveAssigneesToParentModal = ({org, on_hide, on_confirm}) => {
  const {name} = org
  const assignees = get_all_assignees(org)

  const primary_button = (
    <PrimaryButton onClick={on_confirm}>Confirm</PrimaryButton>
  )

  return (
    <Modal is_open={true} on_hide={on_hide} primary_button={primary_button} title={name}>
      Are you sure you want to move all {name} assignees to its parent?
      <div className='mt-2'>
        <div>List of assignees to move:</div>
        {assignees.map((item, i) => {
          const {name} = item
          return (
            <div key={i}>{name}</div>
          )
        })}
      </div>

    </Modal>
  )
}

export default MoveAssigneesToParentModal