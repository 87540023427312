import React from 'react'
import { DropdownItem } from 'reactstrap'

import { COMPARABLES_MODES, COMPARABLES_MODES_BY_ID } from '../../model/organisation.js'
import BaseDropdown from '../widgets/BaseDropdown.js'

import s from './OrgSuggestionsSwitch.module.scss'

export const OrgSuggestionsSwitch = ({selected_mode, set_org_suggestions_mode_handler, disabled}) => {
  return (
    <BaseDropdown
      buttonClassName={s.switch}
      label={COMPARABLES_MODES_BY_ID[selected_mode].label}
      disabled={disabled}
    >
      {COMPARABLES_MODES.map((mode,i) => (
        <DropdownItem key={i} onClick={() => set_org_suggestions_mode_handler(mode.id)} active={mode.id === selected_mode}>
          {mode.label}
        </DropdownItem>
      ))}
    </BaseDropdown>
  )
}