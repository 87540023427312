import React, { useEffect, useState } from 'react'

import { fetch_user_permissions_summary } from '../../utils/user_management_utils.js'
import ErrorBody from '../ErrorBody.js'
import Spinner from '../widgets/Spinner.js'
import AdminPageWrapper from './AdminPageWrapper.js'
import { Table } from '../widgets/Table.js'
import ContainerFixedWidth from '../ContainerFixedWidth.js'

const RoleOverview = () => {
  const [permissions_overview, set_permissions_overview] = useState(null)
  const [is_fetching, set_is_fetching]          = useState(true)
  const [error_fetching, set_error_fetching]             = useState(null)

  useEffect(() => {
    fetch_user_permissions_summary()
    .catch(err => {
      // FAIL
      set_error_fetching(err)
      throw err
    })
    .then(permissions_overview => {
      // SUCCESS
      set_permissions_overview(permissions_overview)
    })
    .finally(() => {
      set_is_fetching(false)
    })
  }, [])

  return (
    <AdminPageWrapper page_name='Role overview'>

      <ContainerFixedWidth>
        {is_fetching &&
          <Spinner />
        }

        {error_fetching &&
          <ErrorBody error={error_fetching} />
        }

        {permissions_overview &&
          <Table
            columns={[
              {id: 'role', field: 'role', label: 'Role', sortable: true},
              {id: 'count', field: 'user_count', label: 'Users in role', sortable: true, width: 150, cell_render: (row) => (<div className='w-100 d-flex justify-content-end'>{row.value}</div>)},
            ]}
            data={permissions_overview}
          />
        }
      </ContainerFixedWidth>
    </AdminPageWrapper>
  )
}

export default RoleOverview