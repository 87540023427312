import React, { useEffect, useReducer, useRef, useState } from 'react'
import cn from 'classnames'
import { withRouter } from 'react-router'
import _ from 'underscore'
import {BENCHMARKING} from '../../../constants/paths'

import ContainerFullWidth from '../../ContainerFullWidth.js'
import Header from './Header.js'
import Footer from './Footer.js'
import {
  set_all_is_fetching,
  set_all_to_fetch,
  set_error,
  set_to_fetch
} from '../../../model/report_reader_fetch_state.js'
import { create_new_user_state } from '../../../model/user_state.js'
import DeckViewer from './DeckViewer.js'
import {
  DECK_PAGE_SUBPATH,
  LANDING_PAGE_ID,
  EXPORT_MODE_ALL_PAGES,
  EXPORT_MODE_CURRENT_PAGE,
} from '../../../constants/report_deck.js'
import {
  fetch_chart_data,
  fetch_report_deck_config,
  get_deck_deref_data,
  get_deck_selections,
  update_report_deck_config,
  set_data,
  get_deck_spec,
  get_relevant_selections_from_root_chart,
  get_org_lists_from_data_summary,
  get_tech_areas_from_data_summary
} from '../../../utils/report_deck_utils.js'
import { ID_TO_DECK_SPEC } from '../../../model/deck_specs.js'
import { SUBSET_SUBPATH } from '../../../constants/viewer_paths.js'
import ErrorModal from '../../ErrorModal.js'
import ReportInitialFetchDisplay from '../ReportInitialFetchDisplay.js'
import DeckKeyDataDisplay from './DeckKeyDataDisplay.js'
import DeckExport from './DeckExport.js'
import { track_deck_viewer_event } from '../../../utils/tracking_utils.js'
import { fetch_report_data_summary } from '../../../utils/report_reader_utils.js'
import { REGION_COLUMN_NAME } from '../../../constants/spec_column_names.js'
import DefaultPageContainer from '../../DefaultPageContainer.js'

import s from './BespokeReportViewer.module.scss'

function fetch_data_reducer(state, data) {
  return {...state, ...data}
}

const BespokeReportViewer = (
  {
    match, history,

    report_deck, deck_type,

    is_initial_fetch,
    fetch_initial_error,
    initial_data,
    choreo_status,
    rename_report
  }
) => {
  const viewer_ref = useRef(false)
  const { external_report_id, report_metadata } = initial_data || {}
  const { internal_report_id, title: report_title, evaluation_classifier_id, created_by_user, created_at } = report_metadata || {}

  const [current_page, set_current_page] = useState(null)
  const [is_menu_open, set_is_menu_open] = useState(false)
  const [is_edit_mode, set_is_edit_mode] = useState(false)

  const [is_fetching_key_data, set_is_fetching_key_data] = useState(true)
  const [deck_config, set_deck_config] = useState(null)
  const [deck_key_data, set_deck_key_data] = useState(null)

  const [spec_id_to_fetch_obj, fetch_data_dispatcher] = useReducer(fetch_data_reducer, {})
  const [is_fetch_new_datasets, set_is_fetch_new_datasets] = useState(false)

  const [show_deck_key_data, set_show_deck_key_data] = useState(null)
  const [selected_export, set_selected_export] = useState(null)
  const [pages_to_export, set_pages_to_export] = useState(null)

  const [fetch_deck_config_error, set_fetch_deck_config_error] = useState(null)
  const [save_deck_config_error, set_save_deck_config_error] = useState(null)

  useEffect(() => {
    viewer_ref.current = true
    return () => {
      viewer_ref.current = false
    }
  }, [])

  useEffect(() => {
    const path_elements = history.location.pathname.split('/').filter(item => item && item !== '')
    // This returns -1 if not present so if the value is 1 ignore
    const page_id_idx = _.findIndex(path_elements, p => `/${p}` === BENCHMARKING) + 2
    const page_id = (path_elements && page_id_idx > 1 && path_elements[page_id_idx] && path_elements[page_id_idx] === DECK_PAGE_SUBPATH) ? path_elements[page_id_idx + 1] : null
    const is_subset = _.contains(path_elements, SUBSET_SUBPATH)

    const new_page_is_clickthrough_page = (report_deck.get_page_by_id(page_id) || {}).root_page_id
    if (clickthrough_item && !is_subset && !new_page_is_clickthrough_page) {
      update_deck_config('clickthrough_item', null)
    }

    set_current_page(page_id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname])

  useEffect(() => {
    if (!external_report_id || !internal_report_id) return

    Promise.all([
      fetch_report_data_summary(internal_report_id),
      fetch_report_deck_config(external_report_id)
    ])
      .then(([ data_summary, deck_config]) => {
        if (viewer_ref.current) {

          set_deck_config( Object.keys(deck_config).length > 0 ? deck_config : create_new_user_state())

          const {portfolios, schema_version} = data_summary
          const {org_lists, portfolio_to_company_list} = get_org_lists_from_data_summary(data_summary)
          const tech_areas = get_tech_areas_from_data_summary(data_summary)

          set_deck_key_data({org_lists, tech_areas, portfolio_to_company_list, all_portfolios: portfolios, schema_version})
          set_is_fetching_key_data(false)
        }
      })
      .catch(err => {
        set_deck_config(create_new_user_state())
        set_fetch_deck_config_error(err)
        set_is_fetching_key_data(false)
      })
  }, [internal_report_id, external_report_id])

  useEffect(() => {

    if ((is_initial_fetch || is_fetching_key_data) || !report_deck || !current_page || current_page === LANDING_PAGE_ID) return

    const {charts=[]} = report_deck.get_page_by_id(current_page)

    const spec_ids = charts.map(item => item.spec_id)
    const spec_ids_to_fetch = spec_ids.filter(id => !spec_id_to_fetch_obj[id])
    const new_spec_id_to_fetch_obj =  set_all_to_fetch({}, _.uniq(spec_ids_to_fetch))
    fetch_data_dispatcher(new_spec_id_to_fetch_obj)
    set_is_fetch_new_datasets(true)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_page, is_initial_fetch, is_fetching_key_data])

  useEffect(() => {

    if (is_fetch_new_datasets) {

      const spec_ids = Object.keys(spec_id_to_fetch_obj).filter(id => spec_id_to_fetch_obj[id].to_fetch)

      const new_spec_id_to_fetch_obj = set_all_is_fetching({}, spec_ids)
      fetch_data_dispatcher(new_spec_id_to_fetch_obj)

      const { chart_selections={}, clickthrough_item } = deck_config || {}
      const { org_lists=[] } = deck_key_data

      spec_ids.forEach(spec_id => {
        const deck_spec = get_deck_spec(spec_id)
        const {root_chart_spec_id} = deck_spec
        const root_chart_selections = get_relevant_selections_from_root_chart(chart_selections[root_chart_spec_id])

        const chart_selection = {
          ...root_chart_selections,
          ...chart_selections[spec_id]
        }

        const chart = {...deck_spec, ...chart_selection}

        fetch_chart_data(internal_report_id, chart, selections, created_at, org_lists, clickthrough_item)
          .then(([data, families_total_count]) => {
            // SUCCESS
            if (viewer_ref.current) {
              const new_spec_id_to_fetch_obj = set_data({}, spec_id, data, families_total_count)
              fetch_data_dispatcher(new_spec_id_to_fetch_obj)
            }
          })
          .catch(err => {
            // FAIL
            if (viewer_ref.current) {
              const new_spec_id_to_fetch_obj = set_error({}, spec_id, err)
              fetch_data_dispatcher(new_spec_id_to_fetch_obj)
            }
          })

      })

      set_is_fetch_new_datasets(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_fetch_new_datasets])

  useEffect(() => {
    if (!selected_export || (selected_export !== EXPORT_MODE_ALL_PAGES)) return

    const pages = []

    const { get_page_section } = report_deck

    report_deck.page_ids.forEach(page_id => {
      if (page_id === LANDING_PAGE_ID) return
      const page = report_deck.get_page_by_id(page_id)

      const section = get_page_section ? get_page_section(page_id) : null
      pages.push({...page, section})
    })

    set_selected_export(null)
    set_pages_to_export(pages)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_export])

  function toggle_menu() {
    set_is_menu_open(!is_menu_open)
  }

  function toggle_edit_mode() {
    track_deck_viewer_event(`obj="report" action="edit_mode_${is_edit_mode?'off':'on'}" deck_type="${deck_type}"`)
    set_is_edit_mode(!is_edit_mode)
  }

  function on_current_page_change(page_id) {
    history.push(page_id ? `${match.url}/${DECK_PAGE_SUBPATH}/${page_id}` : match.url)
  }

  function fetch_filtered_charts(filter_handler) {
    const spec_ids = Object.keys(spec_id_to_fetch_obj || {})
    const spec_ids_to_fetch = spec_ids.filter(id => filter_handler(id))
    if (spec_ids_to_fetch.length > 0) {
      const new_spec_id_to_fetch_obj = set_all_to_fetch(spec_id_to_fetch_obj, spec_ids_to_fetch)
      fetch_data_dispatcher(new_spec_id_to_fetch_obj)
      set_is_fetch_new_datasets(true)
    }
  }

  function on_deck_status_filter_change(status_filter) {
    track_deck_viewer_event(`obj="report" action="change_status_filter" status_filter="${status_filter}" deck_type="${deck_type}"`)

    const updated_chart_selections = {}
    // on deck-wide status switch, update all specs that use a status filter
    Object.keys(ID_TO_DECK_SPEC).forEach(spec_id => {
      updated_chart_selections[spec_id] = {
        ...(chart_selections[spec_id] || {}),
        ...(ID_TO_DECK_SPEC[spec_id].can_apply_status_switch ? {status_filter} : {})
      }
    })

    update_deck_config_properties({status_filter, chart_selections: updated_chart_selections})

    const filter_handler = (spec_id) => {
      const {can_apply_status_switch} = ID_TO_DECK_SPEC[spec_id] || {}
      return can_apply_status_switch
    }
    fetch_filtered_charts(filter_handler)
  }

  function on_deck_region_grouping_change(region_grouping) {
    track_deck_viewer_event(`obj="report" action="select_region_grouping" grouping="${region_grouping}" deck_type="${deck_type}"`)
    update_deck_config('region_grouping', region_grouping)
    const filter_handler = (spec_id) => {
      const {column_names=[]} = ID_TO_DECK_SPEC[spec_id] || {}
      return column_names.indexOf(REGION_COLUMN_NAME) > -1
    }
    fetch_filtered_charts(filter_handler)
  }

  function on_deck_selected_org_lists_change(selected_org_lists) {
    track_deck_viewer_event(`obj="report" action="change_selected_org_lists" deck_type="${deck_type}"`)
    const updated_config = {...deck_config, selected_org_lists}

    if (clickthrough_item && clickthrough_item.items) {
      const clickthrough_org_id = (clickthrough_item.items[0] || {}).id
      const {portfolio_to_company_list} = deck_key_data
      if (!_.contains(selected_org_lists, (portfolio_to_company_list[clickthrough_org_id] || {}).id)) {
        delete updated_config.clickthrough_item
      }
    }

    set_and_save_updated_config(updated_config)
    const filter_handler = (spec_id) => {
      const {list_orgs_only} = ID_TO_DECK_SPEC[spec_id] || {}
      return list_orgs_only
    }
    fetch_filtered_charts(filter_handler)
  }

  function on_deck_selected_geo_filters_change(selected_geo_filters) {
    track_deck_viewer_event(`obj="report" action="change_selected_geo_filters" deck_type="${deck_type}"`)
    update_deck_config('selected_geo_filters', selected_geo_filters)
    fetch_all_charts()
  }

  function on_deck_selected_tech_areas_change(selected_tech_areas) {
    track_deck_viewer_event(`obj="report" action="change_selected_tech_areas" deck_type="${deck_type}"`)
    const spotlighted_tech_areas = selections.spotlighted_tech_areas.filter(id => _.contains(selected_tech_areas, id))
    update_deck_config_properties({...deck_config, selected_tech_areas, spotlighted_tech_areas})
    fetch_all_charts()
  }

  function on_deck_spotlighted_tech_areas_change(spotlighted_tech_areas) {
    track_deck_viewer_event(`obj="report" action="change_spotlighted_tech_areas" deck_type="${deck_type}"`)
    update_deck_config('spotlighted_tech_areas', spotlighted_tech_areas)
    const filter_handler = (spec_id) => {
      const {spotlighted_tech_areas_only, refresh_on_spotlight_techs} = ID_TO_DECK_SPEC[spec_id] || {}
      return spotlighted_tech_areas_only || refresh_on_spotlight_techs
    }
    fetch_filtered_charts(filter_handler)
  }

  function on_deck_spotlighted_orgs_change(spotlighted_orgs) {
    track_deck_viewer_event(`obj="report" action="change_spotlighted_orgs" deck_type="${deck_type}"`)
    update_deck_config('spotlighted_orgs', spotlighted_orgs)
  }

  function on_chart_description_change(spec_id, description) {
    track_deck_viewer_event(`obj="dataset" dataset="${spec_id}" action="change_description" deck_type="${deck_type}"`)
    update_chart_selection(spec_id, 'description', description)
  }

  function on_chart_status_filter_change(spec_id, status_filter) {
    track_deck_viewer_event(`obj="dataset" dataset="${spec_id}" action="change_status_filter" status_filter="${status_filter}" deck_type="${deck_type}"`)
    update_chart_selection(spec_id, 'status_filter', status_filter)
    const new_spec_id_to_fetch_obj = set_to_fetch(spec_id_to_fetch_obj, spec_id)
    fetch_data_dispatcher(new_spec_id_to_fetch_obj)
    set_is_fetch_new_datasets(true)
  }

  function on_chart_timerange_change(spec_id, timerange) {
    track_deck_viewer_event(`obj="dataset" dataset="${spec_id}" action="change_timerange" timerange_value="${timerange}" deck_type="${deck_type}"`, true)
    update_chart_selection(spec_id, 'selected_timerange', timerange)
  }

  function on_clickthrough_item_change(spec_id, clickthrough_item) {
    update_deck_config('clickthrough_item', clickthrough_item)
    track_deck_viewer_event(`obj="dataset" dataset="${spec_id}" action="change_clickthrough_item" deck_type="${deck_type}"`)

    const filter_handler = (spec_id) => {
      const {can_apply_clickthrough_item_switch} = ID_TO_DECK_SPEC[spec_id] || {}
      return can_apply_clickthrough_item_switch
    }
    fetch_filtered_charts(filter_handler)
  }

  function update_chart_selection(spec_id, field, value) {
    const {chart_selections={}} = deck_config
    const spec_custom_config = chart_selections[spec_id] || {}
    update_deck_config('chart_selections', {...chart_selections, [spec_id]: {...spec_custom_config, [field]: value}})
  }

  function reset_deck_config_to_default() {
    track_deck_viewer_event(`obj="report" action="hard_reset" deck_type="${deck_type}"`)
    set_and_save_updated_config({})
    fetch_all_charts()
  }

  function update_deck_config(field, value) {
    set_and_save_updated_config({...deck_config, [field]: value})
  }

  function update_deck_config_properties(updated_properties) {
    set_and_save_updated_config({...deck_config, ...updated_properties})
  }

  function set_and_save_updated_config(updated_config) {
    set_deck_config(updated_config)
    save_deck_config_to_db(updated_config)
  }

  function save_deck_config_to_db(deck_config) {
    update_report_deck_config(external_report_id, deck_config)
      .catch(err => {
        set_save_deck_config_error(err)
      })
  }

  function fetch_all_charts() {
    const new_spec_id_to_fetch_obj = set_all_to_fetch({}, Object.keys(spec_id_to_fetch_obj))
    fetch_data_dispatcher(new_spec_id_to_fetch_obj)
    set_is_fetch_new_datasets(true)
  }

  function on_deck_export(export_mode) {
    if ( (export_mode === EXPORT_MODE_CURRENT_PAGE) && (current_page !== LANDING_PAGE_ID) ) {
      const {get_page_section} = report_deck
      const page_to_export = report_deck.get_page_by_id(current_page)
      const section = (get_page_section) ? get_page_section(current_page) : null
      set_pages_to_export([{...page_to_export, section}])
    } else {
      const spec_ids = []
      report_deck.page_ids.forEach(page_id => {
        if (page_id === LANDING_PAGE_ID) return
        const page = report_deck.get_page_by_id(page_id)
        const {charts} = page
        charts.forEach(chart => {
          spec_ids.push(chart.spec_id)
        })
      })

      const spec_ids_to_fetch = spec_ids.filter(id => !spec_id_to_fetch_obj[id])
      const new_spec_id_to_fetch_obj =  set_all_to_fetch({}, _.uniq(spec_ids_to_fetch))
      fetch_data_dispatcher(new_spec_id_to_fetch_obj)
      set_is_fetch_new_datasets(true)
      set_selected_export(export_mode)
    }
  }

  function on_export_exit() {
    set_selected_export(null)
    set_pages_to_export(null)
  }

  const is_fetching_deck = is_initial_fetch || is_fetching_key_data

  const { chart_selections={}, clickthrough_item } = deck_config || {}

  const { org_lists=[], tech_areas=[] } = deck_key_data || {}

  const selections = is_fetching_deck ? {} : get_deck_selections(deck_key_data, deck_config)
  const deref_data = is_fetching_deck ? {} : get_deck_deref_data(deck_key_data, selections)

  const is_clickthrough = history.location.pathname.indexOf(`/${SUBSET_SUBPATH}/`) !== -1

  return (
    <DefaultPageContainer className={s.block}>
      <Header
        disable={is_fetching_deck || fetch_initial_error}
        deck_type={deck_type}
        external_report_id={external_report_id}
        report_title={report_title}
        created_at={created_at}

        is_menu_open={is_menu_open}
        toggle_menu={toggle_menu}
        is_edit_mode={is_edit_mode}
        toggle_edit_mode={toggle_edit_mode}
        report_deck={report_deck}
        current_page={current_page}
        set_current_page={on_current_page_change}

        deck_config={deck_config}

        org_lists={org_lists}
        tech_areas={tech_areas}
        selections={selections}
        on_deck_status_filter_change={on_deck_status_filter_change}
        on_deck_selected_org_lists_change={on_deck_selected_org_lists_change}
        on_deck_selected_tech_areas_change={on_deck_selected_tech_areas_change}
        on_deck_spotlighted_tech_areas_change={on_deck_spotlighted_tech_areas_change}
        on_deck_selected_geo_filters_change={on_deck_selected_geo_filters_change}
        on_deck_reset_to_default={reset_deck_config_to_default}
        on_deck_export={on_deck_export}
      />

      <ContainerFullWidth className={cn([{'d-md-flex': !is_clickthrough}, s.content])}>

        {(is_fetching_deck || fetch_initial_error) &&
          <ReportInitialFetchDisplay
            show_banner_with_spinner={!fetch_initial_error && ((is_initial_fetch && !choreo_status) || is_fetching_key_data)}
            show_choreo_progress={is_initial_fetch && choreo_status}

            created_by_user={created_by_user}
            is_eval_report={!!evaluation_classifier_id}
            internal_report_id={internal_report_id}
            external_report_id={external_report_id}
            choreo_status={choreo_status}
            report_title={report_title}
            fetch_initial_error={fetch_initial_error}
          />
        }

        {!is_fetching_deck && !fetch_initial_error &&
          <DeckViewer
            report_deck={report_deck}
            deck_type={deck_type}
            is_edit_mode={is_edit_mode}

            selections={selections}
            minimal_selections={selections}
            data_creation_date={created_at}
            ref_data={deck_key_data}
            deref_data={deref_data}
            chart_selections={chart_selections}
            internal_report_id={internal_report_id}
            report_title={report_title}
            clickthrough_item={clickthrough_item}

            spec_id_to_fetch_obj={spec_id_to_fetch_obj}
            set_current_page={on_current_page_change}
            on_chart_description_change={on_chart_description_change}
            on_chart_status_filter_change={on_chart_status_filter_change}
            on_chart_timerange_change={on_chart_timerange_change}
            on_clickthrough_item_change={on_clickthrough_item_change}
            on_deck_spotlighted_tech_areas_change={on_deck_spotlighted_tech_areas_change}
            on_deck_spotlighted_orgs_change={on_deck_spotlighted_orgs_change}
          />
        }
      </ContainerFullWidth>

      <Footer
        deck_type={deck_type}
        selections={selections}
        report_title={report_title}
        created_by_user={created_by_user}
        report_deck={report_deck}
        current_page={is_clickthrough ? null : (current_page || LANDING_PAGE_ID)}
        set_current_page={on_current_page_change}
        on_region_grouping_change={on_deck_region_grouping_change}
        on_deck_reset_to_default={reset_deck_config_to_default}
        on_report_name_change={rename_report}
        on_show_deck_key_data={set_show_deck_key_data}
        disable={is_fetching_deck || fetch_initial_error}
      />

      <DeckKeyDataDisplay
        is_open={show_deck_key_data != null}
        param={show_deck_key_data}
        deck_key_data={deck_key_data || {}}
        internal_report_id={internal_report_id}
        deck_type={deck_type}
        on_close={() => set_show_deck_key_data(null)}
      />

      {fetch_deck_config_error &&
        <ErrorModal
          error={fetch_deck_config_error}
          on_hide={() => set_fetch_deck_config_error(null)}
          context='fetching report config'
        />
      }

      {save_deck_config_error &&
        <ErrorModal
          error={save_deck_config_error}
          on_hide={() => set_save_deck_config_error(null)}
          context='saving report config'
        />
      }

      {pages_to_export &&
        <DeckExport
          pages_to_export={pages_to_export}

          spec_id_to_fetch_obj={spec_id_to_fetch_obj}

          ref_data={deck_key_data}
          deref_data={deref_data}
          data_creation_date={created_at}
          selections={selections}
          chart_selections={chart_selections}
          report_title={report_title}
          clickthrough_item={clickthrough_item}

          on_close={on_export_exit}
        />
      }

    </DefaultPageContainer>
  )
}

export default withRouter(BespokeReportViewer)