import React from 'react'
import cn from 'classnames'

import ChildRoleRows from './ChildRoleRows.js'
import { TrashIcon } from '../widgets/IconSet.js'
import TextLink from '../widgets/TextLink.js'

import s from './RoleRow.module.scss'

const RoleRow = ({
  role,
  on_remove,
  id_to_child_roles
}) => {
  return (
    <div className={cn(s.container)}>
      <div className='d-flex p-1'>
        <TextLink
          className='me-1'
          onClick={() => on_remove(role)}
          no_decoration
        >
          <TrashIcon />
        </TextLink>
        <span>{role.name}</span>
      </div>
      <ChildRoleRows
        role={role}
        id_to_child_roles={id_to_child_roles}
      />
    </div>
  )
}

export default RoleRow