import React, {useEffect, useState, useRef, useCallback} from 'react'
import _ from 'underscore'
import cn from 'classnames'

import FamilyImage from './FamilyImage.js'
import ImageSelector from './ImagesSelector.js'
import Modal from '../widgets/Modal.js'
import Spinner from '../widgets/Spinner.js'
import { ImageBrowseControls, ImageExportControls, ImageTransformControls } from './ImageControls.js'

import {
  DOWN_KEY,
  LEFT_KEY,
  RIGHT_KEY,
  UP_KEY
} from '../../constants/keys.js'
import { IMAGE, PATENT } from '../../constants/paths.js'
import { convert_ifi_image_pkey_to_ucid } from '../../utils/patent_utils.js'
import { is_IE11 } from '../../utils/browser_utils.js'
import { MODAL_CONTENT_PADDING, MODAL_HEADER_HEIGHT } from '../../constants/layout.js'

import s from './ImagesModal.module.scss'

const SELECTOR_BLOCK_HEIGHT = 136 //this corresponds with a setting in module css
const CONTROLS_HEIGHT = 52

const ImagesModal = ({images, images_meta, selected_image_idx, on_image_click_handler, on_hide_handler, on_image_transform_handler, allow_public_access}) => {
  const wrapper_ref = useRef()
  const image_wrapper_ref = useRef()

  const [selected_image_loading, set_selected_image_loading] = useState(true)

  const [wrapper_width, set_wrapper_width] = useState(null)
  const [wrapper_height, set_wrapper_height] = useState(null)
  const [img_width, set_img_width] = useState(0)
  const [img_height, set_img_height] = useState(0)

  const [is_landscape, set_is_landscape] = useState(is_IE11() || window.screen.height < window.screen.width)

  const on_keyup = useCallback((event) => {
    const { keyCode } = event

    if (_.contains([LEFT_KEY, UP_KEY], keyCode)) {
      if (selected_image_idx === 0) {
        return
      }
      event.preventDefault()
      on_image_click_handler(selected_image_idx - 1)
      return
    }

    if (_.contains([RIGHT_KEY, DOWN_KEY], keyCode)) {
      if (selected_image_idx >= (images.length - 1)) {
        return
      }

      event.preventDefault()
      on_image_click_handler(selected_image_idx + 1)
      return
    }
  }, [images, selected_image_idx, on_image_click_handler])

  useEffect(() => {
    document.addEventListener('keyup', on_keyup)
    return () => {
      document.removeEventListener('keyup', on_keyup)
    }
  }, [on_keyup])

  useEffect(() => {
    const update_wrapper_dimensions = () => {
      const modal = wrapper_ref.current

      if (!modal) {
        return
      }

      const container = modal.querySelector('.modal-content')

      const current_height = container.offsetHeight - (CONTROLS_HEIGHT + MODAL_HEADER_HEIGHT + (2 * MODAL_CONTENT_PADDING)) //deduction made to fit modal heading and controls bar
      const current_width = container.offsetWidth - (2 * MODAL_CONTENT_PADDING)
      set_wrapper_width(current_width)
      set_wrapper_height(current_height)
    }

    const update_wrapper_dimensions_debounced = _.debounce(update_wrapper_dimensions, 150)

    update_wrapper_dimensions()

    window.addEventListener('resize', update_wrapper_dimensions_debounced)

    return () => {
      document.removeEventListener('resize', update_wrapper_dimensions_debounced)
    }
  })

  useEffect(() => {
    const toggle_is_landscape = () => {
      set_is_landscape(window.screen.height < window.screen.width)
    }

    window.addEventListener( 'orientationchange', toggle_is_landscape)
    return () => {
      window.removeEventListener( 'orientationchange', toggle_is_landscape)
    }
  }, [is_landscape])


  function on_selected_image_load_or_error(img, err) {
    set_selected_image_loading(false)
    if (err) {
      return
    }

    set_img_width(img.naturalWidth)
    set_img_height(img.naturalHeight)
  }

  function on_image_select(i) {
    if (i === selected_image_idx) return
    set_selected_image_loading(true)
    on_image_click_handler(i)
  }

  function on_zoom_in() {
    image_zoom(.5)
  }

  function on_zoom_out() {
    image_zoom(-.5)
  }

  function image_zoom(ratio) {
    const image_meta = images_meta[selected_image_idx] || {}
    const { magnify = 1 } = image_meta
    const updated_meta = {...image_meta, magnify: magnify + ratio}

    on_image_transform_handler(updated_meta)
  }

  function on_flip_right() {
    image_rotate(90)
  }

  function on_flip_left() {
    image_rotate(-90)
  }

  function on_reset() {
    on_image_transform_handler({ rotate: 0, magnify: 1 })
  }

  function image_rotate(angle) {
    const image_meta = images_meta[selected_image_idx] || {}
    const { rotate = 0 } = image_meta

    let new_angle = rotate + angle

    if (new_angle === 360) {
      new_angle = 0
    }

    if (new_angle === -90) {
      new_angle = 270
    }

    const updated_meta = {...image_meta, rotate: new_angle, magnify: 1}

    on_image_transform_handler(updated_meta)
  }

  function get_transformation() {
    const image_wrapper_height = image_wrapper_ref  ? (is_landscape ? wrapper_height : wrapper_height - SELECTOR_BLOCK_HEIGHT): null
    const image_wrapper_width = image_wrapper_ref ? (is_landscape ? wrapper_width - SELECTOR_BLOCK_HEIGHT : wrapper_width) : null
    const selected_image_meta = images_meta[selected_image_idx]

    const { magnify=1, rotate=0 } = selected_image_meta || {}
    const height_ratio = img_height !== 0 ? image_wrapper_height/img_height : 0
    const width_ratio = img_width !== 0 ? image_wrapper_width/img_width : 0
    const ratio = Math.min(height_ratio, width_ratio)

    const calculated_height = img_height * ratio * magnify
    const calculated_width = img_width * ratio * magnify
    let calculated_left = null
    let calculated_top = null

    if (rotate === 90 || rotate === 270) {
      calculated_left = (calculated_height > image_wrapper_width) ? (calculated_height - calculated_width) / 2 : null
      calculated_top = (calculated_width > image_wrapper_height) ? (calculated_width - calculated_height) / 2 : null
    }

    return { rotation: rotate, height: calculated_height, width: calculated_width, left: calculated_left, top: calculated_top }
  }

  const selected_image = images[selected_image_idx]
  const ucid = convert_ifi_image_pkey_to_ucid(selected_image.pkey)
  const selected_image_meta = images_meta[selected_image_idx] || {}

  const image_wrapper_height = image_wrapper_ref.clientHeight
  const { height, width, rotation } = get_transformation()

  return (
    <Modal className={s.images_modal} contentClassName='h-100' bodyClassName={s.images_modal__body} on_hide={on_hide_handler} inner_ref={wrapper_ref} no_footer={true}>
      <div className={cn('w-100', s.image_controls_block)}>
        <div className='d-flex flex-wrap flex-md-nowrap justify-content-between px-3 py-2'>
          <ImageExportControls
            className={cn(s.image_controls, 'order-1')}
            selected_image_url={`${PATENT}/${IMAGE}/${encodeURIComponent(ucid)}/${encodeURIComponent(selected_image.filename)}`}
            show_link={allow_public_access ? false : true}
          />

          <ImageBrowseControls
            className={cn([
              'w-25',
              {'order-2': is_landscape},
              {'order-3 w-100': !is_landscape},
              'order-md-2'
            ])}
            current_idx={selected_image_idx}
            max_idx={images.length}
            on_change_handler={on_image_click_handler}
          />

          <ImageTransformControls
            className={cn([
              s.image_controls,
              {'order-3': is_landscape},
              {'order-2 w-25': !is_landscape},
              'order-md-3'
            ])}
            on_flip_left={on_flip_left}
            on_flip_right={on_flip_right}
            on_zoom_out={on_zoom_out}
            on_zoom_in={on_zoom_in}
            on_reset={on_reset}
            image_meta={selected_image_meta}
          />

        </div>
      </div>

      <div className={cn([{'d-flex': is_landscape}])}>

        <div
          className={cn([
            {[s.selected_image_block__landscape]: is_landscape},
            {'order-1': is_landscape},
            {[s.selected_image_block__portrait]: !is_landscape},
            {'order-0': !is_landscape},
          ])}
          ref={image_wrapper_ref}

          {... (wrapper_height) ? {style: {'height': (is_landscape ? wrapper_height : wrapper_height - SELECTOR_BLOCK_HEIGHT) + 'px'}} : {}}

        >
          <div className={cn(s.selected_image_wrapper)}>
            {selected_image_loading &&
              <Spinner />
            }

            <div className={cn(
              s.image_wrapper,
              {[s.image__rotate_90]:  (rotation === 90)  },
              {[s.image__rotate_180]: (rotation === 180) },
              {[s.image__rotate_270]: (rotation === 270) }
            )}

              style={{
                width: width+'px',
                height: height+'px',
                margin: 'auto',
                position: 'relative'
              }}

            >
              <FamilyImage
                image={selected_image}
                image_meta={selected_image_meta}
                image_wrapper_height={image_wrapper_height}
                on_image_load_or_error={on_selected_image_load_or_error}
              />
            </div>
          </div>
        </div>

        <div
          className={cn([
            {[s.image_selector_block__landscape]: is_landscape},
            {'order-0': is_landscape},
            {[s.image_selector_block__portrait]: !is_landscape},
            {'order-1': !is_landscape},
          ])}

          {... (is_landscape && wrapper_height) ? {style: {'height': wrapper_height + 'px'}} : {}}
        >
          <ImageSelector
            images={images}
            selected_image_idx={selected_image_idx}
            on_click_handler={on_image_select}
            is_vertical={is_landscape}
          />
        </div>

      </div>

    </Modal>
  )
}

export default ImagesModal