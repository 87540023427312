import React from 'react'
import cn from 'classnames'

const ChildRoleRows = ({
  role,
  id_to_child_roles,
  className
}) => {

  const child_roles = id_to_child_roles[role.id]

  if (!child_roles || !child_roles.length) {
    return null
  }

  return (
    <div className={cn('ms-4 ps-1 font-italic', className)}>
      {child_roles.map((child_role, idx) => {
        return (
          <div
            key={idx}
          >
            {'-'} {child_role.name}
          </div>
        )
      })}
    </div>    
  )
}

export default ChildRoleRows