import React from 'react'
import cn from 'classnames'

import PatentFamilyFieldsControl from '../../patent_family_list/list_controls/PatentFamilyFieldsControl.js'
import { ColumnFilter } from './ColumnFilter.js'
import {
  ALL_FIELDS,
  DEFAULT_FIELDS,
  ID_TO_ALL_FIELDS,
  IGNORE,
  IGNORE_VALUES,
  STATUS,
  TYPE,
  TYPE_VALUES,
} from './matched_patents_table_model.js'
import Label from '../../widgets/Label.js'
import ListControlsInModal from '../../patent_family_list/list_controls/ListControlsInModal.js'
import PageSizeControl from '../../PageSizeControl.js'
import { DEFAULT_PAGE_SIZE } from '../../../model/patent_family_list_page_sizes.js'
import PageControl from '../../PageControl.js'
import { TABLE } from '../../../model/patent_family_list_views.js'

export const MatchedPatentsTableControls = (
  {
    selected_columns_ids=[],
    change_column_selection,
    status_available,
    selected_status_ids,
    on_change_status,
    selected_ignore_ids,
    on_change_ignore,
    selected_type_ids,
    on_change_type,

    num_pages,
    page_sizes,
    page_size=DEFAULT_PAGE_SIZE,
    current_page=0,
    on_change_page_size,
    on_change_current_page,
    className
  }) => {

  return (
    <div className={cn('d-flex justify-content-between', className)}>
      <div className='d-flex align-items-center'>
        <ListControlsInModal
          context='Patent upload'
          patent_family_list_view_id={TABLE}

          all_fields={ALL_FIELDS}
          selected_field_ids={selected_columns_ids}
          default_selected_field_ids={DEFAULT_FIELDS}
          on_change_selected_field_ids={change_column_selection}

          page_sizes={page_sizes}
          selected_page_size={page_size}
          on_change_page_size={on_change_page_size}

          //patent matching table controls
          pm_statuses={status_available}
          selected_pm_statuse_ids={selected_status_ids}
          on_change_pm_status_ids={on_change_status}

          pm_table_rows_statuses={IGNORE_VALUES}
          selected_pm_table_rows_status_ids={selected_ignore_ids}
          on_change_selected_pm_table_rows_status_ids={on_change_ignore}

          pm_publication_types={TYPE_VALUES}
          selected_pm_publication_type_ids={selected_type_ids}
          on_change_selected_pm_publication_type_ids={on_change_type}

          toggleClassName='d-block d-xl-none me-2'
          pageSizeFilterClassName='d-block d-md-none'
        />

        <PatentFamilyFieldsControl
          context='Patent upload'
          all_fields={ALL_FIELDS}
          selected_field_ids={selected_columns_ids}
          default_selected_field_ids={DEFAULT_FIELDS}
          on_change_selected_field_ids={change_column_selection}

          className='me-3 mb-1 d-none d-xl-flex align-items-center'
        />

        <div className='d-none d-xl-flex'>
        <Label className='ms-4 my-auto'>Filters:</Label>
          {selected_columns_ids.includes(STATUS) &&
            <ColumnFilter
              className='ms-2'
              label={ID_TO_ALL_FIELDS[STATUS].name}
              values={status_available}
              selected_value_ids={selected_status_ids}
              on_change_selection={on_change_status}
            />
          }
          {selected_columns_ids.includes(IGNORE) &&
            <ColumnFilter
              className='ms-2'
              label='Rows'
              values={IGNORE_VALUES}
              selected_value_ids={selected_ignore_ids}
              on_change_selection={on_change_ignore}
            />
          }
          {selected_columns_ids.includes(TYPE) &&
            <ColumnFilter
              className='ms-2'
              label={ID_TO_ALL_FIELDS[TYPE].name}
              values={TYPE_VALUES}
              selected_value_ids={selected_type_ids}
              on_change_selection={on_change_type}
            />
          }
        </div>
      </div>

      <div className='d-flex align-content-end'>
        <PageSizeControl
          page_sizes={page_sizes}
          selected_page_size={page_size}
          on_change_page_size={on_change_page_size}
          className='me-3 mb-1 d-none d-md-flex align-items-center'
        />
        <PageControl
          current_page={current_page}
          num_pages={num_pages}
          on_change_current_page={on_change_current_page}
        />
      </div>

    </div>
  )
}
