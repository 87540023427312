import React from 'react'
import ReactTable from 'react-table'
import _ from 'underscore'
import cn from 'classnames'

import { to_local_datetime, format_string_first_character_capitalised } from '../../../utils/utils.js'
import { is_failed_status } from '../../../utils/report_progress_utils.js'
import { is_admin } from '../../../utils/user_permissions.js'

import {
  NAME_FIELD_ID,
  IS_SELECTED_FIELD_ID,
  BUILD_NEW_FIELD_ID,
  RERUN_FIELD_ID,
  OWNER_FIELD_ID,
  CREATED_AT_FIELD_ID,
  LAST_VIEWED_FIELD_ID,
  STATUS_FIELD_ID,
  ACTIONS_FIELD_ID
} from '../model/report_fields.js'

import SortingColumnHeaderCell from '../../patent_family_list/SortingColumnHeaderCell.js'

import CheckboxStatic from '../../widgets/CheckboxStatic.js'
import Spinner from '../../widgets/Spinner.js'
import TextLink from '../../widgets/TextLink.js'
import { RetryIcon } from '../../widgets/IconSet.js'

function get_width_props(field_id) {
  if (_.contains([IS_SELECTED_FIELD_ID, BUILD_NEW_FIELD_ID, RERUN_FIELD_ID], field_id)) {
    return { width: 38 }
  }

  if (_.contains([CREATED_AT_FIELD_ID, LAST_VIEWED_FIELD_ID], field_id)) {
    return { width: 140 }
  }

  if (_.contains([STATUS_FIELD_ID], field_id)) {
    return { width: 120 }
  }

  if (_.contains([OWNER_FIELD_ID], field_id)) {
    return { maxWidth: 180 }
  }

  if (_.contains([NAME_FIELD_ID], field_id)) {
    return { minWidth: 200 }
  }

  if (_.contains([ACTIONS_FIELD_ID], field_id)) {
    return { width: 80 }
  }

  return {}
}

const ReportManagementTableCell = (
  {
    field_id,
    custom_render_fn,
    selected_external_report_ids,
    selected_project_ids,
    toggle_selected,
    user,
    retry_failed_report,
    value,
    data_row
  }) => {

  const report = data_row.external_report_id ? data_row : null
  const {external_report_id} = report || {}

  const project = !report ? data_row : null

  const is_disabled = (report && external_report_id == null) || (project && project.project_id == null)

  if (custom_render_fn) {
    // Custom render function, so use that.
    return custom_render_fn(data_row)
  }

  if (field_id === IS_SELECTED_FIELD_ID) {
    const is_selected = (report && _.contains(selected_external_report_ids, external_report_id)) || (project && _.contains(selected_project_ids, project.project_id))
    const to_select_arr = report ? [report] : [project]

    return (
      <CheckboxStatic
        is_checked={is_selected}
        onClick={() => toggle_selected(to_select_arr, !is_selected)}
        is_disabled={is_disabled}
      />
    )
  }

  if (field_id === OWNER_FIELD_ID && report) {
    const {owner_name} = report
    // show spinner if we're still waiting for this to be fetched from keycloak
    return (
      <span key={external_report_id}>
        {owner_name ? owner_name : <Spinner size={'sm'}/>}
      </span>
    )
  }

  if (field_id === CREATED_AT_FIELD_ID ) {
    return <span>{to_local_datetime(value)}</span>
  }

  if (field_id === LAST_VIEWED_FIELD_ID ) {
    return <span>{to_local_datetime(value)}</span>
  }

  if (field_id === STATUS_FIELD_ID && report) {
    const { status, created_by_user } = report
    if (!status) {
      // show spinner if we're still waiting for the first status response from the choreo
      return (<Spinner size={'sm'}/>)
    }
    if (is_failed_status(status)) {
      const can_rerun_report = created_by_user || is_admin(user)
      return (
        <span>{format_string_first_character_capitalised(status)}
          { can_rerun_report &&
            <TextLink
              className='ms-1'
              onClick={() => retry_failed_report(report)}
              title='Retry'
            >
              <RetryIcon/>
            </TextLink>
          }
        </span>
      )
    }
    return <span>{format_string_first_character_capitalised(status)}</span>
  }

  if (value == null) {
    return null
  }

  return value // simple value with no formatting
}


const ReportManagementTable = (
  {
    reports,
    selected_sort_field_id,
    selected_sort_direction_id,
    on_change_sort_field_id_and_sort_direction_id,
    fields,
    field_id_to_render_fn={},
    field_id_to_width_props={},
    field_id_to_className={},
    selected_external_report_ids,
    selected_project_ids,
    toggle_selected,
    user,
    retry_failed_report,
    resizable,
    loading,
    no_data_text,
    loading_text,
    className
  }) => {

  function get_columns() {
    return fields.map((field) => {

      const {id} = field

      const custom_render_fn = field_id_to_render_fn[id] // optional (may be null)

      const custom_width_props = field_id_to_width_props[id]
      const width_props = custom_width_props || get_width_props(id)

      const className = field_id_to_className[id]

      return {
        Header: render_column_header(field),
        headerClassName: 'no-sort-mark',
        className: cn(className),
        accessor: id,
        Cell: ({value, original}) => {
          return (
            <ReportManagementTableCell
              field_id={id}
              custom_render_fn={custom_render_fn}
              selected_external_report_ids={selected_external_report_ids}
              selected_project_ids={selected_project_ids}
              toggle_selected={toggle_selected}
              user={user}
              retry_failed_report={retry_failed_report}
              value={value}
              data_row={original}
            />
          )
        },
        ...width_props,
        resizable: false,
        sortable: false
      }
    })
  }

  function render_column_header(field) {
    const has_results = reports && reports.length > 0

    return (
      <SortingColumnHeaderCell
        field={field}
        selected_sort_field_id={selected_sort_field_id}
        selected_sort_direction_id={selected_sort_direction_id}
        on_change_sort_field_id_and_sort_direction_id={on_change_sort_field_id_and_sort_direction_id}
        sort_disabled={!has_results}
      />
    )
  }

  const columns = get_columns()

  return (
    <ReactTable
      className={cn('border-0 -striped overflow-visible', className)}

      manual={true}
      showPagination={false}
      filterable={false}
      sortable={false}

      columns={columns}
      data={reports}
      resizable={resizable}
      getTableProps={() => ({className: 'overflow-visible'})}
      getTbodyProps={() => ({className: 'overflow-visible'})}

      noDataText={no_data_text}
      loadingText={loading_text}
      loading={loading}

      defaultSortDesc={true}
      minRows={0}
    />
  )
}

export default ReportManagementTable