import React from 'react'
import cn from 'classnames'

import TextLink from '../widgets/TextLink.js'
import { HELP, SEARCH_GUIDE } from '../../constants/paths.js'

import cs from '../cipher_styles.module.scss'

const PatentFamilyListGuideLink = ({ className, title }) => {
  return (
    <TextLink
      target='_blank'
      rel='noopener noreferrer'
      href={`${HELP}/${SEARCH_GUIDE}`}
      element='a'
      className={cn('fs-unmask', cs.no_underline_on_hover, className)}
      title='Boolean search guide'
      no_decoration
    >
      {title || 'See guide'}
    </TextLink>
  )
}

export default PatentFamilyListGuideLink