import React, { useState } from 'react'
import { DropdownItem } from 'reactstrap'
import cn from 'classnames'
import _ from 'underscore'

import Modal from '../../widgets/Modal.js'
import { TABLE } from '../../../model/patent_family_list_views.js'
import { TertiaryButton } from '../../widgets/Button.js'
import { CogIcon } from '../../widgets/IconSet.js'
import {
  check_if_all_fields_selected,
  check_if_default_field_selected,
  check_if_no_fields_selected
} from '../../../utils/patent_family_list_utils.js'
import { NavTabVertical } from '../../widgets/NavTab.js'
import TextLink from '../../widgets/TextLink.js'
import { CheckboxAndLabel } from '../../widgets/CheckboxAndLabel.js'
import { CUSTOM_SCORE_RANGE } from '../../classifiers_editor/model/filters.js'
import LabelledSlider from '../../LabelledSlider.js'
import { add_or_remove_item_from_array } from '../../../utils/utils.js'
import BaseDropdown from '../../widgets/BaseDropdown.js'
import ScrollableList from '../../widgets/ScrollableList.js'
import { RadiobuttonWithLabel } from '../../widgets/RadiobuttonWithLabel.js'
import FamilyTagsFilter from '../../family_tagging/FamilyTagsFilter'
import { withUser } from '../../UserContext.js'
import { has_family_details_links } from '../../../utils/user_permissions.js'

import cs from '../../cipher_styles.module.scss'
import s from './ListControlsInModal.module.scss'

const LIST_CONTROLS = [
  {id: 'score', label: 'Score', check_if_available: ({is_score_available}) => is_score_available},
  {id: 'status', label: 'Status', check_if_available: ({is_status_available}) => is_status_available},
  {id: 'sort', label: 'Sort by', check_if_available: ({is_sort_available}) => is_sort_available},
  {id: 'view', label: 'View', check_if_available: ({is_view_available}) => is_view_available, get_class_name: ({viewFilterClassName}) => viewFilterClassName},
  {id: 'columns', label: 'Columns', check_if_available: ({is_columns_available}) => is_columns_available, check_if_enabled: ({is_columns_enabled}) => is_columns_enabled},
  {id: 'tags', label: 'Tags', check_if_available: ({is_tags_available}) => is_tags_available},
  {id: 'page_size', label: 'Page size', get_class_name: ({pageSizeFilterClassName}) => pageSizeFilterClassName},
  {id: 'pm_statuses', label: 'Result', check_if_available: ({is_pm_statuses_available}) => is_pm_statuses_available},
  {id: 'pm_ignored', label: 'Rows', check_if_available: ({is_pm_table_rows_statuses_available}) => is_pm_table_rows_statuses_available},
  {id: 'pm_pub_types', label: 'Type', check_if_available: ({is_pm_publication_types_available}) => is_pm_publication_types_available},
]

const ListControlsInModal = (
  {
    filter_groups,
    selected_filter_id,
    on_change_score_filter_id,
    custom_score_range,
    on_change_custom_score_range,

    statuses,
    selected_status_ids,
    on_change_status_ids,
    hide_status,

    sort_fields,
    id_to_sort_field=[],
    selected_sort_field_id,
    on_change_sort_field_id,
    sort_directions,
    selected_sort_direction_id,
    on_change_sort_direction_id,

    patent_family_list_views,
    patent_family_list_view_id,
    on_change_patent_family_list_view_id,

    all_fields=[],
    selected_field_ids,
    default_selected_field_ids,
    on_change_selected_field_ids,

    page_sizes,
    selected_page_size,
    on_change_page_size,

    on_family_tags_filter_change,
    user_viewable_family_tags,
    selected_family_tags,
    apply_tag_filter,
    change_apply_tag_filter,
    fetching_tags_error,
    show_filter_tags_spinner,
    family_tags_enabled,

    pm_statuses,
    selected_pm_statuse_ids,
    on_change_pm_status_ids,

    pm_table_rows_statuses,
    selected_pm_table_rows_status_ids,
    on_change_selected_pm_table_rows_status_ids,

    pm_publication_types,
    selected_pm_publication_type_ids,
    on_change_selected_pm_publication_type_ids,

    toggleClassName,
    pageSizeFilterClassName,
    viewFilterClassName,

    user
  }) => {

  const [is_modal_open, set_is_modal_open] = useState(false)

  const available_filters = LIST_CONTROLS.filter(item => {
    const {check_if_available} = item || {}

    return check_if_available ? check_if_available({
      is_score_available: on_change_score_filter_id != null,
      is_status_available: !hide_status && (on_change_status_ids != null),
      is_sort_available: (on_change_sort_field_id != null) && (on_change_sort_direction_id != null),
      is_view_available: (has_family_details_links(user) && on_change_patent_family_list_view_id != null),
      is_columns_available: on_change_selected_field_ids != null,
      is_tags_available: (family_tags_enabled || !_.isEmpty(user_viewable_family_tags)),
      is_pm_statuses_available: on_change_pm_status_ids != null,
      is_pm_table_rows_statuses_available: on_change_selected_pm_table_rows_status_ids != null,
      is_pm_publication_types_available: on_change_selected_pm_publication_type_ids != null,
    }) : true
  })

  const [selected_filter, set_selected_filter] = useState(available_filters[0].id)

  const selected_sort_field = id_to_sort_field[selected_sort_field_id]

  const all_field_ids = all_fields.map(field => field.id)

  const is_all_selected   = check_if_all_fields_selected({selected_fields: selected_field_ids, all_fields: all_field_ids})
  const is_none_selected  = check_if_no_fields_selected({selected_fields: selected_field_ids})
  const is_default_fields_selected = check_if_default_field_selected({selected_fields: selected_field_ids, default_fields: default_selected_field_ids})

  return (
    <>
      <TertiaryButton icon_only={true} className={toggleClassName} onClick={() => set_is_modal_open(true)}><CogIcon/></TertiaryButton>

      {is_modal_open &&
        <Modal
          on_hide={() => set_is_modal_open(false)}
          className={s.controls_modal}
          contentClassName='h-100'
          bodyClassName={s.modal_body}
        >
          <div className='h-100 d-flex'>
            <div className={cn('h-100 pe-2', s.options_wrapper)}>
              {available_filters.map(({ id, label, check_if_enabled, get_class_name }) => {
                const is_enabled = check_if_enabled ? check_if_enabled({ is_columns_enabled: patent_family_list_view_id === TABLE }) : true

                const customClassName = get_class_name ? get_class_name({pageSizeFilterClassName, viewFilterClassName}) : ''

                return (
                  <NavTabVertical key={id} className={cn('mb-2', customClassName)} is_active={selected_filter === id}>
                    <TextLink onClick={() => set_selected_filter(id)} disable={!is_enabled} className={cs.white_space_nowrap}>{label}</TextLink>
                  </NavTabVertical>
                )
              })}
            </div>

            <div className={cn('ps-1 w-100', s.filter_wrapper)}>
              {selected_filter === 'score' &&
                <div>
                  {filter_groups.map(({ name: group_name, children }, i) => {
                    // for each group of filter ids, show the filter options and a Divider line
                    return (
                      <span key={i}>
                        {group_name != null && <label className='mb-2'>{group_name}</label>}
                          {children.map((filter, j) => {
                            const { id, subfilters = [], name, short_name } = filter

                            if (subfilters.length > 0) {
                              return (
                                <div className='d-md-flex' key={`${i}_${j}`}>
                                  <CheckboxAndLabel
                                    key={j} label={short_name || name}
                                    is_checked={selected_filter_id === id}
                                    on_click={() => on_change_score_filter_id(id)}
                                    className={cn( 'me-3', cs.white_space_nowrap)}
                                  />
                                  {subfilters.map((subfilter, k) => {
                                    const { id, name, short_name } = subfilter
                                    return (
                                      <CheckboxAndLabel
                                        key={`${i}_${j}_${k}`}
                                        label={short_name || name}
                                        is_checked={selected_filter_id === id}
                                        on_click={() => on_change_score_filter_id(id)}
                                        className={cn( 'me-3', cs.white_space_nowrap)}
                                      />
                                    )
                                  })}
                                </div>
                              )
                            }

                            return (
                              <div key={`${i}_${j}`}>
                                <CheckboxAndLabel
                                  label={short_name || name}
                                  is_checked={selected_filter_id === id}
                                  on_click={() => on_change_score_filter_id(id)}

                                  className={cs.white_space_nowrap}
                                />
                                {(id === CUSTOM_SCORE_RANGE) &&
                                  <div className='d-md-flex align-items-center ms-3'>
                                    <LabelledSlider
                                      value={custom_score_range}
                                      extent={[0, 1]}
                                      handleChange={(values) => on_change_custom_score_range(values)}
                                      step={0.1}
                                      hide_label
                                      min_distance={0.1}
                                    />
                                    <div className={cn('ms-3', s.slider_label)}>
                                      {custom_score_range[0]} to {custom_score_range[1]}
                                    </div>
                                  </div>
                                }

                              </div>
                            )})}
                      </span>
                    )
                  })}
                </div>
              }

              {selected_filter === 'status' &&
                <div>
                  {statuses.map(item => {
                    const { id, name } = item

                    return (
                      <CheckboxAndLabel
                        key={id}
                        label={name}
                        is_checked={selected_status_ids.indexOf(id) !== -1}
                        on_click={() => on_change_status_ids(add_or_remove_item_from_array(selected_status_ids, id))}
                      />
                    )
                  })
                  }
                </div>
              }

              {selected_filter === 'sort' &&
                <div>
                  <label>Column</label>
                  <BaseDropdown label={selected_sort_field.name}>
                    <ScrollableList>
                      {sort_fields.map(sort_field => {
                        const { name, id } = sort_field
                        return (
                          <DropdownItem
                            key={id}
                            active={(id === selected_sort_field_id)}
                            onClick={() => on_change_sort_field_id(id)}
                          >
                            {name}
                          </DropdownItem>
                        )
                      })}
                    </ScrollableList>
                  </BaseDropdown>

                  <label>Direction</label>
                  <div>
                    {sort_directions.map(item => {
                      const { name, id } = item
                      return (
                        <RadiobuttonWithLabel
                          key={id}
                          is_checked={(id === selected_sort_direction_id)}
                          on_click={() => on_change_sort_direction_id(id)}
                          label={name}
                        />
                      )
                    })}
                  </div>
                </div>
              }

              {selected_filter === 'view' &&
                <div>
                  {patent_family_list_views.map(item => {
                    const { id, name } = item || {}

                    return (
                      <RadiobuttonWithLabel
                        key={id}
                        is_checked={(id === patent_family_list_view_id)}
                        on_click={() => on_change_patent_family_list_view_id(id)}
                        label={name}
                      />
                    )
                  })}
                </div>
              }

              {selected_filter === 'columns' &&
                <div>
                  <RadiobuttonWithLabel
                    is_checked={is_all_selected}
                    on_click={() => on_change_selected_field_ids(all_field_ids)}
                    label='All'
                  />
                  <RadiobuttonWithLabel
                    is_checked={is_default_fields_selected}
                    on_click={() => on_change_selected_field_ids(default_selected_field_ids)}
                    label='Default'
                  />
                  <RadiobuttonWithLabel
                    is_checked={is_none_selected}
                    on_click={() => on_change_selected_field_ids([])}
                    label='None'
                  />
                </div>
              }

              {selected_filter === 'page_size' &&
                <div>
                  {page_sizes.map(item => {
                    return (
                      <RadiobuttonWithLabel
                        key={item}
                        is_checked={(item === selected_page_size)}
                        on_click={() => on_change_page_size(item)}
                        label={item}
                      />
                    )
                  })}
                </div>
              }

              {selected_filter === 'tags' &&
                <div>
                  <FamilyTagsFilter
                    viewable_tags={user_viewable_family_tags}
                    on_filter_change={on_family_tags_filter_change}
                    selected_tags={selected_family_tags}
                    apply_filter={apply_tag_filter}
                    set_apply_filter={change_apply_tag_filter}
                    tags_error={fetching_tags_error}
                    show_spinner={show_filter_tags_spinner}
                    tags_display_only={true}
                  />
                </div>
              }

              {selected_filter === 'pm_statuses' &&
                <div>
                  {pm_statuses.map(item => {
                    const { id, name } = item

                    return (
                      <CheckboxAndLabel
                        key={id}
                        label={name}
                        is_checked={selected_pm_statuse_ids.indexOf(id) !== -1}
                        on_click={() => on_change_pm_status_ids(add_or_remove_item_from_array(selected_pm_statuse_ids, id))}
                      />
                    )
                  })}
                </div>
              }

              {selected_filter === 'pm_ignored' &&
                <div>
                  {pm_table_rows_statuses.map(item => {
                    const { id, name } = item

                    return (
                      <CheckboxAndLabel
                        key={id}
                        label={name}
                        is_checked={selected_pm_table_rows_status_ids.indexOf(id) !== -1}
                        on_click={() => on_change_selected_pm_table_rows_status_ids(add_or_remove_item_from_array(selected_pm_table_rows_status_ids, id))}
                      />
                    )
                  })}
                </div>
              }

              {selected_filter === 'pm_pub_types' &&
                <div>
                  {pm_publication_types.map(item => {
                    const { id, name } = item

                    return (
                      <CheckboxAndLabel
                        key={id}
                        label={name}
                        is_checked={selected_pm_publication_type_ids.indexOf(id) !== -1}
                        on_click={() => on_change_selected_pm_publication_type_ids(add_or_remove_item_from_array(selected_pm_publication_type_ids, id))}
                      />
                    )
                  })}
                </div>
              }

            </div>
          </div>
        </Modal>
      }
    </>
  )
}

export default withUser(ListControlsInModal)