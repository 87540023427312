import React from 'react'
import cn from 'classnames'

import { DropdownItem } from 'reactstrap'
import BaseDropdown from '../widgets/BaseDropdown.js'

import s from './ThresholdControl.module.scss'

const ThresholdControl = ({ on_change_value, threshold_options, threshold }) => {

  return (
    <span className={'d-flex ms-auto my-0 me-0'}>
      <BaseDropdown
        menuClassName={s.threshold_dropdown}
        buttonClassName={cn('p-0', s.btn)}
        label={threshold}
      >

        {threshold_options.map((value, idx) =>
          <DropdownItem active={value === threshold} key={idx} onClick={on_change_value.bind(this, value)}>
            {value}
          </DropdownItem>
        )}

      </BaseDropdown>
    </span>
  )
}

export default ThresholdControl