import React, { useCallback, useEffect, useState } from 'react'
import cn from 'classnames'

import { NO_FILTER_ID, TIME_RANGE_FILTER_FIELDS } from '../../model/time_range_filter_fields.js'
import { RadiobuttonWithLabel } from '../widgets/RadiobuttonWithLabel.js'
import { extract_date_string_from_date } from '../../utils/utils.js'
import DateRangeSelectorWithSliderAndCalendars from '../widgets/DateRangeSelectorWithSliderAndCalendars.js'
import { track_report_viewer_event } from '../../utils/tracking_utils.js'
import { useDebounce } from '../../hooks/general_hooks.js'

import s from './TimerangeSelector.module.scss'

const TimerangeSelector = ({ selector_id, timerange_field_id_to_default_extent, selected_timerange, set_selected_timerange, className }) => {
  const [new_timerange_selection, set_new_timerange_selection] = useState(null)

  const debounce_time = (new_timerange_selection && new_timerange_selection.field_id === NO_FILTER_ID) ? 0 : 1000
  const new_timerange_selection_debounced = useDebounce(new_timerange_selection, debounce_time)

  const check_if_same_timerange_settings = useCallback((t1, t2) => {
    const {field_id: field_1, timerange: timerange_1} = t1 || {}
    const {field_id: field_2, timerange: timerange_2} = t2 || {}

    const [timerange_1_start, timerange_1_end] = timerange_1 || []
    const [timerange_2_start, timerange_2_end] = timerange_2 || []

    return ((field_1 === field_2) && (timerange_1_start === timerange_2_start) && (timerange_1_end === timerange_2_end))
  }, [])

  useEffect(() => {
    if (!new_timerange_selection_debounced ||
      check_if_same_timerange_settings(new_timerange_selection_debounced, selected_timerange)) return

    set_selected_timerange(new_timerange_selection_debounced)
    set_new_timerange_selection(null)

  }, [new_timerange_selection_debounced, selected_timerange, set_selected_timerange, check_if_same_timerange_settings])

  function get_clean_year_extent(field_id) {
    const { min, max } = timerange_field_id_to_default_extent == null ? {} : (timerange_field_id_to_default_extent[field_id] || {})

    const clean_year_extent = min && max ? [Math.max(min, 1990), max] : null

    if (!clean_year_extent) return null

    return [extract_date_string_from_date(new Date(clean_year_extent[0], 0, 1)), extract_date_string_from_date(new Date(clean_year_extent[1], 11, 31))]
  }

  function update_selected_timerange(field_id, years) {
    if (should_disable(field_id)) return

    const new_timerange = field_id === NO_FILTER_ID ? null : years || get_clean_year_extent(field_id) || []

    track_report_viewer_event(`obj="dropdown_items" dropdown="${selector_id}" date="${field_id}" timerange_updated="${years != null}"`)

    set_new_timerange_selection({field_id, timerange: new_timerange})
  }

  function should_disable(field_id) {
    if (field_id === NO_FILTER_ID) return false

    if (!timerange_field_id_to_default_extent) return true
    return !timerange_field_id_to_default_extent[field_id]
  }

  const { field_id, timerange } = new_timerange_selection || selected_timerange || {}

  const clean_extent = get_clean_year_extent(field_id)

  return (
    <div className={className}>
      {TIME_RANGE_FILTER_FIELDS.map((timerange_field, i) => {
        const { name, id } = timerange_field
        const is_selected = (id === field_id)

        const is_all = (id === NO_FILTER_ID)

        const is_disabled = should_disable(id)

        return (
          <div className={cn('d-flex mb-1', {[s.option__separated]: is_all}, {'pb-1 mb-2': is_all})} key={i}>
            <RadiobuttonWithLabel
              label={name}
              is_checked={is_selected}
              is_disabled={is_disabled}
              on_click={() => update_selected_timerange(id, null)}
            />
          </div>
        )})}

      <div className='my-2'>
        {clean_extent &&
          <DateRangeSelectorWithSliderAndCalendars
            className='d-flex flex-column w-100'
            sliderWrapperClassName='justify-content-center'
            datesWrapperClassName='mt-2 justify-content-center'
            extent={clean_extent}
            date_range={timerange}
            on_change_date_range={(timerange) => update_selected_timerange(field_id, timerange)}
            show_slider_labels={true}
          />
        }
      </div>

    </div>
  )
}

export default TimerangeSelector
