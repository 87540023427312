import React, { useState, useEffect } from 'react'
import { DropdownItem } from 'reactstrap'
import _ from 'underscore'
import cn from 'classnames'

import { EDIT, OWNER } from '../classifiers_editor/model/permission_levels.js'
import { add_or_remove_patent_from_training_set, fetch_training_set_labels, get_label_to_ids_as_id_to_label } from '../../utils/training_set_grpc_utils.js'
import { ok_to_proceed_with_shortcuts } from '../../utils/keyboard_shortcuts/keyboard_utils.js'
import { contains } from '../../utils/utils.js'
import { get_top_score } from '../classifiers_editor/utils/patent_field_utils.js'
import { POSITIVE, NEGATIVE, IGNORE, UNKNOWN, TEST_POSITIVE, TEST_NEGATIVE } from '../classifiers_editor/constants/labels.js'
import {
  LABEL_AS_POSITIVE_KEYS,
  LABEL_AS_NEGATIVE_KEYS,
  LABEL_AS_IGNORE_KEYS,
  REMOVE_LABEL_KEYS,
  LABEL_AS_TEST_POSITIVE_KEYS,
  LABEL_AS_TEST_NEGATIVE_KEYS,
} from '../../constants/keys.js'
import { Label } from './FamilyDetailsLabel.js'
import { FamilyDetailsTile } from './FamilyDetailsTile.js'
import ClassifierLabelControl from '../classifiers_editor/components/ClassifierLabelControl.js'
import ClassifierStatusMessage from '../classifiers_editor/components/ClassifierStatusMessage.js'
import BaseDropdown from '../widgets/BaseDropdown.js'
import ScrollableList from '../widgets/ScrollableList.js'
import SearchBar from '../widgets/SearchBar.js'
import Spinner from '../widgets/Spinner.js'
import ClassifierLabelMarker from '../classifiers_editor/components/ClassifierLabelMarker.js'
import ClassifierScoreDisplay from '../classifiers_editor/components/ClassifierScoreDisplay.js'

import s from './AddPatentToTrainingSetControl.module.scss'

function get_default_training_set_id(local_training_set_id) {
  if (local_training_set_id) {
    // There's a local training set (i.e. eval classifier), so select it by default
    return local_training_set_id
  }

  // No training set selected
  return null
}

function check_is_local(local_training_set_id, selected_training_set_id) {
  if (local_training_set_id == null) {
    return false
  }
  return (local_training_set_id === selected_training_set_id)
}

/**
 * Renders control for selecting a training set, and adding a patent to it.
 */
const AddPatentToTrainingSetControl = ({
  className,
  patent,
  training_set_infos,

  // A "local" training set is one that is held in memory.
  // For example, in a Classifier Evaluation report, the training set associated with the report is "local".
  // Likewise, in the Classifier Editor UI, the training set being edited is "local".

  local_training_set_id,         // optional
  on_set_local_classifier_label, // optional

  reset_selected_training_set_id_on_change_patfam, // optional
}) => {

  const [selected_training_set_id, set_selected_training_set_id] = useState(null)

  const [id_to_external_label, set_id_to_external_label] = useState(null)
  const [is_fetching_external_labels, set_is_fetching_external_labels] = useState(false)
  const [error_fetching_external_labels, set_error_fetching_external_labels] = useState(null)

  const [is_saving_external_training_set, set_is_saving_external_training_set] = useState(null)
  const [error_saving_external_training_set, set_error_saving_external_training_set] = useState(null)

  const [search_input, set_search_input] = useState('')

  const has_some_training_sets = (training_set_infos != null && training_set_infos.length > 0)

  const default_training_set_id = get_default_training_set_id(local_training_set_id, training_set_infos)
  const selected_training_set_id_or_default = (selected_training_set_id != null) ? selected_training_set_id : default_training_set_id

  const is_local = check_is_local(local_training_set_id, selected_training_set_id_or_default)

  useEffect(() => {
    // When patfam changes...
    if (reset_selected_training_set_id_on_change_patfam) {
      // reset
      set_selected_training_set_id(null)
    }
  }, [reset_selected_training_set_id_on_change_patfam, patent.patFamId])

  useEffect(() => {
    // When classifier or patfam changes, wipe any errors/saving state
    set_is_saving_external_training_set(false)
    set_error_saving_external_training_set(null)
  }, [selected_training_set_id, patent.patFamId])

  function fetch_external_labels() {
    // Classifier has changed (and is non-local), so fetch labels
    set_is_fetching_external_labels(true)
    set_error_fetching_external_labels(null)
    fetch_training_set_labels(selected_training_set_id)
      .catch(err => {
        // FAIL
        set_error_fetching_external_labels(err)
        set_is_fetching_external_labels(false)
        throw err
      })
      .then((label_to_ids) => {
        // SUCCESS
        const id_to_external_label = get_label_to_ids_as_id_to_label(label_to_ids)
        set_id_to_external_label(id_to_external_label)
        set_is_fetching_external_labels(false)
      })
  }

  useEffect(() => {
    if (is_local || selected_training_set_id == null) {
      set_id_to_external_label(null)
      return
    }
    fetch_external_labels()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_training_set_id, is_local])

  function on_keyup(event) {
    const { keyCode } = event

    if (!ok_to_proceed_with_shortcuts(event)) {
      return
    }

    if (!selected_training_set_info || is_fetching_external_labels) {
      return
    }

    if (_.contains(LABEL_AS_POSITIVE_KEYS, keyCode)) {
      event.preventDefault()
      on_set_label(POSITIVE)
      return
    }

    if (_.contains(LABEL_AS_NEGATIVE_KEYS, keyCode)) {
      event.preventDefault()
      on_set_label(NEGATIVE)
      return
    }

    if (_.contains(LABEL_AS_IGNORE_KEYS, keyCode)) {
      event.preventDefault()
      on_set_label(IGNORE)
      return
    }

    if (_.contains(LABEL_AS_TEST_POSITIVE_KEYS, keyCode)) {
      event.preventDefault()
      on_set_label(TEST_POSITIVE)
      return
    }

    if (_.contains(LABEL_AS_TEST_NEGATIVE_KEYS, keyCode)) {
      event.preventDefault()
      on_set_label(TEST_NEGATIVE)
      return
    }

    if (_.contains(REMOVE_LABEL_KEYS, keyCode)) {
      event.preventDefault()
      on_set_label(UNKNOWN)
      return
    }

  }

  useEffect(() => {
    // Register handler (post-render)
    document.addEventListener('keyup', on_keyup)

    return () => {
      // Unregister handler (on pre-render/unmount)
      document.removeEventListener('keyup', on_keyup)
    }
  })

  if (!has_some_training_sets) {
    return (
      <div>No classifiers available.</div>
    )
  }

  const external_label = (!is_local && id_to_external_label) ? id_to_external_label[patent.patFamId] : null

  const selected_training_set_info = _.find(training_set_infos, training_set_info => training_set_info.alias === selected_training_set_id_or_default)

  const { name } = (selected_training_set_info || {}) // may be null

  const training_set_items = training_set_infos.map(training_set_info => {
    // Add id
    return { ...training_set_info, id: training_set_info.alias }
  })

  const search_input_clean = search_input.toLowerCase()

  const training_set_items_filtered_and_sorted = _.chain(training_set_items)
    .filter(training_set_info => {
      return _.contains([EDIT, OWNER], training_set_info.requester_permission) // only show editable training sets
    })
    .filter(training_set_info => {
      if (!search_input) {
        return true
      }
      return contains(training_set_info.name, search_input_clean)
    })
    .sortBy(training_set_info => training_set_info.name ? training_set_info.name.toLowerCase() : training_set_info.name)
    .value()

  const local_ts_item = _.find(training_set_items_filtered_and_sorted, item => item.id === local_training_set_id)

  const { id, user_class } = patent // assumes we have id (i.e. patFamId)

  const score = get_top_score(patent)

  function on_set_label(label) {
    // Clear existing
    set_is_saving_external_training_set(false)
    set_error_saving_external_training_set(null)

    if (is_local) {
      // Local
      on_set_local_classifier_label(null, patent, label)
    } else {
      // External
      set_is_saving_external_training_set(true)

      add_or_remove_patent_from_training_set(selected_training_set_id_or_default, id, label)
        .catch(err => {
          set_is_saving_external_training_set(false)
          set_error_saving_external_training_set(err)
          throw err
        })
        .then(() => {
          set_is_saving_external_training_set(false)

          // Update external labels in memory
          const id_to_external_label_new = {
            ...id_to_external_label,
            [id]: label
          }
          set_id_to_external_label(id_to_external_label_new)
        })
    }
  }

  const is_saving = is_local ? patent.is_pending : is_saving_external_training_set
  const is_error  = is_local ? patent.is_error   : error_saving_external_training_set

  const is_positive      = is_local ? (user_class === POSITIVE)      : (external_label === POSITIVE)
  const is_negative      = is_local ? (user_class === NEGATIVE)      : (external_label === NEGATIVE)
  const is_ignore      = is_local ? (user_class === IGNORE)        : (external_label === IGNORE)
  const is_test_positive = is_local ? (user_class === TEST_POSITIVE) : (external_label === TEST_POSITIVE)
  const is_test_negative = is_local ? (user_class === TEST_NEGATIVE) : (external_label === TEST_NEGATIVE)

  const label = is_local ? user_class : external_label

  return (
    <FamilyDetailsTile
      className={cn('py-0 px-0', className)}
    >
      <ClassifierLabelMarker
        is_positive={is_positive}
        is_negative={is_negative}
        is_test_positive={is_test_positive}
        is_test_negative={is_test_negative}
        is_ignore={is_ignore}

        className={s.status}
      />
      <div className={cn('px-3', s.controls)}>
        <div>
          <Label className='mb-3'>Add to classifier</Label>

          <div>
            <ClassifierLabelControl
              disable={!selected_training_set_info || is_fetching_external_labels || error_fetching_external_labels}
              is_saving={is_saving}
              is_error={is_error}
              set_label={on_set_label}
              selected_label={label} // may be null
            />
          </div>
          <div className={cn('d-flex align-items-center mt-1', s.classifier_info_block)}>
            <ClassifierStatusMessage
              is_saving={is_saving}
              is_error={is_error}
            />
            {is_fetching_external_labels &&
              <Spinner size='sm' />
            }
            {error_fetching_external_labels &&
              <div>
                Unable to fetch labels <span onClick={fetch_external_labels} className={cn('ms-1', s.retry_fetch_external_labels_link )}>[retry]</span>
              </div>
            }
          </div>
        </div>

        <div className={cn('mt-2', s.second_row)}>

          <BaseDropdown
            label={selected_training_set_info ? name : 'Select classifier'}
            right={true}
            buttonClassName={cn('fs-mask', s.button)}
            menuClassName={s.menu}
          >
            <SearchBar
              className='m-2 fs-mask'
              textAreaClassName={s.search_text_area}
              placeholder={'Filter by keyword'}
              search_input_ref={null}
              search_input={search_input}
              on_change_search_input={set_search_input}
              no_enter_trigger={true}
              is_search_valid={true}
              autofocus={true}
            />

            {(local_ts_item != null) &&
              <div>
                <DropdownItem divider className='mb-1' />
                <DropdownItem
                  onClick={set_selected_training_set_id.bind(null, local_ts_item.id)} // call directly (react strap passes multiple arguments)}
                  disabled={local_ts_item.disabled}
                  className='fs-mask'
                >
                  {local_ts_item.name}
                </DropdownItem>
              </div>
            }

            <DropdownItem divider className='mb-1' />

            {(training_set_items_filtered_and_sorted.length > 0) &&
              <ScrollableList>
                {training_set_items_filtered_and_sorted.map((classifier, idx) =>
                  <DropdownItem
                    key={idx}
                    onClick={set_selected_training_set_id.bind(null, classifier.id)} // call directly (react strap passes multiple arguments)}
                    disabled={classifier.disabled}
                    className='fs-mask'
                  >
                    {classifier.name}
                  </DropdownItem>
                )}
              </ScrollableList>
            }
            {(training_set_items_filtered_and_sorted.length === 0) &&
              <div
                className='m-2'
              >
                No results found
              </div>
            }
          </BaseDropdown>

        </div>

        <div
          className='mt-2'
        >
          <div className='d-flex'>
            <Label inline>User label</Label>
            <div>
              {(label && label !== UNKNOWN) && <span>{label}</span>}
            </div>
          </div>
          <div className={cn('d-flex', { [s.local_classifier_disabled]: !is_local} )}>
            <Label inline>Score</Label>
            <div className={s.classifier_title}>
              {is_local &&
               <ClassifierScoreDisplay
                 score={score}
                  className='p-1'
               />
              }
              {!is_local &&
                <span>n/a</span>
              }
            </div>
          </div>
        </div>
      </div>
    </FamilyDetailsTile>
  )
}

export default AddPatentToTrainingSetControl