import React, { useState, useEffect } from 'react'
import _ from 'underscore'
import cn from 'classnames'

import { fetch_training_sets } from '../../utils/training_set_grpc_utils.js'
import { track_visit_event } from '../../utils/tracking_utils.js'
import { CUSTOM_CLASSIFIER_UI_BASE_URL, CUSTOM_CLASSIFIERS_UI_BASE_URL } from '../../constants/urls.js'
import FeedbackModal from '../FeedbackModal.js'
import Spinner from '../widgets/Spinner.js'
import TextLink from '../widgets/TextLink.js'
import { DashboardSmallTile } from './DashboardTile.js'
import { TileHelpLink } from './TileHelpLink.js'
import { DashboardTileInner } from './DashboardTileInner.js'
import { FEEDBACK_CATEGORIES_BY_ID } from '../../model/feedback.js'
import { FeedbackIcon } from '../widgets/IconSet.js'
import ErrorBody from '../ErrorBody.js'
import { EDIT, OWNER } from '../classifiers_editor/model/permission_levels.js'
import { to_local_date } from '../../utils/utils.js'

import cs from '../cipher_styles.module.scss'
import s from './RecentClassifiersPanel.module.scss'

const MAX_TRAINING_SETS = 3

const RecentClassifiersPanel = () => {

  const [training_sets, set_training_sets] = useState(null)
  const [show_spinner, set_show_spinner] = useState(true)
  const [show_support_modal, set_show_support_modal] = useState(false)
  const [error_fetching_training_sets, set_error_fetching_training_sets] = useState(null)

  const has_training_sets = training_sets && training_sets.length > 0

  useEffect(() => {
    let did_cancel = false
    fetch_training_sets()
      .then(training_set_infos => {
        // Only show training sets that user has edit permission (i.e. EDIT, OWNER)
        const training_set_infos_filtered = training_set_infos.filter(ts => _.contains([EDIT, OWNER], ts.requester_permission))
        const training_set_infos_filtered_and_sorted = _.sortBy(training_set_infos_filtered, (ts) => -ts.modified_at.seconds)
        if (!did_cancel) {
          set_training_sets(training_set_infos_filtered_and_sorted)
          set_show_spinner(false)
        }
      })
      .catch(error => {
        if (!did_cancel) {
          set_error_fetching_training_sets(error)
          set_show_spinner(false)
        }
      })
    return () => {
      did_cancel = true
    }
  }, [])

  function on_show_support_modal(is_show) {
    set_show_support_modal(is_show)
  }

  function on_click_tile() {
    if (has_training_sets) {
      track_visit_event('page="custom-classifiers"')
      window.open(CUSTOM_CLASSIFIERS_UI_BASE_URL)
    } else {
      track_visit_event('page="custom-classifiers-create-new-from-homepage"')
      window.open(`${CUSTOM_CLASSIFIERS_UI_BASE_URL}?create_new=true`)
    }
  }

  function on_click_training_set(e, training_set) {
    e.stopPropagation()
    e.preventDefault()
    track_visit_event(`page="classifier" classifier="${training_set.alias}"`)
    window.open(CUSTOM_CLASSIFIER_UI_BASE_URL+training_set.alias)
  }

  function get_training_set_display_date(training_set) {
    const last_modified = new Date(training_set.modified_at.seconds * 1000)
    return to_local_date(last_modified)
  }

  return (
    <DashboardSmallTile
      title='Classifier training'
      on_click={on_click_tile}
      hint={
        <TileHelpLink
          on_click={() => on_show_support_modal(true)}
          icon={<FeedbackIcon/>}
        />
      }
    >

      {show_spinner &&
        <div className='text-center'>
          <Spinner innerClassName='spinner-pale' />
        </div>
      }

      {show_support_modal &&
        <FeedbackModal
          is_open={true}
          on_hide={() => on_show_support_modal(false)}
          title={'Request classifier training support'}
          category={FEEDBACK_CATEGORIES_BY_ID.help.label}
          subject={'Classifier training'}
        />
      }

      {error_fetching_training_sets &&
        <DashboardTileInner className={s.content}>
          <ErrorBody
            error={error_fetching_training_sets}
            context='fetching user technologies'
            show_details_in_modal={true}
          />
        </DashboardTileInner>
      }

      {!show_spinner && !error_fetching_training_sets && training_sets &&
        <DashboardTileInner>
          <div className='my-3'>
            { !has_training_sets &&
              <span className={s.content}>Start building classifiers</span>
            }
            { has_training_sets &&
              <>
                <div className={cn('mb-2', s.title)}>
                  Recently edited classifiers:
                </div>
                  {
                    training_sets.slice(0, Math.min(training_sets.length, MAX_TRAINING_SETS)).map((training_set, i) => (
                      <div key={i} className='d-flex'>
                        <span className={cn('me-1', cs.white_space_nowrap, s.content)}>{get_training_set_display_date(training_set)}</span>
                        <TextLink
                          className={cn('p-0', cs.white_text)}
                          onClick={(e) => {on_click_training_set(e, training_set)}}
                        >
                          {training_set.name}
                        </TextLink>
                      </div>
                    ))
                  }
              </>
            }
          </div>
        </DashboardTileInner>
      }
    </DashboardSmallTile>
  )
}

export default RecentClassifiersPanel