import React from 'react'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'

import { MoreVertIcon } from '../widgets/IconSet.js'
import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'
import { SELECTED_ITEMS_SELECTION_ORDER } from '../../utils/main_items_selection_utils.js'

const SelectedChartsMenu = (
  {
    no_items,
    is_bulk_download_enabled,
    selected_charts_display_order_preference,

    on_export_selected_charts_as_xls,
    on_export_selected_charts_as_ppt,
    on_change_selected_charts_display_order_preference,
    on_save_charts_as_set,
    on_load_chart_set,
    on_clear_selected_charts,
  }) => {

  return (
    <UncontrolledDropdown
    >
      <DropdownToggle tag='div' title='Selection options'><MoreVertIcon /></DropdownToggle>

      <DropdownMenu end>
        <DropdownItem header>Export</DropdownItem>
        <DropdownItem
          disabled={no_items || !is_bulk_download_enabled}
          onClick={on_export_selected_charts_as_xls}
        >
          Excel
        </DropdownItem>
        <DropdownItem
          disabled={no_items || !is_bulk_download_enabled}
          onClick={on_export_selected_charts_as_ppt}
        >
          Powerpoint
        </DropdownItem>
        <DropdownItem divider/>
        <DropdownItem header>Chart sets</DropdownItem>
        <DropdownItem disabled={no_items} onClick={on_save_charts_as_set}>Save selection as chart set</DropdownItem>
        <DropdownItem onClick={on_load_chart_set}>Load chart set</DropdownItem>
        <DropdownItem disabled={no_items} onClick={on_clear_selected_charts}>Clear selection</DropdownItem>
        <DropdownItem divider/>
        <DropdownItem>
          <CheckboxAndLabel
            label='Show charts in order added'
            is_checked={selected_charts_display_order_preference===SELECTED_ITEMS_SELECTION_ORDER}
            on_click={on_change_selected_charts_display_order_preference}
          />
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default SelectedChartsMenu