import React, { useState, useEffect } from 'react'
import _ from 'underscore'

import ContainerFullWidth from '../ContainerFullWidth.js'
import ErrorBody from '../ErrorBody.js'
import ErrorModal from '../ErrorModal.js'
import ClassifiersDisplayContainer from '../classifiers/ClassifiersDisplayContainer.js'
import ClassifiersBasketContainer from '../classifiers/ClassifiersBasketContainer.js'
import ClassifiersBasket from '../classifiers/ClassifiersBasket.js'
import SubscriptionsNavigation from './SubscriptionsNavigation.js'
import { withUser } from '../UserContext.js'
import {
  get_leaf_nodes_as_array,
  is_same_classifier,
  filter_super_classifiers
} from '../../utils/classifier_tree_utils.js'
import { get_classifier_groups, IS_USER_TAXONOMY, USER_OTHER_ID } from '../../utils/classifier_group_utils.js'
import {
  get_all_user_subscriptions,
  save_or_update_subscriptions,
  classifier_as_subscription_object,
  is_same_subscription,
  ALERT_THRESHOLD_OPTIONS,
  MULTI_THRESHOLD,
  CLASSIFIER_ID,
  NAME,
} from '../../utils/alerts_utils.js'
import { track_subscriptions_event } from '../../utils/tracking_utils.js'
import { CLASSIFIERS } from '../../constants/paths.js'
import { is_view_only_user, has_old_weekly_classifier_alerts } from '../../utils/user_permissions.js'
import PageNotFound from '../PageNotFound.js'
import TechnologiesDisplay from '../builder/TechnologiesDisplay.js'

import s from './ClassifierAlertSubscriptions.module.scss'

const ClassifierAlertSubscriptions = ({user}) => {

  const [is_fetching_data, set_is_fetching_data] = useState(true)

  const [classifier_groups, set_classifier_groups] = useState([])
  const [selected_classifier_group_id, set_selected_classifier_group_id] = useState(null)
  const [selected_classifiers, set_selected_classifiers] = useState([])
  const [show_config_controls, set_show_config_controls] = useState(false)

  const [subscriptions, set_subscriptions] = useState([])
  const [subscriptions_updated, set_subscriptions_updated] = useState(false)
  const [is_saving, set_is_saving] = useState(false)
  const [subscriptions_fetch_error, set_subscriptions_fetch_error] = useState(null)
  const [subscriptions_save_error, set_subscriptions_save_error] = useState(null)

  function fetch_subs_and_classifiers() {
    return Promise.all([
      get_all_user_subscriptions(),
      get_subscribable_classifier_groups()
    ])
      .then(([db_subs, subscribable_classifier_groups]) => {
        let selected_in_classifier_trees = []

        if (subscribable_classifier_groups.length && db_subs.length) {
          // find pre-selected (active subscriptions) among available classifiers
          const all_classifiers = _.flatten(subscribable_classifier_groups.map(classifier_group => get_leaf_nodes_as_array(classifier_group)))
          selected_in_classifier_trees = all_classifiers
            .filter(tech => _.some(db_subs, sub => is_same_classifier(tech, sub)))
            .map(tech => _.pick(tech, CLASSIFIER_ID, NAME, 'parent'))
        }

        const selected = _.uniq(selected_in_classifier_trees, s => s.classifier_id)

        set_classifier_groups(subscribable_classifier_groups)
        set_selected_classifiers(selected)
        set_subscriptions(db_subs)
        set_is_fetching_data(false)
      })
  }

  useEffect(() => {
    let did_cancel = false
    fetch_subs_and_classifiers()
      .catch(error => {
        if (!did_cancel) {
          set_subscriptions_fetch_error(error)
          set_is_fetching_data(false)
          throw error
        }
      })
    return () => {
      did_cancel = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function get_subscribable_classifier_groups() {
    return get_classifier_groups(user, false /* false here excludes nd */)
    .then((classifier_groups) => {
      return classifier_groups.map((classifier_group) => {
        if (classifier_group[IS_USER_TAXONOMY] || classifier_group.id === USER_OTHER_ID) {
          // For user classifiers, do nothing
          return classifier_group
        }
        // For product taxononomies, remove super-groups
        return filter_super_classifiers(classifier_group)
      })
    })
  }

  function subscriptions_to_basket_items() {
    return selected_classifiers.map(classifier => {
      const thresholds = subscriptions.filter(sub => is_same_classifier(classifier, sub)).map(m => m.threshold)

      return {
        ..._.pick(classifier, CLASSIFIER_ID, NAME),
        threshold: thresholds.length > 1 ? MULTI_THRESHOLD : _.first(thresholds)
      }
    })
  }

  function update_selected_and_save_subscriptions(selections) {
    set_is_saving(true)
    set_subscriptions_updated(false)

    // filter duplicates from repetition in tree
    const updated_selected_classifiers = _.uniq(selections, selected => selected.classifier_id)

    const [active_subscriptions, deselected_subscriptions] =
      _.partition(subscriptions, subscription => _.some(updated_selected_classifiers, selected => is_same_classifier(subscription, selected)))

    const subscriptions_to_deactivate = deselected_subscriptions.map(to_deactivate => classifier_as_subscription_object(to_deactivate, false))

    const new_subscriptions = updated_selected_classifiers
      // find selected classifiers not already in subscriptions
      .filter(selected => !_.some(subscriptions, subscribed => is_same_classifier(selected, subscribed)))
      .map(new_subscription => classifier_as_subscription_object(new_subscription, true))

    // some classifiers occur in multiple trees/ node levels in same tree so ignore duplicates
    const updated_subscriptions = active_subscriptions.concat(_.uniq(new_subscriptions, s => s.classifier_id))

    do_save_or_update_subscriptions([...new_subscriptions, ...subscriptions_to_deactivate])
      .then(() => {
        set_selected_classifiers(updated_selected_classifiers)
        set_subscriptions(updated_subscriptions)
        set_is_saving(false)
        set_subscriptions_updated(true)
      })
  }

  function update_subscription_with_threshold(classifier, updated_threshold) {
    set_is_saving(true)
    set_subscriptions_updated(false)

    const subscription_to_update = _.pick(classifier, CLASSIFIER_ID)
    if (updated_threshold === classifier.threshold) {
      return
    }
    const subscriptions_to_deactivate = get_updated_alert_thresholds(classifier.threshold, updated_threshold, false)
      .map(threshold => classifier_as_subscription_object(subscription_to_update, false, threshold))

    const subscriptions_to_activate = get_updated_alert_thresholds(classifier.threshold, updated_threshold, true)
      .map(threshold => classifier_as_subscription_object(subscription_to_update, true, threshold))

    const updated_subscriptions = subscriptions.filter(sub => !_.some(subscriptions_to_deactivate, deactivated => is_same_subscription(deactivated, sub)))
      .concat(subscriptions_to_activate)

    const updated_selected_classifiers = selected_classifiers.filter(classifier => _.some(updated_subscriptions, sub => is_same_classifier(sub, classifier)))

    do_save_or_update_subscriptions([...subscriptions_to_deactivate, ...subscriptions_to_activate])
      .then(() => {
        set_subscriptions(updated_subscriptions)
        set_selected_classifiers(updated_selected_classifiers)
        set_is_saving(false)
        set_subscriptions_updated(true)
      })
  }

  function get_updated_alert_thresholds(previous, current, is_activate) {
    if (current === MULTI_THRESHOLD) {
      return is_activate ? ALERT_THRESHOLD_OPTIONS.filter(option => !_.contains([MULTI_THRESHOLD, previous], option)) : []
    } else if (is_activate) {
      return previous === MULTI_THRESHOLD ? [] : [current]
    }
    return ALERT_THRESHOLD_OPTIONS.filter(option => !_.contains([MULTI_THRESHOLD, current], option))
  }

  function deactivate_one_subscription(classifier) {
    set_is_saving(true)
    set_subscriptions_updated(false)

    const [subscriptions_to_deactivate, active_subscriptions] = _.partition(subscriptions, subscription => is_same_classifier(subscription, classifier))
    const updated_selected_classifiers = selected_classifiers.filter(selected => !is_same_classifier(selected, classifier))

    do_save_or_update_subscriptions(subscriptions_to_deactivate.map(subscription => classifier_as_subscription_object(subscription, false)))
      .then(() => {
        set_selected_classifiers(updated_selected_classifiers)
        set_subscriptions(active_subscriptions)
        set_subscriptions_updated(true)
        set_is_saving(false)
      })
  }

  function do_save_or_update_subscriptions(subscriptions_to_update) {
    subscriptions_to_update.forEach(update => {
      track_subscriptions_event(`obj="classifier_alert" action="${update.active ? 'activate': 'deactivate'}_subscription"`)
    })
    return save_or_update_subscriptions(subscriptions_to_update)
      .catch(error => {
        set_subscriptions_save_error(error)
        set_is_saving(false)
        set_subscriptions_updated(false)
        throw error
      })
  }

  function toggle_show_config_controls() {
    set_show_config_controls(!show_config_controls)
    set_subscriptions_updated(false)
  }

  const has_classifiers = (classifier_groups.length > 0)
  const subscriptions_basket = subscriptions_to_basket_items()

  if (!has_old_weekly_classifier_alerts(user) || is_view_only_user(user)) {
    // no landscape alerts for read-only users
    // to avoid willful infringement they need to ensure they don't see other orgs' patents
    return (
      <PageNotFound/>
    )
  }

  if (subscriptions_fetch_error) {
    return (
      <ContainerFullWidth>
        <ErrorBody
          error={subscriptions_fetch_error}
          context='fetching active subscriptions'
        />
      </ContainerFullWidth>
    )
  }

  if (subscriptions_save_error) {
    return (
      <ErrorModal
        on_hide={() => this.setState({subscriptions_save_error: null})}
        error={subscriptions_fetch_error}
        context='saving subscriptions'
      />
    )
  }

  return (
    <div className={s.subscriptions_container}>
      <div className='d-lg-flex h-100'>
        <ClassifiersDisplayContainer className='order-lg-0'>
          <SubscriptionsNavigation current_page={CLASSIFIERS}/>
          <TechnologiesDisplay
             is_fetching={is_fetching_data}

             classifier_groups={classifier_groups}
             selected_classifier_group_id={selected_classifier_group_id}
             selected_classifiers={selected_classifiers}
             on_select_classifiers={update_selected_and_save_subscriptions}
             on_select_classifier_group={set_selected_classifier_group_id}

             is_subscription_display={true}
          />
          {!is_fetching_data && !has_classifiers &&
            <div className='order-lg-0 p-3'>
              No classifiers available for alerts on this Cipher account.
            </div>
          }
        </ClassifiersDisplayContainer>

        <ClassifiersBasketContainer className='order-lg-1'>
          <ClassifiersBasket
            selected_classifiers={subscriptions_basket}
            is_saving={is_saving}
            remove_from_basket={deactivate_one_subscription}

            show_config_controls={show_config_controls}
            toggle_show_config_controls={toggle_show_config_controls}
            configure_threshold_handler={(subscription, threshold) => update_subscription_with_threshold(subscription, threshold)}
            threshold_options={ALERT_THRESHOLD_OPTIONS}
            update_success={subscriptions_updated}

            heading_text='Active Subscriptions'
            empty_basket_text='Choose technologies from the menu to receive alerts when patent families are updated in Cipher.'
          />
        </ClassifiersBasketContainer>

      </div>
    </div>
  )
}

export default withUser(ClassifierAlertSubscriptions)