import React from 'react'
import cn from 'classnames'

import s from './NavTab.module.scss'

export const NavTab = ({on_click, is_active, disabled, title, className, children}) => {
  return (
    <div
      title={title}
      onClick={on_click}
      className={cn(
        'py-2 position-relative',
        s.nav_tab,
        {[s.nav_tab__active]: is_active},
        {[s.nav_tab__disabled]: disabled},
        className
      )}
    >
      {children}
      {is_active &&
        <div className={s.__active_marker}/>
      }
    </div>
  )
}

export const NavTabVertical = ({is_active, children, className}) => {
  return (
    <div className={cn('d-flex', className)}>
      <div className={cn(s.option_marker, { [s.option_marker__active]: is_active })}/>
      {children}
    </div>
  )
}

export const NavWrapper = ({ className, children }) => {
  return (
    <div className={cn('d-flex flex-row flex-nowrap', s.navigation_wrapper, className)}>{children}</div>
  )
}