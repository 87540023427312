import {
  ASSIGNEE_GROUPING,
  BENCHMARKING,
  CLASSIFIERS,
  KNN,
  MONITOR,
  PROJECT,
  REPORT,
  TRENDS
} from '../constants/paths.js'

import { CONTEXT as KNN_TRACKING_CONTEXT } from './knn_search.js'
import { get_hashed_id_with_salt } from './utils.js'
import { CLASSIFIER } from '../components/classifiers_editor/constants/classifier_paths.js'

const CLASSIFIER_PATHNAME_PREFIX = `${CLASSIFIERS}/${CLASSIFIER}/`
const PROJECT_REPORT_URL_PARAM = 'report'
const PROJECT_BENCHMARKING_URL_PARAM = 'benchmarking'

const CLASSIFIER_HASH_SALT = 'ONAlPf4FEQUJ9VL'

export function get_document_title(pathname) {
  if (pathname.startsWith(KNN)) return 'Classification: TechDiscovery'
  if (pathname.startsWith(REPORT)) return 'Classification: Report viewer'
  if (pathname.startsWith(BENCHMARKING)) return 'Classification: Benchmarking'
  if (pathname.startsWith(PROJECT)) return 'Classification: Project'
  if (pathname.startsWith(TRENDS)) return 'Classification: Trends'
  return 'Classification'
}

export function prepare_pathname_for_tracking(pathname) {
  if (pathname.startsWith(KNN)) return get_knn_pathname_with_hashed_id(pathname)
  if (pathname.startsWith(REPORT)) return get_report_pathname_with_hashed_id(pathname)
  if (pathname.startsWith(BENCHMARKING)) return get_benchmarking_deck_pathname_with_hashed_id(pathname)
  if (pathname.startsWith(CLASSIFIER_PATHNAME_PREFIX)) return get_classifier_pathname_with_hashed_id(pathname)
  if (pathname.startsWith(PROJECT)) return get_project_pathname_with_hashed_id(pathname)
  if (pathname.startsWith(TRENDS)) return get_trends_pathname_with_hashed_id(pathname)
  if (pathname.startsWith(ASSIGNEE_GROUPING)) return ASSIGNEE_GROUPING
  if (pathname.startsWith(MONITOR)) return MONITOR

  return pathname
}

function get_knn_pathname_with_hashed_id(pathname) {
  return get_pathname_with_hashed_id(pathname, {pathname_prefix: `${KNN}/`, hash_salt: KNN_TRACKING_CONTEXT})
}

function get_report_pathname_with_hashed_id(pathname) {
  const hash_salt = 'Ul9WyguUPJ0vR0Q'

  const alert_param = 'alert/'

  const report_pathname = get_pathname_with_hashed_id(pathname, {pathname_prefix: `${REPORT}/`, hash_salt })

  if (pathname.indexOf(alert_param) > -1) {
    const path_chunks = report_pathname.split(alert_param)

    const path_with_alert_id = `${path_chunks[0]}${alert_param}${get_hashed_id_with_salt(path_chunks[1], hash_salt)}`

    return path_with_alert_id
  }

  return report_pathname
}

function get_benchmarking_deck_pathname_with_hashed_id(pathname) {
  return get_pathname_with_hashed_id(pathname, {pathname_prefix: `${BENCHMARKING}/`, hash_salt: 'sqhaXa68LeyfTTF'})
}

function get_classifier_pathname_with_hashed_id(pathname) {
  return get_pathname_with_hashed_id(pathname, {pathname_prefix: CLASSIFIER_PATHNAME_PREFIX, hash_salt: CLASSIFIER_HASH_SALT})
}

function get_project_pathname_with_hashed_id(pathname) {
  const SALT = 'H2c2LUMP77zZbaE'
  const pathname_chunks = pathname.replace(`${PROJECT}/`, '').split('/')
  const id = pathname_chunks[0]

  if (!id) return pathname

  const report_id_idx = pathname_chunks.indexOf(PROJECT_REPORT_URL_PARAM) > -1 ? pathname_chunks.indexOf(PROJECT_REPORT_URL_PARAM) + 1 : -1
  const deck_id_idx = pathname_chunks.indexOf(PROJECT_BENCHMARKING_URL_PARAM) > -1 ? pathname_chunks.indexOf(PROJECT_BENCHMARKING_URL_PARAM) + 1 : -1
  const path = pathname_chunks.map((item, i) => {return (i === report_id_idx) || (i === deck_id_idx) ? get_hashed_id_with_salt(item, SALT) : item}).slice(1).join('/')

  return `${PROJECT}/${get_hashed_id_with_salt(id, SALT)}${path !== '' ? `/${path}` : ''}`
}

function get_trends_pathname_with_hashed_id(pathname) {
  return get_pathname_with_hashed_id(pathname, {pathname_prefix: `${TRENDS}/`, hash_salt: 'saBrT1C1Uf7zWOe'})
}

function get_pathname_with_hashed_id(pathname, {pathname_prefix, hash_salt}) {
  const pathname_chunks = pathname.replace(pathname_prefix, '').split('/')
  const id = pathname_chunks[0]

  if (!id) return pathname

  const path = pathname_chunks.slice(1).join('/')
  return `${pathname_prefix}${get_hashed_id_with_salt(id, hash_salt)}${path !== '' ? `/${path}` : ''}`
}

export function get_list_of_hashed_classifier_ids(classifier_ids) {
  return (classifier_ids || []).map(item => get_hashed_id_with_salt(item, CLASSIFIER_HASH_SALT).slice(0,10)).join(',')
}
