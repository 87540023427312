import { get_as_map } from '../utils/utils.js'

export const PATENT_LINK_AS_PDF_OPTION_ID    = 'pdf'
export const PATENT_LINK_AS_GOOGLE_OPTION_ID = 'google'
export const PATENT_LINK_AS_EPODOC_OPTION_ID = 'espacenet'

const PATENT_LINK_AS_PDF_OPTION     = { id: PATENT_LINK_AS_PDF_OPTION_ID, label: 'Classification' }
const PATENT_LINK_AS_GOOGLE_OPTION  = { id: PATENT_LINK_AS_GOOGLE_OPTION_ID, label: 'Google' }
const PATENT_LINK_AS_EPODOCS_OPTION = { id: PATENT_LINK_AS_EPODOC_OPTION_ID, label: 'Espacenet' }

export const PATENT_LINKS_OPTIONS = [PATENT_LINK_AS_PDF_OPTION, PATENT_LINK_AS_GOOGLE_OPTION, PATENT_LINK_AS_EPODOCS_OPTION]

export const PATENT_LINKS_OPTIONS_BY_ID = get_as_map(PATENT_LINKS_OPTIONS, 'id')

export const PATENT_LINK_OPTION_DEFAULT_ID = PATENT_LINK_AS_PDF_OPTION_ID