import React, { useState } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'
import cn from 'classnames'
import _ from 'underscore'

import ScrollableList from '../../widgets/ScrollableList.js'
import ClearableSearchInput from '../../widgets/ClearableSearchInput.js'

import s from './ClickthroughItemSelector.module.scss'

const ALL_LIST_ORGS_LABEL = 'All company list orgs'

const ClickthroughItemSelector = ({clickthrough_item, org_lists, selected_org_lists, on_clickthrough_item_change}) => {
  const [dropdown_open, set_dropdown_open] = useState(false)
  const [search_input, set_search_input] = useState(null)

  const selected = (clickthrough_item && !clickthrough_item.is_target_org) ? clickthrough_item.items[0] : null

  function is_search_match(name) {
    return !search_input || name.toLowerCase().indexOf(search_input.toLowerCase()) !== -1
  }

  const list_orgs_filtered = (org_lists || [])
    .filter(list => !list.is_target_org && _.contains(selected_org_lists, list.id))
    .map(list => ({...list, portfolios: list.portfolios.filter(org => is_search_match(org.name))}))
    .filter(list => list.portfolios && list.portfolios.length > 0)

  const none_match = !is_search_match(ALL_LIST_ORGS_LABEL) && list_orgs_filtered.length < 1

  function toggle() {
    set_dropdown_open(!dropdown_open)
    set_search_input(null)
  }

  function set_new_clickthrough_item(list_org) {
    const clickthrough_item_updated = list_org ? {
      key: 'PFTP.portfolio_id',
      items: [list_org],
      is_target_org: false
    } : null
    on_clickthrough_item_change(clickthrough_item_updated)
  }

  return (
    <Dropdown
      isOpen={dropdown_open}
      toggle={toggle}
    >
      <DropdownToggle
        tag='div'
        className={
          cn(
            'px-1 px-sm-2 me-3',
            s.dropdown_toggle,
            {[s.dropdown_toggle__open]: dropdown_open}
          )
        }
      >
        Org [{selected ? selected.name : ALL_LIST_ORGS_LABEL}]
      </DropdownToggle>
      <DropdownMenu
        end={true}
        className={s.dropdown_menu}
        container='body'
      >
        <ClearableSearchInput
          containerClassName={'m-1'}
          inputClassName={s.search_input}
          value={search_input || ''}
          auto_focus={true}
          handle_change={set_search_input}
          show_clear={true}
          placeholder={''}
        />
        {none_match &&
          <DropdownItem disabled>No matches found</DropdownItem>
        }
        <ScrollableList>
          {is_search_match(ALL_LIST_ORGS_LABEL) &&
            <>
              <DropdownItem
                className={s.dropdown_item}
                onClick={() => set_new_clickthrough_item(null)}
                active={!selected}
              >
                {ALL_LIST_ORGS_LABEL}
              </DropdownItem>
              { list_orgs_filtered.length > 0 &&
                <DropdownItem divider/>
              }
            </>
          }
          {list_orgs_filtered
            .map(list => {
              return (
                <div key={list.id}>
                  <DropdownItem header>
                    {list.name}
                  </DropdownItem>
                  { list.portfolios.map(org => {
                    return (
                      <DropdownItem
                        className={s.dropdown_item}
                        key={org.id}
                        active={selected && org.id === selected.id}
                        onClick={() => set_new_clickthrough_item(org)}
                      >
                        {org.name}
                      </DropdownItem>
                    )
                  })}
                </div>
              )
            })}
        </ScrollableList>
      </DropdownMenu>
    </Dropdown>
  )
}

export default ClickthroughItemSelector