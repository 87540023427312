import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import ContainerFixedWidth from './ContainerFixedWidth.js'
import { run_manual_clustering_report } from '../utils/report_builder_utils.js'
import { get_patent_upload_as_portfolio_item } from '../model/portfolio_basket.js'
import Spinner from './widgets/Spinner.js'
import ErrorModal from './ErrorModal.js'
import { track_report_builder_event } from '../utils/tracking_utils.js'
import { withUser } from './UserContext.js'
import { is_creator } from '../utils/user_permissions.js'
import CustomClusteredUpload from './patent_upload/custom_clustered/CustomClusteredUpload.js'
import ReportBuilderNoAccessPanel from './builder/ReportBuilderNoAccessPanel.js'

const NAME_DELIMITER = '/'

const CustomUploadReportBuilder = ({user}) => {
  const [show_spinner, set_show_spinner] = useState(false)
  const [report_id, set_report_id] = useState(null)
  const [report_in_progress, set_report_in_progress] = useState(false)

  const [build_report_error, set_build_report_error] = useState(null)

  useEffect(() => {
    document.title = 'Classification: Custom clustered report'
  }, [])

  function create_report({name, portfolios: parsed_input_portfolios, clusters: parsed_input_technologies}) {

    set_show_spinner(true)
    set_report_in_progress(true)
    track_report_builder_event('action="build_report" report_type="custom_clustered" context="builder"')

    const portfolios = (parsed_input_portfolios || []).map(item => {
      const {name, pat_fam_ids} = item || {}

      return get_patent_upload_as_portfolio_item({name, pat_fam_ids, group_by_owner: false})
    })

    const technologies = (parsed_input_technologies || []).map(item => {
      const {name: technology_name, pat_fam_ids} = item
      
      const name_split_into_path = technology_name.split(NAME_DELIMITER)
      const path = name_split_into_path.length > 1 ? name_split_into_path : []
      const name = name_split_into_path[name_split_into_path.length - 1]

      return {
        name,
        pat_fam_ids,
        path
      }
    })

    run_manual_clustering_report(name, portfolios, technologies)
      .then((external_report_id) => {
        set_report_id(external_report_id)
        set_show_spinner(false)
      })
      .catch((error) => {
        set_build_report_error(error)
        set_show_spinner(false)
        throw error
      })
  }

    if (!is_creator(user)) {
      return (
        <ReportBuilderNoAccessPanel/>
      )
    }


    if (build_report_error) {
      return(
        <ErrorModal
          on_hide={() => set_build_report_error(null)}
          error={build_report_error}
          context='building custom clustered report'
        />
      )
    }


    if (report_id) {
      return (<Redirect to={`/report/${report_id}`} />)
    }

    return (
      <ContainerFixedWidth>
        {show_spinner && report_in_progress &&
        <div className='text-center'>
          <Spinner/>
          <div>Building report...</div>
        </div>
        }

        {!show_spinner &&
          <CustomClusteredUpload
            parsed_results_action_handler={create_report}
            results_action_btn_label='Create report'
          />
        }
      </ContainerFixedWidth>
    )

}

export default withUser(CustomUploadReportBuilder)