import React, { useEffect, useState } from 'react'
import GitInfo from 'react-git-info/macro'

import { get_server_version } from '../utils/version_utils.js'
import { is_cipher_engineering } from '../utils/user_permissions.js'

import ContainerFullWidth from './ContainerFullWidth.js'
import { withUser } from './UserContext.js'

const client_git_info = GitInfo()

const server_data_keys = [
  'Aistemos-FrontendService-Version',
  'Aistemos-Software-Version',
  'Aistemos-Application-Id',
  'Aistemos-Data-Version',
  'Aistemos-AWS-Env'
]

const About = ({ user }) => {

  const is_engineering = is_cipher_engineering(user)

  const [server_version_data, set_server_version_data] = useState(null)
  const [is_fetching, set_is_fetching] = useState(true)
  const [error, set_error] = useState(null)

  useEffect(() => {
    get_server_version()
      .then(version_data => {
        set_is_fetching(false)
        set_server_version_data(version_data)
      })
      .catch(err => {
        set_is_fetching(false)
        set_error(err)
      })
  }, [])

  const {branch, tags=[], commit} = client_git_info || {}
  const {date, hash, message} = commit || {}

  const server_data =  (server_version_data || [])[0] || {}

  return (
    <ContainerFullWidth className='py-3'>
      <div className='d-sm-flex'>
        <div className='w-100 w-sm-50 pe-2'>
          <h5>Client</h5>
          <div className='mb-2'>
            <label>Branch</label>
            <div>{branch}</div>
          </div>
          {(tags || []).length !== 0 &&
            <div className='mb-2'>
              <label>Tags</label>
              <div>{tags.join(', ')}</div>
            </div>
          }
          <div className='mb-2'>
            <label>Date</label>
            <div>{date}</div>
          </div>
          <div className='mb-2'>
            <label>Hash</label>
            <div>{hash}</div>
          </div>

          {is_engineering &&
            <div className='mb-2'>
              <label>Message</label>
              <div>{message}</div>
            </div>
          }
        </div>

        <div className='w-100 w-sm-50 ps-0 ps-sm-2'>
          <h5>Server</h5>
            {is_fetching && <span>fetching...</span> }
            {error && <span>(there was an error)</span>}
            {server_version_data &&
              <div>
                {server_data_keys.map(key => {

                  const key_to_display = key.replace('Aistemos-', '').split('-').join(' ')

                  return (
                    <div className='mb-2' key={key}>
                      <label>{key_to_display}</label>
                      <div>{server_data[key]}</div>
                    </div>
                  )
                })}
              </div>
            }
        </div>

      </div>
    </ContainerFullWidth>
  )
}

export default withUser(About)