import React, { useEffect, useState } from 'react'

import { withRouter } from 'react-router-dom'
import { on_click_from_help_link, get_help_url } from '../../utils/help_utils.js'


const HelpLink = (
  {
    location,
    help_url: help_url_prop,
    className
  }) => {

  const [help_url, set_help_url] = useState(get_help_url())

  useEffect(() => {
    const url = get_help_url()
    set_help_url(url)
  }, [location])

  const help_url_to_display = help_url_prop || help_url

  return (
    <a className={className} href={help_url_to_display} target='_blank' rel='noreferrer' title='Help' onClick={() => on_click_from_help_link(help_url_to_display)}>Help</a>
  )
}

export default withRouter(HelpLink)