import React from 'react'
import cn from 'classnames'

import AutoResizeTextArea from './AutoResizeTextArea.js'

const SearchBar = (
  {
    search_input_ref,
    search_input,
    on_change_search_input,
    on_clear,
    do_search,
    on_focus,
    on_blur,
    is_fetching,
    is_search_valid,
    no_enter_trigger,
    placeholder,
    autofocus,

    className,
    textAreaClassName,
    clearClassName,
}) => {
  return (
    <div className={cn('d-flex', className)}>
      <AutoResizeTextArea
        ref={search_input_ref}
        className={cn(textAreaClassName)}
        containerClassName='w-100'
        value={search_input}
        on_change={on_change_search_input}
        on_focus={on_focus}
        on_blur={on_blur}
        on_key_down={(e) => {
          if (no_enter_trigger) {
            return
          }

          const submit_pressed = e.key === 'Enter' && !e.shiftKey && !e.ctrlKey
          if (submit_pressed && !is_search_valid) {
            e.preventDefault()
            return
          }

          if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey) {
            // Only do search for enter
            // i.e. shift-enter and control-enter add new line
            e.preventDefault()
            do_search()
          }
        }}
        is_clearable={true}
        on_clear={on_clear}
        disabled={is_fetching}
        placeholder={placeholder || 'Enter search query here'}
        auto_focus={autofocus}
        clearClassName={clearClassName}
      />
    </div>
  )
}

export default SearchBar