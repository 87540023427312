import React, { useState, useEffect, useCallback } from 'react'
import _ from 'underscore'

import { Heading } from './FamilyDetailsLabel.js'
import Spinner from '../widgets/Spinner.js'
import ImageSelector from './ImagesSelector.js'
import ImagesModal from './ImagesModal.js'

import { active_element_is_input } from '../../utils/keyboard_shortcuts/keyboard_utils.js'
import { NavigationAnchor } from './FamilyDetailsNavigation.js'

const I_KEY = 73
const DELETE_KEY = 46
const BACKSPACE_KEY = 8

const ImagesView = ({ images_block_ref, images, show_spinner, error, allow_public_access }) => {
  const [images_meta, set_images_meta] = useState(null)
  const [selected_image_idx, set_selected_image_idx] = useState(null)

  const on_keyup = useCallback((event) => {
    const { keyCode } = event

    const active_el = document.activeElement
    if (active_el && active_element_is_input(active_el)) {
      // User is writing in a text field (maybe some other modal), so do nothing
      return
    }

    if (_.contains([I_KEY], keyCode)) {
      if (images == null || images.length === 0) {
        return
      }

      event.preventDefault()

      if (selected_image_idx != null) {
        // Images modal already open, so close it
        hide()
        return
      }

      // Open images modal
      on_click_from_image(0)
      return
    }

    if (_.contains([DELETE_KEY, BACKSPACE_KEY], keyCode)) {
      if (selected_image_idx == null) {
        return
      }
      event.preventDefault()
      window.setTimeout(() => {
        hide() // workaround to block container keyboard handler (modal will still be open when it fires, so it will be ignored)
      }, 100)
      return
    }
  }, [images, selected_image_idx])

  useEffect(() => {
    document.addEventListener('keyup', on_keyup)
    return () => {
      document.removeEventListener('keyup', on_keyup)
    }
  }, [on_keyup])

  function on_click_from_image(idx) {
    set_selected_image_idx(idx)
  }

  function hide() {
    set_selected_image_idx(null)
  }

  function set_meta(meta) {
    const updated_images_meta = JSON.parse(JSON.stringify(images_meta || []))
    updated_images_meta[selected_image_idx] = meta
    set_images_meta(updated_images_meta)
  }

  const has_images = (images != null) && (images.length > 0)

  return (
    <div className='position-relative mb-3 w-100'>
      {(selected_image_idx != null) &&
        <ImagesModal
          images={images}
          images_meta={images_meta || []}
          selected_image_idx={selected_image_idx}
          on_image_click_handler={on_click_from_image}
          on_image_transform_handler={set_meta}
          on_hide_handler={hide}
          allow_public_access={allow_public_access}
        />
      }

      <NavigationAnchor ref={images_block_ref} />

      <Heading>Images<span>{has_images ? ` (${images.length})` : ''}</span></Heading>

      {show_spinner &&
        <Spinner />
      }

      {!show_spinner && has_images &&
        <div className='w-100'>
          <ImageSelector
            images={images}
            on_click_handler={on_click_from_image}
            selected_image_idx={selected_image_idx}
          />
        </div>
      }

      {!show_spinner && !has_images &&
        <div>(none)</div>
      }

      {error &&
        <div>There was an error fetching images</div>
      }

    </div>
  )
}

export default ImagesView