import React, { Component } from 'react'
import _ from 'underscore'
import cn from 'classnames'
import { Input } from 'reactstrap'

import ErrorBody from '../ErrorBody.js'
import Spinner from '../widgets/Spinner.js'
import TextLink from '../widgets/TextLink.js'
import { PrimaryButton, TertiaryButton } from '../widgets/Button.js'
import { RadiobuttonWithLabel } from '../widgets/RadiobuttonWithLabel.js'
import CheckboxStatic from '../widgets/CheckboxStatic.js'
import {
  PlusIcon,
  InfoIcon,
  SimpleErrorIcon,
  MoreVertIcon,
  ExternalLinkIcon, TrashIcon, EditIcon
} from '../widgets/IconSet.js'
import { SearchableDropdown } from '../widgets/SearchableDropdown.js'
import PageNotFound from '../PageNotFound.js'
import { withUser } from '../UserContext.js'
import {
  fetch_all_active_keycloak_groups,
  create_new_keycloak_user_group,
  fetch_roles_for_keycloak_group,
  is_valid_email_address,
  is_valid_group_name,
  fetch_users_in_keycloak_group,
  fetch_all_keycloak_groups,
  fetch_all_users_in_keycloak_group,
  fetch_child_roles,
  get_all_child_role_ids,
  fetch_keycloak_group
} from '../../utils/user_group_utils.js'
import { ACCOUNT_TYPE_ROLES, KEYCLOAK_ROLES_TO_EXCLUDE, bulk_create_new_users_in_group } from '../../utils/user_management_utils.js'
import { IS_TRIAL, IS_LITE, IS_SUBSCRIBER, has_view_admin_page, has_view_create_users_page, is_aistemos } from '../../utils/user_permissions.js'
import { ADMIN } from '../../constants/paths.js'
import ConfirmOrCancel from '../widgets/ConfirmOrCancel.js'
import RemoveRoleModal from './RemoveRoleModal.js'
import AddRoleModal from './AddRoleModal.js'
import RoleRow from './RoleRow.js'
import UserRolesModal from './UserRolesModal.js'
import EditUserDetailsModal from './EditUserDetailsModal.js'
import SendPasswordEmailModal from './SendPasswordEmailModal.js'
import EditGroupWAMDetailsModal from './EditGroupWAMDetailsModal.js'
import { get_hashed_id_with_salt } from '../../utils/utils.js'
import { track_support_event } from '../../utils/tracking_utils.js'
import AdminPageWrapper from './AdminPageWrapper.js'
import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'
import { Table } from '../widgets/Table.js'
import PopoverAsDropdown, { PopoverAsDropdownItem } from '../widgets/PopoverAsDropdown.js'

import s from './UserManagement.module.scss'

const EMAIL_FIELD = 'email'
const FIRST_NAME_FIELD = 'first_name'
const LAST_NAME_FIELD = 'last_name'

const NEW_USER_ROWS_BATCH_SIZE = 5
const MAX_DROPDOWN_HEIGHT = 500

class UserManagement extends Component {
  constructor(props) {
    super(props)

    this.state = {
      is_fetching_groups: true,
      is_saving_new_user_group: false,
      is_creating_users: false,

      is_entering_new_group_name: false,
      is_mark_all_as_lite: false,

      is_show_disabled: false,

      user_groups: null,
      selected_group: null,
      selected_group_roles: null,
      selected_group_details: null,
      group_role_id_to_child_roles: null,
      new_group_name: null,
      new_group_role: null,
      new_user_rows: null,
      users_created: null,
      users_not_created: null,

      group_role_to_delete: null,
      is_show_add_group_role_modal: false,
      is_show_edit_group_wam_details_modal: false,

      users_in_group: null,
      is_fetching_users_in_group: false,

      user_to_show_roles_for: null,
      user_to_show_details_for: null,
      user_to_send_password_reset_for: null,

      error_creating_group: null,
      error_fetching_data: null,
      error_creating_users: null,
    }
  }

  componentDidMount() {
    this.fetch_keycloak_groups()
  }

  fetch_keycloak_groups() {
    this.setState({is_fetching_groups: true, user_groups: null, new_user_rows: null, selected_group: null, selected_group_roles: null, selected_group_details: null, users_in_group: null})

    Promise.resolve()
    .then(() => {
      return this.state.is_show_disabled ? fetch_all_keycloak_groups() : fetch_all_active_keycloak_groups()
    })
    .catch(error => {
      this.setState({error_fetching_data: error, is_fetching_groups: false})
      throw error
    })
    .then((group_data) => {
      const user_groups = _.sortBy(group_data, g => g.name.toLowerCase())
      const new_user_rows = this.get_new_user_rows_batch()
      this.setState({
        user_groups,
        new_user_rows,
        is_fetching_groups: false
      })
    })
  }

  get_new_user_rows_batch() {
    return _.range(0, NEW_USER_ROWS_BATCH_SIZE).map(() => this.get_empty_user_row())
  }

  get_empty_user_row() {
    const {is_mark_all_as_lite} = this.state
    return {[EMAIL_FIELD]: null, [FIRST_NAME_FIELD]: null, [LAST_NAME_FIELD]: null, [IS_LITE]: is_mark_all_as_lite}
  }

  select_group(group_id) {
    const selected_group = _.find(this.state.user_groups, g => g.id === group_id)

    this.setState({
      selected_group,
      users_created: null,
      users_not_created: null,
      error_creating_group: null,
      error_fetching_data: null,
      error_creating_users: null,
      users_in_group: null,
    }, () => {
      this.on_change_group(selected_group.id)
    })
  }

  show_new_group_name_input() {
    this.setState({
      is_entering_new_group_name: true,
      selected_group: null,
      selected_group_roles: null,
      selected_group_details: null,
      group_role_id_to_child_roles: null,
      users_in_group: null,
    })
  }

  hide_new_group_name_input() {
    this.setState({
      is_entering_new_group_name: false,
      new_group_name: null,
      new_group_role: null,
      selected_group: null,
      selected_group_roles: null,
      selected_group_details: null,
      group_role_id_to_child_roles: null,
      error_creating_group: null,
      users_in_group: null,
    })
  }

  on_change_new_group_name(e) {
    this.setState({new_group_name: e.target.value})
  }

  new_group_name_is_valid() {
    return is_valid_group_name(this.state.new_group_name)
  }

  create_new_user_group() {
    const {new_group_name, new_group_role} = this.state

    const is_subscriber = new_group_role === IS_SUBSCRIBER

    this.setState({is_saving_new_user_group: true, error_creating_group: null})
    create_new_keycloak_user_group(new_group_name, is_subscriber)
      .catch(error => {
        this.setState({error_creating_group: error, is_saving_new_user_group: false})
        throw error
      })
      .then(new_group => {
        const {user_groups} = this.state

        this.setState({
          user_groups: [new_group, ...user_groups],
          selected_group: new_group,
          new_group_name: null,
          new_group_role: null,
          users_created: null,
          users_not_created: null,
          is_entering_new_group_name: false,
          is_saving_new_user_group: false,
          error_creating_group: null,
          error_fetching_data: null,
          error_creating_users: null,
          users_in_group: null,
        }, () => {
          this.on_change_group(new_group.id)
        })

      })
  }

  on_change_group(group_id) {
    this.setState({ is_fetching_users_in_group: true })

    Promise.all([
      fetch_keycloak_group(group_id),
      fetch_roles_for_keycloak_group(group_id),
      this.state.is_show_disabled ? fetch_all_users_in_keycloak_group(group_id) : fetch_users_in_keycloak_group(group_id)
    ])
    .then(([selected_group_details, selected_group_roles, users_in_group]) => {
      return fetch_child_roles(selected_group_roles)
      .then(group_role_id_to_child_roles => {
        return [selected_group_details, selected_group_roles, group_role_id_to_child_roles, users_in_group, ]
      })
    })
    .catch(error => {
      this.setState({error_fetching_data: error})
      throw error
    })
    .then(([selected_group_details, selected_group_roles, group_role_id_to_child_roles, users_in_group]) => {
      this.setState({selected_group_details, selected_group_roles, group_role_id_to_child_roles, users_in_group})
    })
    .finally(() => this.setState({ is_fetching_users_in_group: false }))
  }

  on_change_new_user_field(index, field, e) {
    const {new_user_rows} = this.state
    // strip email address of spaces as these can cause keycloak weirdness
    const updated_value = field === EMAIL_FIELD ? e.target.value.replace(/\s+/g, '') : e.target.value
    const updated_user_row = {...new_user_rows[index], [field]: updated_value}
    this.setState({new_user_rows: [...new_user_rows.slice(0, (index)), updated_user_row, ...new_user_rows.slice(index+1)]})
  }

  on_change_new_user_is_lite(index, is_lite) {
    const {new_user_rows} = this.state
    const updated_user_row = {...new_user_rows[index], [IS_LITE]: !is_lite}
    this.setState({new_user_rows: [...new_user_rows.slice(0, (index)), updated_user_row, ...new_user_rows.slice(index + 1)]})
  }

  on_clear_new_user_row(index) {
    const {new_user_rows} = this.state
    this.setState({new_user_rows: [...new_user_rows.slice(0, (index)), this.get_empty_user_row(), ...new_user_rows.slice(index + 1)]})
  }

  on_change_all_users_are_lite() {
    const {is_mark_all_as_lite} = this.state
    if (!is_mark_all_as_lite) {
      const new_user_rows = this.state.new_user_rows.map(user_row => ({...user_row, [IS_LITE]: true}))
      this.setState({new_user_rows, is_mark_all_as_lite: true})
    } else {
      const new_user_rows = this.state.new_user_rows
        .map(user_row => this.is_not_populated(user_row) ? ({...user_row, [IS_LITE]: false}) : user_row)
      this.setState({new_user_rows, is_mark_all_as_lite: false})
    }
  }

  is_not_populated(user_row) {
    return !user_row[EMAIL_FIELD] && !user_row[FIRST_NAME_FIELD] && !user_row[LAST_NAME_FIELD]
  }

  new_user_row_is_valid(user_row) {
    return is_valid_email_address(user_row[EMAIL_FIELD])
  }

  get_populated_new_user_rows() {
    return _.filter(this.state.new_user_rows, user_row => !this.is_not_populated(user_row) )
  }

  can_create_new_users() {
    if (!this.state.selected_group) {
      return false
    }
    const new_users = this.get_populated_new_user_rows()
    return new_users && new_users.length > 0 && !_.some(new_users, user_row => !this.new_user_row_is_valid(user_row))
  }

  create_new_users() {
    const new_users = this.get_populated_new_user_rows()
    const {selected_group } = this.state

    this.setState({is_creating_users: true})

    const creator = this.props.user
    const { email: created_by_email } = creator
    const { name: group_name } = selected_group
    const creator_is_in_group_aistemos = is_aistemos(creator)
    const created_by_email__clean = creator_is_in_group_aistemos ? created_by_email : get_hashed_id_with_salt(created_by_email, 'EMAIL')

    bulk_create_new_users_in_group(new_users, selected_group.id)
      .catch(error => {
        this.setState({error_creating_users: error, is_creating_users: false})
        throw error
      })
      .then(created_user_results => {
        const results = _.groupBy(created_user_results, 'success') // split successfully created users from any errors
        this.setState({users_created: results['true'], users_not_created: results['false'], is_creating_users: false})

        // Create events for Matomo
        const event_strings = new_users.map(user => {
          const { email, is_lite } = user
          const email_hash = get_hashed_id_with_salt(email, 'EMAIL')
          return `action="account_creation" created_by="${created_by_email__clean}" group="${group_name}" email_hash="${email_hash}" is_lite="${is_lite}"`
        })

        // Send events to Matomo
        event_strings.forEach(event_string => {
          track_support_event(event_string)
        })
      })
  }

  clear_new_users_form() {
    this.setState({new_user_rows: this.get_new_user_rows_batch()})
  }

  add_more_new_user_fields() {
    const {new_user_rows} = this.state
    this.setState({new_user_rows: [...new_user_rows, ...this.get_new_user_rows_batch()]})
  }

  render_user_input_fields() {
    const {new_user_rows, selected_group} = this.state

    return new_user_rows.map((user_row, i) =>
      (<div className='d-flex py-2' key={i}>
        <span className={s.user_field_label}>Email:</span>
        <Input
          value={user_row[EMAIL_FIELD] || ''}
          onChange={this.on_change_new_user_field.bind(this, i, EMAIL_FIELD)}
          disabled={!selected_group}
          className={s.user_field}
          invalid={!this.is_not_populated(user_row) && !this.new_user_row_is_valid(user_row)}
          autoComplete='off'
        />
        <span className={cn('ms-3', s.user_field_label)}>Lite user:</span>
        <CheckboxStatic
          is_checked={user_row[IS_LITE]}
          onClick={this.on_change_new_user_is_lite.bind(this, i, user_row[IS_LITE])}
          className='m-1'
          is_disabled={!selected_group}
        />
        <span className={cn('ms-3', s.user_field_label)}>First name:</span>
        <Input
          value={user_row[FIRST_NAME_FIELD] || ''}
          onChange={this.on_change_new_user_field.bind(this, i, FIRST_NAME_FIELD)}
          disabled={!selected_group}
          className={cn(s.user_field, s.user_field_name)}
          autoComplete='off'
        />
        <span className={cn('ms-3', s.user_field_label)}>Last name:</span>
        <Input
          value={user_row[LAST_NAME_FIELD] || ''}
          onChange={this.on_change_new_user_field.bind(this, i, LAST_NAME_FIELD)}
          disabled={!selected_group}
          className={cn(s.user_field, s.user_field_name)}
          autoComplete='off'
        />
        <div className="mt-1 ms-1">
          <TextLink
            onClick={this.on_clear_new_user_row.bind(this, i)}
            className='px-1'
            title='Cancel'
            disable={!this.new_user_row_is_valid(user_row)}
          >
            <TrashIcon />
          </TextLink>
        </div>
      </div>)
    )
  }

  render_users_not_created() {
    const {users_not_created} = this.state
    return users_not_created.map((user, i) => (
      <tr key={i}>
        <td className='pe-5'>{user.email}</td>
        <td>Error: {user.error.errorMessage ? user.error.errorMessage : JSON.stringify(user.error)}</td>
      </tr>
    ))
  }

  render_users_created() {
    const {users_created} = this.state
    return users_created.map((user, i) => (
      <div key={i}>
        {user.email}
      </div>
    ))
  }

  render() {
    if (!has_view_admin_page(this.props.user) && !has_view_create_users_page(this.props.user)) {
      // shouldn't be possible, but I suppose you can't be too careful...
      return (<PageNotFound location={ADMIN} />)
    }

    const {user_groups, selected_group, new_group_name, new_group_role, is_entering_new_group_name, is_mark_all_as_lite, is_show_disabled,
      selected_group_roles, selected_group_details, group_role_id_to_child_roles, is_saving_new_user_group, is_creating_users, users_created, users_not_created,
      new_user_rows, is_fetching_groups, error_creating_group, error_fetching_data, error_creating_users,
      group_role_to_delete, is_show_add_group_role_modal, is_show_edit_group_wam_details_modal, users_in_group, user_to_show_roles_for, user_to_show_details_for, user_to_send_password_reset_for, is_fetching_users_in_group
    } = this.state

    // todo extract out org selector for reuse?

    const selected_group_roles__clean = selected_group_roles ? selected_group_roles.filter(role => !KEYCLOAK_ROLES_TO_EXCLUDE.includes(role.name)) : null

    const group_child_role_ids = group_role_id_to_child_roles ? get_all_child_role_ids(group_role_id_to_child_roles) : []

    const group_contains_is_xxx_role = selected_group_roles ? _.some(selected_group_roles, (role) => _.contains(ACCOUNT_TYPE_ROLES, role.name)) : false

    const { attributes: group_attributes } = selected_group_details || {}

    const wam_customer_id = group_attributes && group_attributes['customer_id'] && group_attributes['customer_id'].length ? group_attributes['customer_id'][0] : undefined

    return (
      <AdminPageWrapper page_name='User management'>

        <div className='d-flex justify-content-end'>
          <CheckboxAndLabel
            is_checked={is_show_disabled}
            label='Show disabled users/groups'
            on_click={() => {
              this.setState({is_show_disabled: !is_show_disabled}, () => {
                this.fetch_keycloak_groups()
              })
            }}
          />
        </div>

        <div className={cn( 'w-auto', 'ms-0')}>

          <h5>Group</h5>

          <div className='d-flex mb-2'>
            <span className='my-auto me-1'>Select a group from the list or </span>
            <TertiaryButton
              onClick={this.show_new_group_name_input.bind(this)}
            >
              <PlusIcon/>
              <span className='ms-1'>Create new group</span>
            </TertiaryButton>
          </div>

          {is_fetching_groups &&
            <Spinner/>
          }

          {error_fetching_data &&
            <ErrorBody
              error={error_fetching_data}
              context={'fetching keycloak data'}
            />
          }

          {error_creating_group &&
            <ErrorBody
              error={error_creating_group}
              context={'creating a new group on keycloak'}
            />
          }
          {error_creating_users &&
            <ErrorBody
              error={error_creating_users}
              context={'creating new users on keycloak'}
            />
          }

          {user_groups &&
            <>
              {!is_entering_new_group_name &&
                <div className='d-flex'>
                  <div className='d-flex'>
                    <SearchableDropdown
                      label='Select group'
                      selected_value={selected_group ? selected_group.name : null}
                      items={user_groups || []}
                      on_change_handler={this.select_group.bind(this)}
                      max_height={MAX_DROPDOWN_HEIGHT}
                      enable_search={true}
                      className={s.group_selector_input}
                    />
                    {is_fetching_users_in_group &&
                      <div className='ms-1 my-auto'>
                        <Spinner size='sm' />
                      </div>
                    }
                  </div>

                  {!is_fetching_users_in_group && selected_group_roles__clean &&
                    <div className={cn('ms-3 p-2', s.group_roles_container)}>
                      <label>Roles for group {selected_group.name}</label>

                      {selected_group_roles__clean.length === 0 &&
                        <div className={cn('text-muted')}>No roles to display</div>
                      }

                      {selected_group_roles__clean.map((role, i) => {
                        return (
                          <RoleRow
                            key={i}
                            role={role}
                            id_to_child_roles={group_role_id_to_child_roles}
                            on_remove={role => this.setState({group_role_to_delete: role})}
                          />
                      )})}

                      <TertiaryButton
                        size='sm'
                        className='mt-3'
                        onClick={() => {this.setState({ is_show_add_group_role_modal: true })}}
                        disable={!selected_group}
                      >
                        <PlusIcon/>
                        <span className='ms-1'>Add role</span>
                      </TertiaryButton>

                      {!group_contains_is_xxx_role && (
                        <div className='mt-4'>
                          <div className={cn('d-flex')}>
                            <span className={cn(s.error_icon)}><SimpleErrorIcon /></span>
                            <p className='ms-1 text-bold'>
                              Please set one of the following:
                            </p>
                          </div>
                          <ul>
                            {ACCOUNT_TYPE_ROLES.map((role, i) => (
                              <li key={i}>{role}</li>
                            ))}
                          </ul>
                        </div>
                      )}

                    </div>
                  }

                  {!is_fetching_users_in_group && selected_group_roles__clean &&
                    <div className={cn('ms-3 p-2', s.group_roles_container)}>
                      <div className='mb-1 d-flex'>
                        <div className={cn('text-endbold')}>WAM</div>
                        <TextLink
                            element='a'
                            className='ms-auto'
                            href='https://mylexis.lexisnexis.com/mylexis/search'
                            target='_blank'
                            rel='noopener noreferrer'
                            title={'Open MyLexis (WAM) in a new tab'}
                          >                    
                            <ExternalLinkIcon />
                          </TextLink>
                      </div>
                      <div>
                        customer_id:
                        {wam_customer_id &&
                          <span className={cn('ms-2 font-italic', s.wam_customer_id)}>{wam_customer_id}</span>
                        }
                        {!wam_customer_id &&
                          <span className={cn('ms-2 font-italic', s.wam_customer_id, s.wam_customer_id__not_set)}>(not set)</span>
                        }
                        <TextLink
                          className={cn('ms-2')}
                          onClick={() => this.setState({ is_show_edit_group_wam_details_modal: true })}
                          no_decoration
                        >
                          <EditIcon />
                        </TextLink>
                      </div>
                    </div>
                  }
                </div>
              }

              {is_entering_new_group_name &&
                <div className='p-4'>
                  <div className={cn('d-flex')}>
                    <div className={s.group_selector_input}>
                      <Input
                        value={new_group_name || ''}
                        onChange={this.on_change_new_group_name.bind(this)}
                        invalid={!this.new_group_name_is_valid()}
                        placeholder={`Enter org name and select 'Trial' or 'Subscriber'`}
                        autoFocus
                        autoComplete='off'
                      />
                    </div>
                    <div className='py-2'>

                      {!is_saving_new_user_group &&
                        <ConfirmOrCancel
                          is_valid={this.new_group_name_is_valid() && new_group_role}
                          on_submit={this.create_new_user_group.bind(this)}
                          on_cancel={this.hide_new_group_name_input.bind(this)}
                          className='ps-1'
                        />
                      }

                      {is_saving_new_user_group &&
                        <Spinner/>
                      }

                    </div>
                  </div>

                  {!is_saving_new_user_group &&
                    <div className='d-flex mt-2 ps-2'>
                      <div className='me-3'>
                        <RadiobuttonWithLabel
                          is_checked={new_group_role === IS_TRIAL}
                          on_click={() => this.setState({new_group_role: IS_TRIAL})}
                          label='Trial'

                        />
                      </div>
                      <RadiobuttonWithLabel
                        is_checked={new_group_role === IS_SUBSCRIBER}
                        on_click={() => this.setState({new_group_role: IS_SUBSCRIBER})}
                        label='Subscriber'
                      />
                    </div>
                  }

                </div>
              }
            </>
          }
        </div>

        {!is_fetching_users_in_group && new_user_rows && selected_group &&
          <div className='mt-3'>
            <h5>{`Add new users to group ${selected_group.name}`}</h5>
            <div>

              {this.render_user_input_fields()}

              <div className='d-flex justify-content-between mt-2'>
                <div className='d-flex'>
                  <TertiaryButton
                    onClick={this.add_more_new_user_fields.bind(this)}
                    disable={!selected_group}
                  >
                    <PlusIcon/>
                    <span className='ms-1'>
                      Add more users
                    </span>
                  </TertiaryButton>

                  <CheckboxAndLabel
                    className='ms-2 my-auto'
                    is_checked={is_mark_all_as_lite}
                    on_click={this.on_change_all_users_are_lite.bind(this)}
                    is_disabled={!selected_group}
                    label='Mark all as &#39;lite&#39;'
                  />
                </div>


                <div className='d-flex justify-content-end'>
                  {(users_created || users_not_created) &&
                    <TertiaryButton
                      onClick={this.clear_new_users_form.bind(this)}
                      disabled={!this.can_create_new_users()}
                    >
                      Clear form
                    </TertiaryButton>
                  }

                  {is_creating_users &&
                    <Spinner size='sm'/>
                  }
                  <PrimaryButton
                    onClick={this.create_new_users.bind(this)}
                    disabled={!this.can_create_new_users()}
                    className='ms-2'
                  >
                    Create users
                  </PrimaryButton>
                </div>
              </div>

            </div>

            {(users_created || users_not_created) &&
              <div className={cn('mt-5 m-4 p-2', s.results_container)}>
                {users_not_created &&
                  <div className='pb-3 text-endbold'>
                    <InfoIcon/>
                    <span className='ms-1'>Failed to create {users_not_created.length} user{users_not_created.length > 1 ? 's' : ''}:</span>
                    <table>
                      <tbody>
                      {this.render_users_not_created()}
                      </tbody>
                    </table>
                  </div>
                }

                {users_created &&
                  <div>
                    <span>{users_created.length} new Classification user{users_created.length > 1? 's' : ''} successfully created:</span>
                    {this.render_users_created()}
                  </div>
                }
              </div>
            }
          </div>
        }

        {users_in_group &&
          <div className='mt-3'>
            <h5>{`Users in group ${selected_group.name} (${users_in_group.length})`}</h5>
              {users_in_group.length === 0 &&
                <span>No users in this group</span>
              }

              {users_in_group.length > 0 &&
                <Table

                  columns={[
                    {id: 'id', field: 'id', label: 'ID', sortable: true},
                    {id: 'email', field: 'email', label: 'Email / Username', sortable: true},
                    {id: 'first_name', field: 'firstName', label: 'First name', sortable: true},
                    {id: 'last_name', field: 'lastName', label: 'Last name', sortable: true},
                    {id: 'actions', field: 'id', label: '', sortable: false, width: 80, cell_render: (row) => (
                        <PopoverAsDropdown label={(<MoreVertIcon />)}>
                          <PopoverAsDropdownItem
                            on_click={() => this.setState({user_to_show_details_for: row.original})}
                          >
                            Edit details (email, name etc)
                          </PopoverAsDropdownItem>
                          <PopoverAsDropdownItem
                            on_click={() => this.setState({user_to_show_roles_for: row.original})}
                          >
                            Edit user roles
                          </PopoverAsDropdownItem>
                          <PopoverAsDropdownItem
                            on_click={() => this.setState({user_to_send_password_reset_for: row.original})}
                          >
                            Send set password email
                          </PopoverAsDropdownItem>
                        </PopoverAsDropdown>
                      )}
                  ]}
                  data={users_in_group}
                />
              }
          </div>
        }

        {group_role_to_delete &&
          <RemoveRoleModal
            role_to_delete={group_role_to_delete}
            group={selected_group}
            on_close={() => this.setState({group_role_to_delete: null})}

            id_to_child_roles={group_role_id_to_child_roles}
            set_id_to_child_roles={(new_id_to_child_roles) => this.setState({group_role_id_to_child_roles: new_id_to_child_roles})}

            local_roles={selected_group_roles}
            set_local_roles={(new_local_roles) => this.setState({selected_group_roles: new_local_roles})}
          />
        }

        {is_show_add_group_role_modal &&
          <AddRoleModal
            group={selected_group}
            on_close={() => this.setState({is_show_add_group_role_modal: null})}
            child_role_ids={group_child_role_ids}

            id_to_child_roles={group_role_id_to_child_roles}
            set_id_to_child_roles={(new_id_to_child_roles) => this.setState({group_role_id_to_child_roles: new_id_to_child_roles})}

            local_roles={selected_group_roles}
            set_local_roles={(new_local_roles) => this.setState({selected_group_roles: new_local_roles})}
          />
        }

        {is_show_edit_group_wam_details_modal &&
          <EditGroupWAMDetailsModal
            group_details={selected_group_details}
            set_group_details={(group_details) => this.setState({ selected_group_details: group_details })}
            on_close={() => this.setState({ is_show_edit_group_wam_details_modal: false })}
          />
        }

        {user_to_show_roles_for &&
          <UserRolesModal
            user={user_to_show_roles_for}
            group_level_roles={selected_group_roles}
            group_child_role_ids={group_child_role_ids}
            on_close={() => this.setState({user_to_show_roles_for: null})}
          />
        }

        {user_to_show_details_for &&
          <EditUserDetailsModal
            user={user_to_show_details_for}
            on_close={() => this.setState({user_to_show_details_for: null})}
            local_users={users_in_group}
            set_local_users={(new_local_users) => this.setState({users_in_group: new_local_users})}
          />
        }

        {user_to_send_password_reset_for &&
          <SendPasswordEmailModal
            user={user_to_send_password_reset_for}
            on_close={() => this.setState({user_to_send_password_reset_for: null})}
          />
        }

      </AdminPageWrapper>
    )
  }
}

export default withUser(UserManagement)