import React from 'react'
import cn from 'classnames'
import _ from 'underscore'

import { PATENT_FAMILY_LIST_VIEWS, TABLE } from '../../../model/patent_family_list_views.js'
import StatusControl from './StatusControl.js'
import SortingControl from '../../widgets/SortingControl.js'
import PatentFamilyFieldsControl from './PatentFamilyFieldsControl.js'
import PatentFamilyListViewSelector from './PatentFamilyListViewSelector.js'
import ScoreFilterControl from '../../classifiers_editor/components/ScoreFilterControl.js'
import PageSizeControl from '../../PageSizeControl.js'
import PageControl from '../../PageControl.js'
import FamilyTagsFilter from '../../family_tagging/FamilyTagsFilter'
import ItemHeaderBackButton from '../../viewer/ItemHeaderBackButton.js'
import ListControlsInModal from './ListControlsInModal.js'

import s from './ListControlsInRow.module.scss'

const ListControlsInRow = (
  {
    className,
    context,

    hide_status,
    statuses,
    selected_status_ids,
    on_change_status_ids,

    sort_fields,
    id_to_sort_field,
    selected_sort_field_id,
    on_change_sort_field_id,
    sort_directions,
    id_to_sort_direction,
    selected_sort_direction_id,
    on_change_sort_direction_id,

    score_filter_id,
    on_change_score_filter_id,
    custom_score_range,
    on_change_custom_score_range,
    score_filter_groups,

    patent_family_list_view_id,
    on_change_patent_family_list_view_id,

    all_fields,
    selected_field_ids,
    default_selected_field_ids,
    on_change_selected_field_ids,
    fields_disabled,

    on_family_tags_filter_change,
    user_viewable_family_tags,
    selected_family_tags,
    apply_tag_filter,
    change_apply_tag_filter,
    fetching_tags_error,
    show_filter_tags_spinner,
    family_tags_enabled,

    page_sizes,
    selected_page_size,
    on_change_page_size,

    current_page,
    num_pages,
    on_change_current_page,
    show_return_control,
    level_1_path,
    level_2_path,
    level_3_path,
    refresh,
    on_close,
  }) => {

  return (
    <div className={cn('d-flex flex-nowrap', s.block, className)}>
      {show_return_control &&
        <ItemHeaderBackButton
          level_1_path={level_1_path}
          level_2_path={level_2_path}
          level_3_path={level_3_path}
          on_refresh={refresh}
          on_close={on_close}
          className={cn('my-1 me-2')}
        />
      }

      <ListControlsInModal
        context={context}
        //score
        filter_groups={score_filter_groups}
        selected_filter_id={score_filter_id}
        on_change_score_filter_id={on_change_score_filter_id}
        custom_score_range={custom_score_range}
        on_change_custom_score_range={on_change_custom_score_range}

        //status
        statuses={statuses}
        selected_status_ids={selected_status_ids}
        on_change_status_ids={on_change_status_ids}
        hide_status={hide_status}

        //sorting
        sort_fields={sort_fields}
        id_to_sort_field={id_to_sort_field}
        selected_sort_field_id={selected_sort_field_id}
        on_change_sort_field_id={on_change_sort_field_id}
        sort_directions={sort_directions}
        id_to_sort_direction={id_to_sort_direction}
        selected_sort_direction_id={selected_sort_direction_id}
        on_change_sort_direction_id={on_change_sort_direction_id}

        //view
        patent_family_list_views={PATENT_FAMILY_LIST_VIEWS}
        patent_family_list_view_id={patent_family_list_view_id}
        on_change_patent_family_list_view_id={on_change_patent_family_list_view_id}

        //fields
        all_fields={all_fields}
        selected_field_ids={selected_field_ids}
        default_selected_field_ids={default_selected_field_ids}
        on_change_selected_field_ids={on_change_selected_field_ids}

        //tags
        family_tags_enabled={family_tags_enabled}
        user_viewable_family_tags={user_viewable_family_tags}
        on_family_tags_filter_change={on_family_tags_filter_change}
        selected_family_tags={selected_family_tags}
        apply_tag_filter={apply_tag_filter}
        change_apply_tag_filter={change_apply_tag_filter}
        fetching_tags_error={fetching_tags_error}
        show_filter_tags_spinner={show_filter_tags_spinner}

        //page size
        page_sizes={page_sizes}
        selected_page_size={selected_page_size}
        on_change_page_size={on_change_page_size}

        toggleClassName='d-block d-xl-none me-2'

        pageSizeFilterClassName='d-block d-md-none'
        viewFilterClassName='d-block d-md-none'
      />

      {on_change_score_filter_id &&
        <ScoreFilterControl
          className='me-3 mb-1 d-none d-xl-flex align-items-center'
          filter_groups={score_filter_groups}
          selected_filter_id={score_filter_id}
          on_change_filter_id={on_change_score_filter_id}
          custom_score_range={custom_score_range}
          on_change_custom_score_range={on_change_custom_score_range}
          title={'Score'}
        />
      }

      {!hide_status &&
        <StatusControl
          className='me-3 mb-1 d-none d-xl-flex align-items-center'
          statuses={statuses}
          selected_status_ids={selected_status_ids}
          on_change_status_ids={on_change_status_ids}
        />
      }

      <SortingControl
        className='me-3 mb-1 d-none d-xl-flex align-items-center'
        sort_fields={sort_fields}
        id_to_sort_field={id_to_sort_field}
        selected_sort_field_id={selected_sort_field_id}
        on_change_sort_field_id={on_change_sort_field_id}
        sort_directions={sort_directions}
        id_to_sort_direction={id_to_sort_direction}
        selected_sort_direction_id={selected_sort_direction_id}
        on_change_sort_direction_id={on_change_sort_direction_id}
      />

      <PatentFamilyListViewSelector
        className='me-3 mb-1 d-none d-md-flex align-items-center'
        size={'sm'}
        patent_family_list_views={PATENT_FAMILY_LIST_VIEWS}
        patent_family_list_view_id={patent_family_list_view_id}
        on_change_patent_family_list_view_id={on_change_patent_family_list_view_id}
      />

      {(patent_family_list_view_id === TABLE) &&
        <PatentFamilyFieldsControl
          className='me-3 mb-1 d-none d-xl-flex align-items-center'
          context={context}
          all_fields={all_fields}
          selected_field_ids={selected_field_ids}
          default_selected_field_ids={default_selected_field_ids}
          on_change_selected_field_ids={on_change_selected_field_ids}
          disabled={fields_disabled} // TODO: do we still need this???
        />
      }

      {(family_tags_enabled || !_.isEmpty(user_viewable_family_tags)) &&
        <FamilyTagsFilter
          className='me-3 mb-1 d-none d-xl-flex'
          viewable_tags={user_viewable_family_tags}
          on_filter_change={on_family_tags_filter_change}
          selected_tags={selected_family_tags}
          apply_filter={apply_tag_filter}
          set_apply_filter={change_apply_tag_filter}
          tags_error={fetching_tags_error}
          show_spinner={show_filter_tags_spinner}
        />
      }

      {/* Right aligned items */}
      <div
        className={cn('d-flex', 'ms-auto')}
      >
        <PageSizeControl
          className='me-3 mb-1 d-none d-md-flex align-items-center'
          page_sizes={page_sizes}
          selected_page_size={selected_page_size}
          on_change_page_size={on_change_page_size}
        />
        <PageControl
          className='mb-1'
          current_page={current_page}
          num_pages={num_pages}
          on_change_current_page={on_change_current_page}
        />
      </div>
    </div>
  )
}

export default ListControlsInRow