import React from 'react'

import TextLink from '../widgets/TextLink.js'
import { BlockedIcon, CheckIcon } from '../widgets/IconSet.js'

import s from './DatasetSelectToggle.module.scss'

const DatasetSelectToggle = (
  {
    spec_id,
    is_selected,
    on_dataset_select,
    on_dataset_deselect,
    className
}) => {

  function select_dataset_handler(e, spec_id) {
    e.stopPropagation()
    return is_selected ? on_dataset_deselect(spec_id) : on_dataset_select(spec_id)
  }

  return (
    <TextLink element='div' onClick={(e) => select_dataset_handler(e, spec_id)} className={className} no_decoration>
      {!is_selected &&  <DatasetSelectedMark />}
      {is_selected && <BlockedIcon className={s.deselected} />}
    </TextLink>
  )
}

export const DatasetSelectedMark = () => {
  return (<CheckIcon className={s.selected} />)
}
export default DatasetSelectToggle