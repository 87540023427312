import React from 'react'

const InvalidOrganisationsWarning = ({invalid_portfolios, suggested_action_text}) => {
  return (
    <div>
      <div className='mb-2'>Classification could not identify the following organisations, probably because they have been
        merged, or split into multiple groups:
      </div>
      {invalid_portfolios.map((item, i) => {
        const {name} = item

        return (
          <div key={i}>{name}</div>
        )
      })}

      {suggested_action_text &&
        <div className='mt-2'>{suggested_action_text}</div>
      }
    </div>
  )
}

export default InvalidOrganisationsWarning