import React, {useState} from 'react'
import ReactTable from 'react-table'
import cn from 'classnames'

import { DownloadAllProjectFiles, DownloadProjectFile } from './ProjectControls.js'
import TextLink from '../widgets/TextLink.js'
import { ChevronDownIcon, ChevronRightIcon, TrashIcon, UploadIcon } from '../widgets/IconSet.js'
import {
  FIELD_FILE_ACTIONS,
  FIELD_FILE_SIZE,
  FIELD_FILENAME,
  FIELD_ID_FILENAME,
  FIELD_LAST_MODIFIED
} from '../../constants/project.js'
import Spinner from '../widgets/Spinner.js'
import { to_local_datetime } from '../../utils/utils.js'
import { ASCENDING } from '../../model/sort_directions.js'
import { sort_table_data } from '../../utils/item_utils.js'
import SortingColumnHeaderCell from '../patent_family_list/SortingColumnHeaderCell.js'
import { is_aistemos } from '../../utils/user_permissions.js'
import { withUser } from '../UserContext.js'

import cs from '../cipher_styles.module.scss'
import s from './ProjectFiles.module.scss'

const ProjectFiles = (
  {
    user,
    project_id,
    project_files,
    files_history,
    can_view,
    can_edit,
    handle_delete,
    handle_open_upload_modal,
    is_fetching_files,
    is_loading_files_history,
    is_deleting_file // do spinner in specific row
  }) => {

  const [selected_sort_field_id, set_selected_sort_field_id] = useState(FIELD_ID_FILENAME)
  const [selected_sort_direction_id, set_selected_sort_direction_id] = useState(ASCENDING)
  const [expanded_history_file_ids, set_expanded_history_file_ids] = useState([])

  function on_change_sort_field_and_direction(sort_field_id, sort_direction_id) {
    set_selected_sort_field_id(sort_field_id)
    set_selected_sort_direction_id(sort_direction_id)
  }

  function get_expanded_rows(sorted_table_rows) {
    const expanded = {}
    sorted_table_rows.forEach((row, idx) => {
      const {s3_resource_id} = row
      if (expanded_history_file_ids.indexOf(s3_resource_id) > -1) {
        expanded[idx] = true
      }
    })
    return expanded
  }

  function render_column_header(field) {
    return (
      <SortingColumnHeaderCell
        field={field}
        selected_sort_field_id={selected_sort_field_id}
        selected_sort_direction_id={selected_sort_direction_id}
        on_change_sort_field_id_and_sort_direction_id={on_change_sort_field_and_direction}
      />
    )
  }

  function render_file_data_cell({original, value, column}) {
    const {is_latest} = original
    const is_filename_column = column && column.id === FIELD_ID_FILENAME
    return (
      <span className={
        cn(
          {
            [s.older_version_row]: !is_latest,
            'ms-4': !is_latest && is_filename_column
          }
        )}
      >
        {value}
      </span>
    )
  }

  const columns = [
    {
      id: 'history_expander',
      Cell: ({original}) => {
        const {s3_resource_id, _subRows, is_latest} = original
        const is_expanded = expanded_history_file_ids.indexOf(s3_resource_id) > -1
        if (is_loading_files_history) {
          return (
            <Spinner
              size='sm'
              className='align-text-bottom'
            />
          )
        }
        if (!is_latest) {
          return null
        }
        const has_history = _subRows && _subRows.length > 0
        return (
          <span
            title={has_history ? `${is_expanded ? 'Collapse' : 'Expand'} file history` : 'No file history available'}
          >
            <TextLink
              className={!has_history && s.expand_disabled}
              disable={!has_history}
              onClick={() => {
                if (is_expanded) {
                  set_expanded_history_file_ids(expanded_history_file_ids.filter(id => id !== s3_resource_id))
                  return
                }
                const updated_expanded = [...expanded_history_file_ids, s3_resource_id]
                set_expanded_history_file_ids(updated_expanded)
              }}
              no_decoration
            >
            {is_expanded ? <ChevronDownIcon/> : <ChevronRightIcon/>}
          </TextLink>
          </span>
        )
      },
      width: 50
    },
    {
      ...FIELD_FILENAME,
      Header: render_column_header(FIELD_FILENAME),
      Cell: (params) => render_file_data_cell(params),
      sortable: false
    },
    {
      ...FIELD_FILE_SIZE,
      Header: render_column_header(FIELD_FILE_SIZE),
      Cell: ({original, value: unformatted_value}) => {
        const number_value = Number(unformatted_value)
        const size_in_kb = number_value * 0.001
        const size_in_mb = number_value * 0.000001
        const value = size_in_mb < 1 ? (size_in_kb.toFixed(2) + 'KB') : (size_in_mb.toFixed(2) + 'MB')
        return render_file_data_cell({original, value})
      },
      maxWidth: 180,
      sortable: false
    },
    {
      ...FIELD_LAST_MODIFIED,
      Header: render_column_header(FIELD_LAST_MODIFIED),
      Cell: ({original, value: unformatted_value}) => {
        return render_file_data_cell({original, value: to_local_datetime(unformatted_value)})
      },
      maxWidth: 230,
      sortable: false
    },
    {
      ...FIELD_FILE_ACTIONS,
      Header: () => <span>{FIELD_FILE_ACTIONS.name}</span>,
      Cell: ({original: file_meta}) => {
        const {s3_resource_id, filename, is_latest} = file_meta || {}
        return (
          <div className='d-flex ms-2'>
            <DownloadProjectFile
              key={s3_resource_id}
              project_id={project_id}
              file_meta={file_meta}
              is_disabled={!can_view}
              className='me-2'
            />
            {is_latest &&
              <TextLink
                onClick={() => handle_delete(s3_resource_id)}
                title={`Delete ${filename}`}
                disable={!can_edit}
                no_decoration
              >
                <TrashIcon/>
              </TextLink>
            }
          </div>
        )
      },
      width: 90
    }
  ]

  // add history subrows
  const project_files_with_history = project_files.map(file => {
    const {s3_resource_id} = file
    return {
      ...file,
      is_latest: true,
      _subRows: files_history[s3_resource_id]
    }
  })

  const project_files_sorted = sort_table_data(project_files_with_history, selected_sort_field_id, selected_sort_direction_id)

  return (
    <div>
      <div className='mt-5 mb-2 d-flex justify-content-between'>
        <h5>
          Files
        </h5>
        <div className='d-flex'>
          <DownloadAllProjectFiles
            project_id={project_id}
            is_disabled={project_files.length === 0}
            text_label='Download all files'
            className={cn('me-4', 'ms-2')}
          />
          {can_edit && is_aistemos(user) &&
            <TextLink
              className={cn('me-2')}
              onClick={handle_open_upload_modal}
              disable={!(can_edit && is_aistemos(user))}
              title='Upload project files'
              no_decoration
            >
              <UploadIcon/>
              <span className={cn(cs.underline_on_hover, 'ms-1')}>
              Upload files
            </span>
            </TextLink>
          }
        </div>
      </div>

      <ReactTable
        className='border-0 w-100 -striped'
        manual={true}
        showPagination={false}
        sortable={false}
        filterable={false}
        columns={columns}
        data={project_files_sorted}
        minRows={1}
        expanded={get_expanded_rows(project_files_sorted)}
        loading={is_fetching_files || is_loading_files_history}
        loadingText='Loading project files'
      />
    </div>
  )
}

export default withUser(ProjectFiles)