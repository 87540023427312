import React from 'react'
import cn from 'classnames'

import ReactTableWrapper from './ReactTableWrapper.js'
import TableColumnHeader from './TableColumnHeader.js'

import s from './Table.module.scss'

const RT_COLUMN_DEFAULTS = {
  id: '', /*mandatory*/
  Header: '', /*mandatory*/
  accessor: null, /*mandatory*/
  sortable: true,
  resizable: true,
  className: '',
  minWidth: 100,
  maxWidth: undefined,
  Cell: row => (<span>{row.value}</span>),
  Footer: null,
  sortMethod: null
}

export const Table = (
  {
    columns,
    data,
    className,
    no_data_text,
    defaultSorted,
    pagination,
    getProps,
    getTrProps,
    getTdProps,
    getTheadThProps,
    ...rest
  }) => {
  const min_rows = data.length || 3

  const columns_to_render = columns.map((column) => {
    const {
      id,
      header,
      label,
      field: accessor,
      sortable,
      resizable,
      className,
      cell_render,
      min_width,
      max_width,
      width,
      foot_render,
      sort,
      headerClassName
    } = column || {}

    return {
      ...RT_COLUMN_DEFAULTS,
      id,
      Header: (header || <TableColumnHeader label={label} sort_disabled={!sortable} sortIconClassName={s.column_sort_icon} />),
      accessor,
      ...(sortable != null) ? { sortable } : {},
      ...(resizable != null) ? { resizable } : {},
      ...(className) ? { className } : {},
      ...(cell_render) ? { Cell: cell_render } : {},
      ...(min_width) ? { minWidth: min_width } : {},
      ...(max_width) ? { maxWidth: max_width } : {},
      ...(width) ? { width } : {},
      ...(foot_render) ? { Footer: foot_render } : {},
      ...(sort) ? { sortMethod: sort } : {},
      ...(headerClassName) ? { headerClassName } : {}
  }})

  return (
    <ReactTableWrapper
      columns={columns_to_render}
      data={data}
      pageSize={data.length || min_rows}
      minRows={min_rows}
      className={cn('-striped', className)}
      showPagination={pagination || false}
      noDataText={no_data_text || 'No data found'}
      defaultSorted={defaultSorted} // optional: specifies default column to sort by
      getProps={getProps}
      getTrProps = {getTrProps}
      getTdProps = {getTdProps}
      getTheadThProps = {getTheadThProps}
      {...rest}
    />
  )

}