import React, {useState} from 'react'
import cn from 'classnames'
import ReactTable from 'react-table'
import _ from 'underscore'

import {
  ENTITY_TYPE_ID_GROUP,
  ENTITY_TYPE_ID_USER,
  FIELD_EDIT_PERMISSION,
  FIELD_ENTITY_NAME,
  FIELD_ENTITY_TYPE,
  FIELD_ID_ENTITY_ID,
  FIELD_VIEW_PERMISSION
} from '../../constants/project.js'
import { withUser } from '../UserContext.js'
import { GroupIcon, UserIcon } from '../widgets/IconSet.js'
import CheckboxStatic from '../widgets/CheckboxStatic.js'
import { ASCENDING, DESCENDING } from '../../model/sort_directions.js'
import { sort_table_data } from '../../utils/item_utils.js'
import SortingColumnHeaderCell from '../patent_family_list/SortingColumnHeaderCell.js'
import { are_permission_rows_identical } from '../../utils/project_and_versioning_utils.ts'
import { Autocomplete, TextField } from '@mui/material'
import { PrimaryButton } from '../widgets/Button.js'

import s from './ProjectPermissions.module.scss'

const ProjectPermissions = (
  {
    user,
    can_edit,
    permissions_rows,
    updated_permissions_rows,
    on_update_permissions_rows,
    on_save_updates,
    all_active_groups,
    all_active_users,
    is_loading
  }) => {

  const [selected_sort_field_id, set_selected_sort_field_id] = useState(FIELD_ID_ENTITY_ID)
  const [selected_sort_direction_id, set_selected_sort_direction_id] = useState(ASCENDING)

  const sharable_groups_users_list = [
    ...(_.values(all_active_groups)).map(group => ({...group, entity_type: ENTITY_TYPE_ID_GROUP})),
    ...(_.values(all_active_users)).map(user => ({...user, entity_type: ENTITY_TYPE_ID_USER}))
  ]

  function get_displayable_name(row) {
    const {entity_id, entity_type} = row
    if (entity_type === ENTITY_TYPE_ID_GROUP) {
      const group = all_active_groups[entity_id]
      return (group || {}).name || 'Unknown'
    }
    const user = all_active_users[entity_id]
    if (!user) {
      return 'Unknown'
    }
    const {firstName, lastName, username} = user
    if (firstName && lastName) {
      return `${firstName} ${lastName} (${username})`
    }
    return username
  }

  function sort_rows() {
    if (selected_sort_field_id === FIELD_ID_ENTITY_ID) {
      const sorted_data = _.sortBy(permissions_rows, (row) => {
        const value = get_displayable_name(row)
        return _.isString(value) ? value.toLowerCase() : value
      })
      return (selected_sort_direction_id === DESCENDING) ? sorted_data.slice().reverse() : sorted_data
    }
    return sort_table_data(permissions_rows, selected_sort_field_id, selected_sort_direction_id)
  }

  const rows_sorted = sort_rows()

  function on_change_sort_field_and_direction(sort_field_id, sort_direction_id) {
    set_selected_sort_field_id(sort_field_id)
    set_selected_sort_direction_id(sort_direction_id)
  }

  function render_column_header(field) {
    return (
      <SortingColumnHeaderCell
        field={field}
        selected_sort_field_id={selected_sort_field_id}
        selected_sort_direction_id={selected_sort_direction_id}
        on_change_sort_field_id_and_sort_direction_id={on_change_sort_field_and_direction}
      />
    )
  }

  const columns = [
    {
      ...FIELD_ENTITY_TYPE,
      width: 100,
      sortable: false,
      Header: render_column_header(FIELD_ENTITY_TYPE),
      Cell: (params) => {
        const {row} = params
        return (
          <div className='d-flex flex-wrap flex-grow-1 align-items-center justify-content-start'>
            {row.entity_type === ENTITY_TYPE_ID_GROUP && (
              <>
                <GroupIcon />
                <span className={cn('ms-2')}>Group</span>
              </>
            )}
            {row.entity_type === ENTITY_TYPE_ID_USER && (
              <>
                <UserIcon/>
                <span className={cn('ms-2')}>User</span>
              </>
            )}
          </div>
        )
      }
    },
    { ...FIELD_ENTITY_NAME,
      Header: render_column_header(FIELD_ENTITY_NAME),
      sortable: false,
      Cell: (params) => {
        const {row} = params
        return get_displayable_name(row)
      }
    },
    {
      ...FIELD_VIEW_PERMISSION,
      width: 100,
      resizable: false,
      sortable: false,
      Header: render_column_header(FIELD_VIEW_PERMISSION),
      Cell: (params) => {
        const {row} = params
        return (
          <CheckboxStatic
            onClick={() => {
              const permissions_updated = permissions_rows.map(permission => {
                if (permission.entity_id === row.entity_id) {
                  return {...row, view: !row.view}
                }
                return permission
              })
              on_update_permissions_rows(permissions_updated)
            }}
            is_checked={row.view}
            is_disabled={row.entity_id === user.user_id}
          />
        )
      }
    },
    {
      ...FIELD_EDIT_PERMISSION,
      width: 100,
      resizable: false,
      sortable: false,
      Header: render_column_header(FIELD_EDIT_PERMISSION),
      Cell: (params) => {
        const {row} = params
        return (
          <CheckboxStatic
            onClick={() => {
              const permissions_updated = permissions_rows.map(permission => {
                if (permission.entity_id === row.entity_id) {
                  return {...row, edit: !row.edit}
                }
                return permission
              })
              on_update_permissions_rows(permissions_updated)
            }}
            is_checked={row.edit}
            is_disabled={row.entity_id === user.user_id}
          />
        )
      }
    }
  ]

  return (
    <div className='mt-3'>
      <div className='d-flex align-items-end justify-content-between mb-3'>
        <Autocomplete
          className='w-50'
          options={sharable_groups_users_list}
          size='small'
          value={null}
          multiple={false}
          freeSolo={false}
          filterSelectedOptions={true}
          onChange={(_event, selection) => {
            const {id, entity_type} = selection
            const new_perm_row = {
              entity_id: id,
              entity_type,
              edit: false,
              view: true
            }
            on_update_permissions_rows(_.union(permissions_rows, [new_perm_row]))
          }}
          disabled={!can_edit || sharable_groups_users_list.length === 0}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Add permissions for a user or group'
              placeholder='Search users'
            />
          )}
          getOptionLabel={(option) => option.name ? option.name : option.username}
          getOptionDisabled={(option) => !!_.find(permissions_rows, r => r.entity_type === option.entity_type && r.entity_id === option.id)}
          groupBy={(option) => option.entity_type === ENTITY_TYPE_ID_GROUP ? 'Groups' : 'Users'}
        />
        <PrimaryButton
          disabled={!can_edit || updated_permissions_rows.length === 0 || is_loading}
          onClick={on_save_updates}
        >
          <span className='ms-1'>Save updates</span>
        </PrimaryButton>
      </div>
      <ReactTable
        className='border-0 w-100 -striped'
        manual={true}
        showPagination={false}
        sortable={false}
        filterable={false}
        columns={columns}
        data={rows_sorted}
        minRows={5}
        getTrProps={(final_state, row_info) => {
          // make row_info accessible for extra styling
          return {row_info}
        }}
        TrComponent={({children, className, row_info, ...rest}) => {
          // additional styling for modified rows to highlight unsaved changes
          const {original} = row_info || {} // i.e. header doesn't have a row_info object
          const is_modified_row = original && _.filter(updated_permissions_rows, p => are_permission_rows_identical(p, original)).length > 0

          return (
            <div
              className={cn(
                'rt-tr',
                (is_modified_row ? s.row_modified : className)
              )}
              role='row'
              {...rest}
            >
              {children}
            </div>
          )
        }}
      />
    </div>
  )
}

export default withUser(ProjectPermissions)