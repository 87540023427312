import React from 'react'
import cn from 'classnames'
import moment from 'moment'

import { Table } from '../widgets/Table.js'
import { get_as_map } from '../../utils/utils.js'

import s from './ChangeLogDisplay.module.scss'


const TOP_RECENT_LIMIT = 3

const ChangeLogDisplay = ({change_log, users, show_user, recent_only}) => {
  const users_by_user_id = (users) ? get_as_map(users) : {}

  function format_user_name(user) {
    const {firstName='', lastName='', email=''} = user || {}
    return user ? `${firstName} ${lastName} (${email})` : ''
  }

  function get_user_for_display(user_id) {
    const user = users_by_user_id[user_id]
    const user_name = format_user_name(user)
    return (user_name && user_name !== '') ? user_name : user_id
  }

  function get_columns() {
    const columns = [{id: 'id', label: 'ID', field: 'id', sortable: true}]

    if (show_user) {
      columns.push({id: 'user', label: 'Who', field: 'user_id', sortable: true, cell_render: (row) => (get_user_for_display(row.original.user_id))})
    }

    return [...columns, ...[
      {id: 'date', label: 'When', field: 'date', sortable: true, cell_render: (row) => (moment(row.original.date).fromNow())},
      {id: 'action', label: 'Action', field: 'action', sortable: true},
      {id: 'message', label: 'Message', field: 'message', sortable: false}
    ]]
  }

  if (recent_only) {
    const recent_changes = (change_log.length === TOP_RECENT_LIMIT + 1) ? change_log : change_log.slice(0, TOP_RECENT_LIMIT)
    return (
      <div className={s.recent_changes_block}>
        {recent_changes.map((item, i) => {
          const {user_id, date} = item
          return (
            <div key={i} className='mb-2'>
              <div className='text-endbold'>
                {moment(date).fromNow()}
              </div>
              <div>
                {get_user_for_display(user_id)}
              </div>
            </div>
          )
        })}
        {(recent_changes.length < change_log.length) &&
         <div className={cn('d-flex justify-content-end', s.more_changes_block)}>and {change_log.length - recent_changes.length} other changes</div>
        }
      </div>
    )
  }

  return (
    <Table
      data={change_log}
      columns={get_columns()}
    />
  )
}

export default ChangeLogDisplay