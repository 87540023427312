import {
  APPLICATION_DATE,
  ASSIGNEE,
  CIPHER_FAMILY_ID,
  ID_TO_ALL_FIELDS,
  IGNORE,
  LINENO,
  OWNER,
  PUB_NUMBER,
  SERIAL,
  SOURCE,
  STATUS,
  TITLE,
  TYPE
} from './matched_patents_table_model.js'
import { TABLE_COLUMN_HEADER } from '../../../model/table.js'

function get_column(field_id) {

  const { name } = ID_TO_ALL_FIELDS[field_id] || {}

  return {
    ...TABLE_COLUMN_HEADER,
    className: 'text-justified',
    resizable: true,
    sortable: false,

    id: field_id,
    field: field_id,
    label: name,
  }
}

export const COLUMNS = [
  {
    ...get_column(LINENO),
    width: 80,
    className: 'text-center'
  },
  {
    ...get_column(STATUS),
    max_width: 100,
    className: 'text-center'
  },
  {
    ...get_column(SOURCE),
    width: 190,
  },
  {
    ...get_column(IGNORE),
    width: 110
  },
  {
    ...get_column(SERIAL),
    width: 160,
    className: 'text-center'
  },
  {
    ...get_column(TYPE),
    width: 90
  },
  {
    ...get_column(PUB_NUMBER),
    width: 140,
  },
  {
    ...get_column(APPLICATION_DATE),
    width: 115,
  },
  {
    ...get_column(CIPHER_FAMILY_ID),
    width: 180,
  },
  {
    ...get_column(ASSIGNEE),
    width: 150,
  },
  {
    ...get_column(OWNER),
    width: 140,
  },
  {
    ...get_column(TITLE),
    width: 400,
  },
]