import React from 'react'
import _ from 'underscore'
import cn from 'classnames'
import { DropdownItem } from 'reactstrap'

import { ALL, SELECTED_ONLY, UNSELECTED_ONLY } from '../../model/selection_views.js'
import { DEFAULT, DEFAULT_ITEM, ALPHABETICALLY_ITEM } from '../../model/simple_sort_items.js'
import { REPORT_PAGE_TOP } from '../../constants/layout.js'
import { track_report_viewer_event } from '../../utils/tracking_utils.js'
import { get_empty_items, get_non_empty_items } from '../../utils/ref_data_utils.js'
import CheckboxGroupHeader from './CheckboxGroupHeader.js'
import CheckboxGroup, { LINE_HEIGHT } from './CheckboxGroup.js'
import CheckboxGroupPaginationControls from './CheckboxGroupPaginationControls.js'
import ClassifiersCheckboxTree from '../classifiers/ClassifiersCheckboxTree.js'
import ScrollableList from '../widgets/ScrollableList.js'

const GROUP_HEADER_HEIGHT = 130  // Approximate height of the CheckboxGroupHeader component
const FOOTER_HEIGHT = 85
const FOOTER_MARGIN = 30

const PAGE_QUANTIZE = 5
const MAX_PAGE_SIZE = 20
const MIN_PAGE_SIZE = 5

function get_page_size(viewport_height) {
  const available_height = viewport_height - (REPORT_PAGE_TOP + GROUP_HEADER_HEIGHT + FOOTER_HEIGHT + FOOTER_MARGIN)

  // As many lines as we can fit...
  const page_size = Math.floor(available_height / LINE_HEIGHT)

  // Limit to some maximum
  const page_size_limited = Math.min(page_size, MAX_PAGE_SIZE)

  // Quantize (i.e. 5, 10, 15, 20 etc...)
  const page_size_limited_and_quantized = Math.floor(page_size_limited / PAGE_QUANTIZE) * PAGE_QUANTIZE

  // Apply a minimum
  return Math.max(page_size_limited_and_quantized, MIN_PAGE_SIZE)
}

function get_selectable_items(items) {
  const empty_items = _.sortBy(get_empty_items(items), 'name')
  const has_empty_items = empty_items && empty_items.length > 0
  const selectable_items = has_empty_items ? get_non_empty_items(items) : items
  return { selectable_items, empty_items, has_empty_items }
}

function get_items_filtered_and_sorted(selectable_items, selected_item_ids, selection_view_id, search_phrase, sort_id) {
  // Filter by selection view
  const items_filtered_by_selection_view = filter_items_by_selection_view_id(selectable_items, selected_item_ids, selection_view_id)

  // Filter by search_phrase
  const items_filtered_by_selection_view_and_search_phrase = !search_phrase ? items_filtered_by_selection_view : items_filtered_by_selection_view.filter(item => {
    return item.name.toLowerCase().indexOf(search_phrase.toLowerCase()) !== -1
  })

  // Sort alphabetically if required
  const items_filtered_and_sorted = (sort_id === DEFAULT) ? items_filtered_by_selection_view_and_search_phrase : _.sortBy(items_filtered_by_selection_view_and_search_phrase, (item) => item.name.trim().toLowerCase())  

  return items_filtered_and_sorted
}

function filter_items_by_selection_view_id(items, selected_item_ids, selection_view_id) {
  if (selection_view_id === ALL) {
    return items
  }

  if (selection_view_id === SELECTED_ONLY) {
    return items.filter((item) => _.contains(selected_item_ids, item.id))
  }

  if (selection_view_id === UNSELECTED_ONLY) {
    return items.filter((item) => !_.contains(selected_item_ids, item.id))
  }

  throw Error(`selection_view_id '${selection_view_id}' not recognised`)
}

class CheckboxGroupWithControls extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      search_phrase: '',
      page: 0,
      viewport_height: window.innerHeight,
      selection_view_id: ALL,
      sort_id: DEFAULT,
      first_count: 10,
      last_count: 10
    }
    this.handle_change_search_phrase            = this.handle_change_search_phrase.bind(this)
    this.handle_change_page                     = this.handle_change_page.bind(this)
    this.on_change_selection_view_id            = this.on_change_selection_view_id.bind(this)
    this.on_change_sort_id                      = this.on_change_sort_id.bind(this)
    this.select_all                             = this.select_all.bind(this)
    this.deselect_all                           = this.deselect_all.bind(this)
    this.update_looked_up_items_selection       = this.update_looked_up_items_selection.bind(this)
    this.update_items_selection                 = this.update_items_selection.bind(this)
    this.update_spotlighted_items               = this.update_spotlighted_items.bind(this)
    this.on_selected_classifiers_updated        = this.on_selected_classifiers_updated.bind(this)
    this.debounced_handle_resize                = _.debounce(this.handle_resize.bind(this), 500)
    this.set_first_count                        = this.set_first_count.bind(this)
    this.set_last_count                         = this.set_last_count.bind(this)
    this.deselect_all_but_first_count           = this.deselect_all_but_first_count.bind(this)
    this.deselect_all_but_last_count            = this.deselect_all_but_last_count.bind(this)

    window.addEventListener('resize', this.debounced_handle_resize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debounced_handle_resize)
  }
  
  componentDidUpdate(prevProps) {
    const {items: prev_items = []} = prevProps
    const {items: current_items = []} = this.props

    if (prev_items.length !== current_items.length) {
      this.setState({ page: 0 })
    }
  }
  
  handle_resize() {
    const { is_paginated } = this.props
    if (!is_paginated) {
      // For non-paginated lists, don't do anything.
      return
    }

    this.setState({ viewport_height: window.innerHeight, page: 0 })
  }

  handle_change_search_phrase(search_phrase) {
    const {selector_id, chart_filters} = this.props
    track_report_viewer_event(`obj="filter" filter="${selector_id}" action="search" context="${chart_filters ? 'chart' : 'viewer'}"`, true)
    this.setState({ search_phrase, page: 0 })
  }

  handle_change_page(page) {
    const {selector_id, chart_filters} = this.props
    track_report_viewer_event(`obj="filter" filter="${selector_id}" action="change_page" context="${chart_filters ? 'chart' : 'viewer'}"`, true)
    this.setState({ page })
  }

  on_change_selection_view_id(selection_view_id) {
    this.setState({ selection_view_id, page: 0 })
  }

  on_change_sort_id(sort_id) {
    this.setState({ sort_id, page: 0 })
  }

  select_all() {
    const { items, set_selected_item_ids } = this.props
    const __last = items[items.length - 1] || {}
    const new_selected_item_ids = (__last.is_next_agglom) ?
      [...items.filter(item => !item.is_next_agglom).map(item => item.id), ...__last.child_ids.map(id => id*1)] : items.map(item => item.id)
    set_selected_item_ids(new_selected_item_ids)
  }

  deselect_all() {
    const { set_selected_item_ids } = this.props
    set_selected_item_ids([])
  }

  update_looked_up_items_selection(selected_item_ids) {
    const { set_selected_item_ids, selector_id, chart_filters } = this.props
    track_report_viewer_event(`obj="${chart_filters ? 'dataset' : 'report'}" filter="${selector_id}" action="${selected_item_ids.length > 0 ? 'select' : 'deselect'}" option="found"`)
    set_selected_item_ids(selected_item_ids)
  }

  update_items_selection(new_selected_item_id) {
    const { selected_item_ids, set_selected_item_ids, selector_id, chart_filters } = this.props
    const is_selected = new_selected_item_id.length > selected_item_ids.length
    track_report_viewer_event(`obj="${chart_filters ? 'dataset' : 'report'}"  filter="${selector_id}" action="${is_selected ? 'select' : 'deselect'}`)
    set_selected_item_ids(new_selected_item_id)
  }

  update_spotlighted_items(new_spotlighted_item_id) {
    const { spotlighted_item_ids, set_spotlighted_item_ids, selector_id, chart_filters } = this.props

    if (!set_spotlighted_item_ids || !spotlighted_item_ids) return
    const is_selected = new_spotlighted_item_id.length > spotlighted_item_ids.length
    track_report_viewer_event(`obj="${chart_filters ? 'dataset' : 'report'}"  filter="${selector_id}" action="${is_selected ? 'spotlight_on' : 'spotlight_off'}`)
    set_spotlighted_item_ids(new_spotlighted_item_id)
  }

  on_selected_classifiers_updated(items) {
    const { id_key } = this.props
    const new_selected_item_ids = items.map(item => item[id_key])
    this.update_items_selection(new_selected_item_ids)
  }

  set_first_count(first_count) {
    this.setState({ first_count })
  }

  set_last_count(last_count) {
    this.setState({ last_count })
  }

  deselect_all_but_first_count() {
    const { items, selected_item_ids, set_selected_item_ids, selector_id } = this.props
    const { first_count, selection_view_id, search_phrase, sort_id } = this.state

    const { selectable_items } = get_selectable_items(items)
    const items_filtered_and_sorted = get_items_filtered_and_sorted(selectable_items, selected_item_ids, selection_view_id, search_phrase, sort_id)

    const first_count_available = Math.min(first_count, items_filtered_and_sorted.length)
    track_report_viewer_event(`obj="report" filter="${selector_id}" action="deselect" option="all_but_first" count="${first_count_available}"`)

    const new_selected_item_idxs = _.range(0, first_count_available)
    const new_selected_item_ids  = new_selected_item_idxs.map(idx => items_filtered_and_sorted[idx].id)

    set_selected_item_ids(new_selected_item_ids)
  }

  deselect_all_but_last_count() {
    const { items, selected_item_ids, set_selected_item_ids, selector_id } = this.props
    const { last_count, selection_view_id, search_phrase, sort_id } = this.state

    const { selectable_items } = get_selectable_items(items)
    const items_filtered_and_sorted = get_items_filtered_and_sorted(selectable_items, selected_item_ids, selection_view_id, search_phrase, sort_id)

    const last_count_available = Math.min(last_count, items_filtered_and_sorted.length)

    track_report_viewer_event(`obj="report" filter="${selector_id}" action="deselect" option="all_but_last" count="${last_count_available}"`)
    const new_selected_item_idxs = _.range(items_filtered_and_sorted.length - last_count_available, items_filtered_and_sorted.length)
    const new_selected_item_ids  = new_selected_item_idxs.map(idx => items_filtered_and_sorted[idx].id)

    set_selected_item_ids(new_selected_item_ids)
  }

  render() {
    const {
      search_phrase,
      page,
      viewport_height,
      selection_view_id,
      sort_id,
      first_count,
      last_count
    } = this.state

    const {
      id_key,
      items,
      selected_item_ids,
      empty_items_section_header,
      tree,
      selected_tree_items,
      is_paginated,
      show_selection_view_controls,
      show_sort_controls,
      default_sort_info,
      next_agglom_selected,
      toggle_next_agglom_visibility,
      agglom_controls,
      chart_filters,
      update_global_filters,
      no_rollups,
      spotlighted_item_ids,
      set_spotlighted_item_ids,
      toggle_spotlighting,
      should_apply_spotlights,
      can_clear_spotlights,
      get_description
    }  = this.props

    if (!items) {
      return null
    }

    let next_agglom_item = null
    const items_no_agglom_item = []
    
    items.forEach(item => {
      if (item.is_next_agglom) {
        next_agglom_item = item
      } else {
        items_no_agglom_item.push(item)
      }
    })
    
    const { selectable_items, empty_items, has_empty_items } = get_selectable_items(items_no_agglom_item)
    const num_selectable_items = selectable_items.length

    const items_filtered_and_sorted = get_items_filtered_and_sorted(selectable_items, selected_item_ids, selection_view_id, search_phrase, sort_id)

    const num_filtered_results = items_filtered_and_sorted ? items_filtered_and_sorted.length : 0
    const is_filtered = ((search_phrase.length > 0) && selectable_items && items_filtered_and_sorted) ? selectable_items.length !== items_filtered_and_sorted.length : false

     // Pagination
    const page_size                = get_page_size(viewport_height, num_selectable_items)
    const total_num_items          = items_filtered_and_sorted.length
    const start_index              = page * page_size
    const num_pages                = Math.ceil(total_num_items / page_size)
    const page_items               = !is_paginated ? items_filtered_and_sorted : items_filtered_and_sorted.slice(start_index, start_index + page_size)
    const is_potentially_multipage = (is_paginated && num_selectable_items > page_size)
    const is_multiple_pages        = total_num_items > page_size

    const default_sort_item = {...DEFAULT_ITEM, title: default_sort_info}

    const is_item_spotlighting_enabled = (set_spotlighted_item_ids && spotlighted_item_ids)

    return (
      <div
        className={cn('pb-2')}
      >
        <CheckboxGroupHeader
          id_key={id_key}
          page_items={page_items}
          filtered_items={items_filtered_and_sorted}
          all_items={selectable_items}
          selected_item_ids={selected_item_ids}
          is_filtered={is_filtered}
          num_filtered_results={num_filtered_results}
          num_selectable_items={num_selectable_items}
          search_phrase={search_phrase}
          set_selected_item_ids={this.update_looked_up_items_selection}
          handle_change_search_phrase={this.handle_change_search_phrase}
          select_all={this.select_all}
          deselect_all={this.deselect_all}

          show_selection_view_controls={show_selection_view_controls}
          selection_view_id={selection_view_id}
          on_change_selection_view_id={this.on_change_selection_view_id}

          show_sort_controls={show_sort_controls}
          available_sort_items={[default_sort_item, ALPHABETICALLY_ITEM]}
          sort_id={sort_id}
          on_change_sort_id={this.on_change_sort_id}

          deselect_all_but_first_count={this.deselect_all_but_first_count}
          deselect_all_but_last_count={this.deselect_all_but_last_count}
          first_count={first_count}
          last_count={last_count}
          set_first_count={this.set_first_count}
          set_last_count={this.set_last_count}
          agglom_controls={agglom_controls}

          is_next_agglom_selected={chart_filters && next_agglom_item ? next_agglom_selected : true}
          no_options_dropdown={chart_filters}
          chart_filters={chart_filters}
          update_global_filters={update_global_filters}

          is_item_spotlighting_enabled={is_item_spotlighting_enabled && spotlighted_item_ids.length > 0}
          clear_spotlighting={() => set_spotlighted_item_ids([])}
          toggle_spotlighting={toggle_spotlighting}
          should_apply_spotlights={should_apply_spotlights}
          can_clear_spotlights={can_clear_spotlights}
        />

        <ScrollableList
          no_scroll={is_paginated}
        >

          {(page_items && tree) &&
            <ClassifiersCheckboxTree
              hide_root_node={true}
              classifiers_data={tree}
              search_input={search_phrase}
              selected_classifiers={selected_tree_items}
              selected_indirectly={[]}
              on_selected_classifiers_updated={this.on_selected_classifiers_updated}
              show_selected_only={false}
              prohibit_parent_level_selection={false}
              is_selection_permitted={true}
              id_key={id_key}
              spotlighted_item_ids={!should_apply_spotlights ? [] : spotlighted_item_ids}
              set_spotlighted_item_ids={is_item_spotlighting_enabled ? this.update_spotlighted_items : null}
              get_description={get_description}
            />
          }

          {(page_items && !tree) &&
            <CheckboxGroup
              className='mt-2'
              items={page_items}
              selected_item_ids={selected_item_ids}
              set_selected_item_ids={this.update_items_selection}
              spotlighted_item_ids={!should_apply_spotlights ? [] : spotlighted_item_ids}
              set_spotlighted_item_ids={is_item_spotlighting_enabled ? this.update_spotlighted_items : null}
              id_key={id_key}
              search_phrase={search_phrase}
              fixed_page_size={is_potentially_multipage ? page_size : null} // if only one page, no point in fixing the height
              next_agglom_item={next_agglom_item}
              next_agglom_selected={next_agglom_selected}
              toggle_next_agglom_visibility={toggle_next_agglom_visibility}
              no_rollups={no_rollups}
              is_multiple_pages={is_multiple_pages}

              get_description={get_description}
            />
          }

          {has_empty_items &&
            <div>
              {page_items && page_items.length > 0 && <DropdownItem divider/>}
              <h5>{`${empty_items_section_header} [${empty_items.length}]`}</h5>

              {empty_items.map((item, i) => (
                <div key={i}>{item.name}</div>
              ))}
            </div>
          }

          {(is_paginated && is_multiple_pages) &&
            <CheckboxGroupPaginationControls
              className={cn('mt-2', 'd-flex', 'justify-content-center')}
              num_pages={num_pages}
              page={page}
              page_size={page_size}
              total_num_items={total_num_items}
              handle_change_page={this.handle_change_page}
            />
          }

        </ScrollableList>
      </div>
    )
  }
}

export default CheckboxGroupWithControls