import React from 'react'
import { withRouter } from 'react-router-dom'

import { DashboardSmallTile } from './DashboardTile.js'
import { DashboardTileDesc } from './DashboardTileDesc.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'
import { DashboardTileInner } from './DashboardTileInner.js'
import { BUILD_SET_THEORY_REPORT } from '../../constants/paths'

interface NewSetTheoryReportPanelProps {
  history: any
}

const NewSetTheoryReportPanel = (props: NewSetTheoryReportPanelProps) => {
  function on_click_from_new_report_btn() {
    track_report_builder_event('action="select_builder" report_type="set_theory_report" context="dashboard"')
    props.history.push(BUILD_SET_THEORY_REPORT)
  }

  return (
    <DashboardSmallTile title='Custom set theory report' hint={null} className={null} on_click={on_click_from_new_report_btn}>
      <DashboardTileInner className={null}>
        <DashboardTileDesc className={null}>
          Create a new report by applying set theory formulas over your reports.
        </DashboardTileDesc>
      </DashboardTileInner>
    </DashboardSmallTile>
  )
}

export default withRouter(NewSetTheoryReportPanel)