import axios from 'axios'

import {
  STATIC_DATA_BASE_URL
} from '../constants/urls.js'

import { add_source_err_to_target_err, JSON_POST_HEADER } from './axios_utils.js'

export const RESOURCE_TAXONOMY    = 'taxonomy'
export const GROUPING_SUGGESTIONS_KEY = 'grouping_suggestions'

export function get_product_classifiers(product) {
  return get_product_resource(product, RESOURCE_TAXONOMY)
}

export function get_product_resource(product, resource) {
  return axios.get(`${STATIC_DATA_BASE_URL}/${product}/${resource}`)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), `Unable to fetch resource '${resource}' for product '${product}': `)
      throw wrapped_err
    })
    .then(response => response.data)
}

export function get_ag_basket_resource(resource, resource_key='ag_basket_input') {
  return axios.get(`${STATIC_DATA_BASE_URL}/${resource_key}/${resource}`)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), `Unable to fetch ag basket input '${resource}': `)
      throw wrapped_err
    })
    .then(response => response.data)
}

export function create_grouping_suggestion_resource(resource, data) {
  return axios.post(`${STATIC_DATA_BASE_URL}/${GROUPING_SUGGESTIONS_KEY}/${resource}`, data, {headers: JSON_POST_HEADER})
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), `Unable to create grouping suggestion '${resource}': `)
      throw wrapped_err
    })
    .then(response => response.data)
}

export function get_available_utt_labels(utt_version) {
  return axios.get(`${STATIC_DATA_BASE_URL}/utt_labels/${utt_version}`)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), `Unable to fetch UTT labels: `)
      throw wrapped_err
    })
    .then(response => response.data)
}

export function get_utt_classes(utt_version) {
  return axios.get(`${STATIC_DATA_BASE_URL}/utt_classes/${utt_version}`)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), `Unable to fetch universal technology taxonomy: `)
      throw wrapped_err
    })
    .then(response => response.data)
}
