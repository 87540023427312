import React from 'react'
import qs from 'query-string'

import ContainerFixedWidth from './ContainerFixedWidth.js'
import { track_visit_event } from '../utils/tracking_utils.js'
import { is_espacenet_compatible, get_espacenet_url, parse_patent } from '../utils/patent_utils.js'
import TextLink from './widgets/TextLink.js'

const DocNotFound = ({location}) => {
  const { search } = location || {}

  const query_params = qs.parse(search)

  const { patent } = query_params || {}
  const patent_id = (patent || '').replace(/-/g, '')
  const { kind_code=''} = parse_patent(patent_id) || {}

  document.title = 'Classification: Document not found'
  track_visit_event(`page="document-not-found" document="${patent_id}"`)

  const espacenet_url = is_espacenet_compatible(patent_id) && (kind_code !== 'D0') ? get_espacenet_url(patent_id) : null

  return (
    <ContainerFixedWidth>
      <div>
        <div>No document available for the patent {patent_id}.</div>
        {espacenet_url && <div className='mt-3'>Try searching in <TextLink element='a' href={espacenet_url} target='_blank'>Espacenet</TextLink></div>}
      </div>
    </ContainerFixedWidth>
  )
}

export default DocNotFound