import React, { useState } from 'react'
import cn from 'classnames'

import { send_error_to_sentry } from '../../utils/sentry_utils'
import { InfoPopover } from '../widgets/Tooltip'
import { UserIcon } from '../widgets/IconSet'
import Spinner from '../widgets/Spinner'

interface TaggersProps {
  className: string,
  fetch_taggers: Function,
  fetch_taggers_params: Array<any>,
}


export const Taggers = ({className, fetch_taggers, fetch_taggers_params}: TaggersProps) => {
  const [taggers_info, set_taggers_info] = useState([])
  const [error_fetching_taggers, set_error_fetching_taggers] = useState(null)
  const [show_taggers_spinner, set_show_taggers_spinner] = useState(false)

  function trigger_fetching(): any {
    set_show_taggers_spinner(true)
    fetch_taggers(...fetch_taggers_params)
      .then((data: any) => {
        set_show_taggers_spinner(false)
        set_error_fetching_taggers(null)
        set_taggers_info(data)
      })
      .catch((err: any) => {
        set_show_taggers_spinner(false)
        set_error_fetching_taggers(err)
        send_error_to_sentry(err, {})
      })
  }

  return (
    // @ts-expect-error
    <InfoPopover
      toggler={<UserIcon/>}
      buttonClassName={cn(className,'p-0')}
      on_show={() => trigger_fetching()}
      is_in_modal={true}
    >
        {error_fetching_taggers &&
          <div> Error fetching taggers </div>
        }
        {show_taggers_spinner &&
          <Spinner size='sm'/>
        }
      {!show_taggers_spinner && !error_fetching_taggers &&
        <div className={'overflow-scroll list-group-flush '}>
          {taggers_info.map((tag_and_tagger: any, idx: number) =>
            (
              <div key={idx} className={'p-1 border-0 list-group-item text-break'}>
                <span className={''}>{tag_and_tagger.tag_name}</span>:
                <span className={''}> {tag_and_tagger.tag_value} </span>
                <span className={'text-endbold font-italic'}>({tag_and_tagger.tagger})</span>
              </div>
            ))
          }
          </div>
        }
    </InfoPopover>
  )
}