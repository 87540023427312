export function is_element_vertically_onscreen(el, top_margin, bottom_margin) {
  top_margin = top_margin || 0
  bottom_margin = bottom_margin || 0

  if (!el || !el.getBoundingClientRect) {
    return true
  }

  const rect   = el.getBoundingClientRect()
  const el_top = rect.top
  const el_bottom = rect.top + rect.height

  const v_height = window.innerHeight || document.documentElement.clientHeight

  const min_top = 0 + top_margin
  const max_bottom = v_height - bottom_margin

  return (el_top >= min_top) && (el_bottom <= max_bottom)
}

export const SCROLL_INTO_VIEW_BEHAVIOUR_INSTANT = 'instant'
export const SCROLL_INTO_VIEW_BEHAVIOUR_SMOOTH = 'smooth'

export function scroll_to_top(ref, behaviour) {
  scroll_to_view(ref.current, behaviour || SCROLL_INTO_VIEW_BEHAVIOUR_SMOOTH, 'start')
}

export function scroll_to_centre(ref, behaviour) {
  scroll_to_view(ref.current, behaviour || SCROLL_INTO_VIEW_BEHAVIOUR_SMOOTH, 'center')
}

function scroll_to_view(element, behavior, block) {
  setTimeout(function () {
    element.scrollIntoView({ behavior, block})
  }, SCROLL_INTO_VIEW_BEHAVIOUR_SMOOTH ? 200 : 10)
}
