import React from 'react'
import cn from 'classnames'

import { get_status, get_status_tooltip } from '../../utils/family_view_utils.js'
import { InfoPopover } from '../widgets/Tooltip.js'
import { WRAPPER_ELEMENT_PARENT } from '../../constants/tooltip.js'

import s from './FamilyStatus.module.scss'

export const FamilyStatus = ({family, is_in_modal, className}) => {
  const {status, patFamId} = family
  const non_null_status = status? status : 'no-status'
  const family_status = get_status(non_null_status) || non_null_status
  const family_status_hint = get_status_tooltip(family_status)

  return (
    <div className={cn('d-flex', className)} key={`status_${patFamId}`}>
      <div className={cn('px-2 my-auto', s.content)}>{family_status}</div>
      {family_status_hint &&
        <InfoPopover is_in_modal={is_in_modal} wrapper={WRAPPER_ELEMENT_PARENT} placement='bottom' buttonClassName='my-auto ms-1'><span className='text-endnormal'>{family_status_hint}</span></InfoPopover>
      }
    </div>
  )
}