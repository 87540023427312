/**
 * Decrement the subidx (and also page if necessary).
 * @param {integer} current_subidx index within the current page of items
 * @param {integer} current_page index of page
 * @param {integer} page_size max number of items per page
 * @returns {Object} Object with properties 'page' and 'subidx' 
 */
export function with_prev_subidx(current_subidx, current_page, page_size) {
  // first page, first subidx - do nothing
  if ((current_page === 0) && (current_subidx === 0)) {
    return {
      page: current_page,
      subidx: current_subidx
    }
  }

  // non-first page, first subidx - switch to last subidx of previous page
  if (current_subidx === 0) {
    return {
      page: current_page - 1,
      subidx: page_size - 1
    }
  }

  // if subidx is null (nothing is selected) go to first on this page
  if (!current_subidx) {
    return {
      page: current_page,
      subidx: 0
    }
  }

  // Otherwise - just decrement the subidx
  return {
    page: current_page,
    subidx: current_subidx - 1
  }
}

/**
 * Increment the subidx (and also page if necessary).
 * @param {integer} current_subidx index on the current page of items
 * @param {integer} current_page index of page
 * @param {integer} page_size max number of items per page
 * @param {integer} total_num_pages total number of pages
 * @param {integer} total_num_items total number of items
 * @returns {Object} Object with properties 'page' and 'subidx' 
 */
export function with_next_subidx(current_subidx, current_page, page_size, total_num_pages, total_num_items) {

  // if subidx is null (nothing is selected) go to first on this page
  if (current_subidx === null) {
    return {
      page: current_page,
      subidx: 0
    }
  }

  // Get last_subidx on current page
  const last_page = total_num_pages - 1
  const num_items_on_current_page = 
        (current_page === last_page) ? ((total_num_items % page_size) || page_size) // last page
                                     : page_size                                    // non-last page

  const last_subidx_on_current_page = num_items_on_current_page - 1
  
  // last page, last subidx - do nothing
  if (current_page === last_page && current_subidx === last_subidx_on_current_page) {
    return {
      page: current_page,
      subidx: current_subidx
    }
  }

  // non-last page, last subidx - switch to first subidx of next page
  if (current_subidx === last_subidx_on_current_page) {
    return {
      page: current_page + 1,
      subidx: 0
    }
  }

  // Otherwise - just increment the subidx
  return {
    page: current_page,
    subidx: current_subidx + 1
  }
}

export function get_prev_page(current_page) {
  return current_page === 0 ? 0 : (current_page - 1)
}

export function get_next_page(current_page, total_num_pages) {
  return current_page === (total_num_pages - 1) ? current_page : (current_page + 1)
}