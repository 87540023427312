import React from 'react'

import OrgSetsByGroup from './OrgSetsByGroup.js'

const OrgSetsPanel = ({groups, on_org_set_select_handler, title, className }) => {
  return (
    <div className={className}>
      <h4>{title}</h4>
      {groups.map((group, i) => (
        <OrgSetsByGroup
          key={i}
          group={group}
          on_org_set_select_handler={on_org_set_select_handler}
          className='ps-2 mt-2'
        />
      ))}
    </div>
  )
}

export default OrgSetsPanel