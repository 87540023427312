import React from 'react'
import cn from 'classnames'

import { TriangleIcon, TriangleOutlineIcon } from './IconSet.js'

import cs from '../cipher_styles.module.scss'
import s from './TableColumnHeader.module.scss'

const SortControl = ({on_click, className}) => {
  return (
    <div onClick={on_click} className={cn(className)}>
      <div className={cn('h-100', s.icon_current)}><TriangleOutlineIcon/></div>
      <div className={cn('h-100', s.icon_new)}><TriangleIcon/></div>
    </div>
  )
}

export const ColumnUnsortedIcon = ({ on_click, className }) => {
  return (
    <div className={cn('d-flex flex-column', s.sort_icon_container, className)} onClick={on_click}>
      <div className={cn(s.sort_asc_wrapper, s.icon)}><TriangleOutlineIcon /></div>

      <div className={cn(cs.rotate_180, s.sort_desc_wrapper, s.icon)}><SortControl /></div>
    </div>
  )
}

export const ColumnSortedAscIcon = ({ on_click, className }) => {
  return (
    <div className={cn('d-flex flex-column', s.sort_icon_container, className)} onClick={on_click}>
      <div className={cn(s.sort_asc_wrapper, s.icon)}>
        <TriangleIcon/>
      </div>

      <div className={cn(cs.rotate_180, s.sort_desc_wrapper, s.icon)}>
        <SortControl />
      </div>
    </div>
  )
}

export const ColumnSortedDescIcon = ({ on_click, className }) => {
  return (
    <div className={cn('d-flex flex-column', s.sort_icon_container, className)} onClick={on_click}>
      <div className={cn(s.sort_asc_wrapper, s.icon)}>
        <SortControl />
      </div>

      <div className={cn(cs.rotate_180, s.sort_desc_wrapper, s.icon)}>
        <TriangleIcon/>
      </div>
    </div>
  )
}

const TableColumnHeader = ({ label, sort_disabled, labelClassName, sortIconClassName }) => {
  return (
    <div
      className={cn('d-flex', s.column_header, s.header_wrapper, { [s.header_wrapper__no_sort]: sort_disabled })}
    >
      <div
        className={cn('text-start', s.label, {[s.label__sortable]: !sort_disabled, labelClassName})}
      >
        {label}
      </div>

      {!sort_disabled &&
        <div
          className={cn('d-flex flex-column', s.sort_icon_container, sortIconClassName)}
        >
          <div className={cn('d-block', s.sort_asc_wrapper, s.icon)}>
            <span className='sort-asc-on d-none'><TriangleIcon/></span>
            <SortControl className='sort-asc-off d-block'/>
          </div>

          <div className={cn('d-block', cs.rotate_180, s.sort_desc_wrapper, s.icon)}>
            <span className='sort-desc-on d-none'><TriangleIcon/></span>
            <SortControl className='sort-desc-off d-block'/>
          </div>

        </div>
      }
    </div>
  )
}


export default TableColumnHeader