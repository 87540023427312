import React from 'react'

import OrgSearchInput from './OrgSearchInput.js'

import s from './OrgFilteringControl.module.scss'

const OrgFilteringControl = ({on_change, text}) => {
  return (
    <OrgSearchInput
      on_change_handler={on_change}
      placeholder={'Enter a search phrase to filter'}
      show_refresh={false}
      autofocus={false}
      value={text}
      inputClassName={s.input}
    />
  )
}

export default OrgFilteringControl