import React from 'react'
import cn from 'classnames'

import { RemoveIcon } from './IconSet.js'

import s from './InputClearIcon.module.scss'

const InputClearControl = ({on_click, className}) => {
  return (
    <span
      className={cn('d-flex align-items-center justify-content-center me-2 my-auto', s.clear_icon, className)}
      onClick={on_click}
      title='Clear'
    >
      <RemoveIcon />
    </span>
  )
}

export default InputClearControl