import React from 'react'

import { MIMETYPE_PDF } from '../../utils/download_utils.js'
import TextLink from './TextLink.js'
import { is_IE11, is_safari } from '../../utils/browser_utils.js'

export const PdfViewer = ({className, src, title, download_url}) => {
  const name = title || 'Classification document'

  const click_and_download = <div className='m-3'>Click to download <TextLink element='a' href={download_url || src} title={name}>{name}</TextLink></div>

  if (is_IE11()) {
    return (
      click_and_download
    )
  }

  return (
    <object data={src} type={is_safari() ? null : MIMETYPE_PDF} className={className}>
      {click_and_download}
    </object>
  )
}