import React from 'react'
import _ from 'underscore'
import cn from 'classnames'

import { file_details, ID_TO_UPLOAD_MODE } from '../../../utils/custom_search_utils.js'
import {
  consider_selected_matches,
  is_status_ambiguous,
  is_status_found,
  is_status_not_found
} from './matched_patents_table_utils.js'
import { MatchAmbiguousIcon, MatchFoundIcon, MatchNotFoundIcon } from '../MatchSymbols.js'

import cs from '../../cipher_styles.module.scss'
import s from './MatchedPatentsSummary.module.scss'

const Result = ({Icon, label, count, className}) => {
  return (
    <span className={className}>
      <Icon disabled={count === 0} /><span className='ms-1'>{label}: <span className={cs.font_weight_bold}>{count}</span></span>
    </span>
  )
}

const MatchedPatentsSummary = (
  {
    input_file,
    upload_mode,
    lines,
    className
  }) => {

  const found = lines.filter((line) => is_status_found(line.status)).length
  const ambiguous = lines.filter((line) => is_status_ambiguous(line.status)).length
  const unrecognised = lines.filter((line) => is_status_not_found(line.status)).length

  const families_to_add = _.unique(consider_selected_matches(lines))

  return (
    <div className={cn('p-2', s.block, className)}>
      <div className='d-flex mb-2'>
        <div>
          <span>Selected upload: </span><span className={cs.font_weight_bold}>{(ID_TO_UPLOAD_MODE[upload_mode] || {}).short_name || ''}</span>
          {input_file &&
            <>
              <span className='me-1'>, input file:</span>
              <span className={cs.font_weight_bold}>{file_details(input_file)}</span>
            </>
          }
        </div>

        <div className='ms-3'>
          <span>Families to add: </span><span className={cs.font_weight_bold}>{(families_to_add || []).length}</span>
        </div>
        <div className='ms-3'>
          <span>Total: </span><span className={cs.font_weight_bold}>{(lines || []).length}</span> patents uploaded
        </div>
      </div>

      <div className='d-flex'>
        <Result Icon={MatchFoundIcon} label='Found' count={found} />
        <Result Icon={MatchAmbiguousIcon} label='Ambiguous' count={ambiguous} className='ms-3' />
        <Result Icon={MatchNotFoundIcon} label='Not found' count={unrecognised} className='ms-3' />
      </div>
    </div>
  )
}

export default MatchedPatentsSummary