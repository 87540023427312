import React from 'react'
import cn from 'classnames'

import { TertiaryButton } from '../widgets/Button.js'
import { ArrowLeftIcon } from '../widgets/IconSet.js'
import ForwardBackSelector from '../widgets/ForwardBackSelector.js'

import s from './PatentFamilyListDetailViewNav.module.scss'

const PatentFamilyListDetailViewNav = (
  {
    on_cancel_details_view,
    current_idx,
    total_results_count,
    on_prev_item,
    on_next_item,

    className
  }) => {
  return (
    <div className={cn('d-flex justify-content-between', className)}>
      <TertiaryButton
        onClick={on_cancel_details_view}
        icon_only={true}
        className={cn('my-auto', s.back_btn)}
      >
        <ArrowLeftIcon/>
      </TertiaryButton>

      <ForwardBackSelector
        className='ms-auto'
        label={'family'}
        current_idx={current_idx}
        min_idx_incl={0}
        max_idx_excl={total_results_count}
        on_prev={on_prev_item}
        on_next={on_next_item}
      />
    </div>
  )
}

export default PatentFamilyListDetailViewNav