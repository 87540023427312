import React, { Component } from 'react'

import Spinner from '../widgets/Spinner.js'
import { Pane } from '../widgets/Block.js'
import DisputeDetails from './DisputeDetails.js'

import { track_visit_event } from '../../utils/tracking_utils.js'
import { fetch_report_metadata } from '../../utils/report_created_utils.js'
import { DISPUTE_DETAILS_VIEWS_BY_ID } from '../../utils/disputes_utils.js'
import ErrorBody from '../ErrorBody.js'
import ContainerFixedWidth from '../ContainerFixedWidth.js'

class DisputeView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show_spinner: true,
      internal_report_id: null,
      dispute_id: null
    }
  }

  componentDidMount() {

    const external_report_id = this.props.match.params.external_report_id
    const dispute_id = this.props.match.params.dispute_id

    fetch_report_metadata(external_report_id)
      .catch(e => {
        this.setState({fetch_report_metadata_error: e, show_spinner: false})
        throw e
      })
      .then(({ internal_report_id }) => {
        this.setState({internal_report_id, dispute_id, show_spinner: false})
      })


    document.title = 'Classification: Dispute'
    track_visit_event('page="dispute"')
  }

  render() {
    const {internal_report_id, dispute_id, show_spinner, fetch_report_metadata_error} = this.state

    if (fetch_report_metadata_error) {
      return(
        <ContainerFixedWidth>
          <ErrorBody
            error={fetch_report_metadata_error}
            context='retrieving dispute details'
          />
        </ContainerFixedWidth>
      )
    }

    return (
      <ContainerFixedWidth>
        {show_spinner &&
          <Pane className='text-center'>
            <Spinner />
            <p>Fetching results ...</p>
          </Pane>
        }

        {!show_spinner && internal_report_id && dispute_id &&
          <DisputeDetails
            dispute_id={dispute_id}
            internal_report_id={internal_report_id}
            view={DISPUTE_DETAILS_VIEWS_BY_ID.page.id}
          />
        }
      </ContainerFixedWidth>
    )
  }
}

export default DisputeView