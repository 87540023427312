import React from 'react'
import cn from 'classnames'

import { TABLE_COLUMN_HEADER } from '../../model/table.js'
import CheckboxStatic from '../widgets/CheckboxStatic.js'
import { Table } from '../widgets/Table.js'

const Selector = ({item, selected_items, on_click}) => {

  const is_checked = (selected_items.indexOf(item) > -1)

  return (
    <CheckboxStatic onClick={on_click} is_checked={is_checked} />
  )
}

const PortfolioCheckboxList = (
  {
    items,
    selected_items_idxs,
    is_header_checkbox_selected,
    on_click_from_item_checkbox,
    on_click_from_header_checkbox,

    className
  }) => {
  return (
    <Table
      columns={[
        {...TABLE_COLUMN_HEADER,
          id: 'idx',
          field: 'idx',
          header: (
            <CheckboxStatic is_checked={is_header_checkbox_selected}  onClick={on_click_from_header_checkbox} />
          ),
          sortable: false,
          width: 60,
          cell_render: (row) => (<span key={row.value}><Selector item={row.value} on_click={() => on_click_from_item_checkbox(row.value)} selected_items={selected_items_idxs || []}  /></span>)
        },
        {...TABLE_COLUMN_HEADER,
          id: 'name',
          field: 'name',
          label: 'Name',
          sortable: false,
        },

      ]}
      data={items}
      className={cn('w-100', className)}
    />
  )
}

export default PortfolioCheckboxList