import React from 'react'
import cn from 'classnames'

import s from './OrgParentsDisplay.module.scss'

const OrgParentsDisplay = ({org, get_parent_details}) => {

  const { root_organisation_ids } = org

  const parents = get_parent_details && root_organisation_ids ? get_parent_details(org) : null

  function get_parents_details_display(parents) {
    const names = parents.map(item => (item.name))
    const last_name = names.pop()
    return names.length > 0 ? `${names.join(', ')} & ${last_name}` : last_name
  }

  if (!parents) return null

  return (
    <span className={cn('ms-1 my-auto', s.org_parent_info)}> part of {get_parents_details_display(parents)}</span>
  )
}

export default OrgParentsDisplay