import { MONITOR, REPORT, REPORT_READER_QUERY } from '../constants/paths.js'
import { get_as_map } from '../utils/utils.js'

export const REPORT_MONITOR_TOOL =   {id: REPORT,               name: 'Report Monitor',   get_path: (report_id) => `${MONITOR}${REPORT}${report_id ? `/${report_id}` : ''}`}
export const RR_QUERY_MONITOR_TOOL = {id: REPORT_READER_QUERY,  name: 'Report Query Checker',    get_path: (report_id) => `${MONITOR}${REPORT_READER_QUERY}${report_id ? `?report_id=${report_id}` : ''}`}

export const MONITOR_TOOLS = [
  REPORT_MONITOR_TOOL, RR_QUERY_MONITOR_TOOL
]

export const ID_TO_MONITOR_TOOL = get_as_map(MONITOR_TOOLS, 'id')