import React, { useEffect, useState } from 'react'

import Modal from '../widgets/Modal.js'
import {
  prepare_clickthrough_report_input,
  save_clickthrough_report_input
} from '../../utils/patent_family_list_utils.js'
import Spinner from '../widgets/Spinner.js'
import ReportNameInput from '../ReportNameInput.js'
import {
  is_valid_report_name,
  remove_not_allowed_chars_from_text
} from '../../utils/name_utils.js'
import { get_patent_upload_as_portfolio_item } from '../../model/portfolio_basket.js'
import { CACHED_REPORT_THRESHOLD_STRICT, existing_or_new_report } from '../../utils/report_builder_utils.js'
import { BUILD_REPORT, REPORT } from '../../constants/paths.js'
import { PrimaryButton } from '../widgets/Button.js'
import ErrorBody from '../ErrorBody.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'
import TextLink from '../widgets/TextLink.js'
import { get_report_name_with_given_suffix, save_to_report_history } from '../../utils/viewer_utils.js'
import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'
import { build_generic_builder_report_input } from '../../utils/report_input_utils.js'
import { UTT_REPORT_TYPE } from '../../constants/constants.js'
import { get_default_utt_version } from '../../utils/user_settings_utils.js'
import { save_eval_report_selected_charts_in_state } from '../../utils/report_state_utils.js'
import { SELECTED_SUBPATH } from '../../constants/viewer_paths.js'

const CLUSTERED_SUFFIX = '- clustered'
const UTT_SUFFIX = '- UTT'

export const InstantReportFromSubsetModal = (
  {
    query_params,
    report_title,
    report_from_subset_type,
    evaluation_classifier_id,
    on_hide,
    user_settings
  }) => {
  const is_utt = report_from_subset_type === UTT_REPORT_TYPE

  const [show_spinner, set_show_spinner] = useState(false)
  const [should_create_report, set_should_create_report] = useState(false)
  const [new_report_name, set_new_report_name] = useState(get_report_name_with_given_suffix(report_title, is_utt ? UTT_SUFFIX : CLUSTERED_SUFFIX))
  const [new_report_id, set_new_report_id] = useState(null)

  const [build_report_error, set_build_report_error] = useState(null)

  useEffect(() => {
    let did_cancel = false
    if (!should_create_report) return

    set_show_spinner(true)

    prepare_clickthrough_report_input({ params:  query_params })
      .then((response) => {
        if (!did_cancel) {
          const { items } = response || {}
          const portfolio_items = []

          items.forEach(item => {
            const { families, name, is_rollup } = item
            portfolio_items.push(get_patent_upload_as_portfolio_item({
              name,
              pat_fam_ids: families,
              group_by_owner: (is_rollup === true)
            }))
          })

          const report_name = new_report_name || report_title

          const report_input = build_generic_builder_report_input({
            report_name: 'Report from subset', // Anonymous report title, in case of shared report parquet
            report_type: report_from_subset_type,
            portfolios: portfolio_items,
            portfolios_to_cluster: is_utt ? null : portfolio_items.map((item, i) => i),
            evaluation_classifier_id,
            utt_version: get_default_utt_version(user_settings)
          })

          return existing_or_new_report(report_input, report_name, CACHED_REPORT_THRESHOLD_STRICT)
            .then(external_report_id => {

              if (evaluation_classifier_id !== null) {
                return save_eval_report_selected_charts_in_state(external_report_id)
              }

              return external_report_id
            })
            .then((external_report_id) => {

              save_to_report_history(external_report_id, false)

              set_new_report_id(external_report_id)
              set_show_spinner(false)
            })
        }
      })
      .catch(err => {
        if (!did_cancel) {
          set_show_spinner(false)
          set_build_report_error(err)
        }
      })

    return () => {
      did_cancel = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [should_create_report])

  function is_new_name_valid() {
    return is_valid_report_name(new_report_name)
  }

  function start_report() {
    track_report_builder_event(`action="build_report" report_type="${report_from_subset_type}" context="viewer"`)
    set_should_create_report(true)
  }

  function on_change_report_name_input(value) {
    set_new_report_name(remove_not_allowed_chars_from_text(value))
  }

  function on_key_up(e) {
    switch (e.keyCode) {
      case 13:
        e.preventDefault()
        if (is_new_name_valid()) {
          start_report()
        }
        break
      case 27:
        e.preventDefault()
        on_close()
        break
      default:
        return
    }
  }

  function on_close() {
    set_build_report_error(null)
    set_new_report_name(null)
    set_new_report_id(null)
    set_show_spinner(false)
    on_hide()
  }

  const primary_button = (
    <PrimaryButton
      onClick={() => start_report()}
      disabled={!is_new_name_valid() || show_spinner || new_report_id}
    >
      Run report
    </PrimaryButton>
  )

  return (
    <Modal is_open={true} on_hide={on_close} primary_button={primary_button} title={`${is_utt ? 'UTT' : 'Clustered'} report`}>
      {!show_spinner && !new_report_id &&
        <ReportNameInput
          report_name={new_report_name}
          on_change={on_change_report_name_input}
          on_key_up={on_key_up}
        />
      }

      <div>
        <div className='my-1'>
          {show_spinner &&
            <div className='d-flex flex-column'>
              <span>Started building report <span className='text-endbold'>{new_report_name}</span>. Please wait...</span>
              <Spinner />
            </div>
          }

          {new_report_id &&
            <>
              Click to open report <TextLink element='a' target='_blank' href={`${REPORT}/${new_report_id}${evaluation_classifier_id != null ? `/${SELECTED_SUBPATH}` : ''}`} onClick={on_close}>{new_report_name}</TextLink>
            </>
          }
        </div>
      </div>

      {build_report_error &&
        <ErrorBody
          error={build_report_error}
          context='building a report for the families subset'
        />
      }

    </Modal>
  )
}

export const NewReportFromSubsetModal = ({ on_hide, query_params, report_type, evaluation_classifier_id }) => {

  const [show_spinner, set_show_spinner] = useState(false)
  const [new_input_id, set_new_input_id] = useState(null)
  const [open_in_new_tab, set_open_in_new_tab] = useState(true)
  const [save_input_error, set_save_input_error] = useState(null)

  useEffect(() => {
    let did_cancel = false

    set_show_spinner(true)

    const input = {
      params:  query_params,
      name: 'clickthrough_report',
      report_type,
      evaluation_classifier_id
    }

    save_clickthrough_report_input(input)
      .then((input_id) => {
        if (!did_cancel) {
          set_new_input_id(input_id)
          set_show_spinner(false)
        }
      })
      .catch(err => {
        if (!did_cancel) {
          set_show_spinner(false)
          set_save_input_error(err)
        }
      })

    return () => {
      did_cancel = true
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const report_builder_url = new_input_id ? `${BUILD_REPORT}?input=${new_input_id}` : null

  function on_close() {
    set_show_spinner(false)
    set_new_input_id(null)
    set_save_input_error(null)
    on_hide()
  }

  function get_footer() {
    if (show_spinner) return <Spinner />

    if (new_input_id) return (
      <TextLink element='a' href={report_builder_url} target={open_in_new_tab ? '_blank' : null}>
        Go to report builder
      </TextLink>
    )

    return null
  }

  return (
    <Modal is_open={true} on_hide={on_close} footer={get_footer()} title='New report from list' no_close_button={true} no_footer={save_input_error != null}>

      {!save_input_error &&
        <div>
          <CheckboxAndLabel
            label='Open in new tab'
            is_checked={open_in_new_tab}
            on_click={() => set_open_in_new_tab(!open_in_new_tab)}
          />
        </div>
      }

      {save_input_error &&
        <ErrorBody
          error={save_input_error}
          context='building a report from the families list'
        />
      }

    </Modal>
  )
}