import React from 'react'
import cn from 'classnames'
import { DropdownItem } from 'reactstrap'

import BaseDropdown from '../widgets/BaseDropdown.js'
import { RadiobuttonWithLabel } from '../widgets/RadiobuttonWithLabel.js'
import { SORT_TYPE_BY_ID, SORT_TYPES, SORT_DIRS } from '../../model/organisation.js'
import { ID_TO_SORT_DIRECTION  } from '../../model/sort_directions.js'

import s from './OrgSortingControl.module.scss'

const OrgSortingControl = ({selected_sort_by, selected_sort_dir, on_children_sort_change}) => {
  function on_sort_change(field, dir) {
    on_children_sort_change(field.id, dir.id)
  }

  function get_summary() {
    return `${SORT_TYPE_BY_ID[selected_sort_by].name} (${ID_TO_SORT_DIRECTION[selected_sort_dir].name})`
  }

  function get_label(field, dir) {
    return `${field.name} (${dir.name})`
  }

  function get_key(field, dir) {
    return `${field.id}_${dir.id}`
  }

  function get_checked_status(field, dir) {
    return (field.id === selected_sort_by) && (dir.id === selected_sort_dir)
  }

  return (
    <span className='d-flex align-items-center'>
      <label className='my-auto'>
        Sort by
      </label>

      <BaseDropdown
        className='ms-1'
        label={get_summary()}
        labelClassName={cn('my-auto', s.button_label)}
        right={false}
        menuClassName={s.menu}
        buttonClassName={cn('d-flex', s.button)}
      >
        {SORT_TYPES.map(field => {
          return SORT_DIRS.map(dir => {
            return (
              <DropdownItem
                className={cn('d-flex align-items-start', s.item)}
                key={get_key(field, dir)}
                toggle={false} // on click, keep the dropdown open
                tag='div'
              >
                <RadiobuttonWithLabel
                  is_checked={get_checked_status(field, dir)}
                  on_click={() => on_sort_change(field, dir)}
                  label={get_label(field, dir)}
                />
              </DropdownItem>
            )
          })
        })}
      </BaseDropdown>
    </span>
  )
}

export default OrgSortingControl