import React, { useState } from 'react'
import qs from 'query-string'
import { withRouter } from 'react-router-dom'

import OrgSuggestionsSearch from './OrgSuggestionsSearch.js'
import ReportBuilderStartScreen from '../builder/ReportBuilderStartScreen.js'
import OrgSearchResultsDisplay from './OrgSearchResultsDisplay.js'
import OrgSearchInput from './OrgSearchInput.js'
import { withReportBasket } from '../builder/ReportBasketContext.js'
import ErrorModal from '../ErrorModal.js'
import { get_all_assignees, is_agglomeration, is_org_type } from '../../utils/organisation_utils.js'
import {  ORG_SEARCH_CONTEXT } from '../../model/organisation.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'
import { useOrgSearch } from '../../hooks/organisation_hooks.js'
import BadSyntaxAlertModal from '../patent_family_list/BadSyntaxAlertModal.js'
import { is_400_error } from '../../utils/axios_utils.js'
import { update_url_with_search_phrase } from '../../utils/url_utils.js'
import OrgSearchModeWrapper from './OrgSearchModeWrapper.js'
import OrgSetsDisplay from '../org_sets/OrgSetsDisplay.js'

const OrgSearch = (
  {
    location,
    history,
    external_items_for_suggestions,
    reset_items_for_suggestions_handler,
    org_group,
    portfolio_basket,
    update_basket_handler,
    update_group_handler,
    toggle_is_search_multiple,

    add_org_set_to_basket_handler,
    user_company_lists,
    user_company_list_updated_handler,
    user_company_list_rename_handler,
    user_company_list_sharing_handler,
    invalid_portfolios_in_list_handler,
  }) => {
  const query_params = qs.parse(location.search)

  const [search_phrase, set_search_phrase] = useState(decodeURIComponent(query_params.org_search || ''))
  const [items_for_suggestions, set_items_for_suggestions] = useState(null)
  const [show_spinner, results, org_search_error, clear_org_search_error ] = useOrgSearch(search_phrase, false, true)

  function on_change_from_search_input(new_search_phrase) {
    if (search_phrase === new_search_phrase) {
      return
    }
    
    set_search_phrase(new_search_phrase)
    update_url_with_search_phrase(new_search_phrase, 'org_search', location, history)
    set_items_for_suggestions(null)
    reset_items_for_suggestions_handler()
  }

  function show_similar_organisations(org) {
    track_report_builder_event('obj="similar_orgs" context="search" action="show"')
    const items = (!is_agglomeration(org)) ? [org] : get_all_assignees(org)
    set_items_for_suggestions({ items, name: org.name })
    reset_items_for_suggestions_handler()
  }

  function add_to_basket(org) {
    update_basket_handler({add: [org]})
  }

  function remove_from_basket(org) {
    update_basket_handler({ remove: [org]})
  }

  function add_to_grouping(org) {
    update_group_handler({add: [org]})
  }

  function remove_from_grouping(org) {
    update_group_handler({ remove: [org] })
  }

  function on_org_click_handler({org, add, with_special_key, result_reference}) {
    add ? on_click_add(org, with_special_key, result_reference) : on_click_remove(org)
  }

  function on_click_add(org, with_special_key, result_reference) {
    const should_add_to_group = with_special_key || is_in_grouping_mode
    track_report_builder_event(`action="${should_add_to_group ? 'add_to_org_group' : 'add_to_basket'}" obj="org" context="search" result_reference="${(result_reference || []).join(',')}"`)
    const handler = should_add_to_group ? add_to_grouping : add_to_basket
    handler(org)
  }

  function on_click_remove(org) {
    if (is_in_grouping_mode) {
      track_report_builder_event('action="remove_from_org_group" obj="org" context="search"')
      remove_from_grouping(org)
    } else {
      track_report_builder_event('action="remove_from_basket" obj="org" context="search"')
      remove_from_basket(org)
    }
  }

  function on_suggestion_click_handler({org, add, with_special_key}) {
    add ? on_click_add_suggestion(org, with_special_key) : on_click_remove_suggestion(org)
  }

  function on_click_add_suggestion(org, with_special_key) {
    const should_add_to_group = with_special_key || is_in_grouping_mode

    if (should_add_to_group) {
      track_report_builder_event(`action="add_to_org_group" obj="org" context="org_suggestions"`)
      add_to_grouping(org)
    } else {
      track_report_builder_event(`action="add_to_basket" obj="org" context="org_suggestions"`)
      add_to_basket(org, false)
    }
  }

  function on_click_remove_suggestion(org) {
    if (is_in_grouping_mode) {
      track_report_builder_event(`action="remove_from_org_group" obj="org" context="org_suggestions"`)
      remove_from_grouping(org)
    } else {
      track_report_builder_event(`action="remove_from_basket" obj="org" context="org_suggestions"`)
      remove_from_basket(org)
    }
  }

  function reset_suggestions() {
    track_report_builder_event(`action="close" obj="org_suggestions" context="search"`)
    reset_items_for_suggestions_handler()
    set_search_phrase('')
    set_items_for_suggestions(null)
  }

  function get_base_items_for_suggestions() {
    return  external_items_for_suggestions || items_for_suggestions || null
  }

  const is_bad_syntax_error = is_400_error(org_search_error)

  if (org_search_error && !is_bad_syntax_error) {
    return(
      <ErrorModal
        on_hide={clear_org_search_error}
        error={org_search_error}
        context='fetching organisation results'
      />
    )
  }

  if (is_bad_syntax_error) {
    return(
      <BadSyntaxAlertModal
        on_hide={clear_org_search_error}
      />
    )
  }

  const is_in_grouping_mode = org_group && org_group.length > 0

  const has_results = (results && results.length > 0)

  const selected_organisations = (is_in_grouping_mode) ? org_group : (portfolio_basket || []).filter(item => is_org_type(item))

  const { items: base_items_for_suggestions, name: base_name } = get_base_items_for_suggestions() || {}

  return (
    <div>
      <OrgSearchModeWrapper search_mode={'single'} on_search_mode_change={toggle_is_search_multiple}>
        <OrgSearchInput
          on_change_handler={on_change_from_search_input}
          value={search_phrase}
          autofocus
        />
      </OrgSearchModeWrapper>

      {!base_items_for_suggestions &&
        <OrgSearchResultsDisplay
          results={results}
          show_spinner={show_spinner}
          search_phrase={search_phrase}
          on_result_check_in={on_org_click_handler}
          context={ORG_SEARCH_CONTEXT}
          selected_organisations={selected_organisations}
          on_show_similar_organisations={show_similar_organisations}
        />
      }

      {!show_spinner && base_items_for_suggestions &&
        <OrgSuggestionsSearch
          base_name={base_name}
          base_items={base_items_for_suggestions}
          on_result_select={on_suggestion_click_handler}
          reset_handler={reset_suggestions}
          on_show_similar_organisations={show_similar_organisations}
          selected_organisations={selected_organisations}
        />
      }

      {!show_spinner && !has_results && !base_items_for_suggestions &&
        <>
          {is_in_grouping_mode &&
            <ReportBuilderStartScreen
              text={'Enter the name of an organisation to add it to the report.'}
            />
          }

          {!is_in_grouping_mode &&
            <OrgSetsDisplay
              add_to_basket_handler={add_org_set_to_basket_handler}
              user_company_lists={user_company_lists}
              user_company_list_updated_handler={user_company_list_updated_handler}
              user_company_list_rename_handler={user_company_list_rename_handler}
              user_company_list_sharing_handler={user_company_list_sharing_handler}
              invalid_portfolios_in_list_handler={invalid_portfolios_in_list_handler}
            />
          }
        </>
      }

    </div>
  )
}

export default withReportBasket(withRouter(OrgSearch))