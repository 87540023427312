import React from 'react'
import SpecHelpLink from './SpecHelpLink.js'

import s from './MainReportItemDesc.module.scss'

export const MainReportItemDesc = ({item}) => {
  const {description, get_description, help_article} = item

  const description_to_display = get_description ? get_description({item}) : description

  return (
    <div className='d-flex justify-content-center'>
      <div className={s.desc_block}>
        {description_to_display}
        <SpecHelpLink help_article={help_article} className='ms-1'/>
      </div>
    </div>
  )
}