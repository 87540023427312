import React from 'react'

import TextLink from '../widgets/TextLink.js'
import { SPIF_GROUP_URI } from '../../constants/constants'
import { HELP, REPORT_SCHEMA_GUIDE } from '../../constants/paths.js'
import { get_alert_focus_description } from '../../utils/alert_report_utils.js'

export const DefendantLitigationsDesc = () => {
  return (
    <div>
      A report giving details of all the litigations in which the selected companies appear as a defendant. Includes a clustering of the litigated technologies, and some analysis of the plaintiffs.
    </div>
  )
}

export const PlaintiffLitigationsDesc = () => {
  return (
    <div>
      A report giving details of all the litigations in which the selected companies appear as a plaintiff. Includes a clustering of the litigated technologies, and some analysis of the defendants.
    </div>
  )
}

export const QualityDesc = () => {
  return (
    <div>
      The Quality export report is ideal if you are dealing with a large portfolio and need a recommendation of which patent families you should focus on first.
      This report provides you with a suggested ranking which is based on what represents value to you and how you score three key factors: Age, Citations and Geography.
    </div>
  )
}

export const FilingTrendsDesc = () => {
  return (
    <div>
      <div>This report takes each organisation in turn, and analyzes publication patterns to see which technologies they are increasing their filing in most quickly, and which they’re decreasing most quickly.</div>
      <div className='mt-2'>Useful to gain insight into the filing strategy of 3rd parties.</div>
    </div>
  )
}

export const GeoWaterfallDesc = () => {
  return (
    <div>
      A visualisation showing the relative strategic importance of each geography to the organisations in the report, for example, if they only file in three territories, it’s likely to be this set.
    </div>
  )
}

export const GeoComparisonDesc = () => {
  return (
    <div>
      An Excel sheet including a heatmap of geography, organisation and technology, allowing geographic comparisons to be made across technologies and organisations at once.
    </div>
  )
}

export const CitationsDesc = () => {
  return (
    <div>
      <div>A report which shows detail about the data relating to citations for selected families.</div>
      <div className='mt-2'>It shows who the owners of the forward and backward cited patents are, and patterns of cross-citation, as well as identifying the most-cited families.</div>
      <div className='mt-2'>This report is limited to 250,000 selected families.</div>
    </div>
  )
}

export const ReassignmentDesc = () => {
  return (
    <div>
      Exports the reassignment history of the selected families for manual processing.
    </div>
  )
}

export const PublicationsDesc = () => {
  return (
    <div>
      Export the selected families, with one row per publication in each family.
    </div>
  )
}

export const PVIXDesc = () => {
  return (
    <div>
      Some in-depth analysis of the PVIX scores in the selected organisations and technologies, showing how the scores vary over time, and where the density of scores lies for each organisation and technology.
    </div>
  )
}

export const BenchmarkMetricsDesc = () => {
  return (
    <div>
      Raw data used in the calculation of benchmarking stats.
    </div>
  )
}

export const BenchmarkingReportDesc = () => {
  return (
    <div>
      Create a benchmarking report deck from a merged landscape report.
    </div>
  )
}

export const FilingStrategyDesc = () => {
  return (
    <div>
      A report to compare the filing strategy of a target company to others in the industry, showing the similarity of their filings to the aggregate of the other companies.
    </div>
  )
}

export const TechnologyRenameDesc = () => {
  return (
    <div>
      A utility script which makes it easier to rename technologies, so they can be uploaded as a Custom Clustered Report.
    </div>
  )
}

export const OrganisationRenameDesc = () => {
  return (
    <div>
      A utility script which makes it easier to rename organisations, so they can be uploaded as a Custom Clustered Report.
    </div>
  )
}

export const OverlapDesc = () => {
  return (
    <div>
      Measures the overlap between technologies in an &quot;overlap mode&quot; classifier report, or between organisation portfolios. Mainly used for QAing taxonomies.
    </div>
  )
}

export const ExecutiveSummaryDesc = () => {
  return (
    <div>
      Generates a one-page PDF file, comparing up to 5 organisations at a high level.
    </div>
  )
}

export const TrendSpotterDesc = () => {
  return (
    <div>
      Analyses the selected families to determine if there are any new areas of rapidly increasing filing, typically from companies entering into new areas, or emerging areas in an industry.
    </div>
  )
}

export const SPIFDesc = () => {
  return (
    <div>
      Export the selected families in the SPIF format. See <TextLink element='a' target='_blank' href={SPIF_GROUP_URI}>{SPIF_GROUP_URI}</TextLink> for more information.
    </div>
  )
}

export const NDDesc = () => {
  return (
    <div>
      <div>Cipher n/d uses the AST Product Taxonomy to classify portfolios.</div>
      <div>Cipher n/d helps with the problem of patent licensing involving companies with large global portfolios in the following scenarios:</div>
      <ul>
        <li>Cross-licensing, to help calculate an estimate of a cross-license with another company</li>
        <li>Royalty payments attributable to multiple organisations</li>
        <li>Monetisation, to compare outcomes of patent sale versus licensing</li>
        <li>Acquisitions, to provide objective evidence of potential value when purchasing patent portfolios</li>
        <li>Patenting, to correlate portfolios to revenue and market share data</li>
      </ul>
    </div>
  )
}

export const GoogleValuationDesc = () => {
  return (
    <div>
      Custom export function for internal Google use
    </div>
  )
}

export const GeoTimelineDesc = () => {
  return (
    <div>
      <div>A report, by territory and organisation, of the number of applications filed in each year.</div>
      <div>By default it will use 2015 to the previous year, to override that set the priority date filter. Which territories are included is controlled by the territory filter.</div>
    </div>
  )
}

export const ReportZIPDesc = () => {
  return (
    <div>
      Download report data as a compressed file. It can take up to 10 min to finish. The output is a zip file containing CSV files with the complete report data. See <TextLink element='a' target='_blank' href={`${HELP}/${REPORT_SCHEMA_GUIDE}`}>report schema</TextLink> for more information.
    </div>
  )
}

export const AlertSetupDesc = ({report_input, params}) => {
  const alert_focus = get_alert_focus_description(report_input)
  const {alert_id} = params
  return (
    <div>
      {alert_id ? 'Update your ' : 'Set up a regular '} email alert based on this report to receive updates on {alert_focus.endsWith('s') ? 'these' : 'this'} {alert_focus}.
    </div>
  )
}