import React, { useEffect } from 'react'
import cn from 'classnames'

import { get_owners_string, get_priority_year, get_top_score } from '../classifiers_editor/utils/patent_field_utils.js'
import { Highlighter } from '../widgets/Highlighter.js'
import InputStatusMarker from '../tech_explorer/InputStatusMarker.js'
import PatentFamilyClassifierMarker from './PatentFamilyClassifierMarker.js'
import { useClick } from '../../hooks/general_hooks.js'
import Tooltip from '../widgets/Tooltip.js'
import {
  is_element_vertically_onscreen,
  scroll_to_centre,
} from '../../utils/scroll_utils.js'
import ClassifierScoreDisplay from '../classifiers_editor/components/ClassifierScoreDisplay.js'
import { format_score } from '../classifiers_editor/utils/training_set_utils.js'

import cs from '../cipher_styles.module.scss'
import s from './PatentFamiliesSimpleList.module.scss'

const Family = (
  {
    family,
    on_click,

    no_highlighting,
    highlight_prefix_only,
    search_phrases,
    search_colours,

    is_selected,

    knn_family_ids,
    knn_blocklist,
    knn_bookmarked,
    show_knn_actions,

    page_top
  }) => {

  const item_ref = React.useRef(null)

  useEffect(() => {
    if (is_selected && !is_element_vertically_onscreen(item_ref.current, page_top)) {
      scroll_to_centre(item_ref)
    }
  }, [is_selected, page_top])

  const highlighter_props = {
    no_highlighting,
    highlight_prefix_only,
    search_words: search_phrases,
    search_colours: search_colours
  }

  const on_family_click = useClick({on_single_click: on_click, on_multiple_click: () => {return}})

  const {patFamId, title, priorityDate, abstract, user_class, owners, similarity_score} = family || {}

  const score = get_top_score(family)
  const priority_year = get_priority_year(priorityDate)
  const owners_string = get_owners_string(owners)

  return (
    <div onClick={on_family_click} className={cn('py-1', s.item_wrapper)} ref={item_ref}>

      <Tooltip
        is_in_modal={true}
        placement='right'
        delay={[200, 50]}
        toggler={(
          <div className={cn('d-flex', s.item, { [s.item__selected]: is_selected })}>
            <div className={cn('d-block flex-grow-1', s.status_marker)}>

              {show_knn_actions &&
                <InputStatusMarker
                  family_id={patFamId}
                  positives={knn_family_ids}
                  negatives={knn_blocklist}
                  bookmarked={knn_bookmarked}

                  display='table'
                />
              }

              {user_class &&
                <PatentFamilyClassifierMarker
                  label={user_class}
                />
              }

            </div>

            <div className='w-100 py-2 pe-2'>

              <div className={cn('d-flex justify-content-between', s.item_detail_line)}>
                <span className={s.title__preview}>
                  <Highlighter
                    {...highlighter_props}
                    text_to_highlight={title}
                  />
                </span>
                <span className={s.year}>
                  <Highlighter
                    {...highlighter_props}
                    text_to_highlight={priority_year}
                  />
                </span>
              </div>

              <div className={cn('d-flex', s.item_detail_line, s.abstract_preview)}>
                {!abstract &&
                  <span>(no abstract available)</span>
                }
                <Highlighter
                  {...highlighter_props}
                  text_to_highlight={abstract}
                />
              </div>

              <div className={cn('d-flex justify-content-between', cs.font_size_small, cs.font_weight_bold, s.item_detail_line)}>
                <span className={s.owner_preview}>
                  <Highlighter
                    {...highlighter_props}
                    text_to_highlight={owners_string}
                  />
                </span>
                {score &&
                  <ClassifierScoreDisplay
                    score={score}
                    className='px-1'
                  />
                }

                {similarity_score && !score &&
                  <span title='Similarity score'>
                    {format_score(similarity_score)}
                  </span>
                }

              </div>
            </div>
          </div>
        )}
      >
        <div className='d-flex flex-column'>
          <span className={cn('mb-2', s.title)}>
            <Highlighter
              {...highlighter_props}
              text_to_highlight={title}
            />
          </span>
          {!abstract &&
            <span>(no abstract available)</span>
          }
          <Highlighter
            {...highlighter_props}
            text_to_highlight={abstract}
          />

          <span className='mt-2'>
            <Highlighter
              {...highlighter_props}
              text_to_highlight={owners_string}
            />
          </span>
        </div>
      </Tooltip>

    </div>
  )
}

const PatentFamiliesSimpleList = (
  {
    selected_family,
    patent_families,

    no_highlighting,
    highlight_prefix_only,
    search_phrases,
    search_colours,

    knn_family_ids,
    knn_blocklist,
    knn_bookmarked,
    show_knn_actions,

    on_change_subidx,
    page_top,
    className
  }) => {
  if ((patent_families || []).length === 0) return null

  const { patFamId: selected_family_id } = selected_family || {}

  function on_item_click(i) {
    if (document.getSelection().type === 'Range') return

    on_change_subidx(i)
  }

  return (
    <div className={className}>

      {patent_families.map((family, i) => {
        const { patFamId } = family || {}

        return (
          <Family
            key={i}
            family={family}
            on_click={() => on_item_click(i)}

            no_highlighting={no_highlighting}
            highlight_prefix_only={highlight_prefix_only}
            search_phrases={search_phrases}
            search_colours={search_colours}

            is_selected={patFamId === selected_family_id}

            knn_family_ids={knn_family_ids}
            knn_blocklist={knn_blocklist}
            knn_bookmarked={knn_bookmarked}
            show_knn_actions={show_knn_actions}

            page_top={page_top}
          />
        )
      })}
    </div>
  )
}

export default PatentFamiliesSimpleList