import React, { useState } from 'react'
import cn from 'classnames'

import { is_agglomeration, is_organisation } from '../../utils/organisation_utils.js'
import TextLink from '../widgets/TextLink.js'
import { PlusIcon, EditIcon, ExtractIcon, MakeGroupIcon, MoveAssigneeUpIcon } from '../widgets/IconSet.js'
import TranslatedNamePopup from './TranslatedNamePopup.js'
import OrgMenu from './OrgMenu.js'
import { run_speedy_org_report } from '../../utils/report_builder_utils.js'
import Modal from '../widgets/Modal.js'
import Spinner from '../widgets/Spinner.js'
import ErrorBody from '../ErrorBody.js'
import OrgChangeLog from './OrgChangeLog.js'

import s from './OrgOptions.module.scss'

const OrgOptions = (
  {
    org,
    on_click_from_edit_handler,
    on_click_from_ungroup_handler,
    on_click_from_move_assignees_to_parent_handler,
    on_click_from_add_handler,
    on_click_from_turn_agglom_into_org,
    tooltip_ref_suffix,
    level,
    is_selected,
    export_assignees_handler,
    className
  }) => {
  const {id, name} = org

  const is_org = is_organisation(org)
  const is_agglom = is_agglomeration(org)

  const is_ungroup_allowed = !is_agglom && (level !== 0)
  const is_move_assignees_to_parent_allowed = is_org && (level !== 0)
  const is_add_new_allowed = is_org

  const [show_new_report_modal,   set_show_new_report_modal] = useState(false)
  const [show_new_report_spinner, set_show_new_report_spinner] = useState(false)
  const [report_id, set_report_id] = useState(null)
  const [build_report_error, set_build_report_error] = useState(null)


  function on_start_report() {
    set_show_new_report_spinner(true)
    set_show_new_report_modal(true)

    run_speedy_org_report(org)
      .then((external_report_id) => {
        set_show_new_report_spinner(false)
        set_report_id(external_report_id)
      })
      .catch((error) => {
        set_show_new_report_spinner(false)
        set_build_report_error(error)
      })
  }

  function close_new_report_modal() {
    set_show_new_report_modal(false)
    set_show_new_report_spinner(false)
    set_report_id(null)
    set_build_report_error(null)
  }

  return (
    <div className={cn(s.wrapper, className)}>
      {show_new_report_modal &&
        <Modal on_hide={close_new_report_modal}>
          {show_new_report_spinner &&
            <Spinner />
          }

          {!show_new_report_spinner && !build_report_error && report_id &&
            <TextLink element={'a'} target={'_blank'} href={`/report/${report_id}`}>Open report for {name}</TextLink>
          }

          {build_report_error &&
            <ErrorBody
              error={build_report_error}
              context='building a report'
            />
          }
        </Modal>
      }

      {is_org &&
        <OrgChangeLog
          org={org}
        />
      }

      {is_org &&
        <TextLink title='Edit' className={s.option} onClick={() => on_click_from_edit_handler(id)} no_decoration><EditIcon/></TextLink>
      }

      {!(name || '').match(/[0-9a-zA-Z]/g) &&
        <TranslatedNamePopup org_name={name} popup_reference={'translate' + tooltip_ref_suffix} className={s.option} />
      }

      {is_move_assignees_to_parent_allowed &&
        <TextLink title='Move all assignees to parent' disable={is_selected} className={s.option} onClick={() => on_click_from_move_assignees_to_parent_handler(org)} no_decoration><MoveAssigneeUpIcon /></TextLink>
      }

      {is_ungroup_allowed &&
        <TextLink title='Clear parent' disable={is_selected} className={s.option} onClick={() => on_click_from_ungroup_handler(org)} no_decoration><ExtractIcon /></TextLink>
      }

      {is_add_new_allowed &&
        <TextLink title='Add new' className={s.option} onClick={() => on_click_from_add_handler(org)} no_decoration ><PlusIcon/></TextLink>
      }

      {is_agglom &&
        <TextLink title='Turn into organisation' className={s.option} onClick={() => on_click_from_turn_agglom_into_org(org)} no_decoration><MakeGroupIcon/></TextLink>
      }

      <OrgMenu
        export_assignees_handler={is_organisation(org) ? export_assignees_handler : null}
        run_assignee_report={!is_agglomeration(org) ? on_start_report : null}
        org_name={name}
        buttonClassName={s.option}
      />

    </div>
  )
}

export default OrgOptions
