import React from 'react'
import { Badge } from 'reactstrap'
import cn from 'classnames'

import { get_org_id, is_agglomeration } from '../../utils/organisation_utils.js'
import { MakeGroupIcon } from '../widgets/IconSet.js'
import { Highlighter } from '../widgets/Highlighter.js'
import OrgTagsPopup from './OrgTagsPopup.js'
import { get_pae_tag, TAG_CATEGORY_JOINT_VENTURE, TAG_GROUP_CATEGORY } from '../../model/org_sets.js'
import OrgNotesPopup from './OrgNotesPopup.js'
import OrgParentsDisplay from './OrgParentsDisplay.js'

import s from './OrgName.module.scss'

const OrgName = ({org, filter_by, is_grouping_context, get_parent_details, show_id, tooltip_ref_suffix, on_click_from_edit_handler, className}) => {

  const { name, tags, notes, grouping_only, is_non_practicing_entity, non_practicing_entity_override } = org

  const icon = is_grouping_context && (is_agglomeration(org) || grouping_only) ? (<span className={s.icon_wrapper}><MakeGroupIcon/>{' '}</span>) : null

  function is_jv() {
    if (!tags) return false
    const jv_tag = `${TAG_GROUP_CATEGORY}:${TAG_CATEGORY_JOINT_VENTURE}`

    return tags.indexOf(jv_tag) > -1
  }

  const pae_tag = get_pae_tag()
  function is_pae() {
    if (!tags) return false
    return tags.indexOf(pae_tag) > -1
  }

  function get_product_tags() {
    if (!tags) return null
    return tags.filter(tag => tag !== pae_tag)
  }

  const org_tags = get_product_tags()

  return (
    <div className={cn(['d-flex', s.org_name, {[s.org_name__agglom] : (is_grouping_context && is_agglomeration(org))}, className])}>
      {icon}
      {<Highlighter search_words={[filter_by]} text_to_highlight={name} />}
      {show_id && !is_agglomeration(org) && <span> [{get_org_id(org)}]</span>}
      {is_grouping_context && (is_non_practicing_entity || non_practicing_entity_override) && !is_agglomeration(org) && <span className='ms-1 my-auto'><Badge color='info' pill>NPE</Badge></span>}
      {is_grouping_context && is_pae() && !is_agglomeration(org) && <span className='ms-1 my-auto'><Badge color='primary' pill>PAE</Badge></span>}
      {is_grouping_context && is_jv() && !is_agglomeration(org) && <span className='ms-1 my-auto'><Badge color='warning' pill title='Joint Venture'>JV</Badge></span>}
      <OrgParentsDisplay org={org} get_parent_details={get_parent_details}/>
      {is_grouping_context && org_tags && <OrgTagsPopup className='ms-2' tags={org_tags} reference={'tags_'+tooltip_ref_suffix} />}
      {is_grouping_context && notes && <OrgNotesPopup className='ms-2' notes={notes} reference={'notes_'+tooltip_ref_suffix} on_click_from_edit_handler={on_click_from_edit_handler}/>}
    </div>
  )
}

export default OrgName