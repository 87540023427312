import React, {useState} from 'react'
import cn from 'classnames'

import ClearableSearchInput from '../widgets/ClearableSearchInput.js'
import { PrimaryButton } from '../widgets/Button.js'
import TextLink from '../widgets/TextLink.js'
import { TrashIcon } from '../widgets/IconSet.js'
import { get_as_map } from '../../utils/utils.js'
import { CIPHER_CHART_SETS } from '../../model/chart_sets.js'
import { get_default_chart_selection_value } from '../../utils/default_chart_selection_utils.js'
import { get_normalised_name } from '../../utils/name_utils.js'
import Modal from '../widgets/Modal.js'
import { FormFeedback } from '../widgets/FormFeedback.js'

import s from './SelectionMenu.module.scss'

const MAX_SELECTION_NAME_LENGTH = 50

const SelectChartSetAsDefault = ({item, default_chart_selection, on_change_default_chart_selection}) => {
  const value = get_default_chart_selection_value(item)
  const is_selected_as_default = (default_chart_selection === value)

  if (is_selected_as_default) {
    return (
      <span className={s.selected_as_default}>default</span>
    )
  }

  return (
    <TextLink className={s.select_as_default} onClick={() => on_change_default_chart_selection(value)}>
      set as default
    </TextLink>
  )
}

export const UserChartSetsList = ({user_chart_sets=[], default_chart_selection, on_select, on_remove, on_change_default_chart_selection}) => {
  const is_selected_as_default_enabled = (on_change_default_chart_selection != null)

  function get_sets_sorted(sets) {
    if (!sets || sets.length < 2) return sets

    const sets_by_name = get_as_map(sets, 'name')

    return Object.keys(sets_by_name).sort().map(name => sets_by_name[name])
  }

  const user_defined_sets = get_sets_sorted(user_chart_sets.filter(item => !item.is_shared_set))
  const shared_sets = get_sets_sorted(user_chart_sets.filter(item => item.is_shared_set))

  return (
    <div>
      {user_defined_sets && user_defined_sets.length > 0 &&
        <div>
          <h5>Saved selections</h5>
          {
            user_defined_sets.map((item, i) => {
              const { name } = item
              const is_selected_as_default = (default_chart_selection === get_default_chart_selection_value(item))

              return (
                <div key={i} className={cn('d-flex justify-content-between')}>
                  <div className='d-flex'>
                    {on_remove &&
                      <TextLink
                        onClick={() => {on_remove(item)}}
                        className={cn('me-2 my-auto pt-1')}
                        disable={is_selected_as_default}
                        no_decoration={true}
                      >
                        <TrashIcon/>
                      </TextLink>
                    }
                    <TextLink onClick={() => on_select(item)} className='my-auto'>{name}</TextLink>
                  </div>
                  {is_selected_as_default_enabled &&
                    <SelectChartSetAsDefault
                      item={item}
                      default_chart_selection={default_chart_selection}
                      on_change_default_chart_selection={on_change_default_chart_selection}
                    />
                  }
                </div>
              )
            })
          }
        </div>
      }

      {shared_sets && shared_sets.length > 0 &&
        <div className='mt-3'>
          <h5>Shared with my organisation</h5>
          {shared_sets.map((item, i) => {
            const {name} = item
            return (
              <div key={i} className={cn('d-flex justify-content-between')}>
                <TextLink onClick={() => on_select(item)}>{name}</TextLink>
                {is_selected_as_default_enabled &&
                  <SelectChartSetAsDefault
                    item={item}
                    default_chart_selection={default_chart_selection}
                    on_change_default_chart_selection={on_change_default_chart_selection}
                  />
                }
              </div>
            )
          })}
        </div>
      }

      <div className='mt-3'>
        <h5>Classification selections</h5>
        {CIPHER_CHART_SETS.map((item, i) => {
          const {name} = item
          return (
            <div key={i} className={cn('d-flex justify-content-between')}>
              <TextLink onClick={() => on_select(item)}>{name}</TextLink>
              {is_selected_as_default_enabled &&
                <SelectChartSetAsDefault
                  item={item}
                  default_chart_selection={default_chart_selection}
                  on_change_default_chart_selection={on_change_default_chart_selection}
                />
              }
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const LoadSelectionModal = ({user_chart_sets=[], default_chart_selection, on_remove_user_chart_set, on_select_user_chart_set, on_change_default_chart_selection, on_hide, className}) => {
  function select(set) {
    on_select_user_chart_set(set)
    on_hide()
  }

  return (
    <Modal
      is_open={true}
      title='Choose from saved chart selections'
      on_hide={on_hide}
      className={className}
    >
      <div className='w-100'>
        <UserChartSetsList
          user_chart_sets={user_chart_sets}
          default_chart_selection={default_chart_selection}
          on_select={select}
          on_remove={on_remove_user_chart_set}
          on_change_default_chart_selection={on_change_default_chart_selection}
        />
      </div>
    </Modal>
  )
}

export const SaveSelectionModal = ({user_chart_sets, on_confirm, on_hide, className}) => {
  const [name, set_name] = useState(null)

  function submit() {
    const normalised_name = get_normalised_name(name)

    on_confirm(normalised_name)
    on_hide()
  }

  function on_key_down(e) {
    if (e.which === 13) {
      submit()
    }
  }

  const name_to_user_set = get_as_map(user_chart_sets || [], 'name')
  const existing_names = Object.keys(name_to_user_set)
  const normalised_name = get_normalised_name(name)
  const is_name_valid = name && (normalised_name.length > 0 && normalised_name.length <= MAX_SELECTION_NAME_LENGTH)
  const has_available_slots = (user_chart_sets || []).length < 20

  const set_with_name_idx = existing_names.indexOf(normalised_name)

  const is_name_already_in_use = set_with_name_idx !== -1

  const is_save_available = name && has_available_slots && is_name_valid

  return (
    <Modal
      is_open={true}
      on_hide={on_hide}
      title='Save selected charts'
      primary_button={(
        <PrimaryButton
          className={s.save_btn}
          onClick={submit}
          disabled={!is_save_available}
        >
          {is_name_already_in_use && <span>Update</span>}
          {!is_name_already_in_use && <span>Save</span>}
        </PrimaryButton>
      )}
      className={className}
    >
      <div className='w-100'>
        <ClearableSearchInput
          show_clear
          value={name || ''}
          placeholder='Save selection as ...'
          handle_change={(value) => set_name(value)}
          handle_key_down={is_save_available ? on_key_down : null}
          auto_focus={true}
        />
        <div className={cn('=d-block mb-2', s.msg_container)}>
          {!has_available_slots &&
            <FormFeedback valid={false} validation_text='You can have up to 20 saved selections' />
          }
          {name != null && !is_name_valid &&
            <FormFeedback valid={false} validation_text={`Selection name cannot be empty or longer than ${MAX_SELECTION_NAME_LENGTH} characters.`} />
          }
          {is_name_already_in_use &&
            <FormFeedback valid={false} validation_text={`There is already a selection with the name '${existing_names[set_with_name_idx]}', would you like to update it?`}/>
          }
        </div>

      </div>

    </Modal>
  )
}