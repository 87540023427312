import React from 'react'

import Modal from './widgets/Modal.js'

const AlertModal = ({title, on_hide, children, size}) => {

  return (
    <Modal
      size={size || 'sm'}
      on_hide={on_hide}
      title={title}
      close_label={'OK'}
    >
      {children}
    </Modal>
  )

}

export default AlertModal