import React, {useState} from 'react'
import _ from 'underscore'
import cn from 'classnames'

import { InfoPopover } from '../../widgets/Tooltip.js'
import { CheckboxAndLabel } from '../../widgets/CheckboxAndLabel.js'
import { add_or_remove_item_from_array } from '../../../utils/utils.js'
import Label from '../../widgets/Label.js'
import DropdownChevron from '../../widgets/DropdownChevron.js'

import cs from '../../cipher_styles.module.scss'
import s from './ColumnFilter.module.scss'

export const ColumnFilter = ({label, values, selected_value_ids, on_change_selection, className}) => {

  const [is_open, set_is_open] = useState(false)

  const is_all_selected = (values || []).every(item => _.contains(selected_value_ids, item.id))
  const selected_items = values.filter(item => selected_value_ids.indexOf(item.id) > -1)
  const button_label = is_all_selected ? 'All' : selected_items.map(item => item.name).join(', ')

  function on_toggle_item_id(item_id) {
    on_change_selection(add_or_remove_item_from_array(selected_value_ids, item_id))
  }

  return (
    <div className={cn('d-flex align-items-center', className)}>
      <Label className={cs.font_weight_normal}>{label}</Label>

      <InfoPopover
        interactive={true}
        arrow={false}
        placement='bottom-start'
        toggler={(
          <span className={cn('d-flex ms-2', s.menu_toggle)}>
            {button_label || 'None'}
            <DropdownChevron is_down_arrow={!is_open}/>
          </span>
        )}
        className={cn(s.filter_menu)}
        popupClassName={s.menu}
        on_show={() => set_is_open(true)}
        on_hide={() => set_is_open(false)}
      >
        {(values|| []).map((item, idx) =>
          <div className='dropdown-item py-1' key={idx}>
            <CheckboxAndLabel
              is_checked={_.contains(selected_value_ids, item.id)}
              label={item.name}
              on_click={() => on_toggle_item_id(item.id)}
            />
          </div>
        )}
      </InfoPopover>
    </div>
  )
}