import React from 'react'
import cn from 'classnames'

import s from './TrademarkSymbol.module.scss'

export const TrademarkSymbol = () => {
  return (
    <sup className={cn(s.symbol, s.symbol_tm)}>TM</sup>
  )
}

export const RegisteredSymbol = () => {
  return (
    <sup className={s.symbol}>&reg;</sup>
  )
}
