import React from 'react'

import Modal from './widgets/Modal.js';
import ErrorBody from './ErrorBody.js'
import { PrimaryButton } from './widgets/Button.js'

const ConfirmModal = ({title, on_confirm, on_cancel, children, confirm_label, cancel_label, error, confirm_disabled, size}) => {
  return (
    <Modal
      size={size || 'md'}
      title={title}
      close_label={cancel_label || (error ? 'Close': 'Cancel')}
      on_hide={on_cancel}
      primary_button={error ? '' : <PrimaryButton onClick={on_confirm} disabled={confirm_disabled}>{confirm_label || 'OK'}</PrimaryButton>}
    >
      {children}

      {error &&
        <ErrorBody error={error}/>
      }
    </Modal>
  )
}

export default ConfirmModal