import React, { useState, useEffect } from 'react'
import _ from 'underscore'
import cn from 'classnames'
import {
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  DropdownItem,
  Card,
  CardBody,
  CardTitle
} from 'reactstrap'

import Spinner from '../widgets/Spinner.js'
import Modal from '../widgets/Modal.js'
import { PrimaryButton } from '../widgets/Button.js'

import { disable_event_when_enter_pressed, remove_double_spaces } from '../../utils/utils.js'
import OrgTags from './OrgTags.js'
import { get_pae_tag, ORG_SET_TAGS_BY_ID, TAG_GROUP_PRODUCT } from '../../model/org_sets.js'
import { get_selected_products } from '../../utils/org_sets_utils.js'
import BaseDropdown from '../widgets/BaseDropdown.js'
import TextLink from '../widgets/TextLink.js'
import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'

import s from './OrganisationModal.module.scss'

const NPEControl = ({org, is_pae, non_practicing_entity_override, on_change}) => {
  const { is_non_practicing_entity } = org

  function get_custom_label() {
    if (non_practicing_entity_override != null) return `${non_practicing_entity_override ? 'Yes' : 'No'}`
    return 'not set'
  }

  const auto_label = `Automatic (${is_non_practicing_entity ? 'yes' : 'no'})`
  const selected_label = (non_practicing_entity_override == null) ? auto_label : get_custom_label()

  return (
    <FormGroup className='d-flex'>
      <div className={cn('my-auto me-2', s.label__npe)}>Is Non Practicing Entity?</div>
      <BaseDropdown label={selected_label} menuClassName={s.menu__npe}>

        <DropdownItem onClick={() => on_change(null)} active={non_practicing_entity_override == null}>
          {auto_label}
        </DropdownItem>
        <DropdownItem onClick={() => on_change(true)} active={non_practicing_entity_override === true}>
          Yes
        </DropdownItem>
        <DropdownItem onClick={() => on_change(false)} active={is_pae || (non_practicing_entity_override === false)}>
          No
        </DropdownItem>

      </BaseDropdown>
    </FormGroup>
  )
}

const OrganisationModal = ({show_spinner, org, title, on_hide, on_confirm, confirm_label}) => {
  const [new_name, set_new_name] = useState(null)
  const [notes, set_notes] = useState(null)
  const [new_grouping_only, set_new_grouping_only] = useState(null)
  const [org_tags, set_org_tags] = useState([])
  const [is_valid, set_is_valid] = useState(true)
  const [non_practicing_entity_override, set_non_practicing_entity_override] = useState(null)

  useEffect(() => {
    const {name, tags, notes, non_practicing_entity_override} = org || {}

    set_new_name(name || '')
    set_org_tags(tags || [])
    set_notes(notes || '')

    if (non_practicing_entity_override != null) {
      set_non_practicing_entity_override(non_practicing_entity_override)
    }

  }, [org])

  function on_change_from_name_input(e) {
    const value = e.target.value
    set_new_name(value)
    set_is_valid(value !== '')
  }

  function on_key_down_from_name_input(e) {
    disable_event_when_enter_pressed(e)
  }

  function add_tag(tag) {
    set_org_tags([...org_tags, tag])
  }

  function remove_tag(full_tag) {
    const [group, tag] = full_tag.split(':')
    const tags = [...org_tags]

    if (group !== TAG_GROUP_PRODUCT) {
      set_org_tags(tags.filter(tag => (tag !== full_tag) ))
      return
    }

    const selected_products = get_selected_products(org_tags)
    if (selected_products.length === 1) {
      clear_product_tags()
      return
    }

    selected_products.splice(selected_products.indexOf(tag), 1)
    tags.splice(tags.indexOf(full_tag), 1)

    tags.forEach(tag => {
      const {products} = ORG_SET_TAGS_BY_ID[tag]

      if (products && products.length > 0 && _.intersection(products, selected_products).length === 0) {
        tags.splice(tags.indexOf(tag), 1)
      }
    })

    set_org_tags(tags)
  }

  function get_product_tags() {
    return org_tags.filter(tag => tag !== pae_tag)
  }

  function clear_product_tags() {
    const updated_tags = org_tags.filter(tag => tag === pae_tag)

    set_org_tags(updated_tags)
  }

  function confirm() {
    if (!new_name || new_name === '') return
    on_confirm({new_name: remove_double_spaces(new_name.trim()), tags: org_tags, non_practicing_entity_override: non_practicing_entity_override, notes: remove_double_spaces(notes.trim()), grouping_only: new_grouping_only})
  }

  function get_is_grouping_only() {
    const {grouping_only} = org || {}

    if (new_grouping_only != null) {
      return new_grouping_only
    }

    return (grouping_only != null) ? grouping_only : false
  }

  const is_grouping_only = get_is_grouping_only()

  const pae_tag = get_pae_tag()
  const is_pae = (org_tags || []).indexOf(pae_tag) > -1

  return (
    <Modal is_open={true} on_hide={on_hide} primary_button={<PrimaryButton onClick={confirm}>{confirm_label}</PrimaryButton>} title={title} close_label='Cancel'>
      {show_spinner &&
        <div className='text-center'>
          <Spinner />
          <p>Fetching results ...</p>
        </div>
      }

      {!show_spinner &&
        <div>
          <FormGroup>
            <InputGroup>

              <InputGroupText>Name</InputGroupText>

              <Input
                placeholder='Enter new name'
                value={new_name || ''}
                onChange={on_change_from_name_input}
                onKeyDown={on_key_down_from_name_input}
                invalid={!is_valid}
                autoComplete='off'
              />
              <FormFeedback>Name cannot be empty.</FormFeedback>

            </InputGroup>
          </FormGroup>

          <Card color='danger' outline className='mb-2'>
            <CardBody className='p-2'>
              <CardTitle>NPE</CardTitle>
              <NPEControl
                org={org}
                is_pae={is_pae}
                non_practicing_entity_override={non_practicing_entity_override}
                on_change={set_non_practicing_entity_override}
              />

              <FormGroup className='d-flex'>
                <div className={cn('my-auto me-2', s.label__npe)}>Is Patent Assertion Entity?</div>

                <BaseDropdown label={is_pae ? 'Yes' : 'No'} menuClassName={s.menu__npe}>
                  <DropdownItem
                    onClick={() => add_tag(pae_tag)}
                    active={is_pae}
                  >
                    Yes
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => remove_tag(pae_tag)}
                    active={!is_pae}
                  >
                    No
                  </DropdownItem>

                </BaseDropdown>
              </FormGroup>
            </CardBody>
          </Card>
          <FormGroup className='d-flex'>
            <CheckboxAndLabel
              label='Not organisation, grouping only'
              is_checked={is_grouping_only}
              on_click={() => set_new_grouping_only(!new_grouping_only)}
            />
          </FormGroup>

          <FormGroup>
            <div className='my-auto me-2 d-flex'>
              Notes
              {notes && <TextLink className='ms-1' onClick={() => set_notes('')}>[clear]</TextLink>}
            </div>
            <Input
              type='textarea'
              value={notes || ''}
              onChange={(e) => set_notes(e.target.value)}
              autoComplete='off'
            />
          </FormGroup>
          
          <OrgTags
            org_tags={get_product_tags()}
            on_tag_add_handler={add_tag}
            on_tag_remove_handler={remove_tag}
            on_clear_handler={clear_product_tags}
          />
        </div>
      }
    </Modal>
  )
}

export default OrganisationModal