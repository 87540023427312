import React from 'react'
import cn from 'classnames'
import { DropdownItem } from 'reactstrap'

import TextLink from '../widgets/TextLink.js'
import { NAV_COSTS_SECTION_ID } from '../../model/family_view.js'
import { scroll_to_top } from '../../utils/scroll_utils.js'
import BaseDropdown from '../widgets/BaseDropdown.js'

import s from './FamilyDetailsNavigation.module.scss'

const NavigationAnchorWithInnerRef = (props, ref) => {
  const { className } = props || {}

  return (
    <div className={cn('position-absolute', s.anchor, className)} ref={ref}/>
  )
}

export const NavigationAnchor = React.forwardRef(NavigationAnchorWithInnerRef)

const FamilyDetailsNavigation = (
  {
    sections = [],
    section_id_to_ref,
    allow_public_access,
    className,
}) => {
  const filtered_sections = allow_public_access ? sections.filter(section => section.id !== NAV_COSTS_SECTION_ID) : sections

  if (filtered_sections.length === 0) {
    return (<div className={className} />)
  }

  return (
    <div className={cn('d-flex flex-row flex-nowrap justify-content-lg-between', className)}>

      <BaseDropdown className='d-block d-lg-none' label='Jump to' buttonClassName={s.menu_toggle}>
        {filtered_sections.map((section, i) => {
          const { id, label } = section
          const ref = section_id_to_ref[id]

          return (
            <DropdownItem key={i} onClick={() => scroll_to_top(ref)} toggle={false}>
              {label}
            </DropdownItem>
          )
        })}
      </BaseDropdown>

      <label className='my-auto d-none d-lg-block'>Jump to:</label>

      <div className='d-none d-lg-flex justify-content-around flex-grow-1'>
        {filtered_sections.map((section, i) => {
          const { id, label } = section
          const ref = section_id_to_ref[id]

          return (
            <div key={i}>
              <TextLink onClick={() => scroll_to_top(ref)}>{label}</TextLink>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default FamilyDetailsNavigation