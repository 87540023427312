import React, { useState, useEffect } from 'react'
import _ from 'underscore'
import cn from 'classnames'

import { get_cpc_info } from '../../utils/cpc_utils.js'
import Tooltip from '../widgets/Tooltip.js'
import { Highlighter } from '../widgets/Highlighter.js'

import cs from '../cipher_styles.module.scss'

const NOT_AVAILABLE = '(not available)'
const DEFAULT_SEPARATOR = ','

function get_num_descriptions_to_show_and_hide(num_cpcs) {
  // Cap at 3 (except if there's only 4, then show 4 - it's really annoying to be told 'and 1 more')
  if (num_cpcs > 4) {
    return [3, num_cpcs - 3]
  }
  if (num_cpcs === 4) {
    return [4, num_cpcs - 4]
  }
  return [num_cpcs, 0]
}

/**
 * Renders a cpc (as an inline element).
 * On hover, fetches cpc description(s), and shows them in a tooltip.
 * @param {} cpc_codes The cpc codes to look up and show in the tooltip.
 * @param {} label Optional - the label to show (i.e. this could be a cpc group)
 */
const CpcWithHover = ({ cpc_codes, label, highlighter_props, show_separator, separator, className }) => {

  const [id] = useState(_.uniqueId('cpc-')) // generate only once, on mount.

  const [cpcs_to_fetch,       set_cpcs_to_fetch]      = useState(null)
  const [is_fetching,         set_is_fetching]        = useState(false)
  const [error,               set_error]              = useState(null)
  const [cpc_to_description,  set_cpc_to_description] = useState(null)

  useEffect(() => {
    let cancelled = false

    if (cpcs_to_fetch) {
      set_is_fetching(true)
      get_cpc_info(cpcs_to_fetch)
        .then(cpc_to_desc => {
          if (!cancelled) {
            set_is_fetching(false)
            set_cpc_to_description(cpc_to_desc)
          }
        })
        .catch(err => {
          if (!cancelled) {
            set_is_fetching(false)
            set_error(err)
        }})
    }

    // Cleanup
    return () => cancelled = true
  }, [cpcs_to_fetch])

  const title = label || cpc_codes[0]

  const [num_descriptions_to_show, num_descriptions_to_hide] = get_num_descriptions_to_show_and_hide(cpc_codes.length)

  return (
    <span
      className={cn('d-inline-block', cs.cursor_pointer, className)}
      id={id}
      onMouseOver={() => {set_cpcs_to_fetch(cpc_codes)}}
    >
      {/* The tooltip */}
      <Tooltip
        is_in_modal={true}
        toggler={(
          <span className={cs.white_space_nowrap}>
            <Highlighter
              {...highlighter_props}
              text_to_highlight={title}
            />
            {show_separator ? (separator || DEFAULT_SEPARATOR) : ''}
          </span>
        )}
        delay={[100, 50]}
        placement='top'
      >
        <div
          className='text-start text-endnormal'
        >
          {(is_fetching) &&
            <span>fetching</span>
          }
          {error &&
            <span>(there was an error)</span>
          }
          {cpc_to_description &&
            <div>
              {cpc_codes.slice(0, num_descriptions_to_show).map((cpc_code, i) => {
                const description = cpc_to_description[cpc_code] || NOT_AVAILABLE
                return (
                  <div key={i}>
                    <div className='text-endbold'>
                      <span>{cpc_code}</span>
                    </div>
                    <div>
                      <span>{description}</span>
                    </div>
                  </div>
                )})
              }

              {(num_descriptions_to_hide > 0) &&
                <div>
                  <span>(and {num_descriptions_to_hide} more...)</span>
                </div>
              }
            </div>
          }
        </div>
      </Tooltip>
    </span>
  )
}

export const CpcCodesWithHover = ({cpc_codes}) => {
  const [id] = useState(_.uniqueId('cpc-')) // generate only once, on mount.

  const [cpcs_to_fetch,       set_cpcs_to_fetch]      = useState(null)
  const [is_fetching,         set_is_fetching]        = useState(false)
  const [error,               set_error]              = useState(null)
  const [cpc_to_description,  set_cpc_to_description] = useState(null)
  const [num_descriptions_to_show, num_descriptions_to_hide] = get_num_descriptions_to_show_and_hide(cpc_codes.length)

  useEffect(() => {
    let cancelled = false

    if (cpcs_to_fetch) {
      set_is_fetching(true)
      get_cpc_info(cpcs_to_fetch)
        .then(cpc_to_desc => {
          if (!cancelled) {
            set_is_fetching(false)
            set_cpc_to_description(cpc_to_desc)
          }
        })
        .catch(err => {
          if (!cancelled) {
            set_is_fetching(false)
            set_error(err)
          }})
    }

    // Cleanup
    return () => cancelled = true
  }, [cpcs_to_fetch])

  return (
    <span
      className={cn(cs.cursor_pointer)}
      id={id}
      onMouseOver={() => {set_cpcs_to_fetch(cpc_codes)}}
    >
      <Tooltip
        is_in_modal={true}
        toggler={<span>{cpc_codes.map((cpc_code, i) => {
          return (<span key={i}><span>{cpc_code}</span><span>{i < cpc_codes.length - 1 ? ', ' : ''}</span></span>)
        })}</span>}
        delay={[100, 50]}
        placement='top'
      >
        <div>
          {(is_fetching) &&
            <span>fetching...</span>
          }
          {error &&
            <span>(there was an error)</span>
          }

          {cpc_to_description &&
            <div>
              {cpc_codes.slice(0, num_descriptions_to_show).map((cpc_code, i) => {
                const description = cpc_to_description[cpc_code] || NOT_AVAILABLE
                return (
                  <div key={i}>
                    <div className='text-endbold'>
                      <span>{cpc_code}</span>
                    </div>
                    <div>
                      <span>{description}</span>
                    </div>
                  </div>
                )})
              }

              {(num_descriptions_to_hide > 0) &&
                <div>
                  <span>(and {num_descriptions_to_hide} more...)</span>
                </div>
              }
            </div>
          }
        </div>
      </Tooltip>
    </span>
  )
}

export default CpcWithHover